import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';

Sentry.init({ dsn: "https://1abe06004f184306a569d343ac2aca49@o407126.ingest.sentry.io/5275724" });

// ReactDOM.render(<App />, document.getElementById('root'));
const Obj = withRouter(App);
ReactDOM.render(<Router><Obj /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
