import React, { useEffect, useState } from 'react';
import { StockHistoryPrice } from '../../graphql/generated/graphql';
import Chart from './Stock';
import HC_exporting from 'highcharts/modules/exporting';
import Highcharts, { AxisSetExtremesEventObject } from 'highcharts/highstock';
import moment from 'moment-timezone';
import DivAlign, { DivPosition } from '../atomic/DivAlign';
import Button from '../atomic/Button';
import KeepMounted from '../generic/KeepMounted';

HC_exporting(Highcharts);

type StockChartData = { price: number[][]; volume: any[][]; holding: any[][] };
export type HoldingHistory = { startDate: Date, quantity: number };

type StockChartProps = {
  symbol: string;
  history: { shortLastFetched?: Date; longLastFetched?: Date; short: StockHistoryPrice[]; long: StockHistoryPrice[] };
  holdingHistory?: HoldingHistory[];
  isPreview?: boolean;
  isCrypto?: boolean;
  onRefreshData?: () => void;
};

const StockChart: React.FC<StockChartProps> = (props: StockChartProps) => {
  const { symbol, history, holdingHistory, isCrypto, onRefreshData } = props;
  const [interval, setInterval] = useState<{ recent: boolean, recentInterval: number, fullInterval: number }>({ recent: false, recentInterval: 0, fullInterval: 3 });
  const [historyData, setHistoryData] = useState<{ recentLastFetched?: Date, fullLastFetched?: Date, recent: StockChartData, full: StockChartData } | undefined>(undefined);
  let dataPoints = 0;

  useEffect(() => {
    if (historyData === undefined) {
      setHistoryData({ recentLastFetched: history.shortLastFetched, fullLastFetched: history.longLastFetched, recent: getDataPointsStockChart(true), full: getDataPointsStockChart(false) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (historyData !== undefined
      && (
        (!historyData.recentLastFetched && history.shortLastFetched)
        || (historyData.recentLastFetched && history.shortLastFetched && moment(historyData.recentLastFetched) < moment(history.shortLastFetched))
        || (!historyData.fullLastFetched && history.longLastFetched)
        || (historyData.fullLastFetched && history.longLastFetched && moment(historyData.fullLastFetched) < moment(history.longLastFetched)))) {
      setHistoryData({ recentLastFetched: history.shortLastFetched, fullLastFetched: history.longLastFetched, recent: getDataPointsStockChart(true), full: getDataPointsStockChart(false) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const getDataPoints = (field: string) => {
    const data = interval.recent ? history.short : history.long;
    if (field === 'date') {
      const firstDate = data.length === 0 ? null : moment(new Date(data[0].date)).tz('America/New_York');
      const lastDate = firstDate ? moment(new Date(data[data.length - 1].date)).tz('America/New_York') : null;
      const diffDays = lastDate ? lastDate.diff(firstDate, 'days') : null;
      const result = data.map((point: StockHistoryPrice) => {
        const time = moment(new Date(point.date)).tz('America/New_York');
        const pointValue = time.format('HH:mm') === '09:30' ? moment(new Date(point.date)).tz('America/New_York').format('YYYY-MM-DD HH:mm') : moment(new Date(point.date)).tz('America/New_York').format('YYYY-MM-DD HH:mm');
        return pointValue;
      });
      // If not crypto, display data for full stock market hours
      if (isCrypto !== true && diffDays !== null && diffDays < 7 && lastDate && lastDate.format('HH:mm') !== '16:00') {
        let addDate = lastDate;
        do {
          addDate = addDate.clone().add(moment.duration(5, 'minutes'));
          result.push(addDate.format('YYYY-MM-DD HH:mm'));
        } while (addDate.format('HH:mm') !== '16:00');
      }
      dataPoints = result.length;
      return result;
    }
    if (field === 'high') {
      const result = data.map((point: StockHistoryPrice) => point.adjHigh);
      while (result.length < dataPoints) {
        result.push(result[result.length - 1]);
      }
      return result;
    }
    if (field === 'low') {
      return data.map((point: StockHistoryPrice) => point.adjLow);
    }
    if (field === 'open') {
      return data.map((point: StockHistoryPrice) => point.adjOpen);
    }
    if (field === 'close') {
      return data.map((point: StockHistoryPrice) => point.adjClose);
    }
    return [];
  };

  const getDataPointsStockChart = (short: boolean): StockChartData => {
    const data = short ? history.short : history.long;
    const firstDate = data.length === 0 ? null : moment(new Date(data[0].date)).tz('America/New_York');
    const lastData = data[data.length - 1];
    const lastDate = firstDate ? moment(new Date(lastData.date)).tz('America/New_York') : null;
    const diffDays = lastDate ? lastDate.diff(firstDate, 'days') : null;
    const volumeResult: any[][] = [];
    const holdingResult: any[][] = [];
    let currentHoldingIndex = 0;
    const result = data.map((point: StockHistoryPrice) => {
      const time = moment(new Date(point.date)).tz('America/New_York');
      const timestamp = time.toDate().getTime();
      // const pointValue = time.format('HH:mm') === '09:30' ? moment(new Date(point.date)).tz('America/New_York').format('YYYY-MM-DD HH:mm') : moment(new Date(point.date)).tz('America/New_York').format('YYYY-MM-DD HH:mm');
      volumeResult.push([timestamp, point.adjVolume]);
      if (holdingHistory && (holdingHistory.length > currentHoldingIndex + 1) && moment(holdingHistory[currentHoldingIndex + 1].startDate) < time) {
        currentHoldingIndex = currentHoldingIndex + 1;
      }
      holdingResult.push([timestamp, holdingHistory && holdingHistory.length > currentHoldingIndex ? holdingHistory[currentHoldingIndex].quantity * point.adjClose : 0]);
      return [timestamp, point.adjOpen, point.adjHigh, point.adjLow, point.adjClose];
    });
    // If not crypto, display data for full stock market hours
    if (isCrypto !== true && diffDays !== null && diffDays < 7 && lastDate && lastDate.format('HH:mm') !== '16:00') {
      let addDate = lastDate;
      do {
        addDate = addDate.clone().add(moment.duration(5, 'minutes'));
        const timestamp = addDate.toDate().getTime();
        // const timestamp = addDate.format('YYYY-MM-DD HH:mm');
        result.push([timestamp, lastData.adjOpen, lastData.adjHigh, lastData.adjLow, lastData.adjClose]);
        volumeResult.push([timestamp, 0]);
      } while (addDate.format('HH:mm') !== '16:00');
    }
    return { price: result, volume: volumeResult, holding: holdingResult };
  };

  const chartOptions = {
    title: {
      text: `${symbol} Timeseries`,
    },
    subtitle: {
      text: 'Intraday (5min) open, high, low, close prices & volume',
    },
    yAxis: {
      title: {
        text: '#',
      },
    },
    xAxis: {
      title: {
        text: 'Time',
      },
      categories: getDataPoints('date'),
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
    },
    rangeSelector: {
      buttons: [{
        type: 'day',
        count: 1,
        text: '1d',
      }, {
        type: 'day',
        count: 7,
        text: '7d',
      }, {
        type: 'month',
        count: 1,
        text: '1m',
      }, {
        type: 'month',
        count: 3,
        text: '3m',
      }, {
        type: 'all',
        text: 'All',
      }],
      selected: 0,
    },
    series: [
      {
        name: 'high',
        data: getDataPoints('high'),
        tooltip: {
          valueDecimals: 2,
        },
      },
      {
        name: 'low',
        data: getDataPoints('low'),
      }, {
        name: 'open',
        data: getDataPoints('open'),
      },
      {
        name: 'close',
        data: getDataPoints('close'),
      },
      /*
      {
        name: 'volume',
        data: getDataPoints('volume'),
      }
      */
    ],
  };

  const recentStockChartOptions: Highcharts.Options = {
    xAxis: [{
      events: {
        setExtremes: (evt: AxisSetExtremesEventObject) => {
          const updateInterval = evt.target['chart'] ? evt.target['chart'].rangeSelector.options.selected : interval;
          console.log(`triggered ${updateInterval}`);
        },
      },
    }],
    yAxis: [{
      events: {
        setExtremes: (evt: AxisSetExtremesEventObject) => {
          const updateInterval = evt.target['chart'] ? evt.target['chart'].rangeSelector.options.selected : interval;
          console.log(`triggered ${updateInterval}`);
        },
        afterSetExtremes: (evt: AxisSetExtremesEventObject) => {
          const updateInterval = evt.target['chart'] ? evt.target['chart'].rangeSelector.options.selected : interval;
          console.log(`triggered ${updateInterval}`);
          if (updateInterval !== undefined) {
            /*
            if (updateInterval === 0) {
              if (!recentHistory) {
                setRecentHistory(true);
                setInterval(2);
              }
            }
            else if (updateInterval === 1) {
              if (recentHistory) {
                setRecentHistory(false);
                setInterval(2);
              }
            }
            */
            if (interval !== updateInterval) {
              setInterval((prevInteval) => {
                const updatePrevInterval = { ...prevInteval };
                updatePrevInterval.recent = updateInterval < 2;
                if (updateInterval < 2) {
                  updatePrevInterval.recent = true;
                  updatePrevInterval.recentInterval = updateInterval;
                }
                else if (updateInterval === 7) {
                  updatePrevInterval.recentInterval = updateInterval;
                }
                else {
                  updatePrevInterval.recent = false;
                  updatePrevInterval.fullInterval = updateInterval;
                }
                return updatePrevInterval;
              });
            }
          }
        },
      },
      labels: {
        align: 'left',
      },
      height: '80%',
      resize: {
        enabled: true,
      },
    }, {
      labels: {
        align: 'left',
      },
      top: '80%',
      height: '20%',
      offset: 0,
    }],
    tooltip: {
      shape: 'square',
      headerShape: 'callout',
      borderWidth: 0,
      shadow: false,
    },
    rangeSelector: {
      buttons: /* recentHistory
      ? [{
        type: 'day',
        count: 1,
        text: 'recent history', // 0
      }, {
        type: 'day',
        count: 1,
        text: 'long history', // 1
      }, {
        type: 'day',
        count: 1,
        text: '1d', // 3
      }, {
        type: 'day',
        count: 7,
        text: '7d', // 4
      }] : [{
        type: 'day',
        count: 1,
        text: 'recent history', // 0
      }, {
        type: 'day',
        count: 1,
        text: 'long history', // 1
      }, */ [{
        type: 'day', // recent
        count: 1,
        text: '1d', // 3
      }, {
        type: 'day',
        count: 6,
        text: '7d', // 4
      }, {
        type: 'all',
        text: 'All', // 7
      }, {
        type: 'month',
        count: 1,
        text: '1m', // 2
      }, {
        type: 'month',
        count: 3,
        text: '3m', // 3
      }, {
        type: 'month',
        count: 6,
        text: '6m', // 4
      }, {
        type: 'year',
        count: 1,
        text: '1y', // 5
      }, {
        type: 'year',
        count: 1,
        text: 'YTD', // 6
      }, {
        type: 'year',
        count: 1,
        text: 'All', // 7
      }],
      selected: 0,
      // allButtonsEnabled: true,
    },
    series: [{
      type: 'ohlc',
      id: `${symbol}-ohlc`,
      name: `${symbol} Stock Pric`,
      data: historyData ? historyData.recent.price : undefined,
    }, {
      type: 'column',
      id: `${symbol}-volume`,
      name: `${symbol} Volume`,
      data: historyData ? historyData.recent.volume : undefined,
      yAxis: 1,
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 800,
        },
        chartOptions: {
          rangeSelector: {
            inputEnabled: false,
          },
        },
      }],
    },
  };

  const fullStockChartOptions: Highcharts.Options = {
    exporting: {
      menuItemDefinitions: {
        refreshData: {
          text: 'Refresh Data',
          textKey: 'refreshData',
          onclick: () => {
            console.log('refresh data');
            if (onRefreshData) {
              onRefreshData();
            }
          },
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen', 'refreshData',
          ],
        },
      },
    },
    yAxis: [{
      events: {
        setExtremes: (evt: AxisSetExtremesEventObject) => {
          const updateInterval = evt.target['chart'] ? evt.target['chart'].rangeSelector.options.selected : interval;
          console.log(`triggered ${updateInterval}`);
        },
        afterSetExtremes: (evt: AxisSetExtremesEventObject) => {
          const updateInterval = evt.target['chart'] ? evt.target['chart'].rangeSelector.options.selected : interval;
          console.log(`triggered ${updateInterval}`);
          if (updateInterval !== undefined) {
            /*
            if (updateInterval === 0) {
              if (!recentHistory) {
                setRecentHistory(true);
                setInterval(2);
              }
            }
            else if (updateInterval === 1) {
              if (recentHistory) {
                setRecentHistory(false);
                setInterval(2);
              }
            }
            */
            if (interval !== updateInterval) {
              setInterval((prevInteval) => {
                const updatePrevInterval = { ...prevInteval };
                updatePrevInterval.recent = updateInterval < 2;
                if (updateInterval < 2) {
                  updatePrevInterval.recent = true;
                  updatePrevInterval.recentInterval = updateInterval;
                }
                else if (updateInterval === 7) {
                  updatePrevInterval.fullInterval = updateInterval;
                }
                else {
                  updatePrevInterval.recent = false;
                  updatePrevInterval.fullInterval = updateInterval;
                }
                return updatePrevInterval;
              });
            }
          }
        },
      },
      labels: {
        align: 'left',
      },
      height: '80%',
      resize: {
        enabled: true,
      },
    }, {
      labels: {
        align: 'left',
      },
      top: '80%',
      height: '20%',
      offset: 0,
    }],
    tooltip: {
      shape: 'square',
      headerShape: 'callout',
      borderWidth: 0,
      shadow: false,
    },
    rangeSelector: {
      buttons: /* recentHistory
      ? [{
        type: 'day',
        count: 1,
        text: 'recent history', // 0
      }, {
        type: 'day',
        count: 1,
        text: 'long history', // 1
      }, {
        type: 'day',
        count: 1,
        text: '1d', // 3
      }, {
        type: 'day',
        count: 7,
        text: '7d', // 4
      }] : [{
        type: 'day',
        count: 1,
        text: 'recent history', // 0
      }, {
        type: 'day',
        count: 1,
        text: 'long history', // 1
      }, */ [{
        type: 'day', // full
        count: 1,
        text: '1d', // 3
      }, {
        type: 'day', // set to count 1 to disable this
        count: 1,
        text: '7d', // 4
      }, {
        type: 'day',
        count: 1,
        text: 'All', // 7
      }, {
        type: 'month',
        count: 1,
        text: '1m', // 2
      }, {
        type: 'month',
        count: 3,
        text: '3m', // 3
      }, {
        type: 'month',
        count: 6,
        text: '6m', // 4
      }, {
        type: 'year',
        count: 1,
        text: '1y', // 5
      }, {
        type: 'ytd',
        count: 1,
        text: 'YTD', // 6
      }, {
        type: 'all',
        text: 'All', // 7
      }],
      selected: 3,
      // allButtonsEnabled: true,
    },
    series: [{
      type: 'ohlc',
      id: `${symbol}-ohlc`,
      name: `${symbol} Stock Pric`,
      data: historyData ? historyData.full.price : undefined,
    }, {
      type: 'column',
      id: `${symbol}-volume`,
      name: `${symbol} Volume`,
      data: historyData ? historyData.full.volume : undefined,
      yAxis: 1,
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 800,
        },
        chartOptions: {
          rangeSelector: {
            inputEnabled: false,
          },
        },
      }],
    },
  };

  const getStockChartOptions = (recent: boolean):  Highcharts.Options  => ({
    exporting: {
      menuItemDefinitions: {
        refreshData: {
          text: 'Refresh Data',
          textKey: 'refreshData',
          onclick: () => {
            console.log('refresh data');
            if (onRefreshData) {
              onRefreshData();
            }
          },
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen', 'refreshData',
          ],
        },
      },
    },
    yAxis: [{
      events: {
        setExtremes: (evt: AxisSetExtremesEventObject) => {
          const updateInterval = evt.target['chart'] ? evt.target['chart'].rangeSelector.options.selected : interval;
          console.log(`setExtremes triggered ${updateInterval}`);
        },
        afterSetExtremes: (evt: AxisSetExtremesEventObject) => {
          const updateInterval = evt.target['chart'] ? evt.target['chart'].rangeSelector.options.selected : interval;
          console.log(`triggered ${updateInterval}`);
          if (updateInterval !== undefined) {
            /*
            if (updateInterval === 0) {
              if (!recentHistory) {
                setRecentHistory(true);
                setInterval(2);
              }
            }
            else if (updateInterval === 1) {
              if (recentHistory) {
                setRecentHistory(false);
                setInterval(2);
              }
            }
            */
            if ((recent && interval.recent && updateInterval >= 2) || (!recent && !interval.recent && updateInterval < 2)) {
              setInterval((prevInteval) => {
                const updatePrevInterval = { ...prevInteval };
                updatePrevInterval.recent = updateInterval < 2;
                if (updateInterval < 2) {
                  updatePrevInterval.recent = true;
                  updatePrevInterval.recentInterval = updateInterval;
                }
                else {
                  updatePrevInterval.recent = false;
                  updatePrevInterval.fullInterval = updateInterval;
                }
                return updatePrevInterval;
              });
            }
          }
        },
      },
      labels: {
        align: 'left',
      },
      height: '70%',
      resize: {
        enabled: true,
      },
    }, {
      labels: {
        align: 'left',
      },
      top: '70%',
      height: '15%',
      offset: 0,
    }, {
      labels: {
        align: 'left',
      },
      top: '85%',
      height: '15%',
      offset: 0,
    }],
    tooltip: {
      shape: 'square',
      headerShape: 'callout',
      borderWidth: 0,
      shadow: false,
    },
    rangeSelector: {
      buttons: /* recentHistory
      ? [{
        type: 'day',
        count: 1,
        text: 'recent history', // 0
      }, {
        type: 'day',
        count: 1,
        text: 'long history', // 1
      }, {
        type: 'day',
        count: 1,
        text: '1d', // 3
      }, {
        type: 'day',
        count: 7,
        text: '7d', // 4
      }] : [{
        type: 'day',
        count: 1,
        text: 'recent history', // 0
      }, {
        type: 'day',
        count: 1,
        text: 'long history', // 1
      }, */ [{
        type: recent ? 'day' : 'month', // full
        count: 1,
        text: '1d', // 3
      }, {
        type: 'recent' ? 'day' : 'month', // set to count 1 to disable this
        count: 7,
        text: '7d', // 4
      }, {
        type: recent ? 'day' : 'month',
        count: 1,
        text: '1m', // 2
      }, {
        type: recent ? 'day' : 'month',
        count: 3,
        text: '3m', // 3
      }, {
        type: recent ? 'day' : 'month',
        count: 6,
        text: '6m', // 4
      }, {
        type: recent ? 'day' : 'year',
        count: 1,
        text: '1y', // 5
      }, {
        type: recent ? 'day' : 'ytd',
        count: 1,
        text: 'YTD', // 6
      }, {
        type: 'all',
        text: 'All', // 7
      }],
      selected: recent ? interval.recentInterval : interval.fullInterval,
      allButtonsEnabled: true,
    },
    series: [{
      type: 'ohlc',
      id: `${symbol}-ohlc`,
      name: `${symbol} Stock Pric`,
      data: historyData ? (recent ? historyData.recent.price : historyData.full.price) : undefined,
    }, {
      type: 'column',
      id: `${symbol}-volume`,
      name: `${symbol} Volume`,
      data: historyData ? (recent ? historyData.recent.volume : historyData.full.volume) : undefined,
      yAxis: 1,
    }, {
      type: 'area',
      id: `${symbol}-history`,
      name: `${symbol} history`,
      data: historyData ? (recent ? historyData.recent.holding : historyData.full.holding) : undefined,
      yAxis: 2,
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 800,
        },
        chartOptions: {
          rangeSelector: {
            inputEnabled: false,
          },
        },
      }],
    },
  });

  return (historyData
    ? <>
    <DivAlign hPosition={DivPosition.Left} style={{ visibility: 'hidden' }}>
      <Button size='small' selected={interval.recent} onClick={() => interval.recent ? null : setInterval({ recent: true, recentInterval: interval.recentInterval, fullInterval: interval.fullInterval })}>2 Week History</Button>
      <Button size='small' selected={!interval.recent} onClick={() => interval.recent ? setInterval({ recent: false, recentInterval: interval.recentInterval, fullInterval: interval.fullInterval }) : null}>2 Year History</Button>
    </DivAlign>
    <KeepMounted isMounted={interval.recent} render={() => <Chart key={'recent-history'} options={getStockChartOptions(true)} chartType='stockchart' />} />
    <KeepMounted isMounted={!interval.recent} render={() => <Chart key={'long-history'} options={getStockChartOptions(false)} chartType='stockchart' />} />
  </>
  : null);
};

export default StockChart;
