// For reference, code based on: https://github.com/abeaudoin2013/react-beautiful-dnd-multi-list-typescript-example/blob/master/src/App.tsx
// https://codesandbox.io/s/40p81qy7v0?file=/index.js:484-866

import React, { useEffect, useState } from 'react';
import { DragDropContext, DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { Dictionary } from 'lodash';
import styled from 'styled-components';
import DroppableCollection from './DroppableCollection';

export type DraggableItemType = {
  id: string;
  content: any;
};

export type DraggableColumnType = {
  id: string;
  title: string;
  itemIds: string[];
};

export type DraggableData = {
  items: Dictionary<DraggableItemType>;
  columns: Dictionary<DraggableColumnType>;
  columnOrder: string[];
};

type ContainerDivProps = {
  minWidth?: number;
};

export const ContainerDiv = styled.div`
  margin: 0px;
  padding: 0px;
  min-width: ${(props: ContainerDivProps) => props.minWidth ? props.minWidth : 200}px;
  border: 1px solid lightgrey;
  border-radius: 5px;
`;

export const TitleDiv = styled.h3`
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  margin: -1px -1px 0px;
  background: gray;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

/**
 * Interface two move items between two lists
 */
export interface IMoveResult<T> {
  source: T[];
  destination: T[];
}

/**
 * Interface copy item from one list to another
 */
export interface ICopyResult<T> {
  source: T[];
  destination: T[];
}

/**
 * Reorder items within a list.
 */
// export const removeArrayElement = <T>(list: T[], removeIndex: number): T[] => {
export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result: T[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

/*
export const reorder = (list: string[], startIndex: number, endIndex: number): string[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};*/

/**
 * Moves an item from one list to another list.
 */
export function move<T>(source: T[], destination: T[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation): IMoveResult<T> {
  const sourceClone = [...source];
  const destClone = [...destination];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {
    source: sourceClone,
    destination: destClone,
  };

  return result;
}

/**
 * Moves an item from one list to another list.
 */
export function copy<T>(source: T[], destination: T[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation): ICopyResult<T> {
  const destClone = Array.from(destination);
  const item = source[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, item);
  return { source, destination: destClone };
}

export type DraggableContextProps = {
  initialData: DraggableData;
  showTitle?: boolean;
  minWidth?: number;
  dragDisabled?: boolean;
  customKey?: string;
  info?: JSX.Element;
  onUpdateOrder?: (updatedItems: Dictionary<string[]>) => void;
};

const DraggableContext: React.FC<DraggableContextProps> = (props: DraggableContextProps) => {
  const { initialData, showTitle, dragDisabled, customKey: key, minWidth, onUpdateOrder } = props;
  const [items, setItems] = useState<Dictionary<string[]>>(Object.assign({}, ...initialData.columnOrder.map((columnId: string) => ({ [columnId]: initialData.columns[columnId].itemIds }))));
  const [data, setData] = useState<DraggableData>(initialData);

  useEffect(() => {
    setItems(Object.assign({}, ...initialData.columnOrder.map((columnId: string) => ({ [columnId]: initialData.columns[columnId].itemIds }))));
    setData(initialData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const getList = (id: string): DraggableItemType[] => {
    return items[id].map((itemId: string) => data.items[itemId]);
  };

  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const update = { ...items };
    if (source.droppableId === destination.droppableId) {
      if (source.index === destination.index) {
        // Item moved to original place, do nothing
        return;
      }
      const updateItems = reorder<string>(
        items[source.droppableId],
        source.index,
        destination.index,
      );

      update[source.droppableId] = updateItems;
    }
    else {
      const resultFromMove:IMoveResult<string> = move<string>(
        items[source.droppableId],
        items[destination.droppableId],
        source,
        destination,
      );

      Object.keys(resultFromMove).forEach((columnId: string) => {
        update[columnId] = resultFromMove[columnId];
      });
    }

    setItems(update);
    if (onUpdateOrder) {
      onUpdateOrder(update);
    }
  };

  return (
    <DragDropContext key={`DragDropContext-${key}`} onDragEnd={onDragEnd}>
      {Object.keys(items).map((columnId: string, index: number) => (
        <ContainerDiv minWidth={minWidth} key={columnId} >
          {showTitle ? <TitleDiv>{data.columns[columnId].title}</TitleDiv> : null}
          <DroppableCollection info={index === 0 ? props.info : undefined } dragDisabled={dragDisabled} key={columnId} items={getList(columnId)} collectionId={columnId} />
        </ContainerDiv>))}
    </DragDropContext>);
};

export default DraggableContext;
