import React, { useEffect, useState } from 'react';
import { DataCollectionFragment, DataCollectionDataFragment, useGetDataCollectionsQuery, useGetDataCollectionsByIdQuery, useAddDataCollectionMutation, MutationAddDataCollectionArgs, DataCollectionInput } from '../../../graphql/generated/graphql';
import { FieldType } from '../../../components/table/DataTypes';
import { ApiProps } from '../Helpers';
import JsxViewResponse from '../JsxViewResponse';
import { dataClientContext, getResponseDataNode, getResponseDataObj, getResponseDataObjs } from '../Api';
import { dataEntryResponseFn } from '../Entry/DataEntry';
import { dataObjectResponseFn } from '../DataObject';

export const dataParentResponse = (keyPath?: string) => {
  return [...getResponseDataObjs(
    [
      'type',
      'uuid',
    ],
    keyPath),
  ];
};

export const dataCollectionDataResponseFn = (keyPath?: string) => {
  return [
    getResponseDataObj('uuid', keyPath),
    getResponseDataNode('parent', dataParentResponse, keyPath),
    ...getResponseDataObjs(
      [
        'ownerUuid',
        'name',
        'description',
        'collectionUuids',
        'objectUuids',
        'entryUuids',
        'dateCreated',
        'active',
        'dateRemoved',
      ],
      keyPath),
  ];
};

export const dataCollectionResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [...dataCollectionDataResponseFn('collections')] },
  },
};

export const dataCollectionByIdResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [
      getResponseDataNode('root', dataCollectionDataResponseFn),
      getResponseDataNode('collections', dataCollectionDataResponseFn),
      getResponseDataNode('objects', dataObjectResponseFn),
      getResponseDataNode('entries', dataEntryResponseFn),
    ]},
  },
};

type DataCollectionsApiProps = ApiProps & {
  includeInactive?: boolean;
};

type DataCollectionsByIdApiProps = ApiProps & {
  collectionUuids: string[];
  includeInactive?: boolean;
};

type AddDataCollectionApiProps = ApiProps & MutationAddDataCollectionArgs;

type UpdateDataCollectionApiProps = AddDataCollectionApiProps & {
  uuid: string;
};

type DeleteDataCollectionApiProps = ApiProps & {
  uuid: string;
};

// Schema objects
type DataCollectionSchemaType = { [collectionUuid: string] : DataCollectionFragment };

// Data Objects
export type DataCollectionDataObjType = DataCollectionDataFragment & {
  mapping: any[];
  fieldName: string;
};

export const GetDataCollections: React.FC<DataCollectionsApiProps> = (props: DataCollectionsApiProps) => {
  const { includeInactive, ...apiProps } = props;
  const [fetchedData, setFetchedData] = useState<any|undefined>(undefined);
  const { data: dataCollections, loading, error } = useGetDataCollectionsQuery({
    variables: { },
    ...dataClientContext,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    console.log('GetDataCollections useEffect');
    console.log(dataCollections);

    if (!dataCollections || !dataCollections.getDataCollections) {
      return;
    }
    const filteredData = includeInactive !== undefined && includeInactive
      ? dataCollections.getDataCollections
      : dataCollections.getDataCollections.filter((d: any) => d.active);
    setFetchedData(filteredData);
    if (props.updateApiResponse) {
      console.log('GetDataCollections useEffect updateApiResponse');
      props.updateApiResponse(filteredData, loading, error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataCollections]); // Empty array ensures that effect is only run on mount and unmount

  /*
  useEffect(() => {
    console.log('GetDataCollections useEffect for loading/error');

    if (props.updateApiResponse) {
      console.log('GetDataCollections useEffect updateApiResponse');
      props.updateApiResponse(filteredData, loading, error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataCollections]); // Empty array ensures that effect is only run on mount and unmount
  */

  console.log('GetDataCollections dataSchema');
  console.log(apiProps);
  console.log(dataCollections);
  if (!props.returnJsx) {
    return null;
  }
  const result = (<JsxViewResponse data={fetchedData} {...apiProps} />);
  console.log('getDataCollections result');
  console.log(result);

  return result;
};

export const GetDataCollectionsById: React.FC<DataCollectionsByIdApiProps> = (props: DataCollectionsByIdApiProps) => {
  const { collectionUuids, ...apiProps } = props;
  const { data: dataCollectionsById } = useGetDataCollectionsByIdQuery({
    variables: { uuids: collectionUuids },
    ...dataClientContext,
  });

  useEffect(() => {
    console.log('GetDataCollectionData useEffect');
    console.log(dataCollectionsById);

    if (!dataCollectionsById || !dataCollectionsById.getDataCollectionsById) {
      return;
    }
    if (props.updateApiResponse) {
      console.log('GetDataCollectionData useEffect updateApiResponse');
      props.updateApiResponse(dataCollectionsById.getDataCollectionsById);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataCollectionsById]); // Empty array ensures that effect is only run on mount and unmount

  console.log('GetDataCollectionData dataSchema');
  console.log(apiProps);
  console.log(dataCollectionsById);
  if (!props.returnJsx) {
    return null;
  }
  const result = (<JsxViewResponse data={dataCollectionsById?.getDataCollectionsById} {...apiProps} />);
  console.log('getDataCollectionData result');
  console.log(result);

  return result;
};

export const AddDataCollection: React.FC<AddDataCollectionApiProps> = (props: AddDataCollectionApiProps) => {
  const { dataCollection, ...apiProps } = props;
  const [addDataCollectionMutation, { data: newCollection, /* loading, */ error }] = useAddDataCollectionMutation({
    variables: { dataCollection: props.dataCollection },
    ...dataClientContext,
  });

  useEffect(() => {
    addCollection(dataCollection);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    console.log('AddDataCollection useEffect');
    console.log(newCollection);

    if (error) {
      console.error(`AddDataCollection useEffect error: ${error}`);
      return;
    }

    if (!newCollection || !newCollection.addDataCollection) {
      return;
    }
    if (props.updateApiResponse) {
      console.log('AddDataCollection useEffect updateApiResponse');
      props.updateApiResponse(newCollection.addDataCollection);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [newCollection]); // Empty array ensures that effect is only run on mount and unmount

  const addCollection = async (collection: DataCollectionInput) => {
    const result = await addDataCollectionMutation({ variables: { dataCollection: collection } });
    console.log('create new collection');
    console.log(result);
  };

  console.log('GetDataCollections dataSchema');
  console.log(apiProps);
  console.log(newCollection);

  return null;
};
