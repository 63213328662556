import React, { useEffect, useState }  from 'react';
import { FeedbackInput, useSubmitFeedbackMutation, SubmitFeedbackMutation } from '../../../graphql/generated/graphql';
import { publicClientContext } from '../Public';

export type SubmitFeedbackProps = {
  feedbackInput: FeedbackInput;
  onUpdate?: (data?: SubmitFeedbackMutation | null, loading?: boolean, error?: any) => any;
};

const SubmitFeedback: React.FC<SubmitFeedbackProps> = (props: SubmitFeedbackProps) => {
  const { feedbackInput, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [submitFeedbackMutation, { data: feedbackData, loading: loadingNew, error: errorNew }] = useSubmitFeedbackMutation({
    ...publicClientContext,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!executed) {
      console.log(`submit feedback`);
      setExecuted(true);
      submitFeedbackMutation({ variables: { feedback: feedbackInput } })
        .then((result: any) => {
          console.log(`done submit feedback ${result}`);
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error submitting feedback`);
          console.log(err);
        });
    }
    if (onUpdate) {
      onUpdate(feedbackData, loadingNew, errorNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [feedbackData, loadingNew, errorNew]);

  return <></>;
};

export default SubmitFeedback;
