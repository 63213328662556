import React from 'react';
import { ModalFields } from '../../modal/RecordModal';
import { getDataEntryTypeToRecordType, RecordType } from '../../table/DataTypes';
import { DataCollectionData, DataCollectionInput, DataEntry, DataEntryInput, DataObject, DataObjectEntry, DataParent, UpdateItemInput } from '../../../graphql/generated/graphql';
import { PlusCircleTwoTone } from '@ant-design/icons';
import CreateCollection from '../Collection/CreateCollection';
import { Guid } from 'guid-typescript';

export const getEntryModalFields = (
  collections: DataCollectionData[],
  onCollectionTrigger: () => void,
  onCollectionSuccess: (data?: any, loading?: boolean, error?: string) => {},
  onCollectionCancel: () => {},
  selectedObject: DataObject | null,
  initialCollection?: DataCollectionData): ModalFields => {
  return {
    pages: [ /*
      {
        entries: {
          ObjectType: { type: RecordType.Dropdown, value: selectedObject ? selectedObject.uuid : undefined, values: objects.map((obj: DataObject) => ({ label: obj.name, value: obj.uuid })), onUpdate: (record: any) => {
            setSelectedObject(objects ? (objects.find((obj: DataObject) => obj.uuid === record) ?? null) : null);
          }},
        },
      }, */
      {
        entries: Object.assign(
          {},
          {
            /* Remove selecting Data Object Type. TODO: Fix as part being able to add additional entry fields
            ObjectType: { fieldName: 'Entry type', required: true, placeholder: 'Select entry type', disabled: true, type: RecordType.Input, value: selectedObject ? selectedObject.name : undefined, values: objects.map((obj: DataObject) => ({ label: obj.name, value: obj.uuid, info: obj.description })), onUpdate: (record: any) => {
              setSelectedObject(objects ? (objects.find((obj: DataObject) => obj.uuid === record) ?? null) : null);
            }}, */
            Title: { placeholder: 'Enter entry name', type: RecordType.Text, required: true },
            Description: { placeholder: 'Enter entry description', type: RecordType.Text },
            Collections: {
              type: collections.length === 0 ? RecordType.TextValue : RecordType.Checkbox,
              value: collections.length === 0
                ? 'There are no Collections in your account'
                : (initialCollection
                    ? [initialCollection]
                    : []),
              values: collections.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)).map((collection: DataCollectionData) => ({ label: collection.name, value: collection.uuid })),
              fieldName: <span>Collections: <CreateCollection
                icon={<PlusCircleTwoTone style={{ marginLeft: '5px' }} />}
                onTrigger={onCollectionTrigger}
                onSuccess={onCollectionSuccess}
                onCancel={onCollectionCancel}
                ordinal={collections.length + 1} />
            </span>,
            },
            DateDue: { placeholder: 'Enter date due', type: RecordType.Date, fieldName: 'Date Due:' },
            DateCompleted: { placeholder: 'Enter date due', type: RecordType.Date, fieldName: 'Date Completed:' },
          },
          ...(selectedObject && selectedObject.entries
            ? selectedObject.entries.map((obj: DataObjectEntry) => ({ [obj.name]: { type: getDataEntryTypeToRecordType(obj.type)?.recordType, inputType: getDataEntryTypeToRecordType(obj.type)?.inputType, info: obj.description, reference: obj.uuid } }))
            : []),
        ),
      },
    ],
  };
};

export const getMockedEntryInputToDataEntry = (entryInput: DataEntryInput, initialEntry?: DataEntry, ownerUuid?: string): DataEntry => {
  const removeCollections = entryInput.dataParent ? entryInput.dataParent.filter((removeCollection: UpdateItemInput) => removeCollection.action === 'remove') : [];
  const parent: DataParent[] = initialEntry && initialEntry.parent
    ? initialEntry.parent.filter((existentParent: DataParent) => !removeCollections.find((remove: UpdateItemInput) => remove.id === existentParent.childUuid))
    : [];
  if (entryInput.dataParent) {
    entryInput.dataParent.forEach((newParent: UpdateItemInput) => {
      if (newParent.action === 'add') {
        parent.push({ type: newParent.itemType, uuid: newParent.id, childUuid: newParent.id, ordinal: newParent.ordinal });
      }
    });
  }

  const entry: DataEntry = {
    dataUuid: entryInput.dataUuid ?? Guid.create().toString(),
    objectUuid: entryInput.objectUuid,
    data: entryInput.data ?? [],
    parent,
    title: entryInput.title ?? '',
    description: entryInput.description,
    instances: [],
    ownerUuid: ownerUuid ?? Guid.create().toString(),
    dateCreated: new Date(),
    dateCompleted: entryInput.dateCompleted ? entryInput.dateCompleted.date : undefined,
    dateDue: entryInput.dateDue ? entryInput.dateDue.date : undefined,
    active: entryInput.active,
  };
  return entry;
};

export const getMockedCollectionInputToDataCollection = (collectionInput: DataCollectionInput, ownerUuid?: string): DataCollectionData => {
  const dataCollectionResult: DataCollectionData = {
    uuid: collectionInput.uuid ?? Guid.create().toString(),
    ownerUuid: ownerUuid ?? Guid.create().toString(),
    name: collectionInput.name ?? '',
    description: collectionInput.description,
    ordinal: collectionInput.ordinal,
    collectionUuids: [],
    entryUuids: [],
    objectUuids: [],
  };
  return dataCollectionResult;
};
