import { PlusCircleTwoTone } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useState } from 'react';
import Button from '../atomic/Button';
import Dropdown, { DropdownType } from '../atomic/Dropdown';
import Popconfirm from '../atomic/Popconfirm';

export enum ViewFilter {
  All = 'All items',
  NoDue = 'Items without due date',
  Due = 'Items with due date',
  OverDue = 'Items that are overdue',
  Completed = 'Completed items',
  Today = 'Items due today',
  TodayPastDue = 'Items due today or past due',
  ThisWeek = 'Items due this week',
  ThisWeekPastDue = 'Items due this week or past due',
  DeletedItems = 'Deleted items',
}

export enum CollectionSort {
  Alphabetical = "Alphabetical",
  // Custom = 'Custom order',
}

export type CollectionGroup = {
  name: string;
  show: boolean;
  uuids: string[];
};

export type CollectionSettings = {
  sortBy: CollectionSort;
  filter: CollectionGroup[];
  hiddenCollections: string[];
  showHidden: boolean;
};

type CollectionFilterProps = {
  icon: JSX.Element;
  settings: CollectionSettings;
  onUpdateSetting: (updateSetting: CollectionSettings) => void;
};

const CollectionFilter: React.FC<CollectionFilterProps> = (props: CollectionFilterProps) => {
  const { icon, settings, onUpdateSetting } = props;
  const [setting, setSetting] = useState<CollectionSettings>(settings);
  const [createGroup, setCreateGroup] = useState<boolean>(false);
  const [showSorting] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);

  return (
    <Popconfirm
      header={"Configure Filter"}
      icon={null}
      title={<>
        {showSorting
          ? <>
            <div style={{ fontWeight: 'bold' }}>Sort By:</div>
              <div>
                <Dropdown
                  overlayStyle={{ zIndex: 2000 }}
                  menuItems={Object.keys(CollectionSort).map((item: string) => ({ name: item, component: CollectionSort[item] })) }
                  dropdownType={[DropdownType.Click]}
                  onClick={(item: any) => {
                    if (CollectionSort[item]) {
                      const updateSettings = { ...settings };
                      updateSettings.sortBy = CollectionSort[item];
                      setSetting(updateSettings);
                      setUpdated(true);
                    }
                  }}
                >
                  <Button key={`filter-${setting.filter}`} style={{ width: '300px', textAlign: 'left' }}>{setting.sortBy}</Button>
                </Dropdown>
              </div>
            </>
          : null}
        <div style={{ fontWeight: 'bold' }}>Select collection groups to show: <PlusCircleTwoTone style={{ marginLeft: '5px' }} disabled={createGroup} onClick={() => setCreateGroup(true)} /></div>
        <div>
          <Dropdown
            overlayStyle={{ zIndex: 2000 }}
            menuItems={Object.keys(ViewFilter).map((item: string) => ({ name: item, component: ViewFilter[item] })) }
            dropdownType={[DropdownType.Click]}
            onClick={(item: any) => {
              if (ViewFilter[item]) {
                const updateSettings = { ...settings };
                updateSettings.filter = ViewFilter[item];
                setSetting(updateSettings);
                setUpdated(true);
              }
            }}
          >
            <Button key={`sort-by-${setting.filter}`} style={{ width: '300px', textAlign: 'left' }}>{setting.filter}</Button>
          </Dropdown>
        </div>
        <div>
          <Checkbox
            checked={setting.showHidden}
            onChange={(value: any) => {
              const updateSettings = { ...settings };
              updateSettings.showHidden = value.target.checked;
              setSetting(updateSettings);
              setUpdated(true);
            }}>Show hidden collections</Checkbox>
        </div>
      </>}
      onConfirm={() => {
        if (updated) {
          onUpdateSetting(setting);
        }
      }}
      onCancel={() => {
        setSetting(settings);
        setUpdated(false);
      }}
      okText='Done'
      cancelText='Cancel'
    >
      {icon}
    </Popconfirm>
  );
};

export default CollectionFilter;
