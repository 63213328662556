import React, { useEffect, useState } from 'react';
import { PlaidAccount } from '../../graphql/generated/graphql';
import { CenteredDiv } from '../styled/CenteredDiv';
import { RecordType } from '../table/DataTypes';
import { EditableColumnProps } from '../table/EditableTable';
import { Dictionary } from 'lodash';
import Table from '../table/Table';
import { BrokerStockData, getBrokerStockDataFlattened, LastPrice, Stock, StockLotMapping, StockOrder, StockTransaction, TransactionAction } from './StockTypes';
import Dropdown, { DropdownType } from '../atomic/Dropdown';
import MenuIcon from '../icons/MenuIcon';
import AddStockData, { StockDataType } from './AddStockData';

type OrderData = {
  key: string;
  row: number;
  broker: string;
  symbol: string;
  name: string;
  account: string;
  quantity: number;
  limit_price: string;
  current_price: string;
  percent_away: string;
  lots?: StockLotMapping[];
  state?: string;
  order: StockOrder;
};

type ViewOrdersProps = {
  existingTransactions: BrokerStockData<StockTransaction>;
  existingOrders: BrokerStockData<StockOrder>;
  brokerAccounts: Dictionary<Dictionary<PlaidAccount>>;
  stockDetails: Dictionary<{ details: Stock, lastPrice?: LastPrice }>;
  readOnly?: boolean;
  onUpdateOrders?: (updateStock?: { transactions: StockTransaction[], orders: StockOrder[] }, deleteStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => void;
};
const ViewOrders: React.FC<ViewOrdersProps> = (props: ViewOrdersProps) => {
  const { existingTransactions, existingOrders, brokerAccounts, stockDetails, readOnly, onUpdateOrders } = props;
  const [allOrders, setAllOrders] = useState<StockOrder[]>([]);
  const [editOrder, setEditOrder] = useState<StockOrder | undefined>(undefined);
  const [deleteOrder, setDeleteOrder] = useState<StockOrder | undefined>(undefined);

  useEffect(() => {
    const initOrders: StockOrder[] = getBrokerStockDataFlattened(existingOrders).sort((a: StockOrder, b: StockOrder) => {
      if (a.action === TransactionAction.BuyOrder) {
        return b.action === TransactionAction.SellOrder
          ? 1
          : (a.price > b.price ? 1 : -1);
      }
      return b.action === TransactionAction.BuyOrder
        ? -1
        : (a.price > b.price ? 1 : -1);
    });
    if (allOrders.length !== initOrders.length) {
      setAllOrders(initOrders);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [existingOrders]);

  const mappedStock = undefined;

  const noOrders = <>
      <CenteredDiv>There are no known orders in your accounts.</CenteredDiv>
    </>;

  const filters: Dictionary<string[]> =
    {
      broker: [] as string[],
      account: [] as string[],
      symbol: [] as string[],
    };

  const ordersData: any[] = [];
  let orderIndex = 1;
  allOrders
    .forEach((order: StockOrder) => {
      const security = order.symbol && stockDetails[order.symbol]
      ? {
        symbol: stockDetails[order.symbol].details.ticker,
        name: stockDetails[order.symbol].details.name,
      }
      : {
        symbol: '--',
        name: '--',
      };
      const lastPrice = stockDetails[order.symbol].lastPrice;
      const limitPrice = order.price;
      const currentPrice = lastPrice ? Number.parseFloat(lastPrice.price.last) : null;
      const priceAway = currentPrice ? (limitPrice - currentPrice) / currentPrice * 100 : null;
      const orderData: OrderData = {
        key: order.uuid,
        row: orderIndex,
        broker: order.broker,
        symbol: security.symbol,
        name: security.name,
        account: order.accountId && brokerAccounts[order.broker] && brokerAccounts[order.broker][order.accountId] ? (brokerAccounts[order.broker][order.accountId].name ?? '--') : '--',
        quantity: order.quantity,
        limit_price: limitPrice.toFixed(2),
        current_price: currentPrice ? currentPrice.toFixed(2) : '--',
        percent_away: priceAway ? priceAway.toFixed(2) : '--',
        lots: order.lots,
        order,
      };
      if (!filters['symbol'].find((symbol: string) => symbol === security.symbol)) {
        filters['symbol'].push(security.symbol ?? '--');
      }

      if (!filters['account'].find((account: string) => account === order.accountId)) {
        filters['account'].push(order.accountId);
      }
      ordersData.push(orderData);
      orderIndex += 1;
    });

  const ordersColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      render: (text: string, record: any, index: number) => (
        <div>{index + 1}</div>
      ),
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      filters: filters['broker'].map((broker: string) => ({ text: broker, value: broker })),
      onFilter: (value, record) => {
        return record.broker.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.broker.toLowerCase() < two.broker.toLowerCase() ? -1 : 1);
        },
        multiple: 1,
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      filters: filters['account'].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        return record.account.indexOf(value) === 0;
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      filters: ['crypto', 'stocks', ...filters['symbol'].sort((a: string, b: string) => a < b ? -1 : 1)].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        if (value === 'crypto') {
          return record.symbol.includes('CUR:');
        }
        if (value === 'stocks') {
          return !record.symbol.includes('CUR:');
        }
        return record.symbol.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return one.symbol && two.symbol ? (one.symbol.toLowerCase() < two.symbol.toLowerCase() ? -1 : 1) : (one.symbol ? -1 : 1);
        },
        multiple: 2,
      },
      inputType: { recordType: RecordType.Text },
      defaultSortOrder: 'ascend',
    },
    /*
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (one, two) => {
          return one.name && two.name ? (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1) : (one.name ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => (
        record.name && record.name.length > 50 ? <Tooltip title={record.name}>
        <div>{record.name.substring(0, 50)}...</div>
        </Tooltip> : <div>{record.name}</div>
      ),
    },
    */
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Limit Price',
      dataIndex: 'limit_price',
      key: 'limit_price',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Current Price',
      dataIndex: 'current_price',
      key: 'current_price',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Percent Away',
      dataIndex: 'percent_away',
      key: 'percent_away',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Edit',
      key: 'edit',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: any, index: number) => {
        const menuOptions: { name: string, component: string }[] = []; // /*'Edit', 'Delete', */
        menuOptions.push({ name: 'edit', component: `Edit manually added order` });
        menuOptions.push({ name: 'delete', component: `Delete manually added order` });
        return menuOptions.length > 0 ? <Dropdown
          menuItems={menuOptions}
          dropdownType={[DropdownType.Click]}
          onClick={(item: any) => {
            if (item === 'edit') {
              setEditOrder(record.order);
            }
            else if (item === 'delete') {
              setDeleteOrder(record.order);
            }
          }} >
            <MenuIcon />
          </Dropdown> : null;
      },
    },
  ];

  const onEditOrder = (updateStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => {
    if (onUpdateOrders) {
      onUpdateOrders(updateStock);
    }
    setEditOrder(undefined);
  };

  const onDeleteOrder = (deleteStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => {
    if (onUpdateOrders) {
      onUpdateOrders(undefined, deleteStock);
    }
    setDeleteOrder(undefined);
  };

  return <>
    {editOrder ? <AddStockData dataType={editOrder.action === TransactionAction.BuyOrder ? StockDataType.BuyOrder : StockDataType.SellOrder} accounts={brokerAccounts} transactions={{}} editOrder={editOrder} stockDetails={{}} onCancel={() => setEditOrder(undefined)} onSuccess={onEditOrder} /> : null}
    {deleteOrder ? <AddStockData dataType={deleteOrder.action === TransactionAction.BuyOrder ? StockDataType.BuyOrder : StockDataType.SellOrder} accounts={brokerAccounts} transactions={{}} deleteOrder={deleteOrder} stockDetails={{}} onCancel={() => setDeleteOrder(undefined)} onSuccess={onDeleteOrder} /> : null}

    <CenteredDiv>
      <CenteredDiv bold>Orders{mappedStock ? ` for mapped stock ${mappedStock}` : ''}:</CenteredDiv>
      {allOrders.length > 0 ? <>
        <Table
          pagination={false}
          className='.antd-documents-table'
          size='small'
          // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
          columns={ordersColumns}
          dataSource={ordersData}
          bordered={true}
        /></> : noOrders}
    </CenteredDiv>
  </>;
};

export default ViewOrders;
