import React, { useState } from 'react';
import moment from 'moment';
import { PlaidAccount, StockHistoryPrice } from '../../graphql/generated/graphql';
import StockChart, { HoldingHistory } from '../chart/StockChart';
import CloseIcon from '../icons/CloseIcon';
import { CenteredDiv, StyledSpan } from '../styled/CenteredDiv';
import { dateFormat } from '../table/DataTypes';
import { BrokerStockData, LastPrice, Stock, StockHistory, StockHolding, StockTransaction, StockOrder, UpdateStockEntryData } from './StockTypes';
import ViewHoldings from './ViewHoldings';
import { Dictionary } from 'lodash';
import ViewTransactionHistory from './ViewTransactionHistory';
import DivAlign, { DivPosition } from '../atomic/DivAlign';
import { ExclamationCircleOutlined, StarFilled } from '@ant-design/icons';
import FindStock from './FindStock';
import { DefaultStockList } from './StockList';

enum HistoryInterval {
  Day1 = '1 Day',
  Week1 = '1 Week',
  Month1 = '1 Month',
  Month3 = '3 Months',
  Month6 = '6 Months',
  Year1 = '1 Year',
  YTD = 'Year To Date',
}

enum TransactionType {
  Track = 0,
  BuyOrder,
  Bought,
  SellOrder,
  Sold,
}

type TrackStock = {
  fallTriggerPrice?: number;
  riseTriggerPrice?: number;
};

type StockBuy = {
  uuid: string;
  price: number;
  units: number;
  fee?: number;
};

type StockSell = {
  price: number;
  units: number;
  fee: number;
  matchBuy: { uuid: string; units: number };
};

type StockTransactions = {
  uuid: string;
  type: TransactionType;
  createdDate: Date;
  expirationDate?: Date;
  symbol: string;
  track?: TrackStock;
  order?: StockOrder;
  buy?: StockBuy;
  sell?: StockSell;
};

type StockDetailProps = {
  ticker?: string;
  stock: { details?: Stock, lastPrice?: LastPrice, weekHistory?: StockHistory, yearHistory?: StockHistory, lists?: Dictionary<string[]>, mappedStock?: string };
  investments: { holdings: BrokerStockData<StockHolding>, transactions: BrokerStockData<StockTransaction>, orders: BrokerStockData<StockOrder>, accounts: Dictionary<Dictionary<PlaidAccount>> }
  mappedInvestments?: { holdings: BrokerStockData<StockHolding>, transactions: BrokerStockData<StockTransaction>, orders: BrokerStockData<StockOrder> }
  trackedStocks?: Dictionary<Stock>;
  onRefreshHistory: (symbol: string) => void;
  onClose: () => void;
  onMapUnknownSymbol: (existingSymbol: string, mappedSymbol: string) => void;
  onGoToMappedStock: (mappedStock: string) => void;
  onUpdateTransactions: (update: Dictionary<UpdateStockEntryData>) => void;
  onUpdateLists: (symbol: string, lists: string[]) => void;
};
const StockDetail: React.FC<StockDetailProps> = (props: StockDetailProps) => {
  const { ticker, stock, investments, mappedInvestments, onRefreshHistory, onClose, onMapUnknownSymbol, onGoToMappedStock, onUpdateTransactions, onUpdateLists } = props;
  const [chartPeriod] = useState<HistoryInterval>(HistoryInterval.Year1);
  // const [transactionHistory, setTransactionHistory] = useState<StockTransactions[]>([]);

  const symbolLists = stock.lists && ticker ? Object.keys(stock.lists).filter((listName: string) => stock.lists![listName].includes(ticker)) : [];
  const listNames = stock.lists ? Object.keys(stock.lists) : [];
  const trackedStocks = props.trackedStocks ?? {};

  const unknownSymbol = stock.details && stock.details.isUnknown;
  const mappedStock = stock && stock.details && stock.details.mappedSymbols && stock.details.mappedSymbols.length > 0 ? stock.details.mappedSymbols[0] : undefined;

  const getStockHistoryTime = (duration?: HistoryInterval): StockHistoryPrice[] => {
    const chartDuration = duration ? duration : chartPeriod;
    if (!ticker || !stock.lastPrice || !stock.weekHistory || stock.weekHistory.history.length === 0) {
      return [];
    }
    if (chartDuration === HistoryInterval.Day1) {
      const lastDay = new Date(stock.weekHistory.history[stock.weekHistory.history.length - 1].date);
      const dayData = stock.weekHistory.history.filter((value: StockHistoryPrice) => {
        const thisDate = new Date(value.date);
        return thisDate.getMonth() === lastDay.getMonth() && thisDate.getDate() === lastDay.getDate();
      });
      console.log(dayData);
      return dayData;
    }
    if (chartDuration === HistoryInterval.Week1) {
      return stock.weekHistory.history;
    }
    if (!stock.yearHistory || stock.yearHistory.history.length === 0) {
      return [];
    }
    const lastDay = new Date(stock.yearHistory.history[stock.yearHistory.history.length - 1].date);
    const now = moment(moment(lastDay).format(dateFormat));
    if (chartDuration === HistoryInterval.Month1) {
      const monthData = stock.yearHistory.history.filter((value: StockHistoryPrice) => {
        const thisDate = moment(moment(new Date(value.date)).format(dateFormat));
        const diffDays = now.diff(thisDate, 'days');
        return diffDays < 31;
      });
      return monthData;
    }
    if (chartDuration === HistoryInterval.Month3) {
      const month3Data = stock.yearHistory.history.filter((value: StockHistoryPrice) => {
        const thisDate = moment(moment(new Date(value.date)).format(dateFormat));
        const diffDays = now.diff(thisDate, 'days');
        return diffDays < 90;
      });
      return month3Data;

    }
    if (chartDuration === HistoryInterval.Month6) {
      const month6Data = stock.yearHistory.history.filter((value: StockHistoryPrice) => {
        const thisDate = moment(moment(new Date(value.date)).format(dateFormat));
        const diffDays = now.diff(thisDate, 'days');
        return diffDays < 183;
      });
      return month6Data;
    }
    if (chartDuration === HistoryInterval.YTD) {
      const lastDay = new Date(stock.yearHistory.history[stock.yearHistory.history.length - 1].date);
      const ytdData = stock.yearHistory.history.filter((value: StockHistoryPrice) => {
        const thisDate = new Date(value.date);
        return thisDate.getFullYear() === lastDay.getFullYear();
      });
      return ytdData;
    }
    return stock.yearHistory ? stock.yearHistory.history : [];
  };

  const getStockChart = (symbol: string) => {
    if (stock.details && stock.details.isUnknown) {
      return <></>;
    }
    const recentHistory = getStockHistoryTime(HistoryInterval.Week1);
    // const first = recentHistory[0].date;
    const longHistory = getStockHistoryTime(HistoryInterval.Year1); // .filter((historyPrice: StockHistoryPrice) => historyPrice.date < first);
    const fullHistory = { shortLastFetched: stock.weekHistory?.lastFetched, longLastFetched: stock.yearHistory?.lastFetched,  short: recentHistory, long: longHistory };
    // const combinedHistory = [...longHistory, ...recentHistory];
    // return <StockChart symbol={symbol} data={combinedHistory} isCrypto={stock.details && stock.details.isCrypto} />;
    const allTransactions: StockTransaction[] = [];
    const processTransactions = (mappedInvestments ? mappedInvestments.transactions : investments.transactions) ?? [];
    Object.keys(processTransactions).forEach((broker: string) => Object.keys(processTransactions[broker]).forEach((account: string) => allTransactions.push(...processTransactions[broker][account])));
    const descendingTransactions = allTransactions.sort((a: StockTransaction, b: StockTransaction) => moment(a.date) < moment(b.date) ? -1 : 1);
    let quantity = 0;
    const holdingHistory: HoldingHistory[] = descendingTransactions.map((transaction: StockTransaction) => {
      quantity = quantity + transaction.quantity;
      return { startDate: moment(transaction.date).toDate(), quantity };
    });

    return stock.weekHistory ? <StockChart symbol={symbol} history={fullHistory} holdingHistory={holdingHistory} isCrypto={stock.details && stock.details.isCrypto} onRefreshData={() => onRefreshHistory(symbol)} /> : <></>;
  };

  const onMergeTransactions = (update?: { transactions: StockTransaction[], orders: StockOrder[] }, remove?: { transactions: StockTransaction[], orders: StockOrder[] }) => {
    const result: Dictionary<UpdateStockEntryData> = {};
    if (update) {
      update.transactions.forEach((transaction: StockTransaction) => {
        if (!result[transaction.symbol]) {
          result[transaction.symbol] = {};
        }
        if (!result[transaction.symbol].transactions) {
          result[transaction.symbol].transactions = [];
        }
        result[transaction.symbol].transactions!.push(transaction);
      });
      update.orders.forEach((order: StockOrder) => {
        if (!result[order.symbol]) {
          result[order.symbol] = {};
        }
        if (!result[order.symbol].orders) {
          result[order.symbol].orders = [];
        }
        result[order.symbol].orders!.push(order);
      });
    }
    if (remove) {
      remove.transactions.forEach((transaction: StockTransaction) => {
        if (!result[transaction.symbol]) {
          result[transaction.symbol] = {};
        }
        if (!result[transaction.symbol].removeTransactions) {
          result[transaction.symbol].removeTransactions = [];
        }
        result[transaction.symbol].removeTransactions!.push(transaction);
      });
      remove.orders.forEach((order: StockOrder) => {
        if (!result[order.symbol]) {
          result[order.symbol] = {};
        }
        if (!result[order.symbol].removeOrders) {
          result[order.symbol].removeOrders = [];
        }
        result[order.symbol].removeOrders!.push(order);
      });
    }
    if (Object.keys(result).length === 0) {
      return;
    }
    // result[update.transactions[0].symbol] = { transactions: update.transactions, orders: update.orders, removeTransactions: remove ? remove.transactions : undefined, removeOrders: remove ? remove.orders : undefined };
    onUpdateTransactions(result);
  };

  const isStarred = symbolLists.includes(DefaultStockList.Starred);

  return <>
    <DivAlign hPosition={DivPosition.Right} absolute>
      <CloseIcon onClick={() => {
        onClose();
      }} />
    </DivAlign>
    <CenteredDiv bold>
      <StarFilled
        style={{ color: symbolLists.length > 0 ? (isStarred ? 'gold' : 'lightgreen') : 'gray' }}
        onClick={() => {
          if (!stock.lists || !ticker) {
            return;
          }
          if (!stock.lists[DefaultStockList.Starred]) {
            stock.lists[DefaultStockList.Starred] = [];
          }
          const updateStarredList: string[] = isStarred ? symbolLists.filter((remove: string) => remove !== DefaultStockList.Starred) : [...symbolLists, DefaultStockList.Starred];
          onUpdateLists(ticker, updateStarredList);
        }}/> [{ticker}]{ticker && stock.details ? ` ${stock.details.name}` : ''}</CenteredDiv>
    <br />
    {unknownSymbol ? (stock.mappedStock
      ? <div style={{ color: 'red' }}><ExclamationCircleOutlined /> Symbol details for {ticker} do not exist. This stock data is mapped to <span style={{ color: 'blue' }} onClick={() => onGoToMappedStock(stock.mappedStock ?? ticker ?? '')}>{stock.mappedStock}</span></div>
      : <div style={{ color: 'red' }}><ExclamationCircleOutlined /> Symbol details for {ticker} not found. You can fix this by selecting correct symbol: <FindStock
      disabled={false}
      trackedStocks={trackedStocks}
      onSelectTicker={(updateSelectedTicker: string) => {
        if (ticker) {
          onMapUnknownSymbol(ticker, updateSelectedTicker);
        }
      }}
    /></div>) : null}
    {stock.details
      ? <ViewHoldings
        key={`view-holdings-${stock.details.ticker}`}
        existingHoldings={investments.holdings}
        existingOrders={investments.orders}
        existingTransactions={investments.transactions}
        brokerAccounts={investments.accounts}
        stockDetails={({ [stock.details.ticker] : stock.details })}
        /* securities={investments.securities} */
        lastPrices={stock.lastPrice ? ({ [stock.details.ticker] : stock.lastPrice }) : undefined}
        readOnly /> : null}
    {stock.details && mappedStock && mappedInvestments
      ? <ViewHoldings
        key={`view-holdings-${stock.details.ticker}`}
        mappedStock={mappedStock}
        existingHoldings={mappedInvestments.holdings}
        existingTransactions={mappedInvestments.transactions}
        existingOrders={mappedInvestments.orders}
        brokerAccounts={investments.accounts}
        stockDetails={({ [stock.details.ticker] : stock.details })}
        /* securities={investments.securities} */
        lastPrices={stock.lastPrice ? ({ [stock.details.ticker] : stock.lastPrice }) : undefined}
        readOnly /> : null}

    {stock.lastPrice && ticker
    ? getStockChart(ticker) /* <>
        <Select
          defaultValue={[chartPeriod]}
          items={{ values: Object.keys(HistoryInterval).map((time: string) => ({ uuid: time, name: HistoryInterval[time] })) }}
          onSelect={(value: string) => {
            setChartPeriod(HistoryInterval[value]);
          }}/>
        {getStockChart(ticker)}
    </> */
    : undefined}
    {stock.details
      ? <ViewTransactionHistory
        key={`view-transaction-history-${stock.details.ticker}`}
        transactionHistory={investments.transactions}
        brokerAccounts={investments.accounts}
        stockDetails={({ [stock.details.ticker] : stock.details })}
        onUpdateTransactions={onMergeTransactions}
        /* securities={investments.securities} */
        readOnly />
      : null}
    {stock.details && mappedStock && mappedInvestments
      ? <ViewTransactionHistory
        key={`view-transaction-history-${stock.details.ticker}`}
        mappedStock={mappedStock}
        mappedSymbol={ticker}
        transactionHistory={mappedInvestments.transactions}
        brokerAccounts={investments.accounts}
        stockDetails={({ [stock.details.ticker] : stock.details })}
        onUpdateTransactions={onMergeTransactions}
        /* securities={investments.securities} */
        readOnly /> : null}
    {ticker && stock.details ? <div><br /><StyledSpan bold>Company description:</StyledSpan> {stock.details.description}</div> : null}
  </>;
  /*
  (ticker && stock.lastPrice
    ? <div style={{ width: '50%', padding: '10px' }}>
      <Select
        defaultValue={[chartPeriod]}
        items={{ values: Object.keys(HistoryInterval).map((time: string) => ({ uuid: time, name: HistoryInterval[time] })) }}
        onSelect={(value: string) => {
          setChartPeriod(HistoryInterval[value]);
        }}/>
      {getStockChart(ticker)}
    </div>
    : null
  );
  */
};

export default StockDetail;
