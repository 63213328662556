import gql from 'graphql-tag';
import DataEntryData from './DataEntryData';
import DataEntryInstance from './DataEntryInstance';

export default gql`
  fragment DataEntry on DataEntry {
    dataUuid
    objectUuid
    title
    description
    parent {
      type
      uuid
      childUuid
      ordinal
    }
    data
    {
      ...DataEntryData
    }
    instances
    {
      ...DataEntryInstance
    }
    dateCreated
    dateDue
    dateCompleted
    active
    dateRemoved
    hideFields
  }
  ${DataEntryData}
  ${DataEntryInstance}
`;
