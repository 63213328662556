import React, { useEffect } from 'react';
import { FieldType } from '../../components/table/DataTypes';
import { ApiProps } from './Helpers';
import JsxViewResponse from './JsxViewResponse';
import { useGetUserQuery } from '../../graphql/generated/graphql';
import logger from '../../utils/Logger';

export const provisionClientContext = { context: { clientName: 'provision' } };
const logInfo = (message: any) => logger.info({ source: 'containers.api.Provision', message });
const logInfoJson = (message: any) => logger.info({ source: 'containers.api.Provision', message: JSON.stringify(message) });

type ProvisionApiProps = ApiProps & {
};

export const GetUser: React.FC<ProvisionApiProps> = (props: ProvisionApiProps) => {
  const { ...apiProps } = props;
  const { data: userData } = useGetUserQuery({
    variables: { },
    ...provisionClientContext,
  });

  useEffect(() => {
    logInfo('GetUser useEffect');
    logInfoJson(userData);

    if (!userData || !userData.getUser) {
      return;
    }

    if (props.updateApiResponse) {
      logInfo('GetUser useEffect updateApiResponse');
      props.updateApiResponse(userData.getUser);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [userData]);

  logInfo('GetUser data');
  logInfoJson(apiProps);
  logInfoJson(userData);

  const result = userData ? (<JsxViewResponse data={userData?.getUser} {...apiProps} />) : null;
  logInfo('GetUser result');
  if (!apiProps.returnJsx) {
    return null;
  }
  return userData ? result : <div>{'No Data Loaded'}</div>; // apiProps.returnJsx ? result : null;
};

export const userResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [...[
      'uuid',
      'firstName',
      'lastName',
      'emailAddress',
      'dateCreated'].map((o: string) => ({ title: `${o}`, key: `${o}` })),
    ]},
  },
};

export const provisionApiDefinition = {
  user: {
    getUser: {
      function: (apiProps: ApiProps) => {
        console.log('provisionApiDefinition.getUser.function');
        console.log(apiProps);
        return <React.Fragment><GetUser {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
        },
      },
      response: userResponse,
    },
  },
};
