import { EventInput } from "@fullcalendar/react";
import moment from "moment";
import { DataCollectionData, DataEntry } from "../../graphql/generated/graphql";
import { dateFormat } from "../table/DataTypes";

export function entryToEvents(entries: DataEntry[], collections: DataCollectionData[]): EventInput[] {
  const now = moment(moment(new Date()).format(dateFormat));
  return entries.filter((entry: DataEntry) => entry.dateDue || entry.dateCompleted).map((entry: DataEntry) => {
    const dueDate = entry.dateDue ? moment(moment(entry.dateDue).format(dateFormat)) : undefined;
    const diffDays = entry.dateDue ? now.diff(dueDate, 'days') : 0;
    const isLate = entry.dateCompleted === undefined && diffDays > 0;
    return {
      id: entry.dataUuid,
      title: entry.title,
      start: entry.dateDue ? new Date(entry.dateDue).toISOString().replace(/T.*$/, '') : new Date(entry.dateCompleted).toISOString().replace(/T.*$/, ''),
      backgroundColor: isLate ? 'red' : (entry.dateCompleted ? 'green' : 'blue'),
    };
  });
}
