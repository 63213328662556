import styled from "styled-components";
import { DeviceType, getDeviceTypeInfo } from "./ScreenSize";

export type CenteredDivProps = {
  maxWidth?: number | string;
  bold?: boolean;
};

export type ResponsiveDivProps = {
  isMobile?: boolean;
  maxWidth?: number | string;
  isCentered?: boolean;
  breakWord?: boolean;
  isOverlfowScroo?: boolean;
  isModal?: boolean;
};

export const CenteredDiv = styled.div`
  ${(props: CenteredDivProps) => props.maxWidth ? `max-width: ${isNaN(Number(props.maxWidth)) ? props.maxWidth : `${props.maxWidth}px`};` : null}
  ${(props: CenteredDivProps) => props.bold ? 'font-weight: bold;' : null}
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export type StyledSpanProps = {
  bold?: boolean;
  inline?: boolean;
  color?: string;
};

export const StyledSpan = styled.span`
  ${(props: StyledSpanProps) => props.bold ? 'font-weight: bold;' : null}
  ${(props: StyledSpanProps) => props.inline ? 'display: inline-block;' : null}
  ${(props: StyledSpanProps) => props.color ? `color: ${props.color}` : null}
`;

export const StyledDiv = styled.div`
  ${(props: StyledSpanProps) => props.bold ? 'font-weight: bold;' : null}
  ${(props: StyledSpanProps) => props.color ? `color: ${props.color}` : null}
`;

export const ResponsiveDiv = styled.div`
  ${(props: ResponsiveDivProps) => props.isCentered ? 'text-align: center;' : null}
  ${(props: ResponsiveDivProps) => props.breakWord ? `word-wrap: break-word;
  overflow-wrap: break-word;` : null}
  ${(props: ResponsiveDivProps) => props.isModal ? 'max-width: 520px;' : (props.maxWidth ? `max-width: ${isNaN(Number(props.maxWidth)) ? props.maxWidth : `${props.maxWidth}px`};` : null)}
  ${(props: ResponsiveDivProps) => props.isOverlfowScroo ? 'overflow: scroll;' : null}
  display: block;
  position: relative;
  width: calc(100vw - ${(props: ResponsiveDivProps) => props.isMobile || getDeviceTypeInfo().deviceType === DeviceType.Mobile ? '30px' : '60px'});
  margin-left: ${(props: ResponsiveDivProps) => props.isMobile || getDeviceTypeInfo().deviceType === DeviceType.Mobile ? '5px' : props.maxWidth ? 'auto' : '30px'};
  margin-right: ${(props: ResponsiveDivProps) => props.isMobile || getDeviceTypeInfo().deviceType === DeviceType.Mobile ? '5px' : props.maxWidth ? 'auto' : '30px'};
`;
