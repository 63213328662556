import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Dictionary } from 'lodash';
import { PlaidAccount } from '../../graphql/generated/graphql';
import { CenteredDiv } from '../styled/CenteredDiv';
import { RecordType } from '../table/DataTypes';
import { EditableColumnProps } from '../table/EditableTable';
import Table from '../table/Table';
import { BrokerStockData, getBrokerStockDataFlattened, getSymbolStockData, Stock, StockLotMapping, StockMergedTransaction, StockOrder, StockTransaction, SymbolStockData } from './StockTypes';
import MenuIcon from '../icons/MenuIcon';
import Dropdown, { DropdownType } from '../atomic/Dropdown';
import { errorNotification, infoNotification } from '../actions/Notification';
import AddStockData, { StockDataType } from './AddStockData';
import MergeTransactions from './MergeTransactions';
import ExclamationIcon from '../icons/ExlamationIcon';
import CloseIcon from '../icons/CloseIcon';
import Tooltip from '../atomic/Tooltip';
import { getUrl, StockReferenceUrlType } from './StockLinks';
import ViewTransactionsFilter, { TransactionColumns, ViewTransactionsSettings } from './ViewTransactionsFilter';
import { getPrice } from '../../helper/PriceHelpers';

export const toFixedNumber = (value: number, precision: number, hover?: boolean) => {
  const valueStr = value.toString();
  const decimalIndex = valueStr.indexOf('.');
  const initPrecision = decimalIndex < 0 ? 0 : valueStr.length - decimalIndex;
  if (initPrecision < precision) {
    return value;
  }
  const newValue = value.toFixed(precision);
  return hover ? <Tooltip title={value}>{newValue}</Tooltip> : newValue;

};

type TransactionData = {
  buyUuid?: string;
  sellUuid?: string;
  key: number;
  row: number;
  broker: string;
  account: string;
  symbol: string;
  name: string;
  date?: string;
  sellDate?: string;
  quantity?: number;
  sellQuantity?: number;
  price?: number;
  sellPrice?: number;
  amount?: number;
  sellAmount?: number;
  fees?: number;
  type?: string;
  subtype?: string;
  sellLots?: StockTransaction[];
  transaction: StockTransaction;
  matchingSellTransaction?: StockTransaction;
  merge?: boolean;
  skipRow?: boolean;
};

export type TransactionHistoryItem = {
  buyDate: Date;
  transactionIds: string[];
  sell: { sellDate: Date; transactionIds: String[] }[];
};

type ViewTransactionHistoryProps = {
  transactionHistory: BrokerStockData<StockTransaction>;
  brokerAccounts: Dictionary<Dictionary<PlaidAccount>>;
  stockDetails: Dictionary<Stock>;
  // securities: Dictionary<Dictionary<PlaidSecurity>>
  mappedStock?: string;
  mappedSymbol?: string;
  readOnly?: boolean;
  onUpdateTransactions?: (update?: { transactions: StockTransaction[], orders: StockOrder[] }, remove?: { transactions: StockTransaction[], orders: StockOrder[] }) => void;
};
const ViewTransactionHistory: React.FC<ViewTransactionHistoryProps> = (props: ViewTransactionHistoryProps) => {
  const { transactionHistory, brokerAccounts, stockDetails, mappedStock, mappedSymbol, onUpdateTransactions, /* securities, */ readOnly } = props;
  const [buySellMapping, setBuySellMapping] = useState<Dictionary<string[]>>({});
  const [mapLots, setMapLots] = useState<{ availableLots: SymbolStockData<StockTransaction>, sellTransaction: StockTransaction } | undefined>(undefined);
  const [mergeTransactions, setMergeTransactions] = useState<StockTransaction[]>([]);
  const [editTransaction, setEditTransaction] = useState<StockTransaction | undefined>(undefined);
  const [deleteTransaction, setDeleteTransaction] = useState<StockTransaction | undefined>(undefined);
  const [showMappedSellTransactions, setShowMappedSellTransactions] = useState<string | undefined>(undefined);
  const [allTransactions, setAllTransactions] = useState<StockTransaction[]>([]);
  const [transactionData, setTransactionData] = useState<TransactionData[]>([]);
  const [filters, setFilters] = useState<Dictionary<string[]>>({});
  const [viewTransactionsSettings, setViewTransactionsSettings] = useState<ViewTransactionsSettings>({
    view: Object.keys(TransactionColumns).map((col: string) => TransactionColumns[col]),
    includeOpenLots: true });

  useEffect(() => {
    const initFilters: Dictionary<string[]> =
      {
        account: [] as string[],
        type: [] as string[],
        subtype: [] as string[],
        broker: [] as string[],
        symbol: [] as string[],
      };
    const flattenedTransactions: StockTransaction[] = getBrokerStockDataFlattened(transactionHistory).sort((a: StockTransaction, b: StockTransaction) => {
      if (moment(a.date) > moment(b.date)) {
        return -1;
      }
      if (a.date === b.date) {
        return (a.subtype === 'buy' ? 1 : (b.subtype === 'buy' ? -1 : 1));
      }
      return 1;
    });
    const historyData: TransactionData[] = [];
    let transactionIndex = 1;
    flattenedTransactions
      .forEach((transaction: StockTransaction) => {
        let soldUnmapped = -transaction.quantity;
        if (transaction.quantity < 0 && transaction.lots) {
          transaction.lots.forEach((mappedLot: StockLotMapping) => {
            soldUnmapped -= mappedLot.quantity;
          });
          if (soldUnmapped === 0) {
            // All sold transactions are mapped to bought lots.
            return;
          }
        }
        const security = transaction.symbol && stockDetails[transaction.symbol]
          ? {
            symbol: stockDetails[transaction.symbol].ticker === '' ? transaction.symbol : stockDetails[transaction.symbol].ticker,
            name: stockDetails[transaction.symbol].name,
          }
          : (mappedSymbol && stockDetails[mappedSymbol]
            ? {
              symbol: stockDetails[mappedSymbol].ticker === '' ? mappedSymbol : stockDetails[mappedSymbol].ticker,
              name: stockDetails[mappedSymbol].name,
            }
            : {
              symbol: transaction.symbol ?? mappedSymbol,
              name: '--',
            });
        if (!initFilters['broker'].find((investmentBroker: string) => investmentBroker === transaction.broker)) {
          initFilters['broker'].push(transaction.broker);
        }
        if (!initFilters['symbol'].find((symbol: string) => symbol === security.symbol)) {
          initFilters['symbol'].push(security.symbol);
        }
        const sellLotsUuids: string[] = [];
        const sellOrderUuids: string[] = [];
        if (transaction.subtype === 'buy' /* .quantity > 0 /* && transaction.action === TransactionAction.Bought */ && transaction.lots) {
          transaction.lots.forEach((lot: StockLotMapping) => {
            if (lot.sellUuid) {
              sellLotsUuids.push(lot.sellUuid);
            }
            else if (lot.orderUuid) {
              sellOrderUuids.push(lot.orderUuid);
            }
          });
        }
        const matchingLots: StockTransaction[] = transaction.subtype === 'buy' /* > 0 && /* transaction.action === TransactionAction.Bought && */ && transaction.lots
          ? flattenedTransactions.filter((sellTrans: StockTransaction) => sellLotsUuids.includes(sellTrans.uuid))
          : [];
        const matchingLot = matchingLots.length > 0 ? matchingLots.find((lot: StockTransaction) => {
          const lots = transaction.lots!.filter((lot: StockLotMapping) => flattenedTransactions.find((existing: StockTransaction) => existing.uuid === lot.sellUuid));
          return lot.uuid === lots[0].sellUuid;
        }) : undefined;
        const matchQuantity = matchingLot && transaction.lots && transaction.lots.length > 0 ? transaction.lots[0].quantity : undefined;
        const matchAmount = matchingLot && matchingLot.price && matchQuantity ? Number.parseFloat((matchingLot.price * matchQuantity).toFixed(2)) : undefined;
        if (transaction.quantity > 0 && !viewTransactionsSettings.includeOpenLots && (!matchingLots || matchingLots.length === 0)) {
          return;
        }
        if (viewTransactionsSettings.startDate || viewTransactionsSettings.endDate) {
          const transDate = transaction.quantity > 0
            ? ((!matchingLots || matchingLots.length === 0)
              ? transaction.date
              : matchingLots[0].date)
            : transaction.date;
          if (viewTransactionsSettings.startDate && moment(transDate) < moment(viewTransactionsSettings.startDate)) {
            return;
          }
          if (viewTransactionsSettings.endDate && moment(transDate) > moment(viewTransactionsSettings.endDate)) {
            return;
          }
        }
        const addTransactionData: TransactionData = {
          buyUuid: transaction.quantity > 0 ? transaction.uuid : undefined,
          sellUuid: matchingLot ? matchingLot.uuid : (transaction.quantity < 0 ? transaction.uuid : undefined),
          key: transactionIndex,
          row: transactionIndex,
          broker: transaction.broker,
          account: brokerAccounts[transaction.broker] && brokerAccounts[transaction.broker][transaction.accountId] ? (brokerAccounts[transaction.broker][transaction.accountId].name ?? brokerAccounts[transaction.broker][transaction.accountId].accountId) : transaction.accountId,
          symbol: security.symbol,
          name: security.name,
          date: transaction.quantity > 0 ? transaction.date : undefined,
          sellDate: matchingLots.length > 0 ? matchingLots[0].date : (transaction.quantity < 0 ? transaction.date : undefined),
          quantity: transaction.quantity > 0 ? transaction.quantity : undefined,
          sellQuantity: matchQuantity ?? (transaction.quantity < 0 ? soldUnmapped : undefined), // transaction.quantity < 0 ? -transaction.quantity : undefined,
          // TODO
          //price: transaction.price && transaction.quantity > 0 ? Number.parseFloat(transaction.price.toFixed(2)) : undefined,
          price: transaction.price && transaction.quantity > 0 ? transaction.price : undefined,
          // TODO
          // sellPrice: matchingLots.length > 0 && matchingLots[0].price ? Number.parseFloat(matchingLots[0].price.toFixed(2)) : (transaction.price && transaction.quantity < 0 ? Number.parseFloat(transaction.price.toFixed(2)) : undefined),
          sellPrice: matchingLots.length > 0 && matchingLots[0].price ? matchingLots[0].price : (transaction.price && transaction.quantity < 0 ? transaction.price : undefined),
          amount: transaction.quantity > 0 ? Number.parseFloat(transaction.amount?.toFixed(2)) : undefined,
          sellAmount: matchAmount ?? (transaction.quantity < 0 ? Number.parseFloat((transaction.amount * soldUnmapped / transaction.quantity)?.toFixed(2)) : undefined),
          fees: transaction.fees,
          type: transaction.type,
          subtype: transaction.subtype,
          sellLots: matchingLots,
          transaction,
          matchingSellTransaction: matchingLot,
        };
        if (addTransactionData.account && !initFilters['account'].find((account: string) => account === addTransactionData.account)) {
          initFilters['account'].push(addTransactionData.account);
        }
        /*
        if (transactionData.symbol && !initFilters['symbol'].find((symbol: string) => symbol === transactionData.symbol)) {
          initFilters['symbol'].push(transactionData.symbol);
        }
        */
        if (addTransactionData.type && !initFilters['type'].find((investmentType: string) => investmentType === addTransactionData.type)) {
          initFilters['type'].push(addTransactionData.type);
        }
        if (addTransactionData.subtype && !initFilters['subtype'].find((subtype: string) => subtype === addTransactionData.subtype)) {
          initFilters['subtype'].push(addTransactionData.subtype);
        }
        const existingDataIndex = historyData.findIndex((existingStock: any) => existingStock.symbol === addTransactionData.symbol
          && existingStock.broker === addTransactionData.broker
          && existingStock.account === addTransactionData.account
          && existingStock.date === addTransactionData.date
          && ((addTransactionData.price && existingStock.price === Number.parseFloat(addTransactionData.price.toFixed(2)))
            || (addTransactionData.sellPrice && existingStock.sellPrice === Number.parseFloat(addTransactionData.sellPrice.toFixed(2))))
          && ((existingStock.quantity && addTransactionData.quantity)
            || (existingStock.sellQuantity && addTransactionData.sellQuantity)));
        if (existingDataIndex >= 0) {
          addTransactionData['merge'] = true;
          if (historyData[existingDataIndex].quantity) {
            historyData[existingDataIndex].merge = true;
            // historyData[existingDataIndex].quantity = historyData[existingDataIndex].quantity + transactionData.quantity;
            // historyData[existingDataIndex].amount = historyData[existingDataIndex].amount + transactionData.amount;
          }
          if (historyData[existingDataIndex].sellQuantity) {
            historyData[existingDataIndex].merge = true;
            // historyData[existingDataIndex].sellQuantity = historyData[existingDataIndex].sellQuantity + transactionData.sellQuantity;
            // historyData[existingDataIndex].sellAmount = historyData[existingDataIndex].sellAmount + transactionData.sellAmount;
          }
        }
        /*
        else {
        */
        historyData.push(addTransactionData);
        if (matchingLots && matchingLots.length > 0) {
          matchingLots.forEach((matchingLot: StockTransaction, index: number) => {
            if (index === 0) {
              return;
            }
            const addMatchingLot = matchingLot.lots ? matchingLot.lots.find((lot: StockLotMapping) => lot.buyUuid === addTransactionData.buyUuid) : undefined;
            const addMatchQuantity = addMatchingLot ? addMatchingLot.quantity : undefined;
            const addMatchAmount = addMatchQuantity && matchingLot.price ? Number.parseFloat((matchingLot.price * addMatchQuantity).toFixed(2)) : undefined;
            const matchingTransactionData: TransactionData = {
              buyUuid: addTransactionData.buyUuid,
              sellUuid: matchingLot.uuid,
              key: index, // transactionIndex,
              row: index, // transactionIndex,
              broker: matchingLot.broker,
              account: brokerAccounts[matchingLot.broker] && brokerAccounts[matchingLot.broker][matchingLot.accountId] ? (brokerAccounts[matchingLot.broker][matchingLot.accountId].name ?? brokerAccounts[matchingLot.broker][matchingLot.accountId].accountId) : matchingLot.accountId,
              symbol: security.symbol,
              name: security.name,
              date: undefined,
              sellDate: matchingLot.date,
              quantity: undefined,
              sellQuantity: addMatchQuantity, // transaction.quantity < 0 ? -transaction.quantity : undefined,
              price: undefined,
              sellPrice: matchingLot.price ? Number.parseFloat(matchingLot.price.toFixed(2)) : undefined,
              amount: undefined,
              sellAmount: addMatchAmount,
              fees: transaction.fees,
              type: transaction.type,
              subtype: transaction.subtype,
              transaction: matchingLot,
              matchingSellTransaction: matchingLot,
              skipRow: true,
            };
            historyData.push(matchingTransactionData);
          });
        }
        transactionIndex += 1;
        // }
      });
    setTransactionData(historyData);
    setAllTransactions(flattenedTransactions);
    setFilters(initFilters);
  },        [brokerAccounts, mappedSymbol, stockDetails, transactionHistory, viewTransactionsSettings, props]);

  if (!transactionHistory || allTransactions.length === 0) {
    return (<>
      <CenteredDiv bold>Transaction History{mappedStock  ? ` for mapped stock ${mappedStock}` : ''}:</CenteredDiv>
      <CenteredDiv>There are no known transaction history in your accounts.</CenteredDiv>
    </>);
  }

  // const transactionData: any[] = [];

  const historyColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      render: (value: string, record: TransactionData, index: number) => {
        return {
          children: record.skipRow ? null : <div>{index + 1}</div>,
          props: {
            rowSpan: record.skipRow ? 0 : (record.sellLots && record.sellLots.length > 0 ? record.sellLots.length : 1),
          },
        };
      },
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      inputType: { recordType: RecordType.Text },
      filters: filters['broker'].map((broker: string) => ({ text: broker, value: broker })),
      onFilter: (value, record) => {
        return record.broker.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.broker.toLowerCase() < two.broker.toLowerCase() ? -1 : 1);
        },
        multiple: 1,
      },
      render: (value: string, record: TransactionData) => {
        const url = getUrl(record.broker, record.account, stockDetails[record.symbol], StockReferenceUrlType.accountPortolio);
        return {
          // eslint-disable-next-line react/jsx-no-target-blank
          children: record.skipRow ? null : (url ? <a href={url} target='_blank'>{value}</a> : <div>{value}</div>),
          props: {
            rowSpan: record.skipRow ? 0 : (record.sellLots && record.sellLots.length > 0 ? record.sellLots.length : 1),
          },
        };
      },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      inputType: { recordType: RecordType.Text },
      filters: filters['account'].map((account: string) => ({ text: account, value: account })),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.account.indexOf(value) === 0,
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      inputType: { recordType: RecordType.Text },
      filters: ['crypto', 'stocks', ...filters['symbol'].sort((a: string, b: string) => a < b ? -1 : 1)].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        if (value === 'crypto') {
          return record.symbol.includes('CUR:');
        }
        if (value === 'stocks') {
          return !record.symbol.includes('CUR:');
        }
        return record.symbol.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.symbol.toLowerCase() < two.symbol.toLowerCase() ? -1 : 1);
        },
        multiple: 2,
      },
      defaultSortOrder: 'ascend',
      render: (value: string, record: TransactionData) => {
        const brokerAccountId: string = brokerAccounts[record.broker] && brokerAccounts[record.broker][record.transaction.accountId] && brokerAccounts[record.broker][record.transaction.accountId].brokerAccountId
          ? (brokerAccounts[record.broker][record.transaction.accountId].brokerAccountId ?? '') : '';
        const url = getUrl(record.broker, brokerAccountId, stockDetails[record.symbol], StockReferenceUrlType.openLot);
        return {
          // eslint-disable-next-line react/jsx-no-target-blank
          children: url ? <a href={url} target='_blank' >{value}</a> : <div>{value}</div>,
        };
      },
    },
    /*
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      inputType: { recordType: RecordType.Text },
    },
    */
    {
      title: 'Buy Date',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (one, two) => {
          return (moment(one.date) < moment(two.date) ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: TransactionData) => {
        return {
          children: !record.skipRow && record.date ? <div>{moment(record.date).format('YYYY-MM-DD')}</div> : null,
          props: {
            rowSpan: record.skipRow ? 0 : (record.sellLots && record.sellLots.length > 0 ? record.sellLots.length : 1),
          },
        };
      },
    },
    {
      title: 'Buy Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: TransactionData) => {
        return {
          children: record.skipRow ? null : <div>{value}</div>,
          props: {
            rowSpan: record.skipRow ? 0 : (record.sellLots && record.sellLots.length > 0 ? record.sellLots.length : 1),
          },
        };
      },
    },
    {
      title: 'Buy Price',
      key: 'buyPrice',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: TransactionData, index: number) => {
        console.log(`price is: ${record.price}, with precision: ${record.price ? getPrice(record.price) : undefined}}`)
        console.log(JSON.stringify(record, null, 2));
        return {
          children: record.skipRow ? null : <div>{record.quantity && record.quantity >= 0 ? (record.price ? getPrice(record.price) : '') : ''}</div>,
          props: {
            rowSpan: record.skipRow ? 0 : (record.sellLots && record.sellLots.length > 0 ? record.sellLots.length : 1),
          },
        };
      },
    },
    {
      title: 'Buy Amount',
      key: 'buyAmount',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: TransactionData, index: number) => {
        return {
          children: record.skipRow ? null : <div>{record.quantity && record.quantity >= 0 ? record.amount?.toFixed(2) : ''}</div>,
          props: {
            rowSpan: record.skipRow ? 0 : (record.sellLots && record.sellLots.length > 0 ? record.sellLots.length : 1),
          },
        };
      },
    },
    {
      title: 'Sell Date',
      dataIndex: 'sellDate',
      key: 'sellDate',
      sorter: {
        compare: (one, two) => {
          return (moment(one.date) < moment(two.date) ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: any) => {
        return record.sellDate ? <div>{moment(record.sellDate).format('YYYY-MM-DD')}</div> : null;
      },
    },
    {
      title: 'Sell Qty',
      dataIndex: 'sellQuantity',
      key: 'sellQuantity',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: TransactionData, index: number) => {
        if (!value) {
          return null;
        }
        const lots = record.quantity && record.quantity > 0 && record.transaction.lots && record.transaction.lots.length > 0 ? record.transaction.lots!.filter((lot: StockLotMapping) => allTransactions.find((existing: StockTransaction) => existing.uuid === lot.sellUuid)) : undefined;
        const sellUuid = lots ? lots[0].sellUuid : record.transaction.uuid;
        const totalQty = record.matchingSellTransaction ? -record.matchingSellTransaction.quantity : (-record.transaction.quantity);
        const sellQty = record.sellQuantity ? toFixedNumber(record.sellQuantity, 2, true) : null;
        const sellQtyValue = totalQty === record.sellQuantity ? sellQty : <><div>{sellQty}</div>({totalQty})</>;
        return -record.transaction.quantity === record.sellQuantity ? <div>{sellQtyValue}</div> : <div style={{ background: sellUuid === showMappedSellTransactions ? 'yellow' : undefined }}>{sellQtyValue} {sellUuid === showMappedSellTransactions ? <CloseIcon onClick={() => setShowMappedSellTransactions(undefined)} /> : <ExclamationIcon onClick={() => setShowMappedSellTransactions(sellUuid) } />}</div>;
      },
    },
    {
      title: 'Sell Price',
      dataIndex: 'sellPrice',
      key: 'sellPrice',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: TransactionData, index: number) => {
        return <div>{record.sellPrice ? getPrice(record.sellPrice) : ''}</div>;
      },
    },
    {
      title: 'Sell Amount',
      key: 'sellAmount',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: any, index: number) => {
        return <div>{record.sellAmount?.toFixed(2)}</div>;
      },
    },
    {
      title: 'Edit',
      key: 'edit',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: TransactionData, index: number) => {
        const menuOptions: { name: string, component: string }[] = []; // /*'Edit', 'Delete', */
        if (record.sellQuantity) {
          menuOptions.push({ name: 'mapLots', component: 'Map Lots' });
        }
        if (record.merge) {
          menuOptions.push({ name: 'merge', component: `Merge with another ${record.sellQuantity ? 'sell' : 'buy'} transaction` });
        }
        menuOptions.push({ name: 'edit', component: record.transaction.source ? `Edit manually added transaction` : `Edit imported transaction` });
        menuOptions.push({ name: 'delete', component: record.transaction.source ? `Delete manually added transaction` : `Delete imported transaction` });
        return menuOptions.length > 0 ? <Dropdown
          menuItems={menuOptions}
          dropdownType={[DropdownType.Click]}
          onClick={(item: any) => {
            if (item === 'mapLots') {
              const sellUuid = record.sellLots && record.sellLots.length > 0 ? record.sellLots[0].uuid : record.sellUuid;
              const sellTransaction = allTransactions.find((sellTransaction: StockTransaction) => sellTransaction.uuid === sellUuid);
              if (!sellTransaction) {
                errorNotification('Something went wrong, please try again.');
                return;
              }
              const buyTransactions: StockTransaction[] = allTransactions.filter((buyTransaction: StockTransaction) => {
                if (buyTransaction.lots && buyTransaction.lots.find((lot: StockLotMapping) => lot.sellUuid === sellTransaction.uuid)) {
                  return true;
                }
                return buyTransaction.quantity > 0
                  && buyTransaction.accountId === sellTransaction.accountId
                  && buyTransaction.broker === sellTransaction.broker
                  && moment(buyTransaction.date) <= moment(sellTransaction.date)
                  && (buyTransaction.lots && buyTransaction.lots.length > 0 ? buyTransaction.lots.map((lot: StockLotMapping) => lot.quantity).reduce((a, b) => a + b) < buyTransaction.quantity : true);
              });
              const mapTrancactions: SymbolStockData<StockTransaction> = getSymbolStockData(buyTransactions);
              setMapLots({ availableLots: mapTrancactions, sellTransaction });
            }
            else if (item === 'merge') {
              const thisTransaction = allTransactions.find((trans: StockTransaction) => (record.buyUuid === trans.uuid || record.sellUuid === trans.uuid));
              if (!thisTransaction) {
                infoNotification('Something went wrong, please try again.');
              }
              const transactionsToMerge: StockTransaction[] = allTransactions.filter((trans: StockTransaction) => {
                if ((thisTransaction!.quantity < 0 && trans.quantity > 0) || (thisTransaction!.quantity > 0 && trans.quantity < 0)) {
                  return false;
                }
                return thisTransaction!.date === trans.date && thisTransaction!.broker === trans.broker && thisTransaction!.accountId === trans.accountId && thisTransaction!.price!.toFixed(3) === trans.price!.toFixed(3);
              });
              setMergeTransactions(transactionsToMerge);
            }
            else if (item === 'edit') {
              infoNotification('edit');
              setEditTransaction(record.transaction);
            }
            else if (item === 'delete') {
              setDeleteTransaction(record.transaction);
              infoNotification('delete');

            }
          }} >
            <MenuIcon />
          </Dropdown> : null;
      },
    },
    /*
    {
      title: 'Fees',
      dataIndex: 'fees',
      key: 'fees',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      inputType: { recordType: RecordType.Text },
      filters: filters['type'].map((investmentType: string) => ({ text: investmentType, value: investmentType })),
      onFilter: (value, record) => {
        return record.type.indexOf(value) === 0;
      },
    },
    {
      title: 'Subtype',
      dataIndex: 'subtype',
      key: 'subtype',
      inputType: { recordType: RecordType.Text },
      filters: filters['subtype'].map((subtype: string) => ({ text: subtype, value: subtype })),
      onFilter: (value, record) => {
        return record.subtype.indexOf(value) === 0;
      },
    },
    */
  ];

  /*
  <Space style={{ marginBottom: 16 }}>
    <Button onClick={}>Sort age</Button>
    <Button onClick={}>Clear filters</Button>
    <Button onClick={}>Clear filters and sorters</Button>
  </Space>
  */

  /*
  <Tabs tabs={tabs} onChange={(selectedTab: string) => setSelectedView(selectedTab)} />
  <div>Selected view: {selectedView}</div>
  const tabs = ['All Transactions', 'Buy Transactions', 'Sell Transactions', 'Buy-Sell View'].map((viewType: string) => ({ name: viewType, value: <></> }));
  */

  const onMergeTransactions = (mergedTransaction: StockTransaction) => {
    if (!mergedTransaction || !mergedTransaction.transactionIds) {
      return;
    }
    if (onUpdateTransactions) {
      const result: StockTransaction[] = allTransactions.filter((filterMerged: StockTransaction) => mergedTransaction.transactionId !== filterMerged.transactionId && !mergedTransaction.transactionIds!.find((merged: StockMergedTransaction) => merged.transactionId === filterMerged.transactionId));
      result.push(mergedTransaction);
      onUpdateTransactions({ transactions: result, orders: [] });
      setMergeTransactions([]);
    }
  };

  const onAddStockData = (addStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => {
    if (addStock && onUpdateTransactions) {
      onUpdateTransactions({ transactions: addStock.transactions, orders: addStock.orders });
    }
    setMapLots(undefined);
  };

  const onEditStockData = (updateStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => {
    if (updateStock && onUpdateTransactions) {
      onUpdateTransactions({ transactions: updateStock.transactions, orders: updateStock.orders });
    }
    setEditTransaction(undefined);
  };

  const onDeleteStockData = (deleteStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => {
    if (deleteStock && onUpdateTransactions) {
      onUpdateTransactions(undefined, { transactions: deleteStock.transactions, orders: deleteStock.orders });
    }
    setDeleteTransaction(undefined);
  };
  console.log('wheeeeeeeee')
  return (<>
    {mergeTransactions.length > 0 ? <MergeTransactions transactions={mergeTransactions} onSuccess={onMergeTransactions} onCancel={() => setMergeTransactions([])} /> : null}
    {mapLots ? <AddStockData dataType={StockDataType.SellTransaction} transactions={mapLots.availableLots} allTransactions={allTransactions.filter((trans: StockTransaction) => trans.symbol === mapLots.sellTransaction.symbol)} stockDetails={{}} mapLots={mapLots.sellTransaction} onCancel={() => setMapLots(undefined)} onSuccess={onAddStockData} /> : null}
    {editTransaction ? <AddStockData dataType={StockDataType.BuyTransaction} accounts={brokerAccounts} transactions={{}} editTransaction={editTransaction} stockDetails={{}} onCancel={() => setEditTransaction(undefined)} onSuccess={onEditStockData} /> : null}
    {deleteTransaction ? <AddStockData dataType={StockDataType.BuyTransaction} accounts={brokerAccounts} transactions={{}} deleteTransaction={deleteTransaction} stockDetails={{}} onCancel={() => setDeleteTransaction(undefined)} onSuccess={onDeleteStockData} /> : null}

    <CenteredDiv>
      <br />
      <CenteredDiv bold>Transaction History{mappedStock  ? ` for mapped stock ${mappedStock}` : ''}: <ViewTransactionsFilter settings={viewTransactionsSettings} onUpdateFilter={(settings: ViewTransactionsSettings) => {
        setViewTransactionsSettings(settings);
      }} /></CenteredDiv>
      <Table
        style={{ overflow: 'auto' }}
        rowSelection={readOnly ? undefined : {
          type: 'checkbox',
          onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            // setSelectedHoldings(selectedRows);
          },
          getCheckboxProps: (record: any) => ({
            name: record.row,
          }),
        }}
        pagination={false}
        className='.antd-documents-table'
        size='small'
        // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
        columns={historyColumns}
        dataSource={transactionData}
        bordered={true}
      />
    </CenteredDiv>
  </>);
};

export default ViewTransactionHistory;
