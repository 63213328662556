import React, { useState } from 'react';
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { DraggableItemType } from './DraggableContext';
import { SwipeEventData, useSwipeable } from 'react-swipeable';

export interface IDraggableItem {
  id: string;
  containerUuid: string;
  enableChildren?: boolean; // only components that can have children components
  childrenListUuid?: string;
}

export interface DraggableItem<T = any> extends IDraggableItem {
  title: string;
  content?: T;
  value?: string;
}

export interface DraggableList<T = any> {
  items: DraggableItem<T>[];
  dragEnabled: boolean;
  isRoot?: boolean;
}

const getItemStyle = (draggableStyle: any, isDragging: boolean):{} => ({
  background: isDragging ? 'lightgreen' : '#f0f0f0',
  borderRadius: '5px',
  position: 'relative',
  ...draggableStyle,
});

type ItemProps = {
  isDragging?: boolean;
};

const Item = styled.div`
  display: flex;
  user-select: none;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  margin: 0 0  0.5rem 0;
  border-radius: 3px;
  background: ${(props: ItemProps) => props.isDragging ? 'lightgreen' : '#f0f0f0'};
  border: 1px ${(props: ItemProps) => props.isDragging ? 'dashed #000' : 'solid #ddd'};
  -webkit-align-items: unset;
 `;
// padding: 0.5rem;

//   /* background: #fff; */

const Clone = styled(Item)`
  + div {
    display: none!important;
  }
`;

const Handle = styled.div`
  display: relative;
  width: 25px;
  top: 0px;
  bottom: 0px;
  padding-left: 5px;
  height: 100%;
`;
/*
  align-items: center;
  align-content: center;
  user-select: none;
  padding: 0.5rem;
  line-height: 1.5;
  border-radius: 3px 0 0 3px;
  background: #fff;
  border-right: 1px solid #ddd;
  color: #000;
*/

type HandleContentProps = {
  visible?: boolean;
};

const HandleContent = styled.div`
  ${(props: HandleContentProps) => props.visible ? `
  padding-left: 25px;
  display: relative;`
  : ''};
`;

type DraggableItemViewProps = {
  item: DraggableItemType;
  index: number;
  dragDisabled?: boolean;
  clone?: boolean;
};

const DraggableItemView: React.FC<DraggableItemViewProps> = (props: DraggableItemViewProps) => {
  const { item, index, clone, dragDisabled } = props;
  const [allowDrag, setAllowDrag] = useState<boolean>(false);
  const handlers = useSwipeable({
    onSwipedLeft: (eventData: SwipeEventData) => setAllowDrag(false),
    onSwipedRight: (eventData: SwipeEventData) => {
      setAllowDrag(true);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  const showHandle = !dragDisabled && allowDrag;

  return (
    <Draggable
      isDragDisabled={dragDisabled}
      key={item.id}
      draggableId={item.id}
      index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <>
          <div {...handlers}>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...(clone ? provided.dragHandleProps : {})}
              style={getItemStyle(
                provided.draggableProps.style,
                snapshot.isDragging,
                )
                /*
                provided.draggableProps.style
                isDragging={snapshot.isDragging} */
              }
            >
              {clone ? null : (!showHandle ? null :
                <div style={{ position: 'absolute', width: '25px', top: '0px', bottom: '0px', border: '1px solid lightgray', borderRadius: "5px 0px 0px 5px" }}>
                  <Handle {...provided.dragHandleProps}>
                    <CaretUpFilled style={{ position: 'absolute', top: 'calc(50% - 10px)' }}/>
                    <CaretDownFilled style={{ position: 'absolute', top: 'calc(50% + 0px)' }}/>
                    {false &&
                    <svg
                        width="20"
                        height="100%"
                        viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M5,15H21V13H3V15M0,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z" // M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z
                        />
                    </svg>}
                  </Handle>
                </div>)
              }
              <HandleContent visible={showHandle}>{item.content}</HandleContent>
            </div>
            {snapshot.isDragging && clone && (
              <Clone>{item.content}</Clone>)}
          </div>
        </>
      )}
    </Draggable>);
};

export default DraggableItemView;
