import React, { useState, useEffect } from 'react';
import { Dictionary } from 'lodash';
import moment from 'moment';
import { PlaidAccount } from '../../../graphql/generated/graphql';
import { CenteredDiv, StyledSpan } from '../../styled/CenteredDiv';
import { RecordType } from '../../table/DataTypes';
import { StockMergedTransaction, StockTransaction } from '../StockTypes';
import { EditableColumnProps } from '../../table/EditableTable';
import DivAlign, { DivPosition } from '../../atomic/DivAlign';
import Button from '../../atomic/Button';
import { errorNotification } from '../../actions/Notification';
import Table from '../../table/Table';
import Popconfirm from '../../atomic/Popconfirm';

/*
const convertPlaidInvestents = (investments : Dictionary<PlaidInvestment>): Dictionary<StockTransaction[]> => {
  const brokers = Object.keys(investments);
  const transactions: Dictionary<StockTransaction[]> = {};
  brokers.forEach((broker: string) => {
    const securities: Dictionary<PlaidSecurity> = Object.assign({}, ...investments[broker].securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
    investments[broker].transactions.forEach((transaction: PlaidInvestmentTransaction) => {
      if (!transaction.securityId) {
        console.log(`No securityId found. Skip transaction ${transaction.transactionId}`);
        return;
      }
      if (!transaction.amount) {
        console.log(`No amount found. Skip transaction ${transaction.transactionId}`);
        return;
      }
      if (!transaction.quantity) {
        console.log(`No quantity found. Skip transaction ${transaction.transactionId}`);
        return;
      }
      if (!transaction.date) {
        console.log(`No date found. Skip transaction ${transaction.transactionId}`);
        return;
      }
      const security = securities[transaction.securityId];
      if (!security.symbol) {
        console.log(`No symbol found for security ${transaction.securityId}. Skip transaction ${transaction.transactionId}`);
        return;
      }
      if (!transactions[security.symbol]) {
        transactions[security.symbol] = [];
      }
      const addTransaction: StockTransaction = {
        uuid: uuid(),
        broker,
        accountId: transaction.accountId,
        date: transaction.date,
        transactionId: transaction.transactionId,
        securityId: transaction.securityId,
        action: transaction.amount < 0 ? TransactionAction.Bought : TransactionAction.Sold,
        symbol: security.symbol,
        type: transaction.type ? transaction.type : undefined,
        subtype: transaction.subtype ? transaction.subtype : undefined,
        amount: transaction.amount,
        quantity: transaction.quantity,
        fees: transaction.fees ? transaction.fees : undefined,
        price: transaction.price ? transaction.price : undefined,
        name: transaction.name ? transaction.name : undefined,
      };
      transactions[security.symbol].push(addTransaction);
    });
  });
  return transactions;
};
*/

type InvestmentTableData = {
  key: string;
  broker: string;
  account: string;
  symbol: string;
  name: string;
  date: string;
  quantity: number;
  price?: number;
  amount: number;
  fees?: number;
  // type: transaction.type,
  // subtype: transaction.subtype,
  transaction: StockTransaction;
  missing?: boolean;
  matchingTransaction?: StockTransaction;
};

type MissingTransaction = {
  existing: StockTransaction;
  new?: StockTransaction;
};

type ImportInvestmentTransactionsProps = {
  broker?: string;
  accounts: Dictionary<Dictionary<PlaidAccount>>; // [broker]: { [accountId]: PlaidAccount }
  existingTransactions: Dictionary<StockTransaction[]>;
  transactions: Dictionary<StockTransaction[]>; //
  onImportInvestmentTransactions: (addInvestments: Dictionary<StockTransaction[]>, removeInvestments: Dictionary<StockTransaction[]>, acocunts?: Dictionary<Dictionary<PlaidAccount>>) => void;
  onClose: () => void;
};
const ImportInvestmentTransactions: React.FC<ImportInvestmentTransactionsProps> = (props: ImportInvestmentTransactionsProps) => {
  const { accounts, transactions, existingTransactions, onImportInvestmentTransactions, onClose } = props;
  const [alreadyImported, setAlreadyImported] = useState<StockTransaction[]>([]);
  // const [missingTransactions, setMissingTransactions] = useState<StockTransaction[]>([]);
  const [matchedMissingTransactions, setMatchedMissingTransactions] = useState<MissingTransaction[]>([]);
  const [selectedTransactions, setSelectedTransactions] = useState<InvestmentTableData[]>([] as InvestmentTableData[]);
  const [showExistingTransactions, setShowExistingTransactions] = useState<boolean>(false);
  const [resolveConflicts, setResolveConflicts] = useState<boolean>(false);
  const [selectedConflicts, setSelectedConflicts] = useState<string[] | undefined>();

  useEffect(() => {
    const initAlreadyImported: StockTransaction[] = [];
    const initMatchedMissingTransactions: MissingTransaction[] = [];
    // const initMissingTransactions: StockTransaction[] = ([] as StockTransaction[]).concat(...Object.keys(existingTransactions).map((symbol: string) => existingTransactions[symbol])).filter((trans: StockTransaction) => {
    ([] as StockTransaction[]).concat(...Object.keys(existingTransactions).map((symbol: string) => existingTransactions[symbol])).forEach((trans: StockTransaction) => {
      if (!transactions[trans.broker] || trans.transactionId === '') {
        return false;
      }
      const prevImported = transactions[trans.broker].find((newTrans: StockTransaction) => {
        return newTrans.transactionId === trans.transactionId || (trans.transactionIds && trans.transactionIds.find((mergedTrans: StockMergedTransaction) => mergedTrans.transactionId === newTrans.transactionId));
      });
      if (prevImported) {
        initAlreadyImported.push(prevImported);
      }
      else {
        const matchingTrans = transactions[trans.broker].find((newTrans: StockTransaction) => newTrans.broker === trans.broker && newTrans.date === trans.date && newTrans.price === trans.price && newTrans.quantity === trans.quantity);
        initMatchedMissingTransactions.push({ existing: trans, new: matchingTrans });
      }
      // return prevImported === undefined;
    });
    setAlreadyImported(initAlreadyImported);
    // setMissingTransactions(initMissingTransactions);
    setMatchedMissingTransactions(initMatchedMissingTransactions);
  },        [existingTransactions, transactions]);

  const filters: Dictionary<Dictionary<string[]>> =
    {
      investments: {
        account: [] as string[],
        symbol: [] as string[],
        type: [] as string[],
        subtype: [] as string[],
        broker: [] as string[],
      },
    };

  const investmentData: InvestmentTableData[] = [];
  const missingInvestmentData: InvestmentTableData[] = [];

  matchedMissingTransactions.forEach((missingTransaction: MissingTransaction) => {
    const account = accounts[missingTransaction.existing.broker][missingTransaction.existing.accountId];
    // const matchingTransaction = matchedMissingTransactions.find((matchedTransaction: { existing: StockTransaction, new: StockTransaction}) => matchedTransaction.existing.transactionId === missingTransaction.transactionId);
    if (!account) {
      console.error(`account not found for missing transactions with broker ${missingTransaction.existing.broker}, account id ${missingTransaction.existing.accountId}, and symbol ${missingTransaction.existing.symbol}`);
      return;
    }
    if (selectedConflicts && !missingTransaction.new) {
      return;
    }
    const transactionData: InvestmentTableData = {
      key: missingTransaction.existing.uuid,
      broker: missingTransaction.existing.broker,
      account: account.name ?? '',
      symbol: missingTransaction.existing.symbol,
      name: missingTransaction.existing.name ?? '',
      date: missingTransaction.existing.date,
      quantity: missingTransaction.existing.quantity,
      price: missingTransaction.existing.price,
      amount: missingTransaction.existing.amount,
      fees: missingTransaction.existing.fees,
      // type: transaction.type,
      // subtype: transaction.subtype,
      transaction: missingTransaction.existing,
      missing: true,
      matchingTransaction: missingTransaction.new,
    };
    missingInvestmentData.push(transactionData);
  });
  Object.keys(transactions)
    .forEach((broker: string) => {
      transactions[broker].forEach((importTransaction: StockTransaction) => {
        if (!filters['investments']['broker'].find((investmentBroker: string) => investmentBroker === importTransaction.broker)) {
          filters['investments']['broker'].push(importTransaction.broker);
        }
        const account = accounts[importTransaction.broker][importTransaction.accountId];
        if (!showExistingTransactions) {
          if (existingTransactions[importTransaction.symbol] && existingTransactions[importTransaction.symbol].find((existingTransactions: StockTransaction) => existingTransactions.transactionId === importTransaction.transactionId)) {
            return;
          }
        }
        const transactionData: InvestmentTableData = {
          key: importTransaction.uuid,
          broker: importTransaction.broker,
          account: account && account.name ? account.name : '',
          symbol: importTransaction.symbol,
          name: importTransaction.name ?? '',
          date: importTransaction.date,
          quantity: importTransaction.quantity,
          price: importTransaction.price,
          amount: importTransaction.amount,
          fees: importTransaction.fees,
          // type: transaction.type,
          // subtype: transaction.subtype,
          transaction: importTransaction,
        };
        investmentData.push(transactionData);
        if (!filters['investments']['account'].find((accountFilter: string) => accountFilter === account.name || accountFilter === account.accountId)) {
          filters['investments']['account'].push(account.name ?? account.accountId);
        }
        if (transactionData.symbol && !filters['investments']['symbol'].find((symbol: string) => symbol === transactionData.symbol)) {
          filters['investments']['symbol'].push(transactionData.symbol);
        }
        /*
        if (transactionData.type && !filters['investments']['type'].find((investmentType: string) => investmentType === transactionData.type)) {
          filters['investments']['type'].push(transactionData.type);
        }
        if (transactionData.subtype && !filters['investments']['subtype'].find((subtype: string) => subtype === transactionData.subtype)) {
          filters['investments']['subtype'].push(transactionData.subtype);
        }
        */

      // const brokerInvestments = transactions[broker];
      // const securities = Object.assign({}, ...brokerInvestments.securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
      // const accounts = Object.assign({}, ...brokerInvestments.accounts.map((account: PlaidAccount) => ({ [account.accountId] : account })));
      // brokerInvestments.transactions.forEach((transaction: PlaidInvestmentTransaction) => {
      //  const security = transaction.securityId ? securities[transaction.securityId] : {
      //    symbol: '--',
      //    name: '--',
       // };
      /*  if (!showExistingTransactions) {
          if (existingTransactions[security.symbol] && existingTransactions[security.symbol].find((existingTransactions: StockTransaction) => existingTransactions.transactionId === transaction.transactionId)) {
            return;
          }
        }
        if (transaction.subtype === 'dividend') {
          return;

        const transactionData = {
          key: investmentIndex,
          row: investmentIndex,
          broker,
          account: accounts[transaction.accountId].name,
          symbol: security.symbol,
          name: security.name,
          date: transaction.date,
          quantity: transaction.quantity,
          price: transaction.price,
          amount: transaction.amount,
          fees: transaction.fees,
          type: transaction.type,
          subtype: transaction.subtype,
          transaction,
        };
        investmentData.push(transactionData);
        if (!filters['investments']['account'].find((account: string) => account === transactionData.account)) {
          filters['investments']['account'].push(transactionData.account);
        }
        if (transactionData.symbol && !filters['investments']['symbol'].find((symbol: string) => symbol === transactionData.symbol)) {
          filters['investments']['symbol'].push(transactionData.symbol);
        }
        if (transactionData.type && !filters['investments']['type'].find((investmentType: string) => investmentType === transactionData.type)) {
          filters['investments']['type'].push(transactionData.type);
        }
        if (transactionData.subtype && !filters['investments']['subtype'].find((subtype: string) => subtype === transactionData.subtype)) {
          filters['investments']['subtype'].push(transactionData.subtype);
        }
        investmentIndex += 1;
        */
      });
    });

  /*
  let investmentIndex = 1;
  Object.keys(transactions)
    .forEach((broker: string) => {
      if (!filters['investments']['broker'].find((investmentBroker: string) => investmentBroker === broker)) {
        filters['investments']['broker'].push(broker);
      }
      const brokerInvestments = transactions[broker];
      const securities = Object.assign({}, ...brokerInvestments.securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
      const accounts = Object.assign({}, ...brokerInvestments.accounts.map((account: PlaidAccount) => ({ [account.accountId] : account })));
      brokerInvestments.transactions.forEach((transaction: PlaidInvestmentTransaction) => {
        const security = transaction.securityId ? securities[transaction.securityId] : {
          symbol: '--',
          name: '--',
        };
        if (!showExistingTransactions) {
          if (existingTransactions[security.symbol] && existingTransactions[security.symbol].find((existingTransactions: StockTransaction) => existingTransactions.transactionId === transaction.transactionId)) {
            return;
          }
        }
        if (transaction.subtype === 'dividend') {
          return;
        }
        const transactionData = {
          key: investmentIndex,
          row: investmentIndex,
          broker,
          account: accounts[transaction.accountId].name,
          symbol: security.symbol,
          name: security.name,
          date: transaction.date,
          quantity: transaction.quantity,
          price: transaction.price,
          amount: transaction.amount,
          fees: transaction.fees,
          type: transaction.type,
          subtype: transaction.subtype,
          transaction,
        };
        investmentData.push(transactionData);
        if (!filters['investments']['account'].find((account: string) => account === transactionData.account)) {
          filters['investments']['account'].push(transactionData.account);
        }
        if (transactionData.symbol && !filters['investments']['symbol'].find((symbol: string) => symbol === transactionData.symbol)) {
          filters['investments']['symbol'].push(transactionData.symbol);
        }
        if (transactionData.type && !filters['investments']['type'].find((investmentType: string) => investmentType === transactionData.type)) {
          filters['investments']['type'].push(transactionData.type);
        }
        if (transactionData.subtype && !filters['investments']['subtype'].find((subtype: string) => subtype === transactionData.subtype)) {
          filters['investments']['subtype'].push(transactionData.subtype);
        }
        investmentIndex += 1;
      });
    });
  */

  const investmentsColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      render: (value: string, record: InvestmentTableData, index: number) => {
        return record.matchingTransaction
          ? <Popconfirm
          key={'fix-existing-transaction'}
          header={'Fix imported transaction'}
          icon={null}
          title={<>
            <div style={{ fontWeight: 'bold' }}>The transaction_id appears to have changed since transaction has been previously imported. Do you want to update the transaction?</div>
            <div>Old transaction_id: {record.transaction.transactionId}</div>
            <div>New transaction_id: {record.matchingTransaction.transactionId}</div>
          </>}
          onConfirm={() => {
            onResolveConflicts(record);
          }}
          onCancel={() => {
          }}
          okText='Done'
          cancelText='Cancel'
        >
          <div style={{ color: record.missing ? 'red' : undefined }}>{index + 1}</div>
        </Popconfirm>
        : <div style={{ color: record.missing ? 'red' : undefined }}>{index + 1}</div>;
      },
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['broker'].map((broker: string) => ({ text: broker, value: broker })),
      onFilter: (value, record) => {
        return record.broker.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.broker.toLowerCase() < two.broker.toLowerCase() ? -1 : 1);
        },
        multiple: 1,
      },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['account'].map((account: string) => ({ text: account, value: account })),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.account.indexOf(value) === 0,
      render: (text: string, record: any, index: number) => {
        if (record.broker === 'Coinbase') {
          return record.account !== 'Coinbase' ? <div>Coinbase Pro</div> : <div>Coinbase</div>;
        }
        return <div>{record.account}</div>;
      },
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionId',
      key: 'transactionId',
      // colSpan: 0,
      hidden: true,
      inputType: { recordType: RecordType.Text },
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      render: (value: string, record: any, index: number) => {
        return <div style={{ color: record.missing ? 'red' : undefined }}>{record.transaction.transactionId}</div>;
      },
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      inputType: { recordType: RecordType.Text },
      filters: ['crypto', 'stocks', ...filters['investments']['symbol'].sort((a: string, b: string) => a < b ? -1 : 1)].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        if (value === 'crypto') {
          return record.symbol.includes('CUR:');
        }
        if (value === 'stocks') {
          return !record.symbol.includes('CUR:');
        }
        return record.symbol === value;
      },
      sorter: {
        compare: (one, two) => {
          if (!one.symbol) {
            return 1;
          }
          if (!two.symbol) {
            return -1;
          }
          return (one.symbol.toLowerCase() < two.symbol.toLowerCase() ? -1 : 1);
        },
        multiple: 2,
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (one, two) => {
          return (moment(one.date) < moment(two.date) ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: any, index: number) => {
        return <div>{record.price?.toFixed(2)}</div>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      inputType: { recordType: RecordType.Text },
      render: (value: string, record: any, index: number) => {
        return <div>{record.amount?.toFixed(2)}</div>;
      },
    },
    {
      title: 'Fees',
      dataIndex: 'fees',
      key: 'fees',
      inputType: { recordType: RecordType.Text },
    },
    /*
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['type'].map((investmentType: string) => ({ text: investmentType, value: investmentType })),
      onFilter: (value, record) => {
        return record.type.indexOf(value) === 0;
      },
    },
    {
      title: 'Subtype',
      dataIndex: 'subtype',
      key: 'subtype',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['subtype'].map((subtype: string) => ({ text: subtype, value: subtype })),
      onFilter: (value, record) => {
        return record.subtype.indexOf(value) === 0;
      },
    },
    */
  ];

  /*
  const securities: Dictionary<PlaidSecurity> = Object.assign({}, ...newTransactions.securities.map((security: PlaidSecurity) => ({ [security.securityId]: security })));
  const documentData: any[] = [];
  newTransactions.transactions
    .sort((one: PlaidInvestmentTransaction, two: PlaidInvestmentTransaction) => (one.securityId && two.securityId && one.securityId < two.securityId ? -1 : 1))
    .forEach((transaction: PlaidInvestmentTransaction, index: number) => {
      const security = transaction.securityId ? securities[transaction.securityId] : null;
      const addTransaction = {
        select: false,
        row: index,
        symbol: security ? security.symbol : '--',
        name: security ? security.name : '--',
        type: transaction.type,
        subtype: transaction.subtype,
        date: transaction.date,
        quantity: transaction.quantity,
        price: transaction.price,
        total: transaction.amount,
        json: JSON.stringify(transaction),
      };
      documentData.push(addTransaction);
    });
    */

  /* dataSource.forEach((d: any, index: number) => {
    const newDocumentData = d.newRow
    ? { key: `document-${index + 1}`, id: index + 1, name: d.name, description: d.description, entries: d.entries, newRow: true }
    : { key: `document-${index + 1}`, id: index + 1, name: d.name, description: d.description, entries: d.entries };
    documentData.push(newDocumentData);
  });
  */
  const onResolveConflicts = (record?: InvestmentTableData) => {
    if (!record) {
      return;
    }
    const addTransaction: Dictionary<StockTransaction[]> = {};
    const removeTransaction: Dictionary<StockTransaction[]> = {};
    const fixTransaction = { ...record.transaction };
    fixTransaction.transactionId = record.matchingTransaction!.transactionId;
    if (fixTransaction.transactionIds) {
      const index = fixTransaction.transactionIds.findIndex((transId: StockMergedTransaction) => transId.transactionId === record.transaction.transactionId);
      fixTransaction.transactionIds[index].transactionId = record.matchingTransaction!.transactionId;
    }
    addTransaction[record.symbol] = [fixTransaction];
    removeTransaction[record.symbol] = [record.transaction!];
    onImportInvestmentTransactions(addTransaction, removeTransaction, accounts);
  };

  const onClickImportTransactions = () => {
    if (selectedTransactions.length === 0) {
      errorNotification('Please select at least one transaction to import');
      return;
    }
    const selectedPlaidTransactions: Dictionary<StockTransaction[]> = {};
    selectedTransactions.forEach((transactionData: InvestmentTableData) => {
      const transaction = transactionData.transaction;
      if (!selectedPlaidTransactions[transaction.symbol]) {
        selectedPlaidTransactions[transaction.symbol] = [];
      }
      if (!alreadyImported.find((imported: StockTransaction) => imported.transactionId === transactionData.transaction.transactionId)
      && !matchedMissingTransactions.find((missing: MissingTransaction) => missing.existing.transactionId === transactionData.transaction.transactionId)) {
        selectedPlaidTransactions[transaction.symbol].push(transaction);
      }
    });
    onImportInvestmentTransactions(selectedPlaidTransactions, {}, accounts);
  };

  const onClickResolveConflicts = () => {
    alert('resolve conflicts');
  };

  return <>
    {Object.keys(transactions).length > 0 ? <>
      <CenteredDiv bold>Investments:</CenteredDiv>
        <DivAlign hPosition={DivPosition.Left}>
          {Object.keys(transactions).map((broker: string) => {
            return <div key={`broker-import-details-${broker}`}><StyledSpan bold>{broker}:</StyledSpan> New Transactions: {transactions[broker].length - alreadyImported.length}; Already Imported: {alreadyImported.length}; Transactions to fix: {matchedMissingTransactions.filter((miss: MissingTransaction) => miss.new).length}, Missing Transactions: {matchedMissingTransactions.filter((miss: MissingTransaction) => miss.new === undefined).length}</div>;
          })}
        </DivAlign>
        <DivAlign hPosition={DivPosition.Right}>
          <Button key='show-existing-transactions' onClick={() => setShowExistingTransactions(!showExistingTransactions)}>{showExistingTransactions ? 'Hide' : 'Show'} imported transactions</Button>
          <Button disabled={matchedMissingTransactions.length === 0} key='resolve-transactions' onClick={() =>  setResolveConflicts(!resolveConflicts)} style={{ color: matchedMissingTransactions.length === 0 ? undefined : 'red' }} >{resolveConflicts ? 'Hide Conflicts' : 'Show Conflicts'}</Button>
          <Button key='import-transactions' onClick={resolveConflicts ? onClickResolveConflicts : onClickImportTransactions}>{resolveConflicts ? 'Resolve Investment Conflicts' : 'Import Selected Investments'}</Button>
        </DivAlign>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: resolveConflicts ? selectedConflicts : [
              ...selectedTransactions.map((selectedTransaction: InvestmentTableData) => {
                return selectedTransaction.key;
              }),
              ...(showExistingTransactions ? missingInvestmentData.map((missing: InvestmentTableData) => missing.key) : []),
              ...investmentData.filter((row: InvestmentTableData) => {
                if (!row.symbol || !existingTransactions[row.symbol]) {
                  return false;
                }
                const existingTransaction = existingTransactions[row.symbol].find((existing: StockTransaction) => existing.transactionId === row.transaction.transactionId);
                return existingTransaction !== undefined;
              }).map((selectedTransaction: InvestmentTableData) => selectedTransaction.key),
            ],
            onChange: (selectedRowKeys: React.Key[], selectedRows: InvestmentTableData[]) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              resolveConflicts ? setSelectedConflicts(selectedRowKeys as string[]) : setSelectedTransactions(selectedRows);
            },
            getCheckboxProps: (record: any) => ({
              name: record.row,
              disabled: !record.symbol
                || (existingTransactions[record.symbol]
                    && existingTransactions[record.symbol].find((existingTransaction: StockTransaction) => {
                      if (existingTransaction.accountId !== record.transaction.accountId) {
                        return false;
                      }
                      if (existingTransaction.transactionId === record.transaction.transactionId) {
                        return true;
                      }
                      return false;
                    }) !== undefined),
            }),
          }}
          pagination={false}
          className='.antd-documents-table'
          size='small'
          columns={investmentsColumns.filter((column: any) => column.hidden !== true)}
          dataSource={resolveConflicts ? missingInvestmentData : [...investmentData, ...(showExistingTransactions ? missingInvestmentData : [])] }
          bordered={true}
        /></> : null}
  </>;
};

export default ImportInvestmentTransactions;

  /*
      <CenteredDiv bold>Import stock transactions from {broker}</CenteredDiv>
      <EditableTable
        className='.antd-documents-table'
        size='small'
        // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
        columns={investmentsColumns}
        dataSource={documentData}
        bordered={true}
        updateDataSource={(data: any[]) => {
          console.log(data);
        }}/> */
