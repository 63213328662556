import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { default as PageComponent } from '../components/layout/Page';
import Stocks from '../components/stocks/Stocks';
import { Features } from '../graphql/generated/graphql';

type StocksSamplePageProps = RouteComponentProps & {
};

const StocksSamplePage: React.FC<StocksSamplePageProps> = (props: StocksSamplePageProps) => {
  const authContext = useContext(AuthContext);
  const featureEnabled = authContext.isFeatureEnabled(Features.Stock);
  return (
    <PageComponent headerTitle={'Stocks'} content={
      featureEnabled
        ? <Stocks />
        : <div>Feature not enabled</div>
    }
    />
  );
};

export default StocksSamplePage;
