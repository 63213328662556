import React, { useEffect } from 'react';
import { FeedbackInput, usePingQuery, UserInput } from '../../graphql/generated/graphql';
import { FieldType } from '../../components/table/DataTypes';
import JsxViewResponse from './JsxViewResponse';
import SubmitFeedback from './Public/SubmitFeedback';
import RegisterAlphaUser from './Public/RegisterAlphaUser';
import { ApiProps } from './Helpers';

type PingApiProps = {
};

export const publicClientContext = { context: { clientName: 'public' } };

export const Ping: React.FC<PingApiProps & ApiProps> = (props: PingApiProps, apiProps: ApiProps) => {
  const { data: pingData } = usePingQuery({
    variables: { },
    ...publicClientContext,
  });

  useEffect(() => {
    if (apiProps.updateApiResponse && pingData) {
      apiProps.updateApiResponse(pingData.ping);
    }
  });

  if (!apiProps.returnJsx) {
    return null;
  }

  const codes = pingData
    ? [pingData.ping]
    : [];
  console.log(codes);
  return (<JsxViewResponse data={codes} {...apiProps} />);
};

const pingResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [...[
      'response',
      ].map((o: string) => ({ title: o, key: o })),
    ]},
  },
};

const registerAlphaUserResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [
      'email',
      'status'].map((o: string) => ({ title: o, key: o })),
    },
  },
};

const feedbackResponse = {
  entries: {
    response: { type: FieldType.Tree, values: ['result'].map((o: string) => ({ title: o, key: o })) },
  },
};

export const publicApiDefinition = {
  public: {
    ping: {
      type: 'query',
      function: () => {
        return <React.Fragment><Ping /></React.Fragment>;
      },
      arguments: {
        entries: {
        },
      },
      response: pingResponse,
    },
    feedback: {
      type: 'mutation',
      function: (feedback: FeedbackInput) => {
        return <React.Fragment><SubmitFeedback feedbackInput={feedback} /></React.Fragment>;
      },
      arguments: {
        entries: {
          message: ({ placeholder: 'Feedback message', type: FieldType.Text }),
          feedbackType: ({ placeholder: 'Feedback type', type: FieldType.Dropdown, values: ['issue', 'suggestion', 'feature_response'] }),
        },
      },
      response: feedbackResponse,
    },
    registerAlphaUser: {
      type: 'mutation',
      function: (user: UserInput) => {
        return <React.Fragment><RegisterAlphaUser user={user} /></React.Fragment>;
      },
      arguments: {
        entries: {
          firstName: ({ placeholder: 'Enter first name', type: FieldType.Text }),
          lastName: ({ placeholder: 'Enter last name', type: FieldType.Text }),
          emailAddress: ({ placeholder: 'Enter email address', type: FieldType.Text }),
        },
      },
      response: registerAlphaUserResponse,
    },
  },
};
