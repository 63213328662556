import React, { useCallback } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import Button from '../../components/atomic/Button';
import logger from '../../utils/Logger';
import s2pConfig from '../../Config';

type LinkProps = {
  loading: boolean;
  token?: string;
  onUpdatePublicToken: (publicToken: string, broker: BrokerMetadata) => void;
};

export type BrokerMetadata = {
  account_id: string;
  accounts: {
    id: string;
    name: string;
    subtype: string;
    type: string;
  }[];
  institution: {
    name: string;
    institution_id: string;
  };
  link_session_id: string;
  public_token: string;
};

const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const onSuccess = useCallback((token: string, metadata: BrokerMetadata) => {
    console.log(`link public token returned for institution: ${metadata.institution.name}`);
    // console.log(token);
    // console.log(metadata);
    props.onUpdatePublicToken(token, metadata as BrokerMetadata);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },                            []);

  const config: PlaidLinkOptions = {
    token: props.token ?? '',
    env: s2pConfig.PLAID_ENV, // 'development', //
    product:['transactions', 'investments'], // 'auth', 'investments', 'liabilities', 'transactions', 'accounts'],
    onSuccess,
    onExit: (error: any, metadata: any) => {
      logger.debug(error);
    },
    onLoad: () => {
      logger.debug('Plaid link client loaded');
    },
    onEvent: (eventName: any, metadata: any) => {
      logger.debug(eventName);
    },
  };

  const { open, ready, error } = usePlaidLink(config);

  return (
    <Button onClick={() => {
      open();
    }} disabled={!ready} loading={props.loading}>
      {error ? alert(error) : null}
      Connect to a new bank
    </Button>
  );
};

export default Link;
