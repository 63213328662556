import React, { useState } from 'react';
import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../components/layout/Page';
import { DataCollectionData, DataEntry, DataObject } from '../graphql/generated/graphql';
import EntriesView from '../components/data/EntriesView';
import { Dictionary } from 'lodash';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

type EntriesProps = RouteComponentProps & {
};

const DefaultHeaderTitle = 'Entries';

const Entries: React.FC<EntriesProps> = (props: EntriesProps) => {
  const collectionUuid = (props.match.params as any).collectionUuid;
  const [headerTitle, setHeaderTitle] = useState<string>(DefaultHeaderTitle);

  if (!collectionUuid && headerTitle !== DefaultHeaderTitle) {
    setHeaderTitle(DefaultHeaderTitle);
  }

  const onUpdate = (collections: DataCollectionData[], objects: Dictionary<DataObject>, entries: DataEntry[]) => {
    if (collectionUuid) {
      const collection = collections.find((c: DataCollectionData) => c.uuid === collectionUuid);
      if (collection && headerTitle !== collection.name) {
        setHeaderTitle(collection.name);
      }
    }
  };

  return (
    <Page
      headerTitle={headerTitle}
      content={<EntriesView collectionUuids={collectionUuid ? [collectionUuid] : undefined} onUpdate={onUpdate} />}
    />
  );
};

export default Entries;
