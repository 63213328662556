import React from "react";

export type ViewProps = {
  data: DataItem[];
  mapping: any;
  sourceName: string;
  collapseable?: boolean;
  noDataMessage?: JSX.Element;
  extraMenu?: (initialData: any) => ExtraMenuType;
};

export enum LayoutDirection {
  Vertical = 0,
  Horizontal,
}

export type ExtraMenuType = {
  items: JSX.Element[];
  direction?: LayoutDirection;
};

export const EmptyMenu: ExtraMenuType = {
  items: [],
};

export type DisplayMapping = {
  name: string,
  field: string,
  type: string,
};

export type DataItem = {
  title: string | React.ReactNode;
  data: any;
};

export type FieldMapping = {
  label: string,
  field: string[],
  jsxTemplate?: JSX.Element;
};

export type DataMapping = {
  header: FieldMapping[],
  content: FieldMapping[],
};

export const jsxJoin = (jsxArray: JSX.Element[], separator?: JSX.Element) => {
  if (!jsxArray || jsxArray.length === 0) {
    return null;
  }
  return jsxArray.map((element: JSX.Element, index: number) => index < jsxArray.length - 1 ? <>{element}{separator}</> : element);
};
