import React from 'react';
// import Highcharts from 'highcharts';
import Highcharts, { Chart } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

type DrawChartProps = {
  options: Highcharts.Options,
  chartType?: 'stockchart';
};

const DrawChart: React.FC<DrawChartProps> = (props: DrawChartProps) => {
  const { options, chartType } = props;

  return (chartType === 'stockchart'
  ? <HighchartsReact
      highcharts={Highcharts}
      constructorType = { 'stockChart' }
      options={options}
      callback={((chart: Chart) => {
        console.log('callback triggered');
      })}
    />
  : <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default DrawChart;
