import { FullscreenExitOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const FullScreenDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
  height: 100%;
  width: 100%;
  background: white;
  overflow: scroll;
  z-index: 10;
`;

const CancelFullScreenIcon = styled(FullscreenExitOutlined)`
  z-index: 2000;
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  font-size: 20px;
`;

type FullScreenProps = {
  fullScreen: boolean;
  content: JSX.Element | JSX.Element[];
  onExit: () => void;
};

const FullScreen: React.FC<FullScreenProps> = (props: FullScreenProps) => {
  const [fullScreen, setFullScreen] = useState<boolean>(props.fullScreen);

  useEffect(() => {
    if (props.fullScreen !== fullScreen) {
      setFullScreen(props.fullScreen);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  return (fullScreen
    ? <FullScreenDiv>
        <CancelFullScreenIcon onClick={() => {
          setFullScreen(false);
          props.onExit();
        }}/>
        {props.content}
      </FullScreenDiv>
    : <>{props.content}</>);
};

export default FullScreen;
