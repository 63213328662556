import React from 'react';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { Features, User, UserInput } from '../graphql/generated/graphql';
import { AuthStatus } from '../components/login/AuthStatusEnum';

export enum AuthProvider {
  Cognito = 'Cognito',
  Google = 'Google',
  Sandbox = 'Sandbox',
  None = 'None',
}

export default React.createContext({
  isAuthenticating: false,
  isSemiAuthenticated: false,
  isAuthenticated: false,
  provider: AuthProvider.Cognito,
  authState: AuthStatus.None,
  userInput: undefined as UserInput | undefined,

  // token: any;
  user: null as User | null,

  getToken: () => {},
  setAuthState: (authState: AuthStatus) => {},
  isFeatureEnabled: (feature: Features): boolean => {
    return true;
  },
  register: (user: any) => {},
  login: (token: CognitoIdToken | string | null, provider: AuthProvider, userInput: UserInput | null, redirectPath: string | null) => {},
  logout: () => {},
});
