import React, { useEffect, useState } from 'react';
import { PlaidAccount, PlaidHolding, PlaidHoldings, PlaidSecurity } from '../../../graphql/generated/graphql';
import DivAlign, { DivPosition } from '../../atomic/DivAlign';
import { CenteredDiv, StyledDiv } from '../../styled/CenteredDiv';
import { RecordType } from '../../table/DataTypes';
import { getBrokerStockDataFlattened, StockHolding, SymbolStockData } from '../StockTypes';
import { EditableColumnProps } from '../../table/EditableTable';
import { Dictionary } from 'lodash';
import Button from '../../atomic/Button';
import Table from '../../table/Table';
import Tooltip from '../../atomic/Tooltip';
import { errorNotification } from '../../actions/Notification';
import ExclamationIcon from '../../icons/ExlamationIcon';

export type ImportHoldingData = {
  key: string;
  row: number;
  broker: string;
  symbol: string;
  name: string;
  account: string;
  cost_basis?: number;
  price: number | string;
  value?: number;
  quantity?: number;
  holding: PlaidHolding;
};

const convertPlaidHoldings = (plaidHoldings : Dictionary<PlaidHoldings>) => {
  const brokers = Object.keys(plaidHoldings);
  const holdings: Dictionary<StockHolding[]> = {};
  brokers.forEach((broker: string) => {
    const securities: Dictionary<PlaidSecurity> = Object.assign({}, ...plaidHoldings[broker].securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
    plaidHoldings[broker].holdings.forEach((holding: PlaidHolding) => {
      /*
      if (!holding.securityId
        || !holding.accountId
        || !holding.costBasis
        || !holding.price
        || !holding.priceDate
        || !holding.quantity
        || !holding.securityId
        || !holding.value) {
        console.log(`missing data. Skip transaction holding`);
        return;
      }
      */
      const symbol: string = holding.securityId
        ? (securities[holding.securityId].symbol ?? '--')
        : '--';
      // const security = holding.securityId ? securities[holding.securityId] : { symbol: '--' };
      /*
      if (!security.symbol) {
        console.log(`No symbol found for security ${holding.securityId}. Skip holding.`);
        return;
      } */
      if (!holdings[symbol]) {
        holdings[symbol] = [];
      }
      const price = holding.costBasis && holding.quantity ? holding.costBasis / holding.quantity : null;
      const addHolding: StockHolding = {
        broker,
        accountId: holding.accountId ?? '--',
        priceDate: holding.priceDate ?? '--',
        securityId: holding.securityId ?? '--',
        symbol,
        costBasis: holding.costBasis === undefined || holding.costBasis === null ? null : holding.costBasis,
        price,
        quantity: holding.quantity === undefined || holding.quantity === null ? null : holding.quantity,
        value: holding.value === undefined || holding.value === null ? null : holding.value,
      };
      holdings[symbol].push(addHolding);
    });
  });
  return holdings;
};

const isHoldingRecordExists = (allHoldings: Dictionary<StockHolding[]>, record: ImportHoldingData) => {
  const existingHold = allHoldings[record.symbol] ? allHoldings[record.symbol].find((existingHolding: StockHolding) => existingHolding.broker === record.broker && existingHolding.accountId === record.holding.accountId) : undefined;
  return existingHold;
};

type ImportHoldingsProps = {
  existingHoldings: SymbolStockData<StockHolding>;
  importedHoldings: Dictionary<PlaidHoldings>;
  onUpdateHoldings: (holdings: Dictionary<StockHolding[]>, removedHoldings: Dictionary<StockHolding[]>, importSecurities: Dictionary<Dictionary<PlaidSecurity>>, importAccounts: Dictionary<Dictionary<PlaidAccount>>) => void;
};
const ImportHoldings: React.FC<ImportHoldingsProps> = (props: ImportHoldingsProps) => {
  const { onUpdateHoldings } = props;
  const [selectedHoldings, setSelectedHoldings] = useState<any[]>([] as any[]);
  const [alreadyImportedHoldings, setAlreadyImportedHoldings] = useState<string[]>([]);
  const [deletedSecurities, setDeletedSecurities] = useState<Dictionary<Dictionary<PlaidSecurity>>>({});
  const [holdings, setHoldings] = useState<Dictionary<PlaidHoldings>>({});
  // const [removedHoldings, setRemovedHoldings] = useState<Dictionary<PlaidHoldings>>({});
  // const [changedHoldings, setChangedHoldings] = useState<Dictionary<Dictionary<PlaidHoldings>>>({});
  const [showExistingHoldings, setShowExistingHoldings] = useState<boolean>(false);
  const [allHoldings, setAllHoldings] = useState<Dictionary<StockHolding[]>>({});
  const [holdingsData, setHoldingsData] = useState<ImportHoldingData[]>([]);
  const [filters, setFilters] = useState<{ broker: string[], account: string[], symbol: string[] }>({ broker: [], account: [], symbol: [] });

  useEffect(() => {
    if (props.existingHoldings) {
      const initAllHoldings = Object.assign({}, ...Object.keys(props.existingHoldings).map((symbol: string) => ({ [symbol]: getBrokerStockDataFlattened(props.existingHoldings[symbol]) })));
      setAllHoldings(initAllHoldings);
    }
    if (props.importedHoldings) {
      setHoldings(props.importedHoldings);
    }
  },        [props]);

  useEffect(() => {
    const initFilters: { broker: string[], account: string[], symbol: string[] } = { broker: [], account: [], symbol: [] };
    const initHoldingsData: ImportHoldingData[] = [];
    const initAlreadyImpoteredHoldings: string[] = [];
    let holdingIndex = 1;
    const deletedHoldings: Dictionary<PlaidHolding[]> = {};
    const deletedSecurity: Dictionary<Dictionary<PlaidSecurity>> = {};
    Object.keys(allHoldings).forEach((symbol: string) => {
      allHoldings[symbol].filter((holding: StockHolding) => {
        if (!holdings[holding.broker]) {
          return false;
        }
        return !holdings[holding.broker].holdings.find((newHolding: PlaidHolding) => {
          return holding.accountId === newHolding.accountId && holding.securityId === newHolding.securityId;
        });
      }).forEach((deleteHolding: StockHolding) => {
        const removePlaidHolding: PlaidHolding = {
          accountId: deleteHolding.accountId,
          costBasis: 0,
          securityId: deleteHolding.securityId,
          quantity: 0,
        };
        if (!deletedHoldings[deleteHolding.broker]) {
          deletedHoldings[deleteHolding.broker] = [];
        }
        deletedHoldings[deleteHolding.broker].push(removePlaidHolding);
        if (!deletedSecurity[deleteHolding.broker]) {
          deletedSecurity[deleteHolding.broker] = {};
        }
        if (!deletedSecurity[deleteHolding.broker][deleteHolding.securityId]) {
          deletedSecurity[deleteHolding.broker][deleteHolding.securityId] = ({ symbol: deleteHolding.symbol, name: '--', securityId: deleteHolding.securityId });
        }
      });
    });
    Object.keys(holdings)
      .forEach((broker: string) => {
        if (!initFilters.broker.find((holdingsBroker: string) => holdingsBroker === broker)) {
          initFilters.broker.push(broker);
        }
        const brokerHoldings = holdings[broker];
        const securities = Object.assign({}, ...brokerHoldings.securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
        const accounts = Object.assign({}, ...brokerHoldings.accounts.map((account: PlaidAccount) => ({ [account.accountId] : account })));

        [...brokerHoldings.holdings, ...(deletedHoldings[broker] ?? [])].forEach((holding: PlaidHolding) => {
          const security = holding.securityId ? (securities[holding.securityId] ?? deletedSecurity[broker][holding.securityId]) : {
            symbol: '--',
            name: '--',
          };
          const toDelete = holding.costBasis === 0 && holding.quantity === 0;
          const holdingData: ImportHoldingData = {
            key: `${security.symbol}-${broker}-${holding.accountId}-${holding.securityId}`,
            row: holdingIndex,
            broker,
            symbol: security.symbol,
            name: security.name,
            account: holding.accountId ? accounts[holding.accountId].name : '--',
            cost_basis: toDelete ? 0 : (holding.costBasis ?? undefined),
            price: toDelete ? 0 : (holding.quantity && holding.costBasis ? holding.costBasis / holding.quantity : '--'),
            value: holding.value ?? undefined,
            quantity: toDelete ? 0 : (holding.quantity ?? undefined),
            holding,
          };
          const oldHolding = isHoldingRecordExists(allHoldings, holdingData);
          if (oldHolding) {
            if (oldHolding.quantity !== holdingData.quantity || oldHolding.costBasis !== holdingData.cost_basis || oldHolding.price !== holdingData.price) {
              holdingData['oldHolding'] = oldHolding;
            }
            else {
              if (!showExistingHoldings) {
                return;
              }
              initAlreadyImpoteredHoldings.push(holdingData.key);
            }
          }
          if (!initFilters.symbol.find((symbol: string) => symbol === holdingData.symbol)) {
            initFilters.symbol.push(holdingData.symbol);
          }

          if (!initFilters.account.find((account: string) => account === holdingData.account)) {
            initFilters.account.push(holdingData.account);
          }
          initHoldingsData.push(holdingData);
          holdingIndex += 1;
        });
      });
    setHoldingsData(initHoldingsData);
    setAlreadyImportedHoldings(initAlreadyImpoteredHoldings);
    setDeletedSecurities(deletedSecurity);
    setFilters(initFilters);
  },        [allHoldings, holdings, props, showExistingHoldings]);

  /*
  const allHoldings = convertPlaidHoldings(holdings);

  Object.keys(allHoldings)
    .forEach((broker: string) => {
      if (!filters['holdings']['broker'].find((holdingsBroker: string) => holdingsBroker === broker)) {
        filters['holdings']['broker'].push(broker);
      }
      // const brokerHoldings = allHoldings[broker].holdings;
      const brokerAccounts = Object.assign({}, ...holdings[broker].accounts.map((account: PlaidAccount) => ({ [account.accountId] : account })));
      allHoldings[broker].forEach((holding: StockHolding) => {
        const security = holding.securityId ? holdings.securities[holding.securityId] : {
          symbol: '--',
          name: '--',
        };
        const holdingData = {
          key: holdingIndex,
          row: holdingIndex,
          broker,
          symbol: security.symbol,
          name: security.name,
          account: holding.accountId, // ? accounts[holding.accountId].name : '--',
          cost_basis: holding.costBasis,
          price: holding.price,
          value: holding.value,
          quantity: holding.quantity,
          holding,
        };
        if (!filters['holdings']['symbol'].find((symbol: string) => symbol === holdingData.symbol)) {
          filters['holdings']['symbol'].push(holdingData.symbol);
        }

        if (!filters['holdings']['account'].find((account: string) => account === holdingData.account)) {
          filters['holdings']['account'].push(holdingData.account);
        }
        holdingsData.push(holdingData);
        holdingIndex += 1;
      });
    });
  */

  const holdingsColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      render: (text: string, record: any, index: number) => (
        <div>{index}</div>
      ),
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      filters: filters.broker.map((broker: string) => ({ text: broker, value: broker })),
      onFilter: (value, record) => {
        return record.broker.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.broker.toLowerCase() < two.broker.toLowerCase() ? -1 : 1);
        },
        multiple: 1,
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      filters: filters.account.map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        return record.account.indexOf(value) === 0;
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      filters: ['crypto', 'stocks', ...filters.symbol.sort((a: string, b: string) => a < b ? -1 : 1)].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        if (!record.symbol) {
          return false;
        }
        if (value === 'crypto') {
          return record.symbol.includes('CUR:');
        }
        if (value === 'stocks') {
          return !record.symbol.includes('CUR:');
        }
        if (record.symbol === null) {
          return value === record.symbol;
        }
        return record.symbol.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return one.symbol && two.symbol ? (one.symbol.toLowerCase() < two.symbol.toLowerCase() ? -1 : 1) : (one.symbol ? -1 : 1);
        },
        multiple: 2,
      },
      inputType: { recordType: RecordType.Text },
      defaultSortOrder: 'ascend',
      render: (text: string, record: any) => (
        <div>{record.symbol}{record.oldHolding && allHoldings[record.symbol] ? <Tooltip title='Value has changed'><ExclamationIcon color='red' /></Tooltip> : ''}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (one, two) => {
          return one.name && two.name ? (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1) : (one.name ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => (
        record.name && record.name.length > 50 ? <Tooltip title={record.name}>
        <div>{record.name.substring(0, 50)}...</div>
        </Tooltip> : <div>{record.name}</div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        return <>
          <div>{text}</div>
          {record.oldHolding ? <StyledDiv style={{ color: 'red' }}>({record.oldHolding.quantity})</StyledDiv> : null}
        </>;
      },
    },
    {
      title: 'Cost Basis',
      dataIndex: 'cost_basis',
      key: 'cost_basis',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        return <>
          <div>{text}</div>
          {record.oldHolding ? <StyledDiv style={{ color: 'red' }}>({record.oldHolding.costBasis})</StyledDiv> : null}
        </>;
      },
    },
    {
      title: 'Average Price',
      dataIndex: 'price',
      key: 'price',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        return <>
          <div>{Number(record.price).toFixed(2)}</div>
          {record.oldHolding ? <StyledDiv style={{ color: 'red' }}>({Number(record.oldHolding.price).toFixed(2)})</StyledDiv> : null}
        </>;
      },
    },
    {
      title: 'Current Value',
      dataIndex: 'value',
      key: 'value',
      sorter: {
        compare: (one, two) => {
          return one.value !== undefined && two.value !== undefined ? (one.value < two.value ? -1 : 1) : (one.value !== undefined ? -1 : 1);
        },
        multiple: 4,
      },      inputType: { recordType: RecordType.Text },
    },
  ];

  return (
    <>
      {Object.keys(holdings).length > 0 ? <>
        <CenteredDiv bold>Holdings:</CenteredDiv>
        <DivAlign hPosition={DivPosition.Right}>
          <Button onClick={() => setShowExistingHoldings(!showExistingHoldings)}>{showExistingHoldings ? 'Hide' : 'Show'} imported holdings</Button>
          <Button onClick={() => {
            const changedSelectedHoldings = selectedHoldings; // No need to filter. .filter((selectedHold: any) => selectedHold.oldHolding);
            if (changedSelectedHoldings.length === 0) {
              errorNotification('Please select at least one holding to import');
              return;
            }
            const selectedPlaidHoldings: Dictionary<PlaidHoldings> = {};
            changedSelectedHoldings.forEach((holding: any) => {
              if (!selectedPlaidHoldings[holding.broker]) {
                selectedPlaidHoldings[holding.broker] = {
                  accounts: holdings[holding.broker].accounts,
                  securities: [...holdings[holding.broker].securities, ...Object.keys(deletedSecurities[holding.broker]).map((secId: string) => deletedSecurities[holding.broker][secId])],
                  holdings: [],
                };
              }
              selectedPlaidHoldings[holding.broker].holdings.push(holding.holding);
            });
            const importHoldings: Dictionary<StockHolding[]> = convertPlaidHoldings(selectedPlaidHoldings);

            // Add items that were imported but no longer present
            const updatedHoldings: Dictionary<StockHolding[]> = {};
            const removedHoldings: Dictionary<StockHolding[]> = {};
            Object.keys(importHoldings).forEach((symbol: string) => {
              importHoldings[symbol].forEach((importHolding: StockHolding) => {
                if (importHolding.quantity === 0 && importHolding.costBasis === 0) {
                  if (!removedHoldings[symbol]) {
                    removedHoldings[symbol] = [];
                  }
                  removedHoldings[symbol].push(importHolding);
                }
                else {
                  if (!updatedHoldings[symbol]) {
                    updatedHoldings[symbol] = [];
                  }
                  updatedHoldings[symbol].push(importHolding);
                }
              });
            });
            /*
            Object.keys(allHoldings).forEach((symbol: string) => {
              allHoldings[symbol].forEach((hold: StockHolding) => {
                const matchedHolding = importHoldings[hold.symbol] && importHoldings[hold.symbol].find((importHold: StockHolding) => {
                  return (importHold.accountId === hold.accountId
                    && importHold.broker === hold.accountId
                    && importHold.symbol === hold.symbol);
                });
                if (!matchedHolding) {
                  removedHoldings.push(hold);
                }
                else if (matchedHolding.quantity !== hold.quantity) {
                  updatedHoldings[symbol][hold.broker][hold.accountId].push(hold);
                }
              });
            });
            */
            const importSecurities: Dictionary<Dictionary<PlaidSecurity>> = {};
            Object.keys(selectedPlaidHoldings).forEach((broker: string) => {
              importSecurities[broker] = Object.assign({}, ...selectedPlaidHoldings[broker].securities.map((security: PlaidSecurity) => ({ [security.securityId]: security }), deletedSecurities[broker]));
            });
            const importAccounts: Dictionary<Dictionary<PlaidAccount>> = {};
            Object.keys(selectedPlaidHoldings).forEach((broker: string) => {
              importAccounts[broker] = Object.assign({}, ...selectedPlaidHoldings[broker].accounts.map((account: PlaidAccount) => ({ [account.accountId]: account })));
            });
            onUpdateHoldings(updatedHoldings, removedHoldings, importSecurities, importAccounts);
          }}>Import Selected Holdings</Button>
        </DivAlign>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: [
              ...selectedHoldings.map((selectedHolding: any) => selectedHolding.key),
              ...alreadyImportedHoldings,
            ],
            onChange: (selectedRowKeys: React.Key[], selectedRows: ImportHoldingData[]) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              setSelectedHoldings(selectedRows.filter((selectedRow: any) => !alreadyImportedHoldings.includes(selectedRow.key)));
            },
            getCheckboxProps: (record: ImportHoldingData) => ({
              name: record.key,
              disabled: alreadyImportedHoldings.includes(record.key), // isHoldingRecordDisabled(record),
            }),
          }}
          pagination={false}
          className='.antd-documents-table'
          size='small'
          // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
          columns={holdingsColumns}
          dataSource={holdingsData}
          bordered={true}
        /></> : null}
    </>);
};

export default ImportHoldings;
