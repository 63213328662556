import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { default as PageComponent } from '../components/layout/Page';
import { Checkbox, Divider, Input, Popconfirm, Select } from 'antd';
// import * as MyQRReader from '../components/qrcode/QRReader';
// import * as MyQRScaner from '../components/qrcode/QRScanner';
import * as QRReader from 'react-qr-reader';
import QrReader from 'react-qr-scanner';
import { errorNotification } from '../components/actions/Notification';
import { CheckCircleTwoTone, CloseSquareTwoTone, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import ViewEntry from '../components/views/Entry';
import DraggableContext, { DraggableData } from '../components/draggable/DraggableContext';
import HookSample from '../components/sample/HookSample';
import { CenteredDiv } from '../components/styled/CenteredDiv';
import moment from 'moment';
import DatePicker from '../components/atomic/DatePicker';
import ClosureExample from '../components/sample/ClosureExample';
import LoadPage from '../components/sample/LoadPageExample';
import Tutorial from '../components/tutorial/Tutorial';
import Button from '../components/atomic/Button';
import Calendar from '../components/calendar/Calendar';
import { entryToEvents } from '../components/calendar/EntryToEvents';
import { sampleCollections, sampleEntries } from '../components/tutorial/TutorialEntry';
import CustomEntriesViewSample from '../components/sample/CustomEntriesViewSample';
import DragCloneExample from '../components/sample/DragCloneExample';
import Stocks from '../components/stocks/Stocks';
import Subscriptions from '../containers/api/Subscription/Subscriptions';

type SamplePageProps = RouteComponentProps & {
};

export enum ScannerType {
  Scanner = 'Scanner',
  ReaderComponent = 'ReaderComponent',
  Reader = 'Reader',
}

/*
type SampleDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  position?: CSS.PositionProperty;
  display?: CSS.DisplayProperty;
  border: CSS.BorderProperty<number>;
};

const SampleDiv = styled.div`
  ${(props: SampleDivProps) => props.display ? `display: ${props.display};` : null}
  ${(props: SampleDivProps) => props.position ? `position: ${props.position};` : null}
`; */

enum ViewItem {
  CustomEntry = 'Custom Entry',
  Entry = 'Entry',
  IFrame = 'IFrame',
  Scanner = 'Scanner',
  Dnd = 'Dnd',
  Hook = 'Hook',
  Sandbox = 'Sandbox',
  Test = 'Test',
  Closure = 'Closure',
  Tutorial = 'Tutorial',
  Calendar = 'Calendar',
  BuildComponent = 'Build Component',
  Stocks = 'Stocks',
  WebSocket = 'Web Socket',
}

const initialData: DraggableData = {
  items: {
    'task-1': { id: 'task-1', content: 'A' },
    'task-2': { id: 'task-2', content: 'B' },
    'task-3': { id: 'task-3', content: 'C' },
    'task-4': { id: 'task-4', content: 'D' },
    'task-5': { id: 'task-5', content: 'E' },
    'task-6': { id: 'task-6', content: 'F' },
    'task-7': { id: 'task-7', content: 'G' },
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'To do',
      itemIds: ['task-1', 'task-2', 'task-3', 'task-4'],
    },
    'column-2': {
      id: 'column-2',
      title: 'To do subset',
      itemIds: ['task-5', 'task-6', 'task-7'],
    },
  },
  columnOrder: ['column-1', 'column-2'],
};

const SamplePage: React.FC<SamplePageProps> = (props: SamplePageProps) => {
  const [scan, setScan] = useState<boolean>(false);
  // const [qrReaderType, setQrReaderType] = useState<ScannerType | null>(null);

  const [scanData, setScanData] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<ViewItem>(ViewItem.Stocks);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loadUrl, setLoadUrl] = useState<string | undefined>(undefined);

  const onScannedQRCode = (scannedData: any) => {
    console.log(`handle scan called with ${scannedData}`);
    if (scannedData) {
      // in case url is from mysejahtera and it's missing / between qrscan and url params, insert the slash.
      // const newUrl = (data as string).replace('https://mysejahtera.malaysia.gov.my/qrscan?', 'https://mysejahtera.malaysia.gov.my/qrscan/?');
      console.log(`QR Code Data: ${scannedData}`);
      // console.log(`QR Code Url: ${newUrl}`);
      // console.log(`QR Code is MySejahtera: ${newIsMySejahtera}`);
      setScanData(scannedData);
      setScan(false);
    }
  };

  const onErrorQRCode = (message) => {
    errorNotification(message);
  };

  const getQRReader = () => {
    if (!scan) {
      return null;
    }
    return true
      ? <div><div>QR Reader</div><QRReader
          delay={500}
          onError={onErrorQRCode}
          onScan={onScannedQRCode}
          style={{ width: '100%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}
        />
        </div>
      : <div><div>QR Scanner</div><QrReader // qr-scanner
          delay={500}
          onError={onErrorQRCode}
          onScan={onScannedQRCode}
          style={{ width: '100%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}
        /></div>;
  };

  const viewOptions = Object.keys(ViewItem).map((e: any, index: number) => {
    return <Select.Option key={`option-view-${e}`} value={e}>{e}</Select.Option>;
  });

  return (
    <PageComponent headerTitle={'Sample Page'} content={
      <>
        <CenteredDiv>
          <Select style={{ top: '10px' }} key='view-select' placeholder="Select View" value={selectedItem} onSelect={(value: any) => {
            if (selectedItem !== ViewItem[value]) {
              setSelectedItem(ViewItem[value]);
            }
          }}>
            {viewOptions}
          </Select>
        </CenteredDiv>
        <Divider />
        {selectedItem === ViewItem.Entry
          ? <>
            {/* item 1 - Entry */}
            <ViewEntry
                editButtons={
                  <>
                    <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor='#52c41a' />
                    <Popconfirm
                        title="Discard user changes."
                        onConfirm={() => {}}
                        onCancel={() => {}}
                        okText="Discard"
                        cancelText="Cancel"
                      >
                      <CloseSquareTwoTone style={{ marginLeft: '5px', fontSize: '22px' }} twoToneColor='red' />
                    </Popconfirm>
                  </>}
                leftElement={<Checkbox/>}
                leftWidth='25px'
                rightElement={
                  <>
                    <EditTwoTone style={{ marginLeft: '5px', top: '20px', fontSize: '22px' }} />
                    <Popconfirm
                      title="Delete user."
                      onConfirm={() => {}}
                      onCancel={() => {}}
                      okText="Delete"
                      cancelText="Cancel"
                    >
                      <DeleteTwoTone style={{ marginLeft: '2px', marginRight: '5px', marginTop: '10px', fontSize: '22px' }} />
                    </Popconfirm>
                  </>}
                rightWidth={'50px'}
                header={
                  <>
                    <Input placeholder='Enter your name' key={`input-user`} style={{ maxWidth: '100%', marginBottom: '5px' }}></Input>
                    <Input key={`input-user-contact`} style={{ maxWidth: '100%' }} disabled={true} ></Input>
                  </>
                }
              />
              {/* item 2 */}
              <div style={{ position: 'relative', border: '1px solid', borderColor: 'lightgray', padding: '5px', borderRadius: '8px', marginTop: '5px' }}>
                <Checkbox style={{ position: 'absolute', width: '25px', padding: '5px' }} ></Checkbox>
                <div style={{  fontWeight: 'bold', marginLeft: '30px', marginRight: '50px' }}>
                  <Input placeholder='Enter your name' key={`input-user`} style={{ maxWidth: '100%', marginBottom: '5px' }}></Input>
                </div>
                <div style={{ fontWeight: 'bold', marginLeft: '30px', marginRight: '50px' }}>
                  <Input key={`input-user-contact`} style={{ maxWidth: '100%' }} disabled={true} ></Input>
                  <div style={{ float: 'right', marginBottom: '0px', marginTop: '5px' }}>
                    <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor='#52c41a' />
                    <Popconfirm
                        title="Discard user changes."
                        onConfirm={() => {}}
                        onCancel={() => {}}
                        okText="Discard"
                        cancelText="Cancel"
                      >
                      <CloseSquareTwoTone style={{ marginLeft: '5px', fontSize: '22px' }} twoToneColor='red' />
                    </Popconfirm>
                  </div>
                </div>
                <div style={{ position: 'absolute', float: 'right', top: '5px', right: '0px' }}>
                  <EditTwoTone style={{ marginLeft: '5px', top: '20px', fontSize: '22px' }} />
                  <Popconfirm
                    title="Delete user."
                    onConfirm={() => {}}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                  ><DeleteTwoTone style={{ marginLeft: '2px', marginRight: '5px', marginTop: '10px', fontSize: '22px' }} />
                </Popconfirm>
                </div>
              </div>
              {/* item 3 */}
              <div style={{ position: 'relative', border: '1px solid', borderColor: 'lightgray', padding: '5px', borderRadius: '8px', marginTop: '5px' }}>
                <Checkbox style={{ position: 'absolute', width: '25px', padding: '5px' }} ></Checkbox>
                <div style={{  fontWeight: 'bold', marginLeft: '30px', marginRight: '50px' }}>
                  <Input placeholder='Enter your name' key={`input-user`} style={{ maxWidth: '100%', marginBottom: '5px' }}></Input>
                </div>
                <div style={{ fontWeight: 'bold', marginLeft: '30px', marginRight: '50px' }}>
                  <Input key={`input-user-contact`} style={{ maxWidth: '100%' }} disabled={true} ></Input>
                  <div style={{ float: 'right', marginBottom: '0px', marginTop: '5px' }}>
                    <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor='#52c41a' />
                    <Popconfirm
                        title="Discard user changes."
                        onConfirm={() => {}}
                        onCancel={() => {}}
                        okText="Discard"
                        cancelText="Cancel"
                      >
                      <CloseSquareTwoTone style={{ marginLeft: '5px', fontSize: '22px' }} twoToneColor='red' />
                    </Popconfirm>
                  </div>
                </div>
                <div style={{ position: 'absolute', float: 'right', top: '5px', right: '0px' }}>
                  <EditTwoTone style={{ marginLeft: '5px', top: '20px', fontSize: '22px' }} />
                  <Popconfirm
                    title="Delete user."
                    onConfirm={() => {}}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                  ><DeleteTwoTone style={{ marginLeft: '2px', marginRight: '5px', marginTop: '10px', fontSize: '22px' }} />
                </Popconfirm>
                </div>
              </div>
            </>
            : null}
        {selectedItem === ViewItem.Scanner
          ? <>
            <Button onClick={() => {
              setScan(!scan);
            }}>{scan ? 'Stop scanning' : 'Scan'}</Button>
            {getQRReader()}
            {scanData
              ? <div>Scanned data: {scanData}</div>
              : null}
          </>
          : null}
        {selectedItem === ViewItem.IFrame
          ? <>
              <div style={{  fontWeight: 'bold', marginLeft: '30px', marginRight: '50px' }}>Input URL
                <Input
                  placeholder='Enter your url'
                  key={`input-url`}
                  style={{ maxWidth: '100%', marginBottom: '5px' }}
                  onChange={(e: any) => {
                    console.log(e.currentTarget.value);
                    setLoadUrl(e.currentTarget.value);
                  }}></Input>
              </div>
              <LoadPage url={loadUrl}/>
            </>
          : null}
        {selectedItem === ViewItem.Dnd
          ? <DraggableContext initialData={initialData} showTitle />
          : null}
        {selectedItem === ViewItem.Hook
          ? <HookSample />
          : null}
        {selectedItem === ViewItem.Sandbox
          ? <iframe
              src='https://codesandbox.io/embed/o5jq32r78q?fontsize=14&hidenavigation=0&module=%2Fsrc%2Findex.js&moduleview=1&theme=dark&view=split&hidedevtools=1'
              style={{ width: '100%', height: '500px', border: 0, borderRadius: '4px', overflow: 'hidden' }}
              title="Iframe preview"
              allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
              sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
            ></iframe>
          : null}
        {selectedItem === ViewItem.Test
          ? <DatePicker
              confirmReset={{ title: 'Reset due date' }}
              size='small'
              fontSize={20}
              initialValue={selectedDate ? moment(selectedDate) : undefined}
              onChange={(e?: moment.Moment | null) => setSelectedDate(e ? e.toDate() : null)}
            />
          : null}
        {selectedItem === ViewItem.Closure
          ? <ClosureExample></ClosureExample>
          : null}
        {selectedItem === ViewItem.Tutorial
          ? <Tutorial />
          : null}
        {selectedItem === ViewItem.Calendar
          ? <Calendar onCreateEvent={() => {}} onUpdateEvent={(dataUuid: string) => {}} onUpdateSelected={(dates: any) => {}} onDeleteEvent={(data: string) => {}} weekendsVisible={true} entryEvents={entryToEvents(sampleEntries, sampleCollections)}/>
          : null}
        {selectedItem === ViewItem.CustomEntry
          ? <CustomEntriesViewSample   />
          : null}
        {selectedItem === ViewItem.BuildComponent
          ? <DragCloneExample />
          : null}
        {selectedItem === ViewItem.Stocks
          ? <Stocks />
          : null}
        {selectedItem === ViewItem.WebSocket
          ? <div>
            <div>Test Web Sockets</div>
            <Subscriptions />
          </div>
          : null}
          
      </>
    }
    />
  );
};

export default SamplePage;

/*
        <div style={{ position: 'relative', border: '1px solid', borderColor: 'lightgray', padding: '5px', borderRadius: '8px', marginTop: '5px' }}>
          <Checkbox style={{ position: 'absolute', width: '25px', padding: '5px' }} ></Checkbox>
          <div style={{  fontWeight: 'bold', marginLeft: '30px', marginRight: '50px' }}>
            <Input placeholder='Enter your name' key={`input-user`} style={{ maxWidth: '100%', marginBottom: '5px' }}></Input>
          </div>
          <div style={{ fontWeight: 'bold', marginLeft: '30px', marginRight: '50px' }}>
            <Input key={`input-user-contact`} style={{ maxWidth: '100%' }} disabled={true} ></Input>
            <div style={{ float: 'right', marginBottom: '0px', marginTop: '5px' }}>
              <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor='#52c41a' />
              <Popconfirm
                  title="Discard user changes."
                  onConfirm={() => {}}
                  onCancel={() => {}}
                  okText="Discard"
                  cancelText="Cancel"
                >
                <CloseSquareTwoTone style={{ marginLeft: '5px', fontSize: '22px' }} twoToneColor='red' />
              </Popconfirm>
            </div>
          </div>
          <div style={{ position: 'absolute', float: 'right', top: '5px', right: '0px' }}>
            <EditTwoTone style={{ marginLeft: '5px', top: '20px', fontSize: '22px' }} />
            <Popconfirm
              title="Delete user."
              onConfirm={() => {}}
              onCancel={() => {}}
              okText="Delete"
              cancelText="Cancel"
            ><DeleteTwoTone style={{ marginLeft: '2px', marginRight: '5px', marginTop: '10px', fontSize: '22px' }} />
          </Popconfirm>
          </div>
        </div>
*/
