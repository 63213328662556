import gql from 'graphql-tag';
import  * as fragments from '../fragments';

export default gql`
  query GetDataObjects($includeInactive: Boolean!) {
    getDataObjects(includeInactive: $includeInactive) {
      ...DataObject
    }
    ${fragments.DataObject}
  }
  query GetDataEntries($collectionUuids: [ID!], $objectUuids: [ID!], $entryUuids: [ID!]) {
    getDataEntries(collectionUuids: $collectionUuids, objectUuids: $objectUuids, entryUuids: $entryUuids) {
      ...DataEntry
    }
    ${fragments.DataEntry}
  }
  query GetDataCollections {
    getDataCollections {
      ...DataCollectionData
    }
    ${fragments.DataCollectionData}
  }
  query GetDataCollectionsById($uuids: [ID!]!) {
    getDataCollectionsById(uuids: $uuids) {
      ...DataCollection
    }
    ${fragments.DataCollection}
  }
  query GetDataPages {
    getDataPages {
      ...DataPage
    }
    ${fragments.DataPage}
  }
  query GetDataPage($uuid: ID!) {
    getDataPage(uuid: $uuid) {
      ...DataPage
    }
    ${fragments.DataPage}
  }
`;
