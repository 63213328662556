import React, { useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import uuid from 'uuid/v4';
import { CenteredDiv, StyledSpan } from '../../../styled/CenteredDiv';
import Table from '../../../table/Table';
import FetchData from '../../../../containers/api/Stock/FetchData';
import { FetchDestination, PlaidAccount, PlaidLinkedInstitution } from '../../../../graphql/generated/graphql';
import { BrokerStockData, LastPrice, Stock, StockOrder, StockTransaction, TransactionAction } from '../../StockTypes';
import ViewOrders from '../../ViewOrders';
import { RobinhoodOrder, RobinhoodOrders } from './ImportRobinhoodTypes';
import { errorNotification } from '../../../actions/Notification';
import moment from 'moment';

type ImportRobinhoodOrdersProps = {
  bearerToken: string;
  institution: PlaidLinkedInstitution;
  existingInvestmentTransactions: Dictionary<StockTransaction[]>;
  stocks: Dictionary<Stock>;
  lastPrice?: Dictionary<LastPrice>
  onUpdateLinkedInstitution: (institution: PlaidLinkedInstitution) => void;
  onUpdateStockDetails: (updateStocks: Stock[]) => void;
  onGetNewInstrumentIds: (instrumentIds: string[]) => void;
  onClose: (portfolio?: any[]) => void;
};
const ImportRobinhoodOrders: React.FC<ImportRobinhoodOrdersProps> = (props: ImportRobinhoodOrdersProps) => {
  const { bearerToken, stocks, existingInvestmentTransactions, lastPrice, onUpdateLinkedInstitution, onUpdateStockDetails, onGetNewInstrumentIds, onClose } = props;
  const [ordersUrl, setOrdersUrl] = useState<string | undefined>('https://api.robinhood.com/orders/');
  const [openOrders, setOpenOrders] = useState<Dictionary<RobinhoodOrders>>({});
  const [instStocks, setInstStocks] = useState<{ existent: Dictionary<Stock>, new: Dictionary<boolean> }>({ existent: {}, new: {} });
  // const [results, setResults] = useState<Dictionary<any>>({});
  const [allOrders, setAllOrders] = useState<{ processed: string[], orders: Dictionary<StockOrder[]>, newInstrument: Dictionary<RobinhoodOrder[]> }>({ processed: [], orders: {}, newInstrument: {} });
  const [viewOrders, setViewOrders] = useState<BrokerStockData<StockOrder>>({});
  const [fetchQuery, setFetchQuery] = useState<{ url: string, fetch: JSX.Element } | undefined>(undefined);
  const [orderStates, setOrderStates] = useState<Dictionary<number>>({});
  const [viewState, setViewState] = useState<string | undefined>(undefined);

  useEffect(() => {
    setInstStocks((prev: { existent: Dictionary<Stock>, new: Dictionary<boolean> }) => {
      const updatePrev = { ...prev };
      let isUpdated = false;
      Object.keys(stocks).forEach((symbol: string) => {
        const instId = stocks[symbol].robinhoodId;
        if (!instId || updatePrev.existent[instId]) {
          return;
        }
        isUpdated = true;
        updatePrev.existent[instId] = stocks[symbol];
      });
      return isUpdated ? updatePrev : prev;
    });
  },        [stocks]);

  useEffect(() => {
    console.log(ordersUrl);
    console.log(openOrders.length);
    const newInstStocks: { existent: Dictionary<Stock>, new: string[] } = { existent: {}, new: [] };
    // grouped by processed url
    const importOrders: Dictionary<{ orders: Dictionary<StockOrder[]>, newInstrument: Dictionary<RobinhoodOrder[]> }> = {};
    const accountMap: Dictionary<string> = {};
    const initOrderStates: Dictionary<number> = {};
    Object.keys(openOrders).forEach((orderUrl: string) => {
      if (allOrders.processed.includes(orderUrl)) {
        return;
      }
      importOrders[orderUrl] = { orders: {}, newInstrument: {} };
      openOrders[orderUrl].results.forEach((orderResult: RobinhoodOrder) => {
        const robinhoodAccountId = orderResult.account.replace('https://api.robinhood.com/accounts/', '').replace('/', '');
        const brokerAccount = props.institution.accounts.find((acc: PlaidAccount) => acc.brokerAccountId === robinhoodAccountId);
        if (!brokerAccount) {
          errorNotification(`Unknown account ${robinhoodAccountId}`);
          return;
        }
        if (!accountMap[robinhoodAccountId]) {
          const account = props.institution.accounts.find((acc: PlaidAccount) => acc.brokerAccountId === robinhoodAccountId);
          if (!account) {
            errorNotification(`Unmapped account: ${robinhoodAccountId}`);
            return;
          }
          accountMap[robinhoodAccountId] = account.accountId;
        }
        const orderAccountId = accountMap[robinhoodAccountId];
        if (!instStocks.existent[orderResult.instrument_id]) {
          if (!newInstStocks.existent[orderResult.instrument_id]) {
            const stock = Object.keys(stocks).find((symbol: string) => stocks[symbol].robinhoodId === orderResult.instrument_id);
            if (stock) {
              newInstStocks.existent[orderResult.instrument_id] = stocks[orderResult.instrument_id];
            }
            else {
              // add instrument_id to fetch instrument obj from robinhood
              if (!newInstStocks.new[orderResult.instrument_id]) {
                newInstStocks.new[orderResult.instrument_id] = true;
              }
              // add robinhoodOrder to process once instrument obj is fetched and Stock obj is created.
              if (!importOrders[orderUrl].newInstrument[orderResult.instrument_id]) {
                importOrders[orderUrl].newInstrument[orderResult.instrument_id] = [];
              }
              importOrders[orderUrl].newInstrument[orderResult.instrument_id].push(orderResult);
              return;
            }
          }
        }
        if (!initOrderStates[orderResult.state]) {
          initOrderStates[orderResult.state] = 0;
        }
        initOrderStates[orderResult.state] = initOrderStates[orderResult.state] + 1;
        const stock = instStocks.existent[orderResult.instrument_id] ?? newInstStocks.existent[orderResult.instrument_id];
        const stockOrder: StockOrder = {
          uuid: orderResult.id,
          action: TransactionAction.BuyOrder,
          broker: 'Robinhood',
          accountId: orderAccountId,
          symbol: stock.ticker,
          securityId: stock.securityId ?? '',
          expiration: orderResult.time_in_force === 'gtc' ? moment(orderResult.created_at).add(90, 'days').toDate() : new Date(),
          price: Number(orderResult.price),
          quantity: Number(orderResult.quantity),
          state: orderResult.state,
          source: 'robinhood',
        };
        if (!importOrders[orderUrl].orders[orderResult.instrument_id]) {
          importOrders[orderUrl].orders[orderResult.instrument_id] = [];
        }
        importOrders[orderUrl].orders[orderResult.instrument_id].push(stockOrder);
      });
    });

    if (Object.keys(importOrders).length > 0) {
      setAllOrders((prevAllOrders: { processed: string[], orders: Dictionary<StockOrder[]>, newInstrument: Dictionary<RobinhoodOrder[]> }) => {
        const updateAllOrders = { ...prevAllOrders };
        let isUpdated = false;
        Object.keys(importOrders).forEach((orderUrl: string) => {
          if (updateAllOrders.processed.find((url: string) => orderUrl === url)) {
            return;
          }
          isUpdated = true;
          updateAllOrders.processed.push(orderUrl);
          Object.keys(importOrders[orderUrl].orders).forEach((instId: string) => {
            importOrders[orderUrl].orders[instId].forEach((order: StockOrder) => {
              if (!updateAllOrders.orders[instId]) {
                updateAllOrders.orders[instId] = [];
              }
              updateAllOrders.orders[instId].push(order);
            });
          });
          Object.keys(importOrders[orderUrl].newInstrument).forEach((instId: string) => {
            importOrders[orderUrl].newInstrument[instId].forEach((robinhoodOrder: RobinhoodOrder) => {
              if (!updateAllOrders.newInstrument[instId]) {
                updateAllOrders.newInstrument[instId] = [];
              }
              updateAllOrders.newInstrument[instId].push(robinhoodOrder);
            });
          });
        });
        return isUpdated ? updateAllOrders : prevAllOrders;
      });
    }
    if (Object.keys(newInstStocks.existent).length > 0 || Object.keys(newInstStocks.new).length > 0) {
      setInstStocks((prev: { existent: Dictionary<Stock>, new: Dictionary<boolean> }) => {
        const updatePrev = { ...prev };
        Object.keys(newInstStocks.existent).forEach((instId: string) => {
          if (!updatePrev.existent[instId]) {
            updatePrev.existent[instId] = newInstStocks.existent[instId];
          }
          if (!updatePrev.new[instId]) {
            updatePrev.new[instId] = newInstStocks.new[instId];
          }
        });
        return updatePrev;
      });
    }
    if (Object.keys(orderStates).length !== Object.keys(initOrderStates).length) {
      setOrderStates((prev) => {
        if (Object.keys(prev).length === Object.keys(initOrderStates).length) {
          return prev;
        }
        const updatePrev = { ...prev };
        Object.keys(initOrderStates).forEach((orderState: string) => {
          if (!updatePrev[orderState]) {
            updatePrev[orderState] = 0;
          }
          updatePrev[orderState] = updatePrev[orderState] + initOrderStates[orderState];
        });
        return updatePrev;
      });
    }
    if (newInstStocks.new.length > 0) {
      onGetNewInstrumentIds(newInstStocks.new);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [openOrders, ordersUrl]);

  useEffect(() => {
    if (!bearerToken || !ordersUrl) {
      if (fetchQuery) {
        setFetchQuery(undefined);
      }
      return;
    }
    if (!fetchQuery || fetchQuery.url !== ordersUrl) {
      const initFetchQuery = <FetchData
        key={`fetch-open-orders-${ordersUrl}`}
        command={ordersUrl}
        token={bearerToken}
        destination={FetchDestination.Robinhood}
        onUpdate={(data?: string, loading?: boolean, error?: any) => {
          if (data) {
            const orders = JSON.parse(data);
            if (!orders) {
              return;
            }
            const ordersObj = orders as RobinhoodOrders;
            setOpenOrders((prevOpenOrders) => {
              const updateOpenOrders = { ...prevOpenOrders };
              updateOpenOrders[ordersUrl] = ordersObj;
              return updateOpenOrders;
            });
            if (ordersObj.next && fetchQuery?.url !== ordersObj.next) {
              setOrdersUrl(ordersObj.next);
            }
            else {
              setOrdersUrl(undefined);
            }
          }
        }}
      />;
      setFetchQuery({ url: ordersUrl, fetch: initFetchQuery });
    }
  },        [bearerToken, fetchQuery, ordersUrl]);

  useEffect(() => {
    const initViewOrders: BrokerStockData<StockOrder> = { Robinhood: {} };
    const accountMap: Dictionary<string> = {};
    Object.keys(allOrders.orders).forEach((instId: string) => {
      allOrders.orders[instId].forEach((order: StockOrder) => {
        if (viewState && viewState !== order.state) {
          return;
        }
        if (!accountMap[order.accountId]) {
          const account = props.institution.accounts.find((acc: PlaidAccount) => acc.brokerAccountId === order.accountId || acc.accountId === order.accountId);
          if (!account) {
            errorNotification(`Unmapped account: ${order.accountId}`);
            return;
          }
          accountMap[order.accountId] = account.accountId;
        }
        if (!initViewOrders.Robinhood[accountMap[order.accountId]]) {
          initViewOrders.Robinhood[accountMap[order.accountId]] = [];
        }
        initViewOrders.Robinhood[accountMap[order.accountId]].push(order);
      });
    });
    setViewOrders(initViewOrders);
  },        [allOrders, props.institution, viewState]);

  return <>
    {fetchQuery ? fetchQuery.fetch : null}
    {ordersUrl ? <div>Fetching from: {ordersUrl}</div> : <div>Fetching: Done</div>}
    {allOrders.processed.length > 0 ? <div>Fetched from {allOrders.processed.length} queries.</div> : null}
    {Object.keys(allOrders).length > 0
      ? <>
        <div>States: {Object.keys(orderStates).map((s: string, index: number) => <span key={`state-${s}`}><StyledSpan bold={s === viewState} onClick={() => {
          setViewState((prev: string | undefined) => {
            return (prev === s ? undefined : s);
          });
        }}>{s} ({orderStates[s]})</StyledSpan>{index === Object.keys(orderStates).length - 1 ? '' : ', '}</span>)}</div>
        <ViewOrders
          key={`view-fetched-orders-${allOrders.processed.length}`}
          existingTransactions={/* Object.keys(allTransactions.transactions).length > 0 && accountId !== '' ? { Robinhood: ({ [accountId] : ([] as StockTransaction[]).concat(...Object.keys(allTransactions.transactions).map((id: string) => allTransactions.transactions[id])) }) } : */ {}}
          existingOrders={viewOrders}
          brokerAccounts={{ Robinhood: Object.assign({}, ...props.institution.accounts.map((account: PlaidAccount) => ({ [account.accountId] : account }))) }}
          stockDetails={Object.assign({}, ...Object.keys(stocks).map((symbol: string) => ({ [symbol]: { details: stocks[symbol], lastPrice: lastPrice ? lastPrice[symbol] : undefined } })))}
          onUpdateOrders={() => {} /* onAddStockData */ } />
        {Object.keys(allOrders.newInstrument).length > 0
          ? <>
            <CenteredDiv>Uknown Instrument data</CenteredDiv>
            {Object.keys(allOrders.newInstrument).map((instId: string) => {
              return (
                <>
                  <div>Instrument Id: ${instId}</div>
                  {allOrders.newInstrument[instId].map((robinhoodOrder: RobinhoodOrder, index: number) => {
                    return <div key={`unknown-instrument-${robinhoodOrder.instrument_id}-order-${index}`}>[{index}] {JSON.stringify(robinhoodOrder)}</div>;
                  })}
                </>);
            })}
          </>
          : null}
        </>
      : null}
  </>;
};

export default ImportRobinhoodOrders;
