import React from 'react';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import { Select as SelectAnt } from 'antd';
import { SelectProps as SelectAntProps } from 'antd/lib/select';
import { TagItem } from './Tag';
import '../../App.css';

type MultiSelectProps = Omit<SelectAntProps<string[]>, 'tagRender|defaultValue'> & {
  customKey?: string;
  items: { values: TagItem[], render?: (renderItem: TagItem, allTags: TagItem[]) => React.ReactElement };
  defaultValue?: string[];
  hasSearch?: boolean;
  width?: string | number;
  onClick?: () => void;
};

type SelectProps = MultiSelectProps & {
  isMultiSelect?: boolean;
};

export const Option = SelectAnt.Option;

export const MultiSelect: React.FC<MultiSelectProps> = (props: MultiSelectProps) => {
  return <Select {...props} isMultiSelect={true} />;
};

const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const { items, customKey, defaultValue, width, onClick, isMultiSelect, hasSearch, children, ...selectProps } = props;

  return (
    //           showSearch={hasSearch ? true : false}
    //           style={{ width }}
    <>
      <div key={customKey} style={{ fontWeight: 'bold', marginBottom: '5px', width: width ? `${width}` : undefined }}>
        <SelectAnt
          key={customKey}
          mode={isMultiSelect ? 'multiple' : undefined}
          value={defaultValue}
          style={{ width: '100%' }}
          defaultValue={defaultValue}
          showSearch={true}
          tagRender={!items.render
            ? undefined
            : (tagVal: CustomTagProps) => {
              const tagItem: TagItem = items.values.find((tag: TagItem) => tag.uuid === tagVal.value)
                ?? { uuid: tagVal.value as string, name: tagVal.label as string, selected: true };
              return items.render ? items.render(tagItem, items.values) : <div key={tagItem.uuid}>{tagItem.name}</div>;
            }
          }
          {...selectProps}
      >
        {children ? children : items.values.map((item: TagItem) => <Option key={`select-option-${item.uuid}`} disabled={item.disabled} value={item.uuid}>{item.name}</Option>)}
      </SelectAnt>
    </div>
  </>
  );
};

export default Select;
