import React, { useEffect }  from 'react';
import { PlaidHoldings, useGetPlaidHoldingsQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type GetHoldingsProps = {
  broker: { name: string, linkedId: string };
  onUpdate?: (data?: { broker: string, holdings: PlaidHoldings }, loading?: boolean, error?: any) => any;
};

const GetHoldings: React.FC<GetHoldingsProps> = (props: GetHoldingsProps) => {
  const { onUpdate, broker } = props;
  const { data: holdings, loading, error } = useGetPlaidHoldingsQuery({
    variables: { accessToken: broker.linkedId },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(holdings ? { broker: broker.name, holdings: holdings.getPlaidHoldings } : undefined, loading, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [holdings, loading, error]);

  return null;
};

export default GetHoldings;
