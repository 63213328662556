import React from 'react';
import FetchData from '../../../../containers/api/Stock/FetchData';
import { FetchDestination } from '../../../../graphql/generated/graphql';

type ImportRobinhoodFetchProps = {
  customKey: string
  url: string;
  bearerToken: string;
  onUpdate: (data?: string, loading?: boolean, error?: any) => void;
};

const ImportRobinhoodFetch: React.FC<ImportRobinhoodFetchProps> = (props: ImportRobinhoodFetchProps) => {
  const { customKey, url, bearerToken, onUpdate } = props;

  return <FetchData
    key={`fetch-robinhood-${customKey}`}
    command={url}
    token={bearerToken}
    destination={FetchDestination.Robinhood}
    onUpdate={onUpdate}
  />;
};

export default ImportRobinhoodFetch;
