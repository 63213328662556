import React, { useContext } from "react";
import { LayoutProps as LayoutPropsAnt } from "antd/lib/layout";
import ThemeContext from "../../context/ThemeContext";
import Tabs from "../atomic/Tabs";
import styled from "styled-components";

export enum LayoutTutorialType {
  None = 0,
  LeftElement,
  RightElement,
  Header,
  Content,
}

type ResponsiveContentSidebarProps = LayoutPropsAnt & {
  customKey: string;
  left?: JSX.Element;
  right: JSX.Element;
  closeable?: ('left' | 'right')[];
  widthLeft?: string | number;
  widthRight?: string | number;
  height?: string | number;
  leftName: string;
  rightName: string;
  separatedScroll?: boolean;
  initialMobilePane?: 'left' | 'right';
  onCloseTab?: (side: 'left' | 'right') => void;
  // tutorial?: { type: LayoutTutorialType, props: WizardTooltipProps, index?: number };
};

type ContentPaneDiv = {
  maxWidth?: string | number;
  width?: string | number;
  height?: string | number;
  scroll?: boolean;
};

const ContentPane = styled.div`
  ${(props: ContentPaneDiv) => `
  ${props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
  ${props.width ? `width: ${props.width};` : (props.maxWidth ? '' : 'width: 50%;')}`}
  ${(props: ContentPaneDiv) => props.scroll ? 'overflow-y: auto;' :''}
  ${(props: ContentPaneDiv) => props.height ? `height: ${props.height}` :''}
  padding: 10px;
  margin: 0px 5px;
  border-radius: 5px;
  border: 1px solid;
`;

const ResponsiveContentSidebar: React.FC<ResponsiveContentSidebarProps> = (props: ResponsiveContentSidebarProps) => {
  const { left, right, closeable, widthLeft, widthRight, leftName, rightName, customKey: key, style, separatedScroll, initialMobilePane, onCloseTab } = props;
  const themeContext = useContext(ThemeContext);

  /*
  const getTutorial = (type: LayoutTutorialType, index: number) => {
    if (!props.tutorial || type !== props.tutorial.type || (props.tutorial.index !== null && index === props.tutorial.index) || (props.tutorial.index === null && index !== 0)) {
      return TutorialNoneProps;
    }
    return props.tutorial.props;
  };
  */

  const tabs: { name: string, value: JSX.Element, closeable?: boolean }[] = [];

  if (left) {
    tabs.push({ name: leftName, value: left, closeable: closeable && closeable.find((isCloseable: 'left' | 'right') => isCloseable === 'left') !== undefined });
  }
  if (right) {
    tabs.push({ name: rightName, value: right, closeable: closeable && closeable.find((isCloseable: 'left' | 'right') => isCloseable === 'right') !== undefined });
  }

  return themeContext.deviceTypeInfo().width < 745
  ? <Tabs
    tabs={tabs}
    fullScreen={true}
    initialTab={initialMobilePane === 'right' ? 1 : 0}
    onEdit={(e: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
      if (onCloseTab && action === 'remove') {
        if (e === '0') {
          onCloseTab('left');
        }
        else if (e === '1') {
          onCloseTab('right');
        }
      }
    }}
  />
  :<div key={key} style={{ display: 'flex', ...style }}>
    <ContentPane width={widthLeft ?? (widthRight ? `calc(100% - ${widthRight})` : undefined)} scroll={separatedScroll} >
      {left}
    </ContentPane>
    <ContentPane width={widthRight ?? (widthLeft ? `calc(100% - ${widthLeft})` : undefined)} scroll={separatedScroll} >
      {right}
    </ContentPane>
  </div>;
};

export default ResponsiveContentSidebar;
