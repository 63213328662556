import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { default as PageComponent } from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
import { Image, Popconfirm, notification } from 'antd';
import { ResultViewEnum } from '../containers/api/Helpers';
import _ from 'lodash';
import ViewCard from '../components/views/Card';
import { GetFiles } from '../containers/api/Storage';
import { DeleteOutlined } from '@ant-design/icons';
import DeleteFile from '../containers/api/Storage/DeleteFile';
import logger from '../utils/Logger';
import { CenteredDiv } from '../components/styled/CenteredDiv';

type PageProps = RouteComponentProps & {
};

const Storage: React.FC<PageProps> = (props: PageProps) => {
  const [lastUpdated, setLastUpdated] = useState<string>(new Date().toLocaleString());
  const [loading, setLoading] = useState<boolean>(false);
  const [/* error, */ setError] = useState<any>(null);
  const [storageData, setStorageData] = useState<any>(null);
  const [deleteFiles, setDeleteFiles] = useState<{ [uuid: string] : JSX.Element }>({});
  const [executeFileDelete, setExecuteFileDelete] = useState<JSX.Element | null>(null);
  const getFilesData = <GetFiles key={lastUpdated} view={ResultViewEnum.Json} updateApiResponse={(data?: any, isLoading?: boolean, error?: any) => {
    if (data) {
      setStorageData(data);
    }
    if (isLoading !== undefined) {
      setLoading(isLoading);
    }
    setError(error);
  }}/>;

  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [storageData]);

  console.log('Storage files');
  console.log(storageData);

  const headerTitle = 'Manage Storage';

  const storageItemsMap = {
    title: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        return Object.keys(mapping).map((m: any) => {
          return <div key={`user-content-${m}`}>{mapping[m].label && mapping[m].label !== '' ? <span style={{ fontWeight: 'bold', width: 100, float: 'left' }}>{mapping[m].label}: </span> : null}{mapping[m].resolve ? mapping[m].resolve(_.get(data, mapping[m].field)) : _.get(data, mapping[m].field)}</div>;
        });
      },
      mapping: {
        storageInfo: {
          image: { label: '', field: 'url', resolve: (url: any) => <Image width={250} src={url} /> },
          // uuid: { label: 'File uuid', field: 'uuid' },
          // eslint-disable-next-line react/jsx-no-target-blank
          // url: { label: 'File url', field: 'url', resolve: (url: any) => <a href={url} target='_blank'>Open in new tab</a> },
          // expirtation: { label: 'Expires on', field: 'expiration' },
          filename: { label: '', field: 'filename' },
        },
      },
    },
  };

  const onConfirmDelete = async (initialData) => {
    const updateDeleteFiles = deleteFiles;
    if (!updateDeleteFiles[initialData.uuid]) {
      logger.info(`Delete file with uuid: ${initialData.uuid}`);
      updateDeleteFiles[initialData.uuid] = <DeleteFile key={initialData.uuid} initialData={initialData} onSuccess={() => {
        notification['success']({
          message: 'Successfully deleted file.',
        });
        const updateWithRemovedFile = deleteFiles;
        delete updateWithRemovedFile[initialData.uuid];
        setDeleteFiles(updateWithRemovedFile);
        const updateExecuteFileDelete: JSX.Element = <>{Object.keys(updateWithRemovedFile).map((key: string) => updateWithRemovedFile[key]).join()}</>;
        setExecuteFileDelete(updateExecuteFileDelete);
        setLastUpdated(new Date().toLocaleString());
      }}/>;
      setDeleteFiles(updateDeleteFiles);
      const updateExecuteFileDelete: JSX.Element = <>{Object.keys(updateDeleteFiles).map((key: string) => updateDeleteFiles[key])}</>;
      setExecuteFileDelete(updateExecuteFileDelete);
    }
    else {
      notification['error']({
        message: 'Failed to delete file. Try again.',
      });
      const updateWithRemovedFile = deleteFiles;
      delete updateWithRemovedFile[initialData.uuid];
      setDeleteFiles(updateWithRemovedFile);
      const updateExecuteFileDelete: JSX.Element = <>{Object.keys(updateWithRemovedFile).map((key: string) => updateWithRemovedFile[key]).join()}</>;
      setExecuteFileDelete(updateExecuteFileDelete);
      logger.info(`File Already marked for deletion: ${initialData.uuid}`);
    }
  };

  const extraMenu = (initialData: any) => {
    return  {
      items: [
        <Popconfirm
          title="Deleted files cannot be restored. Please confirm you want to delete this file."
          onConfirm={() => onConfirmDelete(initialData)} // setUpdateCollection(<EditCollection key={`update-collection-${initialData.uuid}`} action={RecordModalAction.Delete} initialData={initialData} mapping={collectionDataItemsMap} onSuccess={onSuccess} />)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        ><DeleteOutlined disabled={loading} />
        </Popconfirm>, //  onClick={() => setUpdateCollection(<EditCollection key={`update-collection-${initialData.uuid}`} action={RecordModalAction.Delete} initialData={initialData} mapping={collectionDataItemsMap} onSuccess={onSuccess} />)} />,
      ], // (uuid: string, initialData: { [field: string] : string }) => <EditCollectionMenu uuid={uuid} initialData={initialData} />;
    };
  };

  return (
  <PageComponent headerTitle={headerTitle} content={
    <Content style={{ marginTop: 15, maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }}>
      {getFilesData}
      {executeFileDelete}
      <ViewCard
        customKey={'viewcard-uploaded-files'}
        style={{ maxWidth: 300 }}
        data={storageData}
        noDataMessage={<CenteredDiv>There are no files uploaded in your account.</CenteredDiv>}
        mapping={storageItemsMap}
        sourceName='storageInfo'
        extraMenu={extraMenu} />
      <div>last updated: {lastUpdated}</div>
    </Content>
    }
    />
  );
};

//      <DeleteFile key={'e6e0d3d8-607c-4748-a445-28f0f3615331'} initialData={{ uuid: 'e6e0d3d8-607c-4748-a445-28f0f3615331' }} />

// {executeFileDelete}

export default Storage;
