import React, { useState } from 'react';
import { message, Space, Select, Collapse, Card } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
// import { format } from 'graphql-formatter';
// import { print } from 'graphql/language/printer';

import Page, { useWindowSize } from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
import { publicApiDefinition } from '../containers/api/Public';
import * as gqlQueries from '../graphql/queries';
import KeepMounted from '../components/generic/KeepMounted';
import FormDataOld, { FormFields } from '../components/modal/FormDataOld';
import { FieldType, getEnumValues } from '../components/table/DataTypes';
import { dataApiDefinition } from '../containers/api/Data';
import Button from '../components/atomic/Button';
import { ResultViewEnum, ApiProps, getGraphQLQuery as getGQLQuery, JsonDefinitionType } from '../containers/api/Helpers';
import { cognitoApiDefinition } from '../containers/api/Cognito';
import { getResponseKeys } from '../containers/api/Api';
// import { GetDataEntryObjects } from '../containers/api/Entry/DataEntry';
import { GetUser, provisionApiDefinition } from '../containers/api/Provision';
import { userApiDefinition } from '../containers/api/User/User';

// const Ajv = require('ajv');
// const ajv = new Ajv({ allErrors: true, useDefault: true, verbose: true });
// const uuidSchema = { type: 'string', format: 'uuid' };

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

type CssProps = {
  bigWindow: boolean;
  windowWidth: number;
};

// background-color: lightGray;
const ListDiv = styled.div`
  #position: flex;
  float: right;
  #display: flex;
  max-width: 800px;
  padding-right: 10px; /* scrollbar */
  padding-left: 10px;
  padding-bottom: 15px;
  overflow: auto;
  ${(props: CssProps) => props.bigWindow ? `
  margin-left: auto;
  margin-right: auto;` : ''}
  text-align: center;
`;

type ApiBrowserProps = RouteComponentProps & {
};

type QueryType = {
  selectedServer: string,
  selectedEndpoint?: string,
  selectedView: string,
  executeType: 'query' | 'graphql',
  queryArguments: { path: string[], name: string, value: string, type: FieldType }[],
  responseFields: any,
  selectedResponseFields: any;
  date: Date,
};

type QueryResult = {
  query: QueryType,
  result: JSX.Element,
};

const ApiBrowser: React.FC<ApiBrowserProps> = (props: ApiBrowserProps) => {
  const [selectedServer, setSelectedServer] = useState<string>('Data'); // '');
  const [selectedEndpoint, setSelectedEndpoint] = useState<string|undefined>('objects'); // undefined);
  const [selectedAction, setSelectedAction] = useState<string|undefined>('getDataObjects'); // undefined);
  const [selectedView, setSelectedView] = useState<string>('Json'); // '');
  const [queryArguments, setQueryArguments] = useState<{ path: string[], name: string, value: string, type: FieldType }[]>([]);
  const [responseFields, setResponseFields] = useState<any>({});
  // const [queryHistory, setQueryHistory] = useState([]);
  const [resultHistory, setResultHistory] = useState<QueryResult[]>([]);
  const [selectedHistory, setSelectedHistory] = useState<QueryResult|undefined>(undefined);
  const [result, setResult] = useState<JSX.Element|null>(null);
  // const [updatedResult, setUpdatedResult] = useState(null);
  const [minimizeBrowser, setMinimizeBrowser] = useState<boolean>(false);

  // const returnFormRef = React.createRef<FormInstance>();

  const windowSize = useWindowSize();

  let apiSelectWidth = 100;
  const endpoints = Object.keys(gqlQueries);
  endpoints.push('Cognito');
  let apiDefinition: any = null;
  switch (selectedServer) {
    case 'Data': {
      apiDefinition = dataApiDefinition;
      break;
    }
    case 'Public': {
      apiDefinition = publicApiDefinition;
      break;
    }
    case 'Cognito': {
      apiDefinition = cognitoApiDefinition;
      break;
    }
    case 'Provision': {
      apiDefinition = provisionApiDefinition;
      break;
    }
    case 'User': {
      apiDefinition = userApiDefinition;
      break;
    }
    default: {
      apiDefinition = null;
      break;
    }
  }

  const serverOptions = endpoints.map((e: any) => {
    const newWidth = 8 * e.length;
    apiSelectWidth = apiSelectWidth < newWidth ? newWidth : apiSelectWidth;
    return <Select.Option key={`option-server-${e}`} value={e}>{e}</Select.Option>;
  });

  const endpointOptions = apiDefinition ? Object.keys(apiDefinition).map((e: any) => {
    const newWidth = 8 * e.length;
    apiSelectWidth = apiSelectWidth < newWidth ? newWidth : apiSelectWidth;
    Object.keys(apiDefinition[e]).forEach((a: any) => {
      const actionWidth = 8 * a.length;
      apiSelectWidth = apiSelectWidth < actionWidth ? actionWidth : apiSelectWidth;
    });
    return <Select.Option key={`option-endpoint-${e}`} value={e}>{e}</Select.Option>;
  }) : null;

  const actionOptions = apiDefinition && selectedEndpoint && apiDefinition[selectedEndpoint] ? Object.keys(apiDefinition[selectedEndpoint]).map((e: any) => {
    return <Select.Option key={`option-action-${e}`} value={e}>{e}</Select.Option>;
  }) : null;

  const serverItems =
  <Select style={{ width: `${apiSelectWidth}px` }} key='api-select' placeholder="Select API Server" value={selectedServer} onSelect={(value: any) => {
    if (selectedServer !== value) {
      setSelectedServer(value);
      setSelectedEndpoint(undefined);
      setSelectedAction(undefined);
      setResponseFields({});
      setQueryArguments([]);
    }
  }}>
    {serverOptions}
  </Select>;

  const endpointItems =
    <Select style={{ width: `${apiSelectWidth}px` }} key='endpoint-select' placeholder="Select API Endpoint" value={selectedEndpoint} onSelect={(value: any) => {
      if (selectedEndpoint !== value) {
        setSelectedEndpoint(value);
        setSelectedAction(undefined);
        setResponseFields({});
        setQueryArguments([]);
      }
    }}
    >
      {endpointOptions}
    </Select>;

  const actionItems =
    <Select style={{ width: `${apiSelectWidth}px` }} key='action-select' placeholder="Select API Action" value={selectedAction} onSelect={(value: any) => {
      if (selectedAction !== value) {
        setSelectedAction(value);
        setResponseFields({});
      }
    }}
    >
      {actionOptions}
    </Select>;

  const viewItems =
    <Select style={{ width: `${apiSelectWidth}px` }} key='view-select' placeholder="Select Result View" value={selectedView} onSelect={(value: any) => setSelectedView(value)}>
      {getEnumValues(ResultViewEnum).map((v: any) => <Select.Option key={`option-view-${v}`} value={v}>{v}</Select.Option>)}
    </Select>;

  console.log('selectedEndpoint');
  const endpointDefinition = apiDefinition && selectedEndpoint && selectedAction ? apiDefinition[selectedEndpoint][selectedAction] : undefined;
  console.log(selectedEndpoint);
  console.log(JSON.stringify(endpointDefinition ? endpointDefinition.arguments : {}));

  const argumentFields: FormFields = endpointDefinition ? endpointDefinition.arguments : { entries: {} };
  const returnFields: FormFields = endpointDefinition ? endpointDefinition.response : { entries: {} };
  if (responseFields.type) {
    returnFields.entries.response = responseFields;
  }
  const queryFn = endpointDefinition ? endpointDefinition.function : null;
  console.log(argumentFields);
  console.log(returnFields);

  /*
  const updateApiResponse = (data: any) => {
    console.log('updateApiResponse');
    console.log(data);
    setUpdatedResult(data);
  }; */

  const executeQuery = () => {
    // ajv.validate(uuidSchema, queryArguments.uuid); // returns false
    if (!responseFields['value'] || responseFields['value'].checkedKeys.length === 0) {
      message.error('At least one response field must be selected');
      return;
    }
    if (!selectedView) {
      message.error('Please select View');
      return;
    }
    console.log('executeQuery enum');
    console.log(ResultViewEnum[selectedView]);

    if (queryFn) {
      const fields: JsonDefinitionType[] = getResponseKeys(responseFields['value'].checkedKeys, responseFields['values']);
      const apiProps: ApiProps = {
        view: ResultViewEnum[selectedView],
        returnJsx: true,
        fieldFilter: fields,
      };

      const queryArgs = getFnArgs(queryArguments);
      // const queryArgs = Object.assign({}, ...queryArguments.map((x: any) => x.type === FieldType.InputArray ? ({ [x.name]: (x.value ? x.value.split(';') : []) }) : ({ [x.name]: x.value })));
      const resultValue = queryFn(apiProps, queryArgs);
      console.log('executeQuery result');
      console.log(resultValue);
      const queryValue: QueryType = {
        selectedServer,
        selectedEndpoint,
        selectedView,
        executeType: 'query',
        queryArguments,
        responseFields,
        selectedResponseFields: responseFields.value,
        date: new Date(),
      };
      saveHistory({ query: queryValue, result: resultValue });
      setResult(resultValue);
    }
    // console.log(argumentsFormRef);
    // setQueryArguments(argumentsFormRef);
  };

  const saveHistory = (queryResult: QueryResult) => {
    setSelectedHistory(undefined);
    setResultHistory([queryResult, ...resultHistory]);
  };

  const selectHistoryQuery = (query: QueryResult) => {
    const newQuery = query;
    setSelectedHistory(newQuery);
    setSelectedServer(newQuery.query.selectedServer);
    setSelectedEndpoint(newQuery.query.selectedEndpoint);
    setSelectedView(newQuery.query.selectedView);
    newQuery.query.responseFields.value = newQuery.query.selectedResponseFields;
    setResponseFields(newQuery.query.responseFields);
    setQueryArguments(newQuery.query.queryArguments);
    setResult(newQuery.result);
  };

  const getGraphQLQuery = () => {
    if (!responseFields['value'] || responseFields['value'].checkedKeys.length === 0) {
      message.error('At least one response field must be selected');
      return;
    }
    if (!selectedEndpoint) {
      message.error('api endpoint must be selected');
      return;
    }
    if (!selectedAction) {
      message.error('api action must be selected');
      return;
    }
    const cognitoFn = cognitoApiDefinition.cognito.getJwt.function;
    if (queryFn) {
      const jwtFields: JsonDefinitionType[] = getResponseKeys(['jwt'], cognitoApiDefinition.cognito.getJwt.response.entries.response.values);
      const jwtApiProps: ApiProps = {
        view: ResultViewEnum.GraphQL,
        returnJsx: true,
        fieldFilter: jwtFields,
      };
      const jwtResult = cognitoFn(Object.assign({}, jwtApiProps));

      const fields = getResponseKeys(responseFields['value'].checkedKeys, responseFields['values']);
      const queryResult = getGQLQuery(selectedAction, endpointDefinition.arguments.entries, fields, endpointDefinition.type); // queryFn(Object.assign({}, ...queryArguments.map((x: any) => ({ [x.name]: x.value })), apiProps));
      console.log('executeQuery result');
      console.log(queryResult);

      console.log('executeQuery result');
      const resultValue = <pre>{jwtResult}<br />{queryResult}</pre>;
      console.log(resultValue);
      const queryValue: QueryType = {
        selectedServer,
        selectedEndpoint,
        selectedView,
        executeType: 'graphql',
        queryArguments,
        responseFields,
        selectedResponseFields: responseFields.value,
        date: new Date(),
      };
      saveHistory({ query: queryValue, result: resultValue });
      setResult(resultValue);
    }
    // console.log(argumentsFormRef);
    // setQueryArguments(argumentsFormRef);
  };

  const parseArgValues = (data: any, path: string[]) => {
    const fields = Object.keys(data);
    const args: { path: string[], name: string, value: string, type: FieldType }[] = [];
    fields.forEach((field: string) => {
      if (data[field].type) {
        args.push({ path, name: field, value: data[field].value, type: data[field].type });
      }
      else {
        const subArgs = parseArgValues(data[field], [...path, field]);
        args.push(...subArgs);
      }
    });
    return args;
  };

  const getFnArgs = (args: { path: string[], name: string, value: string, type: FieldType }[]) => {
    // const queryArgs = Object.assign({}, ...queryArguments.map((x: any) => x.type === FieldType.InputArray ? ({ [x.name]: (x.value ? x.value.split(';') : []) }) : ({ [x.name]: x.value })));
    const fnArgs = {};
    args.forEach((arg: any) => {
      if (arg.path.length > 0) {
        fnArgs[arg.path[0]] = getFnArgs([{ path: arg.path.split(1), name: arg.name, value: arg.value, type: arg.type }]);
      }
      fnArgs[arg.name] = arg.type === FieldType.InputArray ? (arg.value ? arg.value.split(';') : []) : arg.value;
    });
    return fnArgs;
  };

  const updateArgumentValues = (data: any) => {
    console.log('updateArgumentValues');
    console.log(data);
    // const fields = Object.keys(data);
    const args = parseArgValues(data, []); // fields.map((field: string) => ({ name: field, value: data[field].value, type: data[field].type }));
    setQueryArguments(args);
  };

  const updateResponseValues = (data: any) => {
    console.log('updateResponseValues');
    console.log(data);
    setResponseFields(data.response);
  };

  /*
  const filterFields: JsonDefinitionType[] | undefined = responseFields && responseFields['value'] && responseFields['values'] ? getResponseKeys(responseFields['value'].checkedKeys, responseFields['values']) : undefined;

  const filteredData = updatedResult
    ? (filterFields ? filterResult(updatedResult, filterFields ? filterFields : []) : updatedResult)
    : updatedResult;
  const viewData = !filteredData
    ? <div>Data not loaded</div>
    : (selectedView === ResultViewEnum.Json
      ? <pre style={{ overflow: 'auto', height: 'auto', maxHeight: `${(windowSize.height ?? 550) - 200}px` }}>{JSON.stringify(filteredData, null, 2)}</pre>
      : (selectedView === ResultViewEnum.List
        ? 'List View'
        : (selectedView === ResultViewEnum.Card
          ? 'Card View'
          : 'Select Result View')));
  */

  return (
    <Page headerTitle='Api Browser' content={
      <Content>
        <ListDiv bigWindow={windowSize.width !== undefined && windowSize.width > 800} windowWidth={windowSize.width ? windowSize.width : 800}>
          <Collapse expandIconPosition='right' defaultActiveKey={['1']} onChange={() => setMinimizeBrowser(!minimizeBrowser)} >
            <Collapse.Panel header="Select API" key="1">
              <KeepMounted isMounted={!minimizeBrowser} render={() => {
                return (
                  <React.Fragment>
                    <Space align='start' direction='horizontal'>
                      <Space align='start' direction='vertical' style={{ borderStyle: 'solid', margin: 5, padding: 5 }}>
                        <div style={{ fontWeight: 'bold' }}>Choose API:</div>
                        {serverItems}
                        {endpointItems}
                        {actionItems}
                        {viewItems}
                      </Space>
                      <Space align='start' direction='vertical' style={{ borderStyle: 'solid', margin: 5, padding: 5 }}>
                        <div style={{ fontWeight: 'bold' }}>Arguments</div>
                        {Object.keys(argumentFields.entries).length === 0 ? <div>No arguments</div> : null}
                        <FormDataOld data={argumentFields} updateFieldData={updateArgumentValues}></FormDataOld>
                      </Space>
                      <Space align='start' direction='vertical' style={{ borderStyle: 'solid', margin: 5, padding: 5 }}>
                        <div style={{ fontWeight: 'bold' }}>Select response fields</div>
                        <FormDataOld key={`return-fields-${selectedServer}-${selectedEndpoint}${selectedHistory ? `-${selectedHistory.query.date.toLocaleString()}` : ''}`} data={returnFields} updateFieldData={updateResponseValues}></FormDataOld>
                      </Space>
                    </Space>
                    <Space align='start' direction='vertical'>
                      <Space align='start' direction='horizontal' style={{ borderStyle: 'solid', margin: 5, padding: 5 }}>
                        <Button disabled={selectedServer === '' || selectedEndpoint === ''} onClick={executeQuery}>Execute Query</Button>
                        <Button disabled={selectedServer === '' || selectedEndpoint === ''} onClick={getGraphQLQuery}>Get GraphQL Query</Button>
                        <Button>Save Query</Button>
                        <Button>Save Result</Button>
                      </Space>
                    </Space>
                  </React.Fragment>
                );
              }
            }
            />
            </Collapse.Panel>
          </Collapse>
        </ListDiv>
        <GetUser view={ResultViewEnum.Json} />
        <Space align='start' direction='horizontal'>
          <Space align='start' direction='vertical' style={{ maxWidth: 250, borderStyle: 'solid', margin: 5, padding: 5 }}>
            <div>HISTORY</div>
            {resultHistory
              ? resultHistory.map((r: any) => <Card key={`history-${r.query.date.toLocaleString()}`} style={{ background: `${selectedHistory && r.query.date === selectedHistory.query.date ? 'blue' : 'gray' }` }} onClick={() => selectHistoryQuery(r)}><div>{r.query.date.toLocaleString()}</div><div>{r.query.selectedServer}.{r.query.selectedEndpoint}</div></Card>)
              : null}
          </Space>
          <Space align='start' direction='vertical' style={{ borderStyle: 'solid', margin: 5, padding: 5 }}>
            <div>RESULT</div>
            {result}
          </Space>
        </Space>
      </Content>
    }
    />
  );
};

// {false === false ? null : <GetDataEntryObjects view={ResultViewEnum.List} />}
// {queryArguments && queryArguments[0] ? (<div dangerouslySetInnerHTML={ { __html: queryArguments[0].value } } />) : null}

export default ApiBrowser;
