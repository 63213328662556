import React from 'react';
import RecordModal, { ModalFields, RecordModalAction } from '../../../modal/RecordModal';
import { infoNotification } from '../../../actions/Notification';
import { RecordType } from '../../../table/DataTypes';

type ImportRobinhoodInitProps = {
  onUpdateBearerToken: (bearerToken: string) => void;
  onClose: () => void;
};
const ImportRobinhoodInit: React.FC<ImportRobinhoodInitProps> = (props: ImportRobinhoodInitProps) => {
  const { onUpdateBearerToken, onClose } = props;

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          BearerToken:  { placeholder: 'Enter Robinhood Bearer Token', type: RecordType.Text, value: '' },
        },
      },
    ],
  };

  return <RecordModal
    key={'robinhood-init'}
    data={recordDataValues}
    action={RecordModalAction.Custom}
    title={'Initialize Robinhood'}
    onCancel={() => {
      onClose();
    }}
    onSubmit={(data: any) => {
      infoNotification('Fetching data ...');
      let token = data.pages[0].entries['BearerToken'].value;
      token = token.replace('authorization: ', '').replace('Bearer ', '').replace(/\n$/, '');
      onUpdateBearerToken(token);
    }}
  />;
};

export default ImportRobinhoodInit;
