import React, { Component } from 'react';
import { Typography } from 'antd';
import Page from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';

const { Title } = Typography;

// background: #fff;
/*
const Content = styled(Layout)`
  min-height: 100%;
`; */

class Settings extends Component {
  render() {
    return (
    <Page content={
      <Content>
        <Title>Settings Page</Title>
      </Content>
    }
    />);
  }
}

export default Settings;
