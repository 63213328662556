export const dataClientContext = { context: { clientName: 'data' } };

export const getResponseKeys = (keys: string[], fields: any) => {
  console.log(`getResponseKeys keys: ${keys}\nfields: ${JSON.stringify(fields, null, 2)}`);
  const fieldKeys: any[] = [];
  fields.forEach((f: any) => {
    if (f.children) {
      const childKeys = getResponseKeys(keys, f.children);
      if (childKeys.length > 0) {
        fieldKeys.push({ title: f.title, key: f.key, children: childKeys });
      }
    }
    else if (keys.find((k: string) => k === f.key)) {
      fieldKeys.push(f);
    }
  });
  return fieldKeys;
};

export const getResponseDataObjs = (objs: string[], keyPath?: string, parentKey?: string) => {
  const dataObjs = objs.map((o: string) => {
    return getResponseDataObj(o, keyPath, parentKey);
  });
  return dataObjs;
};

export const getResponseDataObj = (o: string, keyPath?: string, parentKey?: string) => {
  const nodeKey = parentKey ? `${parentKey}-${o}` : `${o}`;
  return ({ title: `${o}`, key: keyPath ? `${keyPath}-${nodeKey}` : `${nodeKey}` });
};

export const getResponseDataNode = (o: string, childrenFn: (parentKey: string) => any[], keyPath?: string) => {
  const key = keyPath ? `${keyPath}-${o}` : o;
  return {
    title: o,
    key,
    children: [...childrenFn(key)],
  };
};
