import React, { useEffect, useState } from 'react';
import { PlaidAccount } from '../../graphql/generated/graphql';
import { CenteredDiv } from '../styled/CenteredDiv';
import { RecordType } from '../table/DataTypes';
import { EditableColumnProps } from '../table/EditableTable';
import { Dictionary } from 'lodash';
import Table from '../table/Table';
import { BrokerStockData, getBrokerStockDataFlattened, LastPrice, Stock, StockHolding, StockLotMapping, StockOrder, StockTransaction, SymbolStockData } from './StockTypes';
import HoldingFilter, { HoldingColumns, HoldingSettings } from './HoldingFilter';
import moment from 'moment';
import { errorMessage } from '../actions/ErrorMessage';
import styled from 'styled-components';

type MissingTransaction = {
  transaction: StockTransaction;
  reason: 'missing sell' | 'missing buy';
};

const FieldsDiv = styled.div`
  margin: 10px;
  width: 100vh;
  display: block
`;

type HoldingFilters = {
  broker: string[];
  account: string[];
  symbol: string[];
};

type HoldingData = {
  key: string;
  row: number;
  broker: string;
  symbol: string;
  name: string;
  account: string;
  quantity: number | null;
  buy_price: string | null;
  current_price?: number | null;
  cost_basis: string | null;
  current_value: number | null;
  price_last_fetched?: Date;
  holding: StockHolding, // aggregate lot
  numberOfLots?: number; // individual lots
  transactionId?: string,
  hold: 'Short' | 'Long' | 'Mixed';
};

export const getTransactionHoldings = (transactions: BrokerStockData<StockTransaction>): { holdings: BrokerStockData<StockHolding>, missingBuyTransactions: BrokerStockData<MissingTransaction> }  => {
  const holdings: BrokerStockData<StockHolding> = {};
  const missingBuyTransactions: BrokerStockData<MissingTransaction> = {};
  if (Object.keys(transactions).length === 0) {
    return { holdings, missingBuyTransactions };
  }
  Object.keys(transactions).forEach((broker: string) => {
    if (!holdings[broker]) {
      holdings[broker] = {};
    }
    Object.keys(transactions[broker]).forEach((account: string) => {
      if (!holdings[broker][account]) {
        holdings[broker][account] = [];
      }
      const accountTransactions = transactions[broker][account].sort((a: StockTransaction, b: StockTransaction) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        if (a.type === 'buy') {
          return -1;
        }
        if (b.type === 'buy') {
          return 1;
        }
        return 1;
      });
      const lots: { buy: StockTransaction, sell: { transaction: StockTransaction, quantity: number }[], soldQuantity: number }[] = [];
      accountTransactions.forEach((transaction: StockTransaction) => {
        if (transaction.type === 'buy' || (transaction.type === undefined && transaction.quantity > 0)) {
          let soldQuantity = 0;
          const sellLots: { transaction: StockTransaction, quantity: number }[] = [];
          if (transaction.lots) {
            transaction.lots.forEach((mappedLot: StockLotMapping) => {
              const sellTrans = accountTransactions.find((trans: StockTransaction) => trans.transactionId === mappedLot.sellUuid);
              if (!sellTrans) {
                if (!missingBuyTransactions[transaction.broker]) {
                  missingBuyTransactions[transaction.broker] = {};
                }
                if (!missingBuyTransactions[transaction.broker][transaction.accountId]) {
                  missingBuyTransactions[transaction.broker][transaction.accountId] = [];
                }
                missingBuyTransactions[transaction.broker][transaction.accountId].push({ transaction, reason: 'missing sell' });
                // infoNotification(`Missing sell transaction for ${transaction.symbol} with uuid: ${mappedLot.sellUuid}`);
                return;
              }
              soldQuantity += mappedLot.quantity;
              sellLots.push({ transaction: sellTrans, quantity: mappedLot.quantity });
            });
          }
          lots.push({ buy: transaction, sell: sellLots, soldQuantity });
        }
        else {
          let buyQty = transaction.quantity < 0 ? -transaction.quantity : transaction.quantity;
          lots.forEach((lot: { buy: StockTransaction, sell: { transaction: StockTransaction, quantity: number }[], soldQuantity: number }) => {
            if (buyQty === 0 || lot.buy.date > transaction.date) {
              return;
            }
            const sellQty = lot.soldQuantity;
            if (sellQty < lot.buy.quantity) {
              const qty = lot.buy.quantity - sellQty < buyQty ? lot.buy.quantity - sellQty : buyQty;
              lot.sell.push({ transaction, quantity: qty });
              buyQty = buyQty - qty;
              lot.soldQuantity = sellQty + qty;
            }
          });
          if (buyQty !== 0) {
            if (!missingBuyTransactions[transaction.broker]) {
              missingBuyTransactions[transaction.broker] = {};
            }
            if (!missingBuyTransactions[transaction.broker][transaction.accountId]) {
              missingBuyTransactions[transaction.broker][transaction.accountId] = [];
            }
            missingBuyTransactions[transaction.broker][transaction.accountId].push({ transaction, reason: 'missing buy' });
            // errorNotification(`There are not enough Buy Transactions before sell transaction with uuid ${transaction.transactionId} on ${transaction.date} for ${transaction.symbol}`);
          }
        }
      });
      lots.forEach((lot: { buy: StockTransaction, sell: { transaction: StockTransaction, quantity: number }[], soldQuantity: number }) => {
        if (lot.soldQuantity < lot.buy.quantity) {
          const addHolding: StockHolding = {
            transactionId: lot.buy.transactionId,
            broker: lot.buy.broker,
            accountId: lot.buy.accountId,
            priceDate: lot.buy.date,
            securityId: lot.buy.securityId,
            symbol: lot.buy.symbol,
            costBasis: lot.buy.amount,
            price: lot.buy.price ?? null,
            quantity: lot.buy.quantity - lot.soldQuantity,
            value: null,
          };
          holdings[lot.buy.broker][lot.buy.accountId].push(addHolding);
        }
      });
    });
  });
  return { holdings, missingBuyTransactions };
};

type ViewHoldingsProps = {
  existingHoldings: BrokerStockData<StockHolding>;
  existingTransactions: BrokerStockData<StockTransaction>;
  existingOrders: BrokerStockData<StockOrder>;
  brokerAccounts: Dictionary<Dictionary<PlaidAccount>>;
  stockDetails: Dictionary<Stock>;
  // securities: Dictionary<Dictionary<PlaidSecurity>>
  lastPrices?: Dictionary<LastPrice>;
  mappedStock?: string;
  readOnly?: boolean;
};
const ViewHoldings: React.FC<ViewHoldingsProps> = (props: ViewHoldingsProps) => {
  const { existingHoldings, existingTransactions, existingOrders, brokerAccounts, stockDetails, lastPrices, mappedStock, readOnly } = props;
  const [allHoldings, setAllHoldings] = useState<StockHolding[]>([]);
  const [holdingsData, setHoldingsData] = useState<HoldingData[]>([]);
  const [holdingLotsData, setHoldingsLotsData] = useState<SymbolStockData<HoldingData>>({}); // symbol / broker / account
  const [missingBuyTransactions, setMissingBuyTransactions] = useState<MissingTransaction[]>([]);
  const [importedHoldingsData, setImportedHoldingsData] = useState<HoldingData[]>([]);
  const [holdingSettings, setHoldingSettings] = useState<HoldingSettings>({ view: [HoldingColumns.Broker] });
  const [showTransactionErrors, setShowTransactionErrors] = useState<boolean>(false);
  const [holdingFilters, setHoldingFilters] = useState<HoldingFilters>({ broker: [], account: [], symbol: [] });

  useEffect(() => {
    if (allHoldings.length === 0) {
      const allHoldings: StockHolding[] = getBrokerStockDataFlattened(existingHoldings);
      const brokerTransactionHoldings : { holdings: BrokerStockData<StockHolding>, missingBuyTransactions: BrokerStockData<MissingTransaction> } = existingTransactions ? getTransactionHoldings(existingTransactions) : { holdings: {}, missingBuyTransactions: {} };
      const transactionHoldings = getBrokerStockDataFlattened(brokerTransactionHoldings.holdings);
      const missingTransactions = getBrokerStockDataFlattened(brokerTransactionHoldings.missingBuyTransactions);
      allHoldings.push(...transactionHoldings);
      setMissingBuyTransactions(missingTransactions);
      setAllHoldings(allHoldings);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (allHoldings.length !== 0) {
      const initFilters: HoldingFilters =
        {
          broker: [] as string[],
          account: [] as string[],
          symbol: [] as string[],
        };
      const initHoldings: SymbolStockData<HoldingData> = {};
      const initImportedHoldings: HoldingData[] = [];
      const initAggregateHoldings: HoldingData[] = [];
      allHoldings
        .forEach((holding: StockHolding, index: number) => {
          const security = holding.symbol && stockDetails[holding.symbol]
          ? {
            symbol: stockDetails[holding.symbol].ticker === '' ? holding.symbol : stockDetails[holding.symbol].ticker,
            name: stockDetails[holding.symbol].name,
          }
          : {
            symbol: holding.symbol,
            name: '--',
          };
          const lastPrice = lastPrices && lastPrices[security.symbol] && lastPrices[security.symbol].price ? Number(parseFloat(lastPrices[security.symbol].price.last).toFixed(2)) : null;
          const lastPriceDate = moment(holding.priceDate);
          const isShort = lastPriceDate.isValid() ? lastPriceDate.diff(new Date(), 'days') <= 365 : null;

          const holdingData: HoldingData = {
            key: `${security.symbol}-${holding.broker}-${holding.accountId}-${holding.transactionId ?? 'imported'}-${index}`,
            row: index,
            broker: holding.broker,
            symbol: security.symbol,
            name: security.name,
            account: holding.accountId && brokerAccounts[holding.broker] && brokerAccounts[holding.broker][holding.accountId] ? (brokerAccounts[holding.broker][holding.accountId].name ?? '--') : '--',
            quantity: holding.quantity,
            buy_price: holding.costBasis !== null && holding.quantity !== null ? (holding.costBasis / holding.quantity).toFixed(2) : null,
            current_price: lastPrice,
            cost_basis: holding.costBasis ? holding.costBasis.toFixed(2) : null,
            current_value: holding.quantity !== null && lastPrice ? (holding.quantity * lastPrice) : null,
            price_last_fetched: lastPrices && lastPrices[security.symbol] ? lastPrices[security.symbol].lastFetched : undefined,
            holding,
            hold: isShort ? 'Short' : 'Long',
          };
          if (!initFilters['symbol'].find((symbol: string) => symbol === security.symbol)) {
            initFilters['symbol'].push(security.symbol ?? '--');
          }
          if (!initFilters['account'].find((account: string) => account === holdingData.account)) {
            initFilters['account'].push(holdingData.account);
          }
          if (!initFilters['broker'].find((broker: string) => broker === holdingData.broker)) {
            initFilters['broker'].push(holdingData.broker);
          }
          if (holdingData.holding.transactionId) {
            if (!initHoldings[holdingData.holding.symbol]) {
              initHoldings[holdingData.holding.symbol] = {};
            }
            if (!initHoldings[holdingData.symbol][holdingData.broker]) {
              initHoldings[holdingData.symbol][holdingData.broker] = {};
            }
            if (!initHoldings[holdingData.symbol][holdingData.broker][holdingData.account]) {
              initHoldings[holdingData.symbol][holdingData.broker][holdingData.account] = [];
            }
            initHoldings[holdingData.symbol][holdingData.broker][holdingData.account].push(holdingData);
          }
          else {
            initImportedHoldings.push(holdingData);
          }
        });
      Object.keys(initHoldings).forEach((symbol: string) => {
        Object.keys(initHoldings[symbol]).forEach((broker: string) => {
          Object.keys(initHoldings[symbol][broker]).forEach((account: string) => {
            const numberOfLots = initHoldings[symbol][broker][account].length;
            if (numberOfLots === 0) {
              return;
            }
            const holdingData: HoldingData = { ...initHoldings[symbol][broker][account][0] };
            holdingData.key = `${holdingData.symbol}-${holdingData.broker}-${holdingData.account}-aggregate-`;
            let aggQuantity = 0;
            let aggCostBasis = 0;
            let aggCount = 0;
            initHoldings[symbol][broker][account].forEach((lotHolding: HoldingData) => {
              if (lotHolding.quantity === undefined || lotHolding.cost_basis === undefined) {
                return;
              }
              aggCount += 1;
              aggQuantity += lotHolding.quantity!;
              aggCostBasis += Number(lotHolding.cost_basis);
              if (holdingData.hold !== 'Mixed' && holdingData.hold !== lotHolding.hold) {
                holdingData.hold = 'Mixed';
              }
            });
            holdingData.quantity = aggQuantity;
            holdingData.buy_price = (aggQuantity === 0 ? 0 : aggCostBasis / aggQuantity).toFixed(2);
            holdingData.cost_basis = (aggCount === 0 ? aggCostBasis : aggCostBasis / aggCount).toFixed(2);
            holdingData.numberOfLots = numberOfLots;
            initAggregateHoldings.push(holdingData);
          });
        });
      });
      setHoldingsLotsData(initHoldings);
      setHoldingsData(initAggregateHoldings);
      setImportedHoldingsData(initImportedHoldings);
      setHoldingFilters(initFilters);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [allHoldings]);

  const noHoldings = <>
      <CenteredDiv>There are no known holdings in your accounts.</CenteredDiv>
    </>;

  const holdingsColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      hidden: true,
      render: (text: string, record: any) => (
        <div>{text}</div>
      ),
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      filters: holdingFilters.broker.map((broker: string) => ({ text: broker, value: broker })),
      hidden: !holdingSettings.view.includes(HoldingColumns.Broker),
      onFilter: (value, record) => {
        return record.broker.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.broker.toLowerCase() < two.broker.toLowerCase() ? -1 : 1);
        },
        multiple: 1,
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      filters: holdingFilters.account.map((account: string) => ({ text: account, value: account })),
      hidden: !holdingSettings.view.includes(HoldingColumns.Account),
      onFilter: (value, record) => {
        return record.account.indexOf(value) === 0;
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      filters: ['crypto', 'stocks', ...holdingFilters.symbol.sort((a: string, b: string) => a < b ? -1 : 1)].map((account: string) => ({ text: account, value: account })),
      hidden: !holdingSettings.view.includes(HoldingColumns.Symbol),
      onFilter: (value, record) => {
        if (value === 'crypto') {
          return record.symbol.includes('CUR:');
        }
        if (value === 'stocks') {
          return !record.symbol.includes('CUR:');
        }
        return record.symbol.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return one.symbol && two.symbol ? (one.symbol.toLowerCase() < two.symbol.toLowerCase() ? -1 : 1) : (one.symbol ? -1 : 1);
        },
        multiple: 2,
      },
      inputType: { recordType: RecordType.Text },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Average Buy Price',
      dataIndex: 'buy_price',
      key: 'buy_price',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Current Price',
      dataIndex: 'current_price',
      key: 'current_price',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Average Cost Basis',
      dataIndex: 'cost_basis',
      key: 'cost_basis',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Current Value',
      dataIndex: 'current_value',
      key: 'current_value',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Total Change',
      dataIndex: 'total_change',
      key: 'total_change',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => (
        <div style={{ color: record.current_value < record.cost_basis ? 'red' : 'green' }} >{(record.current_value - record.cost_basis).toFixed(2)} ({((record.current_value - record.cost_basis) / record.cost_basis).toFixed(2)}%)</div>
      ),
    },
    {
      title: 'Hold',
      dataIndex: 'hold',
      key: 'hold',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        const value = record.hold;
        const color = value === 'Short' ? 'red' : (value === 'Long' ? 'green' : undefined);
        return <div style={{ color }} >{value}</div>;
      },
    },
  ];

  const expandableHoldingColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      hidden: true,
      render: (text: string, record: any) => (
        <div>{text}</div>
      ),
    },
    /*
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (one, two) => {
          return one.name && two.name ? (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1) : (one.name ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => (
        record.name && record.name.length > 50 ? <Tooltip title={record.name}>
        <div>{record.name.substring(0, 50)}...</div>
        </Tooltip> : <div>{record.name}</div>
      ),
    },
    */
    {
      title: 'Date',
      dataIndex: 'priceDate',
      key: 'priceDate',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        const priceDate = moment(record.holding.priceDate);
        return <div>{priceDate.isValid() ? priceDate.format('YYYY-MM-DD') : record.holding.priceDate}</div>;
      },
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Buy Price',
      dataIndex: 'buy_price',
      key: 'buy_price',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Current Price',
      dataIndex: 'current_price',
      key: 'current_price',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Cost Basis',
      dataIndex: 'cost_basis',
      key: 'cost_basis',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Current Value',
      dataIndex: 'current_value',
      key: 'current_value',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Total Change',
      dataIndex: 'total_change',
      key: 'total_change',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => (
        <div style={{ color: record.current_value < record.cost_basis ? 'red' : 'green' }} >{(record.current_value - record.cost_basis).toFixed(2)} ({((record.current_value - record.cost_basis) / record.cost_basis).toFixed(2)}%)</div>
      ),
    },
    {
      title: 'Hold',
      dataIndex: 'hold',
      key: 'hold',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        const lastPrice = moment(record.holding.priceDate);
        const isShort = lastPrice.isValid() ? lastPrice.diff(new Date(), 'days') <= 365 : null;
        const value = lastPrice.isValid() ? (isShort ? 'Short' : 'Long') : '--';
        const color = lastPrice.isValid() ? (isShort ? 'red' : 'green') : undefined;
        return <div style={{ color }} >{value}</div>;
      },
    },
  ];

  return <>
    <CenteredDiv>
      <br />
      <CenteredDiv bold>Holdings{mappedStock ? ` for mapped stock ${mappedStock}` : ''}: <HoldingFilter settings={holdingSettings} onUpdateFilter={(settings: HoldingSettings) => {
        setHoldingSettings(settings);
      }} /></CenteredDiv>
      {missingBuyTransactions.length > 0 ? errorMessage(<span>Transacation data is missing. <span onClick={() => setShowTransactionErrors(!showTransactionErrors)}>{showTransactionErrors ? 'Hide' : 'View'} error details</span></span>) : null}
      {showTransactionErrors ? missingBuyTransactions.map((missingTrans: MissingTransaction) => {
        return <div>{missingTrans.reason} | {missingTrans.transaction.date} | {missingTrans.transaction.symbol}</div>;
      }) : null}
      {allHoldings.length > 0 ? <>
        <Table
          style={{ overflow: 'auto' }}
          rowSelection={readOnly ? undefined : {
            type: 'checkbox',
            onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              // setSelectedHoldings(selectedRows);
            },
            getCheckboxProps: (record: any) => ({
              name: record.row,
            }),
          }}
          pagination={false}
          className='.antd-documents-table'
          size='small'
          // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
          columns={holdingsColumns}
          dataSource={holdingsData}
          expandable={{
            expandedRowRender: (record: any) => {
              const fieldData: HoldingData[] = holdingLotsData[record.symbol][record.broker][record.account];
              return (<FieldsDiv><Table
                className='.antd-document-fields'
                size='small'
                columns={expandableHoldingColumns}
                dataSource={fieldData}
                bordered={true}
                pagination={false}
              /></FieldsDiv>);
            },
            rowExpandable: record => record.name !== 'Not Expandable',
          }}
          bordered={true}
        /></> : noHoldings}
    </CenteredDiv>
  </>;
};

export default ViewHoldings;
