import gql from 'graphql-tag';

import User from '../fragments/user/User';

export default gql`
  query GetUser {
    getUser {
      ...User
    }
  }
  ${User}
`;
