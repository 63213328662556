import React, { useState } from 'react';
import HtmlTableToJson from 'html-table-to-json';
import uuid from 'uuid/v4';
import FetchData from '../../../containers/api/Stock/FetchData';
import { FetchDestination, PlaidAccount, PlaidAccountInput, PlaidLinkedInstitution, PlaidLinkedInstitutionInput } from '../../../graphql/generated/graphql';
import { infoNotification } from '../../actions/Notification';
import Button from '../../atomic/Button';
import { MultiSelect, Option } from '../../atomic/Select';
import Tag from '../../atomic/Tag';
import RecordModal, { ModalFields, RecordModalAction } from '../../modal/RecordModal';
import { CenteredDiv } from '../../styled/CenteredDiv';
import { RecordType } from '../../table/DataTypes';
import { EditableColumnProps } from '../../table/EditableTable';
import Table from '../../table/Table';
import { Stock, StockDataSourceType, StockOrder, StockTransaction, TransactionAction } from '../StockTypes';
import { Dictionary } from 'lodash';
import ImportInvestmentTransactions from './ImportInvestmentTransactions';
import Dropdown, { DropdownType } from '../../atomic/Dropdown';
import ImportIcon from '../../icons/ImportIcon';
import UpdateLinkedInstitutions from '../../../containers/api/Plaid/UpdateLinkedInstitutions';

export type FidelityStockId = {
  account: string[]
  cusip?: string;
  assetId?: string;
  symbol: string
};

enum FidelityImportType {
  Orders = 'Orders',
  OpenTransactions = 'Open Transactions',
  ClosedTransactions = 'Closed Transactions',
}

type ImportFidelityData = {
  symbol: string;
  importType: FidelityImportType;
};

export const getFidelityOpenLotsUrl = (account: string, cusip: string, assetId: string, symbol: string, closedLots?: boolean) => {
  const url = `https://oltx.fidelity.com/ftgw/fbc/ofpositions/${closedLots ? 'closedLots' : 'openLots'}?${account ? `ACCOUNT=${account}&` : ''}QUOTE_REQUEST_ID=E${cusip ? `&CUSIP=${cusip}` : ''}${assetId ? `&ASSET_ID=${assetId}` : ''}&ACCT_TYPE_CODE=1&POSITION_TYPE_CODE=L${symbol ? `&SYMBOL=${symbol}` : ''}${symbol ? `&FBSI_SYMBOL_ID=${symbol}` : ''}&BROKERAGE_ACCOUNT_TYPE=1${symbol ? `&QUERY_FBSI_SYMBOL_ID=${symbol}` : ''}`;
  return url;
};

type ImportFidelityOpenOrdersProps = {
  institution: PlaidLinkedInstitution;
  existingInvestmentTransactions: Dictionary<StockTransaction[]>;
  stockDetails: Dictionary<Stock>;
  onImportInvestmentTransactions: (investments: Dictionary<StockTransaction[]>, updateStockDetails: Stock[], importAccounts: Dictionary<Dictionary<PlaidAccount>>) => void;
  onUpdateStockDetails: (updateStocks: Stock[]) => void;
  onUpdateLinkedInstitution: (institution: PlaidLinkedInstitution) => void;
  onClose: (fidelityStockIds?: FidelityStockId[]) => void;
};
const ImportFidelityOpenOrders: React.FC<ImportFidelityOpenOrdersProps> = (props: ImportFidelityOpenOrdersProps) => {
  const { existingInvestmentTransactions, stockDetails, onImportInvestmentTransactions, onUpdateStockDetails, onClose } = props;
  const [cookie, setCookie] = useState<string | undefined>(undefined);
  const [symbolIds, setSymbolIds] = useState<FidelityStockId[]>([]);
  const [fetchSymbols, setFetchSymbols] = useState<string[]>([]);
  const [loadLots, setLoadLots] = useState<boolean>(false);
  const [loadClosedLots, setLoadClosedLots] = useState<boolean>(false);
  const [loadOrders, setLoadOrders] = useState<boolean>(false);
  const [showLoadedSymbols, setShowLoadedSymbols] = useState<boolean>(true);
  const [openLots, setOpenLots] = useState<Dictionary<StockTransaction[]>>({});
  const [openOrders, setOpenOrders] = useState<StockOrder[]>([]);
  const [importData, setImportData] = useState<ImportFidelityData[]>([]);
  const [updateAccounts, setUpdateAccounts] = useState<{ uuid: string, institution: PlaidLinkedInstitutionInput }[]>([]);

  const urlPositions = 'https://oltx.fidelity.com/ftgw/fbc/ofpositions/snippet/portfolioPositions';

  // data.split('openLots?').map(str => str ? ({ account: str.split('ACCOUNT=')[1]?.split('&')[0], cusip: str.split('CUSIP=')[1].split('&')[0], asset_id: str.split('ASSET_ID=')[1].split('&')[0], symbol: str.split('SYMBOL=')[1]?.split('&')[0] })  : '')
  // data.split('lotsUrl?').map(str => str ? ({ account: str.split('ACCOUNT=')[1]?.split('&')[0], cusip: str.split('CUSIP=')[1].split('&')[0], asset_id: str.split('ASSET_ID=')[1].split('&')[0], symbol: str.split('SYMBOL=')[1]?.split('&')[0] })  : '')

  const fetchPortfolio =
  cookie && symbolIds.length === 0 ? <FetchData
    key='fetch-data'
    command={urlPositions}
    token={cookie}
    destination={FetchDestination.Fidelity}
    onUpdate={(data?: string, loading?: boolean, error?: any) => {
      if (data) {
        if (data.includes('Fidelity.com is Temporarily Unavailable')) {
          infoNotification('Failed to get stock details from Fidelity.com. Service is Temporarily Unavailable');
          return;
        }
        if (data.includes('Reason>Refresh</Reason>')) {
          infoNotification('Cookie has expired, please enter a new cookie');
          setCookie(undefined);
          return;
        }
        const updateSymbolIds = [...symbolIds];
        const parseUrls = [/* ...data.split('ftgw/fbc/ofpositions'), */ ...data.split('json-views=')];
        const updateStocks: Stock[] = [];
        const updateAccountsWithId: Dictionary<PlaidAccountInput> = {};
        parseUrls.forEach((str: string) => {
          if (str && (str.includes('openLots') || str.includes('lotsURL'))) {
            const account = str.split('ACCOUNT=')[1]?.split('&')[0];
            const cusip = str.split('CUSIP=')[1]?.split('&')[0];
            const assetId = str.split('ASSET_ID=')[1]?.split('&')[0];
            const symbol = str.split('SYMBOL=')[1]?.split('&')[0];

            if (account && !updateAccountsWithId[account]) {
              const existingAccount: PlaidAccount | undefined = props.institution.accounts.find((acc: PlaidAccount) => {
                return acc.lastFour && account.includes(acc.lastFour);
              });
              if (existingAccount && (!existingAccount.brokerAccountId || existingAccount.brokerAccountId !== account)) {
                const update: PlaidAccountInput = {
                  uuid: existingAccount.uuid,
                  accountId: existingAccount.accountId,
                  brokerAccountId: account, // main item to update
                  accountName: existingAccount.name ?? '',
                  bankName: props.institution.institutionName,
                };
                updateAccountsWithId[account] = update;
              }
            }

            if (symbol && stockDetails[symbol] && assetId && !stockDetails[symbol].fidelityAssetId && !updateStocks.find((stock: Stock) => stock.ticker === symbol)) {
              const updateStock: Stock = { ...stockDetails[symbol] };
              updateStock.fidelityAssetId = assetId;
              updateStocks.push(updateStock);
            }

            const index = updateSymbolIds.findIndex((stock: FidelityStockId) => {
              return stock.assetId === assetId || stock.cusip === cusip || stock.symbol === symbol;
            });
            if (index < 0) {
              const newStock: FidelityStockId = { account: account ? [account] : [], cusip, assetId, symbol };
              updateSymbolIds.push(newStock);
            }
            else {
              if (!updateSymbolIds[index].assetId && assetId) {
                updateSymbolIds[index].assetId = assetId;
              }
              else if (assetId && updateSymbolIds[index].assetId !== assetId) {
                console.log(`Fidelity Stock Id assetId mismatch: ${updateSymbolIds[index].assetId} ${assetId} - cusip: ${updateSymbolIds[index].cusip}, symbol: ${updateSymbolIds[index].symbol}`);
              }
              if (!updateSymbolIds[index].symbol && symbol) {
                updateSymbolIds[index].symbol = symbol;
              }
              else if (symbol && updateSymbolIds[index].symbol !== symbol) {
                console.log(`Fidelity Stock Id symbol mismatch: ${updateSymbolIds[index].symbol} ${symbol} - cusip: ${updateSymbolIds[index].cusip}, assetId: ${updateSymbolIds[index].assetId}`);
              }
              if (!updateSymbolIds[index].cusip && cusip) {
                updateSymbolIds[index].cusip = cusip;
              }
              else if (cusip && updateSymbolIds[index].cusip !== cusip) {
                console.log(`Fidelity Stock Id cusip mismatch: ${updateSymbolIds[index].cusip} ${cusip} - assetId: ${updateSymbolIds[index].assetId}, symbol: ${updateSymbolIds[index].symbol}`);
              }
              if (!updateSymbolIds[index].account.find((acc: string) => acc === account)) {
                updateSymbolIds[index].account.push(account);
              }
            }
          }
        });
        if (Object.keys(updateAccountsWithId).length > 0) {
          const institutionInput: PlaidLinkedInstitutionInput = {
            linkedId: props.institution.linkedId,
            institutionId: props.institution.institutionId,
            institutionName: props.institution.institutionName,
            accounts: Object.keys(updateAccountsWithId).map((id: string) => updateAccountsWithId[id]),
            source: props.institution.source,
          };
          setUpdateAccounts((prevUpdateAccounts) => {
            const updatePrevAcc = [...prevUpdateAccounts];
            updatePrevAcc.push({
              uuid: uuid(),
              institution: institutionInput,
            });
            return updatePrevAcc;
          });
        }
        if (updateStocks.length > 0) {
          onUpdateStockDetails(updateStocks);
        }
        setSymbolIds(updateSymbolIds);
      }
    }}
  /> : null;

  const fetchOpenLots =
  cookie && (loadLots || loadClosedLots) ? [...symbolIds.filter((filter: FidelityStockId) => fetchSymbols.includes(filter.symbol)).map((symbolId: FidelityStockId) => symbolId.account.map((account: string) => <FetchData
    key='fetch-open-lots'
    command={getFidelityOpenLotsUrl(account, symbolId.cusip ?? '', symbolId.assetId ?? '', symbolId.symbol ?? '', loadClosedLots)}
    token={cookie}
    destination={FetchDestination.Fidelity}
    onUpdate={(data?: string, loading?: boolean, error?: any) => {
      if (data) {
        if (data.includes('Fidelity.com is Temporarily Unavailable')) {
          infoNotification('Failed to get stock open lots from Fidelity.com. Service is Temporarily Unavailable');
          return;
        }
        if (data.includes('Reason>Refresh</Reason>')) {
          infoNotification('Cookie has expired, please enter a new cookie');
          setCookie(undefined);
          return;
        }
        const parsedData = HtmlTableToJson.parse(data).results;
        const importLots: StockTransaction[] = [];
        if (parsedData.length > 0) {
          const accountToPlaidAccount: Dictionary<PlaidAccount | undefined> = {};
          parsedData[0].forEach((checkData: Dictionary<string>) => {
            const dateAcquired = checkData['Date Acquired'] ? Date.parse(checkData['Date Acquired']) : null;
            if (!dateAcquired) {
              return;
            }
            const dateSold = !loadClosedLots || !checkData['Date Sold'] ? null : checkData['Date Sold'];
            const proceeds = checkData['Proceeds'] ? Number(checkData['Proceeds'].replace('$', '').replace(',', '')) : null;
            const costBasisStr = checkData['Cost Basis'].replace('$', '').replace(',', '').replace('s*', '');
            const costBasis = costBasisStr ? Number(costBasisStr) : null;
            const quantity = checkData['Quantity'] ? Number(checkData['Quantity']) : null;
            const costBasisPerShare = checkData['Cost Basisper Share'] ? Number(checkData['Cost Basisper Share'].replace('$', '').replace(',', '')) : null;
            const buyPrice = !loadClosedLots ? costBasisPerShare : ((costBasis && quantity) ? Number((costBasis / quantity).toFixed(2)) : null);
            const sellPrice = proceeds && quantity ? Number((proceeds / quantity).toFixed(2)) : null;
            if (!buyPrice || !quantity || !costBasis || (loadClosedLots && (!dateSold || !sellPrice || !proceeds))) {
              return;
            }
            if (!accountToPlaidAccount[account]) {
              const plaidAccount = props.institution.accounts.find((acc: PlaidAccount) => acc.brokerAccountId === account);
              accountToPlaidAccount[account] = plaidAccount;
            }
            const addTransactions: StockTransaction = ({
              uuid: uuid(),
              broker: 'Fidelity',
              accountId: accountToPlaidAccount[account] ? (accountToPlaidAccount[account]?.accountId ?? account) : account,
              date: checkData['Date Acquired'],
              transactionId: '',
              securityId: stockDetails[symbolId.symbol] && stockDetails[symbolId.symbol].securityId ? stockDetails[symbolId.symbol].securityId! : '',
              action: TransactionAction.Bought,
              symbol: symbolId.symbol,
              amount: costBasis,
              quantity,
              price: buyPrice,
              subtype: 'buy',
              source: {
                sourceType: StockDataSourceType.Manual,
                addedDate: new Date(),
                sourceName: 'Fidelity',
              },
            });
            importLots.push(addTransactions);
            if (loadClosedLots) {
              const addTransactions: StockTransaction = ({
                uuid: uuid(),
                broker: 'Fidelity',
                accountId: accountToPlaidAccount[account] ? (accountToPlaidAccount[account]?.accountId ?? account) : account,
                date: dateSold!,
                transactionId: '',
                securityId: stockDetails[symbolId.symbol] && stockDetails[symbolId.symbol].securityId ? stockDetails[symbolId.symbol].securityId! : '',
                action: TransactionAction.Sold,
                symbol: symbolId.symbol,
                amount: -proceeds!,
                quantity: -quantity,
                price: sellPrice!,
                subtype: 'sell',
                source: {
                  sourceType: StockDataSourceType.Manual,
                  addedDate: new Date(),
                  sourceName: 'Fidelity',
                },
              });
              importLots.push(addTransactions);
            }
          });
        }
        setOpenLots((prevOpenLots) => {
          const updateOpenLots = { ...prevOpenLots };
          if (!updateOpenLots[symbolId.symbol]) {
            updateOpenLots[symbolId.symbol] = importLots;
          }
          else {
            importLots.forEach((load: StockTransaction) => {
              /* const exists = updateOpenLots[symbolId.symbol].find((existing: StockTransaction) => load.accountId === existing.accountId
                && load.action === existing.action
                && load.date === existing.date
                && load.quantity === existing.quantity
                && load.amount === existing.amount);
              if (!exists) {
              */
              updateOpenLots[symbolId.symbol].push(load);
              // }
            });
          }
          return updateOpenLots;
        });
        if (loadLots) {
          setLoadLots(false);
        }
        if (loadClosedLots) {
          setLoadClosedLots(false);
        }
      }
    }}
  />))] : null;

  const fetchOpenOrders =
  cookie && loadOrders ? <FetchData
    key='fetch-open-orders'
    command={'https://oltx.fidelity.com/ftgw/fbc/oftop/portfolio#orders' }// https://oltx.fidelity.com/ftgw/fbc/oftop/portfolio#orders/${account}
    token={cookie}
    destination={FetchDestination.Fidelity}
    onUpdate={(data?: string, loading?: boolean, error?: any) => {
      if (data) {
        if (data.includes('Fidelity.com is Temporarily Unavailable')) {
          infoNotification('Failed to get open orders from Fidelity.com. Service is Temporarily Unavailable');
          return;
        }
        if (data.includes('Reason>Refresh</Reason>')) {
          infoNotification('Cookie has expired, please enter a new cookie');
          setCookie(undefined);
          return;
        }
        const parsedData = HtmlTableToJson.parse(data).results;
        const orders: StockOrder[] = [];
        parsedData.forEach((processData: Dictionary<string>[]) => processData.forEach((checkData: Dictionary<string>) => {
          const dateAcquired = checkData['Date Acquired'] ? Date.parse(checkData['Date Acquired']) : null;
          if (dateAcquired && checkData['Cost Basis'] && checkData['Quantity'] && checkData['Cost Basisper Share']) {
            const addOrder: StockOrder = ({
              uuid: uuid(),
              action: checkData['Description'].startsWith('Sell ') ? TransactionAction.SellOrder : TransactionAction.BuyOrder,
              broker: 'Fidelity',
              accountId: '',
              symbol: '',
              securityId: '',
              expiration: new Date(),
              price: 0,
              quantity: 0,
            });
            orders.push(addOrder);
          }
        }));
        console.log('here i am');
        setOpenOrders(orders);
      }
    }}
  /> : null;

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          Cookie:  { placeholder: 'Enter Fidelity Cookie', type: RecordType.Text, value: '' },
          /*
          Test: { placeholder: 'Test url', type: RecordType.Checkbox, value: false },
          Account: { placeholder: 'Enter Account', type: RecordType.Text, value: 'X24480142' },
          Cusip: { placeholder: 'Enter cusip', type: RecordType.Text, value: '88160R101' },
          AssetId: { placeholder: 'Enter asset id', type: RecordType.Text, value: '11509791' },
          Symbol: { placeholder: 'Enter symbol', type: RecordType.Text, value: 'TSLA' },
          FbsiSymbolId: { placeholder: 'Enter fbsi symbol id', type: RecordType.Text, value: 'TSLA' },
          QueryFbsiSymbolId: { placeholder: 'Enter query fbsi symbol id', type: RecordType.Text, value: 'TSLA' },
          URL: { placeholder: 'URL', type: RecordType.Render, renderFn: (data: ModalFields) => {
            const account = data['Account'].value;
            const cusip = data['Cusip'].value;
            const assetId = data['AssetId'].value;
            const symbol = data['Symbol'].value;
            const fbsiSymbolId = data['FbsiSymbolId'].value;
            const queryFbsiSymbolId = data['QueryFbsiSymbolId'].value;
            const url = `https://oltx.fidelity.com/ftgw/fbc/ofpositions/openLots?${account ? `ACCOUNT=${account}&` : ''}QUOTE_REQUEST_ID=E${cusip ? `&CUSIP=${cusip}` : ''}${assetId ? `&ASSET_ID=${assetId}` : ''}&ACCT_TYPE_CODE=1&POSITION_TYPE_CODE=L${symbol ? `&SYMBOL=${symbol}` : ''}${fbsiSymbolId ? `&FBSI_SYMBOL_ID=${fbsiSymbolId}` : ''}&BROKERAGE_ACCOUNT_TYPE=1${queryFbsiSymbolId ? `&QUERY_FBSI_SYMBOL_ID=${queryFbsiSymbolId}` : ''}`;
            // eslint-disable-next-line react/jsx-no-target-blank
            return <a href={url} target='_blank'>{url}</a>;
          } },
          */
        },
      },
    ],
  };

  const holdingsColumns: EditableColumnProps<any>[] = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Cusip',
      dataIndex: 'cusip',
      key: 'cusip',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Asset Id',
      dataIndex: 'assetId',
      key: 'assetId',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Import',
      dataIndex: 'import',
      key: 'import',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        // eslint-disable-next-line react/jsx-no-target-blank
        return (
          <Dropdown
            menuItems={Object.keys(FidelityImportType).map((item: string) => ({ name: item, component: `Import ${FidelityImportType[item]}` }))}
            dropdownType={[DropdownType.Click]}
            onClick={(item: any) => {
              if (FidelityImportType[item] === FidelityImportType.Orders) {
                infoNotification(`Import ${FidelityImportType[item]} not implemented`);
              }
              else if (FidelityImportType[item] === FidelityImportType.OpenTransactions) {
                infoNotification(`Import ${FidelityImportType[item]} not implemented`);
              }
              else if (FidelityImportType[item] === FidelityImportType.ClosedTransactions) {
                infoNotification(`Import ${FidelityImportType[item]} not implemented`);
              }
            }}
          >
            <ImportIcon size='small' disabled={importData.find((importInst: ImportFidelityData) => importInst.symbol === record.symbol) !== undefined} enclosed onClick={() => {}} />
          </Dropdown>);
      },
    },
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        return (
          <Dropdown
            menuItems={Object.keys(FidelityImportType).map((item: string) => ({
              name: item,
              component: <a href={FidelityImportType[item] === FidelityImportType.OpenTransactions ? record.url
                : (FidelityImportType[item] === FidelityImportType.ClosedTransactions ? record.url.replace('openLots', 'closedLots') : `https://oltx.fidelity.com/ftgw/fbc/oftop/portfolio#orders/${record.account}`)} target='_blank'>Open {FidelityImportType[item]}</a>,
            }))}
            dropdownType={[DropdownType.Click]}
          >
            <ImportIcon size='small' enclosed onClick={() => {}} />
          </Dropdown>);
        /* (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={record.url} target='_blank'>url</a>
      ), */
      },
    },
  ];

  const symbolData: { key: string, symbol: string, account: string, cusip: string, assetId: string, url: string }[] = [];
  symbolIds.forEach((symbolId: FidelityStockId) => {
    if (symbolId.account.length > 0) {
      symbolId.account.forEach((account: string) => {
        symbolData.push({
          key: uuid(),
          symbol: symbolId.symbol ?? '',
          account,
          cusip: symbolId.cusip ?? '',
          assetId: symbolId.assetId ?? '',
          url: getFidelityOpenLotsUrl(account, symbolId.cusip ?? '', symbolId.assetId ?? '', symbolId.symbol ?? ''),
        });
      });
    }
    else {
      symbolData.push({
        key: uuid(),
        symbol: symbolId.symbol ?? '',
        account: '',
        cusip: symbolId.cusip ?? '',
        assetId: symbolId.assetId ?? '',
        url: getFidelityOpenLotsUrl('', symbolId.cusip ?? '', symbolId.assetId ?? '', symbolId.symbol ?? ''),
      });
    }
  });

  const updateFidelityAccounts = updateAccounts.map((updateAccount: { uuid: string, institution: PlaidLinkedInstitutionInput }) => {
    return <UpdateLinkedInstitutions key={updateAccount.uuid} institution={updateAccount.institution} onUpdateLinkedInstitution={(data?: PlaidLinkedInstitution, loading?: boolean, error?) => {
      if (data) {
        setUpdateAccounts((prevAccounts) => {
          const update = prevAccounts.filter((prev: { uuid: string, institution: PlaidLinkedInstitutionInput }) => prev.uuid !== updateAccount.uuid);
          return update;
        });
      }
    }} />;
  });

  return <>
    {updateFidelityAccounts}
    {fetchPortfolio}
    {fetchOpenLots}
    {fetchOpenOrders}
    {symbolIds.length === 0 && !cookie ? <>
      <RecordModal
        key={'fidelity-open-orders'}
        data={recordDataValues}
        action={RecordModalAction.Custom}
        title={'Fidelity Open Orders'}
        onCancel={() => {
          onClose();
        }}
        onSubmit={(data: any) => {
          infoNotification('Fetching data ...');
          let cookieValue = data.pages[0].entries['Cookie'].value;
          cookieValue = cookieValue.replace('cookie: ', '').replace(/\n$/, '');
          setCookie(cookieValue);
        }}
      />
    </> : <><MultiSelect
        key={`load-open-lots-select`}
        items={{ values: symbolIds.map((symbolId: FidelityStockId) => ({ uuid: symbolId.symbol ?? '', name: symbolId.symbol ?? '' })) }}
        defaultValue={fetchSymbols}
        placeholder='Add or remove symbol'
        onChange={(values: string[]) => setFetchSymbols(values)}
        optionLabelProp='label'
      >
        {symbolIds ? symbolIds.map((symbol: FidelityStockId) => <Option key={`history-item-option-${symbol.symbol}`} value={symbol.symbol} label={symbol.symbol}>
          <Tag key={`history-item-option-tag-${symbol.symbol}`} value={{ uuid: symbol.symbol, name: symbol.symbol }} />
        </Option>) : []}
      </MultiSelect>
      <Button onClick={() => setLoadLots(!loadLots)}>Load Open Lots</Button>
      <Button onClick={() => setLoadClosedLots(!loadClosedLots)}>Load Closed Lots</Button>
      <Button onClick={() => setLoadOrders(!loadOrders)}>Load Open Orders</Button>
      <Button onClick={() => setShowLoadedSymbols(!showLoadedSymbols)}>{showLoadedSymbols ? 'Hide' : 'Show'} Loaded Symbols</Button></>}
    {symbolIds.length > 0 && showLoadedSymbols
      ? <CenteredDiv>
      <Table
        className='.antd-symbols-table'
        size='small'
        pagination={false}
        columns={holdingsColumns}
        dataSource={symbolData}
        bordered={true}
      />
    </CenteredDiv>
      : null}
    {Object.keys(openLots).length > 0
      ? <ImportInvestmentTransactions
        accounts={{ Fidelity: Object.assign({}, ...props.institution.accounts.map((account: PlaidAccount) => ({ [account.accountId] : account }))) }}
        existingTransactions={existingInvestmentTransactions}
        transactions={openLots}
        onImportInvestmentTransactions={(addInvestments: Dictionary<StockTransaction[]>, removeInvestments: Dictionary<StockTransaction[]>, accounts?: Dictionary<Dictionary<PlaidAccount>>) => onImportInvestmentTransactions(addInvestments, [], accounts ?? {})}
        onClose={props.onClose} />
      : null}
  </>;
};

export default ImportFidelityOpenOrders;
