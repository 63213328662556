import React, { useEffect, useRef, useState } from 'react';
import { message, Tag, Popover, Space } from 'antd';
import { CheckCircleTwoTone, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined, UndoOutlined } from '@ant-design/icons';
import _, { Dictionary } from 'lodash';
import ContentView from '../data/ContentView';
import { DataCollectionData, DataEntry, DataObject, DataParent } from '../../graphql/generated/graphql';
import { RecordModalAction } from '../modal/RecordModal';
import { GetDataEntries } from '../../containers/api/Entry/DataEntry';
import { GetDataObjects } from '../../containers/api/DataObject';
import { useWindowSize } from '../layout/Page';
import { GetDataCollections } from '../../containers/api/Collection/DataCollection';
import { errorNotification } from '../actions/Notification';
import CreateEntry from '../data/Entry/CreateEntry';
import EditEntry from '../data/Entry/EditEntry';
import { dateFormat, FieldType } from '../table/DataTypes';
import moment from 'moment';
import CircleCheckbox from '../atomic/CircleCheckbox';
import ViewDraggable from '../views/ViewDraggable';
import { fireConfetti } from '../misc/Confetti';
import DatePicker from '../atomic/DatePicker';
import Button from '../atomic/Button';
import Popconfirm from '../atomic/Popconfirm';
import { sampleCollections, sampleEntries } from '../tutorial/TutorialEntry';
import UpdateCollectionEntries from '../data/UpdateCollectionEntries';
import LoadingOverlay from '../atomic/LoadingOverlay';
import { CenteredDiv } from '../styled/CenteredDiv';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

type CustomEntriesViewProps = {
  collectionUuids?: string[];
  entriesUuids?: string[]; // filter to show only these entries
  addToCollection?: boolean;
  viewAsModal?: boolean;
  onUpdate?: (collection: DataCollectionData[], objects: Dictionary<DataObject>, entries: DataEntry[]) => void;
};

type IsLoading = { collections: boolean; dataObjects: boolean; entries: boolean };
type LastUpdated = { collections: string; dataObjects: string; entries: string, fetchedEntries: string };

const CustomEntriesViewSample: React.FC<CustomEntriesViewProps> = (props: CustomEntriesViewProps) => {
  const [isMocked, setIsMocked] = useState<boolean>(true);
  const [lastUpdated, setLastUpdated] = useState<LastUpdated>({ collections: new Date().toLocaleString(), dataObjects: new Date().toLocaleString(), entries: new Date().toLocaleString(), fetchedEntries: new Date().toLocaleString() });
  const [updateEntry, setUpdateEntry] = useState<JSX.Element | null>(null);
  const [isLoading, setIsLoading] = useState<IsLoading>({ collections: false, dataObjects: false, entries: false });
  const [dataEntries, setDataEntries] = useState<DataEntry[] | null>(null);
  const [mockedDataEntries, setMockedDataEntries] = useState<DataEntry[]>(sampleEntries);
  const [mockedDataCollections, setMockedDataCollections] = useState<DataCollectionData[]>(sampleCollections);
  const [dataObjects, setDataObjects] = useState<Dictionary<DataObject>>({});
  const [dataCollections, setDataCollections] = useState<DataCollectionData[]>([]);
  const [markDueDate, setMarkDueDate] = useState<Date | null>(null);
  const [updateDueDate, setUpdateDueDate] = useState<DataEntry | null>(null);
  const [updateDoneDueDate, setUpdateDoneDueDate] = useState<DataEntry | null>(null);
  const [markDoneDate, setMarkDoneDate] = useState<Date | null>(null);
  const [updateDoneDate, setUpdateDoneDate] = useState<DataEntry | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [addEntriesToCollection, setAddEntriesToCollection] = useState<string[] | null>(null);
  const dataEntriesRef = useRef([] as DataEntry[] | null);
  const dataObjectsRef = useRef({} as Dictionary<DataObject>);
  const dataCollectionsRef = useRef([] as DataCollectionData[]);
  dataEntriesRef.current = dataEntries;
  dataObjectsRef.current = dataObjects;
  dataCollectionsRef.current = dataCollections;

  const getDataEntries = <GetDataEntries
    collectionUuids={/* if addToCollection, show all entries */ props.addToCollection ? undefined : props.collectionUuids}
    key={`get-entries-query-${lastUpdated.entries}`}
    updateApiResponse={(data?: any, loading?: boolean, error?: any) => {
      if (data) {
        setDataEntries(data);
      }
      if (loading !== undefined && loading !== isLoading.entries) {
        setIsLoading((prevIsLoading) => {
          const currentLoading = { ...prevIsLoading };
          currentLoading.entries = loading;
          return currentLoading;
        });
      }
      if (error) {
        errorNotification();
      }
    }}/>;

  const getDataObjects = <GetDataObjects
    key={`get-objects-query-${lastUpdated.dataObjects}`}
    updateApiResponse={(data?: DataObject[], loading?: boolean, error?: string) => {
      if (data) {
        const dataObjs = Object.assign({}, ...data.map((dataObj: DataObject) => ({ [dataObj.uuid]: dataObj })));
        setDataObjects(dataObjs);
      }
      if (loading !== undefined && loading !== isLoading.dataObjects) {
        setIsLoading((prevIsLoading) => {
          const currentLoading = { ...prevIsLoading };
          currentLoading.dataObjects = loading;
          return currentLoading;
        });
      }
      if (error) {
        errorNotification();
      }
    }}/>;

  const getDataCollections = <GetDataCollections
    key={`get-collections-query-${lastUpdated.collections}`}
    updateApiResponse={(data?: DataCollectionData[], loading?: boolean, error?: string) => {
      if (data) {
        setDataCollections(data);
      }
      if (loading !== undefined && loading !== isLoading.collections) {
        setIsLoading((prevIsLoading) => {
          const currentLoading = { ...prevIsLoading };
          currentLoading.collections = loading;
          return currentLoading;
        });
      }
      if (error) {
        errorNotification();
      }
    }}/>;

  useEffect(() => {
    if (props.addToCollection && props.onUpdate && dataEntries && !isMocked) {
      props.onUpdate([], {} as Dictionary<DataObject>, dataEntries.filter((entry: DataEntry) => addEntriesToCollection?.find((entryUuid: string) => entry.dataUuid === entryUuid)));
    }
    if (isMocked) {
      // do nothing
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [addEntriesToCollection]);

  useEffect(() => {
    if (props.onUpdate && dataCollections && dataObjects && dataEntries && !isMocked) {
      props.onUpdate(dataCollections, dataObjects, dataEntries);
    }
    const collection = props.collectionUuids && props.collectionUuids.length === 1 && dataCollections ? dataCollections.find((c: DataCollectionData) => props.collectionUuids && c.uuid === props.collectionUuids[0]) : null;
    if (props.addToCollection && collection && dataEntries && !isMocked) {
      setAddEntriesToCollection(dataEntries
        .filter((entry: DataEntry) => entry.parent
          ? entry.parent.find((parent: DataParent) => parent.uuid === collection.uuid)
          : false)
        .map((entry: DataEntry) => entry.dataUuid));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataObjects, dataCollections, dataEntries]);

  const collection = props.collectionUuids && props.collectionUuids.length === 1 && dataCollections ? dataCollections.find((c: DataCollectionData) => props.collectionUuids && c.uuid === props.collectionUuids[0]) : null;

  // Done circle
  const getLeftElement = (data: any) => {
    // Show checkboxes if adding entries to a collection
    if (props.addToCollection) {
      if (addEntriesToCollection) {
        return addEntriesToCollection.find((entryUuid: string) => entryUuid === data.dataUuid)
          ? <CircleCheckbox isChecked onClick={() => setAddEntriesToCollection(prevEntries => prevEntries ? [...prevEntries.filter((entryUuid: string) => entryUuid !== data.dataUuid)] : prevEntries)} />
          : <CircleCheckbox onClick={() => setAddEntriesToCollection(prevEntries => prevEntries ? (prevEntries.find((entryUuid: string) => entryUuid === data.dataUuid) ? prevEntries : [...prevEntries, data.dataUuid]) : prevEntries)}/>;
      }
      return null;
    }
    // TODO: need to do diff based on local date
    const now = moment(moment(new Date()).format(dateFormat));
    const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
    const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;

    return data.dateCompleted
      ? <span style={{ marginRight: '5px' }}><CheckCircleTwoTone style={{ paddingRight: '1px' }} twoToneColor="#52c41a" /></span>
      : (diffDays > 0
          ? <span key={`mark-done-icon`} style={{ marginRight: '5px' }}><Popover key='popover-missing-fields' content={<div>Please update the due date. If item is complete, please mark it as complete</div>} title="Item over due">
              <Popconfirm
                placement={'rightTop'}
                key={`mark-due-done-${data.uuid}`}
                header={'Item expired'}
                title={<>
                  <div><span style={{ marginRight: '15px' }}>Update due date</span>
                    <DatePicker
                      key={`data-picker-due}`}
                      allowClear={true}
                      initialValue={moment(data.dateDue)}
                      popupStyle={{ zIndex: 2000 }}
                      onChange={(e: any) => {
                        // TODO
                        setMarkDueDate(e ? e.toDate() : null);
                      }} />
                  </div>
                  <div style={{ marginTop: '5px' }}><span style={{ marginRight: '4px' }}>Mark item as done</span>
                    <DatePicker
                      key={`data-picker-done}`}
                      allowClear={true}
                      initialValue={undefined}
                      popupStyle={{ zIndex: 2000 }}
                      onChange={(e: any) => {
                        // TODO
                        setMarkDoneDate(e ? e.toDate() : null);
                      }} />
                  </div>
                </>}
                onConfirm={() => {
                  // TODO
                  setUpdateDoneDueDate(data);
                }}
                onCancel={() => {
                  // TODO
                  setMarkDueDate(null);
                  setMarkDoneDate(null);
                }}
                okText='Update dates'
                cancelText='Cancel'
              >
                <ExclamationCircleOutlined style={{ color: 'red' }} onClick={() => {
                  setMarkDueDate(data.dateDue ? moment(data.dateDue).toDate() : null);
                }} />
              </Popconfirm>
            </Popover></span>
          : <span style={{ marginRight: '30px' }}>
              <Popconfirm
                  placement={'rightTop'}
                  title={<>
                    <div>Mark item as complete:
                      <DatePicker
                        allowClear={false}
                        initialValue={moment(new Date())}
                        popupStyle={{ zIndex: 2000 }}
                        onChange={(e: any) => {
                          setMarkDoneDate(e ? e.toDate() : null);
                        }} />
                    </div>
                  </>}
                  onConfirm={() => {
                    if (!markDoneDate) {
                      setMarkDoneDate(new Date());
                    }
                    setUpdateDoneDate(data);
                  }}
                  onCancel={() => setMarkDoneDate(null)}
                  okText="Mark Complete"
                  cancelText="Cancel"
                ><CircleCheckbox /></Popconfirm>
            </span>);
  };

  const getEntryDates = (data: DataEntry, mapping: any, show: boolean, content: boolean) => {
    return !show
      ? null
      : <Space size={10} direction='horizontal' style={{ marginBottom: '5px' }}>
      {content
        ? <>
          <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px', fontWeight: 'bold' }}>{mapping.dateCreated.label}: {mapping.dateCreated.resolve(data)}</div>
          <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px', fontWeight: 'bold' }}>{mapping.dateDue.label}: {mapping.dateDue.resolve(data)}</div>
          <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px', fontWeight: 'bold' }}>{mapping.dateCompleted.label}: {mapping.dateCompleted.resolve(data)}</div>
        </>
        : (!data.dateCompleted
          ? <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px' }}>{mapping.dateDue.label}: {mapping.dateDue.resolve(data)}</div>
          : <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px' }}>{mapping.dateCompleted.label}: {mapping.dateCompleted.resolve(data)}</div>)}
    </Space>;
  };

  const commonHeaderContentFields = {
    collections: { label: 'Collections', field: '', fieldType: FieldType.JsxElement, resolve: (dataEntry: DataEntry) => {
      const currentDataCollections = isMocked ? mockedDataCollections : dataCollectionsRef.current;
      const dataParents: DataParent[] = (dataEntry.parent ?? []) as DataParent[];
      let collectionArray = [<span key={`${dataEntry.dataUuid}-no-collections`}>None </span>];
      const editCollections = <EditOutlined onClick={() => {
        const currentDataObjects = dataObjectsRef.current;
        const dataObject = currentDataObjects ? currentDataObjects[dataEntry.objectUuid] : null;
        if (dataObject) {
          onEditEntry(dataObject, dataEntry);
        }
      }
      } />;
      if (!dataParents) {
        return editCollections;
      }
      const collectionObjs = currentDataCollections.filter((collection: DataCollectionData) => dataParents.find((parent: DataParent) => parent.uuid === collection.uuid));
      collectionArray = collectionObjs && collectionObjs.length > 0 ? collectionObjs.map((collectionObj: any) => <Tag key={`${dataEntry.dataUuid}-collections-${collectionObj.name}`} style={{ right: '0px', borderRadius: '8px', fontWeight: 'bold', fontSize: '10px' }} color="blue">{collectionObj.name}</Tag>) : collectionArray;
      collectionArray.push(editCollections);
      return collectionArray;
    }},
    dateDue: { label: 'Due', field: '', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
      const now = moment(moment().format(dateFormat));
      const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
      const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;

      return <DatePicker
        disabled={props.addToCollection}
        key={`data-picker-due-${data.dateDue}`}
        color={data.dateDue && !data.dateCompleted && diffDays > 0 ? 'red' : undefined}
        style={{ background: 'inherit', color: 'red', height: 'auto', width: 'auto' }}
        confirmReset={{ title: 'Remove due date' }}
        size='small'
        initialValue={data.dateDue ? moment(data.dateDue) : undefined}
        onChange={(e: any) => {
          setMarkDueDate(e);
          setUpdateDueDate(data);
        }}
      />;
    }},
    dateCompleted: { label: 'Completed', field: 'dateCompleted', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
      return <DatePicker
        key={`data-picker-completed-${data.dateCompleted}`}
        disabled={props.addToCollection}
        style={{ background: 'inherit', color: 'red', height: 'auto', width: 'auto' }}
        size='small'
        initialValue={data.dateCompleted ? moment(data.dateCompleted) : undefined}
        format={dateFormat}
        onChange={(e: any) => {
          setMarkDoneDate(e);
          setUpdateDoneDate(data);
        }}
      />;
    }}};

  const entryDataViewMapping = {
    lastUpdated: '',
    lastCollectionsUpdated: '',
    itemId: (data: any) => {
      return data.dataUuid;
    },
    header: {
      leftWidth: '30px',
      leftElement: getLeftElement,
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        // return Object.keys(mapping).map((m: any) => {
        return (
          <div key={`data-entries-header-${lastUpdated.entries}`}>
            {data.title}{mapping.dataObject && false
                          ? <span style={{ fontWeight: 'bold', maxWidth: 125, float: 'right', wordWrap: 'break-word', overflowWrap: 'break-word', right: '0px' }}>
                              {mapping.dataObject.resolve(data[mapping.dataObject.field])}
                            </span>
                          : null}
            <div>
              {getEntryDates(data, mapping, collapsed === undefined ? true : collapsed, false)}
            </div>
          </div>);
        // });
      },
      mapping: {
        dataEntry: {
          title: { label: '', field: 'title' },
          dataObject: { label: '', field: 'objectUuid', resolve: (objectUuid: string) => {
            const latestDataObjects = dataObjectsRef.current;
            const dataObj = dataObjects ? latestDataObjects[objectUuid] : null;
            return dataObj ? <Tag style={{ right: '0px' }} color="magenta">{dataObj.name}</Tag> : null;
          }},
          ...commonHeaderContentFields,
        },
      },
    },
    content: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        const description = _.get(data, mapping.description.fields.description);
        return <>
          {description ? <div key={`entry-content-description`}><span style={{ fontWeight: 'bold' }}>Description: </span>{description}</div> : null}
          {mapping.collections && mapping.collections.resolve ? <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Collections: {mapping.collections.resolve(data)}</div> : null}
          <div>{getEntryDates(data, mapping, collapsed === undefined ? false : !collapsed, true)}</div>
        </>;
      },
      mapping: {
        dataEntry: {
          description: {
            label: 'Description',
            fields: {
              description: 'description',
            },
          },
          dateCreated: { label: 'Created', field: 'dateCreated', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
            return <DatePicker
              disabled={true}
              allowClear={false}
              key={`data-picker-created-${data.dateCreated}`}
              style={{ background: 'inherit', height: 'auto', width: 'auto' }}
              size='small'
              initialValue={data.dateCreated ? moment(data.dateCreated) : undefined}
              format={dateFormat}
            />;
          }},
          ...commonHeaderContentFields,
        },
      },
    },
  };

  useEffect(() => {
    if (updateDoneDate) {
      setLoadingMessage('Update Entry done date ...');
      onMarkDone(updateDoneDate);
      setUpdateDoneDate(null);
    }
    if (updateDueDate) {
      setLoadingMessage('Update Entry due date ...');
      onMarkDue(updateDueDate);
      setUpdateDueDate(null);
    }
    if (updateDoneDueDate) {
      setLoadingMessage('Update Entry done and due date ...');
      onMarkDoneDue(updateDoneDueDate);
      setUpdateDoneDueDate(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [updateDueDate, updateDoneDate, updateDoneDueDate]);

  const windowSize = useWindowSize();

  const extraMenu = (initialData: any) => {
    const currentDataObjects = dataObjectsRef.current;
    const dataObject = currentDataObjects ? currentDataObjects[initialData.objectUuid] : null;
    return  {
      items: [
        <EditOutlined onClick={() => dataObject ? onEditEntry(dataObject, initialData) : errorNotification()} />,
        initialData.active
          ? <Popconfirm
              placement={'leftTop'}
              title={<><div>Deleted Entries cannot be restored.</div><div>Please confirm you that want to delete the Entry.</div></>}
              onConfirm={() => dataObject ? onDeleteEntry(dataObject, initialData) : errorNotification()}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            ><DeleteOutlined />
            </Popconfirm>
          : <Popconfirm
              placement={'leftTop'}
              title="Restore entry."
              onConfirm={() => dataObject ? onRestoreEntry(dataObject, initialData) : errorNotification()}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            ><UndoOutlined />
            </Popconfirm>,
      ],
    };
  };

  const updateCreateEntry = (updatedDataEntry: DataEntry) => {
    if (isMocked) {
      setMockedDataEntries((prevDataEntries: DataEntry[]) => {
        const updateDataEntries = [...prevDataEntries].filter((entry: DataEntry) => entry.dataUuid !== updatedDataEntry.dataUuid);
        updateDataEntries.push(updatedDataEntry);
        return updateDataEntries;
      });
    }
    setLastUpdated((prevLastUpdated) => {
      const currentLastUpdated = { ...prevLastUpdated };
      currentLastUpdated.entries = new Date().toLocaleString();
      return currentLastUpdated;
    });
    if (markDoneDate) {
      fireConfetti();
    }
    setMarkDueDate(null);
    setMarkDoneDate(null);
    setUpdateEntry(null);
  };

  const onUpdateCreateError = (error: string) => {
    errorNotification(`Error found while updating item, please try again. Error: ${error}`);
    setUpdateEntry(null);
  };

  const onUpdateCreateCancel = () => {
    setUpdateEntry(null);
  };

  const onCreateEntry = () => onCreateEntryWithArgs();

  const onCreateEntryWithArgs = (dateDue?: Date, dateCompleted?: Date) => {
    const initialDataEntry: DataEntry | undefined = dateDue || dateCompleted ? { title: '', description: '', dataUuid: '', objectUuid: '', dateCreated: '', instances: [], ownerUuid: '', data: [], dateDue, dateCompleted } : undefined;
    setLoadingMessage('Creating Entry ...');
    setUpdateEntry(<CreateEntry
      mocked
      initialData={initialDataEntry}
      action={RecordModalAction.Create}
      objects={dataObjects}
      collections={isMocked ? mockedDataCollections : dataCollections}
      initialCollection={props.collectionUuids && props.collectionUuids.length > 0 ? props.collectionUuids[0] : undefined}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onUpdateCollections={onUpdateCollection}
      onError={onUpdateCreateError} />);
  };

  const onEditEntry = (dataObject: DataObject, initialEntry: DataEntry) => {
    onEditEntryWithArgs(dataObject, initialEntry);
  };

  const onEditEntryWithArgs = (dataObject: DataObject, initialEntry: DataEntry, dateDue?: Date, dateDone?: Date) => {
    setLoadingMessage('Updating Entry ...');
    if (dateDue) {
      initialEntry.dateDue = dateDue;
    }
    if (dateDone) {
      initialEntry.dateCompleted = dateDone;
    }
    setUpdateEntry(<EditEntry
      mocked
      autoExecute={dateDue ? true : undefined}
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={initialEntry}
      collections={isMocked ? mockedDataCollections : dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onUpdateCollections={onUpdateCollection}
      onError={onUpdateCreateError} />);
  };

  const onDeleteEntry = (dataObject: DataObject, initialEntry: DataEntry) => {
    setLoadingMessage('Deleting Entry ...');
    setUpdateEntry(<EditEntry
      mocked
      autoExecute
      action={RecordModalAction.Delete}
      dataObject={dataObject}
      initialDataEntry={initialEntry}
      collections={isMocked ? mockedDataCollections : dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onMarkDue = (initialEntry: DataEntry, date?: Date) => {
    const dataObject = dataObjects ? dataObjects[initialEntry.objectUuid] : null;
    if (!dataObject) {
      errorNotification();
      return;
    }
    const markDue = { ...initialEntry };
    markDue.dateDue = date ? moment(date) : (markDueDate ? moment(markDueDate) : null);
    setUpdateEntry(<EditEntry
      mocked
      autoExecute
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={markDue}
      collections={isMocked ? mockedDataCollections : dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onMarkDone = (initialEntry: DataEntry, date?: Date) => {
    const currentDataObjects = dataObjectsRef.current;
    const dataObject = currentDataObjects ? currentDataObjects[initialEntry.objectUuid] : null;
    if (!dataObject) {
      errorNotification();
      return;
    }
    const markDone = { ...initialEntry };
    markDone.dateCompleted = date ? moment(date) : (markDoneDate ? moment(markDoneDate) : null);
    // setMarkDoneDate(null);
    setUpdateEntry(<EditEntry
      mocked
      autoExecute
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={markDone}
      collections={isMocked ? mockedDataCollections : dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onMarkDoneDue = (initialEntry: DataEntry, date?: Date) => {
    const dataObject = dataObjects ? dataObjects[initialEntry.objectUuid] : null;
    if (!dataObject) {
      errorNotification();
      return;
    }
    const markDue = { ...initialEntry };
    markDue.dateDue = date ? moment(date) : (markDueDate ? moment(markDueDate) : null);
    markDue.dateCompleted = date ? moment(date) : (markDoneDate ? moment(markDoneDate) : null);
    // setMarkDueDate(null);
    // setMarkDoneDate(null);
    setUpdateEntry(<EditEntry
      mocked
      autoExecute
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={markDue}
      collections={isMocked ? mockedDataCollections : dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onRestoreEntry = (dataObject: DataObject, initialEntry: DataEntry) => {
    setLoadingMessage('Restoring Entry ...');
    setUpdateEntry(<EditEntry
      mocked
      autoExecute
      action={RecordModalAction.Restore}
      dataObject={dataObject}
      initialDataEntry={initialEntry}
      collections={isMocked ? mockedDataCollections : dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onUpdateCollection = (newCollection: DataCollectionData) => {
    if (isMocked) {
      setMockedDataCollections((prevDataCollections: DataCollectionData[]) => {
        const updateDataCollections = [...prevDataCollections];
        updateDataCollections.push(newCollection);
        return updateDataCollections;
      });
      return;
    }
    setLastUpdated((prevLastUpdated) => {
      const currentLastUpdated = { ...prevLastUpdated };
      currentLastUpdated.collections = new Date().toLocaleString();
      return currentLastUpdated;
    });
  };

  const noDataMessage = <div>No entries</div>;

  const entriesToDisplay = isMocked ? mockedDataEntries : (dataEntries ?? []); // (dataEntries ? filterData(entriesSettings, dataEntries, props.entriesUuids) : []) :

  return (
    <>
      {getDataObjects}
      {isMocked ? null : getDataEntries}
      {isMocked ? null : getDataCollections}
      {updateEntry}
      {collection && dataEntries && addEntriesToCollection && !props.addToCollection ? <UpdateCollectionEntries entries={dataEntries} collection={collection} onSuccess={() => {
        setAddEntriesToCollection(null);
        setLastUpdated((prevLastUpdated) => {
          const currentLastUpdated = { ...prevLastUpdated };
          currentLastUpdated.collections = new Date().toLocaleString();
          return currentLastUpdated;
        });
      }} /> : null}
      <CenteredDiv><Button onClick={() => setIsMocked(!isMocked)}>{isMocked ? 'using mocked data' : 'using data'}</Button></CenteredDiv>
      <LoadingOverlay
        active={(isLoading.collections || isLoading.dataObjects || isLoading.entries) && updateEntry === null}
        spinner
        text={`${loadingMessage}`}
        >
        <ContentView
          actionButtons={[
            <Button key='entry-add' style={{ float: 'right' }} onClick={onCreateEntry}><PlusCircleOutlined /></Button>,
          ]}
          data={
            <>
            <ViewDraggable
              customKey={`viewcard-entries`}
              dragDisabled={true}
              style={{ maxWidth: windowSize.width ? windowSize.width : 800 }}
              data={entriesToDisplay}
              loading={(isLoading.collections || isLoading.dataObjects || isLoading.entries) || dataEntries === null}
              noDataMessage={noDataMessage}
              mapping={entryDataViewMapping}
              sourceName='dataEntry'
              collapseable
              extraMenu={props.addToCollection ? undefined : extraMenu} />
            </>}
        />
      </LoadingOverlay>
    </>
  );
};

export default CustomEntriesViewSample;
