import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import EnclosedIcon, { IconProps } from './EnclosedIcon';

const LoadingStyled = styled(LoadingOutlined)`
  z-index: 2000;
  font-size: 14px;
  margin-right: 0px;
  ${(props: { color?: string }) => props.color ? `color: ${props.color};` : null}
`;

type LoadingIconProps = IconProps & {
};

const LoadingIcon: React.FC<LoadingIconProps> = (props: LoadingIconProps) => {
  const { enclosed, color, hide, onClick } = props;
  const icon = <LoadingStyled color={color} />;

  return <EnclosedIcon icon={icon} color={color} enclosed={enclosed} hide={hide} onClick={onClick} />;
};

export default LoadingIcon;
