import React, { CSSProperties } from 'react';
import { Tabs as TabsAnt } from 'antd';
import { TabsProps as TabsPropsAnt } from 'antd/lib/tabs';
import styled from 'styled-components';

const { TabPane } = TabsAnt;

type StyledTabsProps = {
  fullScreen?: boolean;
};

const StyledTabs = styled(TabsAnt)`
  ${(props: StyledTabsProps) => props.fullScreen ? `
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  zIndex: 2001;
  background: white;` : ''}
`;

type StyledTabPaneProps = {
  fullScreen?: boolean;
};
const StyledTabPane = styled(TabPane)`
  overflow: auto;
  ${(props: StyledTabPaneProps) => props.fullScreen ? `
  height: calc(100vh - 60px);` : ''}
`;

type TabsProps = TabsPropsAnt & {
  key? : string;
  style?: CSSProperties;
  initialTab?: number;
  tabs: { name: string; value: JSX.Element, closeable?: boolean }[];
  fullScreen?: boolean;
  embed?: boolean;
  // onChange?: (activeKey: string) => void;
};

const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  const { key, style, initialTab, tabs, fullScreen, onChange, ...antProps } = props;

  return (
  <StyledTabs
    size='small'
    type='editable-card'
    hideAdd
    centered
    style={{ ...style }}
    fullScreen={fullScreen}
    key={key ?? 'tabs'}
    defaultActiveKey={`${initialTab ?? 1}`}
    onChange={onChange}
    {...antProps}
  >
    {tabs.map((tab: { name: string, value: JSX.Element, closeable?: boolean }, index: number) => (
      <StyledTabPane
        fullScreen={fullScreen}
        tab={tab.name}
        key={index}
        closable={tab.closeable}
      >
        {tab.value}
      </StyledTabPane>))}
  </StyledTabs>);
};

export default Tabs;
