import React, { useEffect }  from 'react';
import { StockDetails, useGetStockDetailsQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type StockDetailsProps = {
  ticker: string;
  onUpdate?: (data?: StockDetails, loading?: boolean, error?: any) => any;
};

const GetStockDetails: React.FC<StockDetailsProps> = (props: StockDetailsProps) => {
  const { onUpdate, ticker } = props;
  const { data: stock, loading: stockLoading, error: stockError } = useGetStockDetailsQuery({
    variables: { ticker },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(stock ? stock.getStockDetails : undefined, stockLoading, stockError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stock, stockLoading, stockError]);

  return null;
};

export default GetStockDetails;
