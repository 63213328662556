import React from 'react';
import { CollectionType, DataCollectionData, DataEntry, DataObject } from '../../graphql/generated/graphql';
import { sampleCollection } from './TutorialCollection';

type TutorialEntryProps = {
};

export const sampleCollections: DataCollectionData[] = sampleCollection;

export const sampleEntries: DataEntry[] = [
  {
    dataUuid: '11111111-1111-1111-1111-111111111110',
    objectUuid: '11111111-1111-1111-1111-111111111111',
    ownerUuid: '11111111-1111-1111-1111-111111111111',
    title: 'Sample Entry without due or done data',
    description: '',
    data: [],
    instances: [],
    dateCreated: '2020-09-04T00:50:26.113Z',
    active: true,
  },
  {
    dataUuid: '11111111-1111-1111-1111-111111111111',
    objectUuid: '11111111-1111-1111-1111-111111111111',
    ownerUuid: '11111111-1111-1111-1111-111111111111',
    title: 'Sample Entry with future due date and description',
    description: 'Description for a sample Entry',
    data: [],
    instances: [],
    parent: [{ uuid: '11111111-1111-1111-1111-111111111111', type: CollectionType.Collection, ordinal: 1 }],
    dateCreated: '2020-09-04T00:50:26.113Z',
    dateDue: new Date(new Date().setDate(new Date().getDate() + 3)).toUTCString(),
    active: true,
  },
  {
    dataUuid: '11111111-1111-1111-1111-111111111112',
    objectUuid: '11111111-1111-1111-1111-111111111111',
    ownerUuid: '11111111-1111-1111-1111-111111111111',
    title: 'Sample Entry with due date overdue',
    description: '',
    data: [],
    instances: [],
    dateCreated: '2020-09-04T00:50:26.113Z',
    dateDue: new Date(new Date().setDate(new Date().getDate() - 3)).toUTCString(),
    active: true,
  },
  {
    dataUuid: '11111111-1111-1111-1111-111111111113',
    objectUuid: '11111111-1111-1111-1111-111111111111',
    ownerUuid: '11111111-1111-1111-1111-111111111111',
    title: 'Sample Entry with that is done',
    description: '',
    data: [],
    instances: [],
    parent: [{ uuid: '11111111-1111-1111-1111-111111111111', type: CollectionType.Collection, ordinal: 2 }],
    dateCreated: '2020-09-04T00:50:26.113Z',
    dateCompleted: new Date().toUTCString(),
    active: true,
  },
  {
    dataUuid: '11111111-1111-1111-1111-111111111114',
    objectUuid: '11111111-1111-1111-1111-111111111111',
    ownerUuid: '11111111-1111-1111-1111-111111111111',
    title: 'Sample Entry that is deleted',
    description: '',
    data: [],
    instances: [],
    dateCreated: '2020-09-04T00:50:26.113Z',
    dateDue: new Date(new Date().setDate(new Date().getDate() - 3)).toUTCString(),
    active: false,
  },
];

export const sampleObjects: DataObject[] = [
  {
    uuid: '11111111-1111-1111-1111-111111111111',
    ownerUuid: '00000000-0000-0000-0000-000000000000',
    name: 'global basic type',
    description: '',
  },
];

const TutorialEntry: React.FC<TutorialEntryProps> = (props: TutorialEntryProps) => {
  return <div>Entry tutorial</div>;
};

export default TutorialEntry;
