import React from 'react';
import { Popconfirm as PopconfirmAnt } from 'antd';
import styled from 'styled-components';
import { PopconfirmProps as PopconfirmPropsAnt } from 'antd/lib/popconfirm';

const PopconfirmStyled = styled(PopconfirmAnt)`
  border-radius: 5px;
  .ant-popover-message {
    padding: 0px;
    background: green;
  }
  .ant-popover-inner-content {
    padding: 0px;
    background: blue;
  }
`;

export enum IconType {
  Info = 0,
  Warn,
  None,
}

export type PopconfirmProps = PopconfirmPropsAnt & {
  header?: string | JSX.Element;
  iconType?: IconType;
  customLayout?: boolean;
  isTutorial?: boolean;
};

const Popconfirm: React.FC<PopconfirmProps> = (props: PopconfirmProps) => {
  const { customLayout, children, header, title, iconType, icon, isTutorial, ...antProps } = props;

  // message: 4px 0 12px;
  // inner-content: 12px 16px
  return (
    isTutorial
      ? <PopconfirmStyled
        {...antProps}
        visible={true}
        icon={icon
          ? icon
          : (iconType === IconType.None
            ? null
            : undefined)}
        title={header ? <><h3>{header}</h3>{title}</> : <div style={{ margin: customLayout ? '-16px -16px -16px -38px' : undefined }}>{title}</div>}
        cancelButtonProps={{ style: { borderRadius: '5px' } }}
        okButtonProps={{ style: { borderRadius: '5px' } }}
      >
        {children}
      </PopconfirmStyled>
      : <PopconfirmStyled
        {...antProps}
        icon={icon
          ? icon
          : (iconType === IconType.None
            ? null
            : undefined)}
        title={header ? <><h3>{header}</h3>{title}</> : <div style={{ margin: customLayout ? '-16px -16px -16px -38px' : undefined }}>{title}</div>}
        cancelButtonProps={{ style: { borderRadius: '5px' } }}
        okButtonProps={{ style: { borderRadius: '5px' } }}
      >
        {children}
      </PopconfirmStyled>
  );
};

export default Popconfirm;
