import React, { CSSProperties, useRef, useState } from "react";
import { Input, Tag as TagAnt } from "antd";
import { CloseCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import styled from 'styled-components';
import Button from "./Button";

export enum TagColor {
  Magenta = 'magenta',
  Red = 'red',
  Volcano = 'volcano',
  Orange = 'orange',
  Gold = 'gold',
  Lime = 'lime',
  Green = 'green',
  Cyan = 'cyan',
  Blue = 'blue',
  Geekblue  = 'geekblue',
  Purple = 'purple',
  Lightgray = 'lightgray',
}

export type TagItem = {
  uuid: string; // if uuid is missing, use name
  name: string;
  color?: TagColor;
  selected?: boolean;
  disabled?: boolean;
};

export const getEnumToTagItem = (enumType: any, disabled?: any[]): TagItem[] => {
  return Object.keys(enumType).map((val: string) => ({ uuid: val, name: enumType[val], disabled: disabled && disabled.find((item: any) => enumType[val] === item) ? true : undefined }));
};

const StyledTag = styled(TagAnt)`
  border-radius: 5px;
`;

type TagProps = {
  tagKey? : string;
  style?: CSSProperties;
  value: TagItem;
  newTag?: string;
  icon?: 'close' | JSX.Element;
  iconHasOnClick?: boolean; // if true, then have icon as separate node. if false, include it as part of Button.
  onClick?: (item: TagItem) => void;
  onClickIcon?: (item: TagItem) => void;
  onCreate?: (item: TagItem) => void;
};

const Tag: React.FC<TagProps> = (props: TagProps) => {
  const { value, tagKey, style, iconHasOnClick, newTag, onClick, onClickIcon, onCreate } = props;
  const [editTag, setEditTag] = useState<TagItem | undefined>(undefined);
  const editTagRef = useRef<TagItem | undefined>(undefined);
  editTagRef.current = editTag;

  const icon = props.icon === 'close' ? <CloseCircleTwoTone style={{ zIndex: 2000, fontSize: '14px', marginRight: '0px' }} onClick={() => onClickIcon ? onClickIcon(value) : null} /> : props.icon;
  const clickIcon = iconHasOnClick || onClickIcon !== undefined;

  return (newTag
    ? (editTag
      ? <Input
      autoFocus={true}
      key={'create-tag'}
      size="small"
      className="tag-input"
      defaultValue={editTag.name}
      onChange={(e: any) => setEditTag((prevEditTag: TagItem | undefined) => {
        if (prevEditTag === undefined) {
          return prevEditTag;
        }
        const updateEditTag = { ...prevEditTag };
        if (!e.target) {
          console.log(JSON.stringify(e));
          return updateEditTag;
        }
        updateEditTag.name = e.target.value;
        return updateEditTag;
      })}
      onBlur={() => {
        if (onCreate && editTagRef.current) {
          onCreate(editTagRef.current);
        }
      }}
      onPressEnter={() => {
        if (onCreate && editTagRef.current) {
          onCreate(editTagRef.current);
        }
      }}
    />
      : <StyledTag
        key={tagKey ? tagKey : `new-tag-${value.uuid ?? value.name}`}
        style={style}>
        <Button size='small' key={`tag-button-${value.name}`} style={{ backgroundColor: 'inherit', border: 'none', paddingLeft: '0px', paddingRight: '0px' }} onClick={(e: any) => {
          setEditTag(value);
        }}><PlusOutlined /> {newTag}</Button>
      </StyledTag>)
    : <StyledTag
      key={tagKey ? tagKey : `tag-${value.uuid ?? value.name}`}
      style={style}
      color={value.color}>
      <div>{onClick
        ? <>
          <Button size='small' key={`tag-button-${value.name}`} style={{ backgroundColor: 'inherit', border: 'none', paddingLeft: '0px', paddingRight: '0px' }} onClick={(e: any) => {
            if (onClick) {
              onClick(value);
            }
          }}>
              {value.name}{clickIcon ? null : icon}
          </Button>
          {clickIcon ? icon : null}
          </>
        : <>{value.name}{icon}</>}
      </div>
    </StyledTag>);
};

export default Tag;
