/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

import enTranslationMessages from './en/index';
import cnTranslationMessages from './cn/index';

export interface TranslationMessages {
  [index: string]: string;
}

export const DEFAULT_LOCALE: string = 'en';
export const CN_LOCALE: string = 'cn';

export const formatTranslationMessages = (locale: string, messages: TranslationMessages): TranslationMessages => {
  const defaultFormattedMessages: TranslationMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages: TranslationMessages, key: string) => {
    const formattedMessage: string =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages),
  cn: formatTranslationMessages(DEFAULT_LOCALE, cnTranslationMessages),
};
