// https://www.typescriptlang.org/docs/handbook/advanced-types.html
import React from 'react';
import { Space, Input, message, DatePicker, Select, AutoComplete } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import AuthContext from '../../context/AuthContext';
import { upperFirst, lowerFirst, startCase, Dictionary } from 'lodash';
import ResponsiveModal, { ModalType } from './ResponsiveModal';
import { RecordType, InputType, dateFormat } from '../table/DataTypes';
import { CheckboxGroupVertical } from '../styled/AntdStyled';
import { errorMessage } from '../actions/ErrorMessage';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
// import FullWidthSpace from '../styled/FullWidthSpace';
// import { infoNotification } from '../actions/Notification';
import Tooltip from '../atomic/Tooltip';
import { MultiSelect } from '../atomic/Select';
import Tag, { TagItem } from '../atomic/Tag';
import Button from '../atomic/Button';
import Table from '../table/Table';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { StyledSpan } from '../styled/CenteredDiv';

export enum RecordModalAction {
  None = 'none',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Restore = 'restore',
  Custom = 'custom',
}

export type Option = { label: string, value: string };

/*
function isString(item: string | Option | TagItem): item is string {
  return (item as Option).label === undefined && (item as TagItem).name === undefined;
}

function isOption(item: string | Option | TagItem): item is Option {
  return (item as Option).label !== undefined;
}
*/

function isTagItem(item: string | Option | TagItem): item is TagItem {
  return (item as TagItem).name !== undefined;
}

export type ModalFieldEntry = {
  type: RecordType,
  placeholder?: string,
  inputType?: InputType,
  value?: any,
  renderFn?: (data: ModalFields) => void
  values?: string[] | Option[] | TagItem[],
  entries?: Dictionary<ModalFieldEntry>,
  maxLength?: number;
  variable?: boolean,
  actions?: RecordModalAction[],
  required?: boolean,
  columns?: ColumnsType<any>,
  columnData?: Dictionary<ModalFieldEntry>[],
  disabled?: boolean,
  reference?: string,
  updated?: boolean,
  fieldName?: string | JSX.Element,
  info?: string,
  autoFocus?: boolean,
  hide?: boolean,
  filter?: string,
  onUpdate?: (record: any) => any,
};

export type ModalPageFields = {
  entries: Dictionary<ModalFieldEntry>;
};

export type ModalFields = {
  pages: ModalPageFields[],
};

type RecordModalProps = {
  data: ModalFields;
  action: RecordModalAction;
  allowScan?: boolean;
  isLoading?: boolean;
  error?: string;
  title?: string;
  okText?: string;
  type?: ModalType;
  trigger?: JSX.Element;
  zIndex?: number;
  hideButtons?: boolean;
  lastChanged?: string;
  loadingMessage?: string;
  onSubmit: (data: any) => any;
  onCancel: () => any;
  onEmbedUpdate?: (data: any) => void;
};

type RecordModalState = {
  propsLastChanged: string;
  dataValue: any | undefined;
  modalPage: number;
  inputFields: any;
  changed: boolean;
  error: string | undefined;
};

class RecordModal extends React.Component<RecordModalProps, RecordModalState> {
  static contextType = AuthContext;

  constructor(props: RecordModalProps) {
    super(props);

    const inputFields = this.loadFields(this.props.data);

    this.state = {
      propsLastChanged: new Date().toUTCString(),
      dataValue: this.props.data,
      modalPage: 0,
      inputFields,
      changed: false,
      error: undefined,
    };
  }

  componentDidUpdate() {
    if (this.props.error && this.state.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    const dataValue = this.state.dataValue;
    let updateDataValue = false;
    const propsChanged = this.props.lastChanged && this.props.lastChanged !== this.state.propsLastChanged;
    if (this.state.changed || propsChanged) {
      if (this.props.lastChanged && this.props.lastChanged !== this.state.propsLastChanged) {
        this.setState({ propsLastChanged: this.props.lastChanged });
      }
      updateDataValue = true;
      const prevData = { ...dataValue };
      if (propsChanged) {
        const propsDataPages = this.props.data.pages;
        propsDataPages.forEach((page: ModalPageFields, index: number) => {
          // If a new page in the modal is added, push the new page. Assumption that any new page will only get appended to the end.
          if (prevData.pages.length < index + 1) {
            prevData.pages.push(page);
          }
          else {
            const propsDataFieldsKeys = Object.keys(page.entries);
            propsDataFieldsKeys.forEach((prevDataFieldKey: string) => {
              // If new field for a given page exists, add it to the object
              if (!prevData.pages[index].entries[prevDataFieldKey]) {
                // TODO: when to show notification?
                // infoNotification(`New field added: ${prevDataFieldKey}`);
                prevData.pages[index].entries[prevDataFieldKey] = page.entries[prevDataFieldKey];
              }
              // Else check if values changed. Will not update value given that it may have been updated.
              else {
                if (prevData.pages[index].entries[prevDataFieldKey].values !== page.entries[prevDataFieldKey].values) {
                  const oldLength = prevData.pages[index].entries[prevDataFieldKey].values.length;
                  const newLength = page.entries[prevDataFieldKey] && page.entries[prevDataFieldKey].values ? page.entries[prevDataFieldKey].values!.length : undefined;
                  if (oldLength !== newLength || (oldLength === undefined && newLength === undefined)) {
                    // infoNotification(`Value updated for field: ${prevDataFieldKey}`);
                    prevData.pages[index].entries[prevDataFieldKey].values = page.entries[prevDataFieldKey].values;
                  }
                }
                if (page.entries[prevDataFieldKey].value && prevData.pages[index].entries[prevDataFieldKey].value !== page.entries[prevDataFieldKey].value) {
                  prevData.pages[index].entries[prevDataFieldKey].value = page.entries[prevDataFieldKey].value;
                }
                if (page.entries[prevDataFieldKey].columnData && prevData.pages[index].entries[prevDataFieldKey].columnData !== page.entries[prevDataFieldKey].columnData) {
                  prevData.pages[index].entries[prevDataFieldKey].columnData = page.entries[prevDataFieldKey].columnData;
                }
                if (page.entries[prevDataFieldKey].columns && prevData.pages[index].entries[prevDataFieldKey].columns !== page.entries[prevDataFieldKey].columns) {
                  prevData.pages[index].entries[prevDataFieldKey].columns = page.entries[prevDataFieldKey].columns;
                }
                if (page.entries[prevDataFieldKey].renderFn && prevData.pages[index].entries[prevDataFieldKey].renderFn !== page.entries[prevDataFieldKey].renderFn) {
                  prevData.pages[index].entries[prevDataFieldKey].renderFn = page.entries[prevDataFieldKey].renderFn;
                }
                if (prevData.pages[index].entries[prevDataFieldKey].hide !== page.entries[prevDataFieldKey].hide) {
                  prevData.pages[index].entries[prevDataFieldKey].hide = page.entries[prevDataFieldKey].hide;
                }
                if (prevData.pages[index].entries[prevDataFieldKey].disabled !== page.entries[prevDataFieldKey].disabled) {
                  prevData.pages[index].entries[prevDataFieldKey].disabled = page.entries[prevDataFieldKey].disabled;
                }
                if (prevData.pages[index].entries[prevDataFieldKey].fieldName !== page.entries[prevDataFieldKey].fieldName) {
                  prevData.pages[index].entries[prevDataFieldKey].fieldName = page.entries[prevDataFieldKey].fieldName;
                }
              }
            });
            const removeFields = Object.keys(prevData.pages[index].entries).filter((removeKey: string) => !propsDataFieldsKeys.find((updatedKey: string) => updatedKey === removeKey));
            removeFields.forEach((removeFieldKey: string) => {
              // TODO: when to show notification?
              // infoNotification(`Field removed: ${removeFieldKey}`);
              delete prevData.pages[index].entries[removeFieldKey];
            });
          }
        });
      }
      // this.props.dataValue is undefined when submitting data.
      if (dataValue) {
        dataValue.pages.forEach((page: any, index: number) => {
          const newEntries = Object.keys(page.entries);
          const oldEntries = Object.keys(prevData.pages[index].entries);
          // Add new entries
          newEntries.forEach((pageEntry: string) => {
            if (!oldEntries.find((oldEntry: string) => oldEntry === pageEntry)) {
              updateDataValue = true;
              prevData.pages[index].entries[pageEntry] = page.entries[pageEntry];
            }
          });
          // remove entries no longer applicable
          oldEntries.forEach((pageEntry: string) => {
            if (!newEntries.find((newEntry: string) => newEntry === pageEntry)) {
              updateDataValue = true;
              delete prevData.pages[index].entries[pageEntry];
            }
          });
        });
      }
      const inputFields = this.loadFields(this.state.dataValue);
      if (updateDataValue) {
        this.setState({ dataValue: prevData });
      }
      this.setState({ inputFields, changed: false });
    }
    // if (this.props.error && this.state.dataValue === undefined && this.state.backupDataValue !== undefined) {
    //   this.setState({ dataValue: this.state.backupDataValue });
    // }
  }

  loadFields = (loadData: any) => {
    const modalFields = loadData;

    const allDataKeys: string[] = [];
    const allInputFields: any[] = [];

    // when cancelled, this will be undefined
    if (!modalFields) {
      return allInputFields;
    }
    modalFields.pages.forEach((data: any, pageIndex: number) => {
      const dataKeys = Object.keys(data.entries);
      allDataKeys.push(...dataKeys);

      const inputFields: any[] = [];
      dataKeys.forEach((key: string) => {
        console.log('dataKeys.forEach data');
        console.log(data);
        const fieldObject: string | JSX.Element = this.getFieldJsxObject(modalFields, data.entries, key, pageIndex, modalFields.pages[pageIndex].entries[key]);
        inputFields.push(<div style={{ width: '100%' }} key={`recordModal-${key}`}>{fieldObject}</div>);
      });
      allInputFields.push(inputFields);
    });
    console.log(allInputFields);
    return allInputFields;
  }

  onFieldChange = (newValue: any, key: string, updateValues?: boolean) => {
    const prevState = this.state.dataValue;
    if (updateValues) {
      prevState.pages[this.state.modalPage].entries[key].values = newValue;
    }
    else {
      prevState.pages[this.state.modalPage].entries[key].value = newValue;
    }
    if (prevState.pages[this.state.modalPage].entries[key].onUpdate) {
      prevState.pages[this.state.modalPage].entries[key].onUpdate(newValue);
    }
    prevState.pages[this.state.modalPage].entries[key].updated = true;
    this.setState({ dataValue: prevState, changed: true });
    if (this.props.onEmbedUpdate) {
      this.props.onEmbedUpdate(prevState);
    }
  }

  onFilterChange = (newValue: any, key: string) => {
    const prevState = this.state.dataValue;
    prevState.pages[this.state.modalPage].entries[key].filter = newValue;
    if (prevState.pages[this.state.modalPage].entries[key].onUpdate) {
      prevState.pages[this.state.modalPage].entries[key].onFilterUpdate(newValue);
    }
    prevState.pages[this.state.modalPage].entries[key].updated = true;
    this.setState({ dataValue: prevState, changed: true });
    if (this.props.onEmbedUpdate) {
      this.props.onEmbedUpdate(prevState);
    }
  }

  render() {
    const {
      action, isLoading, onCancel, onSubmit,
    } = this.props;
    const { modalPage, inputFields } = this.state;
    const title = this.props.title ? this.props.title : 'data';
    const modalTitle = (action === RecordModalAction.Create) ? `Add new ${title}` :
      (action === RecordModalAction.Update) ? `Update ${title}` :
      (action === RecordModalAction.Delete) ? `Delete ${title}` :
      (action === RecordModalAction.Restore) ? `Restore ${title}` :
      (title ? title : 'Unknown action');
    const okText = this.props.okText
      ? this.props.okText
      : (action === RecordModalAction.Create) ? "Add" :
        (action === RecordModalAction.Update) ? "Update" :
        (action === RecordModalAction.Delete) ? "Delete" :
        (action === RecordModalAction.Restore) ? "Restore" :
        "OK";

    return (
      <ResponsiveModal
        loadingMessage={this.props.loadingMessage}
        zIndex={this.props.zIndex}
        hideButtons={this.props.hideButtons}
        modalType={this.props.type ?? ModalType.ResponsiveModal}
        visible={action !== RecordModalAction.None}
        isLoading={isLoading}
        title={modalTitle}
        okText={(modalPage + 1) === inputFields.length ? okText : 'Next'}
        cancelText={(modalPage === 0) ? 'Cancel' : 'Previous'}
        onCancel={() => {
          if (modalPage !== 0) {
            this.setState({ modalPage: modalPage - 1 });
            return;
          }
          this.setState({ dataValue: undefined, changed: true });
          onCancel();
        }}
        onOk={() => {
          if (!this.state.dataValue) {
            message.error(`An error encoutered, please try again.`);
            return;
          }
          if ((modalPage + 1) < inputFields.length) {
            this.setState({ modalPage: modalPage + 1 });
            return;
          }
          if (this.state.error) {
            this.setState({ error: undefined });
          }
          /*
          if (this.state.dataValue === this.props.data) {
            message.error(`You must update code value.`);
            return;
          } */
          let verifyFieldsFailed = false;
          this.state.dataValue.pages.forEach((verifyPage: any) => {
            const verifyFieldsKeys = Object.keys(verifyPage.entries);
            verifyFieldsKeys.forEach((verifyField: string) => {
              if (verifyPage.entries[verifyField].required && (!verifyPage.entries[verifyField].value)) {
                verifyFieldsFailed = true;
                message.error(`Required field ${verifyField} missing data`);
                return;
              }
            });
          });
          if (verifyFieldsFailed) {
            return;
          }

          const data: any = this.state.dataValue;

          // this.setState({ dataValue: undefined, backupDataValue: data, changed: true });

          (action === RecordModalAction.None) ? onCancel() : onSubmit(data);
        }}>
        {this.props.trigger}
        <Space direction="vertical">
          {isLoading ? <div>Loading...</div> : null }
          {this.state.error ? errorMessage(`Failed to ${lowerFirst(modalTitle)}. Try again.\n
${this.state.error}`) : null }
          {inputFields[modalPage]}
        </Space>
      </ResponsiveModal>
    );
  }

  private getFieldJsxObject(modalData: ModalFields, data: any, key: string, pageIndex: number, valueNode: any, includeName = true) {
    if (data[key].hide) {
      return <></>;
    }
    let fieldObject: JSX.Element = <div>Unknown type {data[key].type} for {key}</div>;
    console.log('getFieldsJsxObject');
    console.log(data);
    console.log(key);
    console.log(pageIndex);
    // let variableObj: { [name: string]: JSX.Element } = {};
    // const dataSourceObj: { [name: string]: JSX.Element }[] = [];
    if (data[key].type === RecordType.Input) {
      fieldObject = data[key].values
        ? <AutoComplete
          autoFocus={valueNode.autoFocus}
          style={{ width: '100%' }}
          key={`input-${key}`}
          maxLength={valueNode.maxLength}
          disabled={valueNode.disabled}
          placeholder={data[key].placeholder}
          value={valueNode.value}
          options={valueNode.values && valueNode.value && valueNode.value.length > 0 ? valueNode.values.filter((filterOption: string) => filterOption.toLowerCase().includes(valueNode.value.toLowerCase())).map((valueOption: string) => {
            const index = valueOption.toLowerCase().indexOf(valueNode.value.toLowerCase());
            const optionLabel = <div>{valueOption.slice(0, index)}<StyledSpan bold>{valueOption.slice(index, index + valueNode.value.length)}</StyledSpan>{valueOption.slice(index + valueNode.value.length)}</div>;
            return ({ value: valueOption, label: optionLabel });
          }) : []}
          onChange={(value: string) => {
            this.onFieldChange(value, key);
          }}
          onSelect={(value: string) => {
            this.onFieldChange(value, key);
          }} />
        : <Input
        autoFocus={valueNode.autoFocus}
        style={{ width: '100%' }}
        key={`input-${key}`}
        maxLength={valueNode.maxLength}
        disabled={valueNode.disabled}
        placeholder={data[key].placeholder}
        value={valueNode.value}
        onChange={(e: any) => this.onFieldChange(e.target.value, key)} />;
    }
    if (data[key].type === RecordType.Text) {
      fieldObject = <Input.TextArea
        autoFocus={valueNode.autoFocus}
        autoSize
        style={{ width: '100%' }}
        key={`input-${key}`}
        maxLength={valueNode.maxLength}
        showCount={valueNode.maxLength ? true : false}
        disabled={valueNode.disabled}
        placeholder={data[key].placeholder}
        value={valueNode.value}
        onChange={(e: any) => this.onFieldChange(e.target.value, key)} />;
    }
    if (data[key].type === RecordType.TextValue) {
      fieldObject = <div>{valueNode.value}</div>;
    }
    if (data[key].type === RecordType.Checkbox) {
      fieldObject = data[key].values
        ? <CheckboxGroupVertical
          style={{ width: '100%' }}
          key={`input-${key}`}
          disabled={valueNode.disabled}
          options={data[key].values}
          defaultValue={data[key].value}
          onChange={(e: any) => this.onFieldChange(e, key)} />
        : <Checkbox
          style={{ width: '100%' }}
          key={`input-${key}`}
          disabled={valueNode.disabled}
          value={data[key].value}
          onChange={(e: any) => this.onFieldChange(e, key)}>{data[key].placeholder}</Checkbox>;
    }
    if (data[key].type === RecordType.MultiSelect) {
      if (valueNode.values && valueNode.values.length > 0 && !isTagItem(valueNode.values[0])) {
        console.error('RecordType.MultiSelect values is wrong type');
        return null;
      }
      const filter = valueNode.filter && valueNode.filter !== '' ? valueNode.filter.toLowerCase() : undefined;
      const tags: TagItem[] = valueNode.values as TagItem[];
      const filteredTags: TagItem[] = filter ? tags.filter((tag: TagItem) => tag.name.toLowerCase().includes(filter)) : tags;
      const selectedTags = valueNode.value as string[];
      fieldObject = <MultiSelect
        disabled={valueNode.disabled}
        customKey={`input-multi-select-${key}`}
        items={{ values: tags ?? [], render: (renderTag: TagItem) => {
          return <Tag
          key={`history-item-tag-${renderTag.uuid}`}
          value={renderTag}
          icon='close'
          onClick={(clickedTag: TagItem) => {
            const updateTags = [...selectedTags].filter((tagUuid: string) => tagUuid !== clickedTag.uuid);
            this.onFieldChange(updateTags, key); // onUpdateTag(updateTags);
          }}
          />;
        }}}
        notFoundContent={tags.length === 0 ? 'No data found' : undefined}
        defaultValue={selectedTags}
        placeholder='Add to a collection'
        onChange={(values: string[]) => {
          // infoNotification(`onChange clicked with values ${values}`);
          this.onFieldChange(values, key); // onUpdateTag(updateTags);
        }}
        filterOption={false}
        onSearch={(value: string) => {
          this.onFilterChange(value, key);
        }}
      >
        {filteredTags ? filteredTags.map((tag: TagItem) => <Select.Option key={`history-item-option-${tag.uuid}-${tag.name}`} value={tag.uuid ?? tag.name} label={tag.name}>
          <Tag key={`history-item-option-tag-${tag.uuid}-${tag.name}`} value={tag} />
        </Select.Option>) : null}
      </MultiSelect>;
    }
    if (data[key].type === RecordType.Index) {
      fieldObject = <div key={`index-${key}`}>{valueNode}</div>;
    }
    else if (data[key].type === RecordType.Date) {
      fieldObject = <DatePicker
        key={`date-picker-${key}`}
        defaultValue={valueNode.value ? moment(valueNode.value) : undefined}
        format={dateFormat}
        disabled={valueNode.disabled}
        onChange={(e: any) => this.onFieldChange(e, key)} />;
    }
    else if (data[key].type === RecordType.Dropdown) {
      const dropdownOptions = data[key].values ? data[key].values.map((item: any) => {
        const value = item.value ? item.value : item;
        const label = item.label ? item.label : item;
        return <Select.Option key={`dropdown-${key}-${value}`} value={value}>{label}{item.info ? <Tooltip placement="topLeft" title={item.info as string}> <QuestionCircleTwoTone /></Tooltip> : null}</Select.Option>;
      }) : [];
      // TODO: Add defaultValue
      fieldObject = <Select
        showSearch={data[key].onUpdate !== undefined}
        style={{ width: '100%' }}
        key={`dropdown-${key}`}
        placeholder={data[key].placeholder}
        disabled={valueNode.disabled}
        value={valueNode.value}
        onChange={(value: any, option: any) => this.onFieldChange(value, key)}
        onSelect={(value: any, option: any) => this.onFieldChange(value, key)}
        onSearch={data[key].onUpdate ? (val: string) => data[key].onUpdate(val) : undefined}
      >{dropdownOptions}</Select>;
    }
    else if (data[key].type === RecordType.Action) {
      fieldObject = data[key].values.map((item: any) => (
        <Button disabled={valueNode.disabled} key={`action-${key}-${item}`} value={item}>{item}</Button>));
    }
    else if (data[key].type === RecordType.Render) {
      return data[key].renderFn ? data[key].renderFn(modalData) : data[key].value;
    }
    else if (data[key].type === RecordType.Table) {
      const columnData = data[key].columnData;
      let selectable = false;
      const tableData = columnData.map((column: Dictionary<ModalFieldEntry>, index: number) => {
        const columnDatum = Object.assign({}, ...Object.keys(column).map((col: string) => {
          if (col === 'key' && column[col].type === RecordType.Index && column[col].value) {
            selectable = true;
            return ({ [col]: column[col].value });
          }
          return ({
            [col] : this.getFieldJsxObject(
              modalData,
              column,
              col,
              pageIndex,
              column[col].type === RecordType.Index ? /* index + */ 1 : column[col],
              false) });
        }));
        if (data[key].values) {
          columnDatum['row'] = columnDatum['key'] ?? index;
          columnDatum['key'] = columnDatum['key'] ?? index;
          selectable = true;
        }
        return columnDatum;
      });
      fieldObject = <Table
        style={{ overflow: 'auto' }}
        rowSelection={data[key].values && selectable ? {
          type: 'checkbox',
          selectedRowKeys: data[key].values,
          onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.onFieldChange(selectedRows.map((row: any) => row.row), key, true);
          },
          getCheckboxProps: (record: any) => ({
            name: record.row,
          }),
        } : undefined}
        key={`table-${key}`}
        className='.antd-modal-fields'
        size='small'
        columns={data[key].columns}
        dataSource={tableData}
        bordered={true}
        pagination={false}
        // scroll={{ x: '100', y: '300' }}
      />;
    }
    const fieldName = (typeof data[key].fieldName === "string" ? `${data[key].fieldName}:` : data[key].fieldName) ?? `${upperFirst(startCase(key))}:`;
    return <React.Fragment>{data[key].type === RecordType.Table || !includeName || fieldName === ':' ? '' : <div style={{ fontWeight: 'bold' }}>{fieldName} {data[key].info ? <Tooltip placement="topLeft" title={data[key].info}><QuestionCircleTwoTone /></Tooltip> : null}</div>}{fieldObject}{data[key].variable && data[key].type === RecordType.Table
      ? <Button onClick={() => {
      }}>Add new data field</Button>
      : ''}</React.Fragment>;
  }
}

export default RecordModal;
