import * as React from 'react';
import { FieldType, InputType } from '../../components/table/DataTypes';
import { ApiProps } from './Helpers';
import { GetDataCollections, dataCollectionResponse, GetDataCollectionsById, dataCollectionByIdResponse, AddDataCollection } from './Collection/DataCollection';
import { dataPagesResponse, GetDataPage, GetDataPages } from './DataPage';
import { dataObjectResponse, GetDataObjects } from './DataObject';
import { AddDataEntry, dataEntryResponse, GetDataEntries } from './Entry/DataEntry';
import { DataCollectionInput, DataEntryInput } from '../../graphql/generated/graphql';

export const dataApiDefinition = {
  objects: {
    getDataObjects: {
      type: 'query',
      function: (apiProps: ApiProps, includeInactive?: boolean) => {
        console.log('dataApiDefinition.getDataObjects.function');
        console.log(apiProps);
        console.group(includeInactive);
        return <React.Fragment><GetDataObjects includeInactive={includeInactive ?? false} {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
          includeInactive: { placeholder: 'true | false', type: FieldType.Dropdown, values: ['true', 'false'], inputType: InputType.Boolean },
        },
      },
      response: dataObjectResponse,
    },
  },

  entries: {
    getDataEntries: {
      type: 'query',
      function: (apiProps: ApiProps, objectUuids: string[]) => {
        console.log('dataApiDefinition.getDataEntries.function');
        console.log(apiProps);
        console.log(objectUuids);
        return <React.Fragment><GetDataEntries objectUuids={objectUuids} {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
          dataUuid: { placeholder: 'Enter object uuids comma separated', type: FieldType.InputArray, inputType: InputType.String },
        },
      },
      response: dataEntryResponse,
    },
    addDataEntry: {
      type: 'mutation',
      function: (apiProps: ApiProps, dataEntry: DataEntryInput) => {
        console.log('dataApiDefinition.addDataEntry.function');
        console.log(apiProps);
        console.log('dataEntry');
        console.log(dataEntry);
        return <React.Fragment><AddDataEntry dataEntry={dataEntry} {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
          dataEntry: {
            objectUuid: { placeholder: 'Enter object uuid', type: FieldType.Input, inputType: InputType.String },
            data: {
              entryUuid: { placeholder: 'Enter entry uuid', type: FieldType.Input, inputType: InputType.String },
              data: { placeholder: 'Enter data', type: FieldType.Input, inputType: InputType.String },
            },
          },
        },
      },
      response: dataEntryResponse,
    },
  },
  collections: {
    getDataCollections: {
      type: 'query',
      function: (apiProps: ApiProps) => {
        console.log('dataApiDefinition.getDataCollection.function');
        console.log(apiProps);
        return <React.Fragment><GetDataCollections {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
        },
      },
      response: dataCollectionResponse,
    },
    getDataCollectionsById: {
      type: 'query',
      function: (apiProps: ApiProps, collectionUuids: string[]) => {
        console.log('dataApiDefinition.getDataCollectionsById.function');
        console.log(apiProps);
        console.log(collectionUuids);
        return <React.Fragment><GetDataCollectionsById collectionUuids={collectionUuids} {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
          uuids: { placeholder: 'Enter collection uuids', type: FieldType.InputArray, inputType: InputType.String },
        },
      },
      response: dataCollectionByIdResponse,
    },
    addDataCollection: {
      type: 'mutation',
      function: (apiProps: ApiProps, dataCollection: DataCollectionInput) => {
        console.log('dataApiDefinition.getDataCollection.function');
        console.log(apiProps);
        console.log('dataCollection');
        console.log(dataCollection);
        return <React.Fragment><AddDataCollection dataCollection={dataCollection} {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
          dataCollection: {
            name: { placeholder: 'Enter name', type: FieldType.Input, inputType: InputType.String },
            description: { placeholder: 'Enter description', type: FieldType.Input, inputType: InputType.String },
            collectionUuids: { placeholder: 'Enter collection uuids', type: FieldType.InputArray, inputType: InputType.String },
            objectUuids: { placeholder: 'Enter object uuids', type: FieldType.InputArray, inputType: InputType.String },
            entryUuids: { placeholder: 'Enter entry uuids', type: FieldType.InputArray, inputType: InputType.String },
          },
        },
      },
      response: dataCollectionResponse,
    },
    updateDataCollection: {
      type: 'mutation',
      function: (apiProps: ApiProps) => {
      },
      arguments: {
        entries: {
        },
      },
      response: dataCollectionResponse,
    },
    deleteDataCollection: {
      function: (apiProps: ApiProps) => {
      },
      arguments: {
        entries: {
        },
      },
      response: dataCollectionResponse,
    },
  },
  pages: {
    getDataPages: {
      type: 'query',
      function: (apiProps: ApiProps) => {
        console.log('dataApiDefinition.getDataCollection.function');
        console.log(apiProps);
        return <React.Fragment><GetDataPages {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
        },
      },
      response: dataPagesResponse,
    },
    getDataPage: {
      type: 'query',
      function: (apiProps: ApiProps, uuid: string) => {
        console.log('dataApiDefinition.getDataCollection.function');
        console.log(apiProps);
        console.log(uuid);
        return <React.Fragment><GetDataPage uuid={uuid} {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
          uuid: { placeholder: 'Enter page uuid', type: FieldType.Input, inputType: InputType.String },
        },
      },
      response: dataPagesResponse,
    },
  },
};
