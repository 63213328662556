import React, { useEffect, useRef, useState } from "react";
import { Auth } from 'aws-amplify';
import { Store } from "antd/lib/form/interface";
import { AuthStatus } from "../../components/login/AuthStatusEnum";
import { errorNotification } from "../../components/actions/Notification";
import Reset from "../../components/login/ResetEmail";

type ResetContainerProps = {
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

const ResetContainer: React.FC<ResetContainerProps> = (props: ResetContainerProps) => {
  const [authState, setAuthState] = useState<AuthStatus>(AuthStatus.RequestReset);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const authStateRef = useRef(AuthStatus.RequestReset);
  authStateRef.current = authState;

  useEffect(() => {
    if (error) {
      errorNotification(error);
      setError(null);
    }
  },        [error]);

  const reset = (values: Store) => {
    const { code, password, email } = values;
    if (authStateRef.current === AuthStatus.RequestReset) {
      setLoading(true);
      Auth.forgotPassword(email.toLowerCase())
      .then(() => {
        setAuthState(AuthStatus.Reset);
        setLoading(false);
      })
      .catch((err: any) => {
        setError(err.message);
        setLoading(false);
        // this.setState({ authenticationStatus: AuthStatus.Fail });
      });
    }
    if (authStateRef.current === AuthStatus.Reset) {
      setLoading(true);
      Auth.forgotPasswordSubmit(email.toLowerCase(), code, password)
      .then(() => {
        setAuthState(AuthStatus.ResetDone);
        setLoading(false);
      })
      .catch((err: any) => {
        setError(err.message);
        setLoading(false);
        // this.setState({ authenticationStatus: AuthStatus.Fail });
      });
    }
  };

  const getContainer = () => {
    let container = <></>;
    switch (authState) {
      case AuthStatus.Reset:
      case AuthStatus.RequestReset:
      case AuthStatus.ResetDone: {
        container = <Reset
          key='confirm-email'
          reset={reset}
          authenticationStatus={authState}
          loading={loading} />;
        break;
      }
      default: {
        container = <></>;
        break;
      }
    }

    return (
    <>
      {container}
    </>);
  };

  return getContainer();
};

export default ResetContainer;
