import React, { useEffect, useState } from 'react';
import { AddDataCollectionMutation, DataCollectionInput, useAddDataCollectionMutation } from '../../../graphql/generated/graphql';
import { dataClientContext } from '../Api';

type AddCollectionProps = {
  collectionInput: DataCollectionInput;
  onUpdate?: (data?: AddDataCollectionMutation | null, loading?: boolean, error?: any) => any;
};

const AddCollection = (props: AddCollectionProps) => {
  const { collectionInput, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [addDataCollectionMutation, { data: newCollection, loading: loadingNew, error: errorNew }] = useAddDataCollectionMutation({
    ...dataClientContext,
  });

  useEffect(() => {
    if (!executed) {
      console.log(`add new collection '${collectionInput.name}'`);
      setExecuted(true);
      addDataCollectionMutation({ variables: { dataCollection: collectionInput } })
        .then((result: any) => {
          console.log(`done adding collection ${result.uuid}`);
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error adding collection ${collectionInput.name}`);
          console.log(err);
        });
    }
    if (onUpdate) {
      onUpdate(newCollection, loadingNew, errorNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [newCollection, loadingNew, errorNew]);

  return <></>;
};

export default AddCollection;
