import React, { useEffect }  from 'react';
import { useGetStocksQuery, StockPrice } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type StockProps = {
  tickers: string[];
  onUpdate?: (data?: StockPrice[], loading?: boolean, error?: any) => any;
};

const GetStock: React.FC<StockProps> = (props: StockProps) => {
  const { onUpdate, tickers } = props;
  const { data: stock, loading: stockLoading, error: stockError } = useGetStocksQuery({
    variables: { tickers },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(stock ? stock.getStocks : undefined, stockLoading, stockError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stock, stockLoading, stockError]);

  return null;
};

export default GetStock;
