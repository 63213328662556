/* @author: Alex Kronrod
 *
 * References:
 * https://ant.design/components/form/#Form.List
 * https://codesandbox.io/s/antd-form-list-multiple-formitem-dynamic-fields-gowd8?file=/src/DynamicField.js:638-643
 * https://codesandbox.io/s/lively-haze-cje76?fontsize=14&hidenavigation=1&theme=dark
 * https://codesandbox.io/s/listshouldupdate2-70b7h
 * https://codesandbox.io/s/jnpjpj2p55
 * https://github.com/ant-design/ant-design/issues/16404
 */
import React, { useState, useEffect } from 'react';
import { Form, Popconfirm, Button } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import EditableCell from './EditableCell';
import Table, { TableProps } from './Table';
import { RecordType, InputType } from './DataTypes';

type MakeRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

declare type EditableColumnsType<CellType = unknown> = (EditableColumnProps<CellType>)[];

export interface EditableColumnProps<CellType> extends ColumnType<CellType> {
  editable?: boolean;
  inputType: { recordType: RecordType, inputType?: InputType, value?: string, values?: string[] };
  required?: boolean;
  hidden?: boolean;
}

type EditableTableProps = Omit<MakeRequired<TableProps<any>, 'columns'|'dataSource'>, 'columns'> & {
  columns: EditableColumnsType<any>;
  actions?: JSX.Element[];
  updateDataSource: (data: any[]) => void;
  updateNewField?: (documentName: string) => void;
  updateEditing?: (editing: boolean) => void;
  editable?: boolean; // if undefined, handle as true
};

const EditableTable = (props: EditableTableProps) => {
  const {
    editable,
    actions,
    columns,
    dataSource,
    updateDataSource,
    updateNewField,
    updateEditing,
    ...tableProps } = props;
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  // const [isNewRow, setIsNewRow] = useState(false);
  // if empty array, then use table's default values

  const [expandedRows, setExpandedRows] = useState<React.Key[]>([]);

  useEffect(() => {
    if (props.expandedRowKeys && expandedRows !== props.expandedRowKeys) {
      setExpandedRows(props.expandedRowKeys);
    }
  },        [expandedRows, props.expandedRowKeys]);

  // TODO: any or EditableColumnProps<any>
  const isEditing = (record: any) => record.key === editingKey || dataSource.find((d:any) => d.newRow && record.key === d.key);

  const tabledEditEnabled = (editable === undefined) ? true : editable;

  const dataColumns = [...columns.filter((column: EditableColumnProps<any>) => column.hidden !== true),
    {
      title: 'operation',
      dataIndex: 'operation',
      inputType: { recordType: RecordType.Action },
      // TODO: any? for record or EditableColumnProps<any>
      render: (_: any, record: any) => {
        const editing = isEditing(record);
        if (editing && editingKey === '') {
          setEditingKey(record.key);
        }
        return editing
        ? (
          <span>
            <Button onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={() => record.newRow ? deleteRow(record) : cancel()}>
              <Button>Cancel</Button>
            </Popconfirm>
          </span>
        )
        : (
          <React.Fragment>
            {actions}
            <Button disabled={editingKey !== '' || !tabledEditEnabled} onClick={() => edit(record)}>
              Edit
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record)}>
              <Button disabled={editingKey !== '' || !tabledEditEnabled}>Delete</Button>
            </Popconfirm>
            {props.expandable
              ? <Button disabled={editingKey !== '' || !tabledEditEnabled} onClick={() => addNewField(record)}>Add new field</Button>
              : null}
          </React.Fragment>
        );
      },
    } as EditableColumnProps<any>,
  ];

  // TODO: any or EditableColumnProps<any>
  const edit = (record: any) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
    if (updateEditing) {
      updateEditing(true);
    }
  };

  const addNewField = (record: any) => {
    if (!expandedRows.includes(record.key)) {
      console.log(`expand row ${record.key} to add new field`);
      handleRowExpand(record);
    }
    if (updateNewField) {
      updateNewField(record.name);
    }
    if (updateEditing) {
      updateEditing(true);
    }
  };

  const cancel = () => {
    setEditingKey('');
    form.resetFields();
    if (updateEditing) {
      updateEditing(false);
    }
    form.resetFields();
  };

  const deleteRow = (record: any) => {
    const newData = [...props.dataSource.filter((d: any) => d.key !== record.key)];
    // setData(newData);
    updateDataSource(newData);
    if (editingKey !== '') {
      cancel();
    }
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields());
      if (row.type === RecordType.Dropdown) {
        const newValues: string[] = row.fields.map((field: any) => field.value);
        /*[];
        row.fields.foxrEach((key: string) => {
          if (row[`FormItem-${key}`]) {
            newValues.push(row[`FormItem-${key}`]);
          }
        });*/
        row.values = newValues;
      }

      const newData = [...props.dataSource];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      }
      else {
        newData.push(row);
      }
      const newRowIndex = dataSource.findIndex((d:any) => d.newRow);
      if (newRowIndex > -1) {
        delete newData[newRowIndex].newRow;
      }
      // setData(newData);
      updateDataSource(newData);
      if (updateEditing) {
        updateEditing(false);
      }
      setEditingKey('');
      form.resetFields();
    }
    catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleRowExpand = (record: any) => {
    // if a row is expanded, collapses it, otherwise expands it
    console.log(`toggle expand row ${record.key}`);
    setExpandedRows(
      expandedRows.includes(record.key)
        ? expandedRows.filter(key => key !== record.key)
        : [...expandedRows, record.key]);
  };

  const mergedColumns = dataColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      // TODO: any or EditableColumnProps<any>
      onCell: (record: any) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        required: col.required === undefined ? true : col.required,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const fields = props.dataSource
    .filter((data: any) => data.type === RecordType.Dropdown)
    .map((val: any) => [...val.values.map((field: any) => {
      return { name: val.name, value: field  };
    })]);

    /* {
      value: 'AK',
      type: 'string',
    },
    {
      value: 'TJ',
      type: 'string',
    }, */

  return (
    <Form form={form} component={false} initialValues={{ fields: fields.length === 0 ? [] : fields[0] }}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowClassName="editable-row"
        columns={mergedColumns as ColumnsType<any>}
        {...tableProps}
        dataSource = {props.dataSource}
        onExpand={(expanded, record) => handleRowExpand(record)}
        expandedRowKeys={expandedRows}
      />
    </Form>
  );
};

export default EditableTable;
