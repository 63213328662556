/* Steps to retrieve and process collection page data
 *
 * <ComponentA></ComponentA>
 *
 * 1. Get Collection UUID from URL
 * 2. Get Collection Data from GraphQL
 *    getPageData(collectionUuid) {
 *      uuid
 *      layout [
 *        {
 *          component
 *          content {
 *            collection [ // collection of collections, DOs, and items
 *              {
 *                type
 *                uuid
 *                fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *                  visible [
 *                    {
 *                      entryUuid
 *                      viewMap
 *                    }
 *                  ]
 *                  expandable [...]
 *                  hidden [...]
 *                }
 *                data { // entry data to display on the page.
 *                  entries [
 *                    {
 *                      uuid
 *                      name
 *                      data
 *                    }
 *                  ]
 *                }
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 *
 *    getPageData(collectionUuid) {
 *      uuid
 *      layout [
 *        {
 *          component
 *          collectionUuid
 *        }
 *      ]
 *      collection [ // collection of collections, DOs, and items
 *        {
 *          type
 *          uuid
 *          fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *            visible [
 *              {
 *                 entryUuid
 *                 viewMap
 *              }
 *            ]
 *            expandable [...]
 *            hidden [...]
 *          }
 *          data { // entry data to display on the page.
 *            entries [
 *              {
 *                uuid
 *                name
 *                data
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 *
 *    getPageData('starbucks_page_uuid') {
 *      uuid: 'starbucks_page_uuid'
 *      ownerUuid: 'owner_uuid'
 *      layout [
 *        {
 *          component
 *          collectionUuid
 *        }
 *      ]
 *      collection [ // collection of collections, DOs, and items
 *        {
 *          type
 *          uuid
 *          fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *            visible [
 *              {
 *                 entryUuid
 *                 viewMap
 *              }
 *            ]
 *            expandable [...]
 *            hidden [...]
 *          }
 *          data { // entry data to display on the page.
 *            entries [
 *              {
 *                uuid
 *                name
 *                data
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 */

import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { message, Select } from 'antd';
import { DeleteOutlined, EditOutlined, FilterOutlined, InfoCircleTwoTone, QuestionCircleTwoTone, ReloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { GetDataCollections } from '../../containers/api/Collection/DataCollection';
import { ResultViewEnum } from '../../containers/api/Helpers';
import ViewCollapsable from '../views/Collapsable';
import UserData from './User';
import EditCollectionMenu from '../../containers/api/Collection/EditCollectionMenu';
import ViewCard from '../views/Card';
import { getWindowDimension } from '../styled/ScreenSize';
import { RecordModalAction } from '../modal/RecordModal';
import ViewList from '../views/List';
import EditCollection from './Collection/EditCollection';
import ViewDraggable, { ViewTutorialType } from '../views/ViewDraggable';
import { CenteredDiv } from '../styled/CenteredDiv';
import { ExtraMenuType, LayoutDirection } from '../views/Helpers';
import ContentView from './ContentView';
import { DataCollectionData } from '../../graphql/generated/graphql';
import CollectionFilter, { CollectionSettings, CollectionSort } from '../filter/CollectionFilter';
import { DataViewMap } from './DataViewMap';
import BottomPageOrContent from '../atomic/BottomPageOrContent';
import Button from '../atomic/Button';
import Popconfirm from '../atomic/Popconfirm';
import CreateCollection from './Collection/CreateCollection';
import LoadingOverlay from '../atomic/LoadingOverlay';
import TutorialCollection, { sampleCollection } from '../tutorial/TutorialCollection';
import WizardTooltip, { ALLOWED_KEYS, WizardTooltipProps } from '../wizard/WizardTooltip';
import { getTutorialProps, TutorialNoneProps, TutorialType } from '../tutorial/Tutorial';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

enum ViewFilter {
  Alphabetical = 'Alphabetical order',
  CustomOrder = 'Custom order',
  All = 'All collections',
  S2P = 'S2P collections',
  Chinese = 'Chinese collections',
}

type CollectionsProps = {
};

const CollectionsData: React.FC<CollectionsProps> = (props: CollectionsProps) => {
  const [collectionsData, setCollectionsData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [selectedView, setSelectedView] = useState<any>('Sortable');
  const [lastUpdated, setLastUpdated] = useState<string>(new Date().toLocaleString());
  const [updateCollection, setUpdateCollection] = useState<JSX.Element | null>(null);
  const [triggerCreate, setTriggerCreate] =  useState<boolean>(false);
  const [selectedFilter] = useState<ViewFilter>(ViewFilter.All);
  const [debug, setDebug] = useState<number>(0);
  const [newOrdinal, setNewOrdinal] = useState<number>(-1);
  const [showTutorial, setShowTutorial] = useState<TutorialType>(TutorialType.None);
  const [entriesSettings, setEntriesSettings] = useState<CollectionSettings>({ sortBy: CollectionSort.Alphabetical, filter: [], hiddenCollections: [], showHidden: false });
  const showTutorialRef = useRef(TutorialType.None);
  showTutorialRef.current = showTutorial;
  const dataCollections = <GetDataCollections key={lastUpdated} view={ResultViewEnum.Card} updateApiResponse={(data?: any, loading?: any, error?: any) => {
    if (loading) {
      if (!collectionsData && loadingMessage !== 'Loading collections ...') {
        setLoadingMessage('Loading collections ...');
      }
      else if (loadingMessage !== 'Updating collections ...') {
        setLoadingMessage('Updating collections ...');
      }
    }
    else if (loadingMessage !== '') {
      setLoadingMessage('');
    }
    if (data) {
      if (selectedFilter === ViewFilter.CustomOrder) {
        setCollectionsData(data.sort((one, two) => (one.ordinal < two.ordinal ? -1 : 1)));
      }
      else {
        setCollectionsData(data.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)));
      }
      let updateOrdinal = -1;
      data.forEach((collection: any) => {
        if (collection.ordinal > updateOrdinal) {
          updateOrdinal = collection.ordinal;
        }
      });
      setNewOrdinal(updateOrdinal + 1);
    }
    setLoading(loading);
    setError(error);
  }}/>;

  console.log('data collections');
  console.log(collectionsData);

  const collectionDataItemsMap: DataViewMap = {
    itemId: (data: any) => {
      return data.uuid;
    },
    header: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        const collectionUuid = _.get(data, mapping.uuid.fields.uuid);
        const title = _.get(data, mapping.name.fields.name);
        if (!collectionUuid) {
          console.error(`collectionUuid not found in data with path ${mapping.uuid.fields.uuid}`);
        }
        if (!title) {
          console.error(`title not found in data with path ${mapping.name.fields.name}`);
        }
        return <a href={`/entries/${collectionUuid}`}>{title}</a>; // <div key='user-title'><span style={{ fontWeight: 'bold', width: 100, float: 'left' }}>{mapping.name.label}:</span> {_.get(data, mapping.name.fields.firstName)} {_.get(data, mapping.name.fields.lastName)}</div>;
      },
      mapping: {
        collections: { // itemUuid
          uuid: {
            label: 'Uuid',
            fields: {
              uuid: 'uuid',
            },
          },
          name: {
            label: 'Title',
            fields: {
              name: 'name',
            },
          },
        },
      },
    },
    content: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        const description = _.get(data, mapping.description.fields.description);
        return description ? <div key={`collection-content-description`}>{description}</div> : null;
        /*
        return Object.keys(mapping).map((m: any) => {
          const val = _.get(data, mapping[m].fields.);
          if (!val) {
            console.error(`content failed to find data with path ${mapping[m].field}`);
          }
          return <div key={`collection-content-${m}`}>{mapping[m].resolve ? mapping[m].resolve(val) : val}</div>;
        });
        */
      },
      mapping: {
        collections: {
          description: {
            label: 'Description',
            fields: {
              description: 'description',
            },
          },
        },
      },
    },
    modalContent: {
      mapping: {
        argument: {
          uuid: {
            label: '',
            fields: {
              uuid: 'uuid',
            },
          },
        },
        collection: {
          name: {
            label: 'name',
            fields: {
              name: 'name',
            },
          },
          description: {
            label: 'description',
            fields: {
              description: 'description',
            },
          },
        },
      },
    },
  };

  const data = collectionsData ? collectionsData.map((d: any, index: number) => {
    const extra: any[] = [];
    const ownerName = <UserData uuid={d.ownerUuid} />;
    extra.push(ownerName);
    if (d.description) {
      extra.push(<div>description: {d.description}</div>);
    }

    return ({
      header: [<a href={`/entries/${d.uuid}`}>{d.name}</a>],
      extra,
    });
  }) : [];

  const viewOptions = ['Collapseable', 'Card', 'List', 'Sortable'].map((e: any) => {
    return <Select.Option key={`option-view-${e}`} value={e}>{e}</Select.Option>;
  });

  const viewItems =
    <Select style={{ top: '30px' }} key='view-select' placeholder="Select View" value={selectedView} onSelect={(value: any) => {
      if (selectedView !== value) {
        setSelectedView(value);
      }
    }}>
      {viewOptions}
    </Select>;

  const windowHeight = getWindowDimension().width;
  const style = windowHeight > 500
    ? { top: 15, marginLeft: '10px', marginRight: '10px' }
    : { top: 15, marginLeft: '10px', marginRight: '10px' };

  const extraMenu = (initialData: any) => {
    const menuItems: ExtraMenuType = {
      items: [
        <EditOutlined onClick={() => onEditCollection(initialData)}/>,
        <Popconfirm
          placement={'leftTop'}
          title={<><div>Deleted Collection cannot be restored.</div><div>Please confirm that you want to delete the Collection.</div></>}
          onConfirm={() => onDeleteCollection(initialData)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>,
      ], // (uuid: string, initialData: { [field: string] : string }) => <EditCollectionMenu uuid={uuid} initialData={initialData} />;
      direction: LayoutDirection.Horizontal,
    };
    return menuItems;
  };

  const onEditCollection = (collection: DataCollectionData) => {
    setLoadingMessage('Updating collection ...');
    setUpdateCollection(<EditCollection key={`update-collection-${collection.uuid}`} action={RecordModalAction.Update} initialData={collection} mapping={collectionDataItemsMap} onSuccess={onSuccess} onCancel={onCancel} />);
  };

  const onDeleteCollection = (collection: DataCollectionData) => {
    setLoadingMessage('Deleting collection ...');
    setUpdateCollection(<EditCollection autoExecute key={`update-collection-${collection.uuid}`} action={RecordModalAction.Delete} initialData={collection} mapping={collectionDataItemsMap} onSuccess={onSuccess} onCancel={onCancel}/>);
  };

  const onSuccess = (data?: any, loading?: boolean, error?: string) => {
    setLoadingMessage('');
    // data is used for creating a collection inside create entry. No need here.
    if (error) {
      setError(error);
      setLoading(loading);
      return;
    }
    setLastUpdated(new Date().toLocaleString());
    setTriggerCreate(false);
    setNewOrdinal(newOrdinal + 1);
    setLoading(loading);
    setUpdateCollection(null);
  };

  const onCancel = () => {
    setLoadingMessage('');
    setTriggerCreate(false);
    setUpdateCollection(null);
  };

  const noDataMessage =
    <div>
      <CenteredDiv style={{ fontWeight: 'bold' }}>There are no Collections in your account.</CenteredDiv>
      <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> You can create a Collection to track a to-do list, a project to track tasks, or create a routine.</div>
      <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> An Entry can belong to multiple Collections, allowing you to organize Entries the way you want it.</div>
      <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> Filter and sort Entries in a collection to highlight the data you want to see.</div>
      <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> Click on <QuestionCircleTwoTone onClick={() => setShowTutorial(TutorialType.CollectionCreateNew)}/> for more details.</div>
      <br/>
      <br/>
      <CenteredDiv><span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setTriggerCreate(true)}>Create a new Collection</span> or <Link to='/entries' style={{ color: 'blue', cursor: 'pointer' }}>View Entries in your account.</Link></CenteredDiv>
    </div>;

  const filterCollectionsData = (collectionsData) => {
    if (selectedFilter === ViewFilter.All || selectedFilter === ViewFilter.Alphabetical) {
      return collectionsData;
    }
    if (selectedFilter === ViewFilter.CustomOrder) {
      return collectionsData.sort((one, two) => (one.ordinal < two.ordinal ? -1 : 1));
    }
    const filterKey: string = (Object.keys(ViewFilter).find((item: string) => ViewFilter[item] === selectedFilter) ?? 'All').toLowerCase();
    return collectionsData.filter((data: DataCollectionData) => {
      return data.name.toLowerCase().includes(filterKey) || (data.description && data.description.toLowerCase().includes(filterKey));
    });
  };

  const onTutorialClose = () => setShowTutorial(TutorialType.None);

  const getTutorialNextPrev = (type: TutorialType): { nextPage: TutorialType | null, prevPage: TutorialType | null } => {
    const order = [
      TutorialType.CollectionCreateNew,
      TutorialType.CollectionItemName,
      TutorialType.CollectionItemToggle,
      TutorialType.CollectionItemDescription,
      TutorialType.CollectionItemEditDelete,
    ];
    const index = order.findIndex((item: TutorialType) => item === type);
    const nextPage = index + 1 === order.length || index === undefined ? null : order[index + 1];
    const prevPage = index === 0 || index === undefined ? null : order[index - 1];
    return { nextPage, prevPage };
  };

  const onTutorialKeyPress = (currentPage: TutorialType, key: string) => {
    const currentShowTutorial = showTutorialRef.current;
    if (currentPage !== currentShowTutorial) {
      return;
    }
    const { prevPage, nextPage } = getTutorialNextPrev(currentShowTutorial);
    if (key === ALLOWED_KEYS.ArrowLeft && prevPage) {
      setShowTutorial(prevPage);
    }
    if (key === ALLOWED_KEYS.ArrowRight && nextPage) {
      setShowTutorial(nextPage);
    }
  };

  const getTutorialButtons = (type: TutorialType): JSX.Element[] => {
    const { prevPage, nextPage } = getTutorialNextPrev(type);
    return [
      <Button key='tutorial-close-button' style={{ background: 'darkgray' }} onClick={() => setShowTutorial(TutorialType.None)}>Close</Button>,
      ...(prevPage === null) ? [] : [<Button key='tutorial-prev-button' style={{ background: 'darkgray' }}  onClick={() => setShowTutorial(prevPage)}>Previous</Button>],
      ...(nextPage === null) ? [] : [<Button key='tutorial-next-button' style={{ background: 'darkgray' }}  onClick={() => setShowTutorial(nextPage)}>Next</Button>],
    ];
  };

  const getTutorial = (): { type: ViewTutorialType, props: WizardTooltipProps, index?: number }  => {
    if (showTutorial === TutorialType.CollectionItemName) {
      return { type: ViewTutorialType.Header, props: getTutorialProps(TutorialType.CollectionItemName, onTutorialClose, getTutorialButtons(TutorialType.CollectionItemName), onTutorialKeyPress) };
    }
    if (showTutorial === TutorialType.CollectionItemToggle) {
      return { type: ViewTutorialType.LeftElement, props: getTutorialProps(TutorialType.CollectionItemToggle, onTutorialClose, getTutorialButtons(TutorialType.CollectionItemToggle), onTutorialKeyPress) };
    }
    if (showTutorial === TutorialType.CollectionItemDescription) {
      return { type: ViewTutorialType.Content, props: getTutorialProps(TutorialType.CollectionItemDescription, onTutorialClose, getTutorialButtons(TutorialType.CollectionItemDescription), onTutorialKeyPress), index: 1 };
    }
    if (showTutorial === TutorialType.CollectionItemEditDelete) {
      return { type: ViewTutorialType.RightElement, props: getTutorialProps(TutorialType.CollectionItemEditDelete, onTutorialClose, getTutorialButtons(TutorialType.CollectionItemEditDelete), onTutorialKeyPress) };
    }

    return { type: ViewTutorialType.None, props: TutorialNoneProps };
  };

  return (
    <>
      <TutorialCollection tutorial={showTutorial} />
      <LoadingOverlay
        active={loading || updateCollection !== null}
        spinner
        text={`${loadingMessage}`}
        >
        {dataCollections}
        {updateCollection}
        <ContentView
          hideDivider={selectedFilter === ViewFilter.All && !(collectionsData && collectionsData.length === 0)}
          actionButtons={[
            <Button hidden={true} key='collection-filter' style={{ float: 'right' }}>
              <CollectionFilter
                settings={entriesSettings}
                onUpdateSetting={(updateSettings: CollectionSettings) => setEntriesSettings(updateSettings)}
                icon={<FilterOutlined />}
              />
            </Button>,
            <Button key='entry-refresh' style={{ float: 'right' }} onClick={() => setLastUpdated(new Date().toLocaleString())}><ReloadOutlined /></Button>,
            <WizardTooltip
              key={`tutorial-collections-create-new`}
              {...getTutorialProps(
                showTutorial === TutorialType.CollectionCreateNew ? showTutorial : TutorialType.None,
                onTutorialClose,
                getTutorialButtons(TutorialType.CollectionCreateNew),
                onTutorialKeyPress)}
            >
              <CreateCollection key={lastUpdated} onSuccess={onSuccess} onCancel={onCancel} triggerCreate={triggerCreate} ordinal={newOrdinal} />
            </WizardTooltip>,
          ]}
          data={
            <>
                {selectedFilter !== ViewFilter.All ? <div><span style={{ fontWeight: 'bold' }}>Filter: </span>{selectedFilter}</div> : null}
                {collectionsData && selectedView === 'Collapseable' ? <ViewCollapsable key={'collapseable-collections'} style={style} data={data} mapping={filterCollectionsData(collectionsData)} sourceName={'collections'} extraMenu={(uuid: string, initialData: { [field: string] : string }) => ({ items: [<EditCollectionMenu uuid={uuid} initialData={initialData} />] })} /> : null}
                {collectionsData && selectedView === 'Card' ? <ViewCard customKey={'viewcard-collections'} style={style} size={'small'} loading={collectionsData === null} data={filterCollectionsData(collectionsData)} mapping={collectionDataItemsMap} sourceName='collections' extraMenu={extraMenu} ></ViewCard> : null}
                {collectionsData && selectedView === 'List' ? <ViewList key={'viewlist-collections'} loading={loading} style={style} size={'small'} data={filterCollectionsData(collectionsData)} mapping={collectionDataItemsMap} sourceName='collections' extraMenu={extraMenu} ></ViewList> : null}
                {collectionsData && selectedView === 'Sortable'
                  ? <ViewDraggable
                    tutorial={getTutorial()}
                    info={<Button type="text" key='collection-info' style={{ float: 'left' }}><QuestionCircleTwoTone style={{ float: 'left' }} onClick={() => setShowTutorial(TutorialType.CollectionCreateNew)}/></Button>}
                    customKey={'draggable-collections'}
                    loading={loading}
                    style={style}
                    data={![TutorialType.CollectionItemDescription, TutorialType.CollectionItemToggle, TutorialType.CollectionItemEditDelete, TutorialType.CollectionItemName].find((item: TutorialType) => item === showTutorial) ? filterCollectionsData(collectionsData) : sampleCollection}
                    mapping={collectionDataItemsMap}
                    sourceName='collections'
                    extraMenu={extraMenu}
                    noDataMessage={noDataMessage}
                    collapseable
                    dragDisabled={selectedFilter !== ViewFilter.CustomOrder}>
                  </ViewDraggable> : null}
                <CenteredDiv>
                  {debug > 10 ? <div style={{ padding: '10px' }}>{viewItems}</div> : null}
                  <BottomPageOrContent pinBottom={!collectionsData || collectionsData.length === 0}>
                    <div onClick={() => setDebug(debug + 1)} >last updated: {lastUpdated}</div>
                    {debug > 10 ? (error ? <div>error: {error}</div> : null) : null}
                  </BottomPageOrContent>
                </CenteredDiv>
            </>}
        />
      </LoadingOverlay>
    </>
  );
};

export default CollectionsData;
