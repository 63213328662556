import gql from 'graphql-tag';
import DataPageData from './DataPageData';

export default gql`
  fragment DataPage on DataPage {
    uuid
    data
    {
      ...DataPageData
    }
    ownerUuid
    name
    description
    dateCreated
    active
    dateRemoved
  }
  ${DataPageData}
`;
