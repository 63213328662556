import { Collapse, Space } from "antd";
import { CollapseProps } from "antd/lib/collapse";
import React from "react";
import { ExtraMenuType } from "./Helpers";

type DisplayMapping = {
  name: string,
  field: string,
  type: string,
};

type PanelItem = {
  header: any[];
  extra: any;
};

type ViewCollapseableProps = CollapseProps & {
  data: PanelItem[];
  mapping: any;
  sourceName: string;
  extraMenu?: (uuid: string, initialData: { [field: string] : string }) => ExtraMenuType;
};

const ViewCollapsable: React.FC<ViewCollapseableProps> = (props: ViewCollapseableProps) => {
  const { data, mapping, extraMenu, sourceName, ...collapseProps } = props;
  console.log('ViewCollapsable');
  console.log(data);
  const panelItems = data.map((item: any, index: number) => {
    console.log('ViewCollapsable item');
    console.log(item);
    const initialData = {
      Name: 'Foo',
      Description: 'Bar',
    };
    const headerItem = <Space direction='vertical'>{item.header.map((header: any, index: number) => <div key={`div-${index}`}>{header}</div>)}</Space>;
    const extraItem = item.extra.length > 0 ? <Space direction='vertical'>{item.extra.map((extra: any, index: number) => <div key={`div-${index}`}>{extra}</div>)}</Space> : null;
    return <Collapse.Panel showArrow={extraItem ? true : false} key={`data-${index}`} header={headerItem} extra={extraMenu ? extraMenu('', initialData).items : undefined}>{extraItem}</Collapse.Panel>;
  });

  const response = (<>
    <Collapse
      {...collapseProps}
    >
      {panelItems}
      </Collapse>
    </>);
  console.log(response);
  return response;
};

export default ViewCollapsable;
