import { CloseCircleTwoTone } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import EnclosedIcon, { IconProps } from './EnclosedIcon';

const CloseStyled = styled(CloseCircleTwoTone)`
  z-index: 2000;
  font-size: 14px;
  margin-right: 0px;
`;

type CloseIconProps = IconProps & {
};

const CloseIcon: React.FC<CloseIconProps> = (props: CloseIconProps) => {
  const { onClick, enclosed, color } = props;
  const icon = <CloseStyled twoToneColor={color ?? undefined} />;

  return <EnclosedIcon icon={icon} enclosed={enclosed} onClick={onClick} />;
};

export default CloseIcon;
