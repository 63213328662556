import React, { useRef, useState } from "react";
import { CloseSquareTwoTone } from "@ant-design/icons";
import Button from "../atomic/Button";
import { errorNotification, infoNotification } from '../actions/Notification';

type ClosureSampleProps = {
};

const ClosureExample: React.FC<ClosureSampleProps> = (props: ClosureSampleProps) => {
  const [items, setItems] = useState<string[]>([]);
  const [counter, setCounter] = useState<number>(1);
  const itemsRef = useRef([] as string[]);
  itemsRef.current = items;

  return (
    <>
      <Button onClick={() => {
        const newItem = `item #${counter}`;
        const updatedItems = [...items, newItem];
        setItems(updatedItems);
        setCounter(counter + 1);
        infoNotification(`item added: ${newItem}`, 'Undo', () => {
          const currentItems = itemsRef.current;
          // Issue:
          // 1) If i use updateItems, after adding 2 items and clicking "Undo" on the first item,
          // updateItems doesn't have the second item, so it deleted both.
          // 2) If i use items, the state is stale and doesn't include the last item that was added
          // (the one I want to remove as part of undo)
          if (!currentItems.find((existentItem: string) => existentItem === newItem)) {
            errorNotification(`Item already removed`);
            return;
          }
          const updateItems = [...currentItems].filter((existingItem: string) => existingItem !== newItem);
          infoNotification(`Item removed: ${newItem}`);
          setItems(updateItems);
        });
      }}>Add item</Button>
      <Button onClick={() => {
        setItems([]);
        setCounter(1);
      }}>Reset</Button>
      <div>items: {items.join(',')}</div>
      <div>counter: {counter}</div>
      {items.map((item: string, index: number) => <div><CloseSquareTwoTone onClick={() => {
        const updatedItems = [...items].filter((existingItem: string) => existingItem !== item);
        setItems(updatedItems);
        infoNotification(`Removed item: ${item}`, 'Undo', () => {
          const currentItems = itemsRef.current;
          if (currentItems.find((existentItem: string) => existentItem === item)) {
            infoNotification(`items state for undo removal: ${items.join()}`);
            infoNotification(`Item already restored`);
            return;
          }
          infoNotification(`Item restored: ${item}`);
          setItems([...[...currentItems].slice(0, index), item, ...[...currentItems].slice(index)]);
        });
      }} style={{ fontSize: '22px' }} twoToneColor='red' />{item}</div>)}
    </>
  );
};

export default ClosureExample;
