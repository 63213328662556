import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { CardProps as CardPropsAnt } from "antd/lib/card";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { EmptyMenu, ExtraMenuType, jsxJoin, ViewProps } from "./Helpers";
import ViewEntry from "./Entry";
import WizardTooltip, { WizardTooltipProps } from "../wizard/WizardTooltip";
import { TutorialNoneProps } from "../tutorial/Tutorial";

export enum CardTutorialType {
  None = 0,
  LeftElement,
  RightElement,
  Header,
  Content,
}

type CardProps = ViewProps & CardPropsAnt & {
  customKey: string;
  tutorial?: { type: CardTutorialType, props: WizardTooltipProps, index?: number };
};

const ViewCard: React.FC<CardProps> = (props: CardProps) => {
  const { noDataMessage, customKey: key, style, data, mapping, sourceName, collapseable, extraMenu, ...cardProps } = props;
  const [hideContent, setHideContent] = useState<{ [key: string]: boolean }>({});
  const [response, setResponse] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!data || props.loading) {
      if (response === null || !loading) {
        if (!loading) {
          setLoading(true);
        }
        setResponse(<Card key={key} loading={true} />);
      }
      return;
    }
    if (loading) {
      setLoading(false);
    }

    if (data.length === 0) {
      setResponse(noDataMessage ?? null);
      return;
    }

    updateDataView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [data, hideContent, loading]);

  useEffect(() => {
    if (!data) {
      return;
    }
    updateDataView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [hideContent, props]);

  const getTutorial = (type: CardTutorialType, index: number) => {
    if (!props.tutorial || type !== props.tutorial.type || (props.tutorial.index !== null && index === props.tutorial.index) || (props.tutorial.index === null && index !== 0)) {
      return TutorialNoneProps;
    }
    return props.tutorial.props;
  };

  const updateDataView = () => {
    if (data.length === 0 && response !== noDataMessage) {
      setResponse(noDataMessage ?? null);
      return;
    }
    const cardItems: JSX.Element[] = data.map((item: any, index: number) => {
      console.log('ViewCard item');
      console.log(item);
      // const label = props.labelWidth ? <div style={{ width: props.labelWidth }}>{item.label}</div> : item.label;

      const cardKey = `${key}-${index}`;
      /*
      const showIcon = <RightOutlined onClick={() => extraFn(cardKey)} />;
      const hideIcon = <DownOutlined onClick={() => extraFn(cardKey)} />;
      let extra = collapseable
        ? (hideContent[cardKey] || hideContent[cardKey] === undefined ? showIcon : hideIcon)
        : undefined;
      if (props.extraMenu) {
        extra = <>{extra}{props.extraMenu(item)}</>;
      }
      */
      if (!mapping.header || !mapping.header.mapFn || !mapping.header.mapping || !mapping.header.mapping[sourceName]) {
        console.error(`ViewCard header mapping invalid for item: ${mapping.header}`);
        return <></>;
      }
      if (mapping.content && (!mapping.header.mapFn || !mapping.header.mapping || !mapping.header.mapping[sourceName])) {
        console.error(`ViewCard content mapping invalid for item ${mapping.content}`);
        return <></>;
      }
      const hide = collapseable && (hideContent[cardKey] || hideContent[cardKey] === undefined);
      const header = mapping.header.mapFn(item, mapping.header.mapping[sourceName], hide);
      const content = mapping.content ? mapping.content.mapFn(item, mapping.content.mapping[sourceName], hide) : null;
      const menu: ExtraMenuType | undefined = extraMenu ? extraMenu(item) : (collapseable && content ? EmptyMenu : undefined);
      if (collapseable && menu) {
        menu.items.push(content && hide ? <CaretDownOutlined onClick={() => toggleShowContent(cardKey)}/> : <CaretUpOutlined onClick={() => toggleShowContent(cardKey)}/>);
      }
      return hide || !content
          ? <ViewEntry
            key={cardKey}
            header={<WizardTooltip {...getTutorial(CardTutorialType.Header, index)}>{header}</WizardTooltip>}
            leftWidth={mapping.header.leftWidth}
            leftElement={mapping.header.leftElement ? <WizardTooltip {...getTutorial(CardTutorialType.LeftElement, index)}>{mapping.header.leftElement(item)}</WizardTooltip> : undefined}
            rightWidth='35px'
            rightElement={<div style={{ marginRight: '5px' }}>{menu ? <WizardTooltip {...getTutorial(CardTutorialType.RightElement, index)}>{menu.items.map((item: JSX.Element) => <div style={{ marginLeft: '2px' }}>{item}</div>)}</WizardTooltip> : null}</div>}
            content={<WizardTooltip {...getTutorial(CardTutorialType.Content, index)}>{content}</WizardTooltip>}
            />
          // <Card {...cardProps} key={cardKey} actions={menu}>{title}</Card>
          : <Card {...cardProps} key={cardKey} title={<WizardTooltip {...getTutorial(CardTutorialType.Header, index)}>{header}</WizardTooltip>} actions={menu ? menu.items : undefined}><WizardTooltip {...getTutorial(CardTutorialType.RightElement, index)}>{content}</WizardTooltip></Card>;
    }).filter((validItem: any) => validItem !== <></>);

    setResponse(<>
      {jsxJoin(cardItems, <br />)}
    </>);
  };

  const toggleShowContent = (itemKey: string) => {
    const newHideContent = hideContent;
    newHideContent[itemKey] = (hideContent[itemKey] === undefined) ? false : !hideContent[itemKey];
    setHideContent(newHideContent);
    updateDataView();
  };

  /*
  const extraFn = (key: string) => {
    const prevHideContent = hideContent;
    prevHideContent[key] = prevHideContent[key] === undefined ? false : !prevHideContent[key] ;
    setHideContent(prevHideContent);
  };
  */

  return response;
};

  // const extra = collapseable ? (key: string) => { prevShowContent[key] ?  } : undefined;

export default ViewCard;
