import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout, Typography, Space } from 'antd';
import Page from '../components/layout/Page';
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom';

const { Title } = Typography;

// background: #fff;
const Content = styled(Layout)`
  min-height: 100%;
`;

class Overview extends Component {
  static contextType = AuthContext;

  onClickException() {
  }

  render() {
    return (
      <Page headerTitle='Overview' content={
        <Content>
          <div>Manage your <Link to='/pages'>pages</Link></div>
          <div>Manage your <Link to='/pages'>collections</Link></div>
          <Space direction='horizontal'>
            <Space direction='vertical'>
              <Title level={4} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Favorites</Title>
            </Space>
            <Space direction='vertical'>
            <Title level={4} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Due Items</Title>
              <div>Nothing due this week</div>
            </Space>
            <Space direction='vertical'></Space>
          </Space>
        </Content>}
      />
    );
  }
}

export default Overview;
