import { List } from 'antd';
import React from 'react';
import { TagItem } from '../atomic/Tag';
import { StyledSpan } from '../styled/CenteredDiv';
import { defaultTags, QRHistoryItem } from './QRHistory';
import QRHistoryItemViewEntry from './QRHistoryItemViewEntry';

type QRHistoryItemViewProps = {
  history: QRHistoryItem[];
  tags: TagItem[];
  selectedTag: string | null;
  isNew: boolean;
  editItem: QRHistoryItem | null;
  onEdit: (updateItem: QRHistoryItem | null) => void; // null to cancel editing
  onUpdate: (updateItem: QRHistoryItem) => void;
  onUpdateTag: (updateItem: QRHistoryItem) => void;
  onSelect: (selectItem: QRHistoryItem) => void;
  onDelete: (deleteItem: QRHistoryItem) => void;
};

const QRHistoryItemView: React.FC<QRHistoryItemViewProps> = (props: QRHistoryItemViewProps) => {
  const { history, tags, isNew, editItem, selectedTag, onUpdate, onUpdateTag, onDelete } = props;

  const itemsToDisplay = props.isNew && props.editItem
  ? [props.editItem]
  : (!selectedTag
      ? []
      : (selectedTag === defaultTags.allItems.key
        ? history
        : history.filter((filterItem: QRHistoryItem) => selectedTag === defaultTags.itemsWithoutTags.key
                                                          ? (!filterItem.tagUuids || filterItem.tagUuids.length === 0)
                                                          : (!filterItem.tagUuids
                                                              ? false
                                                              : filterItem.tagUuids.some((tagUuid: string) => selectedTag === tagUuid))))).sort((one, two) => (one.date < two.date ? -1 : 1));
  const dataSource = itemsToDisplay.map((item: QRHistoryItem) => {
    return(<QRHistoryItemViewEntry
        key={`item-view-entry-${item.uuid}`}
        item={editItem !== null && editItem.uuid === item.uuid ? editItem : item}
        tags={tags}
        isDisabled={editItem !== null && editItem.uuid !== item.uuid}
        isEdit={editItem !== null && editItem.uuid === item.uuid}
        isNew={isNew}
        onUpdate={(updateItem: QRHistoryItem) => {
          onUpdate(updateItem);
        }}
        onUpdateTag={(updateItem: QRHistoryItem) => {
          onUpdateTag(updateItem);
        }}
        onDelete={(deleteItem: QRHistoryItem) => {
          onDelete(deleteItem);
          /*
          const updateHistory = [...history].filter((item: QRHistoryItem) => item.uuid !== itemUuid);
          setHistory(updateHistory);
          */
        }}
        onSelect={props.onSelect}
        onEdit={props.onEdit}
        />);
  });

  return (
    <div>
      {isNew ? <StyledSpan bold>Save new item to QR history:</StyledSpan> : <StyledSpan bold>QR history:</StyledSpan>}
      <br />
      {selectedTag === null
        ? <div>No tag selected. Please select tag above.</div>
        : itemsToDisplay && itemsToDisplay.length > 0
          ? <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1,
              }}
              dataSource={dataSource}
              renderItem={item => (
                item
              )} />
          : (history.length > 0
            ? <div>No history found with selected tags.</div>
            : <div>No history found.</div>)}
    </div>
  );
};

export default QRHistoryItemView;
