import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HtmlTableToJson from 'html-table-to-json';
import { default as PageComponent } from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
import { Checkbox, Input, Radio, Space } from 'antd';
import ReactJson from 'react-json-view';
import Button from '../components/atomic/Button';
import FetchData from '../containers/api/Stock/FetchData';
import { errorNotification } from '../components/actions/Notification';
import Select from '../components/atomic/Select';
import { FetchDestination } from '../graphql/generated/graphql';

const { TextArea } = Input;

type PrettyJsonProps = RouteComponentProps & {
};

enum DataInputType {
  Json = 'json',
  Html = 'html',
  Csv = 'Csv',
}

const PrettyJson: React.FC<PrettyJsonProps> = (props: PrettyJsonProps) => {
  const [url, setUrl] = useState<string>('');
  const [lastFetched, setLastFetched] = useState<string>(new Date().toLocaleString());
  const [inputData, setInputData] = useState<string | undefined>(undefined);
  const [jsonData, setJsonData] = useState<JSON>(JSON.parse("{}"));
  const [error, setError] = useState<string|null>(null);
  const [minimizeInput, setMinimizeInput] = useState<boolean>(false);
  const [collapseable, setCollapseable] = useState<boolean>(false);
  const [escape, setEscape] = useState<boolean>(false);
  const [auth, setAuth] = useState<string>('');
  const [dataType, setDataType] = useState<DataInputType>(DataInputType.Json);
  const [executeFetch, setExecuteFetch] = useState<boolean>(false);
  const [fetchType, setFetchType] = useState<'Cookie' | 'Bearer' | 'Direct' | 'None'>('None');

  return (
    <PageComponent headerTitle={'Sample Page'} content={
      <Content>
        {executeFetch
          ? <FetchData
            command={url}
            token={auth}
            destination={fetchType === 'Bearer' ? FetchDestination.Bearer : (fetchType === 'Cookie' ? FetchDestination.Cookie : FetchDestination.Robinhood)}
            onUpdate={(data?: string) => {
              if (data) {
                setInputData(data);
                setExecuteFetch(false);
              }
            }}
          />
          : null}
        <Select
          items={{ values: ['Cookie', 'Bearer', 'Direct', 'None'].map((opt: string) => ({ uuid: opt, name: opt })) }}
          onSelect={(value: any, option: any) => {
            setFetchType(value);
          }}
          value={[fetchType]}
        />
        <Input style={{ marginTop: '10px' }} placeholder='Enter url to fetch JSON data' onChange={(e: any) => {
          setUrl(e.target.value);
        }}></Input>
        <Input style={{ marginTop: '10px' }} placeholder='Enter auth token, if required' onChange={(e: any) => {
          setAuth(e.target.value);
        }}></Input><Space direction='horizontal'><Button onClick={() => {
          if (fetchType !== 'None') {
            if (url) {
              setExecuteFetch(true);
            }
            else {
              errorNotification('Missing url');
            }
            return;
          }
          const options = {
            method: 'GET',
            url,
            data: undefined,
          };
          const xhr = new XMLHttpRequest();
          xhr.open(options.method, url);
          if (auth !== '') {
            xhr.setRequestHeader('Authorization', 'auth');
          }
          // return result;
          xhr.onload = () => {
            console.log('fetchedExternal data');
            // const index = xhr.responseText.indexOf('<body');
            try {
              const jsonValue = JSON.parse(xhr.responseText);
              setJsonData(jsonValue);
              setError(null);
            }
            catch (e) {
              setError('Invalid Json found');
            }
            setInputData(xhr.responseText);
            setLastFetched(new Date().toLocaleString());
            setMinimizeInput(true);
          };
          xhr.onerror = (err: any) => {
            console.log('fetchedExternal data');
            // const index = xhr.responseText.indexOf('<body');
            setError('Unable to fetch data from the given url');
          };
          xhr.send(options.data);
        }}>
          Fetch
        </Button>
        <Button onClick={() => {
          setInputData(undefined);
          setLastFetched(new Date().toLocaleString());
        }}>Clear</Button>
        <Button onClick={() => {
          setMinimizeInput(!minimizeInput);
        }}>
          {minimizeInput ? 'Expand Input' : 'Collapse Input'}
        </Button>
        <Radio.Group onChange={(e: any) => setDataType(e.target.value)} value={dataType} defaultValue={DataInputType.Json}>
          <Radio value={DataInputType.Json}>json</Radio>
          <Radio value={DataInputType.Html}>html table</Radio>
          <Radio value={DataInputType.Csv}>csv</Radio>
        </Radio.Group>
        </Space>
        <TextArea key={`json-input-${lastFetched}-${minimizeInput}`} autoSize={minimizeInput ? { maxRows: 3 } : true} placeholder='Enter json data' defaultValue={inputData} value={inputData} onChange={(e: any) => {
          setInputData(e.target.value);
          if (!e.target.value) {
            setJsonData(JSON.parse("{}"));
            setError(null);
          }
          else {
            try {
              const jsonValue = dataType === DataInputType.Html ? HtmlTableToJson.parse(e.target.value).results : JSON.parse(escape ? e.target.value.replace(/\\"/g, '"') : e.target.value);
              setJsonData(jsonValue);
              setError(null);
            }
            catch (e) {
              setError('Invalid Json found');
            }
          }
        }}>
        </TextArea>
        <div key={`json-pretty-result`} style={{ border: 'solid 1px', marginTop: '10px' }}>
          {error ? <div><span style={{ color: 'red' }}>{error}</span><br />Previously parsed JSON object:</div> : null}
          <Checkbox onClick={() => setCollapseable(!collapseable)}>Collapseable</Checkbox>
          <Checkbox onClick={() => setEscape(!escape)}>Escape string</Checkbox>
          {collapseable ? <ReactJson src={jsonData}/> : <pre>{JSON.stringify(jsonData, null, 2)}</pre>}
        </div>
      </Content>
    }
    />
  );
};

export default PrettyJson;
