import React from 'react';
import styled from 'styled-components';
import { Modal as ModalAnt } from 'antd';
import { ModalProps as ModalPropsAnt } from 'antd/lib/modal/Modal';
import '../../App.css';
import '../../index.css';
import LoadingOverlay from '../atomic/LoadingOverlay';

const StyledModal = styled(ModalAnt)`
  .ant-modal-header {
    background: lightgray;
    border-radius: 4px 4px 0 0;
  }

  .ant-modal-footer {
    border-radius: 0 0 5px 5px;
  }

  .ant-modal-content {
    border-radius: 5px;
    border: 1px solid lightgray;
}
`;

type ModalProps = ModalPropsAnt & {
  modalContent?: React.ReactFragment;
  isLoading?: boolean;
  zIndex?: number;
  loadingMessage?: string;
};

type ModalState = {
};

export class Modal extends React.Component<ModalProps, ModalState> {

  constructor(props: ModalProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { loadingMessage, visible, isLoading, title, okText, cancelText, onCancel, onOk, okButtonProps, cancelButtonProps, modalContent } = this.props;
/*
    if (this.context.displaySettings.showFooter === visible) {
      this.context.setDisplaySettings({ showFooter: !visible, deviceTypeInfo: this.context.displaySettings.deviceTypeInfo });
    }
    */

    // modalRender={(node: React.ReactNode) => <div style={{ border: '1px solid green', borderRadius: '20px' }}>{node}</div>}
    return (
      <StyledModal
        visible={visible}
        title={title}
        okText={okText}
        cancelText={cancelText}
        onCancel={(e: any) => isLoading || !onCancel ? undefined : onCancel(e)}
        onOk={(e: any) => isLoading || !onOk ? undefined : onOk(e)}
        okButtonProps={okButtonProps ? okButtonProps : { disabled: isLoading }}
        cancelButtonProps={cancelButtonProps ? cancelButtonProps : { disabled: isLoading }}
      >
        <LoadingOverlay
          active={isLoading}
          spinner
          text={loadingMessage ? loadingMessage : `Updating ...`}
        >
          {this.props.children}
          {modalContent ? modalContent : null}
        </LoadingOverlay>
      </StyledModal>);
  }
}

export default Modal;
