import React, { useState } from 'react';
import FetchData from '../../../../containers/api/Stock/FetchData';
import { FetchDestination, PlaidAccount, PlaidLinkedInstitution, PlaidLinkedInstitutionInput } from '../../../../graphql/generated/graphql';
import RecordModal, { ModalFields, RecordModalAction } from '../../../modal/RecordModal';
import { errorNotification, infoNotification } from '../../../actions/Notification';
import { RecordType } from '../../../table/DataTypes';
import UpdateLinkedInstitutions from '../../../../containers/api/Plaid/UpdateLinkedInstitutions';
import { RobinhoodAccount, ROBINHOOD_URLS } from './ImportRobinhoodTypes';

type ImportRobinhoodAccountProps = {
  bearerToken: string;
  institution: PlaidLinkedInstitution;
  onUpdateRobinhoodAccount: (account: RobinhoodAccount, updateInstitution?: PlaidLinkedInstitution) => void;
  onClose: (portfolio?: any[]) => void;
};

const ImportRobinhoodAccount: React.FC<ImportRobinhoodAccountProps> = (props: ImportRobinhoodAccountProps) => {
  const { bearerToken, institution, onUpdateRobinhoodAccount, onClose } = props;
  const [robinhoodAccount, setRobinhoodAccount] = useState<RobinhoodAccount | undefined>(undefined);
  const [mapAccounts, setMapAccounts] = useState<string[]>([]);
  const [updateRobinhoodAccounts, setUpdateRobinhoodAccounts] = useState<PlaidLinkedInstitutionInput | undefined>(undefined);

  const fetchRobinhoodAccount =
    bearerToken && !robinhoodAccount ? <FetchData
      key={`fetch-robinhood-account`}
      command={ROBINHOOD_URLS.ACCOUNT()}
      token={bearerToken}
      destination={FetchDestination.Robinhood}
      onUpdate={(data?: string, loading?: boolean, error?: any) => {
        if (data) {
          const dataObj = JSON.parse(data);
          if (!(dataObj && dataObj.results && dataObj.results.length === 1)) {
            errorNotification('Failed to get Robinhood account details.');
            return;
          }
          const initRobinhoodAccount: RobinhoodAccount = dataObj.results[0] as RobinhoodAccount;
          if (!initRobinhoodAccount) {
            return;
          }
          setRobinhoodAccount(initRobinhoodAccount);
          const robinhoodAccountId = initRobinhoodAccount.account_number;
          const brokerAccount = institution.accounts.find((acc: PlaidAccount) => acc.brokerAccountId === robinhoodAccountId);
          if (!brokerAccount) {
            let alreadyMapped = true;
            setMapAccounts((prev: string[]) => {
              const existingMapAccount = prev.find((mapAccount: string) => mapAccount === robinhoodAccountId);
              if (existingMapAccount) {
                return prev;
              }
              const updateMapAccounts = [...prev];
              alreadyMapped = false;
              updateMapAccounts.push(robinhoodAccountId);
              return updateMapAccounts;
            });
            if (alreadyMapped) {
              onUpdateRobinhoodAccount(initRobinhoodAccount);
            }
          }
        }
      }}
    /> : null;

  const mapAccountDataValues: ModalFields = {
    pages: [
      {
        entries: Object.assign(
          {},
          ...(mapAccounts.map((mapAccount: string) => ({ [mapAccount] : {
            placeholder: 'Select existing account',
            type: RecordType.Dropdown,
            values: institution.accounts.map((account: PlaidAccount) => ({ label: account.name, value: account.accountId })),
          } })))),
      },
    ],
  };

  return <>
    {fetchRobinhoodAccount}
    {updateRobinhoodAccounts}
    {robinhoodAccount && mapAccounts.length > 0 ? <RecordModal
      key={'robinhood-map-accounts'}
      data={mapAccountDataValues}
      action={RecordModalAction.Custom}
      title={'Map Robinhood account to known account'}
      onCancel={() => {
        onClose();
      }}
      onSubmit={(data: any) => {
        infoNotification('Fetching data ...');
        const updateBroker = { ...institution };
        let allMapped = true;
        Object.keys(data.pages[0].entries).forEach((robinhoodAccountId: string) => {
          const accountIndex = updateBroker.accounts.findIndex((acc: PlaidAccount) => acc.accountId === data.pages[0].entries[robinhoodAccountId].value);
          if (accountIndex >= 0) {
            updateBroker.accounts[accountIndex].brokerAccountId = robinhoodAccountId;
          }
          else {
            allMapped = false;
          }
        });
        if (!allMapped) {
          errorNotification('Not all accounts are mapped');
          return;
        }
        const institutionInput: PlaidLinkedInstitutionInput = {
          linkedId: institution.linkedId,
          institutionId: institution.institutionId,
          institutionName: institution.institutionName,
          accounts: updateBroker.accounts.map((account: PlaidAccount) => ({
            uuid: account.uuid,
            accountId: account.accountId,
            brokerAccountId: account.brokerAccountId, // main item to update
            accountName: account.name ?? '',
            bankName: institution.institutionName,
          })),
          source: institution.source,
        };
        setUpdateRobinhoodAccounts(institutionInput);
      }}
    /> : null}
    {updateRobinhoodAccounts && robinhoodAccount
      ? <UpdateLinkedInstitutions key={updateRobinhoodAccounts.institutionId!} institution={updateRobinhoodAccounts} onUpdateLinkedInstitution={(data?: PlaidLinkedInstitution, loading?: boolean, error?) => {
        if (data) {
          onUpdateRobinhoodAccount(robinhoodAccount, data);
          // onUpdateLinkedInstitution(updateBroker);
          setMapAccounts([]);
          setUpdateRobinhoodAccounts(undefined);
          onClose();
        }
        if (error) {
          errorNotification('Failed to update account mapping.');
          setUpdateRobinhoodAccounts(undefined);
        }
      }} />
      : null}
  </>;
};

export default ImportRobinhoodAccount;
