import React, { useEffect, useState } from 'react';
import { FieldType } from '../../../components/table/DataTypes';
import { ApiProps } from '../Helpers';
import logger from '../../../utils/Logger';
import { User, useRegisterUserMutation, UserInput } from '../../../graphql/generated/graphql';

import { provisionClientContext } from '../Provision';
import { GetFriends } from './User';

export const userClientContext = { context: { clientName: 'user' } };
const logInfo = (message: any) => logger.info({ source: 'containers.api.RegisterUser', message });
const logInfoJson = (message: any) => logger.info({ source: 'containers.api.RegisterUser', message: JSON.stringify(message) });

export type RegisterUserProps = {
  userInput: UserInput;
  onUpdate?: (user?: any, loading?: boolean, error?: any) => any;
};

export const RegisterUser: React.FC<RegisterUserProps> = (props: RegisterUserProps) => {
  const { userInput, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [RegisterUserMutation, { data: registeredUser, loading, error }] = useRegisterUserMutation({
    ...provisionClientContext,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!executed) {
      console.log(`register new entry '${userInput.emailAddress}'`);
      setExecuted(true);
      RegisterUserMutation({ variables: { user: userInput } })
        .then((result: any) => {
          console.log(`done registering user ${userInput.emailAddress}`);
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error registering user ${userInput.emailAddress}`);
          console.log(err);
        });
    }
    if (onUpdate) {
      onUpdate(registeredUser ? registeredUser.registerUser as User : undefined, loading, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [registeredUser, loading, error]);

  return <></>;
};

export const registerUserResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [
      ...[
      'firstName',
      'lastName',
      'emailAddress',
      'status',
      'dateCreated',
      'dateConfirmed'].map((o: string) => ({ title: `${o}`, key: `${o}` })),
    ]},
  },
};

export const regiserUserDefinition = {
  registerUser: {
    registerUser: {
      function: (apiProps: ApiProps) => {
        logInfo('userApiDefinition.getFriends.function');
        logInfoJson(apiProps);
        return <React.Fragment><GetFriends {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
        },
      },
      response: registerUserResponse,
    },
  },
};
