import React from 'react';
import { Col } from 'antd';

/** Presentational */
import FullWidthWrapper from '../styled/FullWidthWrapper';
import { CreateRegisterUserDivWrapper } from '../styled/EmailConfirmFormWrapper';
import LoginContainer from '../../containers/auth/LoginContainer';

type CreateUserProps = {
  email: string;
};

const CreateUser: React.FC<CreateUserProps> = (props: CreateUserProps) => {
  const { email } = props;

  return (
    <FullWidthWrapper align='center'>
      <CreateRegisterUserDivWrapper>
        <Col md={24} lg={18}>
          <div className="full-width">
            <h2>Finish Account Creation</h2>
            <p>We have confirmed your email. Please login to finish your account creation.</p>
          </div>
          <LoginContainer emailConfirmed={email} />
        </Col>
      </CreateRegisterUserDivWrapper>
    </FullWidthWrapper>
  );
};

export default CreateUser;
