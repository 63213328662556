import { CameraOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import { errorNotification } from '../actions/Notification';
import Button from '../atomic/Button';
import ResponsiveModal from '../modal/ResponsiveModal';

type QRReaderComponentProps = {
  modal?: boolean;
  loadImage?: boolean;
  onUpdate: (data?: any, err?: any) => void;
  onCancel: () => void;
};

type QRReaderComponentState = {
  data?: any;
  error?: any;
  cameraSide: 'environment' | 'user';
  errorAlert: JSX.Element | null;
  current: any;
};

class QRReaderComponent extends Component <QRReaderComponentProps, QRReaderComponentState> {
  readerRef = React.createRef<QrReader>();

  constructor(props: QRReaderComponentProps) {
    super(props);
    this.state = {
      data: null,
      error: null,
      cameraSide: 'environment',
      errorAlert: null,
      current: null,
    };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
  }

  componentDidMount() {
    if (!this.state.data && this.props.loadImage) {
      this.openImageDialog();
    }
    if (!this.state.current && this.readerRef  && this.readerRef.current) {
      this.setState({ current: this.readerRef.current });
    }
  }

  componentWillUnmount() {
    if (!this.state.current && this.readerRef && this.readerRef.current) {
      console.log('unmount QR Reader component, cleanup first');
      this.readerRef.current.clearComponent();
    }
    else {
      console.log('unmount QR Reader component, QR Reader reference cleaned up');
    }
  }

  handleScan (scannedData: any) {
    console.log(`handle scan called with ${scannedData}`);

    /*if (scannedData && this.readerRef.current) {
      console.log('recieved data, but component ref still not cleaned up');
      this.setState({ data: scannedData, error: null });
      return; */
      /*
      console.log('stop camera');
      this.state.current.stopCamera();
      this.setState({ current: null }); */
    // }
    if (scannedData && scannedData !== this.state.data) { // (this.state.data && !this.readerRef.current)) {
      const updateData = scannedData ? scannedData : this.state.data;
      this.setState({ data: updateData, error: null });
      if (!this.props.modal && this.props.onUpdate) {
        this.props.onUpdate(updateData, null);
      }
    }
    if (this.props.loadImage && scannedData === null) {
      if (this.props.onUpdate) {
        this.props.onUpdate(null, `Unable to detect a QR code in the image, please try using a different image.`);
      }
    }
    console.log(`current: ${this.state.current}`);
  }

  handleError (err: any) {
    console.log(`handle error called with ${err}`);
    console.error(err);
    this.setState({ error: err });
    if (!this.props.modal && this.props.onUpdate) {
      this.props.onUpdate(null, err);
    }
    else {
      errorNotification();
    }
  }

  openImageDialog() {
    this.readerRef.current.openImageDialog();
  }

  render () {
    const data = this.state.data;
    const error = this.state.error;
    const cameraSide = this.state.cameraSide;
    const modal = this.props.modal;
    const initialCameraSide = cameraSide === 'user' ? 'user' : 'environment';
    console.log(`QR Reader show modal: ${modal} with camera side ${initialCameraSide}`);
    console.log(`useEffect data is ${data}`);
    const qrCode = (<div>
      {data && !this.state.current
        ? <div><div style={{ fontWeight: 'bold' }}>Result:</div><p style={{ wordWrap: 'break-word' }}>{data}</p><Button onClick={() => this.setState({ data: null })}>Rescan</Button></div>
        : null /*<>
          {this.state.errorAlert}
          <QrReader
            ref={this.readerRef}
            delay={200}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
            facingMode={initialCameraSide}
            legacyMode={this.props.loadImage}
          />
          <CameraOutlined style={{ zIndex: 3000, position: 'fixed', bottom: 20, right: 20, fontSize: '20px' }} onClick={() => {
            console.log(`switch camera from ${cameraSide} to ${cameraSide === 'environment' ? 'user' : 'environment'}`);
            this.setState({ cameraSide: cameraSide === 'environment' ? 'user' : 'environment' });
          }}/>
          <div style={{ zIndex: 3000, position: 'fixed', bottom: 20, marginLeft: 'auto', marginRight: 'auto' }}>component</div></> */}
                    <QrReader
            ref={this.readerRef}
            delay={200}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
            facingMode={initialCameraSide}
            legacyMode={this.props.loadImage}
          />
          <CameraOutlined style={{ zIndex: 3000, position: 'fixed', bottom: 20, right: 20, fontSize: '20px' }} onClick={() => {
            console.log(`switch camera from ${cameraSide} to ${cameraSide === 'environment' ? 'user' : 'environment'}`);
            this.setState({ cameraSide: cameraSide === 'environment' ? 'user' : 'environment' });
          }}/>
      </div>);

    return (modal
      ? <ResponsiveModal
          visible={true}
          onCancel={this.props.onCancel}
          onOk={(e: any) => this.props.onUpdate(data, error)}
          modalContent={qrCode}
        />
      : qrCode
    );
  }
}

export default QRReaderComponent;
