import styled from 'styled-components';
import { Layout } from 'antd';

export const Content = styled(Layout)`
  top: 60px;
  #background-color: #eeeeee;
`;

export const HiddenDiv = styled.div`
  visibility: hidden;
  height: 0px;
`;

// background-color: #00008b;
//   background-color: lightGray;
