import React, { useEffect } from 'react';
import { useGetDataObjectsQuery } from '../../graphql/generated/graphql';
import { FieldType } from '../../components/table/DataTypes';
import { ApiProps } from './Helpers';
import JsxViewResponse from './JsxViewResponse';
import { getResponseDataNode, getResponseDataObjs, dataClientContext } from './Api';

type DataApiProps = ApiProps & {
  includeInactive?: boolean;
};

export const GetDataObjects: React.FC<DataApiProps> = (props: DataApiProps) => {
  const { includeInactive, ...apiProps } = props;
  const { data: dataSchema } = useGetDataObjectsQuery({
    variables: { includeInactive: includeInactive === undefined ? false : includeInactive },
    ...dataClientContext,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!dataSchema || !dataSchema.getDataObjects) {
      return;
    }

    if (props.updateApiResponse) {
      props.updateApiResponse(dataSchema.getDataObjects);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataSchema]);
  /*
  useEffect(() => {
    console.log('useEffect inside GetDataObject');
    console.log(apiProps.updateApiResponse);
    console.log(dataSchema);
    if (apiProps.updateApiResponse && dataSchema) {
      apiProps.updateApiResponse(dataSchema.getDataObjects);
    }
  });
  */

  console.log('GetDataObjects dataSchema');
  console.log(props);
  console.log(apiProps);
  console.log(dataSchema);
  const result = (<JsxViewResponse data={dataSchema?.getDataObjects} {...apiProps} />);
  console.log('getDataObjects result');
  console.log(result);
  if (!apiProps.returnJsx) {
    return null;
  }

  return result;
};

export const dataObjectResponseFn = (keyPath?: string) => {
  return [
    ...getResponseDataObjs(
      [
        'uuid',
        'ownerUuid',
        'name',
        'description',
      ],
      keyPath),
    getResponseDataNode(
      'entries',
      (parentKey: string) => getResponseDataObjs(
        [
          'uuid',
          'objectUuid',
          'ordinal',
          'name',
          'type',
          'schema',
          'description',
          'dateCreated',
          'active',
        ],
        parentKey),
      keyPath),
    ...getResponseDataObjs(
      [
        'dateCreated',
        'active',
      ],
      keyPath),
  ];
};

export const dataObjectResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [...[
      'uuid',
      'ownerUuid',
      'name',
      'description'].map((o: string) => ({ title: o, key: o })),
      {
        title: 'entries',
        key: 'entries',
        children: [...[
        'uuid',
        'objectUuid',
        'ordinal',
        'name',
        'type',
        'schema',
        'description',
        'dateCreated',
        'active'].map((o: string) => ({ title: `${o}`, key: `entries-${o}` })),
        ],
      },
      ...[
      'dateCreated',
      'active'].map((o: string) => ({ title: o, key: o })),
    ]},
  },
};
