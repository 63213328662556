import React, { useEffect, useState }  from 'react';
import { DeleteDataCollectionMutation, useDeleteDataCollectionMutation } from '../../../graphql/generated/graphql';
import { dataClientContext } from '../Api';

type DeleteCollectionProps = {
  uuid: string;
  onUpdate?: (data?: DeleteDataCollectionMutation | null, loading?: boolean, error?: any) => any;
};

const DeleteCollection: React.FC<DeleteCollectionProps> = (props: DeleteCollectionProps) => {
  const { uuid, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [deleteDataCollectionMutation, { data: deletedCollection, loading: loadingDelete, error: errorDelete }] = useDeleteDataCollectionMutation({
    ...dataClientContext,
  });

  useEffect(() => {
    if (!executed) {
      console.log(`delete collection ${uuid}`);
      setExecuted(true);
      deleteDataCollectionMutation({ variables: { uuid } })
        .then((result: any) => {
          console.log(`done deleting collection ${uuid}`);
          console.log(result);
          /*
          if (!errorDelete) {
            setExecuted(true);
            if (props.onSuccess) {
              props.onSuccess();
            }
          }
          */
        })
        .catch((err: any) => {
          console.log(`error deleting collection ${uuid}`);
          console.log(err);
          // errorNotification();
        });
    }
    if (onUpdate) {
      onUpdate(deletedCollection, loadingDelete, errorDelete);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [deletedCollection, loadingDelete, errorDelete]);

  return <></>;
};

export default DeleteCollection;
