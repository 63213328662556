import { CameraOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { errorNotification } from '../actions/Notification';

type QRReaderProps = {
  modal?: boolean;
  loadImage?: boolean;
  onUpdate: (data?: any, err?: any) => void;
  onCancel: () => void;
};

const QRReader: React.FC<QRReaderProps> = (props: QRReaderProps) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [cameraSide, setCameraSide] = useState<'user' | 'environment'>('environment');

  const handleScan = (scannedData: any) => {
    console.log(`handle scan called with ${scannedData}`);
    if (scannedData && scannedData !== data) {
      setData(scannedData);
      setError(null);
      if (!props.modal && props.onUpdate) {
        props.onUpdate(scannedData, null);
      }
    }
  };

  const handleError = (err: any) => {
    console.log(`handle error called with ${err}`);
    console.error(err);
    setError(err);
    if (!props.modal && props.onUpdate) {
      props.onUpdate(null, err);
    }
    else {
      errorNotification();
    }
  };

  return <>
    {error ? errorNotification(error) : null}
    <QrReader
      delay={700}
      onError={handleError}
      onScan={handleScan}
      style={{ width: '100%', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}
      facingMode={cameraSide}
    />
    <CameraOutlined style={{ zIndex: 3000, position: 'fixed', bottom: 20, right: 20, fontSize: '20px' }} onClick={() => {
      console.log(`switch camera from ${cameraSide} to ${cameraSide === 'environment' ? 'user' : 'environment'}`);
      setCameraSide(cameraSide === 'environment' ? 'user' : 'environment');
    }}/>
  </>;
};

export default QRReader;

  /*(props.modal
    ? <ResponsiveModal
        visible={true}
        onCancel={props.onCancel}
        onOk={(e: any) => props.onUpdate(data, error)}
        modalContent={qrCode}
      />
    : qrCode
  ); */
