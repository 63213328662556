import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { errorNotification } from '../actions/Notification';

const ScrollableDiv = styled.div`
  height: 400;
  width: 400;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  background: transparent;
  border-style: solid;
  z-index: 100;
`;

const DisabledIFrame = styled.iframe.attrs(() => ({ target: '_blank' }))`
  width: 400;
  height: 400;
  # disabled: "disabled"
  # pointer-events: none;
  z-index: -2;
`;

type LoadPageProps = {
  url?: string;
  showUrlInput?: boolean;
};
const LoadPage: React.FC<LoadPageProps> = (props: LoadPageProps) => {
  const [url, setUrl] = useState<string | undefined>(props.url);
  const [ajaxData, setAjaxData] = useState<any>(null);

  useEffect(() => {
    if (props.url) {
      if (props.url !== url) {
        setUrl(props.url);
        fetchExternal(props.url);
      }
      else if (ajaxData === null) {
        fetchExternal(url);
      }
    }
    else if (ajaxData) {
      setAjaxData(null);
      setUrl(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

    /*
    if (ajaxData === null) {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      const targetUrl = 'https://google.com'

      fetch(proxyUrl + targetUrl)
        .then((res) => {
          return res.json();
        })
        .then(
          (result) => {
            console.log('fetch ajax');
            console.log(result);
            if (result.items) {
              setAjaxData(result.items);
            }
            if (!result.items) {
              setAjaxData('failed to load ajax');
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setAjaxError(error);
          },
        );
    }
  });
  */

  const fetchExternal = async (url: string) => {
    const corsApiUrl = 'https://cors-anywhere.herokuapp.com/';
    const options = {
      method: 'GET',
      url,
      data: undefined,
    };
    // function doCORSRequest(options, printResult) {
    const xhr = new XMLHttpRequest();
    const proxyUrl = corsApiUrl + options.url;
    xhr.open(options.method, proxyUrl);
    // xhr.setRequestHeader("Origin", corsApiUrl);
    // xhr.setRequestHeader("Referer", proxyUrl);
    console.log('fetchedExternal open');
    // return result;
    xhr.onload = xhr.onerror = () => {
      console.log('fetchedExternal data');
      console.log(xhr.responseText);
      // const index = xhr.responseText.indexOf('<body');
      let updateData = xhr.responseText ? xhr.responseText.replaceAll('<body', `<script>function clickBody(event, element, x){ console.log(this); event.stopPropagation(); alert('Click is disabled. Clicked on tag ' + element.tagName); }</script><body onclick="clickBody(event, this, 'body')"`) : '';
      updateData = updateData.replaceAll('<div', `<div style="pointer-events:none" onclick="clickBody(event, this, 'div')"`);
      setAjaxData(updateData || '');
    };
    xhr.send(options.data);
    /*
      if (/^POST/i.test(options.method)) {
        x.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      }
      x.send(options.data);
    }
    */
  };

  if (ajaxData && document.getElementById('iframe')) {
    console.log('insertData');
    console.log(ajaxData);
    console.log(document.getElementById('iframe'));
    // document.getElementById('iframe')?.insertAdjacentHTML('afterbegin', '<div>Hello world</div>');
    console.log(document.getElementById('iframe'));
    // document.getElementById('iframe').set = "<html><body>Hello world</body></html>";
    // .contentWindow.document.write("<html><body>Hello world</body></html> ");
  }

  return (
  <>
    {props.showUrlInput
      ? <>
        <input onChange={e => setUrl(e.target.value)} defaultValue={url ?? ''}></input>
        <button onClick={() => url ? fetchExternal(url) : errorNotification('url cannot be empty')}>FETCH</button>
        </>
      : null}
    <ScrollableDiv onClick={(e: any) => {
      alert("Im an alert");
      e.preventDefault();
      e.stopPropagation();
    }}>
      <DisabledIFrame target="_blank" title='ajax data' height={400} width={400} srcDoc={ajaxData ? ajaxData : '<html><body>loading...</body></html>'}></DisabledIFrame>
    </ScrollableDiv>
    </>
  );
};

export default LoadPage;
