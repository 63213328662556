import React, { useEffect, useState } from "react";
import { List } from "antd";
import { ListProps as ListPropsAnt } from "antd/lib/list";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { DataItem, EmptyMenu, ExtraMenuType, ViewProps } from "./Helpers";

type ListProps = ViewProps & ListPropsAnt<DataItem> & {
  key: string;
};

const ViewList: React.FC<ListProps> = (props: ListProps) => {
  const { data, mapping, sourceName, collapseable, extraMenu, ...listProps } = props;
  const [hideContent, setHideContent] = useState<{ [key: string]: boolean }>({});
  const [response, setResponse] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!data || data.length === 0 || props.loading) {
      if (response === null || !loading) {
        if (!loading) {
          setLoading(true);
        }
        setResponse(<List loading={true} />);
      }
      return;
    }
    if (loading) {
      setLoading(false);
    }

    updateDataView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [data, hideContent, loading]);

  useEffect(() => {
    if (!data) {
      return;
    }
    updateDataView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [hideContent]);

  const toggleShowContent = (itemKey: string) => {
    const newHideContent = hideContent;
    newHideContent[itemKey] = (hideContent[itemKey] === undefined) ? false : !hideContent[itemKey];
    setHideContent(newHideContent);
    updateDataView();
  };

  const updateDataView = () => {
    const updateList = <List
      {...listProps}
      itemLayout='horizontal'
      dataSource={data}
      renderItem={(item: DataItem, index) => {
        console.log('ViewCard item');
        console.log(item);

        const cardKey = `data-${index}`;
        /*
        const showIcon = <RightOutlined onClick={() => extraFn(cardKey)} />;
        const hideIcon = <DownOutlined onClick={() => extraFn(cardKey)} />;
        let extra = collapseable
          ? (hideContent[cardKey] || hideContent[cardKey] === undefined ? showIcon : hideIcon)
          : undefined;
        if (props.extraMenu) {
          extra = <>{extra}{props.extraMenu(item)}</>;
        }
        */
        if (!mapping.header || !mapping.header.mapFn || !mapping.header.mapping || !mapping.header.mapping[sourceName]) {
          console.error(`ViewCard mapping invalid for item`);
          return <></>;
        }
        const header = mapping.header.mapFn(item, mapping.header.mapping[sourceName]);
        const content = mapping.content.mapFn(item, mapping.content.mapping[sourceName]);
        const menu: ExtraMenuType | undefined = extraMenu ? extraMenu(item) : (collapseable ? EmptyMenu : undefined);
        if (collapseable && menu) {
          menu.items.push(content ? <CaretUpOutlined onClick={() => toggleShowContent(cardKey)}/> : <CaretDownOutlined onClick={() => toggleShowContent(cardKey)}/>);
        }
        return <List.Item
          actions={menu ? menu.items : undefined}
        >
          <List.Item.Meta
            title={header}
            description={content}
          />
        </List.Item>;
      }}
    />;

    setResponse(<>
      {updateList}
    </>);
  };

  return response;
};

export default ViewList;
