import React, { useEffect, useState, useRef }  from 'react';
import RecordModal, { ModalFields, RecordModalAction } from '../modal/RecordModal';
import { RecordType } from '../table/DataTypes';
import { ActionType, CollectionType, DataCollectionData, DataCollectionInput, DataEntry, DataObject, UpdateDataCollectionMutation, UpdateItemInput } from '../../graphql/generated/graphql';
import EntriesView from './EntriesView';
import UpdateCollection from '../../containers/api/Collection/UpdateCollection';
import { errorNotification, infoNotification } from '../actions/Notification';
import { Dictionary } from 'lodash';

type UpdateCollectionEntriesProps = {
  entries: DataEntry[];
  collection: DataCollectionData;
  onSuccess?: () => any;
};

const UpdateCollectionEntries: React.FC<UpdateCollectionEntriesProps> = (props: UpdateCollectionEntriesProps) => {
  const { entries, collection, onSuccess } = props;
  const [modalAction, setModalAction] = useState<RecordModalAction>(RecordModalAction.Custom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);
  const [selectedEntries, setSelectedEntries] = useState<string[]>([] as string[]);
  const [executeUpdate, setExecuteUpdate] = React.useState<JSX.Element | null>(null);
  const selectedEntriesRef = useRef<string[]>([] as string[]);
  selectedEntriesRef.current = selectedEntries;

  useEffect(() => {
    if (selectedEntries.length === 0 && collection.entryUuids.length > 0) {
      setSelectedEntries(collection.entryUuids.filter((entryUuid: string | null | undefined) => entryUuid) as string[]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          Entries: { type: RecordType.Render, value: <EntriesView collectionUuids={[collection.uuid]} addToCollection={true} onUpdate={(collection: DataCollectionData[], objects: Dictionary<DataObject>, entries: DataEntry[]) => {
            setSelectedEntries(entries.map((entry: DataEntry) => entry.dataUuid));
          }} /> },
        },
      },
    ],
  };

  const onUpdateCollection = async (updateSelectedEntries: string[]) => {
    /*
    const updateEntryCollectionUuids: string[] = record.pages[0].entries.Collections.value;
    */
    const currentSelectedEntries = selectedEntriesRef.current;
    const updateEntryCollections: UpdateItemInput[] = [];
    entries.forEach((originalEntry: DataEntry, index: number) => {
      if (currentSelectedEntries.find((selectedEntryUuid: string) => selectedEntryUuid === originalEntry.dataUuid)) {
        // do nothing, originally item was selected
      }
      else {
        updateEntryCollections.push({ itemType: CollectionType.Item, action: ActionType.Remove, id: originalEntry.dataUuid, ordinal: -1 });
      }
    });
    currentSelectedEntries.forEach((selectedEntryUuid: string, index: number) => {
      if (entries.find((originalEntry: DataEntry, index: number) => originalEntry.dataUuid === selectedEntryUuid)) {
        // do nothing, originally item was selected
      }
      else {
        updateEntryCollections.push({ itemType: CollectionType.Item, action: ActionType.Add, id: selectedEntryUuid, ordinal: collection.entryUuids.length + index });
      }
    });

    if (updateEntryCollections.length === 0) {
      infoNotification(`No changes were made to Collection's Entries`);
      if (onSuccess) {
        onSuccess();
      }
      setExecuteUpdate(null);
      return;
    }

    const updateCollection: DataCollectionInput = {
      uuid: collection.uuid,
      items: updateEntryCollections,
      ordinal: collection.ordinal,
    };

    // const updateQuery = <EditCollection  collectionInput={collectionInput} onUpdate={(data?: UpdateDataCollectionMutation, loading?: boolean, error?: any) => {
    const updateQuery = <UpdateCollection collectionInput={updateCollection} onUpdate={(data?: UpdateDataCollectionMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        errorNotification(`Error updating Collection's Entries, please try again.`);
        setExecuteUpdate(null);
        return;
      }

      if (data) {
        setError(null);
        setIsLoading(false);
        setModalAction(RecordModalAction.None);
        if (onSuccess) {
          onSuccess();
        }
        setExecuteUpdate(null);

      }
    }} />;
    setExecuteUpdate(updateQuery);
  };

  return <>
    {executeUpdate}
    {modalAction !== RecordModalAction.None && (
      <RecordModal
        key={'update-collection-entries-modal'}
        data={recordDataValues}
        action={modalAction}
        title={`Update entries in '${collection.name}'`}
        okText={'Update Collection'}
        onCancel={() => {
          setModalAction(RecordModalAction.None);
          if (onSuccess) {
            onSuccess();
          }
        }}
        onSubmit={onUpdateCollection}
        isLoading={isLoading}
        error={error ? error.message : undefined}
      />
    )}
  </>;
};

export default UpdateCollectionEntries;
