import React, { useEffect, useState }  from 'react';
import { errorNotification } from '../../../components/actions/Notification';
import { DataCollectionInput, UpdateDataCollectionMutation, useUpdateDataCollectionMutation } from '../../../graphql/generated/graphql';
import { dataClientContext } from '../Api';

type UpdateCollectionProps = {
  collectionInput: DataCollectionInput;
  onUpdate?: (result?: UpdateDataCollectionMutation | null, loading?: boolean, error?: any) => any;
};

const UpdateCollection: React.FC<UpdateCollectionProps> = (props: UpdateCollectionProps) => {
  const [executed, setExecuted] = useState<boolean>(false);
  const [updateDataCollectionMutation, { data: updatedCollection, loading: loadingUpdate, error: errorUpdate }] = useUpdateDataCollectionMutation({
    ...dataClientContext,
  });

  useEffect(() => {
    if (!executed) {
      setExecuted(true);
      console.log(`update collection ${props.collectionInput.uuid}`);
      updateDataCollectionMutation({ variables: { dataCollection: props.collectionInput } })
        .then((result: any) => {
          console.log(`done updating collection ${props.collectionInput.uuid}`);
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error updating collection ${props.collectionInput.uuid}`);
          console.log(err);
          errorNotification();
          setExecuted(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (props.onUpdate) {
      props.onUpdate(updatedCollection, loadingUpdate, errorUpdate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [updatedCollection, loadingUpdate, errorUpdate]);

  return <></>;
};

export default UpdateCollection;
