export const ROBINHOOD_URLS = {
  ACCOUNT: () => 'https://api.robinhood.com/accounts/',
  STOCK_QUOTES: (symbols: string[]) => `https://api.robinhood.com/quotes/?symbols=${symbols.join(',')}`,
  CRYPTO_QUOTES: (symbols: string[]) => `https://api.robinhood.com/marketdata/forex/quotes/?symbols=${symbols.join(',')}`,
  POSITIONS: (accountId: string, instrumentId: string) => `https://api.robinhood.com/positions/${accountId}/${instrumentId}/`,
  ORDERS: () => `https://api.robinhood.com/orders/`,
  SPLITS: (instrumentId: string) => `https://api.robinhood.com/instruments/${instrumentId}/splits/`,
  DIVIDENDS: (instrumentId: string) => `https://api.robinhood.com/dividends/?instrument_id=${instrumentId}`,
  EARNINGS_BY_SYMBOL: (symbol: string) => `https://api.robinhood.com/marketdata/earnings/?symbol=${symbol}`,
  EARNINGS_BY_ID: (id: string) => `https://api.robinhood.com/marketdata/earnings/?instrument=/instruments/${id}/`,
  NEWS: (symbol: string) => `https://api.robinhood.com/midlands/news/${symbol}/`,
  INSTRUMENT_BY_SYMBOL: (symbol: string) => `https://api.robinhood.com/instruments/?active_instruments_only=false&symbol=${symbol}`,
  INSTRUMENT_BY_ID: (instrumentId: string) => `https://api.robinhood.com/instruments/${instrumentId}/`,
  INSTRUMENT_BY_IDS: (instrumentIds: string[]) => `https://api.robinhood.com/instruments/?active_instruments_only=false&ids=${instrumentIds.join(',')}`,
  MARKET_HOURS: (date: string) => `https://api.robinhood.com/markets/XNAS/hours/${date}/`,
};

export type RobinhoodInstrument = {
  bloomberg_unique: string;
  country: string;
  day_trade_ratio: string;
  default_collar_fraction: string;
  fractional_tradability: string;
  fundamentals: string; // fundamentals url
  id: string
  ipo_date?: string;
  list_date: string;
  maintenance_ratio: string;
  margin_initial_ratio: string;
  market: string; // 'https://api.robinhood.com/markets/XNYS/'
  min_tick_size?: string;
  name: string; // company name
  quote: string; // 'https://api.robinhood.com/quotes/TSM/'
  rhs_tradability: string; // 'tradable'
  simple_name: string;
  splits: string; // 'https://api.robinhood.com/instruments/ca4821f9-06c3-4c22-bbb8-efe569f23d2b/splits/'
  state: string;
  symbol: string;
  tradability: string;
  tradable_chain_id: string; // 'fb248609-0fb3-4fc5-a3cf-86c7edb95b42'
  tradeable: boolean;
  type: string;
  url: string;
};

export type RobinhoodPosition = {
  average_buy_price: string;
  created_at: string;
  instrument: string;
  intraday_average_buy_price: string;
  intraday_quantity: string;
  ipo_allocated_quantity: string;
  pending_average_buy_price: string;
  quantity: string;
  shares_available_for_closing_short_position: string;
  shares_available_for_exercise: string;
  shares_held_for_buys: string;
  shares_held_for_options_collateral: string;
  shares_held_for_options_events: string;
  shares_held_for_sells: string;
  shares_held_for_stock_grants: string;
  shares_pending_from_options_events: string;
  updated_at: string;
  url: string;
};

export type RobinhoodOrder = {
  account: string;
  average_price?: string;
  created_at: Date;
  cumulative_quantity: string;
  dollar_based_amount?: string;
  executed_notional?: any;
  executions: {
    id: string;
    ipo_access_execution_rank?: string;
    price: string;
    quantity: string;
    settlement_date: string;
    timestamp: string;
  }[];
  extended_hours: boolean;
  fees: string;
  id: string; // unique order id ??
  instrument: string; // instrument url
  instrument_id: string; // instrument id
  position: string; // position url 'https://api.robinhood.com/positions/5SJ43488/1d7d38e7-cf2d-4efa-93b0-490a627788a6/'
  price: string;
  quantity: string;
  ref_id: string
  reject_reason?: string;
  response_category?: string;
  side: string;
  state: string;
  stop_price?: string;
  stop_triggered_at?: string;
  time_in_force: string;
  total_notional: {
    amount: string;
  };
  trigger: string;
  type: string;
  updated_at: string;
  url: string; // orders url (this)
};

export type RobinhoodOrders = {
  next?: string;
  previous?: string;
  results: RobinhoodOrder[];
};

export type RobinhoodAccount = {
  url: string;
  account_number: string;
  created_at: string;
  state: string;
  locked: boolean;
  permanently_deactivated: boolean;
  user_id: string;
};

export type RobinhoodEarnings = {
  symbol: string;
  instrument: string;
  year: number;
  quarter: number;
  eps: {
    estimate: number;
    actual: number;
  },
  report: {
    date: string;
    timing: string;
    verified: boolean;
  },
  call: {
    datetime: string;
    broadcast_url: string | null;
    replay_url: string;
  },
};

export type RobinhoodQuote = {
  ask_price: number;
  bid_price: number;
  mark_price: number;
  high_price: number;
  low_price: number;
  open_price: number;
  symbol: string;
  id: string;
  volume: number,
};
