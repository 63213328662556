import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout, Typography } from 'antd';
import Page from '../components/layout/Page';
import { CheckCircleOutlined } from '@ant-design/icons';
import AuthContext from '../context/AuthContext';
import Button from '../components/atomic/Button';

const { Title } = Typography;

// background: #fff;
const Content = styled(Layout)`
  min-height: 100%;
`;

class Home extends Component {
  static contextType = AuthContext;

  onClickException() {
  }

  render() {
    return (
      <Page content={
        <Content>
          <Title level={3} style={{ marginTop: 50, marginLeft: 'auto', marginRight: 'auto' }}>Track everything in one place</Title>
          <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
            <div><CheckCircleOutlined /> Create and manage an <span style={{ fontWeight: 'bold' }}>Entry</span>.</div>
            <div style={{ marginLeft: '20px' }}><CheckCircleOutlined /> An Entry can be a note, a task, or a data record.</div>
            <div style={{ marginLeft: '20px', display: 'none' }}><CheckCircleOutlined /> You decide how you want to store, view, and use your data in an Entry.</div>
            <div style={{ marginLeft: '20px' }}><CheckCircleOutlined /> Set due date to keep track of deadlines and mark Entries as done once they are complete.</div>
            <div><CheckCircleOutlined /> Create and manage a <span style={{ fontWeight: 'bold' }}>Collection</span> to group <span style={{ fontWeight: 'bold' }}>Entries</span>.</div>
            <div style={{ marginLeft: '20px' }}><CheckCircleOutlined /> You can create a Collection to track a to-do list, a project to track tasks, or create a routine.</div>
            <div style={{ marginLeft: '20px' }}><CheckCircleOutlined /> An Entry can belong to multiple Collections, allowing you to organize Entries the way you want it.</div>
            <div style={{ marginLeft: '20px' }}><CheckCircleOutlined /> Filter and sort Entries in a collection to highlight the data you want to see.</div>
            <br/>
          </div>
          {this.context.isAuthenticated
            ? <div style={{ marginLeft: 'auto', marginRight: 'auto' }}><Button style={{ margin: '3px', width: '150px' }}><a href="/collections">View Collections</a></Button><Button style={{ margin: '3px', width: '150px' }}><a href="/entries">View Entries</a></Button></div>
            : <div style={{ marginLeft: 'auto', marginRight: 'auto' }}><Button style={{ margin: '3px', width: '100px' }}><a href="/login">Login</a></Button><Button style={{ margin: '3px', width: '100px' }}><a href="/register">Register</a></Button></div>}
        </Content>}
      />
    );
  }
}

//  OR <Button><a href="/register">Register</a></Button>

export default Home;
