// https://fullcalendar.io/demos
// https://codesandbox.io/s/2z6wp2jozn?file=/src/DemoApp.jsx
// https://stackoverflow.com/questions/58567034/fullcalendar-js-how-to-restrict-date-select-to-one-day-on-month-view
// https://stackoverflow.com/questions/13571700/get-first-and-last-date-of-current-month-with-javascript-or-jquery

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, EventInput, DateSpanApi, EventChangeArg, DayCellContentArg, AllDayContentArg, DayHeaderContentArg, DatesSetArg } from '@fullcalendar/react';
import dayGridPlugin, { MoreLinkArg, MoreLinkContentArg } from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { Checkbox, Divider, InputNumber, Space } from 'antd';
import { BackwardFilled, CalendarOutlined, DownCircleTwoTone, FieldTimeOutlined, ForwardFilled, LeftCircleTwoTone, QuestionCircleTwoTone, RightCircleTwoTone, SettingTwoTone, UnorderedListOutlined, UpCircleTwoTone } from '@ant-design/icons';
import Popconfirm from '../atomic/Popconfirm';
import { CenteredDiv } from '../styled/CenteredDiv';
import DatePicker, { PickerType } from '../atomic/DatePicker';
import moment from 'moment';
import ChineseLunar from 'chinese-lunar';
import { IconType } from '../atomic/Tooltip';
import Button from '../atomic/Button';
import WizardTooltip from '../wizard/WizardTooltip';
import { TutorialType } from '../tutorial/Tutorial';
import { colors } from '../themes/Colors';
import styled from 'styled-components';

interface CalendarAppState {
  weekendsVisible: boolean;
  currentEvents: EventApi[];
}

type CalendarProps = {
  entryEvents: EventInput[];
  weekendsVisible: boolean;
  onCreateEvent: (dateDue?: Date, dateDone?: Date) => void;
  onUpdateEvent: (dataUuid: string, dateDue?: Date, dateDone?: Date) => void;
  onDeleteEvent: (dataUuid: string) => void;
  onUpdateSelected: (dates: { start: Date, end?: Date }[]) => void;
};

const DivHideable = styled.div`
  ${(props: ({ hide?: boolean })) => props.hide ? 'display: none;' : null}
`;

const SpanHideable = styled.span`
  ${(props: ({ hide?: boolean })) => props.hide ? 'display: none;' : null}
`;

enum ViewOption {
  list = 'List View',
  dayGrid = 'Day View',
  timeGrid = 'Time View',
  // basicDay = 'Basic Day',
  /* dayGridDay = 'Day Grid Day View',
  timeGridWeek = 'Weekly Grid View',
  timeGridDay = 'Day Grid View',
  timeGrid = 'Time Grid',
  basicDay = 'Basic Day',
  dayGridCustom = ' Custom Grid 21 days', */
}

enum DurationOption {
  day = 'Day',
  week = 'Weekly',
  month = 'Monthly',
  // year = 'Yearly',
  custom = 'Custom',
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

type CustomViewSetting = {
  type: string;
  duration: { months?: number; weeks?: number; days?: number };
  buttonText: string;
  dayCellContent?: () => void,
  defaults: { fixedWeekCount?: boolean | number };
};

type CustomView = { [viewId: string] : any }; // any === CustomViewSetting

/*
const getDurationOptions = (view: ViewOption): DurationOption[] => {
  switch (view) {
    case ViewOption.list:
      return [DurationOption.day, DurationOption.week, DurationOption.month, DurationOption.custom];
    case ViewOption.dayGrid:
      return [DurationOption.day, DurationOption.week, DurationOption.month, DurationOption.custom];
    case ViewOption.timeGrid:
      return [DurationOption.day, DurationOption.week, DurationOption.custom];
    // case ViewOption.basicDay:
    //   return [DurationOption.day, DurationOption.week, DurationOption.month, DurationOption.year, DurationOption.custom];
    default:
      return [];
  }
};
*/

const getPickerType = (calendarSetting: CalendarSetting) : string | PickerType | undefined => {
  if (calendarSetting.duration === DurationOption.month) {
    return PickerType.month;
  }
  if (calendarSetting.duration === DurationOption.week) {
    return PickerType.week;
  }
  if (calendarSetting.duration === DurationOption.day) {
    return PickerType.date;
  }
  if (calendarSetting.duration === DurationOption.custom) {
    return 'range';
  }
  return undefined;
};

const getWeekStartEndDates = (date: Date): { startDate: Date, endDate: Date } => {
  const givenDate = new Date(date);
  const day = givenDate.getDay();
  const diff = givenDate.getDate() - day;
  const firstDay = new Date(givenDate.setDate(diff));
  const lastDay = new Date(givenDate.setDate(givenDate.getDate() + 6));
  return { startDate: firstDay, endDate: lastDay };
};

const getMonthStartEndDates = (date: Date): { startDate: Date, endDate: Date } => {
  const startDate = new Date(new Date(date).setDate(1));
  const endDate = new Date(new Date(startDate).setMonth(startDate.getMonth() + 1, 0));
  return { startDate, endDate };
};

const getTitle = (firstDay: Date, lastDay: Date | undefined, view: DurationOption): string => {
  const definedLastDay = lastDay ? lastDay : (view === DurationOption.custom ? new Date(new Date(firstDay).setDate(new Date(firstDay).getDate() + (4 * 7))) : undefined); // default to 6 weeks for custom;
  const startYear = firstDay.getFullYear();
  const endYear = definedLastDay ? definedLastDay.getFullYear() : null;
  const startMonth = firstDay.getMonth();
  const endMonth = definedLastDay ? definedLastDay.getMonth() : null;
  const startDay = firstDay.getDate();
  const endDay = definedLastDay ? definedLastDay.getDate() : null;
  const sameYear = endYear ? startYear === endYear : true;
  const sameMonth = endMonth ? startMonth === endMonth : true;
  const startMonthName = sameYear && sameMonth ? monthNames[startMonth] : monthNames[startMonth].substr(0, 3);
  const endMonthName = endMonth ? monthNames[endMonth].substr(0, 3) : '';

  if (view === DurationOption.month) {
    return sameYear
      ? (sameMonth
        ? `${startMonthName} ${startYear}`
        : `${startMonthName}-${endMonthName}, ${startYear}`)
      : `${startMonthName} ${startYear}-${endMonthName} ${endYear}`;
  }
  if (view === DurationOption.day) {
    return `${startMonthName} ${startDay}, ${startYear}`;
  }

  if (view === DurationOption.week || view === DurationOption.custom) {
    return sameYear
      ? (sameMonth
        ? `${startMonthName} ${startDay}-${endDay}, ${startYear}`
        : `${startMonthName} ${startDay}-${endMonthName} ${endDay}, ${startYear}`)
      : `${startMonthName} ${startDay}, ${startYear}-${endMonthName} ${endDay}, ${endYear}`;
  }
  return '';
};

const getCalendarView = (view: ViewOption, duration: DurationOption, start?: Date, end?: Date) : { view: string; title: string; customViewSetting?: CustomView } => {
  const startDate = start ? start : new Date();
  const weekDates = duration === DurationOption.week && !end ? getWeekStartEndDates(startDate) : null;
  const result = { view: 'dayGridMonth', title: weekDates ? getTitle(weekDates.startDate, weekDates.endDate, duration) : getTitle(startDate, end, duration), customViewSetting: {} };
  if (view === ViewOption.dayGrid) {
    result.view =
      duration === DurationOption.day ? 'dayGridDay'
      : (duration === DurationOption.week ? 'dayGridWeek'
      : (duration === DurationOption.month ? 'dayGridMonth'
      : (duration === DurationOption.custom ? 'dayGridCustom'
      : '')));

  }
  if (view === ViewOption.timeGrid) {
    result.view =
      duration === DurationOption.day ? 'timeGridDay'
      : (duration === DurationOption.week ? 'timeGridWeek'
      : (duration === DurationOption.month ? 'timeGridMonth'
      : (duration === DurationOption.custom ? 'timeGridCustom'
      : '')));
  }
  if (view === ViewOption.list) {
    result.view =
      duration === DurationOption.day ? 'listDay'
      : (duration === DurationOption.week ? 'listWeek'
      : (duration === DurationOption.month ? 'listMonth'
      : (duration === DurationOption.custom ? 'listCustom'
      : '')));
  }
  if (duration === DurationOption.custom) {
    const customView: CustomView = {};
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const durationLength = start && end ? Math.floor((end.getTime() - start?.getTime()) / oneWeek) + (end.getDay() <= start.getDay() ? 2 : 1) : 5; // default to 5 weeks
    customView[result.view] = {
      type: view === ViewOption.dayGrid ? 'dayGrid'
        : (view === ViewOption.list ? 'list'
        : (view === ViewOption.timeGrid ? 'timeGrid' : '')),
      duration: { weeks: durationLength },
      // dayCellContent: renderDayContent,
      defaults: {
        fixedWeekCount: false,
      },
    };
    result.customViewSetting = customView;
  }

  return result;
};

type CalendarSetting = {
  view: ViewOption;
  duration: DurationOption;
  startDate: Date;
  endDate?: Date;
  limitEntriesNumber: boolean;
  filterEntries: boolean;
  maxEntries: number;
  showExpandCalendarIcons: boolean;
  showLunarCalendar: boolean;
};

type DateSelected = { date: Date,  action: 'create' | 'select' | 'unselect' | 'ask' | 'calendarView', endDate?: Date };

const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  const { weekendsVisible } = props;
  const [eventInput, setEventInput] = useState<EventInput[]>(props.entryEvents);
  const [events, setEvents] = useState<EventApi[] | null>(null);
  const [showInstruction, setShowInstructions] = useState<boolean>(false);
  const [showCalendarSettings, setShowCalendarSettings] = useState<boolean>(false);
  const [collapseCalendar, setCollapseCalendar] = useState<boolean>(false);
  const [moveEvent, setMoveEvent] = useState<EventChangeArg | null>(null);
  const [clickEventUuid, setClickEventUuid] = useState<string | null>(null);
  const [calendarSetting, setCalendarSetting] = useState<CalendarSetting>({
    view: ViewOption.dayGrid,
    duration: DurationOption.month,
    startDate: getMonthStartEndDates(new Date()).startDate,
    limitEntriesNumber: false,
    maxEntries: 3,
    filterEntries: true,
    showExpandCalendarIcons: false,
    showLunarCalendar: false });
  const [dateSelect, setDateSelect] = useState<DateSelected[]>([]);
  // const [dateType, setDateType] = useState<string>('month');
  const [editCalendarType, setEditCalendarType] = useState<boolean>(false);
  const calendarRef = React.createRef<FullCalendar>();
  const dateSelectRef = useRef<DateSelected[]>([] as DateSelected[]);
  dateSelectRef.current = dateSelect;

  useEffect(() => {
    if (props.entryEvents.length !== eventInput.length || eventInput.length !== 0) {
      setEventInput(props.entryEvents);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props.entryEvents]);

  useEffect(() => {
    if (!calendarSetting.filterEntries) {
      props.onUpdateSelected([]);
    }
    else {
      const filterDates: { start: Date, end?: Date }[] = dateSelect
        .filter((selected: DateSelected) => selected.action === 'select' || selected.action === 'calendarView')
        .map((selected: DateSelected) => ({ start: selected.date, end: selected.endDate }));
      if (filterDates.length > 0) {
        props.onUpdateSelected(filterDates);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [calendarSetting, dateSelect]);

  // const viewOptions: TagItem[] = Object.keys(ViewOption).map((view: string) => ({ uuid: view, name: ViewOption[view] }));

  const onChangeDate = (nextOrPrev: 'next' | 'nextnext' | 'prev' | 'prevprev') => {
    setCalendarSetting((prevCalendarSetting: CalendarSetting) => {
      const updateCalendarSetting = { ...prevCalendarSetting };
      if (nextOrPrev === 'next' || nextOrPrev === 'prev') {
        if (updateCalendarSetting.duration === DurationOption.month) {
          updateCalendarSetting.startDate.setMonth(updateCalendarSetting.startDate.getMonth() + (nextOrPrev === 'next' ? 1 : -1));
        }
        else if (updateCalendarSetting.duration === DurationOption.week) {
          updateCalendarSetting.startDate.setDate(updateCalendarSetting.startDate.getDate() + (nextOrPrev === 'next' ? 7 : -7));
        }
        else if (updateCalendarSetting.duration === DurationOption.day) {
          updateCalendarSetting.startDate.setDate(updateCalendarSetting.startDate.getDate() + (nextOrPrev === 'next' ? 1 : -1));
        }
        else {
          const dates: { startDate: Date, endDate?: Date } = ({ startDate: updateCalendarSetting.startDate, endDate: updateCalendarSetting.endDate }); // custom
          if (dates.startDate && dates.endDate) {
            updateCalendarSetting.startDate = new Date(dates.startDate.setDate(dates.startDate.getDate() + (nextOrPrev === 'next' ? 7 : -7)));
            updateCalendarSetting.endDate = new Date(dates.endDate.setDate(dates.endDate.getDate() + (nextOrPrev === 'next' ? 7 : -7)));
          }
        }
        /*
        if (updateCalendarSetting.duration === DurationOption.month) {
          updateCalendarSetting.startDate.setMonth(updateCalendarSetting.startDate.getMonth() + addOrSubtract * 1);
        }
        else if (updateCalendarSetting.duration === DurationOption.week) {
          updateCalendarSetting.startDate.setDate(updateCalendarSetting.startDate.getDate() + addOrSubtract * 7);
        }
        else if (updateCalendarSetting.duration === DurationOption.day) {
          updateCalendarSetting.startDate.setDate(updateCalendarSetting.startDate.getDate() + addOrSubtract * 1);
        }
        else if (updateCalendarSetting.duration === DurationOption.custom) {
          const calendarViewSetting = getCalendarView(
            calendarSetting.view ?? ViewOption.dayGrid,
            calendarSetting.duration ?? DurationOption.month,
            calendarSetting.startDate,
            calendarSetting.endDate);
          const adjustMonths = calendarViewSetting.customViewSetting ?
            (calendarViewSetting.customViewSetting[calendarViewSetting.view] as CustomViewSetting).duration.months : 0;
          const adjustWeeks = calendarViewSetting.customViewSetting ?
            (calendarViewSetting.customViewSetting[calendarViewSetting.view] as CustomViewSetting).duration.weeks : 0;
          const adjustDays = calendarViewSetting.customViewSetting ?
            (calendarViewSetting.customViewSetting[calendarViewSetting.view] as CustomViewSetting).duration.days : 0;
          if (adjustMonths !== 0 && adjustMonths !== undefined) {
            updateCalendarSetting.startDate.setMonth(updateCalendarSetting.startDate.getDate() + addOrSubtract * adjustMonths);
            if (updateCalendarSetting.endDate) {
              updateCalendarSetting.endDate.setMonth(updateCalendarSetting.startDate.getDate() + addOrSubtract * adjustMonths);
            }
          }
          else if (adjustWeeks !== 0 && adjustWeeks !== undefined) {
            updateCalendarSetting.startDate.setDate(updateCalendarSetting.startDate.getDate() + addOrSubtract * 7 * adjustWeeks);
            if (updateCalendarSetting.endDate) {
              updateCalendarSetting.endDate.setDate(updateCalendarSetting.startDate.getDate() + addOrSubtract * 7 * adjustWeeks);
            }
          }
          else if (adjustDays !== 0 && adjustDays !== undefined) {
            updateCalendarSetting.startDate.setDate(updateCalendarSetting.startDate.getDate() + addOrSubtract * adjustDays);
            if (updateCalendarSetting.endDate) {
              updateCalendarSetting.endDate.setDate(updateCalendarSetting.startDate.getDate() + addOrSubtract * adjustDays);
            }
          }
        }
        */
      }
      else if (nextOrPrev === 'nextnext' || nextOrPrev === 'prevprev') {
        const isFebFirstSunday = calendarSetting.startDate.getMonth() === 1 && (new Date(new Date(calendarSetting.startDate).setDate(1)).getDay() === 0);
        let currentNumberOfWeeks = calendarSetting.duration === DurationOption.month
          ? (isFebFirstSunday ? 4 : 5)
          : (calendarSetting.duration === DurationOption.week ? 1 : 0);
        if (calendarSetting.duration === DurationOption.custom) {
          const calendarViewSetting = getCalendarView(
            calendarSetting.view ?? ViewOption.dayGrid,
            calendarSetting.duration,
            calendarSetting.startDate,
            calendarSetting.endDate);
          currentNumberOfWeeks = calendarViewSetting.customViewSetting ?
            ((calendarViewSetting.customViewSetting[calendarViewSetting.view] as CustomViewSetting).duration.weeks ?? 1) : 0;
        }
        if (!(nextOrPrev === 'prevprev' && currentNumberOfWeeks < 2)) {
          let currentEndDate = updateCalendarSetting.endDate ? updateCalendarSetting.endDate : getWeekStartEndDates(updateCalendarSetting.startDate).endDate;
          if (calendarSetting.duration === DurationOption.month) {
            currentEndDate = getWeekStartEndDates(getMonthStartEndDates(updateCalendarSetting.startDate).endDate).endDate;
          }
          const updateEndDate =  new Date(currentEndDate.setDate(currentEndDate.getDate() + (nextOrPrev === 'nextnext' ? 7 : -7)));
          if (updateCalendarSetting.duration !== DurationOption.custom) {
            updateCalendarSetting.duration = DurationOption.custom;
          }
          updateCalendarSetting.startDate = getWeekStartEndDates(updateCalendarSetting.startDate).startDate;
          updateCalendarSetting.endDate = updateEndDate;
        }
      }
      return updateCalendarSetting;
    });
  };

  const onChangeSetting = (viewType?: ViewOption, duration?: DurationOption, start?: Date, end?: Date) => {
    setCalendarSetting((prevCalendarSetting: CalendarSetting) => {
      const updateCalendarSetting = { ...prevCalendarSetting };
      if (viewType) {
        updateCalendarSetting.view = viewType;
      }
      if (duration) {
        updateCalendarSetting.duration = duration;
        if (duration !== DurationOption.custom) {
          updateCalendarSetting.endDate = undefined;
        }
        if (duration === DurationOption.month) {
          updateCalendarSetting.startDate = getMonthStartEndDates(start ? start : updateCalendarSetting.startDate).startDate;
        }
        else if (duration === DurationOption.week) {
          updateCalendarSetting.startDate = getWeekStartEndDates(start ? start : updateCalendarSetting.startDate).startDate;
        }
      }
      if (start) {
        updateCalendarSetting.startDate = start;
        if (duration && duration === DurationOption.month) {
          updateCalendarSetting.startDate = getMonthStartEndDates(start ? start : updateCalendarSetting.startDate).startDate;
        }
        else if (duration && duration === DurationOption.week) {
          updateCalendarSetting.startDate = getWeekStartEndDates(start ? start : updateCalendarSetting.startDate).startDate;
        }
        if (updateCalendarSetting.endDate && start > updateCalendarSetting.endDate) {
          updateCalendarSetting.endDate = undefined;
        }
      }
      if (end && updateCalendarSetting.startDate && end > updateCalendarSetting.startDate) {
        updateCalendarSetting.endDate = end;
      }
      return updateCalendarSetting;
    });
  };

  const onCalendarClick = (dateSelected?: DateSelected) => {
    // ask popup does not exist, return.
    if (!dateSelected && !dateSelectRef.current.find((item: DateSelected) => item.action === 'ask')) {
      return;
    }
    if (dateSelected && dateSelected.action === 'ask' && clickEventUuid) {
      setClickEventUuid(null);
    }
    setDateSelect((prevDateSelect: DateSelected[]) => {
      const updateDateSelect = [...prevDateSelect].filter((item: DateSelected) => (item.action !== 'ask' && !(dateSelected && dateSelected.action === 'unselect' && item.action === 'select' && item.date.toUTCString() === dateSelected.date.toUTCString())));
      if (dateSelected && dateSelected.action === 'ask') {
        updateDateSelect.push(dateSelected);
      }
      if (dateSelected && dateSelected.action === 'select') {
        const exists = prevDateSelect.find((item: DateSelected) => item.date.toUTCString() === dateSelected.date.toUTCString() && item.action === 'select');
        if (!exists) {
          updateDateSelect.push(dateSelected);
        }
      }
      return updateDateSelect;
    });
  };

  const onCalendarEventClick = (eventSelected?: string) => {
    // remove any ask popups that exist.
    onCalendarClick(undefined);
    if (eventSelected && clickEventUuid !== eventSelected) {
      setClickEventUuid(eventSelected);
    }
    else if (!eventSelected && clickEventUuid) {
      setClickEventUuid(null);
    }
  };

  const renderSidebar = () => {
    return (
      <div key='calendar-details'>
        {showInstruction
          ? <div key='calendar-instructions'>
          <h2>Instructions</h2>
          <ul>
            <li>Click on date to Create a new Entry with given Due Date.</li>
            <li>Drag and Drop entry to change Due Date</li>
            <li>Click an Entry to edit it</li>
          </ul>
        </div> : null}
        {showCalendarSettings
          ? <div key='calendar-settings'>
          <h2>Calendar Settings</h2>
          <ul>
            <div>
              <Checkbox checked={calendarSetting.filterEntries} onChange={(value: any) => {
                setCalendarSetting((prevCalendarSetting: CalendarSetting) => {
                  const updateCalendarSetting = { ...prevCalendarSetting };
                  updateCalendarSetting.filterEntries = value.target.checked;
                  return updateCalendarSetting;
                });
              }}>Filter entries to selected dates</Checkbox>
            </div>
            <div>
              <Checkbox checked={calendarSetting.limitEntriesNumber} onChange={(value: any) => {
                setCalendarSetting((prevCalendarSetting: CalendarSetting) => {
                  const updateCalendarSetting = { ...prevCalendarSetting };
                  updateCalendarSetting.limitEntriesNumber = value.target.checked;
                  return updateCalendarSetting;
                });
              }}>Limit number of entries shown per day: </Checkbox>
              <InputNumber size='small' style={{ width: '50px', borderRadius: '5px' }} min={1} max={15} disabled={!calendarSetting.limitEntriesNumber} value={calendarSetting.maxEntries} onChange={(value: any) => {
                setCalendarSetting((prevCalendarSetting: CalendarSetting) => {
                  const updateCalendarSetting = { ...prevCalendarSetting };
                  updateCalendarSetting.maxEntries = value;
                  return updateCalendarSetting;
                });
              }} />
            </div>
            <DivHideable hide>
              <Checkbox checked={calendarSetting.showExpandCalendarIcons} onChange={(value: any) => {
                setCalendarSetting((prevCalendarSetting: CalendarSetting) => {
                  const updateCalendarSetting = { ...prevCalendarSetting };
                  updateCalendarSetting.showExpandCalendarIcons = value.target.checked;
                  return updateCalendarSetting;
                });
              }}>Enable <BackwardFilled style={{ background: colors.iconLightBlue, borderRadius: '50%', border: `1px solid ${colors.iconBlue}`, height: '14px', width: '17px', color: colors.iconBlue, paddingBottom: '14px', paddingRight: '14px', marginRight: '2px' }}/><ForwardFilled style={{ background: colors.iconLightBlue, borderRadius: '50%', border: `1px solid ${colors.iconBlue}`, height: '14px', width: '17px', color: colors.iconBlue, paddingBottom: '14px', paddingRight: '14px' }}/> to expand and shrink calendar</Checkbox>
            </DivHideable>
            <div>
              <Checkbox checked={calendarSetting.showLunarCalendar} onChange={(value: any) => {
                setCalendarSetting((prevCalendarSetting: CalendarSetting) => {
                  const updateCalendarSetting = { ...prevCalendarSetting };
                  updateCalendarSetting.showLunarCalendar = value.target.checked;
                  return updateCalendarSetting;
                });
              }}>Show lunar calendar dates</Checkbox>
            </div>
          </ul>
        </div> : null}
        {showCalendarSettings || showInstruction ? <Divider style={{ margin: '5px 0px 10px' }} /> : null}
      </div>
    );
  };

  /**
   * Render Date of the Day Cell
   * @param dayContent
   * @returns
   */
  const renderDayContent = (dayContent: DayCellContentArg) => {
    // infoNotification('render day content');
    const isFirst = dayContent.date.getDate() === 1;
    let lunarDate = '';
    let lunarBold: 'bold' | undefined = undefined;
    let leapMonth = false;
    if (dayContent.date.getDate() === 1 && calendarSetting.duration && calendarSetting.duration === DurationOption.custom) {
      dayContent.dayNumberText = `${monthNames[dayContent.date.getMonth()].substring(0, 3)}${dayContent.date.getDate()}`;
    }
    if (calendarSetting.showLunarCalendar) {
      lunarDate = ChineseLunar.solarToLunar(dayContent.date, 'md');
      if (lunarDate.includes('月初一') || lunarDate.includes('月十五')) {
        lunarBold = 'bold';
      }
      if (lunarDate.includes('闰')) {
        leapMonth = true;
      }
    }
    const todaysActions = dateSelect.filter((item: DateSelected) => (item.date.toUTCString() === dayContent.date.toUTCString()));
    const isVisible = todaysActions.find((item: DateSelected) => item.action === 'ask') !== undefined;
    return (
      <WizardTooltip
        currentPage={TutorialType.EntryCalendar}
        visible={isVisible}
        iconType={IconType.None}
        noButtons
        hideCloseIcon
        defaultVisible={false}
        placement="bottom"
        key={`event-calendarmark-due-done-${dayContent.date.toDateString()}`}
        onClose={() => {
          onCalendarClick();
        }}
        title={`${dayContent.date.toDateString()}`}
        message={<>
          <Space direction='vertical'>
            Select action:
            <Button size='small' style={{ width: '100px' }} onClick={() => {
              onCalendarClick();
              if (props.onCreateEvent) {
                // set to noon of current timezone.
                const adjustTime = new Date(dayContent.date.setHours(12));
                props.onCreateEvent(adjustTime, undefined);
              }
            }}>New Entry</Button>
            <Button size='small' style={{ width: '100px', display: 'none' }} onClick={() => {
              onCalendarClick({ date: dayContent.date, action: 'select' });
            }} >{dateSelect.find((item: DateSelected) => item.date.toUTCString() === dayContent.date.toUTCString() && item.action === 'select') ? 'Unselect Day' : 'Select Day'}</Button>
            <Button size='small' style={{ width: '100px', display: calendarSetting.duration === DurationOption.day ? 'none' : undefined }} onClick={() => {
              onChangeSetting(ViewOption.timeGrid, DurationOption.day, dayContent.date);
              onCalendarClick();
            }} >View Day</Button>
            <Button size='small' style={{ width: '100px', display: calendarSetting.duration === DurationOption.week ? 'none' : undefined }} onClick={() => {
              onChangeSetting(ViewOption.dayGrid, DurationOption.week, dayContent.date);
              onCalendarClick();
            }} >View Week</Button>
            <Button size='small' style={{ width: '100px', display: calendarSetting.duration === DurationOption.month ? 'none' : undefined }} onClick={() => {
              onChangeSetting(ViewOption.dayGrid, DurationOption.month, dayContent.date);
              onCalendarClick();
            }} >View Month</Button>
            <Button size='small' style={{ width: '100px' }} onClick={() => {
              onCalendarClick();
            }} >Close</Button>
          </Space>
        </>}
      >
        <div style={{ fontWeight: isFirst ? 'bold' : undefined, background: todaysActions.find((item: DateSelected) => item.action === 'select') ? 'yellow' : undefined }} onClick={() => {
          onCalendarClick({ date: dayContent.date, action: 'ask' });
        }}>{dayContent.dayNumberText}{lunarDate !== '' ? <div style={{ paddingRight: '4px', float: 'left', fontWeight: lunarBold, color: leapMonth ? 'green' : undefined }}>({lunarDate})</div> : null}</div>
      </WizardTooltip>);
  };

  /**
   * Render the more link
   * @param moreContent
   * @returns
   */
  const renderMoreContent = (moreContent: MoreLinkContentArg) => {
    // return <div onClick={() => setClickMore(moreContent.view.calendar.getCurrentData().currentDate.toDateString())}>Foo {moreContent.text}</div>;
    return (
      <div>{moreContent.text}</div>
    );
  };

  /*
  const renderOnClickMoreLink = (arg: any) => {
    infoNotification('onClickMoreLink render');
  };
  */

  /**
   * Execute on click more link
   * https://fullcalendar.io/docs/moreLinkClick
   *
   * If want to modify popover content, see http://jsfiddle.net/slyvain/6vmjt9rb/
   *
   * @param moreLinkClick
   * @returns 'popover', 'week', 'day', view name, or callback function.
   */
  const renderMoreLinkClick = (moreLinkClick: MoreLinkArg) => {
    // setClickMore(moreLinkClick.date.toDateString());
    return 'popover';
  };

  /**
   * Displayed during list view
   * @param allDayContent
   * @returns
   */
  const renderAllDayContent = (allDayContent: AllDayContentArg) => {
    return <div>{allDayContent.text}</div>;
  };

  const onDateClick = (arg: DateClickArg) => {
    onCalendarClick({ date: arg.date, action: 'ask' });
  };

  const renderDayHeaderContent = (dayHeaderContent: DayHeaderContentArg) => {
    // return dayHeaderContent;
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    // eventContent.backgroundColor =  eventContent.event.start && clickMore === eventContent.event.start.toDateString() ?  'blue' : 'red';
    const isMoveEvent: boolean = (moveEvent !== null && moveEvent.event.id === eventContent.event.id) || (clickEventUuid !== null && eventContent.event.id === clickEventUuid);

    const isSelected = clickEventUuid  && eventContent.event.id === clickEventUuid;
    return (isSelected
      ? <WizardTooltip
          currentPage={TutorialType.EntryCalendar}
          visible={isSelected}
          iconType={IconType.None}
          noButtons
          hideCloseIcon
          defaultVisible={false}
          placement="bottom"
          key={`event-calendarmark-due-done-${eventContent.event.id}`}
          onClose={() => {
            onCalendarEventClick();
          }}
          title={`${eventContent.event.title}`}
          message={<>
            <Space direction='vertical'>
              Select action:
              <Button size='small' style={{ width: '120px' }} onClick={() => {
                onCalendarEventClick();
                props.onUpdateEvent(eventContent.event.id);
              }}>View Entry</Button>
              <Button size='small' style={{ width: '120px' }} onClick={() => {
                onCalendarEventClick();
                props.onUpdateEvent(eventContent.event.id, undefined, new Date());
              }}>Mark Completed</Button>
              <Button size='small' style={{ width: '120px' }} onClick={() => {
                onCalendarEventClick();
                props.onUpdateEvent(eventContent.event.id);
              }}>Edit Entry</Button>
              <Button size='small' style={{ width: '120px' }} onClick={() => {
                onCalendarEventClick();
                props.onDeleteEvent(eventContent.event.id);
              }} >Delete Entry</Button>
              <Button size='small' style={{ width: '120px' }} onClick={() => {
                onCalendarEventClick();
              }} >Close</Button>
            </Space>
          </>}
        >
          <b>{eventContent.timeText}</b>
          <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={() => {
            onCalendarEventClick(eventContent.event.id);
          }}><i>{eventContent.event.title}</i></div>
          {/* clickEventUuid.length > 0 && eventContent.event.id === clickEventUuid[0] ? <ViewEntryInModal entryUuids={[clickEventUuid[0]]} onSuccess={() => { setClickEventUuid(['baz']  }} /> : null */}
        </WizardTooltip>

      : <Popconfirm
        visible={isMoveEvent}
        placement={'rightTop'}
        key={`event-calendarmark-due-done-${eventContent.event.id}`}
        header={`Update Entry: ${eventContent.event.title}`}
        title={<>
          <i>{moveEvent ? `Please confirm changing due date from ${moveEvent.oldEvent.startStr} to  ${moveEvent.event.startStr}:` : ''} </i>
        </>}
        onConfirm={() => {
          if (moveEvent) {
            props.onUpdateEvent(moveEvent.event.id, new Date(moveEvent.event.startStr), undefined);
          }
          setMoveEvent(null);
        }}
        onCancel={() => {
          if (moveEvent) {
            handleCancelMoveEvent(moveEvent);
          }
          setMoveEvent(null);
        }}
        okText='Update dates'
        cancelText='Cancel'
      >
        <b>{eventContent.timeText}</b>
        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><i>{eventContent.event.title}</i></div>
        {clickEventUuid ? null /* <ViewEntryInModal entryUuids={[clickEventUuid} onSuccess={() => setClickEventUuid('test')} /> */ : null}
      </Popconfirm>
    );
  };

  /*
  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  const getFilteredEvents = () => {
    if (!calendarSetting.filterEntries) {
      return eventInput;
    }
    const getFilteredEvents = eventInput.filter((event: EventInput) => {
      const selectedDates = dateSelect.find((date: DateSelected) => date.action === 'select');
      if (!selectedDates) {
        return true;
      }
      return dateSelect.find((date: DateSelected) => event.date && date.action === 'select' && date.date.toUTCString() === (event.start as Date).toUTCString()) ? true : false;
    });
    return getFilteredEvents;
  };
  */

  const handleDateSelect = (selectInfo: DateSelectArg) => {

    /*
    if (props.onCreateEvent) {
      props.onCreateEvent(new Date(selectInfo.startStr), undefined);
    }
    */
    /*
    const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    } */
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    onCalendarEventClick(clickInfo.event.id);

    // if (props.onUpdateEvent) {
    //  props.onUpdateEvent(clickInfo.event.id);
    // }
    /*
    infoNotification(`Are you sure you want to delete the event '${clickInfo.event.title}'`);
    // TODO add popconfirm
    clickInfo.event.remove();
    */
  };

  const handleEvents = (updateEvents: EventApi[]) => {
    // initial events object. Otherwise, use other methods to control update to the calendar.
    setEvents(updateEvents);
  };

  const handleCancelMoveEvent = (arg: EventChangeArg) => {
    if (events === null) {
      console.error(`failed to initial calendar events, unable to move item ${arg.event.id}`);
      return;
    }
    const oldEventInput: EventInput = eventInput.find((event: EventInput) => event.id === arg.oldEvent.id) ?? {
      id: arg.oldEvent.id,
      title: arg.oldEvent.title,
      start: new Date(arg.oldEvent.startStr).toISOString().replace(/T.*$/, ''),
      backgroundColor: arg.oldEvent.backgroundColor,
    };
    const updateEventInput = [...eventInput].filter((event: EventInput) => event.id !== arg.event.id);
    updateEventInput.push(oldEventInput);
    setEventInput(updateEventInput);
  };

  const handleUpdateEvent = (arg: EventChangeArg) => {
    setMoveEvent({ ...arg });
    onCalendarEventClick();

    // props.onUpdateEvent(arg.event.id, new Date(arg.event.startStr), undefined);
    // setEvents(updateEvents);
  };

  const handleDatesSet = (arg: DatesSetArg) => {
    if (dateSelect.find((date: DateSelected) => date.action === 'calendarView' && date.date.toISOString() === arg.start.toISOString() && date.endDate && date.endDate.toISOString() === arg.end.toISOString())) {
      return;
    }
    setDateSelect((prevDateSelect) => {
      const updateDateSelect = [...prevDateSelect].filter((dateSelected: DateSelected) => dateSelected.action !== 'calendarView');
      updateDateSelect.push({ date: arg.start, endDate: arg.end, action: 'calendarView' });
      return updateDateSelect;
    });
  };

  const calendarDisplaySettings = getCalendarView(
    calendarSetting.view ?? ViewOption.dayGrid,
    calendarSetting.duration ?? DurationOption.month,
    calendarSetting.startDate,
    calendarSetting.endDate);

  return (
    <div key='calendar' style={{ border: '1px solid gray', borderRadius: '5px', padding: '10px', paddingTop: '6px', marginBottom: '10px', background: 'lightgray' }}>
      {renderSidebar()}
      <div key='calendar-main'>
        <CenteredDiv style={{ paddingBottom: collapseCalendar ? undefined : '5px' }}>
          <Space direction='horizontal' style={{ float: 'left', width: '130px' }} size={4}>
            <BackwardFilled onClick={() => onChangeDate('prevprev')} style={{ display: calendarSetting.showExpandCalendarIcons ? undefined : 'none', background: colors.iconLightBlue, borderRadius: '50%', border: `1px solid ${colors.iconBlue}`, height: '14px', width: '17px', color: colors.iconBlue, paddingBottom: '14px', paddingRight: '14px' }}/>
            <LeftCircleTwoTone onClick={() => onChangeDate('prev')} />
            <RightCircleTwoTone onClick={() => onChangeDate('next')} />
            <ForwardFilled onClick={() => onChangeDate('nextnext')} style={{ display: calendarSetting.showExpandCalendarIcons ? undefined : 'none', background: 'rgb(230, 247, 255)', borderRadius: '50%', border: '1px solid rgb(24, 144, 255)', height: '14px', width: '17px', color: 'rgb(24, 144, 255)', paddingBottom: '14px', paddingLeft: '1px' }}/>
            <CalendarOutlined
              onClick={() => onChangeSetting(ViewOption.dayGrid)}
              style={{ background: 'rgb(230, 247, 255)', borderRadius: '5px', border: calendarSetting.view === ViewOption.dayGrid ? '1px solid blue' : '1px solid rgb(24, 144, 255)', height: '14px', width: '20px', color: calendarSetting.view === ViewOption.dayGrid ? 'blue' : 'rgb(24, 144, 255)', marginLeft: '10px', paddingBottom: '14px' }}/>
            <Popconfirm
              disabled={![DurationOption.month /*, DurationOption.year */].find((item: DurationOption) => item === calendarSetting.duration)}
              placement={'rightTop'}
              key={`calendar-time-view-confirm`}
              header={`Time View is not available for monthly view`}
              title={<>
                <div>Do you want to change calendar view to weekly view?</div>
              </>}
              onConfirm={() => {
                onChangeSetting(ViewOption.timeGrid, DurationOption.week);
              }}
              onCancel={() => {
              }}
              okText='Weekly View'
              cancelText='Cancel'
            >
              <FieldTimeOutlined
                onClick={() => calendarSetting.duration !== DurationOption.month ? onChangeSetting(ViewOption.timeGrid) : null }
                style={{ background: 'rgb(230, 247, 255)', borderRadius: '5px', border: calendarSetting.view === ViewOption.timeGrid ? '1px solid blue' : '1px solid rgb(24, 144, 255)', height: '14px', width: '20px', color: calendarSetting.view === ViewOption.timeGrid ? 'blue' : 'rgb(24, 144, 255)', paddingBottom: '14px' }}/>
            </Popconfirm>
            <UnorderedListOutlined
              onClick={() => onChangeSetting(ViewOption.list)}
              style={{ background: 'rgb(230, 247, 255)', borderRadius: '5px', border: calendarSetting.view === ViewOption.list ? '1px solid blue' : '1px solid rgb(24, 144, 255)', height: '14px', width: '20px', color: calendarSetting.view === ViewOption.list ? 'blue' :'rgb(24, 144, 255)', paddingBottom: '14px' }}/>
          </Space>
          <Space direction='horizontal' style={{ float: 'right', paddingLeft: '90px' }} size={4}>
            {collapseCalendar ? <UpCircleTwoTone onClick={() => setCollapseCalendar(!collapseCalendar)} twoToneColor={'blue'} /> : <DownCircleTwoTone onClick={() => setCollapseCalendar(!collapseCalendar)} />}
            <SettingTwoTone onClick={() => setShowCalendarSettings(!showCalendarSettings)} twoToneColor={showCalendarSettings ? 'blue' : undefined}/>
            <QuestionCircleTwoTone onClick={() => setShowInstructions(!showInstruction)} twoToneColor={showInstruction ? 'blue' : undefined}/>
          </Space>
          <CenteredDiv>
            {editCalendarType
              ? <>
                <DatePicker
                  key={`${calendarSetting.startDate.toUTCString()}`}
                  allowClear={false}
                  defaultOpen={editCalendarType}
                  autoFocus={true}
                  initialValue={calendarSetting.duration === DurationOption.custom ? { start: moment(calendarSetting.startDate), end: calendarSetting.endDate ? moment(calendarSetting.endDate) : moment(calendarSetting.startDate) } : moment(calendarSetting.startDate)}
                  noIcon
                  bold
                  panelRender={(panelNode: ReactNode) => {
                    return (
                      <>
                        <CenteredDiv style={{ paddingTop: '4px' }}>
                          <span style={{ paddingLeft: '2px', paddingRight: '2px', color: 'blue', fontWeight: calendarSetting.duration === DurationOption.day ? 'bold' : undefined }} onClick={() => onChangeSetting(undefined, DurationOption.day)}>day</span>
                          <span style={{ paddingLeft: '2px', paddingRight: '2px', color: 'blue', fontWeight: calendarSetting.duration === DurationOption.week ? 'bold' : undefined }} onClick={() => onChangeSetting(undefined, DurationOption.week)}>week</span>
                          <Popconfirm
                            disabled={![ViewOption.timeGrid /*, DurationOption.year */].find((item: ViewOption) => item === calendarSetting.view)}
                            placement={'bottom'}
                            key={`calendar-time-view-confirm`}
                            header={`Time View is not available for monthly view`}
                            title={<>
                              <div>Do you want to change calendar view to day grid view?</div>
                            </>}
                            onConfirm={() => {
                              onChangeSetting(ViewOption.dayGrid, DurationOption.month);
                            }}
                            onCancel={() => {
                            }}
                            okText='Day Grid View'
                            cancelText='Cancel'
                          >
                          <span style={{ paddingLeft: '2px', paddingRight: '2px', color: 'blue', fontWeight: calendarSetting.duration === DurationOption.month ? 'bold' : undefined }} onClick={() => {
                            return calendarSetting.view !== ViewOption.timeGrid ? onChangeSetting(undefined, DurationOption.month) : null;
                          }}>month</span>
                          </Popconfirm>
                          <SpanHideable hide style={{ paddingLeft: '2px', paddingRight: '2px', color: 'blue', fontWeight: calendarSetting.duration === DurationOption.custom ? 'bold' : undefined }} onClick={() => onChangeSetting(undefined, DurationOption.custom)}>custom</SpanHideable>
                        </CenteredDiv>
                        {panelNode}
                      </>);
                  }}
                  pickerType={getPickerType(calendarSetting)}
                  onChange={(value: moment.Moment | null) => {
                    if (value) {
                      onChangeSetting(undefined, undefined, value.toDate());
                      setEditCalendarType(false);
                    }
                  }}
                  onRangeChange={(start: moment.Moment | null, end: moment.Moment | null, info: any) => {
                    if (start && end) {
                      onChangeSetting(undefined, undefined, start.toDate(), end ? end.toDate() : undefined);
                      setEditCalendarType(false);
                    }
                  }}
                  onBlur={(e: any) => {
                    console.log(e);
                    setEditCalendarType(false);
                  }}
                  onOpenChange={(open: boolean) => {
                    console.log(open);
                    // setEditCalendarType(open ? open : false);
                  }}
                  clearIcon={undefined}
                  onOk={(start: moment.Moment, end?: moment.Moment) => {
                    console.log(start);
                  }}
                  format={calendarSetting.duration === DurationOption.month ? (value: any) => value ? `${monthNames[value.month()].toLocaleUpperCase()} ${value.year()}` : '' : undefined}
                />
              </>
              : <div style={{ fontWeight: 'bold', color: 'blue' }} onClick={() => setEditCalendarType(!editCalendarType)}>{calendarDisplaySettings.title}</div>}
          </CenteredDiv>
        </CenteredDiv>
        <div style={{ background: '#f0f2f5', display: collapseCalendar ? 'none' : undefined }}>
          <FullCalendar
            displayEventTime={true}
            ref={calendarRef}
            fixedWeekCount={false}
            height={'auto' /* calendarSetting.view && ViewOption[calendarSetting.view] === ViewOption.dayGridCustom ? 'auto' : undefined */}
            key={`show-calendar-${calendarSetting.view}-${calendarSetting.duration}-${calendarSetting.startDate.toUTCString()}-`}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            headerToolbar={false /*{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,dayGridCustom',
            } */}
            selectAllow={(e: DateSpanApi) => {
              if (e.end.getTime() / 1000 - e.start.getTime() / 1000 <= 86400) {
                return true;
              }
              return false;
            }}
            initialView={calendarDisplaySettings.view}
            initialDate={calendarSetting.startDate}
            now={new Date()}
            eventStartEditable={moveEvent === null && clickEventUuid === null && dateSelect.find((item: DateSelected) => item.action === 'ask') === undefined}
            views={{
              ...(calendarDisplaySettings.customViewSetting ? calendarDisplaySettings.customViewSetting : {}),
              dayGridCustom2: {
                type: 'dayGrid',
                duration: { months: 3 },
                buttonText: '3 months',
                dayCellContent: renderDayContent,
                defaults: {
                  fixedWeekCount: false,
                },
              },
            }}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={calendarSetting.limitEntriesNumber ? calendarSetting.maxEntries : false}
            weekends={weekendsVisible}
            events={eventInput}
            // initialEvents={entryEvents} // alternatively, use the `events` setting to fetch from a feed
            dateClick={onDateClick}
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            dayHeaderContent={renderDayHeaderContent}
            dayCellContent={renderDayContent}
            moreLinkContent={renderMoreContent}
            moreLinkClick={renderMoreLinkClick}
            // moreLinkText={(num: number) => `huh? ${num}`} same as moreLinkContent and moreLinkClick ???
            allDayContent={renderAllDayContent}
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            datesSet={handleDatesSet}
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            */
            eventChange={handleUpdateEvent}
            /*
            eventRemove={function(){}}
            */
          />
        </div>
      </div>
    </div>
  );
};

/*
function renderSidebarEvent(event: EventApi) {
  return (
    <li key={event.id}>
      <b>{formatDate(event.start!, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
      <i>{event.title}</i>
    </li>
  );
}
*/

export default Calendar;
