import gql from 'graphql-tag';

export default gql`
  fragment DataObjectEntry on DataObjectEntry {
    uuid
    objectUuid
    ordinal
    name
    type
    schema
    description
    dateCreated
    active
  }
`;
