import { CheckCircleTwoTone } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

const CircleCheckboxStyled = styled.svg`
  margin-top: 4px;
  margin-right: 16px;
  position: absolute;
  &:hover {
    opacity: 0%;
  }
  # background-image: url(${window.btoa("data:image/svg+xml;utf8,<svg height='16' width='16'><circle cx='8' cy='8' r='7' stroke='black' strokeWidth='2' fill='red' /></svg>")});
  # &:hover {
  #   background-image: url(${window.btoa("data:image/svg+xml;utf8,<svg height='16' width='16'><circle cx='8' cy='8' r='7' stroke='black' strokeWidth='2' fill='red' /></svg>")}); # url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16'><circle cx="8" cy="8" r="7" stroke="black" strokeWidth="1" fill="red" /></svg>");
  # }
  # }
`;

const CircleCheckboxOnHoverStyled = styled(CheckCircleTwoTone)`
  margin-top: 5px;
  margin-left: 1px;
  position: absolute;
`;

type CircleCheckboxProps = {
  isChecked?: boolean;
  onClick?: () => void;
};

const CircleCheckbox: React.FC<CircleCheckboxProps> = (props: CircleCheckboxProps) => {
  return (
    <>
      <CircleCheckboxOnHoverStyled onClick={props.onClick} twoToneColor={props.isChecked ? 'blue' : undefined } />
      {props.isChecked ? null : <CircleCheckboxStyled onClick={props.onClick} height="16" width="16">
        <circle cx='8' cy='8' r='7' stroke='lightblue' strokeWidth='1' fill='#e6f7ff' />
      </CircleCheckboxStyled>}
    </>
  );
};

export default CircleCheckbox;
