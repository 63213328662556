import React, { useState, useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps, useHistory, Link } from 'react-router-dom';
import { Form, Input, Checkbox, Row, notification, Spin } from 'antd';
import { LoadingOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import '../../index.css';
import { colors } from '../themes/Colors';
import AuthContext from '../../context/AuthContext';
import { AuthStatus } from './AuthStatusEnum';
import { UserInput } from '../../graphql/generated/graphql';
import Button from '../atomic/Button';
import { CenteredDiv } from '../styled/CenteredDiv';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

type LoginProps = RouteComponentProps & {
  handleLogin: (email: string, password: string) => any;
  handleCreateUser: (userInput: UserInput) => any;
  loginStatus: AuthStatus;
  confirmEmail?: string;
  emailConfirmed?: string;
};

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const authContext = useContext(AuthContext);
  // const formRef = React.createRef<FormInstance>();

  // const [initLoading, setInitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  // To disable submit button at the beginning.
  /*
  useEffect(() => {
    forceUpdate({});
  },        []);
  */

  /*
  useEffect(() => {
    if (props.loginStatus !== loginStatus) {
      setLoginStatus(props.loginStatus);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);
  */

  useEffect(() => {
    if (authContext.authState === AuthStatus.LoggingIn) {
      setIsLoading(true);
    }
    if (authContext.authState === AuthStatus.None && isLoading) {
      setIsLoading(false);
    }
    if (authContext.authState === AuthStatus.Authenticated && isLoading) { // authContext.isAuthenticated && !isAuthenticated) {
      // localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
      notification.success({
        message: 'Successfully logged in!',
        description: `Logged in successfully, Redirecting you in a few!`,
        placement: 'topRight',
        duration: 1.0,
        onClose: () => {
          // history.push('/collections'); // already pushed in "login"
          // authContext.login(token, '/starbucks/false');
        },
      });
      setIsLoading(false);
    }

    if (authContext.authState === AuthStatus.ConfirmRegister && isLoading) { // loginStatus === AuthStatus.ConfirmRegister && isLoading/* && authContext.isSemiAuthenticated && isLoading */) {
      // localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
      notification.error({
        message: 'Email needs to be confirmed!',
        description: `Your email needs to be confirmed, redirecting to the confirmation page`,
        placement: 'topRight',
        duration: 1.0,
        onClose: () => {
          history.push(`/register?email=${props.confirmEmail}`);
        },
      });
      // authContext.isSemiAuthenticated
      setIsLoading(false);
    }

    if (authContext.authState === AuthStatus.CreateUser && isLoading && props.confirmEmail) { // loginStatus === AuthStatus.CreateUser && authContext.isSemiAuthenticated && isLoading) {
      // localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);

      notification.success({
        message: 'successfully logged in!',
        description: `Logged in successfully, but we need to finalize registration in Swing 2 Places`,
        placement: 'topRight',
        duration: 1.0,
        onClose: () => {
          if (!authContext.userInput || !authContext.userInput.firstName || !authContext.userInput.lastName) {
            history.push(`/register?email=${props.confirmEmail ? props.confirmEmail : (authContext.userInput ? authContext.userInput.emailAddress : '') }`);
          }
          else {
            props.handleCreateUser(authContext.userInput);
          }
        },
      });
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [authContext, isLoading, props]);

  const onFinish = (values: Store) => {
    // values.preventDefault();
    const { email, password } = values;
    setIsLoading(true);
    props.handleLogin(email, password);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setIsLoading(false);
  };

  return (
    <>
      <Row justify="center" align="middle" style={ { minHeight: '20vh' } }>
        <Form
          form={form}
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            initialValue={props.emailConfirmed}
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input style={{ minWidth: '300px' }} prefix={<UserOutlined style={{ color: colors.transparentBlack }} />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input
              style={{ minWidth: '300px' }}
              prefix={<LockOutlined style={{ color: colors.transparentBlack }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {false && <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>}
          <Form.Item className="text-center">
            <Button
              style={{ width: '100%', minWidth: '300px' }}
              type="primary"
              disabled={isLoading}
              htmlType="submit"
              className="login-form-button"
            >
              {isLoading ? <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />} /> : 'Log in'}
            </Button>
            <CenteredDiv style={{ width: '100%', paddingTop: '2px', minWidth: '300px' }}><Link to="/reset">Forgot your password?</Link></CenteredDiv>
          </Form.Item>
        </Form>
      </Row>
    </>
  );
};

/*  // Remove forgot password, registration, and login with google
            <Row gutter={30}>
              <Col lg={24}>
                <div style={{ marginTop: '8px', marginLeft: 35, marginRight: 30, minWidth: '300px' }}>
                  <a href="/forgot-password">Forgot password</a> Or <a href="/register">Register now!</a>
                </div>
              </Col>
              <Col lg={24}>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col lg={24}>
                <div style={{ minWidth: '300px', height: '5px', borderBottom: '1px solid black', position: 'absolute' }}/>
                <br />
                <Button
                  style={{ width: '100%', minWidth: '300px' }}
                  type="primary"
                  disabled={isLoading}
                  htmlType="submit"
                  className="login-form-button"
                >
                  <a href="http://localhost:4000/auth/google">Login with Google</a>
                </Button>
              </Col>
            </Row>
*/
export default withRouter(Login);
