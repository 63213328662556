
import React from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { RecordType, InputType, getRecordElement } from './DataTypes';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: { recordType: RecordType, inputType?: InputType, value?: string, values?: string[]};
  required: boolean;
  record: any;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = (props: EditableCellProps, ...restProps) => {
  const { editing,
  dataIndex,
  title,
  inputType,
  required,
  children } = props;

  const inputNode = inputType ? getRecordElement(
    inputType.recordType,
    props.record,
    inputType.inputType,
    {
      placeholder: `Please enter ${title}`,
      value: inputType && inputType.value ? inputType.value : (props.record ? props.record.name : ''),
      values: inputType && inputType.values && inputType.values.length > 0 ? inputType.values : [],
    }) : null;

  const tdProps = (restProps.length === 1 && isEmpty(restProps[0])) ? [] : restProps;

  return (
    <td {...tdProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
