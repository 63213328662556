import React, { useContext, useState } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import "antd/dist/antd.css";
import { CloseCircleOutlined } from '@ant-design/icons';
import ThemeContext from '../../context/ThemeContext';
import Resize from '../resize/Resize';

const { Footer } = Layout;

type FooterStyledProps = {
  backgroundColor?: string;
  padding?: number;
  height: number;
};

const FooterStyled = styled(Footer)`
  position: fixed;
  background-color: #00008b;
  padding: ${(props: FooterStyledProps) => props.padding ? props.padding : 15}px;
  height: ${(props: FooterStyledProps) => props.height}px;
`;

const FooterDiv = styled.div`
  text-align: center;
  color: white;
`;

type ThemeSettingsProps = {
  onHeightChange?: (height: number) => {};
};

const ThemeSettings: React.FC<ThemeSettingsProps> = (props: ThemeSettingsProps) => {
  const themeContext = useContext(ThemeContext);
  const [themeHeight, setThemeHeight] = useState<number>(50);
  // const defaultProp = { visible: true, bottom: 50, backgroundColor: '#00008b' };
  return (
    <React.Fragment>
      {themeContext.showThemeSetting
        ? <FooterStyled height={themeHeight}>
            <Resize
              reference='bottom'
              offset={themeHeight}
              onResize={(x: number, y: number) => {
                if (props.onHeightChange) {
                  props.onHeightChange(y);
                }
                setThemeHeight(themeHeight + y);
              }}
            />
            <div style={{ position: 'absolute', float: 'right', width: '10px', right: '10px', bottom: `${themeHeight - 25}px` }}>
                <CloseCircleOutlined
                  style={{ color: 'white' }}
                  onClick={() => themeContext.setDisplaySettings({ showThemeSetting: false })}
                />
              </div>
              <FooterDiv>Color Setting</FooterDiv>
          </FooterStyled>
        : null
      }
    </React.Fragment>
  );
};

export default ThemeSettings;
