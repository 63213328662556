import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import EnclosedIcon, { IconProps } from './EnclosedIcon';

const SearchStyled = styled(SearchOutlined)`
  z-index: 2000;
  font-size: 14px;
  margin-right: 0px;
  ${(props: { color?: string }) => props.color ? `color: ${props.color};` : null}
`;

type SearchIconProps = IconProps & {
};

const SearchIcon: React.FC<SearchIconProps> = (props: SearchIconProps) => {
  const { enclosed, color, onClick } = props;
  const icon = <SearchStyled color={color} />;

  return <EnclosedIcon icon={icon} color={color} enclosed={enclosed} onClick={onClick} />;
};

export default SearchIcon;
