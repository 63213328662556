import { notification } from "antd";
import { Guid } from "guid-typescript";
import React from "react";
import Button from "../atomic/Button";

export const errorNotification = (message?: string | JSX.Element) => {
  showNotification('error', message ?? 'Something went wrong. Please try again.');
};

export const infoNotification = (message: string, actionName?: string, action?: () => void) => {
  showNotification('info', message, actionName, action);
};

export const warnNotification = (message: string, actionName?: string, action?: () => void) => {
  showNotification('warn', message, actionName, action);
};

const showNotification = (type: 'warn' | 'info' | 'error', message: string | JSX.Element, actionName?: string, action?: () => void) => {
  const key = `${type}-notification-${Guid.create().toString()}`;
  const showMessage = actionName && action
    ? <div>{message}<Button style={{ float:'right' }} onClick={() => {
      action();
      notification.close(key);
    }}>{actionName}</Button></div>
    : message;
  notification[type]({
    key,
    message: showMessage,
    onClick: () => notification.close(key),
  });
};
