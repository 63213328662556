import gql from 'graphql-tag';

export default gql`
  fragment DataCollectionData on DataCollectionData {
    uuid
    parent {
      type
      uuid
      ordinal
    }
    ownerUuid
    name
    description
    ordinal
    collectionUuids
    objectUuids
    entryUuids
    dateCreated
    active
    dateRemoved
  }
`;
