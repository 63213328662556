// https://github.com/Bunlong/react-papaparse/blob/master/demo/CSVReader1.js
import React, { useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { errorNotification, infoNotification } from '../actions/Notification';

export type ImportCsvProps = {
  trigger?: JSX.Element | string;
  onImportData: (file: string, columns: string[], rows: string[][]) => void;
};

const ImportCsv: React.FC<ImportCsvProps> = (props: ImportCsvProps) => {
  const { trigger, onImportData } = props;
  const [filename, setFilename] = useState<string>('');
  const [rows, setRows] = useState<string[]>([]);
  const [columns, setColumns] = useState<string[][]>([]);

  const buttonRef = React.createRef<any>();

  const handleOpenDialog = (e: any) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data: any, file?: any) => {
    const filename = file ? file.name : '';
    if (data.length === 0) {
      errorNotification(`No data loaded from file ${filename}`);
      return;
    }
    let maxLength = 0;
    let firstRow = 0;
    let lastRow = 0;
    data.forEach((row: any, index: number) => {
      if (maxLength < row.data.length) {
        maxLength = row.data.length;
        firstRow = index;
        lastRow = index;
      }
      if (row.data.length === maxLength) {
        lastRow = index;
      }
    });

    const columns = data.splice(firstRow, 1)[0].data;
    const rows = data.splice(firstRow, lastRow - firstRow + 1).map((row: any) => row.data);
    setColumns(columns);
    setRows(rows);
    setFilename(filename);
    onImportData(filename, columns, rows);
    // console.log('---------------------------');
    // console.log(data);
    // console.log('---------------------------');
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.log('---------------------------');
    console.log(err);
    console.log('---------------------------');
  };

  const handleOnRemoveFile = (data: any) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleRemoveFile = (e: any) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  return (
    <>
      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          trigger
          ? <div onClick={handleOpenDialog}>{trigger}</div>
          : <aside
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <button
              type="button"
              onClick={handleOpenDialog}
              style={{
                borderRadius: 0,
                marginLeft: 0,
                marginRight: 0,
                width: '40%',
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              Browse file
            </button>
            <div
              style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#ccc',
                height: 45,
                lineHeight: 2.5,
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: 13,
                paddingTop: 3,
                width: '60%',
              }}
            >
              {file && file.name}
            </div>
            <button
              style={{
                borderRadius: 0,
                marginLeft: 0,
                marginRight: 0,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={handleRemoveFile}
            >
              Remove
            </button>
          </aside>
        )}
      </CSVReader>
    </>
  );
};

export default ImportCsv;
