import { EditTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Popconfirm, Dropdown, Menu, Button } from 'antd';
import { Guid } from "guid-typescript";
import { Dictionary } from 'lodash';
import React, { useEffect, useState } from 'react';
import { infoNotification } from '../actions/Notification';
import Tag, { TagColor, TagItem } from '../atomic/Tag';
import { StyledSpan } from '../styled/CenteredDiv';
import QRHistoryTagEdit from './QRHistoryTagEdit';

export type KeyValuePair = {
  key: string;
  value: string;
};

type QRHistoryTagsProps = {
  tags: TagItem[];
  defaultTags?: Dictionary<KeyValuePair>;
  selectedTags: string[];
  disabled?: boolean;
  onCreate: (tag: TagItem) => void;
  onEdit: (editing: boolean) => void;
  onUpdate: (tag: TagItem) => void;
  onDelete: (tag: TagItem) => void;
  onSelect: (tag: TagItem) => void;
};

const QRHistoryTags: React.FC<QRHistoryTagsProps> = (props: QRHistoryTagsProps) => {
  const { defaultTags, selectedTags, disabled, onCreate, onEdit, onUpdate, onDelete, onSelect } = props;
  const [tags, setTags] = useState<TagItem[]>(props.tags);
  const [editTag, setEditTag] = useState<{ isNew: boolean; value: TagItem } | null>(null);

  useEffect(() => {
    if (props.tags !== tags) {
      setTags(props.tags);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const onSelectItem = (item: TagItem) => {
    if (disabled) {
      infoNotification('Changing tags is not allowed while editing a history item');
      return;
    }
    if (!item.uuid) {
      return;
    }
    onSelect({ ...item });
  };

  return (
    <div>
      <StyledSpan bold>Tags: <PlusCircleTwoTone style={{ marginLeft: '5px' }} disabled={editTag !== null} onClick={() => setEditTag({ isNew: true, value: { uuid: Guid.create().toString(), name: '', color: TagColor.Blue, selected: true } })} /></StyledSpan>
      {editTag ? <QRHistoryTagEdit
        tag={editTag}
        tags={tags}
        onCancel={() => setEditTag(null)}
        onCreate={(tag: TagItem) => {
          onCreate(tag);
          setEditTag(null);
        }}
        onUpdate={(tag: TagItem) => {
          onUpdate(tag);
          setEditTag(null);
        }} /> : null}
      <br />
      {tags.length === 0
        ? <span>No Tags found. Please click on <PlusCircleTwoTone /> above to create a new tag</span>
        : <>
            <div style={{ marginBottom: '5px' }}>
              {!defaultTags ? null : Object.keys(defaultTags).map((defaultKey: string) => {
                return <Tag
                  style={{ opacity: !selectedTags.find((tagKey: string) => tagKey === defaultTags[defaultKey].key) ? '30%' : undefined }}
                  key={`tag-${defaultKey}`}
                  value={{ uuid: defaultTags[defaultKey].key, name: defaultTags[defaultKey].value, color: TagColor.Lightgray }}
                  onClick={onSelectItem} />;
              })}
            </div>
            <div>
            {tags.map((t: TagItem, index: number) => (
              <Tag
                style={{ opacity: !selectedTags.find((tagKey: string) => tagKey === t.uuid) ? '30%' : undefined }}
                key={`tag-group-${t.name}`}
                icon={
                  <Dropdown
                    disabled={disabled}
                    overlay={
                      <Menu>
                        <Menu.Item key={`edit-tag-${index}-${t.name}`}>
                          <div onClick={(event: any) => {
                            setEditTag({ isNew: false, value: Object.assign({}, t) });
                            onEdit(true);
                          }}>
                            Edit
                          </div>
                        </Menu.Item>
                        <Menu.Item key={`delete-${index}-${t.name}`}>
                          <Popconfirm
                            title="Delete tag."
                            onConfirm={(e: any) => {
                              onDelete(t);
                            }}
                            onCancel={() => {}}
                            okText="Delete"
                            cancelText="Cancel"
                          ><div onClick={() => {}}>
                            Delete
                          </div></Popconfirm>
                        </Menu.Item>
                      </Menu>}>
                    <Button size='small' style={{ width: '20px', backgroundColor: 'transparent', paddingTop: '8px 0px 4px', border: 'none' }}>
                      <EditTwoTone style={{ fontSize: '14px', opacity: `${disabled ? '30%' : undefined}` }} />
                    </Button>
                  </Dropdown>}
                value={t}
                onClick={(e: any) => {
                  onSelectItem(t);
                }} />
              ))}
            </div>
          </>}

    </div>
  );
};

export default QRHistoryTags;

/* ON Delete

                              const updateHistory = [...history];
                              for (const historyItem of updateHistory) {
                                historyItem.tagUuids = historyItem.tagUuids.filter((tagUuid: string) => tagUuid !== t.uuid);
                              }
                              const removeTag = tags.filter((tag: TagItem) => tag.uuid !== t.uuid);
                              localStorage.setItem('s2p-scan-history-tags', JSON.stringify(removeTag));
                              setTags(removeTag);
                              setHistory(updateHistory);
*/

/*

      {tags.length === 0
        ? <span>No Tags found. Please click on <PlusCircleTwoTone /> above to create a new group</span>}
        : <>
          <div style={{ marginBottom: '5px' }}>
            <Tag
              style={{ opacity: !viewAllItems ? '30%' : undefined }}
              key={`tag-all-items`}
              value={{ uuid: 'all-itmes', name: 'All Items', color: TagColor.Lightgray }}
              onClick={(item: TagItem) => {
                setViewAllItems(!viewAllItems);
                setViewItemsWithoutTags(false);
              }} />
            <Tag
              style={{ opacity: !viewItemsWithoutTags ? '30%' : undefined }}
              key={`tag-items-without-tags`}
              value={{ uuid: 'tag-without-items', name: 'Items with no tags', color: TagColor.Lightgray }}
              onClick={(item: TagItem) => {
                setViewItemsWithoutTags(!viewItemsWithoutTags);
                setViewAllItems(false);
              }} />
          </div>
          <div>
            {tags.map((t: TagItem, index: number) => (
              <Tag
                style={{ opacity: !t.selected || viewAllItems || viewItemsWithoutTags ? '30%' : undefined }}
                key={`tag-group-${t.name}`}
                icon={
                  <Dropdown
                    disabled={editItem !== null}
                    overlay={
                      <Menu>
                        <Menu.Item key={`edit-tag-${index}-${t.name}`}>
                          <div onClick={() => {
                            setEditTag({ isNew: false, value: Object.assign({}, t) });
                          }}>
                            Edit
                          </div>
                        </Menu.Item>
                        <Menu.Item key={`delete-${index}-${t.name}`}>
                          <Popconfirm
                            title="Delete tag."
                            onConfirm={(e: any) => {
                              const updateHistory = [...history];
                              for (const historyItem of updateHistory) {
                                historyItem.tagUuids = historyItem.tagUuids.filter((tagUuid: string) => tagUuid !== t.uuid);
                              }
                              const removeTag = tags.filter((tag: TagItem) => tag.uuid !== t.uuid);
                              localStorage.setItem('s2p-scan-history-tags', JSON.stringify(removeTag));
                              setTags(removeTag);
                              setHistory(updateHistory);
                            }} // setUpdateCollection(<EditCollection key={`update-collection-${initialData.uuid}`} action={RecordModalAction.Delete} initialData={initialData} mapping={collectionDataItemsMap} onSuccess={onSuccess} />)}
                            onCancel={() => {}}
                            okText="Delete"
                            cancelText="Cancel"
                          ><div onClick={() => {}}>
                            Delete
                          </div></Popconfirm>
                        </Menu.Item>
                      </Menu>}>
                    <Button size='small' style={{ width: '20px', backgroundColor: 'transparent', paddingTop: '8px 0px 4px', border: 'none' }}>
                      <EditTwoTone style={{ fontSize: '14px', opacity: `${editItem ? '30%' : undefined}` }} />
                    </Button>
                  </Dropdown>}
                value={t}
                onClick={(item: TagItem) => {
                  const updateTag = tags.map((tag: TagItem, tagIndex) => {
                    tag.selected = tagIndex === index ? (viewAllItems || viewItemsWithoutTags ? true : !tag.selected) : false;
                    return tag;
                  });
                  // updateTag[index].selected = viewAllItems || viewItemsWithoutTags ? true : !t.selected;
                  localStorage.setItem('s2p-scan-history-tags', JSON.stringify(updateTag));
                  setTags([...updateTag]);
                  setViewAllItems(false);
                  setViewItemsWithoutTags(false);
                }} />
              ))}
                </div></></div>
          <br />
        </div>
        */
