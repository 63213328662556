import React, { useContext, useEffect, useState } from 'react';
// import { Auth, Hub } from 'aws-amplify';
import AuthContext, { AuthProvider } from '../context/AuthContext';
// import config from '../Config';
import { RouteComponentProps } from 'react-router-dom';

type OAuthProps = RouteComponentProps & {
//  provider: 'cognito' | 'google' | 'facebook';
};

const OAuth: React.FC<OAuthProps> = (props: OAuthProps) => {
  const authContext = useContext(AuthContext);
  // const [provider, setProvider] = useState<string | undefined>(undefined);
  // const [token, setToken] = useState<string|null>(null);
  const [authState, setAuthState] = useState<string>('signing in...');

  /*
  Hub.listen('auth', (capsule) => {
        const { channel, payload } = capsule;
        if (channel === 'auth') {
          switch (payload.event) {
            case 'signIn':
              console.log('hub says signed in');
              // this.setState({ authState: 'signedIn' });

              Auth.currentAuthenticatedUser().then((user) => {
                console.log(user);
                this.setState({ authState: 'hub got current authenticated user' });
                this.context.login(user.signInUserSession.idToken, '/');

              }).catch((e) => {
                console.log(e);
                this.setState({ authState: 'hub got error' });
              });

              break;
            case 'signIn_failure':
              console.log('hub says signin failure');
              this.setState({ authState: 'signIn' });
              break;
            default:
              break;
          }
        }
      });
    }
    this.state = {
      authState: 'loading',
    };
  }
  */

  // componentDidMount() {

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location && props.location.search ? props.location.search : '');
    const providerValue = urlParams.get('provider') ?? undefined;
    const tokenValue = urlParams.get('token') ?? null;
    if (providerValue === 'google') {
      authContext.login(tokenValue, AuthProvider.Google, null, '/collections');
      setAuthState('Logged in to GOOGLE');
    }
    // setProvider(providerValue);
    // setToken(tokenValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  /*
  Auth.configure({
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: 'swing2places.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: config.OAUTH_REDIRECT_SIGN_IN_URL,
      redirectSignOut: config.OAUTH_REDIRECT_SIGN_OUT_URL,
      responseType: 'token',
    },
  });

  Auth.currentAuthenticatedUser().then((user) => {
    console.log('success from Auth.currentAuthenticatedUser(): ', user);
    this.setState({ authState: 'component did mount says signedIn' });
  }).catch((e) => {
    console.log('error from Auth.currentAuthenticatedUser(): ', e);
    this.setState({ authState: 'component did mount says error' });
  });
  */

  return (
    <div>{authState}</div>
  );
};

export default OAuth;
