import React, { useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import { PlaidLinkedInstitution } from '../../../../graphql/generated/graphql';
import { LastPrice, Stock, StockOrder, StockTransaction } from '../../StockTypes';
import { CenteredDiv } from '../../../styled/CenteredDiv';
import Button from '../../../atomic/Button';
import { RobinhoodAccount } from './ImportRobinhoodTypes';
import ImportRobinhoodAccount from './ImportRobinhoodAccount';
import ImportRobinhoodInit from './ImportRobinhoodInit';
import ImportRobinhoodInstruments from './ImportRobinhoodInstruments';
import ImportRobinhoodOrders from './ImportRobinhoodOrders';

type ImportRobinhoodProps = {
  institution: PlaidLinkedInstitution;
  existingInvestmentTransactions: Dictionary<StockTransaction[]>;
  stocks: Dictionary<Stock>;
  lastPrice?: Dictionary<LastPrice>
  onUpdateLinkedInstitution: (institution: PlaidLinkedInstitution) => void;
  onUpdateStockDetails: (updateStocks: Stock[]) => void;
  onClose: () => void;
};
const ImportRobinhood: React.FC<ImportRobinhoodProps> = (props: ImportRobinhoodProps) => {
  const { stocks, existingInvestmentTransactions, lastPrice, onUpdateLinkedInstitution, onUpdateStockDetails, onClose } = props;
  const [bearerToken, setBearerToken] = useState<string | undefined>(undefined);
  const [robinhoodAccount, setRobinhoodAccount] = useState<RobinhoodAccount | undefined>(undefined);
  const [missingInstrumentSymbols, setMissingIntrumentSymbols] = useState<string[]>([]);
  const [newInstrumentIds, setNewInstrumentIds] = useState<string[]>([]);
  const [fetchOrders, setFetchOrders] = useState<{ fetch: boolean, results: StockOrder[]}>({ fetch: false, results: [] });
  const [fetchTransactions, setFetchTransactions] = useState<{ fetch: boolean, results: StockTransaction[]}>({ fetch: false, results: [] });
  const [fetchEarnings, setFetchEarnings] = useState<{ symbols: string[], results: any[]}>({ symbols: [], results: [] });
  const [fetchDividends, setFetchDividends] = useState<{ symbols: string[], results: any[]}>({ symbols: [], results: [] });

  useEffect(() => {
    const initMissingInstrumentSymbols: string[] = [];
    Object.keys(stocks).forEach((symbol: string) => {
      if (!stocks[symbol].robinhoodId && stocks[symbol].ticker !== '') {
        initMissingInstrumentSymbols.push(stocks[symbol].ticker);
      }
    });
    setMissingIntrumentSymbols(initMissingInstrumentSymbols);
  },        [stocks]);

  const onClickFetchOrders = () => {
    setFetchOrders((prev) => {
      if (prev.fetch) {
        return prev;
      }
      const updatePrev = { ...prev };
      updatePrev.fetch = true;
      return updatePrev;
    });
  };

  const onClickFetchTransactions = () => {
    setFetchTransactions((prev) => {
      if (prev.fetch) {
        return prev;
      }
      const updatePrev = { ...prev };
      updatePrev.fetch = true;
      return updatePrev;
    });
  };

  const onSetNewInstrumentIds = (instrumentIds: string[]) => {
    setNewInstrumentIds((prev) => {
      const updatePrev = { ...prev };
      instrumentIds.forEach((id: string) => {
        if (updatePrev.find((prevId: string) => prevId === id)) {
          return;
        }
        updatePrev.push(id);
      });
      return updatePrev;
    });
  };

  return <>
    {!bearerToken
      ? <ImportRobinhoodInit
        onClose={onClose}
        onUpdateBearerToken={(updateBearerToken: string) => setBearerToken(updateBearerToken)}
      />
      : null}
    {!robinhoodAccount && bearerToken
      ? <ImportRobinhoodAccount
          bearerToken={bearerToken}
          institution={props.institution}
          onUpdateRobinhoodAccount={(updateRobinhoodAccount: RobinhoodAccount, updateInstitution?: PlaidLinkedInstitution) => {
            setRobinhoodAccount(updateRobinhoodAccount);
            if (updateInstitution) {
              onUpdateLinkedInstitution(updateInstitution);
            }
          }}
          onClose={onClose}
        />
      : null}
    {missingInstrumentSymbols.length > 0 && bearerToken
      ? <ImportRobinhoodInstruments
        key='import-rh-instruments'
        bearerToken={bearerToken}
        stocks={Object.assign({}, ...missingInstrumentSymbols.map((symbol: string) => ({ [symbol] : stocks[symbol] })))}
        onUpdateStocks={(updateStocks: Dictionary<Stock>) => onUpdateStockDetails(Object.keys(updateStocks).map((symbol: string) => updateStocks[symbol]))}
        onNotAuthenticated={() => setBearerToken(undefined)}
      />
      : null}
    {newInstrumentIds.length > 0 && bearerToken
      ? <ImportRobinhoodInstruments
        key='import-rh-instruments'
        bearerToken={bearerToken}
        stocks={{}}
        newInstrumentIds={newInstrumentIds}
        onUpdateStocks={(updateStocks: Dictionary<Stock>) => onUpdateStockDetails(Object.keys(updateStocks).map((symbol: string) => updateStocks[symbol]))}
        onNotAuthenticated={() => setBearerToken(undefined)}
      />
      : null}
    <CenteredDiv bold>Import from Robinhood</CenteredDiv>
    <CenteredDiv><Button disabled={fetchTransactions.fetch} onClick={onClickFetchTransactions}>Import investment history</Button><Button disabled={fetchOrders.fetch} onClick={onClickFetchOrders}>Import open orders</Button></CenteredDiv>

    {fetchOrders.fetch && bearerToken
      ? <ImportRobinhoodOrders
        bearerToken={bearerToken}
        institution={props.institution}
        existingInvestmentTransactions={existingInvestmentTransactions}
        stocks={stocks}
        lastPrice={lastPrice}
        onUpdateLinkedInstitution={(institution: PlaidLinkedInstitution) => {}}
        onUpdateStockDetails={(updateStocks: Stock[]) => {}}
        onGetNewInstrumentIds={onSetNewInstrumentIds}
        onClose={() => {}}
      /> : null}
  </>;
};

export default ImportRobinhood;
