import React from 'react';
import { Button as AntButton } from 'antd';
import { ButtonProps as ButtonPropsAnt } from 'antd/lib/button';
import styled from 'styled-components';

const ButtonStyled = styled(AntButton)`
  ${(props: { selected?: boolean }) => props.selected ? `
  font-weight: bold;
  border: 1px solid blue;
  background: #e6f7ff;
  ` : ''}
  border-radius: 5px;
`;

export type ButtonProps = ButtonPropsAnt & {
  keyTrigger?: string;
  keyPressed?: string;
  selected?: boolean;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { ...antProps } = props;

  return (
    <ButtonStyled {...antProps}>
      {props.children}
    </ButtonStyled>
  );
};

export default Button;
