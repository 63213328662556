import gql from 'graphql-tag';
import User from './User';

export default gql`
  fragment Friend on Friend {
    user
    {
      ...User
    }
    status
    dateCreated
    dateConfirmed
  }
  ${User}
`;
