import React, { useEffect } from 'react';
import { FieldType } from '../../../components/table/DataTypes';
import { ApiProps } from '../Helpers';
import JsxViewResponse from '../JsxViewResponse';
import logger from '../../../utils/Logger';
import { useGetFriendsQuery, useGetUserQuery } from '../../../graphql/generated/graphql';

export const userClientContext = { context: { clientName: 'user' } };
const logInfo = (message: any) => logger.info({ source: 'containers.api.User', message });
const logInfoJson = (message: any) => logger.info({ source: 'containers.api.User', message: JSON.stringify(message) });

type UserApiProps = ApiProps & {
};

export const GetFriends: React.FC<UserApiProps> = (props: UserApiProps) => {
  const { ...apiProps } = props;
  const { data: friendData } = useGetFriendsQuery({
    variables: { },
    ...userClientContext,
  });

  useEffect(() => {
    logInfo('GetFriends useEffect');
    logInfoJson(friendData);

    if (!friendData || !friendData.getFriends) {
      return;
    }

    if (props.updateApiResponse) {
      logInfo('GetFriends useEffect updateApiResponse');
      props.updateApiResponse(friendData.getFriends);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [friendData]);

  logInfo('GetFriends data');
  logInfoJson(apiProps);
  logInfoJson(friendData);
  const result = (<JsxViewResponse data={friendData?.getFriends} {...apiProps} />);
  logInfo('GetFriends result');
  if (!apiProps.returnJsx) {
    return null;
  }

  return friendData ? result : <div>{'No Data Loaded'}</div>; // apiProps.returnJsx ? result : null;
};

export const GetUsers: React.FC<UserApiProps> = (props: UserApiProps) => {
  const { ...apiProps } = props;
  const { data: userData } = useGetUserQuery({
    variables: { },
    ...userClientContext,
  });

  useEffect(() => {
    logInfo('GetUser useEffect');
    logInfoJson(userData);

    if (!userData || !userData.getUser) {
      return;
    }

    if (props.updateApiResponse) {
      logInfo('GetUser useEffect updateApiResponse');
      props.updateApiResponse(userData.getUser);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [userData]);

  logInfo('GetUser data');
  logInfoJson(apiProps);
  logInfoJson(userData);
  const result = (<JsxViewResponse data={userData?.getUser} {...apiProps} />);
  logInfo('GetUser result');
  if (!apiProps.returnJsx) {
    return null;
  }

  return userData ? result : <div>{'No Data Loaded'}</div>; // apiProps.returnJsx ? result : null;
};

export const friendsResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [
      {
        title: 'user',
        key: 'user',
        children: [...[
          'uuid',
          'firstName',
          'lastName',
          'emailAddress',
          'dateCreated',
          ].map((o: string) => ({ title: `${o}`, key: `user-${o}` })),
        ],
      },
      ...[
      'status',
      'dateCreated',
      'dateConfirmed'].map((o: string) => ({ title: `${o}`, key: `${o}` })),
    ]},
  },
};

export const userApiDefinition = {
  friend: {
    getFriends: {
      function: (apiProps: ApiProps) => {
        logInfo('userApiDefinition.getFriends.function');
        logInfoJson(apiProps);
        return <React.Fragment><GetFriends {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
        },
      },
      response: friendsResponse,
    },
  },
};
