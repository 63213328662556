
// import styled from 'styled-components';

// Css Colors: https://www.w3schools.com/cssref/css_colors_legal.asp

// https://www.w3schools.com/cssref/pr_class_position.asp
export enum CssPosition {
  absolute = 'absolute',
  flex = 'flex',
  static = 'static',
  fixed = 'fixed',
  relative = 'relative',
  sticky = 'sticky',
  initial = 'initial',
  inherit = 'inherit',
  undefined = '',
}

// https://www.w3schools.com/cssref/pr_text_text-align.ASP TextAlignProperty
export enum CssTextAlign {
  center = 'center',
  left = 'left',
  right = 'right',
  justify = 'justify',
  initial = 'initial',
  inherit = 'inherit',
  undefined = '',
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/display
export enum CssDisplay {
  /* legacy values */
  block = 'Block',
  inline = 'Inline',
  'inline-block' = 'Inline Block',
  flex  = 'Flex',
  'inline-flex' = 'Inline Flex',
  grid = 'Grid',
  'inline-grid' = 'Inline Grid',
  'flow-root' = 'Flow Root',

  /* box generation */
  none = 'None',
  contents = 'Contents',

  /* two-value syntax */
  'block flow' = 'Block Flow',
  'inline flow' = 'Inline Flow',
  'inline flow-root' = 'Inline Flow Root',
  'block flex' = 'Block Flex',
  // inlineFlex = 'inline-flex',
  // inlineGrid = 'inline grid',
  'block grid'= 'Block Grid',
  'block flow-root' = 'Block Flow Root',
  /* other values */
  table = 'Table',
  'table-row' = 'Table Row',
  'list-item' = 'List Item',

  /* Global values */
  inherit = 'Inherit',
  initial = 'Initial',
  unset = 'Unset',
  undefined = '',
}

export type DivProps = {
  color?: string;
  margin?: string;
  position?: CssPosition
};

export type CssBackgrounColor = {
  header: string;
  content: string;
  footer: string;
};

export const cssDefaultBackgroundColor = {
  header: '#00008b',
  content: '#eeeeee',
  footer: '#00008b',
};
