// https://github.com/Bunlong/react-papaparse/blob/master/demo/CSVReader1.js
import React, { useState } from 'react';
import { errorNotification, infoNotification } from '../actions/Notification';
import Import from './Import';

export type ImportPdfProps = {
  trigger?: JSX.Element | string;
  onImportData: (file: string, columns: string[], rows: string[][]) => void;
};

const ImportPdf: React.FC<ImportPdfProps> = (props: ImportPdfProps) => {
  const { trigger, onImportData } = props;
  const [filename, setFilename] = useState<string>('');
  const [rows, setRows] = useState<string[]>([]);
  const [columns, setColumns] = useState<string[][]>([]);

  const buttonRef = React.createRef<any>();

  const handleOpenDialog = (e: any) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data: any, file?: any) => {
    const filename = file ? file.name : '';
    if (data.length === 0) {
      errorNotification(`No data loaded from file ${filename}`);
      return;
    }
    let maxLength = 0;
    let firstRow = 0;
    let lastRow = 0;
    data.forEach((row: any, index: number) => {
      if (maxLength < row.data.length) {
        maxLength = row.data.length;
        firstRow = index;
        lastRow = index;
      }
      if (row.data.length === maxLength) {
        lastRow = index;
      }
    });

    const columns = data.splice(firstRow, 1)[0].data;
    const rows = data.splice(firstRow, lastRow - firstRow + 1).map((row: any) => row.data);
    setColumns(columns);
    setRows(rows);
    setFilename(filename);
    onImportData(filename, columns, rows);
    // console.log('---------------------------');
    // console.log(data);
    // console.log('---------------------------');
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.log('---------------------------');
    console.log(err);
    console.log('---------------------------');
  };

  const handleOnRemoveFile = (data: any) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleRemoveFile = (e: any) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  return (
    <>
      <Import onLoad={(file: { name: string, data: undefined | string }) => {
        console.log(file.name);
      }} icon={<div>Import pdf</div>} type='.pdf' />
    </>
  );
};

export default ImportPdf;
