import React, { useEffect, useState } from 'react';
import { ApiProps, ResultViewEnum } from './Helpers';
import { Auth } from 'aws-amplify';
import { FieldType } from '../../components/table/DataTypes';

type CognitoApiProps =  ApiProps & {
};

export const GetJwt: React.FC<CognitoApiProps> = (props: CognitoApiProps) => {
  const [jwt, setJwt] = useState('Getting Jwt token');
  const session = Auth.currentSession();

  useEffect(() => {
  },        []);

  session.then((s: any) => {
    const jwtToken = s.getIdToken().getJwtToken();
    if (props.updateApiResponse) {
      props.updateApiResponse(jwtToken);
    }
    setJwt(jwtToken);
  });

  return props.view === ResultViewEnum.GraphQL
    ? <div>{`{ "authorization" : "${jwt}" }`}</div>
    : (<div>{jwt}</div>);
};

export const cognitoApiDefinition = {
  cognito: {
    getJwt: {
      function: (apiProps: ApiProps) => {
        return <React.Fragment><GetJwt view={apiProps.view} /></React.Fragment>;
      },
      arguments: {
        entries: {},
      },
      response: {
        entries: {
          response: { type: FieldType.Tree, values: [...[
            'jwt'].map((o: string) => ({ title: o, key: o })),
          ] },
        },
      },
    },
  },
};
