import React, { useEffect, useState }  from 'react';
import { AddDataEntryMutation, DataEntryInput, useAddDataEntryMutation } from '../../../graphql/generated/graphql';
import { dataClientContext } from '../Api';

export type AddEntryProps = {
  entryInput: DataEntryInput;
  onUpdate?: (data?: AddDataEntryMutation | null, loading?: boolean, error?: any) => any;
};

const AddEntry: React.FC<AddEntryProps> = (props: AddEntryProps) => {
  const { entryInput, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [addDataEntryMutation, { data: newEntry, loading: loadingNew, error: errorNew }] = useAddDataEntryMutation({
    ...dataClientContext,
  });

  useEffect(() => {
    if (!executed) {
      console.log(`add new entry '${entryInput.title}'`);
      setExecuted(true);
      addDataEntryMutation({ variables: { dataEntry: entryInput } })
        .then((result: any) => {
          console.log(`done adding entry ${result.dataUuid}`);
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error adding entry ${entryInput.title}`);
          console.log(err);
        });
    }
    if (onUpdate) {
      onUpdate(newEntry, loadingNew, errorNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [newEntry, loadingNew, errorNew]);

  return <></>;
};

export default AddEntry;
