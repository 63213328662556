import React from 'react';
import moment from "moment";
import { DataEntry } from "../../graphql/generated/graphql";
import { EntrySettings, EntrySort, ViewFilter } from "../filter/EntryFilter";
import { dateFormat, FieldType } from "../table/DataTypes";
import { Space } from 'antd';
import DatePicker from '../atomic/DatePicker';

export function applyFilter(entriesSettings: EntrySettings, dataEntries: any) {
  if (entriesSettings.filter === ViewFilter.Due) {
    return dataEntries.filter((data: DataEntry) => data.active && data.dateDue && (entriesSettings.includeCompleted || !data.dateCompleted));
  }
  if (entriesSettings.filter === ViewFilter.NoDue) {
    return dataEntries.filter((data: DataEntry) => data.active && !data.dateDue && (entriesSettings.includeCompleted || !data.dateCompleted));
  }
  if (entriesSettings.filter === ViewFilter.ThisWeek) {
    return dataEntries.filter((data: DataEntry) => {
      const now = moment(moment().format(dateFormat));
      const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
      const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;

      return data.active
        && (entriesSettings.includeCompleted || !data.dateCompleted)
        && data.dateDue
        && diffDays <= 0
        && diffDays >= -6;
    });
  }
  if (entriesSettings.filter === ViewFilter.ThisWeekPastDue) {
    return dataEntries.filter((data: DataEntry) => {
      const now = moment(moment().format(dateFormat));
      const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
      const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;

      return data.active
        && (entriesSettings.includeCompleted || !data.dateCompleted)
        && data.dateDue
        && diffDays >= -6;
    });
  }
  if (entriesSettings.filter === ViewFilter.Today) {
    // dataEntries.forEach((data: DataEntry) => console.log(`Name: ${data.title} Due date: ${moment(data.dateDue)}, diff: ${moment().diff(moment(data.dateDue), 'days')}`));
    return dataEntries.filter((data: DataEntry) => {
      const now = moment(moment().format(dateFormat));
      const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
      const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;
      return (data.active
        && (entriesSettings.includeCompleted || !data.dateCompleted)
        && data.dateDue
        && diffDays === 0);
    });
  }
  if (entriesSettings.filter === ViewFilter.TodayPastDue) {
    return dataEntries.filter((data: DataEntry) => {
      const now = moment(moment().format(dateFormat));
      const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
      const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;
      return data.active
        && (entriesSettings.includeCompleted || !data.dateCompleted)
        && data.dateDue
        && diffDays >= 0;
    });
  }
  if (entriesSettings.filter === ViewFilter.OverDue) {
    return dataEntries.filter((data: DataEntry) => {
      const now = moment(moment().format(dateFormat));
      const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
      const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;
      return data.active
        && (entriesSettings.includeCompleted || !data.dateCompleted)
        && data.dateDue
        && diffDays > 0;
    });
  }
  if (entriesSettings.filter === ViewFilter.All) {
    return dataEntries.filter((data: DataEntry) => data.active
      && (entriesSettings.includeCompleted || !data.dateCompleted));
  }
  if (entriesSettings.filter === ViewFilter.DeletedItems) {
    return dataEntries.filter((data: DataEntry) => !data.active && (entriesSettings.includeCompleted || !data.dateCompleted));
  }
  if (entriesSettings.filter === ViewFilter.Completed) {
    return dataEntries.filter((data: DataEntry) => !data.active && data.dateCompleted);
  }
  // All and completed
  return dataEntries.filter((data: DataEntry) => data.active);
}

export function filterData(entriesSettings: EntrySettings, dataEntries: DataEntry[], filterEntries?: string[], filterDates?: { start: Date, end?: Date }[]) {
  const optionalFilterApplied = filterEntries && filterEntries.length > 0 ? dataEntries.filter((entry: DataEntry) => filterEntries.find((filterEntry: string) => filterEntry === entry.dataUuid)) : dataEntries;
  const calendarFilterApplied = !filterDates || filterDates.length === 0
    ? optionalFilterApplied
    : (filterDates.length === 1
      // Filter events in calendar view
      ? optionalFilterApplied.filter((entry: DataEntry) => filterDates.find((filterDate: { start: Date, end?: Date }) => {
        const entryDate = entry.dateCompleted ? entry.dateCompleted : entry.dateDue;
        if (!entryDate) {
          return false;
        }
        return filterDate.end ? moment(entryDate).format(dateFormat) >= moment(filterDate.start).format(dateFormat) && moment(entryDate).format(dateFormat) <= moment(filterDate.end).format(dateFormat) : false;
      }))
      // Filter events for selected only items
      : optionalFilterApplied.filter((entry: DataEntry) => filterDates.find((filterDate: { start: Date, end?: Date }) => {
        const entryDate = entry.dateCompleted ? entry.dateCompleted : entry.dateDue;
        if (!entryDate) {
          return false;
        }
        return filterDate.end ? false : moment(entryDate).format(dateFormat) === moment(filterDate.start).format(dateFormat);
      })));
  // filter
  const filteredData = applyFilter(entriesSettings, calendarFilterApplied);
  // sort by
  if (entriesSettings.sortBy === EntrySort.Alphabetical) {
    return filteredData.sort((one, two) => (one.title.toLowerCase() < two.title.toLowerCase() ? -1 : 1));
  }
  if (entriesSettings.sortBy === EntrySort.DoneDate) {
    return filteredData.sort((one, two) => {
      if (!one.dateCompleted && !two.dateCompleted) {
        return one.title.toLowerCase() < two.title.toLowerCase() ? -1 : 1;
      }
      if (!one.dateCompleted) {
        return 1;
      }
      if (!two.dateCompleted) {
        return -1;
      }
      const oneDate = moment(one.dateCompleted).format(dateFormat);
      const twoDate = moment(two.dateCompleted).format(dateFormat);

      return oneDate < twoDate
        ? -1
        : (oneDate > twoDate
          ? 1
          : (one.title.toLowerCase() < two.title.toLowerCase() ? -1 : 1));
    });
  }
  if (entriesSettings.sortBy === EntrySort.DueDate) {
    return filteredData.sort((one, two) => {
      if (!one.dateDue && !two.dateDue) {
        return one.title.toLowerCase() < two.title.toLowerCase() ? -1 : 1;
      }
      if (!one.dateDue) {
        return 1;
      }
      if (!two.dateDue) {
        return -1;
      }
      const oneDate = moment(one.dateDue).format(dateFormat);
      const twoDate = moment(two.dateDue).format(dateFormat);

      return oneDate < twoDate
        ? -1
        : (oneDate > twoDate
          ? 1
          : (one.title.toLowerCase() < two.title.toLowerCase() ? -1 : 1));
    });
  }
  // else Created Date
  return filteredData.sort((one, two) => moment(one.dateCreated) < moment(two.dateCreated) ? -1 : 1);
}

export function getEntriesMapping() {
  return {
    lastUpdated: '',
    lastCollectionsUpdated: '',
    itemId: (data: any) => {
      return data.dataUuid;
    },
    header: {
      leftWidth: '20px',
      // leftElement added after dateObjects data retrieved
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        // return Object.keys(mapping).map((m: any) => {
        return (
          <div key={`data-entries-header`}>
            {data.title}{mapping.dataObject && false
                          ? <span style={{ fontWeight: 'bold', maxWidth: 125, float: 'right', wordWrap: 'break-word', overflowWrap: 'break-word', right: '0px' }}>
                              {mapping.dataObject.resolve(data[mapping.dataObject.field])}
                            </span>
                          : null}
            <div>
              {mapping.collections & mapping.collections.resolve ? <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Collections: {mapping.collections.resolve(data)}</div> : null}
              <Space size={2} direction='horizontal' style={{ marginBottom: '5px' }}>
                {!data.dateCompleted
                  ? <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px' }}>{mapping.dateDue.label}: {mapping.dateDue.resolve(data)}</div>
                  : <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px' }}>{mapping.dateCompleted.label}: {mapping.dateCompleted.resolve(data)}</div>}
              </Space>
            </div>
          </div>);
        // });
      },
      mapping: {
        dataEntry: {
          title: { label: '', field: 'title' },
          dataObject: null,
          collections: [],
          dateDue: { label: 'Due', field: '', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
            const now = moment(moment().format(dateFormat));
            const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
            const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;

            return <DatePicker
              key={`data-picker-due`}
              color={data.dateDue && !data.dateCompleted && diffDays > 0 ? 'red' : undefined}
              style={{ background: 'inherit', color: 'red', height: 'auto', width: 'auto' }}
              confirmReset={{ title: 'Remove due date' }}
              size='small'
              initialValue={data.dateDue ? moment(data.dateDue) : undefined}
              onChange={(e: any) => {
                // setMarkDueDate(e);
                // setUpdateDueDate(data);
              }}
            />;
          }},
          dateCompleted: { label: 'Completed', field: 'dateCompleted', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
            return <DatePicker
              key={`data-picker-completed`}
              style={{ background: 'inherit', color: 'red', height: 'auto', width: 'auto' }}
              size='small'
              initialValue={data.dateCompleted ? moment(data.dateCompleted) : undefined}
              format={dateFormat}
              onChange={(e: any) => {
                // setMarkDoneDate(e);
                // setUpdateDoneDate(data);
              }}
            />;
          }},
        },
      },
    },
  };
}
