import gql from 'graphql-tag';

export default gql`
  fragment File on File {
    uuid
    url
    filename
    expiration
  }
`;
