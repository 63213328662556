import React, { useEffect }  from 'react';
import { PlaidInvestment, useGetPlaidInvestmentTransactionsQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type GetInvestmentsProps = {
  broker: { name: string, linkedId: string };
  startDate?: string;
  endDate?: string;
  onUpdate?: (data?: { broker: string, investments: PlaidInvestment }, loading?: boolean, error?: any) => any;
};

const GetInvestments: React.FC<GetInvestmentsProps> = (props: GetInvestmentsProps) => {
  const { onUpdate, broker, startDate, endDate } = props;
  const { data: investments, loading, error } = useGetPlaidInvestmentTransactionsQuery({
    variables: { accessToken: broker.linkedId, startDate, endDate },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(investments ? { broker: broker.name, investments: investments.getPlaidInvestmentTransactions } : undefined, loading, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [investments, loading, error]);

  return null;
};

export default GetInvestments;
