import gql from 'graphql-tag';

export default gql`
  fragment User on User {
    uuid
    firstName
    lastName
    emailAddress
    dateCreated
    accountDetails {
      features
    }
  }
`;
