import React from 'react';
import uuid from 'uuid/v4';
import { TitleDiv } from '../draggable/DraggableContext';
import { DraggableItem } from '../draggable/DraggableItemView';
import { DraggableComponentItem } from './ComponentProperties';
import DraggableListView from './DraggableList';

export enum ComponentItems {
  Header = 'Header',
  Container = 'Container',
  Text = 'Text',
  Collection = 'Collection',
  Calendar = 'Calendar',
  Button = 'Button',
  'Create Entry' = 'Create Entry',
  'Form Field' = 'Form Field',
  Form = 'Form',
}

export const ITEMS: DraggableComponentItem[] = [
  ...Object.keys(ComponentItems).map((item: string) => {
    return ({
      id: uuid(),
      title: ComponentItems[item],
      enableChildren: ComponentItems[item] === ComponentItems.Container ? true : false,
      containerUuid: 'ITEMS',
    });
  }),
];

type ComponentListProps = {
};

const ComponentList: React.FC<ComponentListProps> = (props: ComponentListProps) => {
  return <>
    <TitleDiv>Components</TitleDiv>
    <div style={{ padding: '10px' }} >
      <DraggableListView id='ITEMS' list={ITEMS as DraggableItem<any>[]} />
    </div>
  </>;
};

export default ComponentList;
