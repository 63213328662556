import React, { useContext, useState } from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { withRouter, Link } from 'react-router-dom';
import { Menu, Dropdown, Avatar, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import { MenuOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import AuthContext from '../../context/AuthContext';
import ThemeContext from '../../context/ThemeContext';
// import { lowerFirst } from 'lodash';
import { OffsetProps } from '../resize/ResizeGeneric';
// import md5 from 'md5';
import { GetDataCollections } from '../../containers/api/Collection/DataCollection';
import { DataCollectionData, Features } from '../../graphql/generated/graphql';
import FeedbackModal from '../feedback/FeedbackModal';

const TitleStyled = styled(Title)`
  position: relative;
  text-align: center;
  white-space: nowrap;
`;

type HeaderDivProps = {
  background?: string;
  height?: string;
};

const HeaderDiv = styled.div`
  position: fixed;
  width: 100%;
  line-height: ${(props: HeaderDivProps) => props.height ? props.height : '60px'};
  margin-padding: 10px;
  background-color: ${(props: HeaderDivProps) => props.background ? props.background : '#00008b'};
`;

const Img = styled.img`
  margin-left: 10px;
  margin-top: 5px;
  object-fit: cover;
  width: 120px;
  height: 50px;
  vertical-align: top;
`;

const AvatarDropdown = styled(Dropdown)`
  float: right;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const AvatarDropdownMoveble = styled(Dropdown)`
  float: right;
  ${(props: OffsetProps) => `${props.vertical}: ${props.offsetY}px;`}
  ${(props: OffsetProps) => `${props.horizontal}: ${props.offsetX}px;`}
  position: absolute;
  z-index: 1000;
  draggable;
`;

const AppMenu = styled(Menu)`
  line-height: 35px;
`;

/*
const MinimizeHeaderDiv = styled.div`
  margin: 15px
  float: right;
  z-index: 1000;
`;
*/

type HeaderProps = RouteComponentProps & {
  headerTitle?: string;
};

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const themeContext = useContext(ThemeContext);

  const isAuthenticated: boolean = authContext.isAuthenticated;
  const isLoginOrRegistering = props.location.pathname === '/login' || props.location.pathname === '/register';
  const isLogoMenuDisabled = props.location.pathname === '/qr' || props.location.pathname === '/qr#scan';
  const [collectionsData, setCollectionsData] = useState<any>(null);
  const dataCollections = collectionsData === null ? <GetDataCollections key={themeContext.lastUpdated} updateApiResponse={(data?: any, loading?: any, error?: any) => {
    if (data) {
      setCollectionsData(data.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)));
    }
  }}/> : null;

  const collectionUuid = (props.match.params as any).collectionUuid;
  const pathname = props.location.pathname;

  /*
  const handleOffset = (deltaX: number, deltaY: number) => {
    if (!themeContext.header.show) {
      themeContext.setDisplaySettings({ header: { show: themeContext.header.show, right: themeContext.header.right + deltaX, top: themeContext.header.top + deltaY } });
    }
  }; */

  const menuItems = collectionsData && collectionsData.length > 0
    ? collectionsData.map((item: DataCollectionData) => (
      <Menu.Item key={`collection-menu-item${item.uuid}`}>
        {collectionUuid === item.uuid && pathname.startsWith('/entries/')
        ? <div key={`collection-menu-selected-${item.uuid}`} style={{ fontWeight: 'bold' }}>{item.name.length < 38 ? item.name : `${item.name.substring(0, 35)}...`}</div>
        : <Link key={`collection-menu-link-${item.uuid}`} to={`/entries/${item.uuid}`}>
          {item.name.length < 38 ? item.name : `${item.name.substring(0, 35)}...`}
        </Link>}
      </Menu.Item>))
    : (!collectionsData ? [<Menu.Item key={'menu-loading-collections'}><div >Loading Collections ...</div></Menu.Item>] : []);

  const menu = (
    <Menu key='collection-menu'>
      {[
        <Menu.Item style={{ fontWeight: 'bold' }} key={'collections-menu-entries-title'}>
          <div>ENTRIES:</div>
        </Menu.Item>,
        <Menu.Item key={'menu-all-entries-link'}>
          <Link to={`/entries`}>View all Entries</Link>
        </Menu.Item>,
        <Menu.Item key={'collections-menu-divider'}>
          <Divider style={{ margin: '0px' }} />
        </Menu.Item>,
        <Menu.Item style={{ fontWeight: 'bold' }} key={'collections-menu-collection-title'}>
          <div>COLLECTIONS:</div>
        </Menu.Item>,
        <Menu.Item key={'collections-menu-collection-link'}>
          <Link to={`/collections`}>View all Collections</Link>
        </Menu.Item>,
        ...menuItems]}
    </Menu>
    );

  const loggedInAvatarDropdown = (Element: AnyStyledComponent, props?: any) => {
    /*
      <Menu.Item key='settings'>
        <Link to='/settings/'>
          <FormattedMessage id='settings' />
        </Link>
      </Menu.Item>
      <Menu.Item key='configureTheme' onClick={() => themeContext.setDisplaySettings({ showThemeSetting: true })}>
        <FormattedMessage id='configureTheme' />
      </Menu.Item>
      <Menu.Item key='manageDocument'>
        <Link to='/documents/'>
          <FormattedMessage id='manageDocument' />
        </Link>
      </Menu.Item>
    */
    return (
    <Element key='avatar-account'
      {... props}
      overlayStyle={{ border: '1px solid black', borderRadius: '4px' }}
      trigger={['click']}
      overlay={
      <Menu>
        {[
          { id: 'account', to: '/account/' },
          ...(authContext.isFeatureEnabled(Features.Qr) ? [{ id: 'qrScanner', to: '/qr' }] : []),
          ...(authContext.isFeatureEnabled(Features.Stock) ? [{ id: 'stocks', to: '/stocksample' }] : []),
        ].map((item: any) =>
          <Menu.Item key={item.id}>
          {pathname === item.to
            ? <div style={{ fontWeight: 'bold' }}><FormattedMessage id={item.id} /></div>
            : (
            <Link to={item.to}>
              <FormattedMessage id={item.id} />
            </Link>)}
        </Menu.Item>)}
        <Menu.Divider key='divider'/>
        <Menu.Item key='logout' onClick={() => authContext.logout() }>
          <Link to='/'>
            <FormattedMessage id='logout' />
          </Link>
        </Menu.Item>
        <Menu.Divider key='divider2'/>
        <Menu.Item key='user-email' disabled={true}>
          {authContext.user ? authContext.user.emailAddress : ''}
        </Menu.Item>
        {process.env.REACT_APP_ENV
          ? <Menu.Item key='env' disabled={true}>
              Env: {process.env.REACT_APP_ENV}
            </Menu.Item>
          : null}
        <Menu.Item key='submitFeedback' onClick={(event: any) => setShowFeedback(true)} >
          Submit Feedback
        </Menu.Item>
        <Menu.Item key='minimizeHeader' onClick={(event: any) => {
          themeContext.setDisplaySettings({ header: { show: !themeContext.header.show, top: themeContext.header.top, right: themeContext.header.right } });
        }}>
          {themeContext.header.show ? 'Minimize' : 'Show'} header
        </Menu.Item>
      </Menu>
    }
    placement='bottomRight'
  >
  {// authContext.user && false // ? <MenuOutlined style={{ fontSize: '26px', paddingRight: '6px', color: 'white' }} /> : <MenuOutlined style={{ fontSize: '26px', paddingRight: '6px', color: 'white' }} />
    // ? (<Avatar shape='circle' icon={<MenuOutlined style={{ fontSize: '26px', paddingRight: '6px', color: 'white' }} />} src={`https://www.gravatar.com/avatar/${md5(authContext.user.emailAddress)}?s=120`} data-intercom-target='Account'/>)
    // : (<Avatar shape='circle'  data-intercom-target='Account' >S2P</Avatar>)
    <Avatar shape='circle' style={{ background: themeContext.header.show ?  'inherit' : 'blue', border: themeContext.header.show ? '1px solid white' : undefined }} data-intercom-target='Account' >S2P</Avatar>
  }
  </Element>);
  };

  return <>
    {showFeedback ? <FeedbackModal onClose={() => setShowFeedback(false) } /> : null}
    {(!themeContext.header.show
      ? /*<ResizeGeneric
        element={MinimizeHeaderDiv}
        vertical={'top'}
        horizontal={'right'}
        initialX={15}
        initialY={15}
        onResize={handleOffset}
        >
          {loggedInAvatarDropdown(AvatarDropdown)}
        </ResizeGeneric> */
          loggedInAvatarDropdown(AvatarDropdownMoveble, { offsetX: 10, offsetY: 10, vertical: 'top', horizontal: 'right' })
      : <HeaderDiv background=''>
        {props.headerTitle ? <div style={{ position: 'absolute', left: 125, right: 100, top: 15 }}><TitleStyled style={{ color: 'white' }} ellipsis level={4}>{props.headerTitle}</TitleStyled></div> : null}

        {isLogoMenuDisabled ? null : (isAuthenticated ? <Link to='/home'><Img src="/logo_transparent.png" /></Link> : <Link to='/home'><Img src="/logo_transparent.png" /></Link>)}
        {isAuthenticated && !isLoginOrRegistering && (
          loggedInAvatarDropdown(AvatarDropdown)
        )}
        {isAuthenticated && !isLoginOrRegistering && (
          <>
            {dataCollections}
            {<AvatarDropdown key='avatar-apps'
                  trigger={['click']}
                  overlayStyle={{ overflow: 'scroll', maxHeight: '350px', border: '1px solid black', borderRadius: '4px' }}
                  overlay={menu}>
                    {true
                      ? <Avatar shape='circle' style={{ background: themeContext.header.show ? 'inherit' : 'blue', border: themeContext.header.show ? '1px solid white' : undefined }}data-intercom-target='Collections' >
                          <MenuOutlined style={{ color: 'white' }}/>
                        </Avatar>
                      : <MenuOutlined style={{ fontSize: '26px', color: 'white' }} />}
                </AvatarDropdown>}
          </>)
        }
        {!isAuthenticated && !isLoginOrRegistering ? (
          <AvatarDropdown
            overlayStyle={{ border: '1px solid black', borderRadius: '4px' }}
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item key='home'>
                  <Link to='/home/'>
                    <FormattedMessage id='home' />
                  </Link>
                </Menu.Item>
                <Menu.Item key='qr'>
                  <Link to='/qr'>
                    <FormattedMessage id='qrScanner' />
                  </Link>
                </Menu.Item>

              </Menu>
            }
            placement='bottomRight'
            data-intercom-target='Login'
          >
            <MenuOutlined style={{ fontSize: '26px', paddingRight: '6px', color: 'white' }} />
          </AvatarDropdown>
        ) : null}
        {isAuthenticated && !isLoginOrRegistering && false && (
          <AppMenu
          theme='light'
          mode='horizontal'
          defaultSelectedKeys={[props.match.path]}
        >
          <Menu.Item key='home' data-intercom-target='Home'>
            <Link to='/home/'>
              <FormattedMessage id='home' />
            </Link>
          </Menu.Item>
          <Menu.Item key='apps' data-intercom-target='Apps'>
            <Link to='/apps/'>
              <FormattedMessage id='apps' />
            </Link>
          </Menu.Item>
        </AppMenu>
        )}
      </HeaderDiv>)}
  </>;
};

/*
  <Menu.Item key='pages'>
    <Link to='/pages/'>
      <FormattedMessage id='pages' />
    </Link>
  </Menu.Item>
  <Menu.Item key='managePages'>
    <Link to='/manage'>
      <FormattedMessage id='manage' />
    </Link>
  </Menu.Item>

  {!isAuthenticated && !isLoginOrRegistering && (
  <AppMenu
    theme='light'
    mode='horizontal'
    defaultSelectedKeys={[props.match.path]}
  >
    <Menu.Item key='/home/' data-intercom-target='Home'>
      <Link to='/home/'>
        <FormattedMessage id='home' />
      </Link>
    </Menu.Item>
    <Menu.Item key='/events/' data-intercom-target='Events'>
      <Link to='/events/'>
        <FormattedMessage id='events' />
      </Link>
    </Menu.Item>
    <Menu.Item key='/schedule/' data-intercom-target='Schedule'>
      <Link to='/schedule/'>
        <FormattedMessage id='schedule' />
      </Link>
    </Menu.Item>
  </AppMenu>
)}
*/

export default withRouter(Header);
