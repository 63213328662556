import { useSubscription } from '@apollo/client';
import React, { useEffect, useState }  from 'react';
import UpdateClientList from '../../../graphql/subscriptions/Data';

export type SubscriptionsProps = {
};

const Subscriptions: React.FC<SubscriptionsProps> = (props: SubscriptionsProps) => {
  const { data: subData, loading, error } = useSubscription(UpdateClientList, { context: { clientName: 'data-ws' } });
  const [result, setResult] = useState<null | JSX.Element>(null);

  useEffect(() => {
    if (loading) {
      setResult(<div>loading...</div>);
    }
    else if (error) {
      setResult(<pre>Error: {JSON.stringify(error, null, 2)}</pre>);
    }
    else if (subData) {
      setResult(<pre>{JSON.stringify(subData, null, 2)}</pre>);
    }
    else {
      setResult(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [subData, loading, error]);

  return result;
};

export default Subscriptions;
