import React, { useEffect, useState }  from 'react';
import { useDeleteFilesMutation } from '../../../graphql/generated/graphql';
import { storageClientContext } from '../Storage';

type DeleteFileProps = {
  initialData: any;
  onSuccess?: () => any;
};

const DeleteFile: React.FC<DeleteFileProps> = (props: DeleteFileProps) => {
  const { initialData, onSuccess } = props;
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [deleteFilesMutation, { /* data: updatedCollection, */ loading: loadingDelete, error: errorDelete }] = useDeleteFilesMutation({
    ...storageClientContext,
  });

  useEffect(() => {
    if (!initialData.uuid) {
      console.error('failed to delete collection. Uuid not provided');
      return;
    }
    if (!isDeleted) {
      deleteFilesMutation({ variables: { uuids:[initialData.uuid] } })
      .then((result: any) => {
        console.log('delete file');
        console.log(result.data);
        if (!errorDelete) {
          setIsDeleted(true);
          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch((err: any) => {
        console.log('error deleting file');
        console.log(err);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  return <></>;
};

export default DeleteFile;
