import React, { useEffect } from 'react';
import { useGetDataObjectsQuery, useGetDataEntriesQuery, DataObjectEntryFragment, DataObjectFragment, DataEntryDataFragment, DataEntryFragment, MutationAddDataEntryArgs, useAddDataEntryMutation } from '../../../graphql/generated/graphql';
import { FieldType } from '../../../components/table/DataTypes';
import { ApiProps, TypeOverride, TypeOmit } from '../Helpers';
import JsxViewResponse from '../JsxViewResponse';
import { dataClientContext, getResponseDataNode, getResponseDataObj, getResponseDataObjs } from '../Api';

enum DataEntryFieldDisplayEnum {
  Show = 'Show',
  Expandable = 'Expandable',
  Hidden = 'Hidden',
}

type DataEntriesApiProps = ApiProps & {
  collectionUuids?: string[];
  objectUuids?: string[];
  entryUuids?: string[];
};

type DataEntryObjectsProps = ApiProps & {
  includeInactive?: boolean;
  objectUuids?: string[];
};

type AddDataEntryApiProps = ApiProps & MutationAddDataEntryArgs;

// Schema objects
type DataObjectEntrySchemaType = { [entryUuid: string] : DataObjectEntryFragment };
type DataObjSchemaType = { [objectUuid: string] : TypeOmit<DataObjectFragment, 'entries>'> & { fields: DataObjectEntrySchemaType } };

// Data Objects
export type DataEntryDataObjType = DataEntryDataFragment & {
  mapping: any[];
  fieldName: string;
};
export type DataEntryObjType = { show: DataEntryDataObjType[], expandable: DataEntryDataObjType[], hidden: string[] };

// type DataEntriesDataObjType = { [entryUuid: string] : (DataObjectEntryFragment & { data: string })}
type DataEntriesObjType = { [objectUuid: string] : TypeOverride<DataEntryFragment, { data: DataEntryObjType[] }> };

export const GetDataEntries: React.FC<DataEntriesApiProps> = (props: DataEntriesApiProps) => {
  const { collectionUuids, objectUuids, entryUuids, ...apiProps } = props;

  const { data: dataEntries, loading, error } = useGetDataEntriesQuery({
    /* client: publicClient, */
    variables: { collectionUuids, objectUuids, entryUuids },
    ...dataClientContext,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (apiProps.updateApiResponse) {
      apiProps.updateApiResponse(dataEntries ? dataEntries.getDataEntries : undefined, loading, error);
    }
  });

  if (!apiProps.returnJsx && apiProps.updateApiResponse) {
    return null;
  }

  console.log('GetDataEntries dataEntries');
  console.log(dataEntries);
  console.log(apiProps);
  console.log(dataEntries);
  const result = (<JsxViewResponse data={dataEntries?.getDataEntries} {...apiProps} />);
  console.log(result);
  return result;
};

export const GetDataEntryObjects: React.FC<DataEntryObjectsProps> = (props: DataEntryObjectsProps) => {
  const { objectUuids, includeInactive, ...apiProps } = props;

  const { data: dataSchema } = useGetDataObjectsQuery({
    variables: { includeInactive: includeInactive === undefined ? false : includeInactive },
    ...dataClientContext,
  });
  const { data: dataEntries } = useGetDataEntriesQuery({
    /* client: publicClient, */
    variables: { objectUuids: objectUuids ?? ['0c7e3db7-0b43-4e92-9d66-d2c71015b787'] },
    ...dataClientContext,
  });

  const filter = {
    'c001fec6-e0fa-4707-82c7-9c63a4ed2f19': DataEntryFieldDisplayEnum.Show, // owner
    '7f8526e2-0eba-4709-b643-d97ebe893d1b': DataEntryFieldDisplayEnum.Show, // code
    'c001fec6-e0fa-4707-82c7-9c63a4ed2f18': DataEntryFieldDisplayEnum.Expandable, // dataAdded
    'c001fec6-e0fa-4707-82c7-9c63a4ed2f20': DataEntryFieldDisplayEnum.Expandable, // dateUsed
    'c001fec6-e0fa-4707-82c7-9c63a4ed2f21': DataEntryFieldDisplayEnum.Hidden, // usedBy
  };

  const dataObjEntries: DataObjSchemaType = {};

  if (dataSchema && dataSchema.getDataObjects) {
    dataSchema.getDataObjects.forEach((dataObject: DataObjectFragment) => {
      const { entries, ...dataObjectProperties } = dataObject;
      let entryDictionary: DataObjectEntrySchemaType = {};
      if (entries && entries.length > 0) {
        entryDictionary = Object.assign({}, ...entries.map((entry: DataObjectEntryFragment) => ({ [entry.uuid]: entry })));
          // const { uuid, objectUuid, ...dataEntryProperties } = entry;
          // return ({ [entry.uuid]: entry })
          // return ({ [entry.uuid]: entry });
        // }));
      }
      dataObjEntries[dataObject.uuid] = { ...dataObjectProperties, fields: entryDictionary };
    });
  }
  console.log('dataObjEntries');
  console.log(dataObjEntries);

  const entryObjects: DataEntriesObjType = {};
  if (dataEntries && dataEntries.getDataEntries) {
    dataEntries.getDataEntries.forEach((entry: DataEntryFragment) => {
      const { data, ...objNode } = entry;
      const dataObj = dataObjEntries[entry.objectUuid];
      if (!dataObj) {
        console.error(`Data Object missing with uuid: ${entry.objectUuid}`);
        return;
      }
      if (!entryObjects[objNode.objectUuid]) {
        entryObjects[objNode.objectUuid] = { ...objNode, data: [] };
      }

      const newEntry: DataEntryObjType = { show: [], expandable: [], hidden: [] };
      data.forEach((d: DataEntryDataFragment) => {
        if (filter[d.entryUuid] === DataEntryFieldDisplayEnum.Hidden) {
          console.log(`Data Entry hidden with uuid: ${d.entryUuid}`);
          return;
        }
        console.log(dataObj.fields);
        console.log(d);
        const entryObj = {
          ...d,
          mapping: [],
          fieldName: dataObj.fields[d.entryUuid].name,
        };
        if (filter[d.entryUuid] === DataEntryFieldDisplayEnum.Show) {
          newEntry.show.push(entryObj);
        }
        else if (filter[d.entryUuid] === DataEntryFieldDisplayEnum.Expandable) {
          newEntry.expandable.push(entryObj);
        }
        else {
          console.error(`Data Entry is not includes in filter object uuid: ${d.entryUuid}`);
          return;
        }
      });

      entryObjects[entry.objectUuid].data.push(newEntry);
    });
  }

  console.log('entryObjects ');
  console.log(entryObjects);
  const result = entryObjects ? (<JsxViewResponse data={entryObjects} {...apiProps} />) : <div>'No data loaded'</div>;
  console.log(result);
  return result;
};

export const AddDataEntry: React.FC<AddDataEntryApiProps> = (props: AddDataEntryApiProps) => {
  const { ...apiProps } = props;
  const [addDataEntryMutation, { data: newEntry, /* loading, */ error }] = useAddDataEntryMutation({
    variables: { dataEntry: props.dataEntry },
    ...dataClientContext,
  });

  // ;

  useEffect(() => {
    if (!newEntry) {
      addDataEntryMutation();
    }
    console.log('AddDataCollection useEffect');
    console.log(newEntry);

    if (error) {
      console.error(`AddDataCollection useEffect error: ${error}`);
      return;
    }

    if (!newEntry || !newEntry.addDataEntry) {
      return;
    }
    if (props.updateApiResponse) {
      console.log('AddDataCollection useEffect updateApiResponse');
      props.updateApiResponse(newEntry.addDataEntry);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [newEntry]); // Empty array ensures that effect is only run on mount and unmount

  console.log('GetDataCollections dataSchema');
  console.log(apiProps);
  console.log(newEntry);

  return null;
};

export const dataEntryResponseFn = (keyPath?: string) => {
  return [
    getResponseDataObj('dataUuid', keyPath),
    getResponseDataObj('objectUuid', keyPath),
    getResponseDataNode(
      'data',
      (parentKey: string) => getResponseDataObjs(
        [
          'entryUuid',
          'data',
        ],
        parentKey),
      keyPath),
    ...getResponseDataObjs(
      [
        'dateCreated',
        'active',
        'dateRemoved',
      ],
      keyPath),
  ];
};

export const dataEntryResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [...dataEntryResponseFn()], /* [
      'objectUuid'].map((o: string) => ({ title: `${o}`, key: `${o}` })),
      {
        title: 'data',
        key: 'data',
        children: [...[
          'uuid',
          'entryUuid',
          'data',
        ].map((o: string) => ({ title: `${o}`, key: `data-${o}` })),
        ],
      },
      ...[
      'dateCreated',
      'active',
      'dateRemoved'].map((o: string) => ({ title: `${o}`, key: `${o}` })),
    ] */
    },
  },
};
