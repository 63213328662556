import { Checkbox, Input, Radio } from 'antd';
import { Dictionary } from 'lodash';
import uuid from 'uuid/v4';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { PlaidAccount, StockDetails, StockPrice } from '../../graphql/generated/graphql';
import { errorNotification } from '../actions/Notification';
import LoadingOverlay from '../atomic/LoadingOverlay';
import ExclamationIcon from '../icons/ExlamationIcon';
import RecordModal, { ModalFields, RecordModalAction } from '../modal/RecordModal';
import { StyledDiv } from '../styled/CenteredDiv';
import { InputType, RecordType } from '../table/DataTypes';
import Table from '../table/Table';
import { Stock, StockDataSourceType, StockLotMapping, StockOrder, StockTransaction, SymbolStockData, TransactionAction } from './StockTypes';
import FindStock from './FindStock';
import GetStockDetails from '../../containers/api/Stock/GetStockDetails';
import GetStocks from '../../containers/api/Stock/GetStocks';

export enum StockDataType {
  BuyOrder = 'Buy Order',
  BuyTransaction = 'Buy Transaction',
  SellOrder = 'Sell Order',
  SellTransaction = 'Sell Transaction',
}

export enum SellLotType {
  Fifo = 'FIFO',
  Lifo = 'LIFO',
  Hifo = 'HIFO',
  Custom = 'Custom Lots',
}

const holdingsColumns = ['Shares to sell', 'Shares Available', 'Date Aquired', 'Cost Basis/Share', 'Lot Basis', 'Unrealized Gain/Loss', 'Holding Period'].map((column: string, index: number) => ({
  title: column,
  dataIndex: index,
  key: column,
  inputType: { recordType: RecordType.Index },
}));

type AddStockDataProps = {
  dataType: StockDataType,
  transactions: SymbolStockData<StockTransaction>,
  allTransactions?: StockTransaction[],
  accounts?: Dictionary<Dictionary<PlaidAccount>>,
  mapLots?: StockTransaction,
  editTransaction?: StockTransaction,
  deleteTransaction?: StockTransaction,
  editOrder?: StockOrder,
  deleteOrder?: StockOrder,
  stockDetails: Dictionary<{ price?: StockPrice, details: Stock }>,
  onSuccess: (updateStock?: { transactions: StockTransaction[], orders: StockOrder[], details?: StockDetails }, deleteStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => void;
  onCancel: () => any;
};

const AddStockData: FC<AddStockDataProps> = (props: AddStockDataProps) => {
  const { dataType, stockDetails, accounts, mapLots, editTransaction, deleteTransaction, editOrder, deleteOrder, transactions, onSuccess, onCancel } = props;
  const [modalAction, setModalAction] = useState<RecordModalAction>(props.mapLots || props.editTransaction ? RecordModalAction.Update : (props.deleteTransaction ? RecordModalAction.Delete : RecordModalAction.Create));
  const [newStockDetails, setNewStockDetails] = useState<Dictionary<StockDetails>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [executeUpdate, setExecuteUpdate] = useState<JSX.Element | null>(null);
  const [lastChanged, setLastChanged] = useState<string | undefined>(undefined);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
  const [sellLotType, setSellLotType] = useState<SellLotType>(mapLots ? SellLotType.Custom : SellLotType.Fifo);
  // const [brokers, setBrokers] = useState<string[]>([]);
  // const [accounts, setAccounts] = useState<Dictionary<string[]>>({});
  const [sellLots, setSellLots] = useState<Dictionary<number>>({});
  const [selectedSymbol, setSelectedSymbol] = useState<string | undefined>(mapLots ? mapLots.symbol : undefined);
  const [brokerAccounts, setBrokerAccounts] = useState<{ brokers: string[], accounts: Dictionary<{ accountId: string, name: string }[]>, selectedBroker?: string, selectedAccount?: { accountId: string, name: string } }>({ brokers: [], accounts: {} });
  // const [sellPrice, setSellPrice] = useState<number | undefined>(mapLots ? mapLots.price : undefined);
  const [allowPartialMapping, setAllowPartialMapping] = useState<boolean>(false);
  const [quantityAmount, setQuantityAmount] = useState<{ types: ('Quantity' | 'Total Amount' | 'Price')[], quantity?: number, amount?: number, price?: number }>({ types: ['Quantity', 'Price'], price: mapLots ? mapLots.price : undefined });
  const [date, setDate] = useState<moment.Moment>(mapLots ? moment(mapLots.date) : (editTransaction ? moment(editTransaction.date) : (deleteTransaction ? moment(deleteTransaction.date) : moment(new Date()))));
  const sellLotTypeRef = useRef<SellLotType>(SellLotType.Fifo);
  sellLotTypeRef.current = sellLotType;
  const sellLotsRef = useRef<Dictionary<number>>({});
  sellLotsRef.current = sellLots;
  const brokerAccountsRef = useRef<{ brokers: string[], accounts: Dictionary<{ accountId: string, name: string }[]>, selectedBroker?: string, selectedAccount?: { accountId: string, name: string } }>({ brokers: [], accounts: {} });
  brokerAccountsRef.current = brokerAccounts;
  const quantityAmountRef = useRef<{ types: ('Quantity' | 'Total Amount' | 'Price')[], quantity?: number, amount?: number, price?: number }>({ types: [] });
  quantityAmountRef.current = quantityAmount;

  useEffect(() => {
    if (mapLots && mapLots.lots) {
      const initLots: Dictionary<number> = {};
      mapLots.lots.forEach((lot: StockLotMapping) => {
        const mappedTrans = transactions[mapLots.symbol] && transactions[mapLots.symbol][mapLots.broker] && transactions[mapLots.symbol][mapLots.broker][mapLots.accountId]
          ? transactions[mapLots.symbol][mapLots.broker][mapLots.accountId].find((mappedTransaction: StockTransaction) => mappedTransaction.uuid === lot.buyUuid)
          : undefined;
        if (mappedTrans && lot.quantity > 0) {
          initLots[mappedTrans.uuid] = lot.quantity;
        }
      });
      setSellLots(initLots);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    const initBrokers: string[] = [];
    const initAccounts: Dictionary<{ accountId: string, name: string }[]> = {};
    let updateBrokers = false;
    let updateAccounts = false;
    if (mapLots) {
      initBrokers.push(mapLots.broker);
      const mappedAccount: { accountId: string, name: string } = {
        accountId: mapLots.accountId,
        name: accounts && accounts[mapLots.broker] && accounts[mapLots.broker][mapLots.accountId] && accounts[mapLots.broker][mapLots.accountId].name !== undefined ?  (accounts[mapLots.broker][mapLots.accountId].name as string) : mapLots.accountId,
      };
      initAccounts[mapLots.broker] = [mappedAccount];
      setSelectedSymbol(mapLots.symbol);
      // setSellPrice(mapLots.price);
      onUpdateQuantityAmountValue(mapLots.amount, mapLots.price, mapLots.quantity);
      setBrokerAccounts({ brokers: initBrokers, accounts: initAccounts, selectedBroker: initBrokers[0], selectedAccount: initAccounts[mapLots.broker][0] });
      return;
    }
    if (editTransaction || deleteTransaction || editOrder || deleteOrder || mapLots) {
      const initTrans: StockTransaction | undefined = editTransaction ?? (deleteTransaction ?? mapLots);
      const initOrder: StockOrder | undefined = editOrder ?? deleteOrder;
      const initBroker = initTrans ? initTrans.broker : initOrder!.broker;
      const initAccountId = initTrans ? initTrans.accountId : initOrder!.accountId;
      // const initSymbol = initTrans ? initTrans.symbol : initOrder!.symbol;
      const initAccount: { accountId: string, name: string } = {
        accountId: initAccountId,
        name: accounts && accounts[initBroker] && accounts[initBroker][initAccountId] && accounts[initBroker][initAccountId].name !== undefined ?  (accounts[initBroker][initAccountId].name as string) : initAccountId,
      };
      initBrokers.push(initBroker);
      initAccounts[initBroker] = [initAccount];
      // setSelectedSymbol(initSymbol);
      // onUpdateQuantityAmountValue(initTrans ? initTrans.amount : undefined, initTrans ? initTrans.price : initOrder!.price, initTrans ? initTrans.quantity : initOrder!.quantity);
      // setBrokerAccounts({ brokers: initBrokers, accounts: initAccounts, selectedBroker: initBrokers[0], selectedAccount: initAccounts[initBroker][0] });
      // return;
    }
    if (transactions) {
      Object.keys(transactions).forEach((symbol: string) => {
        Object.keys(transactions[symbol]).forEach((broker: string) => {
          if (!initBrokers.includes(broker)) {
            initBrokers.push(broker);
            updateBrokers = true;
          }
          if (!initAccounts[broker]) {
            initAccounts[broker] = [];
            updateAccounts = true;
          }
          Object.keys(transactions[symbol][broker]).forEach((account: string) => {
            if (!initAccounts[broker].find((acc: { accountId: string, name: string }) => acc.accountId === account)) {
              const accountName: string = accounts && accounts[broker] && accounts[broker][account] && accounts[broker][account].name !== undefined ? accounts[broker][account].name as string : account;
              initAccounts[broker].push({ accountId: account, name: accountName });
            }
          });
        });
      });
    }
    if (accounts) {
      Object.keys(accounts).forEach((broker: string) => {
        if (!initBrokers.includes(broker)) {
          initBrokers.push(broker);
          updateBrokers = true;
        }
        Object.keys(accounts[broker]).forEach((account: string) => {
          if (!initAccounts[broker]) {
            initAccounts[broker] = [];
          }
          if (!initAccounts[broker].find((acc: { accountId: string, name: string }) => acc.accountId === account)) {
            updateAccounts = true;
            initAccounts[broker].push({ accountId: account, name: accounts && accounts[broker] && accounts[broker][account] && accounts[broker][account].name ? accounts[broker][account].name as string : account });
          }
          // console.log(accounts[broker][account]);
        });
      });
    }
    if (updateBrokers || updateAccounts) {
      const updateTransaction = editTransaction ?? deleteTransaction ?? editOrder ?? deleteOrder;
      const selectedBroker = updateTransaction ? updateTransaction.broker : initBrokers[0];
      const editAccount = updateTransaction ? initAccounts[selectedBroker].find((acc: { accountId: string, name: string }) => acc.accountId === updateTransaction.accountId || acc.name === updateTransaction.accountId) : undefined;
      const selectedAccount = editAccount ?? (initAccounts[selectedBroker].length > 0 ? initAccounts[selectedBroker][0] : undefined);
      if (updateTransaction) {
        setSelectedSymbol(updateTransaction.symbol);
        // setSellPrice(updateTransaction.price);
        onUpdateQuantityAmountValue(
          undefined,
          updateTransaction.price && updateTransaction.price < 0 ? -updateTransaction.price : updateTransaction.price,
          updateTransaction.quantity && updateTransaction.quantity < 0 ? -updateTransaction.quantity : updateTransaction.quantity);
      }
      if (initAccounts['Coinbase'] && !initAccounts['Coinbase'].find((acc:  { accountId: string, name: string }) => acc.accountId === 'Coinbase')) {
        initAccounts['Coinbase'] = [{ accountId: 'Coinbase', name: 'Coinbase' }, ...initAccounts['Coinbase']];
      }
      setBrokerAccounts({ brokers: initBrokers, accounts: initAccounts, selectedBroker, selectedAccount });
    }
  },        [transactions, accounts, editTransaction, deleteTransaction, editOrder, deleteOrder, mapLots]);

  useEffect(() => {
    setLastChanged(new Date().toISOString());
  },        [brokerAccounts, sellLots, selectedSymbol]);

  const isSellType: boolean = dataType === StockDataType.SellOrder || dataType === StockDataType.SellTransaction;

  if (isSellType && !transactions) {
    console.error(`AddStockData missing transactions for ${dataType}`);
    return null;
  }

  const descriptionAction = editTransaction || mapLots || editOrder ? 'Update' : (deleteTransaction || deleteOrder ? 'Delete' : 'Create');

  const fieldTexts: { description: string, dateFieldName: string, dateFieldPlaceholder: string, orderExperiationFieldName?: string, orderExpirationPlaceholder?: string, pricePlaceholder?: string } =
    (dataType === StockDataType.SellOrder
      ? { description: `${descriptionAction} sell limit order for known bought lots`,
        dateFieldName: 'Date sell order created',
        dateFieldPlaceholder: 'Set date when sell order was created',
        orderExperiationFieldName: 'Order Expiration Date',
        orderExpirationPlaceholder: 'Set date when sell order will expire',
        pricePlaceholder: 'Sell limit price' }
      : (dataType === StockDataType.SellTransaction
        ? { description: `${descriptionAction} sell transaction for known bought lots. If missing, please add bought lot first.`,
          dateFieldName: 'Date sold',
          dateFieldPlaceholder: 'Set date when stock was sold',
          pricePlaceholder: 'Set price sold' }
        : (dataType === StockDataType.BuyOrder
          ? { description: `${descriptionAction} buy limit order`,
            dateFieldName: 'Date buy order created',
            dateFieldPlaceholder: 'Set date when buy order was created',
            orderExperiationFieldName: 'Order Expiration Date',
            orderExpirationPlaceholder: 'Set date when sell order will expire',
            pricePlaceholder: 'set buy limit price' }
          : (dataType === StockDataType.BuyTransaction
            ? { description: `${descriptionAction} bought transaction`,
              dateFieldName: 'Date bought',
              dateFieldPlaceholder: 'Set date when stock was bought',
              pricePlaceholder: 'Set price bought' }
            : { description: '', dateFieldName: '', dateFieldPlaceholder: '' }))));

  const onUpdateBrokerAccount = (broker?: string, accountId?: string) => {
    setBrokerAccounts((prevBrokerAccounts) => {
      if (broker && prevBrokerAccounts.selectedBroker === broker && !accountId) {
        return prevBrokerAccounts;
      }
      if (accountId && prevBrokerAccounts.selectedAccount && prevBrokerAccounts.selectedAccount.accountId === accountId && !broker) {
        return prevBrokerAccounts;
      }
      const updateBrokerAccounts = { ...prevBrokerAccounts };
      if (broker) {
        if (updateBrokerAccounts.selectedBroker !== broker) {
          updateBrokerAccounts.selectedAccount = undefined;
        }
        updateBrokerAccounts.selectedBroker = broker;
        /*
        if (updateBrokerAccounts.selectedAccount && !updateBrokerAccounts.accounts[broker].find((acc: any) => acc.name === updateBrokerAccounts.selectedAccount)) {
          updateBrokerAccounts.selectedAccount = updateBrokerAccounts.accounts[broker][0].name;
        }
        */
      }
      const selectedBroker = broker ?? updateBrokerAccounts.selectedBroker;
      if (accountId && selectedBroker) {
        const selectedAccount = updateBrokerAccounts.accounts[selectedBroker].find((acc: any) => acc.accountId === accountId);
        updateBrokerAccounts.selectedAccount = selectedAccount;
      }
      else if (selectedBroker && updateBrokerAccounts.selectedAccount === undefined) {
        const initAccount = prevBrokerAccounts.accounts[selectedBroker] && prevBrokerAccounts.accounts[selectedBroker].length > 0 ? prevBrokerAccounts.accounts[selectedBroker][0] : undefined;
        updateBrokerAccounts.selectedAccount = initAccount;
      }
      setSellLots({});
      return updateBrokerAccounts;
    });
  };

  const onUpdateData = (data: ModalFields, sellType?: SellLotType) => {
    if (!isSellType) {
      return;
    }
    const currentSellLotType = sellType ?? sellLotTypeRef.current;
    const recordData = data.pages[0].entries;
    if (recordData.Symbol.value && recordData.Symbol.value !== selectedSymbol) {
      setSellLots({});
      setSelectedSymbol(recordData.Symbol.value);
    }
    if (transactions && recordData.Symbol.value && brokerAccountsRef.current.selectedAccount && brokerAccountsRef.current.selectedBroker && recordData.Quantity.value && currentSellLotType !== SellLotType.Custom) {
      const transactionLots = transactions[recordData.Symbol.value][brokerAccountsRef.current.selectedBroker][brokerAccountsRef.current.selectedAccount.accountId];
      const updateSellLots: Dictionary<number> = {};
      let totalQty = 0;
      if (currentSellLotType === SellLotType.Lifo) {
        transactionLots.filter((lot: StockTransaction) => lot.quantity > 0).sort((a: StockTransaction, b: StockTransaction) => moment(a.date) > moment(b.date) ? -1 : 1)
          .forEach((transactionLot: StockTransaction) => {
            if (totalQty >= recordData.Quantity.value) {
              return;
            }
            updateSellLots[transactionLot.uuid] = totalQty + transactionLot.quantity <= recordData.Quantity.value ? transactionLot.quantity : recordData.Quantity.value - totalQty;
            totalQty = totalQty + transactionLot.quantity;
          });
      }
      else if (currentSellLotType === SellLotType.Fifo) {
        transactionLots.filter((lot: StockTransaction) => lot.quantity > 0).sort((a: StockTransaction, b: StockTransaction) => moment(a.date) < moment(b.date) ? -1 : 1)
          .forEach((transactionLot: StockTransaction) => {
            if (totalQty >= recordData.Quantity.value) {
              return;
            }
            updateSellLots[transactionLot.uuid] = totalQty + transactionLot.quantity <= recordData.Quantity.value ? transactionLot.quantity : recordData.Quantity.value - totalQty;
            totalQty = totalQty + transactionLot.quantity;
          });
      }
      else if (currentSellLotType === SellLotType.Hifo) {
        transactionLots.filter((lot: StockTransaction) => lot.quantity > 0).sort((a: StockTransaction, b: StockTransaction) => a.price ? (b.price ? (a.price > b.price ? -1 : 1) : 1) : -1)
          .forEach((transactionLot: StockTransaction) => {
            if (totalQty >= recordData.Quantity.value) {
              return;
            }
            const newQty = totalQty + transactionLot.quantity <= recordData.Quantity.value ? transactionLot.quantity : recordData.Quantity.value - totalQty;
            if (newQty > 0) {
              updateSellLots[transactionLot.uuid] = newQty;
            }
            else if (updateSellLots[transactionLot.uuid] !== undefined) {
              delete updateSellLots[transactionLot.uuid];
            }
            totalQty = totalQty + transactionLot.quantity;
          });
      }
      setSellLots(updateSellLots);
    }
  };

  const onSubmit = async (record: any) => {
    const recordData = record.pages[0].entries;
    if (!recordData.Symbol) {
      setError('Symbol missing');
      return;
    }
    if (!recordData.Account) {
      setError('Account missing');
      return;
    }
    if (!recordData.Broker) {
      setError('Broker missing');
      return;
    }
    if (!recordData.Quantity || !Number(recordData.Quantity.value)) {
      setError('Quantity missing');
      return;
    }
    if (!recordData.Price || !Number(recordData.Price.value)) {
      setError('Price missing');
      return;
    }
    if (!recordData.Date) {
      setError('Date missing');
      return;
    }
    if ((dataType === StockDataType.BuyOrder || dataType === StockDataType.SellOrder) && !recordData.ExpirationDate) {
      setError('Expiration Order Date missing');
      return;
    }
    const selectedSymbol: string = recordData.Symbol.value;
    const selectedBroker: string = recordData.Broker.value;
    const selectedAccountObj = brokerAccounts.accounts[selectedBroker].find((acc: { accountId: string, name: string }) => acc.name === recordData.Account.value);
    const selectedAccount: string = selectedAccountObj ? selectedAccountObj.accountId : recordData.Account.value;
    const quantity: number = Number(recordData.Quantity.value);
    const price: number = Number(recordData.Price.value);
    const fees: number | undefined = recordData.Fees.value ? Number(recordData.Fees.value) : undefined;
    const date: moment.Moment = recordData.Date.value;
    const expirationDate: moment.Moment | undefined = recordData.ExpirationDate ? moment(recordData.ExpirationDate.value) : undefined;
    const updateTransaction = editTransaction ?? deleteTransaction;
    const updateOrder = editOrder ?? deleteOrder;
    const stockUuid = updateTransaction ? updateTransaction.uuid : (updateOrder ? updateOrder.uuid : uuid());

    if (dataType === StockDataType.BuyOrder) {
      const buyOrder: StockOrder = {
        uuid: stockUuid,
        action: TransactionAction.BuyOrder,
        broker: selectedBroker,
        accountId: selectedAccount,
        symbol: selectedSymbol,
        securityId: updateOrder ? updateOrder.securityId : '',
        expiration: expirationDate!.toDate(),
        price,
        quantity,
        remove_date: deleteOrder ? new Date() : undefined,
        // lots?
      };
      onSuccess({ transactions: [], orders: [buyOrder] });
      return;
    }
    if (dataType === StockDataType.BuyTransaction) {
      // for manually entered entries, set uuid and transactionId to be the same?
      const buyTransaction: StockTransaction = {
        uuid: stockUuid,
        broker: selectedBroker,
        accountId: selectedAccount,
        date: date.toString(),
        transactionId: updateTransaction ? updateTransaction.transactionId : '',
        transactionIds: updateTransaction ? updateTransaction.transactionIds : [],
        securityId: updateTransaction ? updateTransaction.securityId : '',
        action: TransactionAction.Bought,
        symbol: selectedSymbol,
        amount: quantity * price,
        quantity,
        price,
        fees,
        name: updateTransaction ? updateTransaction.name : undefined, // ???
        source: updateTransaction ? updateTransaction.source : {
          sourceType: StockDataSourceType.Manual,
          addedDate: new Date(),
        },
        lots: updateTransaction ? updateTransaction.lots : [],
      };
      onSuccess({ transactions: [buyTransaction], orders: [], details: newStockDetails[selectedSymbol] });
      return;
    }
    if (transactions && !Object.keys(transactions).map((symbol: string) => symbol.toLowerCase()).includes(selectedSymbol.toLowerCase())) {
      setError(`Specified Symbol doesn't have any bought lots`);
      return;
    }
    const buyTransactions: StockTransaction[] = [];
    const sellOrder: StockOrder | null = dataType === StockDataType.SellOrder
      ? {
        uuid: stockUuid,
        action: TransactionAction.SellOrder,
        broker: selectedBroker,
        accountId: selectedAccount,
        symbol: selectedSymbol,
        securityId: updateOrder ? updateOrder.securityId : '',
        expiration: expirationDate!.toDate(),
        price,
        quantity,
        remove_date: deleteOrder ? new Date() : undefined,
      }
      : null;
    const sellTransaction: StockTransaction | null = mapLots
      ? mapLots
      : (dataType === StockDataType.SellOrder
        ? {
          uuid: uuid(),
          broker: selectedBroker,
          accountId: selectedAccount,
          date: date.toString(),
          transactionId: '',
          transactionIds: [],
          securityId: '',
          action: TransactionAction.Sold,
          symbol: selectedSymbol,
          amount: -quantity * price,
          quantity: -quantity,
          price,
          fees,
          name: undefined, // ???
          source: {
            sourceType: StockDataSourceType.Manual,
            addedDate: new Date(),
          },
          lots: [],
          // notes?: string[];
        }
        : null);
    if (sellTransaction && sellLots) {
      sellTransaction.lots = Object.keys(sellLots).filter((lot: string) => sellLots[lot] > 0).map((sellLot: string) => ({
        buyUuid: sellLot, // used for sold transaction
        quantity: sellLots[sellLot],
        sellType: sellLotType, // used for sell order
      }));

      let totalQuantity = 0;
      if (selectedSymbol && transactions && transactions![selectedSymbol] && transactions[selectedSymbol][selectedBroker] && transactions[selectedSymbol][selectedBroker][selectedAccount]) {
        // update buy transaction with mapping to the sellTransaction
        transactions[selectedSymbol][selectedBroker][selectedAccount].forEach((transaction: StockTransaction) => {
          const updateLots: StockLotMapping[] = [];
          let isUpdated = false;
          let exists = false;
          if (transaction.lots) {
            transaction.lots.forEach((lot: StockLotMapping) => {
              if (lot.sellUuid !== sellTransaction.uuid) {
                // Check that another sell transaction exists in the buy transaction mapping.
                if (lot.sellUuid && props.allTransactions && !props.allTransactions.find((checkExisting: StockTransaction) => checkExisting.uuid === lot.sellUuid)) {
                  isUpdated = true; // remove bad mapping
                  console.error(`buy transaction with uuid ${transaction.uuid} mapped to non existing sell transaction with uuid ${lot.sellUuid}`);
                  return;
                }
                updateLots.push(lot);
                return;
              }
              exists = true;
              // remove if previously mapped to SellTransaction, but no longer is mapped
              if (sellLots[transaction.uuid] === undefined || sellLots[transaction.uuid] === 0) {
                isUpdated = true; // item removed (not added) to updateLots
                return;
              }
              // update buy transaction that already has existing mapping
              totalQuantity += sellLots[transaction.uuid];
              if (lot.quantity !== sellLots[transaction.uuid]) {
                const updateLot = { ...lot };
                updateLot.quantity = sellLots[transaction.uuid];
                updateLots.push(updateLot);
                isUpdated = true;
              }
            });
          }
          // if new mapping for a buy transaction, add to the lot
          if (!exists && sellLots[transaction.uuid] && sellLots[transaction.uuid] > 0) {
            isUpdated = true;
            totalQuantity += sellLots[transaction.uuid];
            updateLots.push({
              sellUuid: sellTransaction.uuid,
              quantity: sellLots[transaction.uuid],
              sellType: sellLotType,
            });
          }
          if (isUpdated) {
            transaction.lots = updateLots.length > 0 ? updateLots : undefined;
            buyTransactions.push(transaction);
          }
        });
      }
          /*
          // let isUpdated = transaction.lots && transaction.lots.length !== updateLots.length;
          if (sellLots[transaction.uuid] )

          // if set to 0, remove from buy transaction lots. if misssing, no update. else update qty value.
          // const updateBuyLots: Dictionary<number> = {};
          const updateBuyLots: Dictionary<number> = {}; // transaction.lots ? transaction.lots.filter((lot: StockLotMapping) => lot.sellUuid !== sellTransaction.uuid) : [];
          /// let isUpdated = false;
          // const updateBuyOrders: string[] = [];
          // update or remove existing lots mapped to the buy transaction
          if (transaction.lots) {
            transaction.lots.forEach((lot: StockLotMapping) => {
              if (lot.sellUuid !== sellTransaction.uuid) {
                return;
              }
              if (sellLots[lot.sellUuid] === undefined || sellLots[lot.sellUuid] === 0) {
                isUpdated = true;
                updateBuyLots[lot.sellUuid] = 0;
              }
              else if (sellLots[lot.sellUuid] !== lot.quantity) {
                isUpdated = true;
                updateBuyLots[lot.sellUuid] = sellLots[lot.sellUuid];
                totalQuantity += sellLots[lot.sellUuid];
              }
            });
          }
          // insert newly mapped sell transaction
          if (!isUpdated && !sellLots[transaction.uuid]) {
            isUpdated = true;
            updateBuyLots[sellTransaction.uuid] = sellLots[transaction.uuid];
          }
          if (isUpdated) {
            // init lots to all mappings associated with other sold transactions and sell orders
            transaction.lots = transaction.lots ? transaction.lots.filter((lot: StockLotMapping) => lot.sellUuid !== sellTransaction.uuid) : [];
            of (updateBuyLots[lot.sellUuid] > 0)
              transaction.lots.push({ quantity: })
          }

            Object.keys(updateBuyLots).forEach((sellUuid: string) => {
              transaction.lots = [transaction.lots].filter((lot: StockLotMapping) => !(lot.sellUuid && removeSellLots.includes(lot.sellUuid)));
            })
            if (removeSellLots.length > 0) {
              transaction.lots = transaction.lots.filter((lot: StockLotMapping) => !(lot.sellUuid && removeSellLots.includes(lot.sellUuid)));
            }
          }
          if (Object.keys(updateBuyLots).length > 0) {
            buyTransactions.push(transaction);
          }
        });
      }
      Object.keys(sellLots).forEach((lot: string) => {
        if (!selectedSymbol || !transactions || !transactions![selectedSymbol] || !transactions[selectedSymbol][selectedBroker] || !transactions[selectedSymbol][selectedBroker][selectedAccount]) {
          // skip if lot is not for selected symbol/broker/account;
          return;
        }
        const addBuyLot = transactions[selectedSymbol][selectedBroker][selectedAccount].find((trans: StockTransaction) => trans.uuid === lot);
        if (!addBuyLot) {
          return;
        }
        if (!addBuyLot.lots) {
          addBuyLot.lots = [];
        }
        addBuyLot.lots = addBuyLot.lots.filter((removeMapped: StockLotMapping) => removeMapped.sellUuid !== sellTransaction!.uuid && removeMapped.orderUuid !== sellTransaction!.uuid);
        addBuyLot.lots.push({
          sellUuid: dataType === StockDataType.SellTransaction ? sellTransaction!.uuid : undefined, // used for sold transaction
          orderUuid: dataType === StockDataType.SellOrder ? sellTransaction!.uuid : undefined, // used for sell order
          quantity: sellLots[lot],
          sellType: sellLotType, // used for sell order
        });
        buyTransactions.push(addBuyLot);
        totalQuantity += sellLots[lot];
      });
      */

      if (totalQuantity !== quantity && !allowPartialMapping) {
        setError(`Only ${totalQuantity} of ${quantity} selected.`);
        return;
      }
    }
    // remove buy transactions that is incorrectly mapped to the sell transaction
    onSuccess({ transactions: [...buyTransactions, ...(sellTransaction ? [sellTransaction] : [])], orders: sellOrder ? [sellOrder] : [] });
  };

  const onUpdateQuantityAmount = (inputType: 'Quantity' | 'Total Amount' | 'Price', checked: boolean) => {
    setQuantityAmount((prevQuantityAmount: any) => {
      const updateQuantityAmount = { ...prevQuantityAmount };
      if (checked && !quantityAmount.types.includes(inputType)) {
        if (updateQuantityAmount.types.length > 1) {
          errorNotification('Select two of the three to input: Quantity, Total Amount, Price');
          return prevQuantityAmount;
        }
        updateQuantityAmount.types.push(inputType);
      }
      else if (!checked && quantityAmount.types.includes(inputType)) {
        updateQuantityAmount.types = quantityAmount.types.filter((t: string) => t !== inputType);
      }
      return updateQuantityAmount;
    });
    setLastChanged(new Date().toISOString());
  };

  const onUpdateQuantityAmountValue = (amount?: number, price?: number, quantity?: number) => {
    const currentQuantityAmount = { ...quantityAmountRef.current };
    const updateAmount = amount ? (amount < 0 ? -amount : amount) : currentQuantityAmount.amount;
    const updatePrice = price ? (price < 0 ? -price : price) : currentQuantityAmount.price;
    const updateQty = quantity ? (quantity < 0 ? -quantity : quantity) : currentQuantityAmount.quantity;
    currentQuantityAmount.amount = amount
      ? updateAmount
      : (currentQuantityAmount.types.includes('Quantity') && updateQty && currentQuantityAmount.types.includes('Price') && updatePrice
        ? updateQty * updatePrice
        : (!currentQuantityAmount.types.includes('Total Amount') ? undefined : updateAmount));
    currentQuantityAmount.price = price
      ? updatePrice
      : (currentQuantityAmount.types.includes('Quantity') && updateQty && currentQuantityAmount.types.includes('Total Amount') && updateAmount
        ? updateAmount / updateQty
        : (!currentQuantityAmount.types.includes('Price') ? undefined : updatePrice));
    currentQuantityAmount.quantity = quantity
      ? updateQty
      : (currentQuantityAmount.types.includes('Total Amount') && updateAmount && currentQuantityAmount.types.includes('Price') && updatePrice
        ? updateAmount / updatePrice
        : (!currentQuantityAmount.types.includes('Quantity') ? undefined : updateQty));
    setQuantityAmount(currentQuantityAmount);
    setLastChanged(new Date().toISOString());
  };

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: Object.assign(
          {},
          {
            Description: { type: RecordType.Render, renderFn: (data: ModalFields) => fieldTexts.description },
            Symbol: {
              placeholder: 'Enter stock symbol',
              type: isSellType ? RecordType.Dropdown : RecordType.Render,
              value: selectedSymbol,
              disabled: mapLots !== undefined || editTransaction !== undefined || deleteTransaction !== undefined,
              values: transactions ? Object.keys(transactions).sort((a: string, b: string) => a < b ? -1 : 1) : undefined,
              autoFocus: !(mapLots !== undefined || editTransaction !== undefined || deleteTransaction !== undefined),
              onUpdate: (record: any) => {
              },
              renderFn: (record: any) => {
                return <>
                  <StyledDiv bold>Symbol:</StyledDiv>
                  <FindStock
                    disabled={mapLots !== undefined || editTransaction !== undefined || deleteTransaction !== undefined}
                    trackedStocks={stockDetails ? Object.assign({}, ...Object.keys(stockDetails).map((trackedStock: string) => ({ [trackedStock] : stockDetails[trackedStock].details }))) : {}}
                    initialValue={selectedSymbol}
                    inheritWidth
                    onSelectTicker={(updateSelectedTicker: string) => {
                      setSelectedSymbol(updateSelectedTicker);
                      // setSearchResult(updateSelectedTicker);
                      // onSelectTicker(updateSelectedTicker);
                    }}
                  />
                </>;
              },
            },
            SymbolDescription: { type: RecordType.Render, renderFn: (data: ModalFields) => {
              const symbol = data.pages[0].entries.Symbol.value;
              if (mapLots || !symbol || symbol === '') {
                return null;
              }
              if (!symbol || !stockDetails || !stockDetails[symbol]) {
                return <div>Unknown symbol {symbol}</div>;
              }
              return <>
                <StyledDiv>Company: {stockDetails[symbol].details ? stockDetails[symbol].details!.name : ''}</StyledDiv>
                <StyledDiv>Last Price: {stockDetails[symbol].price && stockDetails[symbol].price ? stockDetails[symbol].price!.last : ''}</StyledDiv>
              </>;
            } },
            Broker: {
              placeholder: 'Enter broker',
              type: RecordType.Dropdown,
              value: mapLots ? mapLots.broker : brokerAccounts.selectedBroker,
              values: brokerAccounts.brokers,
              disabled: mapLots !== undefined || deleteTransaction !== undefined,
              onUpdate: (record: any) => onUpdateBrokerAccount(record) },
            Account: {
              placeholder: 'Enter account',
              type: RecordType.Dropdown,
              value: (brokerAccounts.selectedAccount ? brokerAccounts.selectedAccount.name : ''),
              values: brokerAccounts.selectedBroker ? brokerAccounts.accounts[brokerAccounts.selectedBroker].map((acc: any) => acc.name) : [],
              disabled: mapLots !== undefined || deleteTransaction !== undefined,
              onUpdate: (record: any) => onUpdateBrokerAccount(undefined, record) },
            Date: {
              placeholder: fieldTexts.dateFieldPlaceholder,
              fieldName: fieldTexts.dateFieldName,
              type: RecordType.Date,
              value: date,
              disabled: mapLots !== undefined || deleteTransaction !== undefined,
              onUpdate: (record: any) => {
                setDate(record);
              },
            },
            ...(dataType === StockDataType.BuyOrder || dataType === StockDataType.SellOrder
              ? {
                ExpirationDate: { placeholder: fieldTexts.orderExpirationPlaceholder, fieldName: fieldTexts.orderExperiationFieldName, type: RecordType.Date, value: editOrder ? editOrder.expiration : (deleteOrder ? deleteOrder.expiration : undefined) },
              }
              : {}
            ),
            Quantity: {
              placeholder: 'Enter quantity',
              type: RecordType.Input,
              inputType: InputType.Number,
              fieldName: <div>{mapLots !== undefined || deleteTransaction !== undefined || deleteOrder !== undefined ? null : <Checkbox checked={quantityAmount.types.includes('Quantity')} onChange={(e: any) => {
                onUpdateQuantityAmount('Quantity', e.target.checked);
                /*
                setQuantityAmount((prevQuantityAmount: any) => {
                  const updateQuantityAmount = { ...prevQuantityAmount };
                  if (e.target.checked && !quantityAmount.types.includes('Quantity')) {
                    if (updateQuantityAmount.types.length > 1) {
                      errorNotification('Select two of the three to input: Quantity, Total Amount, Price');
                      return prevQuantityAmount;
                    }
                    updateQuantityAmount.types.push('Quantity');
                  }
                  else if (!e.target.checked && quantityAmount.types.includes('Quantity')) {
                    updateQuantityAmount.types = quantityAmount.types.filter((t: string) => t !== 'Quantity');
                  }
                  return updateQuantityAmount;
                });
                setLastChanged(new Date().toISOString());
                */
              }} />} Quantity</div>,
              value: quantityAmount.quantity,
              onUpdate: (updateQuantity: any) => {
                onUpdateQuantityAmountValue(undefined, undefined, Number(updateQuantity.replace(',', '')));
              },
              disabled: mapLots !== undefined || !quantityAmount.types.includes('Quantity') || deleteTransaction !== undefined },
            Price: {
              placeholder: fieldTexts.pricePlaceholder,
              type: RecordType.Input,
              inputType: InputType.Number,
              value: quantityAmount.price,
              fieldName: <div>{mapLots !== undefined || deleteTransaction !== undefined || deleteOrder !== undefined  ? null : <Checkbox checked={quantityAmount.types.includes('Price')} onChange={(e: any) => {
                onUpdateQuantityAmount('Price', e.target.checked);
              }} />} Price</div>,
              disabled: mapLots !== undefined  || !quantityAmount.types.includes('Price') || deleteTransaction !== undefined,
              onUpdate: (updatePrice: any) => {
                onUpdateQuantityAmountValue(undefined, Number(updatePrice.replace(',', '')));
                /*
                const newSellPrice = Number(updatePrice);
                if (newSellPrice) {
                  setSellPrice(newSellPrice);
                }
                if (quantityAmount.types.includes('Quantity') && quantityAmount.quantity) {
                  setQuantityAmount((prevQuantityAmount: any) => {
                    const updateQuantityAmount = { ...prevQuantityAmount };
                    updateQuantityAmount.amount = prevQuantityAmount.quantity && newSellPrice ? prevQuantityAmount.quantity * newSellPrice : undefined;
                    return updateQuantityAmount;
                  });
                }
                else {
                  setQuantityAmount((prevQuantityAmount: any) => {
                    const updateQuantityAmount = { ...prevQuantityAmount };
                    updateQuantityAmount.quantity = prevQuantityAmount.amount && newSellPrice ? prevQuantityAmount.amount / newSellPrice : undefined;
                    return updateQuantityAmount;
                  });
                }
                setLastChanged(new Date().toISOString());
                */
              },
            },
            Amount: {
              placeholder: 'Enter Amount',
              type: RecordType.Input,
              inputType: InputType.Number,
              fieldName: <div>{mapLots !== undefined || deleteTransaction !== undefined || deleteOrder !== undefined  ? null : <Checkbox checked={quantityAmount.types.includes('Total Amount')} onChange={(e: any) => {
                onUpdateQuantityAmount('Total Amount', e.target.checked);
              }} />} Total Amount</div>,
              value: quantityAmount.amount,
              disabled: mapLots !== undefined || !quantityAmount.types.includes('Total Amount') || deleteTransaction !== undefined,
              hide: dataType === StockDataType.BuyOrder || dataType === StockDataType.SellOrder,
              onUpdate: (updateAmount: any) => {
                onUpdateQuantityAmountValue(Number(updateAmount.replace(',', '')));
              },
            },
            Fees: {
              placeholder: 'Enter fees',
              type: RecordType.Input,
              inputType: InputType.Number,
              disabled: mapLots !== undefined || editTransaction !== undefined || deleteTransaction !== undefined || deleteTransaction !== undefined,
            },
            ...(dataType === StockDataType.SellOrder || dataType === StockDataType.SellTransaction
            ? {
              Lots: { placeholder: "Select lots being sold", type: RecordType.Render, value: sellLotTypeRef.current, renderFn: (modalData: ModalFields) => {
                const data = modalData.pages[0].entries;
                const selectedSymbol = data['Symbol'].value;
                const broker = brokerAccountsRef.current.selectedBroker;
                const account = brokerAccountsRef.current.selectedAccount;
                // Below only used for Sell Order
                const limitPrice = quantityAmount.price;
                // transactions already filterd by parent component.
                const holdingsData = transactions && selectedSymbol && broker && account  && transactions[selectedSymbol] && transactions[selectedSymbol][broker] && transactions[selectedSymbol][broker][account.accountId]
                  ? transactions[selectedSymbol][broker][account.accountId].filter((transaction: StockTransaction) => transaction.quantity > 0).map((transaction: StockTransaction) => {
                    const currentDuration = moment(new Date(transaction.date)).diff(moment(), 'days');
                    const lotQuantity = sellLotsRef.current[transaction.uuid] ?? 0;
                    return ({
                      0: <Input style={{ width: '70px' }} value={lotQuantity} disabled={sellLotTypeRef.current !== SellLotType.Custom} onChange={(e: any) => setSellLots((prevSellLots) => {
                        const newValue = e.target.value === '' ? 0 : Number(e.target.value);
                        if (!newValue && newValue !== 0) {
                          setLastChanged(new Date().toISOString());
                          return prevSellLots;
                        }
                        if (prevSellLots[transaction.uuid] && prevSellLots[transaction.uuid] === newValue) {
                          return prevSellLots;
                        }
                        let totalQuantity = newValue;
                        Object.keys(prevSellLots).forEach((addLot: string) => {
                          if (addLot !== transaction.uuid) {
                            totalQuantity = totalQuantity + prevSellLots[addLot];
                          }
                        });
                        const buyQty = data['Quantity'].value < 0 ? -data['Quantity'].value : data['Quantity'].value;
                        if (totalQuantity > buyQty) {
                          errorNotification(`Total quantity of selected lots ${totalQuantity} is greater than specified Sell Quantity ${buyQty}`);
                          return prevSellLots;
                        }
                        const updateSellLots = { ...prevSellLots };
                        if (newValue > 0) {
                          updateSellLots[transaction.uuid] = newValue;
                        }
                        else if (updateSellLots[transaction.uuid] !== undefined) {
                          delete updateSellLots[transaction.uuid];
                        }
                        return updateSellLots;
                      })} />,
                      1: transaction.quantity,
                      2: transaction.date,
                      3: transaction.price,
                      4: transaction.amount,
                      5: transaction.price && limitPrice ? (transaction.quantity * (limitPrice - transaction.price)).toFixed(2) : '',
                      6: currentDuration > 365 ? 'Long' : 'Short',
                    });
                  })
                  : [];
                let totalQuantity = 0;
                Object.keys(sellLotsRef.current).forEach((addLot: string) => {
                  if (mapLots && addLot !== mapLots.uuid) {
                    totalQuantity = totalQuantity + sellLotsRef.current[addLot];
                  }
                });
                const validHoldings = holdingsData.filter((validHolding: any) => {
                  if (validHolding[2] && modalData.pages[0].entries.Date.value && moment(validHolding[2]) <= moment(modalData.pages[0].entries.Date.value)) {
                    return true;
                  }
                  return false;
                });
                return <>
                  {mapLots
                    ? <>
                      <StyledDiv bold>Select associated lots sold:</StyledDiv>
                      <div>Remaining quantity: {data['Quantity'].value - totalQuantity}</div>
                      </>
                    : <>
                    <div style={{ fontWeight: 'bold' }}>Select which lots to sell ({sellLotTypeRef.current}):</div>
                      <Radio.Group onChange={(e: any) => {
                        const sellType = e.target.value as SellLotType;
                        setSellLotType(sellType);
                        setLastChanged(new Date().toISOString());
                        onUpdateData(modalData, sellType);
                      }} value={sellLotTypeRef.current} defaultValue={SellLotType.Lifo}>
                        {Object.keys(SellLotType).map((sellType: string) => <Radio key={`sell-type-${sellType}`} value={SellLotType[sellType]}>{SellLotType[sellType]}</Radio>)}
                      </Radio.Group>
                    </>}
                  {sellLotTypeRef.current === SellLotType.Lifo ? <div style={{ background: 'lightgray', border: '1px solid gray' }}><ExclamationIcon color='red' /> If a new buy transaction occurs, this sell order will automatically be associated with the new lot.</div> : null}
                  {sellLotTypeRef.current === SellLotType.Hifo ? <div style={{ background: 'lightgray', border: '1px solid gray' }}><ExclamationIcon color='red' /> If a new transaction with higher purchase price occurs or the highest purchase lot is sold due to another order, this sell order will automatically be associated with the new lot with highest purchase price.</div> : null}
                  {validHoldings.length > 0
                    ? <Table
                      style={{ overflow: 'auto' }}
                      pagination={false}
                      className='.antd-documents-table'
                      size='small'
                      columns={holdingsColumns}
                      dataSource={validHoldings}
                      bordered={true}
                    />
                    : <div>No transactions {selectedSymbol} in broker {brokerAccountsRef.current.selectedBroker ?? <span style={{ color: 'red' }}>(not selected)</span>} and account {brokerAccountsRef.current.selectedAccount ? brokerAccountsRef.current.selectedAccount.name : <span style={{ color: 'red' }}>(not selected)</span>}</div>}
                </>;
              }},
              AllowPartial: { type: RecordType.Checkbox, placeholder: 'Allow partial mapping', fieldName: '', value: allowPartialMapping, onUpdate: (record: any) => setAllowPartialMapping((prevAllowPartialMapping: boolean) => !prevAllowPartialMapping) },
            }
            : {}),
          },
        ),
      },
    ],
  };

  return <>
    {executeUpdate}
    {selectedSymbol && stockDetails && !stockDetails[selectedSymbol] ? <GetStockDetails ticker={selectedSymbol} onUpdate={(newStockDetails?: StockDetails, loading?: boolean, error?: any) => {
      if (newStockDetails && !stockDetails[newStockDetails.ticker]) {
        stockDetails[newStockDetails.ticker] = { details: {
          ticker: newStockDetails.ticker,
          name: newStockDetails.name,
          description: newStockDetails.description,
          isCrypto: newStockDetails.isCrypto ?? false,
        } };
        setLastChanged(new Date().toISOString());
        setNewStockDetails((prevStockDetails: Dictionary<StockDetails>) => {
          if (prevStockDetails[newStockDetails.ticker]) {
            return prevStockDetails;
          }
          const updateStockDetails = { ...prevStockDetails };
          updateStockDetails[newStockDetails.ticker] = newStockDetails;
          return updateStockDetails;
        });
      }
    }} /> : null}
    {selectedSymbol && stockDetails && stockDetails[selectedSymbol] && !stockDetails[selectedSymbol].price ? <GetStocks tickers={[selectedSymbol]} onUpdate={(stocks?: StockPrice[], loading?: boolean, error?: any) => {
      if (stocks && stocks.length === 1 && stockDetails[stocks[0].ticker]) {
        stockDetails[stocks[0].ticker].price = stocks[0];
        setLastChanged(new Date().toISOString());
      }
    }}/>
    : null}
    {modalAction !== RecordModalAction.None && (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={loadingMessage ? loadingMessage : `Adding new Entry ...`}
        >
        <RecordModal
          loadingMessage={loadingMessage}
          hideButtons={isLoading}
          lastChanged={lastChanged}
          key={'add-entry-modal'}
          data={recordDataValues}
          action={modalAction}
          title={mapLots ? 'Lots Sold' : dataType}
          onCancel={() => {
            setError(null);
            setModalAction(RecordModalAction.None);
            if (onCancel) {
              onCancel();
            }
          }}
          onSubmit={onSubmit}
          onEmbedUpdate={onUpdateData}
          isLoading={isLoading}
          error={error}
        />
      </LoadingOverlay>
    )}
  </>;
};

export default AddStockData;

/*
              Lots: {
                placeholder: "Select lots being sold",
                type: RecordType.Table,
                columns: ['Shares Sold', 'Shares Available', 'Date Acquired', 'Cost Basis/Share', 'Lot Basis', 'Unrealized Gain/Loss', 'Holding Period'].map((column: string, index: number) => ({ title: column, dataIndex: index, key: column, inputType: { recordType: RecordType.TextValue } })),
                columnData: transactions && selectedSymbol && brokerAccounts.selectedBroker && brokerAccounts.selectedAccount  && transactions[selectedSymbol] && transactions[selectedSymbol][brokerAccounts.selectedBroker] && transactions[selectedSymbol][brokerAccounts.selectedBroker][brokerAccounts.selectedAccount]
                ? transactions[selectedSymbol][brokerAccounts.selectedBroker][brokerAccounts.selectedAccount].filter((transaction: StockTransaction) => transaction.quantity > 0).map((transaction: StockTransaction) => {
                  const currentDuration = moment(new Date(transaction.date)).diff(moment(), 'days');
                  const lotQuantity = sellLotsRef.current[transaction.uuid];
                  const limitPrice = sellPrice ?? undefined;
                  return ({
                    key: { type: RecordType.Index, value: transaction.uuid },
                    0: { type: RecordType.Input, inputType: InputType.Number, value: sellLots[transaction.uuid], onUpdate: (record: any) => {} },
                    1: { type: RecordType.TextValue, value: transaction.quantity },
                    2: { type: RecordType.TextValue, value: transaction.date },
                    3: { type: RecordType.TextValue, value: transaction.price },
                    4: { type: RecordType.TextValue, value: transaction.amount },
                    5: { type: RecordType.TextValue, value: transaction.price && limitPrice ? transaction.quantity * (limitPrice - transaction.price) : '' },
                    6: { type: RecordType.TextValue, value: currentDuration > 365 ? 'Long' : 'Short' },
                  });
                }) : [],
              },
            }
*/
