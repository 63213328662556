import React, { useEffect }  from 'react';
import { PlaidLinkedInstitution, useGetPlaidAccessTokenQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type GetAccessTokenProps = {
  publicToken: string;
  institutionId: string;
  onUpdate?: (data?: PlaidLinkedInstitution, loading?: boolean, error?: any) => any;
};

const GetAccessToken: React.FC<GetAccessTokenProps> = (props: GetAccessTokenProps) => {
  const { onUpdate, publicToken, institutionId } = props;
  const { data: accessToken, loading, error } = useGetPlaidAccessTokenQuery({
    variables: { institutionId, publicToken },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(accessToken ? accessToken.getPlaidAccessToken : undefined, loading, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [accessToken, loading, error]);

  return null;
};

export default GetAccessToken;
