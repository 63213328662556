import React, { useEffect }  from 'react';
import { PlaidLinkedInstitution, useGetPlaidLinkedInstitutionsQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type GetLinkedInstitutionsProps = {
  onUpdateLinkedInstitutions: (data?: PlaidLinkedInstitution[], loading?: boolean, error?: any) => any;
};

const GetLinkedInstitutions: React.FC<GetLinkedInstitutionsProps> = (props: GetLinkedInstitutionsProps) => {
  const { onUpdateLinkedInstitutions } = props;
  const { data: linkedInstitutions, loading, error } = useGetPlaidLinkedInstitutionsQuery({
    variables: { },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    onUpdateLinkedInstitutions(linkedInstitutions ? linkedInstitutions.getPlaidLinkedInstitutions : undefined, loading, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [linkedInstitutions, loading, error]);

  return null;
};

export default GetLinkedInstitutions;
