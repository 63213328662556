import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Spin, Form, notification, Input, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

/** Presentational */
import FullWidthWrapper from '../styled/FullWidthWrapper';
import EmailConfirmFormWrapper from '../styled/EmailConfirmFormWrapper';
import { AuthStatus } from './AuthStatusEnum';
import { errorNotification, infoNotification } from '../actions/Notification';
import { Auth } from 'aws-amplify';
import Button from '../atomic/Button';

type ConfirmEmailProps = RouteComponentProps & {
  handleConfirmEmail: (email: string, confirmationCode: string) => any;
  confirmEmailStatus: AuthStatus;
  email: string;
  error: string;
  loading: boolean;
};

const ConfirmEmail: React.FC<ConfirmEmailProps> = (props: ConfirmEmailProps) => {
  const { email } = props;
  const [loading, setLoading] = useState(props.loading);
  const [redirect, setRedirect] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');

  useEffect(() => {
    if (loading !== props.loading) {
      setLoading(props.loading);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const onFinish = (event: any) => {
    // show progress spinner
    if (!loading) {
      setLoading(true);
      props.handleConfirmEmail(email, confirmationCode);
    }
  };

  const handleOnPaste = (event: React.ClipboardEvent) => {
    const code = event.clipboardData.getData('Text').trim();

    /** Update input */

    // regex to check if string is numbers only
    const reg = new RegExp('^[0-9]+$');

    if (reg.test(code) && code.length === 6) {
      setConfirmationCode(code);
      // code is a valid number
      setLoading(true);
      props.handleConfirmEmail(email, code);
    }
    else {
      errorNotification(`Confirmation code should be 6 characters long and only include digits. Invalid code was pasted: ${code}`);
      return;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateValue = !Number.isNaN(Number(event.currentTarget.value)) && event.currentTarget.value.length < 7 ? event.currentTarget.value : confirmationCode;
    if (updateValue !== confirmationCode) {
      setConfirmationCode(updateValue);
    }
  };

  const { confirmEmailStatus, error } = props;

  if (confirmEmailStatus === AuthStatus.Success) {
    notification['success']({
      message: 'Successfully confirmed!',
      description: 'You will be redirected to login in a few!',
      placement: 'topRight',
      duration: 1.5,
      onClose: () => {
        setRedirect(true);
        setLoading(false);
      },
    });
  }

  if (confirmEmailStatus === AuthStatus.Fail) {
    notification['error']({
      message: 'Invalid code',
      description: error,
      placement: 'topRight',
      duration: 1.5,
    });
    setLoading(false);
  }

  return (
    <FullWidthWrapper align='center'>
      <EmailConfirmFormWrapper onFinish={onFinish}>
        <Col md={24} lg={18}>
          <div className="full-width">
            <h2>Check your email</h2>
            <p>We've sent a six­ digit confirmation code</p>
          </div>
          <Form.Item validateStatus={error && 'error'} help={error} label=''>
            <Input
              style={{ width: '100%', marginTop: '5px' }}
              size="middle"
              placeholder="Enter confirmation code"
              onChange={handleChange}
              onPaste={handleOnPaste}
              value={confirmationCode}
            />
            <div style={{ paddingTop: '20px' }}>
              <Button type="primary" disabled={loading} htmlType="submit" >
                {loading ? <Spin indicator={<LoadingOutlined spin />} /> : 'Confirm Email'}
              </Button>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '5px' }}><span style={{ color: 'blue' }} onClick={() => {
              Auth.resendSignUp(email.toLowerCase())
              .then(() => {
                infoNotification('Confirmation code has been resent to your email.');
              })
              .catch((err: any) => {
                if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                  setRedirect(true);
                  // this.setState({ confirmEmailStatus: AuthStatus.Success });
                }
                else {
                  errorNotification(err.message);
                  // this.setState({ confirmEmailStatus: AuthStatus.Fail, error: err.message });
                }
              });
            }}>{loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Resend Confirmation Code'}</span></div>
          </Form.Item>
        </Col>
      </EmailConfirmFormWrapper>
      {redirect && <Redirect to={{ pathname: '/login' }} />}
    </FullWidthWrapper>
  );
};

export default withRouter(ConfirmEmail);
