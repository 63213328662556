import React from 'react';
import styled from 'styled-components';
import { Button, Space, Card } from 'antd';
import AuthContext from '../../context/AuthContext';
import ThemeContext from '../../context/ThemeContext';
import { ModalProps } from 'antd/lib/modal/Modal';
import '../../App.css';
import '../../index.css';
import Popconfirm from '../atomic/Popconfirm';
import LoadingOverlay from '../atomic/LoadingOverlay';
import Modal from '../generic/Modal';

/*
export const ResponsiveModalStyle = styled(Modal)`
  height: 400px;
  max-height: 100%;
  position: fixed;

  @media (max-width:600px) {
    height: 100%;
    position: fixed;
    bottom: 0px;
    margin: 0 auto;
  }
`;*/

const EmbedButtonDiv = styled.div`
  float: right;
  height: 55px;
  right: 0px;
  width: 100%;
  background: white;
  border-top: solid;
  z-index: ${(props: MobileDivProps) => props.zIndex ? props.zIndex + 2 : 1002};
`;

const MobileButtonSpace = styled(Space)`
  position: fixed;
  height: 50px;
  bottom: 10px;
  right: 10px;
  right-margin: 10px;
  bottom-margin: 10px;
`;

const MobileButtonDiv = styled.div`
  position: fixed;
  height: 55px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background: white;
  border-top: solid;
  z-index: ${(props: MobileDivProps) => props.zIndex ? props.zIndex + 2 : 1002};
`;

const MobileContent = styled.div`
  position: fixed;
  top: 0px; # hide navigation
  left: 0px;
  right: 0px;
  bottom: 55px;
  width: 100%;
  background: #fff;
  overflow: scroll;
  z-index: ${(props: MobileDivProps) => props.zIndex ? props.zIndex + 1 : 1001};
`;

type MobileDivProps = {
  zIndex?: number;
};

const MobileDiv = styled.div`
  position: fixed;
  top: 0px; # hide navigation when modal is opened
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background: #fff;
  z-index: ${(props: MobileDivProps) => props.zIndex ? props.zIndex : 1000};
`;

// TODO this didn't work, had to add style={{}} below. Why? if not needed, remove.
const StyledCard = styled(Card)`
  .ant-card-border {
    border: 0px solid #00008b;
    border-radius: 0px;
  }
`;

export enum ModalType {
  ResponsiveModal = 0, // Default
  PopConfirm,
  FullScreen,
  Embed,
}

type ResponsiveModalProps = ModalProps & {
  modalContent?: React.ReactFragment;
  modalType?: ModalType
  isLoading?: boolean;
  zIndex?: number;
  hideButtons?: boolean;
  loadingMessage?: string;
};

type ResponsiveModalState = {
  fadeIn: boolean;
};

class ResponsiveModal extends React.Component<ResponsiveModalProps, ResponsiveModalState> {
  static contextType = AuthContext;
  static themeContext = ThemeContext;

  constructor(props: ResponsiveModalProps) {
    super(props);
    this.state = {
      fadeIn: false,
    };
  }

  componentDidMount() {
    this.setState({ fadeIn: true });
  }

  render() {
    const { zIndex, loadingMessage, hideButtons, visible, isLoading, title, okText, cancelText, onCancel, onOk, okButtonProps, cancelButtonProps, modalType, modalContent } = this.props;
/*
    if (this.context.displaySettings.showFooter === visible) {
      this.context.setDisplaySettings({ showFooter: !visible, deviceTypeInfo: this.context.displaySettings.deviceTypeInfo });
    }
    */

    // modalRender={(node: React.ReactNode) => <div style={{ border: '1px solid green', borderRadius: '20px' }}>{node}</div>}
    return (
      <React.Fragment>
        {
          visible ?
           ((this.context.showFooter)
              ? (modalType === ModalType.PopConfirm
                ? <Popconfirm
                  visible={visible}
                  title={title}
                  okText={okText}
                  cancelText={cancelText}
                  onCancel={(e: any) => isLoading || !onCancel ? undefined : onCancel(e)}
                  onConfirm={(e: any) => isLoading || !onOk ? undefined : onOk(e)}
                  okButtonProps={okButtonProps ? okButtonProps : { disabled: isLoading }}
                  cancelButtonProps={cancelButtonProps ? cancelButtonProps : { disabled: isLoading }}
                  >
                    <LoadingOverlay
                      active={isLoading}
                      spinner
                      text={loadingMessage ? loadingMessage :`Updating ...`}
                    >
                      {this.props.children}
                      {modalContent ? modalContent : null}
                    </LoadingOverlay>
                  </Popconfirm>
                : (modalType === ModalType.Embed
                  ? <div>
                      <div>
                        <LoadingOverlay
                          active={isLoading}
                          spinner
                          text={loadingMessage ? loadingMessage : `${title} ...`}
                          style={{ zIndex: 40000 }}
                        >
                          <StyledCard style={{ border: '0px' }} headStyle={{ background: '#00008b', borderRadius: '5px', color: 'white', left: '0px', top: '0px', right: '0px', margin: '0px', height: '60px', textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }} title={title}>
                            {this.props.children}
                            {modalContent ? modalContent : null}
                          </StyledCard>
                        </LoadingOverlay>
                      </div>
                      {hideButtons ? null :
                        <EmbedButtonDiv>
                          <Button disabled={cancelButtonProps ? cancelButtonProps.disabled : isLoading} onClick={onCancel}>{cancelText ? cancelText : 'Cancel'}</Button>
                          <Button disabled={okButtonProps ? okButtonProps.disabled : isLoading} onClick={onOk} style={{ background: '#1890ff' }}>{okText ? okText : 'Add'}</Button>
                        </EmbedButtonDiv>}
                    </div>
                  : <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    cancelText={cancelText}
                    onCancel={(e: any) => isLoading || !onCancel ? undefined : onCancel(e)}
                    onOk={(e: any) => isLoading || !onOk ? undefined : onOk(e)}
                    okButtonProps={okButtonProps ? okButtonProps : { disabled: isLoading }}
                    cancelButtonProps={cancelButtonProps ? cancelButtonProps : { disabled: isLoading }}
                    >
                      <LoadingOverlay
                        active={isLoading}
                        spinner
                        text={loadingMessage ? loadingMessage : `Updating ...`}
                      >
                        {this.props.children}
                        {modalContent ? modalContent : null}
                      </LoadingOverlay>
                    </Modal>))
              : (<MobileDiv zIndex={this.props.zIndex} className={this.state.fadeIn ? 'fadeIn' : 'fadeOut'}>
                  <MobileContent zIndex={this.props.zIndex}>
                    <LoadingOverlay
                      active={isLoading}
                      spinner
                      text={loadingMessage ? loadingMessage : `${title} ...`}
                      style={{ zIndex: 40000 }}
                    >
                      <StyledCard style={{ border: '0px' }} headStyle={{ background: '#00008b', borderRadius: '0px', color: 'white', left: '0px', top: '0px', right: '0px', margin: '0px', height: '60px', textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }} title={title}>
                        {this.props.children}
                        {modalContent ? modalContent : null}
                      </StyledCard>
                    </LoadingOverlay>
                  </MobileContent>
                  {hideButtons ? null :
                  <MobileButtonDiv zIndex={this.props.zIndex}>
                    <MobileButtonSpace direction='horizontal' align='end'>
                      <Button style={{ zIndex, position: 'relative' }} disabled={okButtonProps ? okButtonProps.disabled : isLoading} onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                        if (onCancel) {
                          onCancel(e);
                        }
                        return false;
                      }}>{cancelText ? cancelText : 'Cancel'}</Button>
                      <Button disabled={okButtonProps ? okButtonProps.disabled : isLoading} onClick={onOk} style={{ background: '#1890ff', zIndex, position: 'relative' }}>{okText ? okText : 'Add'}</Button>
                    </MobileButtonSpace>
                  </MobileButtonDiv>}
              </MobileDiv>))
            : null
          }
      </React.Fragment>
    );
  }
}

export default ResponsiveModal;
