import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import { PlaidAccount, PlaidHolding, PlaidHoldings, PlaidInvestment, PlaidInvestmentTransaction, PlaidLinkedInstitution, PlaidSecurity, PlaidTransactions } from '../../../graphql/generated/graphql';
import DivAlign, { DivPosition } from '../../atomic/DivAlign';
import { CenteredDiv, StyledDiv } from '../../styled/CenteredDiv';
import { RecordType } from '../../table/DataTypes';
import { getBrokerStockDataFlattened, Stock, StockHolding, StockMergedTransaction, StockOrder, StockTransaction, SymbolStockData, TransactionAction } from '../StockTypes';
import { EditableColumnProps } from '../../table/EditableTable';
import { Dictionary } from 'lodash';
import Button from '../../atomic/Button';
import Table from '../../table/Table';
import Tooltip from '../../atomic/Tooltip';
import BrokerList, { AccountType } from '../BrokerList';
import { infoNotification } from '../../actions/Notification';
import ImportStockCsv from './ImportStockCsvSplit';
import ImportInvestmentTransactions from './ImportInvestmentTransactions';
import ExclamationIcon from '../../icons/ExlamationIcon';
import ImportHoldings from './ImportHoldings';

type ImportHoldingData = {
  key: string;
  row: number;
  broker: string;
  symbol: string;
  name: string;
  account: string;
  cost_basis?: number;
  price: number | string;
  value?: number;
  quantity?: number;
  holding: PlaidHolding;
};

const convertPlaidInvestments = (investments : Dictionary<PlaidInvestment>): Dictionary<StockTransaction[]> => {
  const brokers = Object.keys(investments);
  const transactions: Dictionary<StockTransaction[]> = {};
  brokers.forEach((broker: string) => {
    const securities: Dictionary<PlaidSecurity> = Object.assign({}, ...investments[broker].securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
    investments[broker].transactions.forEach((transaction: PlaidInvestmentTransaction) => {
      if (transaction.subtype && ['dividend', 'transfer', 'interest', 'withdrawal', 'deposit', 'miscellaneous fee'].includes(transaction.subtype)) {
        return;
      }
      if (!transaction.securityId) {
        console.log(`No securityId found. Skip transaction ${transaction.transactionId}`);
        return;
      }
      if (!transaction.amount) {
        console.log(`No amount found. Skip transaction ${transaction.transactionId} type ${transaction.type} subtype ${transaction.subtype}`);
        return;
      }
      if (!transaction.quantity) {
        console.log(`No quantity found. Skip transaction ${transaction.transactionId} type ${transaction.type} subtype ${transaction.subtype}`);
        return;
      }
      if (!transaction.date) {
        console.log(`No date found. Skip transaction ${transaction.transactionId}`);
        return;
      }
      const security = securities[transaction.securityId];
      if (!security.symbol) {
        console.log(`No symbol found for security ${transaction.securityId}. Skip transaction ${transaction.transactionId}`);
        return;
      }
      if (!transactions[security.symbol]) {
        transactions[security.symbol] = [];
      }
      const posAmount = transaction.amount < 0 ? -transaction.amount : transaction.amount;
      const posQty = transaction.quantity < 0 ? -transaction.quantity : transaction.quantity;
      const isBought = transaction.subtype === 'buy' ? true : (transaction.subtype === 'sell' ? false : (transaction.amount < 0 ? false : true));
      const addTransaction: StockTransaction = {
        uuid: uuid(),
        broker,
        accountId: transaction.accountId,
        date: transaction.date,
        transactionId: transaction.transactionId,
        securityId: transaction.securityId,
        action: isBought ? TransactionAction.Bought : TransactionAction.Sold,
        symbol: security.symbol,
        type: transaction.type ? transaction.type : undefined,
        subtype: transaction.subtype ? transaction.subtype : undefined,
        amount: isBought ? posAmount : -posAmount,
        quantity: isBought ? posQty : -posQty,
        fees: transaction.fees ? transaction.fees : undefined,
        price: transaction.price ? transaction.price : undefined,
        name: transaction.name ? transaction.name : undefined,
      };
      transactions[security.symbol].push(addTransaction);
    });
  });
  return transactions;
};

const convertPlaidHoldings = (plaidHoldings : Dictionary<PlaidHoldings>) => {
  const brokers = Object.keys(plaidHoldings);
  const holdings: Dictionary<StockHolding[]> = {};
  brokers.forEach((broker: string) => {
    const securities: Dictionary<PlaidSecurity> = Object.assign({}, ...plaidHoldings[broker].securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
    plaidHoldings[broker].holdings.forEach((holding: PlaidHolding) => {
      /*
      if (!holding.securityId
        || !holding.accountId
        || !holding.costBasis
        || !holding.price
        || !holding.priceDate
        || !holding.quantity
        || !holding.securityId
        || !holding.value) {
        console.log(`missing data. Skip transaction holding`);
        return;
      }
      */
      const symbol: string = holding.securityId
        ? (securities[holding.securityId].symbol ?? '--')
        : '--';
      // const security = holding.securityId ? securities[holding.securityId] : { symbol: '--' };
      /*
      if (!security.symbol) {
        console.log(`No symbol found for security ${holding.securityId}. Skip holding.`);
        return;
      } */
      if (!holdings[symbol]) {
        holdings[symbol] = [];
      }
      const price = holding.costBasis && holding.quantity ? holding.costBasis / holding.quantity : null;
      const addHolding: StockHolding = {
        broker,
        accountId: holding.accountId ?? '--',
        priceDate: holding.priceDate ?? '--',
        securityId: holding.securityId ?? '--',
        symbol,
        costBasis: holding.costBasis === undefined || holding.costBasis === null ? null : holding.costBasis,
        price,
        quantity: holding.quantity === undefined || holding.quantity === null ? null : holding.quantity,
        value: holding.value === undefined || holding.value === null ? null : holding.value,
      };
      holdings[symbol].push(addHolding);
    });
  });
  return holdings;
};

type ImportBrokerProps = {
  existingInvestmentTransactions: SymbolStockData<StockTransaction>;
  existingHoldings: SymbolStockData<StockHolding>;
  existingOrders: SymbolStockData<StockOrder>;
  importData?: { file: string; columns: string[]; rows: string[][] };
  linkedInstitutions?: PlaidLinkedInstitution[];
  stockDetails: Dictionary<Stock>;
  onClose: () => void;
  onUpdateLinkedInstitutions: (linkedInstitutions: PlaidLinkedInstitution[]) => void;
  onImportInvestmentTransactions: (addInvestments: Dictionary<StockTransaction[]>, removeTransactions: Dictionary<StockTransaction[]>, importSecurities: Dictionary<Dictionary<PlaidSecurity>>, importAccounts: Dictionary<Dictionary<PlaidAccount>>) => void;
  onUpdateHoldings: (holdings: Dictionary<StockHolding[]>, removedHoldings: Dictionary<StockHolding[]>, importSecurities: Dictionary<Dictionary<PlaidSecurity>>, importAccounts: Dictionary<Dictionary<PlaidAccount>>) => void;
  onUpdateOrders: (addOrders: Dictionary<StockOrder[]>, removeOrders: Dictionary<StockOrder[]>) => void;
  onUpdateStocks: (updateStocks: Stock[]) => void;
};
const ImportBroker: React.FC<ImportBrokerProps> = (props: ImportBrokerProps) => {
  const { existingInvestmentTransactions, linkedInstitutions, stockDetails, onUpdateLinkedInstitutions, onClose, onImportInvestmentTransactions, onUpdateHoldings, onUpdateStocks } = props;
  const [selectedHoldings, setSelectedHoldings] = useState<any[]>([] as any[]);
  const [importData, setImportData] = useState<{ file: string; columns: string[]; rows: string[][] } | undefined>(props.importData);
  // const [selectedTransactions, setSelectedTransactions] = useState<any[]>([] as any[]);
  // const [transactionHistory, setTransactionHistory] = useState<PlaidInvestment | undefined>(undefined);
  // const [importFromBroker, setImportFromBroker] = useState<string | undefined>(undefined);
  const [holdings, setHoldings] = useState<Dictionary<PlaidHoldings>>({});
  const [removedHoldings, setRemovedHoldings] = useState<Dictionary<PlaidHoldings>>({});
  const [changedHoldings, setChangedHoldings] = useState<Dictionary<Dictionary<PlaidHoldings>>>({});
  const [investments, setInvestements] = useState<Dictionary<StockTransaction[]>>({});
  const [accounts, setAccounts] = useState<Dictionary<PlaidAccount[]>>({});
  const [orders, setOrders] = useState<Dictionary<StockOrder[]>>({});
  // const [showExistingTransactions, setShowExistingTransactions] = useState<boolean>(false);
  const [showExistingHoldings, setShowExistingHoldings] = useState<boolean>(false);
  const [allHoldings, setAllHoldings] = useState<Dictionary<StockHolding[]>>({});
  const [securities, setSecurities] = useState<Dictionary<Dictionary<PlaidSecurity>>>({}); // Broker / securityId / PlaidSecrity
  useEffect(() => {
    if (props.existingHoldings) {
      const initAllHoldings = Object.assign({}, ...Object.keys(props.existingHoldings).map((symbol: string) => ({ [symbol]: getBrokerStockDataFlattened(props.existingHoldings[symbol]) })));
      setAllHoldings(initAllHoldings);
    }
  },        [props]);
  /*
  const [fidelityUrl, setFidelityUrl] = useState<{
    account: string;
    cusip: string;
    asset_id: string;
    symbol: string;
    FBSI_SYMBOL_ID: string;
    QUERY_FBSI_SYMBOL_ID: string;
  } | undefined>(undefined);
  */

  const allTransactions: Dictionary<StockTransaction[]> = {};
  Object.keys(existingInvestmentTransactions)
    .forEach((symbol: string) => {
      allTransactions[symbol] = getBrokerStockDataFlattened(existingInvestmentTransactions[symbol]);
      allTransactions[symbol].forEach((merged: StockTransaction) => {
        if (merged.transactionIds) {
          merged.transactionIds.forEach((merge: StockMergedTransaction) => {
            if (merge.transactionId !== merged.transactionId) {
              const addMerged = { ...merged };
              const addMergedUuid = merge.uuid ?? uuid();
              addMerged.quantity = merge.quantity;
              addMerged.fees = merge.fees;
              addMerged.uuid = addMergedUuid;
              addMerged.transactionId = merge.transactionId ?? addMergedUuid;
              allTransactions[symbol].push(addMerged);
            }
          });
        }
      });
    });

  const filters: Dictionary<Dictionary<string[]>> =
    {
      brokers: {
        bankName: [] as string[],
        account: [] as string[],
      },
      holdings: {
        broker: [] as string[],
        account: [] as string[],
        symbol: [] as string[],
      },
      investments: {
        account: [] as string[],
        symbol: [] as string[],
        type: [] as string[],
        subtype: [] as string[],
        broker: [] as string[],
      },
    };

  const isHoldingRecordChanged = (record: any) => {
    const existingHold = allHoldings[record.symbol] ? allHoldings[record.symbol].find((existingHolding: StockHolding) => existingHolding.broker === record.broker && existingHolding.accountId === record.holding.accountId) : undefined;
    return !existingHold || existingHold.quantity !== record.quantity || existingHold.price !== record.price ? existingHold : undefined;
  };

  const isHoldingRecordDisabled = (record: any) => {
    if (!record.symbol) {
      return true;
    }
    if (!allHoldings[record.symbol]) {
      return false;
    }
    if (isHoldingRecordChanged(record) !== undefined) {
      return false;
    }
    return true;
  };

  const holdingsData: ImportHoldingData[] = [];
  let holdingIndex = 1;
  Object.keys(holdings)
    .forEach((broker: string) => {
      if (!filters['holdings']['broker'].find((holdingsBroker: string) => holdingsBroker === broker)) {
        filters['holdings']['broker'].push(broker);
      }
      const brokerHoldings = holdings[broker];
      const securities = Object.assign({}, ...brokerHoldings.securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
      const accounts = Object.assign({}, ...brokerHoldings.accounts.map((account: PlaidAccount) => ({ [account.accountId] : account })));
      brokerHoldings.holdings.forEach((holding: PlaidHolding) => {
        const security = holding.securityId ? securities[holding.securityId] : {
          symbol: '--',
          name: '--',
        };
        const holdingData: ImportHoldingData = {
          key: `${security.symbol}-${broker}-${holding.accountId}`,
          row: holdingIndex,
          broker,
          symbol: security.symbol,
          name: security.name,
          account: holding.accountId ? accounts[holding.accountId].name : '--',
          cost_basis: holding.costBasis ?? undefined,
          price: holding.quantity && holding.costBasis ? holding.costBasis / holding.quantity : '--',
          value: holding.value ?? undefined,
          quantity: holding.quantity ?? undefined,
          holding,
        };
        const oldHolding = isHoldingRecordChanged(holdingData);
        if (oldHolding) {
          holdingData['oldHolding'] = oldHolding;
        }
        else if (!showExistingHoldings) {
          return;
        }
        if (!filters['holdings']['symbol'].find((symbol: string) => symbol === holdingData.symbol)) {
          filters['holdings']['symbol'].push(holdingData.symbol);
        }

        if (!filters['holdings']['account'].find((account: string) => account === holdingData.account)) {
          filters['holdings']['account'].push(holdingData.account);
        }
        holdingsData.push(holdingData);
        holdingIndex += 1;
      });
    });
  /*
  const allHoldings = convertPlaidHoldings(holdings);

  Object.keys(allHoldings)
    .forEach((broker: string) => {
      if (!filters['holdings']['broker'].find((holdingsBroker: string) => holdingsBroker === broker)) {
        filters['holdings']['broker'].push(broker);
      }
      // const brokerHoldings = allHoldings[broker].holdings;
      const brokerAccounts = Object.assign({}, ...holdings[broker].accounts.map((account: PlaidAccount) => ({ [account.accountId] : account })));
      allHoldings[broker].forEach((holding: StockHolding) => {
        const security = holding.securityId ? holdings.securities[holding.securityId] : {
          symbol: '--',
          name: '--',
        };
        const holdingData = {
          key: holdingIndex,
          row: holdingIndex,
          broker,
          symbol: security.symbol,
          name: security.name,
          account: holding.accountId, // ? accounts[holding.accountId].name : '--',
          cost_basis: holding.costBasis,
          price: holding.price,
          value: holding.value,
          quantity: holding.quantity,
          holding,
        };
        if (!filters['holdings']['symbol'].find((symbol: string) => symbol === holdingData.symbol)) {
          filters['holdings']['symbol'].push(holdingData.symbol);
        }

        if (!filters['holdings']['account'].find((account: string) => account === holdingData.account)) {
          filters['holdings']['account'].push(holdingData.account);
        }
        holdingsData.push(holdingData);
        holdingIndex += 1;
      });
    });
  */

  const holdingsColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      render: (text: string, record: any, index: number) => (
        <div>{index}</div>
      ),
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      filters: filters['holdings']['broker'].map((broker: string) => ({ text: broker, value: broker })),
      onFilter: (value, record) => {
        return record.broker.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.broker.toLowerCase() < two.broker.toLowerCase() ? -1 : 1);
        },
        multiple: 1,
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      filters: filters['holdings']['account'].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        return record.account.indexOf(value) === 0;
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      filters: ['crypto', 'stocks', ...filters['holdings']['symbol'].sort((a: string, b: string) => a < b ? -1 : 1)].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        if (!record.symbol) {
          return false;
        }
        if (value === 'crypto') {
          return record.symbol.includes('CUR:');
        }
        if (value === 'stocks') {
          return !record.symbol.includes('CUR:');
        }
        if (record.symbol === null) {
          return value === record.symbol;
        }
        return record.symbol.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return one.symbol && two.symbol ? (one.symbol.toLowerCase() < two.symbol.toLowerCase() ? -1 : 1) : (one.symbol ? -1 : 1);
        },
        multiple: 2,
      },
      inputType: { recordType: RecordType.Text },
      defaultSortOrder: 'ascend',
      render: (text: string, record: any) => (
        <div>{record.symbol}{record.oldHolding && allHoldings[record.symbol] ? <Tooltip title='Value has changed'><ExclamationIcon color='red' /></Tooltip> : ''}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (one, two) => {
          return one.name && two.name ? (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1) : (one.name ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => (
        record.name && record.name.length > 50 ? <Tooltip title={record.name}>
        <div>{record.name.substring(0, 50)}...</div>
        </Tooltip> : <div>{record.name}</div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        return <>
          <div>{text}</div>
          {record.oldHolding ? <StyledDiv style={{ color: 'red' }}>({record.oldHolding.quantity})</StyledDiv> : null}
        </>;
      },
    },
    {
      title: 'Cost Basis',
      dataIndex: 'cost_basis',
      key: 'cost_basis',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        return <>
          <div>{text}</div>
          {record.oldHolding ? <StyledDiv style={{ color: 'red' }}>({record.oldHolding.costBasis})</StyledDiv> : null}
        </>;
      },
    },
    {
      title: 'Average Price',
      dataIndex: 'price',
      key: 'price',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any) => {
        return <>
          <div>{Number(record.price).toFixed(2)}</div>
          {record.oldHolding ? <StyledDiv style={{ color: 'red' }}>({Number(record.oldHolding.price).toFixed(2)})</StyledDiv> : null}
        </>;
      },
    },
    {
      title: 'Current Value',
      dataIndex: 'value',
      key: 'value',
      sorter: {
        compare: (one, two) => {
          return one.value !== undefined && two.value !== undefined ? (one.value < two.value ? -1 : 1) : (one.value !== undefined ? -1 : 1);
        },
        multiple: 4,
      },      inputType: { recordType: RecordType.Text },
    },
  ];

  /* dataSource.forEach((d: any, index: number) => {
    const newDocumentData = d.newRow
    ? { key: `document-${index + 1}`, id: index + 1, name: d.name, description: d.description, entries: d.entries, newRow: true }
    : { key: `document-${index + 1}`, id: index + 1, name: d.name, description: d.description, entries: d.entries };
    documentData.push(newDocumentData);
  });
  */

  const csvColumns = importData ? importData.columns.map((column: string, index: number) => ({
    title: column,
    dataIndex: index,
    key: column,
    inputType: { recordType: RecordType.Index },
  })) : [];

  const csvData = importData ? importData.rows
  .map((row: string[]) => {
    return Object.assign(
      {},
      ...row.map((data: string, index: number) => {
        return { [index] : data };
      }));
  }) : [];

  const updateAccounts = (broker: string, importAccounts: PlaidAccount[]) => {
    setAccounts((prevAccounts) => {
      const updatePrevAccounts = { ...prevAccounts };
      let accountsUpdated = false;
      importAccounts.forEach((importAccount) => {
        if (!updatePrevAccounts[broker]) {
          accountsUpdated = true;
          updatePrevAccounts[broker] = [];
        }
        if (!updatePrevAccounts[broker].find((importedAccount: PlaidAccount) => importedAccount.accountId === importAccount.accountId)) {
          accountsUpdated = true;
          updatePrevAccounts[broker].push(importAccount);
        }
      });
      return accountsUpdated ? updatePrevAccounts : prevAccounts;
    });
  };

  return <>
    {importData ? null :
    <BrokerList
      key='broker-list'
      accountTypes={[AccountType.Investment]}
      linkedInstitutions={linkedInstitutions}
      stockDetails={stockDetails}
      onUpdateLinkedInstitutions={onUpdateLinkedInstitutions}
      onUpdateHoldings={(broker: string, importedHoldings: PlaidHoldings) => {
        console.log(`holdings for ${broker}`);
        const importHoldings = convertPlaidHoldings({ [broker]: importedHoldings });
        const updatedHoldings: SymbolStockData<StockHolding> = {};
        const removedHoldings: StockHolding[] = [];
        Object.keys(allHoldings).forEach((symbol: string) => {
          allHoldings[symbol].forEach((hold: StockHolding) => {
            const matchedHolding = importHoldings[hold.symbol] ? importHoldings[hold.symbol].find((importHold: StockHolding) => {
              return (importHold.accountId === hold.accountId
                && importHold.broker === hold.accountId
                && importHold.symbol === hold.symbol);
            }) : undefined;
            if (!matchedHolding) {
              removedHoldings.push(hold);
            }
            else if (matchedHolding.quantity !== hold.quantity) {
              updatedHoldings[symbol][broker][hold.accountId].push(hold);
            }
          });
        });
        setHoldings((prevHoldings) => {
          const updatePrevHoldings = { ...prevHoldings };
          updatePrevHoldings[broker] = importedHoldings;
          return updatePrevHoldings;
        });
        setSecurities((prevSecurities) => {
          const updateSecurities = { ...prevSecurities };
          let updated = false;
          importedHoldings.securities.forEach((importSecurity: PlaidSecurity) => {
            if (!updateSecurities[broker]) {
              updateSecurities[broker] = {};
            }
            if (!updateSecurities[broker][importSecurity.securityId]) {
              updateSecurities[broker][importSecurity.securityId] = importSecurity;
              updated = true;
            }
          });
          return updated ? updateSecurities : prevSecurities;
        });
        updateAccounts(broker, importedHoldings.accounts);
        infoNotification(`Successfully retrieved holdings from ${broker}`);
      }}
      onUpdateInvestmentTransactions={(broker: string, updatedInvestments: PlaidInvestment) => {
        console.log(`investments for ${broker}`);
        const stockTransactions = convertPlaidInvestments({ [broker]: updatedInvestments });
        const updatedStockTransactions: StockTransaction[] = ([] as StockTransaction[]).concat(...Object.keys(stockTransactions).map((symbol: string) => stockTransactions[symbol]));
        setInvestements((prevInvestments) => {
          const updatePrevInvestments = { ...prevInvestments };
          updatePrevInvestments[broker] = updatedStockTransactions;
          return updatePrevInvestments;
        });
        setSecurities((prevSecurities) => {
          const updateSecurities = { ...prevSecurities };
          let updated = false;
          updatedInvestments.securities.forEach((importSecurity: PlaidSecurity) => {
            if (!updateSecurities[broker]) {
              updateSecurities[broker] = {};
            }
            if (!updateSecurities[broker][importSecurity.securityId]) {
              updateSecurities[broker][importSecurity.securityId] = importSecurity;
              updated = true;
            }
          });
          return updated ? updateSecurities : prevSecurities;
        });
        updateAccounts(broker, updatedInvestments.accounts);
        infoNotification(`Successfully retrieved transaction history from ${broker}`);
      }}
      onUpdateTransactions={(broker: string, transactions: PlaidTransactions) => {}}
      onUpdateOrders={(broker: string, updatedOrders: StockOrder[]) => {
        console.log(`open orders for ${broker}`);
        setOrders((prevOrders) => {
          const updatePrevOrders = { ...prevOrders };
          updatePrevOrders[broker] = updatedOrders;
          return updatePrevOrders;
        });
        infoNotification(`Successfully retrieved transaction history from ${broker}`);
      }}
      onUpdateStockDetails={onUpdateStocks}
      onClose={onClose}
    />}
    {importData
      ? <>
        <DivAlign hPosition={DivPosition.Right}>
          <Button onClick={() => props.onClose()}>Close</Button>
        </DivAlign>
        <CenteredDiv bold>Import from file: {importData.file}</CenteredDiv>
        <ImportStockCsv linkedInstitutions={linkedInstitutions ?? []} importData={importData} onUpdateImportData={(updateImportData: { columns: string[]; rows: string[][] }) => setImportData({ file: importData!.file, columns: updateImportData.columns, rows: updateImportData.rows })} onCancel={() => {}} />
        <Table
          pagination={false}
          className='.antd-documents-table'
          size='small'
          // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
          columns={csvColumns}
          dataSource={csvData}
          bordered={true}
        />
      </>
      : null}
    {/* transactionHistory && importFromBroker
      ? <ImportStocks
        broker={importFromBroker}
        stocks={Object.keys(stocks).map((stock: string) => stocks[stock])}
        existingTransactions={transactionHistory}
        newTransactions={transactionHistory}
        onImport={() => {}} />
    : null */}
    <CenteredDiv>
      <br />
      <ImportHoldings
        existingHoldings={props.existingHoldings}
        importedHoldings={holdings}
        onUpdateHoldings={onUpdateHoldings} />
      {/*
      Object.keys(holdings).length > 0 ? <>
        <CenteredDiv bold>Holdings:</CenteredDiv>
        <DivAlign hPosition={DivPosition.Right}>
          <Button onClick={() => setShowExistingHoldings(!showExistingHoldings)}>{showExistingHoldings ? 'Hide' : 'Show'} imported holdings</Button>
          <Button onClick={() => {
            const changedSelectedHoldings = selectedHoldings.filter((selectedHold: any) => selectedHold.dataChanged);
            if (changedSelectedHoldings.length === 0) {
              errorNotification('Please select at least one holding to import');
              return;
            }
            const selectedPlaidHoldings: Dictionary<PlaidHoldings> = {};
            changedSelectedHoldings.forEach((holding: any) => {
              if (!selectedPlaidHoldings[holding.broker]) {
                selectedPlaidHoldings[holding.broker] = {
                  accounts: holdings[holding.broker].accounts,
                  securities: holdings[holding.broker].securities,
                  holdings: [],
                };
              }
              selectedPlaidHoldings[holding.broker].holdings.push(holding.holding);
            });
            const importHoldings = convertPlaidHoldings(selectedPlaidHoldings);

            // Add items that were imported but no longer present
            const updatedHoldings: SymbolStockData<StockHolding> = {};
            const removedHoldings: StockHolding[] = [];
            Object.keys(allHoldings).forEach((symbol: string) => {
              allHoldings[symbol].forEach((hold: StockHolding) => {
                const matchedHolding = importHoldings[hold.symbol].find((importHold: StockHolding) => {
                  return (importHold.accountId === hold.accountId
                    && importHold.broker === hold.accountId
                    && importHold.symbol === hold.symbol);
                });
                if (!matchedHolding) {
                  removedHoldings.push(hold);
                }
                else if (matchedHolding.quantity !== hold.quantity) {
                  updatedHoldings[symbol][hold.broker][hold.accountId].push(hold);
                }
              });
            });
            const importSecurities: Dictionary<Dictionary<PlaidSecurity>> = {};
            Object.keys(selectedPlaidHoldings).forEach((broker: string) => {
              importSecurities[broker] = Object.assign({}, ...selectedPlaidHoldings[broker].securities.map((security: PlaidSecurity) => ({ [security.securityId]: security })));
            });
            const importAccounts: Dictionary<Dictionary<PlaidAccount>> = {};
            Object.keys(selectedPlaidHoldings).forEach((broker: string) => {
              importAccounts[broker] = Object.assign({}, ...selectedPlaidHoldings[broker].accounts.map((account: PlaidAccount) => ({ [account.accountId]: account })));
            });
            onUpdateHoldings(importHoldings, {}, importSecurities, importAccounts);
          }}>Import Selected Holdings</Button>
        </DivAlign>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: [
              ...selectedHoldings.map((selectedHolding: any) => selectedHolding.key),
              ...holdingsData.filter((row: any) => {
                return row.dataChanged === undefined;
              }).map((selectedHolding: any) => selectedHolding.key),  // existingHoldings[row.symbol] && allHoldings[row.symbol].find((existingHolding: StockHolding) => existingHolding.broker === row.broker && existingHolding.accountId === row.holding.accountId)).map((selectedHolding: any) => selectedHolding.key),
            ],
            onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              setSelectedHoldings(selectedRows);
            },
            getCheckboxProps: (record: any) => ({
              name: record.row,
              disabled: isHoldingRecordDisabled(record),
            }),
          }}
          pagination={false}
          className='.antd-documents-table'
          size='small'
          // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
          columns={holdingsColumns}
          dataSource={holdingsData}
          bordered={true}
        /></> : null */}
      {Object.keys(investments).length > 0
        ? <ImportInvestmentTransactions
          accounts={Object.assign({}, ...Object.keys(accounts).map((broker: string) => ({ [broker] : Object.assign({}, ...accounts[broker].map((account: PlaidAccount) => ({ [account.accountId] : account }))) })))}
          existingTransactions={allTransactions}
          transactions={investments}
          onImportInvestmentTransactions={(addInvestments: Dictionary<StockTransaction[]>, removeInvestments: Dictionary<StockTransaction[]>, accounts?: Dictionary<Dictionary<PlaidAccount>>) => onImportInvestmentTransactions(addInvestments, removeInvestments, securities, accounts ?? {})}
          onClose={props.onClose} />
        : null}
    </CenteredDiv>
  </>;
};

export default ImportBroker;

  /*
  const investmentData: any[] = [];
  let investmentIndex = 1;
  Object.keys(investments)
    .forEach((broker: string) => {
      if (!filters['investments']['broker'].find((investmentBroker: string) => investmentBroker === broker)) {
        filters['investments']['broker'].push(broker);
      }
      const brokerInvestments = investments[broker];
      const securities = Object.assign({}, ...brokerInvestments.securities.map((security: PlaidSecurity) => ({ [security.securityId] : security })));
      const accounts = Object.assign({}, ...brokerInvestments.accounts.map((account: PlaidAccount) => ({ [account.accountId] : account })));
      brokerInvestments.transactions.forEach((transaction: PlaidInvestmentTransaction) => {
        const security = transaction.securityId ? securities[transaction.securityId] : {
          symbol: '--',
          name: '--',
        };
        if (!showExistingTransactions) {
          if (allTransactions[security.symbol] && allTransactions[security.symbol].find((existingTransactions: StockTransaction) => existingTransactions.transactionId === transaction.transactionId)) {
            return;
          }
        }
        if (transaction.subtype === 'dividend') {
          return;
        }
        const transactionData = {
          key: investmentIndex,
          row: investmentIndex,
          broker,
          account: accounts[transaction.accountId].name,
          symbol: security.symbol,
          name: security.name,
          date: transaction.date,
          quantity: transaction.quantity,
          price: transaction.price,
          amount: transaction.amount,
          fees: transaction.fees,
          type: transaction.type,
          subtype: transaction.subtype,
          transaction,
        };
        investmentData.push(transactionData);
        if (!filters['investments']['account'].find((account: string) => account === transactionData.account)) {
          filters['investments']['account'].push(transactionData.account);
        }
        if (transactionData.symbol && !filters['investments']['symbol'].find((symbol: string) => symbol === transactionData.symbol)) {
          filters['investments']['symbol'].push(transactionData.symbol);
        }
        if (transactionData.type && !filters['investments']['type'].find((investmentType: string) => investmentType === transactionData.type)) {
          filters['investments']['type'].push(transactionData.type);
        }
        if (transactionData.subtype && !filters['investments']['subtype'].find((subtype: string) => subtype === transactionData.subtype)) {
          filters['investments']['subtype'].push(transactionData.subtype);
        }
        investmentIndex += 1;
      });
    });

  const investmentsColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 40,
      render: (value: string, record: any, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: 'Broker',
      dataIndex: 'broker',
      key: 'broker',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['broker'].map((broker: string) => ({ text: broker, value: broker })),
      onFilter: (value, record) => {
        return record.broker.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          return (one.broker.toLowerCase() < two.broker.toLowerCase() ? -1 : 1);
        },
        multiple: 1,
      },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['account'].map((account: string) => ({ text: account, value: account })),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.account.indexOf(value) === 0,
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      inputType: { recordType: RecordType.Text },
      filters: ['crypto', 'stocks', ...filters['investments']['symbol'].sort((a: string, b: string) => a < b ? -1 : 1)].map((account: string) => ({ text: account, value: account })),
      onFilter: (value, record) => {
        if (value === 'crypto') {
          return record.symbol.includes('CUR:');
        }
        if (value === 'stocks') {
          return !record.symbol.includes('CUR:');
        }
        return record.symbol.indexOf(value) === 0;
      },
      sorter: {
        compare: (one, two) => {
          if (!one.symbol) {
            return 1;
          }
          if (!two.symbol) {
            return -1;
          }
          return (one.symbol.toLowerCase() < two.symbol.toLowerCase() ? -1 : 1);
        },
        multiple: 2,
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (one, two) => {
          return (moment(one.date) < moment(two.date) ? -1 : 1);
        },
        multiple: 3,
      },
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Fees',
      dataIndex: 'fees',
      key: 'fees',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['type'].map((investmentType: string) => ({ text: investmentType, value: investmentType })),
      onFilter: (value, record) => {
        return record.type.indexOf(value) === 0;
      },
    },
    {
      title: 'Subtype',
      dataIndex: 'subtype',
      key: 'subtype',
      inputType: { recordType: RecordType.Text },
      filters: filters['investments']['subtype'].map((subtype: string) => ({ text: subtype, value: subtype })),
      onFilter: (value, record) => {
        return record.subtype.indexOf(value) === 0;
      },
    },
  ];

  // return
      Object.keys(investments).length > 0 ? <>
        <CenteredDiv bold>Investments:</CenteredDiv>
        <DivAlign hPosition={DivPosition.Right}>
          <Button onClick={() => setShowExistingTransactions(!showExistingTransactions)}>{showExistingTransactions ? 'Hide' : 'Show'} imported transactions</Button>
          <Button onClick={() => props.onClose()} style={{ color: 'red' }} >Resolve Conflicts</Button>
          <Button onClick={() => {
            if (selectedTransactions.length === 0) {
              errorNotification('Please select at least one transaction to import');
              return;
            }
            const selectedPlaidTransactions: Dictionary<PlaidInvestment> = {};
            selectedTransactions.forEach((transaction: any) => {
              if (!selectedPlaidTransactions[transaction.broker]) {
                selectedPlaidTransactions[transaction.broker] = {
                  accounts: investments[transaction.broker].accounts,
                  securities: investments[transaction.broker].securities,
                  transactions: [],
                };
              }
              if (!allTransactions[transaction.symbol] || !allTransactions[transaction.symbol].find((existingTrans: StockTransaction) => existingTrans.transactionId === transaction.transaction.transactionId)) {
                selectedPlaidTransactions[transaction.broker].transactions.push(transaction.transaction);
              }
            });
            const importTransactions = convertPlaidInvestents(selectedPlaidTransactions);
            const importSecurities: Dictionary<Dictionary<PlaidSecurity>> = {};
            Object.keys(selectedPlaidTransactions).forEach((broker: string) => {
              importSecurities[broker] = Object.assign({}, ...selectedPlaidTransactions[broker].securities.map((security: PlaidSecurity) => ({ [security.securityId]: security })));
            });
            const importAccounts: Dictionary<Dictionary<PlaidAccount>> = {};
            Object.keys(selectedPlaidTransactions).forEach((broker: string) => {
              importAccounts[broker] = Object.assign({}, ...selectedPlaidTransactions[broker].accounts.map((account: PlaidAccount) => ({ [account.accountId]: account })));
            });
            onImportInvestmentTransactions(importTransactions, importSecurities, importAccounts);
          }}>Import Selected Investments</Button>
        </DivAlign>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: [
              ...selectedTransactions.map((selectedTransaction: any) => selectedTransaction.key),
              ...investmentData.filter((row: any) => existingInvestmentTransactions[row.symbol] && allTransactions[row.symbol].find((existingTransaction: StockTransaction) => existingTransaction.transactionId === row.transaction.transactionId)).map((selectedTransaction: any) => selectedTransaction.key),
            ],
            onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              setSelectedTransactions(selectedRows);
            },
            getCheckboxProps: (record: any) => ({
              name: record.row,
              disabled: !record.symbol
                || (existingInvestmentTransactions[record.symbol]
                    && allTransactions[record.symbol].find((existingTransaction: StockTransaction) => {
                      if (existingTransaction.accountId !== record.transaction.accountId) {
                        return false;
                      }
                      if (existingTransaction.transactionId === record.transaction.transactionId) {
                        return true;
                      }
                      return false;
                    }) !== undefined),
            }),
          }}
          pagination={false}
          className='.antd-documents-table'
          size='small'
          columns={investmentsColumns }
          dataSource={investmentData}
          bordered={true}
        /></> : null */
