import gql from 'graphql-tag';
import DataCollectionData from './DataCollectionData';
import DataObject from './DataObject';
import DataEntry from './DataEntry';

export default gql`
  fragment DataCollection on DataCollection {
    root
    {
      ...DataCollectionData
    }
    collections
    {
      ...DataCollectionData
    }
    objects
    {
      ...DataObject
    }
    entries
    {
      ...DataEntry
    }
  }
  ${DataCollectionData}
  ${DataCollectionData}
  ${DataObject}
  ${DataEntry}
`;
