import React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Space } from 'antd';
import { DraggableItemType } from './DraggableContext';
import DraggableItemView from './DraggableItemView';

type DroppableCollectionProps = {
  items: DraggableItemType[];
  collectionId: string;
  dragDisabled?: boolean;
  dropDisabled?: boolean;
  clone?: boolean;
  info?: JSX.Element;
};

export const ItemList = styled.div`
  padding: 8px;
`;

export const DraggableItemDiv = styled.div`
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  background: white;
`;

const getListStyle = (isDraggingOver: boolean, hasInfo: boolean):{} => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: `${hasInfo ? '20px' : '4px'} 8px 4px`,
  width: '100%',
  borderRadius: '4px',
  minHeight: 80,
});

const DroppableCollection: React.FC<DroppableCollectionProps> = (props: DroppableCollectionProps) => {
  const { items, collectionId, dragDisabled, dropDisabled, clone } = props;

  return (
    <Droppable key={collectionId} droppableId={collectionId} isDropDisabled={dropDisabled}>
      {(provided:DroppableProvided, snapshot:DroppableStateSnapshot) => (
        <>
          {props.info ? <Space style={{ float: 'right', height: '10px', marginTop: '8px', marginRight: '-7px' }}>{props.info}</Space> : null}
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ ...getListStyle(snapshot.isDraggingOver, props.info !== undefined), touchAction: 'pan-y' }}
          >
            {items.map((item, index) => (
              <DraggableItemView clone={clone} dragDisabled={dragDisabled} key={index} item={item} index={index} />
              ))}
            {provided.placeholder}
          </div>
        </>
      )}
    </Droppable>);
};

export default DroppableCollection;
