import { FilterOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import React, { useState } from 'react';
import Button from '../atomic/Button';
import DatePicker from '../atomic/DatePicker';
import Popconfirm from '../atomic/Popconfirm';
import { StyledDiv } from '../styled/CenteredDiv';

export enum TransactionColumns {
  'Row' = 'Row',
  'Broker' = 'Broker',
  'Account' = 'Account',
  'Symbol' = 'Symbol',
}
export type ViewTransactionsSettings = {
  view: TransactionColumns[];
  includeOpenLots: boolean;
  startDate?: Date;
  endDate?: Date;
};

type ViewTransactionsFilterProps = {
  settings: ViewTransactionsSettings;
  onUpdateFilter: (updateSetting: ViewTransactionsSettings) => void;
};

const ViewTransactionsFilter: React.FC<ViewTransactionsFilterProps> = (props: ViewTransactionsFilterProps) => {
  const { settings, onUpdateFilter } = props;
  const [setting, setSetting] = useState<ViewTransactionsSettings>(settings);
  const [updated, setUpdated] = useState<boolean>(false);

  return (
    <Popconfirm
      key={`entry-filter`}
      header={"Configure Filter"}
      icon={null}
      title={<>
        <div style={{ fontWeight: 'bold' }}>Select table columns to display:</div>
        <div>
          {Object.keys(TransactionColumns).map((option: string) => <Checkbox
            key={`stock-filter-${option}`}
            checked={setting.view.find((selectedOption: TransactionColumns) => {
              return selectedOption === TransactionColumns[option];
            }) !== undefined}
            onChange={(value: any) => {
              setSetting((prevUpdateSettings) => {
                const updateSettings = { ...prevUpdateSettings };
                updateSettings.view = value.target.checked ? [...updateSettings.view, TransactionColumns[option]] : updateSettings.view.filter((selectedOption: TransactionColumns) => selectedOption !== TransactionColumns[option]);
                return updateSettings;
              });
              setUpdated(true);
            }}>{TransactionColumns[option]}</Checkbox>)}
        </div>
        <div style={{ fontWeight: 'bold' }}>Filter Transactions:</div>
        <div>
          <Checkbox checked={setting.includeOpenLots} onClick={() => setSetting((prev) => {
            const updatePrev = { ...prev };
            updatePrev.includeOpenLots = !prev.includeOpenLots;
            return updatePrev;
          })}>Include Open Lots</Checkbox>
          <div>
            <StyledDiv bold>Filter dates:</StyledDiv>
            <div>Start: <DatePicker
              confirmReset={{ title: 'Reset start date' }}
              size='small'
              fontSize={20}
              popupStyle={{ zIndex: 2000 }}
              initialValue={setting.startDate ? moment(setting.startDate) : undefined}
              onChange={(e?: moment.Moment | null) => {
                setSetting((prev) => {
                  const updatePrev = { ...prev };
                  updatePrev.startDate = e ? e.toDate() : undefined;
                  return updatePrev;
                });
                setUpdated(true);
              }} />
            </div>
            <div>End: <DatePicker
              confirmReset={{ title: 'End' }}
              size='small'
              fontSize={20}
              popupStyle={{ zIndex: 2000 }}
              initialValue={setting.endDate ? moment(setting.endDate) : undefined}
              onChange={(e?: moment.Moment | null) => {
                setSetting((prev) => {
                  const updatePrev = { ...prev };
                  updatePrev.endDate = e ? e.toDate() : undefined;
                  return updatePrev;
                });
                setUpdated(true);
              }} />
            </div>
          </div>
        </div>
      </>}
      onConfirm={() => {
        if (updated) {
          onUpdateFilter(setting);
        }
      }}
      onCancel={() => {
        setSetting(settings);
        setUpdated(false);
      }}
      okText='Done'
      cancelText='Cancel'
    >
      <Button size='small' key='stock-list-filter'><FilterOutlined /></Button>
    </Popconfirm>
  );
};

export default ViewTransactionsFilter;
