import debug from 'debug';

// const COLOURS = {
//   trace: 'lightblue',
//   info: 'blue',
//   warn: 'pink',
//   error: 'red'
// };

class Log {
  generateMessage(level: string, message: string, source: string) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `s2p:${level}`;
    const createDebug = debug(namespace);

    // Set the colour of the message based on the level
    // createDebug.color = COLOURS[level];

    if (source) {
      createDebug(source, message);
    }
    else {
      createDebug(message);
    }
  }

  trace(message: any, source: string) {
    return this.generateMessage('trace', message, source);
  }

  info(message: any, source: string) {
    return this.generateMessage('info', message, source);
  }

  warn(message: any, source: string) {
    return this.generateMessage('warn', message, source);
  }

  error(message: any, source: string) {
    return this.generateMessage('error', message, source);
  }
}

export default new Log();
