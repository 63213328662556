import React, { useState, useEffect } from 'react';

import { Link, Redirect } from 'react-router-dom';

import { Form, Input, Popover, Spin, notification } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';

// Presentational
// import FormWrapper from '../../Components/Styled/FormWrapper';

// App theme
import { colors } from '../themes/Colors';
import FormWrapper from "../styled/FormWrapper";
import { AuthStatus } from './AuthStatusEnum';
import { UserInput } from '../../graphql/generated/graphql';
import Button from '../atomic/Button';
import { CenteredDiv } from '../styled/CenteredDiv';

type ResetProps = {
  reset: (event: any) => any;
  authenticationStatus: AuthStatus;
  userInput?: UserInput;
  loading: boolean;
};

type UserFormData = {
  email: string;
  code: string;
  newPassword: string;
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

const Reset: React.FC<ResetProps> = (props: ResetProps) => {
  const { reset, loading, authenticationStatus } = props;

  const [form] = Form.useForm();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(loading);
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (isLoading !== loading) {
      setIsLoading(loading);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  useEffect(() => {
    if (authenticationStatus === AuthStatus.ResetDone) {
      notification.success({
        message: 'Successfully reset your password!',
        description: 'Account created successfully, Redirecting you in a few!',
        placement: 'topRight',
        duration: 1.5,
        onClose: () => {
          setRedirect(true);
        },
      });
    }

    if (authenticationStatus === AuthStatus.Fail) {
      notification.error({
        message: 'Error',
        description: 'Error signing up user',
        placement: 'topRight',
        duration: 1.5,
      });
      setIsLoading(false);
    }
  },        [authenticationStatus]);

  const onFinish = (values: any) => {
    // values.preventDefault();

    const { email } = values;

    // show loader
    setIsLoading(true);
    setEmail(email);

    reset(values);
  };

  /*
  const onFinishFailed = ({ errorFields: any }) => {
    form.scrollToField(errorFields[0].name);
  }; */

  const handleConfirmBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule: object, value: string, callback: (message?: string) => void) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    }
    else {
      callback();
    }
  };

  const validateToNextPassword = (rule: object, value: string, callback: (message?: string) => void) => {
    const validationRulesErrors = schema.validate(value, { list: true });

    if (value && confirmDirty) {
      // form.validateFields(['confirm'], { force: true });
    }
    if (validationRulesErrors.length > 0) {
      callback(formatPasswordValidateError(validationRulesErrors));
    }
    callback();
  };

  const formatPasswordValidateError = (errors: Array<string>) => {
    for (let i = 0; i < errors.length; i += 1) {
      if (errors[i] === 'min') {
        return 'password length should be a at least 8 characters';
      }
      if (errors[i] === 'lowercase') {
        return 'password should contain lowercase letters';
      }
      if (errors[i] === 'uppercase') {
        return 'password should contain uppercase letters';
      }
      if (errors[i] === 'digits') {
        return 'password should contain digits';
      }
      if (errors[i] === 'symbols') {
        return 'password should contain symbols';
      }
    }
  };

  const title = 'Password Policy';
  const passwordPolicyContent = (
    <React.Fragment>
      <h4>Your password should contain: </h4>
      <ul>
        <li>Minimum length of 8 characters</li>
        <li>Numerical characters (0-9)</li>
        <li>Special characters</li>
        <li>Uppercase letter</li>
        <li>Lowercase letter</li>
      </ul>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <FormWrapper onFinish={onFinish} form={form}>
        <CenteredDiv style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '-30px', paddingBottom: '5px' }}>Reset Password</CenteredDiv>
          {props.authenticationStatus === AuthStatus.RequestReset
            ? <div style={{ paddingBottom: '10px' }} >Enter email address and submit to get a reset code in your email.</div>
            : (props.authenticationStatus === AuthStatus.Reset
              ? <div style={{ paddingBottom: '10px' }} >Please enter reset code found in your email and your new password</div>
              : (props.authenticationStatus === AuthStatus.ResetDone
                ? <div style={{ paddingBottom: '10px' }} >Password successfully reset. <Link to="/login">Login to your account!</Link></div>
                : null))}
          <Form.Item name='email' rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}>
            {props.userInput ?
              <Input disabled={authenticationStatus !== AuthStatus.RequestReset} defaultValue={props.userInput.emailAddress} prefix={<UserOutlined style={{ color: colors.transparentBlack }} />} placeholder="Email" /> :
              <Input prefix={<UserOutlined style={{ color: colors.transparentBlack }} />} placeholder="Email" />}
          </Form.Item>
          {props.authenticationStatus !== AuthStatus.Reset
            ? null
            : <>
              <Form.Item name='code' rules={[{ required: true, message: 'Please input your reset code!' }]}>
                {props.userInput ?
                  <Input disabled={false} prefix={<UserOutlined style={{ color: colors.transparentBlack }} />} placeholder="Code" /> :
                  <Input prefix={<UserOutlined style={{ color: colors.transparentBlack }} />} placeholder="Code" />}
              </Form.Item>

              <Popover placement="right" title={title} content={passwordPolicyContent}>
                <Form.Item name='password' rules={
                [
                  { required: true, message: 'Please input your new password!' },
                  { validator: validateToNextPassword },
                ]}
                >
                  <Input
                    prefix={<LockOutlined style={{ color: colors.transparentBlack }} />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
              </Popover>
              <Form.Item name='confirm' rules={
              [
                {
                  required: true,
                  message: 'Please confirm your new password!',
                },
                {
                  validator: compareToFirstPassword,
                },
              ]
              }>
                <Input
                  prefix={<LockOutlined style={{ color: colors.transparentBlack }} />}
                  type="password"
                  placeholder="Confirm Password"
                  onBlur={handleConfirmBlur}
                />
              </Form.Item>
            </>}
        <Form.Item className="text-center">
          <Button style={{ width: '100%' }} type="primary" disabled={isLoading} htmlType="submit">
            {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : (props.authenticationStatus === AuthStatus.RequestReset ? 'Reset Password' : 'Update Password')}
          </Button>
          <div style={{ textAlign: 'center' }}>Or <Link to="/login">login to your account!</Link>
          </div>
        </Form.Item>
      </FormWrapper>
      {redirect && (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Reset;
