import React, { useState } from 'react';
import { Tree as AntTree } from 'antd';
import { TreeProps as TreePropsAnt } from 'antd/lib/tree';
import styled from 'styled-components';

const TreeStyled = styled(AntTree)`
`;

type TreeProps = TreePropsAnt & {
  data: any;
  checkedKeys?: any[];
  showSelectAll?: boolean;
  updateSelected: (e: any) => void;
};

const Tree: React.FC<TreeProps> = (props: TreeProps) => {
  const [expandedKeys, setExpandedKeys] = useState<(string|number)[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<((string | number)[] | {
    checked: (string | number)[];
    halfChecked: (string | number)[];
  })>(props.checkedKeys ?? []);
  const [selectedKeys, setSelectedKeys] = useState<(string | number)[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [firstLoadShowResponse, setFirstLoadShowResponse] = useState<boolean>(true);

  const onExpand = (expandedKeys: (string|number)[]) => {
    console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
    setFirstLoadShowResponse(false);
    props.updateSelected({ expandedKeys, checkedKeys, selectedKeys });
  };

  const onCheck = (checkedKeys: (string | number)[] | {
    checked: (string | number)[];
    halfChecked: (string | number)[];
  }) => {
    console.log('onCheck', checkedKeys);
    setCheckedKeys(checkedKeys);
    props.updateSelected({ expandedKeys, checkedKeys, selectedKeys });
  };

  const onSelect = (selectedKeys: (string|number)[], info: any) => {
    console.log('onSelect', info);
    setSelectedKeys(selectedKeys);
    props.updateSelected({ expandedKeys, checkedKeys, selectedKeys });
  };

  const treeData = props.showSelectAll ? [{ title: '', key: 'select-all', children: props.data }] : props.data;

  return (
    <>
      <TreeStyled
        checkable
        draggable
        onExpand={onExpand}
        expandedKeys={props.showSelectAll && firstLoadShowResponse ? ['select-all', ...expandedKeys] : expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={treeData}
    />
  </>);
};

export default Tree;
