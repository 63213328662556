export enum AuthStatus {
  Unknown = 1,
  None, // Sign up for alpha-program
  LoggingIn,
  Success,
  Fail,
  AlphaProgram, // Already Signed up for alpha program
  Register, // 7. Start registration flow
  ConfirmRegister, // 8. Confirm user in Congito
  EmailConfirmed, // 9. Email confirmed, don't know yet if User has been created
  CreateUser, // 10. Authenticated, create user in S2P
  Authenticated, // 11. User exists and authenticated
  Error, // Error while registering
  RequestReset,
  Reset,
  ResetDone,
}
