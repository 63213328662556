export const reorderArrayElement = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result: T[] = list;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const removeArrayElement = <T>(list: T[], removeIndex: number): T[] => {
  const result: T[] = list;
  if (removeIndex >= 0) {
    result.splice(removeIndex, 1);
  }

  return result;
};
