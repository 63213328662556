import React from 'react';
import { DataCollectionData } from '../../graphql/generated/graphql';
import { DarkBackground } from '../atomic/TutorialTooltip';
import { TutorialType } from './Tutorial';

type TutorialCollectionProps = {
  tutorial: TutorialType;
};

export const sampleCollection: DataCollectionData[] = [
  { uuid: '11111111-1111-1111-1111-111111111110', ownerUuid: '11111111-1111-1111-1111-111111111111', name: 'Sample Collection', description: '', collectionUuids: [], entryUuids: [], objectUuids: [], ordinal: 1 },
  { uuid: '11111111-1111-1111-1111-111111111111', ownerUuid: '11111111-1111-1111-1111-111111111111', name: 'Sample Collection with description', description: 'Collection for a sample Collection', collectionUuids: [], entryUuids: [], objectUuids: [], ordinal: 2 },
];

const TutorialCollection: React.FC<TutorialCollectionProps> = (props: TutorialCollectionProps) => {
  const { tutorial } = props;
  return <DarkBackground isVisible={tutorial !== TutorialType.None}></DarkBackground>;
};

export default TutorialCollection;
