import { Dictionary } from "lodash";
import { StockDetails, StockHistoryPrice, StockPrice } from "../../graphql/generated/graphql";
import { SellLotType } from "./AddStockData";

export type StockEarning = {
  date: Date;
  dateConfirmed: boolean;
  estimate: string;
  actual: string;
}

export type Stock = {
  ticker: string;
  name: string;
  description: string;
  isCrypto: boolean;
  cusip?: string;
  isin?: string;
  sedol?: string;
  mappedSymbols?: string[];
  isMapped?: boolean;
  isUnknown?: boolean;
  robinhoodId?: string;
  securityId?: string;
  fidelityAssetId?: string;
  inactiveStock?: boolean;
  endDate?: string;
  lastViewed?: Date;
  earnings?: StockEarning[];
};

export type StockHolding = {
  uuid?: string;
  transactionId?: string; // corresponds to buy transaction
  broker: string;
  accountId: string;
  securityId: string;
  symbol: string;
  priceDate: string;
  costBasis: number | null;
  price: number | null;
  quantity: number | null;
  value: number | null;
  lastFetched?: Date;
};

export const getStockFromStockDetails = (stockDetails?: StockDetails): Stock | undefined => {
  if (!stockDetails) {
    return undefined;
  }
  const stock: Stock = {
    ticker: stockDetails.ticker,
    name: stockDetails.name,
    description: stockDetails.description,
    isCrypto: stockDetails.isCrypto === true,
  };
  return stock;
};

export type AccountStockData<T> = Dictionary<T[]>;
export type BrokerStockData<T> = Dictionary<AccountStockData<T>>;
export type SymbolStockData<T> = Dictionary<BrokerStockData<T>>;

export const getBrokerStockData = <T>(symbolTransactions: SymbolStockData<T>): BrokerStockData<T> => {
  const brokerTransactions: BrokerStockData<T> = {};
  Object.keys(symbolTransactions).forEach((symbol: string) => {
    Object.keys(symbolTransactions[symbol]).forEach((broker: string) => {
      if (!brokerTransactions[broker]) {
        brokerTransactions[broker] = {};
      }
      Object.keys(symbolTransactions[symbol][broker]).forEach((account: string) => {
        if (!brokerTransactions[broker][account]) {
          brokerTransactions[broker][account] = [];
        }
        brokerTransactions[broker][account].push(...symbolTransactions[symbol][broker][account]);
      });
    });
  });
  return brokerTransactions;
};

export const getBrokerStockDataFlattened = <T>(data: BrokerStockData<T>): T[] => {
  const allData: T[] = [];
  if (data) {
    Object.keys(data)
      .forEach((broker: string) => {
        Object.keys(data[broker])
          .forEach((account: string) => {
            allData.push(...data[broker][account]);
          });
      });
  }
  return allData;
};

export const getSymbolStockDataFlattened = <T>(data: SymbolStockData<T>): T[] => {
  const allData: T[] = [];
  if (data) {
    Object.keys(data)
      .forEach((symbol: string) => {
        allData.push(...getBrokerStockDataFlattened(data[symbol]));
      });
  }
  return allData;
};

export const getSymbolStockData = <T extends { accountId: string, broker: string, symbol: string }>(data: T[]): SymbolStockData<T> => {
  const symbolStockData: SymbolStockData<T> = {};
  data.forEach((datum: T) => {
    if (!symbolStockData[datum.symbol]) {
      symbolStockData[datum.symbol] = {};
    }
    if (!symbolStockData[datum.symbol][datum.broker]) {
      symbolStockData[datum.symbol][datum.broker] = {};
    }

    if (!symbolStockData[datum.symbol][datum.broker][datum.accountId]) {
      symbolStockData[datum.symbol][datum.broker][datum.accountId] = [];
    }
    symbolStockData[datum.symbol][datum.broker][datum.accountId].push(datum);
  });
  return symbolStockData;
};

export enum TransactionAction {
  Track = 0,
  BuyOrder,
  Bought,
  SellOrder,
  Sold,
}

export enum StockDataSourceType {
  Manual = 0,
  Plaid,
  Coinbase,
  Csv,
}

type StockDataSource = {
  sourceType: StockDataSourceType;
  addedDate: Date;
  sourceName?: string;
};

export type StockLotMapping = {
  buyUuid?: string; // used for bought transaction
  sellUuid?: string; // used for sold transaction
  orderUuid?: string; // used for sell order
  quantity: number;
  sellType?: SellLotType; // used for sell order
};

// Use uuid for manually added items.
export type StockMergedTransaction = { uuid?: string, transactionId?: string, quantity: number, fees?: number };

export type StockTransaction = {
  uuid: string;
  broker: string;
  accountId: string;
  date: string;
  transactionId: string;
  transactionIds?: StockMergedTransaction[];
  securityId: string;
  action: TransactionAction;
  symbol: string;
  type?: string;
  subtype?: string;
  amount: number;
  quantity: number;
  fees?: number;
  price?: number;
  name?: string; // ???
  source?: StockDataSource;
  lots?: StockLotMapping[];
  // notes?: string[];
};

export type StockOrder = {
  uuid: string;
  action: TransactionAction.BuyOrder | TransactionAction.SellOrder;
  broker: string;
  accountId: string;
  symbol: string;
  securityId: string;
  expiration: Date; // | 'GTC';
  price: number;
  quantity: number;
  remove_date?: Date;
  lots?: StockLotMapping[];
  state?: string;
  source?: 'manual' | 'plaid' | 'robinhood',
};

export type LastPrice = {
  lastFetched: Date;
  price: StockPrice;
};

export type StockHistory = {
  lastFetched: Date;
  history: StockHistoryPrice[];
};

export type StockAlert = {
  symbol: string
  created: Date;
  expiration?: Date;
  fallTriggerPrice?: number;
  riseTriggerPrice?: number;
};

export type UpdateStockEntryData = {
  holdings?: StockHolding[];
  removeHoldings?: StockHolding[];
  transactions?: StockTransaction[]; // automatically removes merged stocks
  removeTransactions?: StockTransaction[];
  orders?: StockOrder[];
  removeOrders?: StockOrder[];
  lists?: string[];
  details?: Stock;
  updateMappedSymbols?: string[];
  updateIsMapped?: boolean;
  isUnknown?: boolean;
  updateLastViewed?: boolean;
};

/*
export type StockTransactions = {
  uuid: string;
  action: TransactionAction;
  createdDate: Date;
  expirationDate?: Date;
  symbol: string;
  track?: TrackStock;
  order?: StockOrder;
  buy?: StockBuy;
  sell?: StockSell;
};

export type StockTransactions = {
  uuid: string;
  type: TransactionType;
  createdDate: Date;
  expirationDate?: Date;
  symbol: string;
  track?: TrackStock;
  order?: StockOrder;
  buy?: StockBuy;
  sell?: StockSell;
};
export type Portfolio = {
  openBuy: StockBuy[];
  openSell: StockSell[];
  transactions: StockTransactions[];
};

type TrackStock = {
  fallTriggerPrice?: number;
  riseTriggerPrice?: number;
};

type StockOrder = {
  buyOrSell: 'Buy' | 'Sell';
  type: 'Limit' | 'Market';
  triggerPrice: number;
};

type StockBuy = {
  uuid: string;
  price: number;
  units: number;
  fee?: number;
};

type StockSell = {
  price: number;
  units: number;
  fee: number;
  matchBuy: { uuid: string; units: number };
};

enum AccountSubtype {
  // Credit type
  CreditCard = 'credit card',
  // depository type
  Checking = 'checking',
  Savings = 'savings',
  // investment type
  Brokerage = 'brokerage',
  StockPlan = 'stock plan',
  Roth = 'roth',
  '457b' = '457b',
  Hsa = 'hsa',
  '401k' = '401k',
  Other = 'other',
}
*/
