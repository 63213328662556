import styled from 'styled-components';
import { Space } from 'antd';

export const MobileButtonSpace = styled(Space)`
  position: fixed;
  height: 50px;
  bottom: 10px;
  right: 10px;
  right-margin: 10px;
  bottom-margin: 10px;
`;

export const MobileButtonDiv = styled.div`
  position: fixed;
  height: 55px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background: white;
  border-top: solid;
  z-index:1001;
`;

// background: white;
export const MobileContent = styled.div`
  position: fixed;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 55px;
  width: 100%;
  overflow: scroll;
  z-index: 1000;
`;

// background: white;
export const MobileDiv = styled.div`
  position: fixed;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 2000;
`;

type ResponsiveContentDivProps = {
  isMobile: boolean;
  topMargin?: number;
};

// ${(props: CssProps) => props.bigWindow ? `

// background-color: #f7f7f7;
export const ResponsiveContentDiv = styled.div`
  position: flex;
  top: calc(60px + ${(props: ResponsiveContentDivProps) => props.topMargin ? props.topMargin : 0}); /* 62 (header) + 15 (body top margin) */
  ${(props: ResponsiveContentDivProps) => props.isMobile
    ? `left: 5px;
    right: 5px;
    padding-left: 5px; /* scrollbar */
    padding-right: 5px; /* scrollbar */`
    : `  left: 50px;
  right: 50px;
  padding-left: 50px; /* scrollbar */
  padding-right: 50px; /* scrollbar */
  padding-bottom: 15px;
`}
  bottom: 0px;
  overflow: auto;
`;
