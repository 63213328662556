import React, { useEffect, useRef, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import Button from '../../../components/atomic/Button';
import { ModalType } from '../../modal/ResponsiveModal';
import RecordModal, { ModalFields, RecordModalAction } from '../../../components/modal/RecordModal';
import { RecordType } from '../../../components/table/DataTypes';
import AddCollection from '../../../containers/api/Collection/AddCollection';
import { AddDataCollectionMutation, DataCollectionData, DataCollectionInput } from '../../../graphql/generated/graphql';
import { getMockedCollectionInputToDataCollection } from '../Entry/EntryFields';

type CreateCollectionProps = {
  triggerCreate?: boolean;
  ordinal: number;
  modalType?: ModalType;
  icon?: JSX.Element;
  float?: 'left' | 'right';
  mocked?: boolean;
  onSuccess: (data?: DataCollectionData, loading?: boolean, error?: string) => any;
  onTrigger?: () => any; // update when modal is shown.
  onCancel?: () => any;
  onError?: (error: string) => any;
};

const CreateCollection = (props: CreateCollectionProps) => {
  const { triggerCreate, ordinal, modalType, icon, mocked, onSuccess, onTrigger, onCancel, onError } = props;
  const [action, setAction] = useState<RecordModalAction>(RecordModalAction.None);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [executeUpdate, setExecuteUpdate] = useState<JSX.Element | null>(null);
  const [hasError, setHasError] = useState<any>(null);

  const actionRef = useRef(RecordModalAction.None);
  actionRef.current = action;

  useEffect(() => {
    if (action === RecordModalAction.None && triggerCreate) {
      setAction(RecordModalAction.Create);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          name: { placeholder: 'Enter name', type: RecordType.Text },
          description: { placeholder: 'Enter description', type: RecordType.Text },
        },
      },
    ],
  };

  const onAddNewCollection = () => {
    setAction(RecordModalAction.Create);
    if (onTrigger) {
      onTrigger();
    }
  };

  const onCreateCollection = async (record: any) => {
    const dataCollection: DataCollectionInput = record.pages[0].entries;
    Object.keys(dataCollection).forEach((field: any) => dataCollection[field] = dataCollection[field].value);
    dataCollection.ordinal = ordinal;

    if (mocked) {
      setHasError(null);
      setIsLoading(false);
      setAction(RecordModalAction.None);
      setExecuteUpdate(null);
      const dataCollectionResult: DataCollectionData = getMockedCollectionInputToDataCollection(dataCollection);
      if (onSuccess) {
        onSuccess(dataCollectionResult);
      }
      return;
    }

    const updateQuery = <AddCollection key={new Date().toUTCString()} collectionInput={dataCollection} onUpdate={(data?: AddDataCollectionMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setHasError(error);
        setExecuteUpdate(null);
        if (onError) {
          onError(error);
        }
        return;
      }

      if (data && data.addDataCollection) {
        setHasError(null);
        setIsLoading(false);
        setAction(RecordModalAction.None);
        setExecuteUpdate(null);
        if (onSuccess) {
          onSuccess(data.addDataCollection, loading, error);
        }
      }
    }} />;
    setExecuteUpdate(updateQuery);
  };

  const createIcon = icon ? <span onClick={onAddNewCollection}>{props.icon}</span> : <Button key='collections-add' style={{ float: 'right' }} onClick={onAddNewCollection}><PlusCircleOutlined /></Button>;

  return <>
    {createIcon}
    {executeUpdate}
    {action !== RecordModalAction.None
      ? <RecordModal
          zIndex={3000}
          type={modalType}
          key={'create-collection-modal'}
          data={recordDataValues}
          action={action}
          title={'collection'}
          onCancel={() => {
            setAction(RecordModalAction.None);
            if (onCancel) {
              onCancel();
            }
          }}
          onSubmit={onCreateCollection}
          isLoading={isLoading}
          error={hasError ? hasError.message : undefined}
          loadingMessage={'Creating new Collection ...'}
        />
      : null}
  </>;
};

export default CreateCollection;
