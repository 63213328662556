import React from 'react';
import { AutoComplete as AntAutoComplete, Input } from 'antd';
import styled from 'styled-components';
import { AutoCompleteProps as AntAutoCompleteProps } from 'antd/lib/auto-complete';
import LoadingIcon from '../icons/LoadingIcon';

const AutoCompleteStyled = styled(AntAutoComplete)`
  border-radius: 5px;
`;

type AutoCompleteProps = Omit<AntAutoCompleteProps, 'placeholder' > & {
  keyTrigger?: string;
  keyPressed?: string;
  loading?: boolean;
  placeholder?: string;
};

const AutoComplete: React.FC<AutoCompleteProps> = (props: AutoCompleteProps) => {
  const { loading, ...antProps } = props;

  return (
    <AutoCompleteStyled key='styled-autocomplete' {...antProps} >
      {props.children ?? <Input key='auto-complete-input' suffix={<LoadingIcon hide={!loading} />} />}
    </AutoCompleteStyled>
  );
};

export default AutoComplete;
