import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ThemeContext from '../../context/ThemeContext';
import { BorderVerticleOutlined } from '@ant-design/icons';

type VerticalSlideDivProps = {
  visible: boolean;
  left: number;
  right: number;
  backgroundColor: string;
};

type HorizontalSlideDivProps = {
  visible: boolean;
  top: number;
  bottom: number;
  backgroundColor: string;
};

const VerticalSlideDiv = styled.div`
  ${(props: VerticalSlideDivProps) => props.visible ? '' : 'display: none;'}
  background-color: ${(props: VerticalSlideDivProps) => props.backgroundColor};
  position: absolute,
  ${(props: VerticalSlideDivProps) => props.left !== -1 ? `left: ${props.left}px;` : ''}
  ${(props: VerticalSlideDivProps) => props.right !== -1 ? `right: ${props.right}px;` : ''}
  width: 4px;
  height: 100%;
`;

//  ${(props: HorizontalSlideDivProps) => props.visible ? '' : 'display: none;'}
const HorizontalSlideDiv = styled.div`
  background-color: ${(props: HorizontalSlideDivProps) => props.backgroundColor};
  position: absolute;
  left: 0px;
  right: 0px;
  ${(props: HorizontalSlideDivProps) => props.top !== -1 ? `top: ${props.top}px;` : ''}
  ${(props: HorizontalSlideDivProps) => props.bottom !== -1 ? `bottom: ${props.bottom}px;` : ''}
  height: 4px;
  width: 100%;
  z-index: 3000;
  cursor: ns-resize;
`;

type ResizeProps = {
  reference: 'top' | 'bottom' | 'left' | 'right';
  offset: number;
  color?: string; // default black
  hideColor?: string; // default black
  hoverOnly?: boolean; // default false
  onResize: (x: number, y: number) => void;
};

const Resize: React.FC<ResizeProps> = (props: ResizeProps) => {
  const themeContext = useContext(ThemeContext);
  const [drag, setDrag] = useState<{ active: Boolean, start: { x: number, y: number }, mouseLeft: boolean }>({
    active: false,
    start: { x: -1, y: -1 },
    mouseLeft: false,
  });
  const [height, setHeight] = useState<number>(props.reference === 'top' || props.reference === 'bottom' ? props.offset : -1);
  const [width, setWidth] = useState<number>(props.reference === 'left' || props.reference === 'right'  ? props.offset : -1);
  const [touch, setTouch] = useState<Touch | undefined>(undefined);
  const [showResize, setShowResize] = useState(false);

  const defaultVerticalProp = {
    visible: true,
    left: props.reference === 'left' ? props.offset : -1,
    right: props.reference === 'right' ? props.offset : -1,
    backgroundColor: props.color ? props.color : 'black',
  };

  const defaultHorizontalProp = {
    visible: true,
    top: props.reference === 'top' ? props.offset : -1,
    bottom: props.reference === 'bottom' ? props.offset : -1,
    backgroundColor: props.color ? props.color : 'black',
  };

  const onMouseEnter = (event: React.MouseEvent<any>) => {
    console.log('onMouseEnter');
    const updateDrag = { ...drag };
    updateDrag.mouseLeft = false;
    const updateProps = { ...themeContext.componentProps };
    const updateProp = updateProps['page.theme-settings.horiontal-bar'] ? updateProps['page.theme-settings.horiontal-bar'] : defaultHorizontalProp;
    updateProp.backgroundColor = 'black';
    console.log(updateProp);
    updateProps['page.theme-settings.horiontal-bar'] = updateProp;
    themeContext.setComponentProps(updateProps);
    setDrag(updateDrag);
  };

  const onMouseLeave = (event: React.MouseEvent<any>) => {
    console.log('onMouseLeave');
    const updateDrag = { ...drag };
    updateDrag.mouseLeft = true;
    setDrag(updateDrag);
    if (drag.active) {
      return;
    }
    const updateProps = { ...themeContext.componentProps };
    const updateProp = updateProps['page.theme-settings.horiontal-bar'] ? updateProps['page.theme-settings.horiontal-bar'] : defaultHorizontalProp;
    updateProp.backgroundColor = '#00008b';
    console.log(updateProp);
    updateProps['page.theme-settings.horiontal-bar'] = updateProp;
    themeContext.setComponentProps(updateProps);
  };

  const onDragStart = (event: React.MouseEvent<HTMLDivElement>) => {
    console.log(`dragstart clientX ${event.clientX}, clientY ${event.clientY}`);
    console.log(event);
    const updateDrag = { ...drag };
    updateDrag.start = { x: event.clientX, y: event.clientY };
    updateDrag.active = true;
    setDrag(updateDrag);
  };

  const onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    console.log(`dragstart clientX ${event.touches[0].clientX}, clientY ${event.touches[0].clientY}`);
    console.log(event);
    if (event.touches.length === 0) {
      return;
    }
    const updateDrag = { ...drag };
    updateDrag.start = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    updateDrag.active = true;
    setDrag(updateDrag);
    setTouch(event.touches[0] as Touch);
    event.preventDefault();
  };

  const onDropEnd = (event: React.MouseEvent<HTMLDivElement>) => {
    console.log('dragEnd');
    console.log(event);
    console.log(touch);
    // console.log(`dropend clientX ${event.clientX}, clientY ${event.clientY}`);
    const updateDrag = { ...drag };
    const deltaY = updateDrag.start.y - event.clientY;
    const deltaX = updateDrag.start.x - event.clientX;
    updateDrag.active = false;
    updateDrag.start = { x: -1, y: -1 };

    setDrag(updateDrag);
    if (height !== -1) {
      setHeight(height + deltaY);
    }
    if (width !== -1) {
      setWidth(width + deltaX);
    }

    props.onResize(deltaX, deltaY);
    event.preventDefault();
  };

  const onTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    console.log('touchEnd');
    console.log(touch);
    if (!touch) {
      return;
    }
    console.log(`dropend clientX ${event.changedTouches[0].clientX}, clientY ${event.changedTouches[0].clientY}`);

    const updateDrag = { ...drag };
    const deltaY = updateDrag.start.y - event.changedTouches[0].clientY;
    const deltaX = updateDrag.start.x - event.changedTouches[0].clientX;
    updateDrag.active = false;
    updateDrag.start = { x: -1, y: -1 };

    setDrag(updateDrag);
    setTouch(undefined);
    if (height !== -1) {
      setHeight(height + deltaY);
    }
    if (width !== -1) {
      setWidth(width + deltaX);
    }

    props.onResize(deltaX, deltaY);
    event.preventDefault();
  };

  return (
    // {... themeContext.componentProps['page.theme-settings.horiontal-bar'] ? themeContext.componentProps['page.theme-settings.horiontal-bar'] :
    <React.Fragment>
      {(props.reference === 'top' || props.reference === 'bottom') && (
        <HorizontalSlideDiv {... defaultHorizontalProp}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onDragEnter={onDragStart}
          onTouchStart={onTouchStart}
          onDragEnd={onDropEnd}
          onTouchEnd={onTouchEnd}
          onClick={() => setShowResize(!showResize)}
        > <BorderVerticleOutlined onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} />
        </HorizontalSlideDiv>
      )}
      {(props.reference === 'left' || props.reference === 'right') && (
        <VerticalSlideDiv {... defaultVerticalProp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDragEnter={onDragStart}
        onDragEnd={onDropEnd}
        />
      )}
    </React.Fragment>
  );
};

export default Resize;
