import React from 'react';
import {  Dropdown as AntDropdown, Menu } from 'antd';
import { DropDownProps as DropdownPropsAnt } from 'antd/lib/dropdown';
import styled from 'styled-components';

const DropdownStyled = styled(AntDropdown)`
`;

export type DropdownMenuItem = { name: string, component?: JSX.Element | string};
export enum DropdownType {
  Click = 'click',
  Hover = 'hover',
  ContextMenu = 'contextMenu',
}

type DropdownProps = Omit<DropdownPropsAnt, 'overlay'|'onClick'> & {
  menuItems: DropdownMenuItem[];
  dropdownType?: DropdownType[];
  children: React.ReactNode;
  onClick?: (item: any) => void;
};

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const { menuItems, dropdownType, onClick, children } = props;

  const items = menuItems.map((item: DropdownMenuItem) => (
    <Menu.Item key={item.name}>
      {onClick
        ? <div onClick={() => {
          onClick(item.name);
        }}>{item.component ? item.component : item.name}</div>
        : (item.component ? item.component : item.name)}
    </Menu.Item>));

  const menu = (
    <Menu>
      {items}
    </Menu>
    );

  // const triggerValue:  = dropdownType ? ['click', 'hover', 'contextType'].filter((item: string) => dropdownType.find(DropdownType[item]))  : undefined;

  return (
    <DropdownStyled {...props} trigger={dropdownType ? dropdownType.map((item: 'click' | 'hover' | 'contextMenu') => item) : undefined} overlay={menu}>
      {children}
    </DropdownStyled>
  );
};

export default Dropdown;
