import React from 'react';
import { Tooltip as TooltipAnt } from 'antd';
import styled from 'styled-components';
import { TooltipProps as TooltipPropsAnt } from 'antd/lib/tooltip';

const TooltipStyled = styled(TooltipAnt)`
  border-radius: 5px;
  .ant-popover-message {
    padding: 0px;
    background: green;
  }
  .ant-popover-inner-content {
    padding: 0px;
    background: blue;
  }
`;

export enum IconType {
  Info = 0,
  Warn,
  None,
}

export type TooltipProps = TooltipPropsAnt & {
  header?: string | JSX.Element;
  iconType?: IconType;
  icon?: JSX.Element
  customLayout?: boolean;
};

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const { customLayout, children, header, title, iconType, icon, ...antProps } = props;

  // message: 4px 0 12px;
  // inner-content: 12px 16px
  return (
    <TooltipStyled
      {...antProps}
      title={header ? <><h3>{header}</h3>{title}</> : <div style={{ margin: customLayout ? '-16px -16px -16px -38px' : undefined }}>{title}</div>}
    >
      {children}
    </TooltipStyled>
  );
};

export default Tooltip;
