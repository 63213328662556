import { CaretDownOutlined, CaretUpOutlined, CheckCircleTwoTone, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileAddOutlined, FilterOutlined, PlusCircleOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../atomic/Button';
import CircleCheckbox from '../atomic/CircleCheckbox';
import ResponsiveModal from '../modal/ResponsiveModal';
import { WizardTooltipProps } from '../wizard/WizardTooltip';
import TutorialEntry from './TutorialEntry';

enum TutorialPageEnum {
  Intro = 0,
  Navigation,
  Collections,
  Entries,
}

export enum TutorialType {
  None = 0,
  CollectionCreateNew,
  CollectionItemName,
  CollectionItemToggle,
  CollectionItemDescription,
  CollectionItemEditDelete,
  CollectionHowTo,
  CollectionDialog,
  EntryCreateNew, // 8
  EntryFilter,
  EntryCollections,
  EntryItem,
  EntryEdit,
  EntryItemToggle,
  EntryContent,
  EntryOverdue,
  EntryCompleted,
  QRTracker,
  EntryCalendar,
}

export const TutorialNoneProps: WizardTooltipProps = {
  title: '',
  message: '',
  visible: false,
  currentPage: TutorialType.None,
  onClose: () => {},
};

const ULStyled = styled.ul`
  padding-left: 28px;
`;

export const getTutorialProps = (tutorial: TutorialType, onClose: () => void, buttons?: JSX.Element[], onKeyPress?: (currentPage: TutorialType, key: string) => void) : WizardTooltipProps => {
  const collectionTypes = [TutorialType.CollectionCreateNew, TutorialType.CollectionItemName, TutorialType.CollectionItemToggle, TutorialType.CollectionItemDescription, TutorialType.CollectionItemEditDelete];
  const entryTypes = [TutorialType.EntryCreateNew, TutorialType.EntryCollections, TutorialType.EntryCompleted, TutorialType.EntryContent, TutorialType.EntryEdit, TutorialType.EntryFilter, TutorialType.EntryItem, TutorialType.EntryItemToggle,  TutorialType.EntryOverdue];
  const bottomLeftPlacement = [TutorialType.CollectionItemToggle, TutorialType.EntryCompleted, TutorialType.EntryOverdue, TutorialType.EntryItemToggle, TutorialType.EntryItem];
  const leftPlacement = [TutorialType.EntryFilter];
  const rightTopPlacement = [TutorialType.CollectionItemToggle, TutorialType.EntryCompleted, TutorialType.EntryOverdue, TutorialType.EntryItemToggle];
  const centerPlacement = [TutorialType.CollectionItemName, TutorialType.CollectionItemDescription];
  const topPlacement = [TutorialType.EntryContent];
  const placement =
    bottomLeftPlacement.find((item: TutorialType) => item === tutorial) ? 'bottomLeft'
      : (leftPlacement.find((item: TutorialType) => item === tutorial) ? 'left'
      : (rightTopPlacement.find((item: TutorialType) => item === tutorial) ? 'bottom'
      : (centerPlacement.find((item: TutorialType) => item === tutorial) ? 'bottom'
      : (topPlacement.find((item: TutorialType) => item === tutorial) ? 'top'
      : 'bottomRight'))));
  const title = collectionTypes.find((item: TutorialType) => item === tutorial) ? `How To: Collection (${tutorial}/5)`
    :(entryTypes.find((item: TutorialType) => item === tutorial) ? `How To: Entry (${tutorial - 7}/9)`
      : '');
  const message =
    (tutorial === TutorialType.CollectionCreateNew ? <ULStyled><li>A Collections helps you organize and track your entries by grouping them.</li><li>Click on <PlusCircleOutlined /> icon to create a new collection.</li></ULStyled>
    : (tutorial === TutorialType.CollectionItemName ? 'Click on the Name of a collection to view and manage all Entries associated with the collection.'
    : (tutorial === TutorialType.CollectionItemToggle ? <ULStyled><li>Click on the <CaretUpOutlined style={{ fontSize: '16px', paddingTop: '0px' }} /> icon to view Description of a Collection.</li><li>If Collection does not have a description, <CaretUpOutlined style={{ opacity: '30%', fontSize: '16px', paddingTop: '0px' }} /> will be light gray.</li></ULStyled>
    : (tutorial === TutorialType.CollectionItemDescription ? `Collection's description will be shown once triangle toggle is clicked.`
    : (tutorial === TutorialType.CollectionItemEditDelete ? <ULStyled><li>Click on the <EditOutlined /> to edit the Collection's name and description.</li><li>You can click on <DeleteOutlined /> to delete the collection. Once deleted, the collection cannot be restored.</li></ULStyled>
    : (tutorial === TutorialType.EntryCreateNew ? <ULStyled><li>An Entry allows you keep track of information - whether it's an appointment, an item to complete, a reference to an external data, or anything else you want to store.</li><li>At the moment, you can store a Title, a Description, a Due Date, and a Done Date when entry was completed.</li><li>You can also add an Entry to any number of Collections to help you sort and manage your Entries.</li><li>Click on <PlusCircleOutlined /> icon to create a new Entry.</li></ULStyled>
    : (tutorial === TutorialType.EntryFilter ? <div>Click on <FilterOutlined /> to filter and sort Entries.</div>
    : (tutorial === TutorialType.EntryCollections ? <ULStyled><li>Click on <FileAddOutlined /> to add or remove Entries to a collection.</li><li>This button is only visible when viewing Entries in a Collection.</li></ULStyled>
    : (tutorial === TutorialType.EntryItem ? <ULStyled style={{ paddingLeft: '28px' }}><li>Entry title will be listed here for every entry.</li><li>Click on the Due Date to update or delete the value.</li></ULStyled>
    : (tutorial === TutorialType.EntryEdit ? <ULStyled><li>Click on the <EditOutlined /> to edit all of Entries content.</li><li>You can click on <DeleteOutlined /> to delete an Entry.</li></ULStyled>
    : (tutorial === TutorialType.EntryItemToggle ? <ULStyled>
        <li>Click on <CaretUpOutlined style={{ fontSize: '16px', paddingTop: '0px' }} /> to view additional details of an Entry.</li>
        <li>Click on <CaretDownOutlined style={{ fontSize: '16px', paddingTop: '0px' }} /> to hide additional details of an Entry.</li>
        <li>Click on <span style={{ paddingRight: '20px' }}><CircleCheckbox /></span>  to mark Entry as done</li>
      </ULStyled>
    : (tutorial === TutorialType.EntryContent ? <ULStyled><li>Entry's additional details will be shown once <CaretUpOutlined style={{ fontSize: '16px', paddingTop: '0px' }} /> is clicked.</li><li>You can view and update Collections that include this Entry</li><li>You can also update the Entry's Date Due and Date Completed.</li></ULStyled>
    : (tutorial === TutorialType.EntryOverdue ? <div>When Entry is past Due Date, Entry will be marked with <ExclamationCircleOutlined style={{ color: 'red' }} /> icon. Click on the icon to update Due Date and or Done/Date. The Due Date will also be shown in red.</div>
    : (tutorial === TutorialType.EntryCompleted ? <div>When Entry is completed, <CheckCircleTwoTone style={{ paddingRight: '1px' }} twoToneColor="#52c41a" /> is displayed. You can click on to configure whether you want to completed Entries to appear or be hidden.</div>
    : ``))))))))))))));
  if (collectionTypes.find((item: TutorialType) => item === tutorial) || entryTypes.find((item: TutorialType) => item === tutorial)) {
    return ({
      placement,
      title,
      message,
      buttons,
      visible: true,
      icon: <QuestionCircleTwoTone />,
      onClose,
      currentPage: tutorial,
      onKeyPress,
    });
  }
  return ({
    title: '',
    message: '',
    visible: false,
    onClose,
    currentPage: TutorialType.None,
    onKeyPress,
  });
};

type TutorialProps = {
  tutorialPage?: TutorialPageEnum;
};

const Tutorial: React.FC<TutorialProps> = (props: TutorialProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [tutorialPage, setTutorialPage] = useState<TutorialPageEnum>(props.tutorialPage ? props.tutorialPage : TutorialPageEnum.Intro);

  return <ResponsiveModal
    visible={isVisible}
    title='Tutorial'
    onOk={(e: any) => setIsVisible(false)}
    onCancel={(e: any) => setIsVisible(false)}
    cancelButtonProps={{ hidden: tutorialPage === TutorialPageEnum.Intro }}
    okText={'Close'}
    modalContent={<>
      {tutorialPage === TutorialPageEnum.Intro ? <div>Welcome!<br/> Swing 2 Places provides an easy way to store data and group entries in categories. Press Next to discover currently avaiable functionality.</div> : null}
      {tutorialPage === TutorialPageEnum.Entries ? <TutorialEntry /> : null}
      <Button style={{ float: 'right', bottom: '10px' }} onClick={() => {
        if (tutorialPage === TutorialPageEnum.Intro) {
          setTutorialPage(TutorialPageEnum.Collections);
        }
        if (tutorialPage === TutorialPageEnum.Collections) {
          setTutorialPage(TutorialPageEnum.Entries);
        }
      }}>Next</Button>
    </>}
    />;
};

export default Tutorial;
