import { FilterOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useState } from 'react';
import Button from '../atomic/Button';
import Popconfirm from '../atomic/Popconfirm';

export enum StockListVisible {
  HoldingUnits = "Holding Units",
  HoldingAmount = 'Holding Amount',
  HoldingChange = 'Holding Change',
}

export type StockListSettings = {
  view: StockListVisible[];
};

type StockListFilterProps = {
  settings: StockListSettings;
  onUpdateFilter: (updateSetting: StockListSettings) => void;
};

const StockListFilter: React.FC<StockListFilterProps> = (props: StockListFilterProps) => {
  const { settings, onUpdateFilter } = props;
  const [setting, setSetting] = useState<StockListSettings>(settings);
  const [updated, setUpdated] = useState<boolean>(false);

  return (
    <Popconfirm
      key={`entry-filter`}
      header={"Configure Filter"}
      icon={null}
      title={<>
        <div style={{ fontWeight: 'bold' }}>Show following Holdings data:</div>
        <div>
          {Object.keys(StockListVisible).map((option: string) => <Checkbox
            key={`stock-filter-${option}`}
            checked={setting.view.find((selectedOption: StockListVisible) => {
              return selectedOption === StockListVisible[option];
            }) !== undefined}
            onChange={(value: any) => {
              setSetting((prevUpdateSettings) => {
                const updateSettings = { ...prevUpdateSettings };
                updateSettings.view = value.target.checked ? [...updateSettings.view, StockListVisible[option]] : updateSettings.view.filter((selectedOption: StockListVisible) => selectedOption !== StockListVisible[option]);
                return updateSettings;
              });
              setUpdated(true);
            }}>{StockListVisible[option]}</Checkbox>)}
        </div>
      </>}
      onConfirm={() => {
        if (updated) {
          onUpdateFilter(setting);
        }
      }}
      onCancel={() => {
        setSetting(settings);
        setUpdated(false);
      }}
      okText='Done'
      cancelText='Cancel'
    >
      <Button size='small' key='stock-list-filter'><FilterOutlined /></Button>
    </Popconfirm>
  );
};

export default StockListFilter;
