import React from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from '../../i18n/i18n';

interface LanguageProviderProps {
  messages: any;
}

export class LanguageProvider extends React.PureComponent<LanguageProviderProps> {
  render() {
    return (
      <IntlProvider
        locale={DEFAULT_LOCALE}
        defaultLocale={DEFAULT_LOCALE}
        key={DEFAULT_LOCALE}
        messages={this.props.messages[DEFAULT_LOCALE]}
        onError={undefined}
        formats={undefined}
        defaultFormats={undefined}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

export default LanguageProvider;
