import React, { FC, useEffect, useState } from 'react';
import { errorNotification } from '../actions/Notification';
import LoadingOverlay from '../atomic/LoadingOverlay';
import RecordModal, { ModalFields, RecordModalAction } from '../modal/RecordModal';
import { StyledSpan } from '../styled/CenteredDiv';
import { RecordType } from '../table/DataTypes';
import { StockMergedTransaction, StockTransaction } from './StockTypes';

type MergeTransactionsProps = {
  transactions: StockTransaction[],
  onSuccess: (mergedTransaction: StockTransaction) => any;
  onCancel: () => any;
};

const MergeTransactions: FC<MergeTransactionsProps> = (props: MergeTransactionsProps) => {
  const { onSuccess, transactions, onCancel } = props;
  const [modalAction, setModalAction] = useState<RecordModalAction>(RecordModalAction.Update);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [executeUpdate, setExecuteUpdate] = useState<JSX.Element | null>(null);
  const [lastChanged, setLastChanged] = useState<string | undefined>(undefined);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>([]);

  useEffect(() => {
    setLastChanged(new Date().toISOString());
  },        [selectedTransactions]);

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          Description: { type: RecordType.TextValue, fieldName: '', value: <>Following {transactions[0].quantity < 0 ? 'Sell' : 'Buy'} Transactions occured on <StyledSpan bold>{transactions[0].date}</StyledSpan> in broker <StyledSpan bold>{transactions[0].broker}</StyledSpan> account <StyledSpan bold>{transactions[0].accountId}</StyledSpan> with same price <StyledSpan bold>{transactions[0].price ? transactions[0].price.toFixed(2) : '--'}</StyledSpan>. Select transactions that you want to merge:</> },
          TransactionsHeader: { type: RecordType.TextValue, fieldName: 'Transactions' },
          Transactions: {
            type: RecordType.Table,
            values: selectedTransactions,
            columns: ['Quantity', 'Price', 'Date', 'Lot Basis'].map((column: string, index: number) => ({ title: column, dataIndex: index, key: column, inputType: { recordType: RecordType.TextValue } })),
            columnData: transactions.map((transaction: StockTransaction, index: number) => {
              return ({
                key: { type: RecordType.Index, value: transaction.transactionId },
                0: { type: RecordType.TextValue, value: transaction.quantity < 0 ? -transaction.quantity : transaction.quantity },
                1: { type: RecordType.TextValue, value: transaction.price ? transaction.price.toFixed(2) : '' },
                2: { type: RecordType.TextValue, value: transaction.date },
                3: { type: RecordType.TextValue, value: transaction.price ? ((transaction.quantity < 0 ? -1 : 1) * transaction.quantity * transaction.price).toFixed(2) : '' },
              });
            }),
            onUpdate: (record: any) => {
              setSelectedTransactions(record);
            },
          },
          ...(selectedTransactions.length === 0
          ? {
            NoResultHeader: { type: RecordType.TextValue, fieldName: `Result from combining ${selectedTransactions.length} transactions above` },
            NoResult: { type: RecordType.TextValue, fieldName: '', value: 'No transactions selected.' } }
          : {}),
          ...(selectedTransactions.length === 0
          ? {}
          : {
            TransactionsResultHeader: { type: RecordType.TextValue, fieldName: `Result from combining ${selectedTransactions.length} transactions above` },
            TransactionResult: {
              type: RecordType.Table,
              columns: ['Quantity', 'Price', 'Date', 'Lot Basis'].map((column: string, index: number) => ({ title: column, dataIndex: index, key: column, inputType: { recordType: RecordType.TextValue } })),
              columnData: [{
                key: { type: RecordType.Index, value: selectedTransactions[0] },
                0: {
                  type: RecordType.TextValue,
                  value: transactions
                    .filter((trans: StockTransaction) => selectedTransactions.includes(trans.transactionId))
                    .map((quantity: StockTransaction) => quantity.quantity < 0 ? - quantity.quantity : quantity.quantity).reduce((a: number, b: number) => a + b) },
                1: { type: RecordType.TextValue, value: transactions[0].price ? (transactions[0].price).toFixed(2) : '' },
                2: { type: RecordType.TextValue, value: transactions[0].date },
                3: {
                  type: RecordType.TextValue,
                  value: transactions[0].price ? ((transactions
                    .filter((trans: StockTransaction) => selectedTransactions.includes(trans.transactionId))
                    .map((quantity: StockTransaction) => quantity.quantity < 0 ? -quantity.quantity : quantity.quantity).reduce((a: number, b: number) => a + b)) * transactions[0].price).toFixed(2) : '' },
              }],
            },
          }),
        },
      },
    ],
  };

  const onSubmit = (data: ModalFields) => {
    if (selectedTransactions.length < 2) {
      errorNotification('At least two transactions need to be selected to merge.');
      return;
    }
    const mergedTransaction = transactions.find((merge: StockTransaction) => merge.transactionId === selectedTransactions[0]);
    if (!mergedTransaction) {
      return; // this will never happen.
    }
    const transactionIds: StockMergedTransaction[] = [];
    transactions.filter((merged: StockTransaction) => selectedTransactions.includes(merged.transactionId))
                .forEach((merged: StockTransaction) => {
                  // If previously merged, add all. This includes the original unmerged transaction with given transactionId.
                  if (merged.transactionIds) {
                    merged.transactionIds.forEach((prevMerged: StockMergedTransaction) => {
                      if (!transactionIds.find((check: StockMergedTransaction) => check.transactionId === prevMerged.transactionId)) {
                        transactionIds.push(prevMerged);
                      }
                    });
                  }
                  else {
                  // add merged transaction
                    if (!transactionIds.find((check: StockMergedTransaction) => check.transactionId === merged.transactionId)) {
                      transactionIds.push({ transactionId: merged.transactionId, quantity: merged.quantity, fees: merged.fees });
                    }
                  }
                });
    mergedTransaction.transactionIds = transactionIds;
    mergedTransaction.quantity = mergedTransaction.transactionIds.map((getQuantity: any) => getQuantity.quantity).reduce((a: any, b: any) => a + b);
    onSuccess(mergedTransaction);
  };

  return <>
    {executeUpdate}
    {modalAction !== RecordModalAction.None && (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={loadingMessage ? loadingMessage : `Adding new Entry ...`}
        >
        <RecordModal
          loadingMessage={loadingMessage}
          hideButtons={isLoading}
          lastChanged={lastChanged}
          key={'add-entry-modal'}
          data={recordDataValues}
          action={modalAction}
          title={'Transactions'}
          onCancel={() => {
            setError(null);
            setModalAction(RecordModalAction.None);
            if (onCancel) {
              onCancel();
            }
          }}
          onSubmit={onSubmit}
          isLoading={isLoading}
          error={error}
        />
      </LoadingOverlay>
    )}
  </>;
};

export default MergeTransactions;
