import { AuthProvider } from '../context/AuthContext';
import config from '../Config';

enum LocalAuthStorageKeys {
  AuthProvider = 'auth-provider',
  JwtAuth = 's2p-jwt-auth',
  SandboxAuthProvider = 'sandbox-auth-provider',
  SandboxJwtAuth = 'sandbox-s2p-jwt-auth'
}

export enum LocalStorageKeys {
  ClientId = 's2p-client-id',
}

export enum SessionStorageKeys {
  ClientId = 's2p-client-id',
}

const AUTH_PROVIDER_KEY = config.ENV === 'Local' && config.MOCK_AUTH !== undefined ? LocalAuthStorageKeys.SandboxAuthProvider : LocalAuthStorageKeys.AuthProvider;
const JWT_AUTH = config.ENV === 'Local' && config.MOCK_AUTH !== undefined ? LocalAuthStorageKeys.SandboxJwtAuth : LocalAuthStorageKeys.JwtAuth;

export function setLocalStoage(key: LocalStorageKeys, value?: string) {
  setInternalLocalStorage(LocalStorageKeys[key], value);
}

export function getLocalStorage(key: LocalStorageKeys) {
  return localStorage.getItem(LocalStorageKeys[key]);
}

export function setSessionStorage(key: SessionStorageKeys, value?: string) {
  if (!value) {
    sessionStorage.removeItem(SessionStorageKeys[key]);

  }
  else {
    sessionStorage.setItem(SessionStorageKeys[key], value);
  }}

export function getSessionStorage(key: SessionStorageKeys) {
  return sessionStorage.getItem(LocalStorageKeys[key]);
}

function setInternalLocalStorage(key: string, value?: string) {
  if (!value) {
    localStorage.removeItem(key);

  }
  else {
    localStorage.setItem(key, value);
  }
}

function getAuthProvider(): AuthProvider {
  return AuthProvider[localStorage.getItem(AUTH_PROVIDER_KEY) ?? 'None'];
}

function setAuthProvider(authProvider?: AuthProvider) {
  setInternalLocalStorage(AUTH_PROVIDER_KEY, authProvider);
}

function getGoogleAuth(): string | null {
  return localStorage.getItem(JWT_AUTH);
}

function setGoogleAuth(token: string) {
  setInternalLocalStorage(JWT_AUTH, token);
}

export {
  getAuthProvider,
  setAuthProvider,
  getGoogleAuth,
  setGoogleAuth,
};
