import moment from 'moment';
import React, { useEffect }  from 'react';
import { StockOrder, TransactionAction } from '../../../components/stocks/StockTypes';
import { PlaidHoldings, PlaidInvestment, useGetCoinbaseDataQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type GetCoinbaseProps = {
  dataType: 'accounts' | 'transactions' | 'holdings' | 'orders';
  onUpdate?: (data?: { broker: string, investments?: PlaidInvestment, holdings?: PlaidHoldings, orders?: StockOrder[], accounts?: any }, loading?: boolean, error?: any) => void;
};

const GetCoinbase: React.FC<GetCoinbaseProps> = (props: GetCoinbaseProps) => {
  const { onUpdate, dataType } = props;
  const { data: coinbaseData, loading, error } = useGetCoinbaseDataQuery({
    variables: { dataType },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      const investments: PlaidInvestment | undefined = dataType === 'transactions' && coinbaseData ? JSON.parse(coinbaseData.getCoinbaseData) : undefined;
      const holdings: PlaidHoldings | undefined = dataType === 'holdings' && coinbaseData ? JSON.parse(coinbaseData.getCoinbaseData) : undefined;
      const accounts: any[] = [];
      const orders: StockOrder[] = [];
      const coinbaseOrders: any[] | undefined = dataType === 'orders' && coinbaseData ? JSON.parse(coinbaseData.getCoinbaseData) : undefined;
      if (coinbaseOrders) {
        coinbaseOrders.forEach((coinbaseOrder: any) => {
          const symbol = coinbaseOrder.product_id.split('-')[0];
          const addOrder: StockOrder = {
            uuid: coinbaseOrder.id,
            action: coinbaseOrder.side === 'buy' ? TransactionAction.BuyOrder : TransactionAction.SellOrder,
            broker: 'Coinbase Pro',
            accountId: symbol,
            symbol: `CUR:${symbol}`,
            securityId: symbol,
            expiration: moment(coinbaseOrder.created_at).toDate(),
            price: Number(coinbaseOrder.price),
            quantity: Number(coinbaseOrder.size),
          };
          orders.push(addOrder);
        });
      }
      onUpdate(coinbaseData ? { broker: 'Coinbase', investments, holdings, accounts, orders } : undefined, loading, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [coinbaseData, loading, error]);

  return null;
};

export default GetCoinbase;
