import React, { useEffect, useState }  from 'react';
import { DataEntryInput, UpdateDataEntryMutation, useUpdateDataEntryMutation } from '../../../graphql/generated/graphql';
import { dataClientContext } from '../Api';

export type UpdateEntryProps = {
  entryInput: DataEntryInput;
  onUpdate?: (data?: UpdateDataEntryMutation | null, loading?: boolean, error?: any) => any;
};

const UpdateEntry: React.FC<UpdateEntryProps> = (props: UpdateEntryProps) => {
  const { entryInput, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [updateDataEntryMutation, { data: updatedEntry, loading: loadingUpdate, error: errorUpdate }] = useUpdateDataEntryMutation({
    ...dataClientContext,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!executed) {
      console.log(`update entry ${entryInput.dataUuid}`);
      setExecuted(true);
      updateDataEntryMutation({ variables: { dataEntry: entryInput } })
        .then((result: any) => {
          console.log(`done updating entry ${entryInput.dataUuid}`);
          console.log(result);
          /*
          if (!errorUpdate) {
            setExecuted(true);
            if (onUpdate) {
              onUpdate(result, loadingUpdate, errorUpdate);
            }
          }
          */
        })
        .catch((err: any) => {
          console.log(`error updating entry ${entryInput.dataUuid}`);
          console.log(err);
          // errorNotification();
        });
    }
    if (onUpdate) {
      onUpdate(updatedEntry, loadingUpdate, errorUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [updatedEntry, loadingUpdate, errorUpdate]);

  return <></>;
};

export default UpdateEntry;
