import { FieldType, InputType } from "../../components/table/DataTypes";

export type TypeOverride<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
export type TypeOmit<T, K> = Pick<T, Exclude<keyof T, K>>;

export type JsonDefinitionType = {
  title: string,
  key: string,
  mapping?: string,
  children?: JsonDefinitionType[],
};

export enum ResultViewEnum {
  Json = 'Json',
  Card = 'Card',
  List = 'View',
  GraphQL = 'GraphQL',
}

export type ApiProps = {
  returnJsx?: boolean;
  fieldFilter?: JsonDefinitionType[];
  view?: ResultViewEnum;
  updateApiResponse?: (data?: any, loading?: any, error?: any) => void;
};

export type ApiViewProps = ApiProps & {
  data: any;
};

const getGraphQLField = (fields: any[], indent: string) => {
  return fields.map((field: any) => {
    const childrenFields = field.children ? getGraphQLField(field.children, `  ${indent}`) : null;
    return `${indent}${field.title}${childrenFields ? ` {
${childrenFields}
${indent}}` : ''}`;
  }).join(`
`);
};

const getGraphQLArgs = (argumentEntries: any) => {
  const args = Object.keys(argumentEntries);

  if (!args || args.length === 0) {
    return '';
  }
  /*
  ${args.length > 1 ? `
  ` : ''}${args.map((argument:string) => {
    const argValue = argumentEntries[argument].type === FieldType.InputArray
    ? (argumentEntries[argument].value ? `[${argumentEntries[argument].value.split(';').map((a: string) => `"${a}"`).join(',')}]` : '[]')
    : (argumentEntries[argument].value ? (argumentEntries[argument].inputType === InputType.Boolean ? `${argumentEntries[argument].value}` : `"${argumentEntries[argument].value}"`) : '""');
    return `${argument}: ${argValue}`
  }).join(`
  `)})` : '';
  */
  return args.map((arg: any) => {
    if (!argumentEntries[arg].type) {
      return `${arg}: {
${getGraphQLArgs(argumentEntries[arg])}
  }`
      // getGraphQLArgs(arg);
    }
    else {
      if (argumentEntries[arg].type === FieldType.InputArray) {
        return `${arg}: [${argumentEntries[arg].value ? argumentEntries[arg].value.split(';').map((v: string) => `"${v}"`).join(`,`) : ''}]`;
      }
      else {
        return `${arg}: ${argumentEntries[arg].value ? (argumentEntries[arg].inputType === InputType.Boolean ? argumentEntries[arg].value : `"${argumentEntries[arg].value}"`) : '""'}`;
      }
    }
  }).join(`
  `);
}

export const getGraphQLQuery = (endpoint: string, argumentEntries: any, fields: any[], type: 'mutation' | 'query' | undefined) => {
  const queryArgs = getGraphQLArgs(argumentEntries);
  /*
  console.log(`foo: ${foo}`);
  const args = Object.keys(argumentEntries);
  const queryArgs = args && args.length > 0 ? ` (${args.length > 1 ? `
  ` : ''}${args.map((argument:string) => {
    const argValue = argumentEntries[argument].type === FieldType.InputArray
    ? (argumentEntries[argument].value ? `[${argumentEntries[argument].value.split(';').map((a: string) => `"${a}"`).join(',')}]` : '[]')
    : (argumentEntries[argument].value ? (argumentEntries[argument].inputType === InputType.Boolean ? `${argumentEntries[argument].value}` : `"${argumentEntries[argument].value}"`) : '""');
    return `${argument}: ${argValue}`
  }).join(`
  `)})` : ''; */
  const fieldsArgs = getGraphQLField(fields, '  ');
  const query = `{ ${endpoint}${queryArgs ? `(${queryArgs})` : ''} {
${fieldsArgs}
}}`;
  if (type === 'mutation') {
    return `mutation
  ${query}`;
  }
  return query;
};

export const filterData = (data: any, filter: JsonDefinitionType[]) => {
  const filteredData: { [key: string]: any } = {};
  const dataKeys = Object.keys(data);
  dataKeys.forEach((k: string) => {
    const includeNode = filter.find((f: any) => f.title === k);
    if (includeNode) {
      filteredData[includeNode.title] = includeNode.children && data[includeNode.title]
        ? filterResult(data[includeNode.title], includeNode.children)
        : data[includeNode.title];
    }
  });
  return filteredData;
};

export const filterResult = (result: any, filter: JsonDefinitionType[]) => {
  const filteredResult: any[] = [];
  console.log('filterResult');
  console.log(result);
  console.log(filter);
  if (Array.isArray(result)) {
    result.forEach((r: any) => {
      const filteredData = filterData(r, filter);
      filteredResult.push(filteredData);
    });
  }
  else {
    const filteredData = filterData(result, filter);
    return filteredData;
  }
  return filteredResult;
};
