import React, { useState } from "react";
import { DatePicker as DatePickerAnt } from 'antd';
import { DatePickerProps as DatePickerPropsAnt, RangePickerProps as RangePickerPropsAnt } from "antd/lib/date-picker";
import { CloseCircleOutlined as CloseCircleOutlinedAnt } from "@ant-design/icons";
import moment from "moment";
import styled from "styled-components";
import { dateFormat } from "../table/DataTypes";
import Popconfirm from "./Popconfirm";

const { RangePicker } = DatePickerAnt;

type DatePickerStyledProps = {
  color?: string;
  fontSize?: number;
  bold?: boolean
};

const DatePickerSmall = styled(DatePickerAnt)`
  .ant-picker-input > input {
    font-size: ${(props: DatePickerStyledProps) => props.fontSize ? props.fontSize : '10'}px;
    ${(props: DatePickerStyledProps) => props.color ? `color: ${props.color};` : null};
    ${(props: DatePickerStyledProps) => props.bold ? `font-weight: bold;` : null};
  }
`;

const RangePickerSmall = styled(RangePicker)`
  .ant-picker-input > input {
    font-size: ${(props: DatePickerStyledProps) => props.fontSize ? props.fontSize : '10'}px;
    ${(props: DatePickerStyledProps) => props.color ? `color: ${props.color};` : null};
    ${(props: DatePickerStyledProps) => props.bold ? `font-weight: bold;` : null};
  }
`;

type CloseCircleOutlinedStyledProps = {
  fontSize?: number;
};

const CloseCircleOutlined = styled(CloseCircleOutlinedAnt)`
  font-size: ${(props: CloseCircleOutlinedStyledProps) => `${props.fontSize ? props.fontSize : '10'}px`};
  padding-top: 2px;
`;

type DatePickerProps = (Omit<DatePickerPropsAnt, 'defaultValue'|'onChange'> | Omit<RangePickerPropsAnt, 'defaultValue'|'onChange'|'onCalendarChange'|'onOk'>) & {
  confirmReset?: { okText?: string, cancelText?: string, title?: string | JSX.Element };
  pickerType?: string | PickerType;
  initialValue?: moment.Moment | { start: moment.Moment, end: moment.Moment };
  fontSize?: number;
  color?: string;
  noIcon?: boolean;
  bold?: boolean;
  onChange?: (value: moment.Moment | null) => void;
  onRangeChange?: (start: moment.Moment | null, end: moment.Moment | null, info: any) => void;
  onOk?: (start: moment.Moment, end?: moment.Moment) => void;
};

export enum DateType {
  time = 'Time',
  date = 'Date',
  week = 'Week',
  month = 'Month',
  quarter = 'Quarter',
  // year = 'Year',
  range = 'Custom Range',
}

export enum PickerType {
  time = 'time',
  date = 'date',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  // year = 'year',
}

const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const { confirmReset, pickerType, noIcon, suffixIcon, initialValue, bold, onChange, onOk, onBlur, onRangeChange, format, fontSize, allowClear, ...antProps } = props;
  const [resetDate, setResetDate] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedRange, setSelectedRange] = useState<{ start: moment.Moment | null, end?: moment.Moment | null }>({ start: null, end: null });

  const clear = allowClear === undefined ? true : allowClear;

  const pickType: PickerType | undefined = pickerType ? PickerType[pickerType] : undefined;
  const isRangePicker: boolean = pickerType === 'range';

  const commonSufficIcon = noIcon ? undefined : !clear
  ? undefined
  : (confirmReset
    ? <Popconfirm
      visible={resetDate}
      title={confirmReset.title}
      onConfirm={() => {
        setResetDate(false);
        setSelectedDate(null);
        if (onChange) {
          onChange(null);
        }
      }}
      onCancel={() => setResetDate(false)}
      okText={confirmReset.okText ?  confirmReset.okText : 'Confirm'}
      cancelText={confirmReset.cancelText ? confirmReset.cancelText : 'Cancel'}
    >{suffixIcon ? suffixIcon : <CloseCircleOutlined fontSize={fontSize} />}</Popconfirm>
    : (suffixIcon ? suffixIcon : <CloseCircleOutlined fontSize={fontSize} />));

  const commonOnChange = (e: any) => {
    if (e === null) {
      if (confirmReset) {
        setResetDate(true);
      }
      else {
        setSelectedDate(null);
        if (onChange) {
          onChange(null);
        }
      }
      return;
    }
    setSelectedDate(e.toDate());
    if (onChange) {
      onChange(e);
    }
  };

  const onRangeOkInternal = (values: [moment.Moment | null, moment.Moment | null] | null) => {
    if (onOk && values && values[0]) {
      onOk(values[0], values[1] ?? undefined);
    }
  };

  const onRangeBlur = (e: any) => {
    if (onRangeChange && selectedRange.start && selectedRange.end) {
      onRangeChange(selectedRange.start, selectedRange.end, 'end');
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const onRangeChangeInternal = (values: [moment.Moment | null, moment.Moment | null] | null, formatString: [string, string], info: any) => {
    if (values) {
      setSelectedRange({ start: values[0], end: values[1] });
    }
    if (onRangeChange) {
      values ? onRangeChange(values[0], info.range === 'end' ? values[1] : null, info) : onRangeChange(null, null, info);
    }
  };

  const defaultRangeValue: [moment.Moment, moment.Moment] | undefined = isRangePicker && initialValue ? [(initialValue as { start: moment.Moment, end: moment.Moment }).start, (initialValue as { start: moment.Moment, end: moment.Moment }).end] : undefined;

  // pickerType={dateType}
  return (isRangePicker
    ? <RangePickerSmall
    { ...antProps as RangePickerPropsAnt }
    allowClear={clear}
    picker={pickType }
    key={`${selectedDate}-${resetDate}`}
    inputReadOnly
    fontSize={fontSize}
    style={{ borderRadius: '5px' }}
    defaultValue={defaultRangeValue}
    /* defaultPickerValue={defaultRangeValue} */
    /* value={selectedDate ? moment(selectedDate) : undefined} */
    suffixIcon={commonSufficIcon}
    format={format ? format : dateFormat.replaceAll('-', '/')}
    onCalendarChange={onRangeChangeInternal}
    onOk={onOk ? onRangeOkInternal : undefined}
    onBlur={onRangeBlur}
  />
  : <DatePickerSmall
      { ...antProps as DatePickerPropsAnt }
      allowClear={clear}
      picker={pickType}
      key={`${selectedDate}-${resetDate}`}
      inputReadOnly
      fontSize={fontSize}
      bold={bold}
      style={{ borderRadius: '5px' }}
      defaultValue={initialValue as moment.Moment}
      value={selectedDate ? moment(selectedDate) : undefined}
      suffixIcon={commonSufficIcon}
      format={format ? format : dateFormat.replaceAll('-', '/')}
      onChange={commonOnChange}
      onOk={onOk ? (date: moment.Moment) => onOk(date) : undefined}
      onBlur={onBlur}
    />);
};

export default DatePicker;
