import gql from 'graphql-tag';

export default gql`
  fragment PlaidAccount on PlaidAccount {
    accountId
    brokerAccountId
    balances {
      available
      current
    }
    lastFour
    name
    subtype
    type
  }
  fragment PlaidSecurity on PlaidSecurity {
    closePrice
    closePriceDate
    cusip
    isin
    sedol
    name
    securityId
    symbol
    type
  }
`;
