export default {
  account: '中文 Account',
  apps: '中文 Apps',
  configureTheme: '中文 Configure Theme',
  entries: '中文 Entries',
  events: '中文 Events',
  fuel: '中文 Fuel',
  login: '登录 Login',
  home: '回到首頁 Home',
  schedule: '中文 Schedule',
  settings: '设定页面 Settings',
  starbucks: '星巴克 Starbucks',
  stocks: '中文 Stocks',
  travel: '中文 Travel',
  manageDocument: '中文 Manage Documents',
  pages: '中文 Pages',
  collections: '中文 Collections',
  qrScanner: '中文 QR Scanner',
  manage: '中文 Manage Pages and Collections',
  'errors.errorSmiley': 'Error :(',
};
