import React, { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';

type BottomPageOrContentProps = {
  pinBottom: boolean;
  children?: any;
};

const BottomPageOrContent: React.FC<BottomPageOrContentProps> = (props: BottomPageOrContentProps) => {
  const themeContext = useContext(ThemeContext);
  const { pinBottom } = props;
  return (
    <div
      style={{
        position: pinBottom ? 'fixed' : undefined,
        paddingTop: pinBottom ? undefined : '100px',
        bottom: pinBottom ? (themeContext.showFooter ? '60px' : '10px') : undefined }} >
     {props.children}
    </div>
  );
};

export default BottomPageOrContent;
