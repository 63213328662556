// TODO: redo this so we're not sending dev config to browsers
const ENV_LOCAL = {
  ENV: 'Local',
  MOCK_AUTH: 'f64e04ff-4ba8-4e78-a30f-3552514aa949',
  API_URL: 'http://localhost:4000/graphql',
  OAUTH_REDIRECT_URL: 'http%3A%2F%2Flocalhost%3A3000%2Foauth',
  OAUTH_REDIRECT_SIGN_IN_URL: 'http://localhost:3000/oauth',
  OAUTH_REDIRECT_SIGN_OUT_URL: 'http://localhost:3000/',
  LOCAL_DEVELOPMENT: true,
  PLAID_ENV: 'development', // 'sandbox',
};

const ENV_DEV = {
  ENV: 'Dev',
  MOCK_AUTH: undefined,
  API_URL: 'https://s2p-api-dev.herokuapp.com/graphql', // 'https://s2p-server.herokuapp.com/graphql',
  OAUTH_REDIRECT_URL: 'https%3A%2F%2Fdev.swingtoplaces.com%2Foauth',
  OAUTH_REDIRECT_SIGN_IN_URL: 'https://dev.swingtoplaces.com/oauth',
  OAUTH_REDIRECT_SIGN_OUT_URL: 'https://dev.swingtoplaces.com/',
  PLAID_ENV: 'sandbox',
};

const ENV_PROD = {
  ENV: 'Prod',
  MOCK_AUTH: undefined,
  API_URL: 'https://s2p-api.herokuapp.com/graphql', // 'https://s2p-server.herokuapp.com/graphql',
  OAUTH_REDIRECT_URL: 'https%3A%2F%2Fswingtoplaces.com%2Foauth',
  OAUTH_REDIRECT_SIGN_IN_URL: 'https://swingtoplaces.com/oauth',
  OAUTH_REDIRECT_SIGN_OUT_URL: 'https://swingtoplaces.com/',
  PLAID_ENV: 'development',
};

const API_ENDPOINTS = {
  ENDPOINT_STORAGE: 'storage',
  ENDPOINT_DATA: 'data',
  ENDPOINT_PUBLIC: 'public',
  ENDPOINT_PROVISION: 'provision',
  ENDPOINT_USER: 'user',
  ENDPOINT_LAUNCH: 'launch',
  ENDPOINT_STOCK: 'stocks',
  ENDPOINT_DATA_WS: 'data',
};

const API_CONFIG =
  process.env.REACT_APP_ENV === 'local' ? ENV_LOCAL : (process.env.REACT_APP_ENV === 'dev' ? ENV_DEV : ENV_PROD);

export default {
  cognito: {
    REGION: process.env.COGNITO_REGION ?? 'us-east-1',
    USER_POOL_ID: process.env.COGNITO_USER_POOL_ID ?? 'us-east-1_Wxdgr6pYL', // 'us-east-1_7e3K0KIG4',
    APP_CLIENT_ID: process.env.COGNITO_APP_CLIENT_ID ?? '29amapim7cl0npdskp3gj5lo51', // '5op7n46cjs90r8o3tf72glj665',
    IDENTITY_POOL_ID: undefined, // process.env.COGNITO_IDENTITY_POOL_ID ?? 'us-east-1:c27e8075-84e6-49c7-8913-b53757e06601',
  },
  google: {
    oAuthClientID: process.env.GOOGLE_OAUTH_CLIENT_ID ?? 'TODO',
    // The OAuth client secret from the Google Developers console.
    oAuthclientSecret: process.env.GOOGLE_OAUTH_CLIENTSECRET ?? 'TODO',
    // The callback to use for OAuth requests. This is the URL where the app is
    // running. For testing and running it locally, use 127.0.0.1.
    oAuthCallbackUrl: process.env.GOOGLE_OAUTH_CALLBACK_URL ?? (process.env.REACT_APP_ENV === 'local' ? 'http://localhost:3000/auth/google/callback' : 'https://dev.swingtoplaces/auth/google/callback'),
  },
  social: {
    FACEBOOK: '',
  },
  LOCAL_DEVELOPMENT: false,
  ...API_CONFIG,
  ...API_ENDPOINTS,
};
