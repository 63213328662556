import React from 'react';
import styled, { CSSProperties } from 'styled-components';

export enum DivPosition {
  Right = 0,
  Center,
  Left,
}

export enum DivVerticalPosition {
  Top,
  Center,
  Bottom,
}

type DivAlignProps = {
  children?: React.ReactNode;
  hPosition: DivPosition,
  vPosition?: DivVerticalPosition,
  absolute?: boolean;
  style?: CSSProperties;
};

const DivAlignStyled = styled.div`
  ${(props: DivAlignProps) => {
    const position = props.absolute ? 'position: absolute;' : 'position: relative;';
    const leftright = props.hPosition === DivPosition.Left ? (props.absolute ? 'left: 0px;' : 'float: left;')
    : (props.hPosition === DivPosition.Center ? (props.absolute ? 'left: 50%; right: 50%;' : 'float: center;')
    : (props.hPosition === DivPosition.Right ? (props.absolute ? 'right: 0px;' : 'float: right;')
    : (props.absolute ? 'left: 0px;' : 'float: left;')));
    return `${position}
    ${leftright}`;
  }}
  ${(props: DivAlignProps) => {
    return props.vPosition === DivVerticalPosition.Top ? `top: 0px;`
      : (props.vPosition === DivVerticalPosition.Center ? `heigth: 100%; top: 50%; bottom: 50%;`
      : (props.vPosition === DivVerticalPosition.Bottom ? `heigth: 100%; bottom: 0px;`
      : `top: 0px;`));
  }}
`;

const DivAlign: React.FC<DivAlignProps> = (props: DivAlignProps) => {
  const { style } = props;
  return (
    <div style={{ position: 'relative', overflow: props.absolute ? undefined : 'hidden', ...style }}  >
      <DivAlignStyled {...props}>{props.children}</DivAlignStyled>
    </div>
  );
};

export default DivAlign;
