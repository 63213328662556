import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useState } from 'react';
import Button from '../atomic/Button';
import Dropdown, { DropdownType } from '../atomic/Dropdown';
import Popconfirm from '../atomic/Popconfirm';
import { TutorialType } from '../tutorial/Tutorial';
import TutorialCollection from '../tutorial/TutorialCollection';

export enum ViewFilter {
  All = 'All items',
  NoDue = 'Items without due date',
  Due = 'Items with due date',
  OverDue = 'Items that are overdue',
  Completed = 'Completed items',
  Today = 'Items due today',
  TodayPastDue = 'Items due today or past due',
  ThisWeek = 'Items due this week',
  ThisWeekPastDue = 'Items due this week or past due',
  DeletedItems = 'Deleted items',
}

export enum EntrySort {
  Alphabetical = "Alphabetical",
  Custom = 'Custom order',
  DueDate = 'Due Date',
  DoneDate = 'Done Date',
  CreatedDate = 'Created Date',
}

export type EntrySettings = {
  sortBy: EntrySort;
  filter: ViewFilter;
  includeCompleted: boolean;
  showFireworks: boolean;
};

type EntryFilterProps = {
  icon: JSX.Element;
  settings: EntrySettings;
  onUpdateSetting: (updateSetting: EntrySettings) => void;
  isTutorial?: boolean;
};

const EntryFilter: React.FC<EntryFilterProps> = (props: EntryFilterProps) => {
  const { icon, isTutorial, settings, onUpdateSetting } = props;
  const [setting, setSetting] = useState<EntrySettings>(settings);
  const [updated, setUpdated] = useState<boolean>(false);

  return (
    <Popconfirm
      key={`entry-filter${isTutorial ? '-tutorial' : ''}`}
      header={"Configure Filter"}
      isTutorial={isTutorial}
      icon={null}
      title={<>
        <TutorialCollection tutorial={isTutorial ? TutorialType.EntryFilter : TutorialType.None} />
        <div style={{ fontWeight: 'bold' }}>Sort By:</div>
        <div>
          <Dropdown
            overlayStyle={{ zIndex: 2000 }}
            menuItems={Object.keys(EntrySort).filter((item: string) => EntrySort.Custom !== EntrySort[item]).map((item: string) => ({ name: item, component: EntrySort[item] })) }
            dropdownType={[DropdownType.Click]}
            onClick={(item: any) => {
              if (EntrySort[item]) {
                setSetting((prevUpdateSettings) => {
                  const updateSettings = { ...prevUpdateSettings };
                  updateSettings.sortBy = EntrySort[item];
                  return updateSettings;
                });
                setUpdated(true);
              }
            }}
          >
            <Button key={`filter-${setting.filter}`} style={{ width: '300px', textAlign: 'left' }}>{setting.sortBy}</Button>
          </Dropdown>
        </div>
        <div style={{ fontWeight: 'bold' }}>Select Filter:</div>
        <div>
          <Dropdown
            overlayStyle={{ zIndex: 2000 }}
            menuItems={Object.keys(ViewFilter).map((item: string) => ({ name: item, component: ViewFilter[item] })) }
            dropdownType={[DropdownType.Click]}
            onClick={(item: any) => {
              if (ViewFilter[item]) {
                setSetting((prevUpdateSettings) => {
                  const updateSettings = { ...prevUpdateSettings };
                  updateSettings.filter = ViewFilter[item];
                  return updateSettings;
                });
                setUpdated(true);
              }
            }}
          >
            <Button key={`sort-by-${setting.filter}`} style={{ width: '300px', textAlign: 'left' }}>{setting.filter}</Button>
          </Dropdown>
        </div>
        <div>
          <Checkbox
            disabled={setting.filter === ViewFilter.Completed}
            checked={setting.includeCompleted}
            onChange={(value: any) => {
              setSetting((prevUpdateSettings) => {
                const updateSettings = { ...prevUpdateSettings };
                updateSettings.includeCompleted = value.target.checked;
                return updateSettings;
              });
              setUpdated(true);
            }}>Include completed items</Checkbox>
        </div>
      </>}
      onConfirm={() => {
        if (updated) {
          onUpdateSetting(setting);
        }
      }}
      onCancel={() => {
        setSetting(settings);
        setUpdated(false);
      }}
      okText='Done'
      cancelText='Cancel'
    >
      {icon}
    </Popconfirm>
  );
};

export default EntryFilter;
