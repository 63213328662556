/* Steps to retrieve and process collection page data
 *
 * <ComponentA></ComponentA>
 *
 * 1. Get Collection UUID from URL
 * 2. Get Collection Data from GraphQL
 *    getPageData(collectionUuid) {
 *      uuid
 *      layout [
 *        {
 *          component
 *          content {
 *            collection [ // collection of collections, DOs, and items
 *              {
 *                type
 *                uuid
 *                fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *                  visible [
 *                    {
 *                      entryUuid
 *                      viewMap
 *                    }
 *                  ]
 *                  expandable [...]
 *                  hidden [...]
 *                }
 *                data { // entry data to display on the page.
 *                  entries [
 *                    {
 *                      uuid
 *                      name
 *                      data
 *                    }
 *                  ]
 *                }
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 *
 *    getPageData(collectionUuid) {
 *      uuid
 *      layout [
 *        {
 *          component
 *          collectionUuid
 *        }
 *      ]
 *      collection [ // collection of collections, DOs, and items
 *        {
 *          type
 *          uuid
 *          fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *            visible [
 *              {
 *                 entryUuid
 *                 viewMap
 *              }
 *            ]
 *            expandable [...]
 *            hidden [...]
 *          }
 *          data { // entry data to display on the page.
 *            entries [
 *              {
 *                uuid
 *                name
 *                data
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 *
 *    getPageData('starbucks_page_uuid') {
 *      uuid: 'starbucks_page_uuid'
 *      ownerUuid: 'owner_uuid'
 *      layout [
 *        {
 *          component
 *          collectionUuid
 *        }
 *      ]
 *      collection [ // collection of collections, DOs, and items
 *        {
 *          type
 *          uuid
 *          fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *            visible [
 *              {
 *                 entryUuid
 *                 viewMap
 *              }
 *            ]
 *            expandable [...]
 *            hidden [...]
 *          }
 *          data { // entry data to display on the page.
 *            entries [
 *              {
 *                uuid
 *                name
 *                data
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 */

import React, { useState } from 'react';
import {  Button, message, Space, Divider } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import CollectionData from '../components/data/Collection';
import Page, { useWindowSize } from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
import RecordModal, { RecordModalAction } from '../components/modal/RecordModal';
import { FilterOutlined, PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

type CssProps = {
  bigWindow: boolean;
  windowWidth: number;
};

// background-color: lightGray;
const ListDiv = styled.div`
  position: flex;
  float: right;
  display: 'flex';
  max-width: 800px;
  padding-right: 10px; /* scrollbar */
  padding-left: 10px;
  padding-bottom: 15px;
  overflow: auto;
  ${(props: CssProps) => props.bigWindow ? `
  margin-left: auto;
  margin-right: auto;` : ''}
  text-align: center;
`;

type CollectionProps = RouteComponentProps & {
};

const Collection: React.FC<CollectionProps> = (props: CollectionProps) => {
  const [action, setAction] = useState<RecordModalAction>(RecordModalAction.None);
  const collectionUuid = (props.match.params as any).collectionUuid;


  // const [includeInactive, setIncludeInactive] = useState<boolean>((props.match.params as any).includeInactive === "true" ? true : false);

  const windowSize = useWindowSize();

  return (
    // TODO: Why does button onClick not work without a divider?
    // {addCodeError && (message.error(`Failed to add code: ${addCodeError}`))}
    <Page content={
      <Content>
        {action !== RecordModalAction.None && (
          <RecordModal
            data={{ pages: [] } /* editCode */}
            action={action}
            onCancel={() => {} /* onCancelCode */}
            onSubmit={() => {} /* onUpdate */}
          />
        )}
        <ListDiv bigWindow={windowSize.width !== undefined && windowSize.width > 800} windowWidth={windowSize.width ? windowSize.width : 800}>
          <Space direction='horizontal' style={{ float: 'right', marginTop: 10, marginBottom: 10 }}>
            <Button style={{ float: 'right' }} onClick={() => {} /* onChangeInactive */}><SettingOutlined /></Button>
            <Button style={{ float: 'right' }} onClick={() => {} /* onChangeInactive */}><FilterOutlined /></Button>
            <Button style={{ float: 'right' }} onClick={() => {} /* onAddCode */}><PlusCircleOutlined /></Button>
          </Space>
          <Divider style={{ margin: 0 }}/>
          <CollectionData uuids={[collectionUuid]}/>
        </ListDiv>
      </Content>
    }
    />
  );
};

//          <Table className='.antd-starbucks-code-table' size='small' scroll={{ y: windowSize.height ? windowSize.height - 300 : undefined }} columns={columns} dataSource={sCodes} bordered={true} pagination={{ pageSizeOptions: ['5', '10', '20'], showSizeChanger: true, defaultPageSize: 10  }}/>

export default Collection;
