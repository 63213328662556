import React from 'react';
import { Input, DatePicker, Select, Button, Form } from "antd";
import moment from 'moment';
import { T } from 'antd/lib/upload/utils';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DataEntryType } from '../../graphql/generated/graphql';
export const dateFormat = 'YYYY-MM-DD';

export const HorizontalList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const HorizontalListItem = styled.div`
  dipslay: inline;
  border: 1px solid lightgray;
  margin: 2px;
  padding-left 5px;
  padding-right 5px;
`;

const DeleteIcon = styled(MinusCircleOutlined)`
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
  .hover {
    color: #777;
  }
  .[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export enum RecordType {
  Action = 'Action',
  Checkbox = 'Checkbox',
  Date = 'Date',
  Dropdown = 'Dropdown',
  Formula = 'Formula',
  Index = 'Index',
  Input = 'Input',
  Image = 'Image',
  MultiSelect = 'Multi Select',
  Render = 'Render',
  Table = 'Table',
  Text = 'Text',
  TextValue = 'Text Value',
  Tree = 'Tree',
}

export enum FieldType {
  Action = 'Action',
  Checkbox = 'Checkbox',
  Date = 'Date',
  Dropdown = 'Dropdown',
  Formula = 'Formula',
  Image = 'Image',
  Index = 'Index',
  Input = 'Input',
  InputArray = 'InputArray',
  JsxElement = 'JsxElement',
  Table = 'Table',
  Text = 'Text',
  Tree = 'Tree',
}

export enum InputType {
  Boolean = 'Boolean',
  Date = 'Date',
  Dropdown = 'Dropdown',
  Integer = 'Integer',
  Link = 'Link',
  Image = 'Image',
  Money = 'Money',
  Number = 'Number',
  Regex = 'Regex',
  String = 'String',
}

/**
 * Get RecordType and InputType based on DataEntry type.
 *
 * @param type DataEntry type
 * @returns RecordType and InputType
 */
export function getDataEntryTypeToRecordType(type: DataEntryType): { recordType: RecordType; inputType: InputType } | null {
  switch (type) {
    /*
      Integer = 'integer',
  Boolean = 'boolean',
  Enum = 'enum',
  String = 'string',
  Date = 'date',
  Number = 'number',
  Link = 'link',
  Image = 'image',
  Formula = 'formula',
  DataObject = 'data_object'
    case DataEntryType.Checkbox: {
      return RecordType.Checkbox;
    } */
    case DataEntryType.Integer:
      return { recordType: RecordType.Input, inputType: InputType.Integer };
    case DataEntryType.Boolean:
      return { recordType: RecordType.Input, inputType: InputType.Boolean };
    case DataEntryType.Link:
      return { recordType: RecordType.Input, inputType: InputType.Link };
    case DataEntryType.Number:
      return { recordType: RecordType.Input, inputType: InputType.Number };
    case DataEntryType.String: {
      return { recordType: RecordType.Input, inputType: InputType.String };
    }
    case DataEntryType.Image: {
      return { recordType: RecordType.Image, inputType: InputType.Image };
    }
    case DataEntryType.Enum: {
      return { recordType: RecordType.Dropdown, inputType: InputType.String };
    }
    case DataEntryType.Formula: {
      return { recordType: RecordType.Formula, inputType: InputType.String };
    }
    case DataEntryType.Date: {
      return { recordType: RecordType.Date, inputType: InputType.Date };
    }
    case DataEntryType.DataObject:
    default: {
      return null;
    }
  }
}

export type DocumentEntrySchema = {
  name: string;
  type: RecordType | '';
  values?: any[];
  inputType?: InputType;
  value?: string;
  newRow?: boolean;
};

export type DocumentDataSchema = {
  name: string;
  description?: string;
  entries?: DocumentEntrySchema[]
  newRow?: boolean;
};

export function getEnumValue<T>(obj: T, value: string): T {
  return obj;
}

export function getEnumValues(type: any): string[] {
  console.log('getEnumValues');
  console.log(Object.keys(type));
  console.log(Object.keys(InputType));
  const values = Object.keys(type).filter((key: string) => isNaN(Number(key))); // .map((val: string) => type[val as keyof typeof type] as unknown as string);
  console.log(values);
  return values;
}

export type RecordProps = {
  placeholder?: string;
  value?: string;
  values?: string[];
  onChange?: (e: any) => void;
};

export type RecordElementProps = {
  recordType: RecordType;
  record: any;
  inputType?: InputType;
  recordProps?: RecordProps;
};

// export const RecordElement: React.FC<RecordElementProps> = (props: RecordElementProps): JSX.Element | null => {
export function getRecordElement(recordType: RecordType, record: any, inputType?: InputType, recordProps?: RecordProps): JSX.Element | null {
//  const { recordType, record, inputType, recordProps } = props;

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  // const [value, setValue] = useState('foo');
  if (recordType === RecordType.Input) {
    // TODO inputType.
    return <Input
      placeholder={recordProps ? recordProps.placeholder : undefined}
      value={recordProps ? recordProps.value : undefined}
    />;
  }
  if (recordType === RecordType.Index) {
    return <div>{recordProps ? recordProps.value : ''}</div>;
  }
  if (recordType === RecordType.Date) {
    // any value. TODO: provide ability to specify restrictions.
    return <DatePicker
      defaultValue={moment(recordProps ? recordProps.value : '')}
      format={dateFormat}
    />;
  }
  if (recordType === RecordType.Dropdown) {
    // inputType:
    // Drowdown: show values of recordProps.values and allow to select value
    // undefined: add/remove any value
    // else: add/remove values of specified type
    const values = recordProps && recordProps.values && recordProps.values.length > 0
      ? recordProps.values
      : (record.values ? record.values : []);
    if (inputType && inputType === InputType.Dropdown) {
      const dropdownOptions = values.map((item: any) => (
          <Select.Option key={`input-dropdown-${item}`} value={item}>{inputType && inputType === InputType.Dropdown ? null : <DeleteIcon onClick={(e: any) => recordProps && recordProps.onChange ? recordProps.onChange(record.values.filter((v: any) => v !== e.value)) : null} />}{item}</Select.Option>));
      if (!(inputType && inputType === InputType.Dropdown)) {
        dropdownOptions.push(<Select.Option key={`input-dropdown-add`} style={{ opacity: 0.5 }} value={'Add new option'}><PlusCircleOutlined /> Add new option</Select.Option>);
      }
      return <Select
        disabled={false}
        placeholder={recordProps ? recordProps.placeholder : undefined}
        onChange={recordProps ? recordProps.onChange : undefined}
      >{dropdownOptions}</Select>;
    }

    return (<Form.List name="fields">
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field, index) => (
            <Form.Item
              {...formItemLayoutWithOutLabel}
              required={false}
              key={field.fieldKey}
            >
              <Form.Item
                {...field}
                name={[index, 'value']}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    // whitespace: true,
                    message: "Please input option name or delete this option.",
                  },
                ]}
                noStyle
              >
                <Input placeholder="Add new option" defaultValue={field.name} style={{ width: '60%' }} />
              </Form.Item>
              {fields.length > 1 ? (
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  style={{ margin: '0 8px' }}
                  onClick={() => {
                    remove(field.name);
                  } } />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              } }
              style={{ width: '100%' }}
            >
              <PlusCircleOutlined /> Add new option
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>);
  }
  if (recordType === RecordType.Action) {
    return recordProps && recordProps.values ? <React.Fragment>{recordProps.values.map((item: any) => (
      <Button key={`input-action-${item}`} value={item}>{item}</Button>))}</React.Fragment>
      : null;
  }
  if (recordType === RecordType.Table) {
  }
  return null;
}
