import React, { useEffect, useState }  from 'react';
import { DeleteDataEntryMutation, useDeleteDataEntryMutation } from '../../../graphql/generated/graphql';
import { dataClientContext } from '../Api';

export type DeleteEntryProps = {
  dataUuid: string;
  objectUuid: string
  mocked?: boolean;
  onUpdate?: (data?: DeleteDataEntryMutation | null, loading?: boolean, error?: any) => any;
};

const DeleteEntry: React.FC<DeleteEntryProps> = (props: DeleteEntryProps) => {
  const { dataUuid, objectUuid, mocked, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [deleteDataEntryMutation, { data: deletedEntry, loading: loadingDelete, error: errorDelete }] = useDeleteDataEntryMutation({
    ...dataClientContext,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!executed) {
      console.log(`update entry data uuid '${dataUuid}' object uuid '${objectUuid}'`);
      setExecuted(true);
      if (mocked) {
        if (onUpdate) {
          onUpdate();
        }
        return;
      }
      deleteDataEntryMutation({ variables: { dataUuid, objectUuid } })
        .then((result: any) => {
          console.log(`done deleting entry '${dataUuid}' object uuid '${objectUuid}'`);
          console.log(result);
          /*
          if (!errorUpdate) {
            setExecuted(true);
            if (onUpdate) {
              onUpdate(result, loadingUpdate, errorUpdate);
            }
          }
          */
        })
        .catch((err: any) => {
          console.log(`error deleting entry '${dataUuid}' object uuid '${objectUuid}'`);
          console.log(err);
          // errorNotification();
        });
    }
    if (onUpdate) {
      onUpdate(deletedEntry, loadingDelete, errorDelete);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [deletedEntry, loadingDelete, errorDelete]);

  return <></>;
};

export default DeleteEntry;
