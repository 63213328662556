import { CheckCircleTwoTone, CloseSquareTwoTone, EditTwoTone } from '@ant-design/icons';
import { Input, Popconfirm, Dropdown, Menu, Select } from 'antd';
import React from 'react';
import { errorNotification } from '../actions/Notification';
import { MultiSelect } from '../atomic/Select';
import Tag, { TagItem } from '../atomic/Tag';
import { ResponsiveDiv } from '../styled/CenteredDiv';
import ViewEntry from '../views/Entry';
import { QRHistoryItem } from './QRHistory';

const { Option } = Select;

type QRHistoryItemViewEntryProps = {
  item: QRHistoryItem;
  // history: QRHistoryItem[];
  tags: TagItem[];
  isDisabled?: boolean;
  isNew?: boolean;
  isEdit?: boolean;
  onEdit: (item: QRHistoryItem | null) => void; // null to cancel editing
  onUpdate: (item: QRHistoryItem) => void;
  onUpdateTag: (item: QRHistoryItem) => void;
  onDelete: (deleteItem: QRHistoryItem) => void;
  onSelect: (item: QRHistoryItem) => void;
};

const QRHistoryItemViewEntry: React.FC<QRHistoryItemViewEntryProps> = (props: QRHistoryItemViewEntryProps) => {
  const { item, tags, isNew, isEdit, onUpdate, onUpdateTag, onDelete, onSelect, onEdit } = props;

  const isDisabled = props.isDisabled || isEdit;

  return (
    <ResponsiveDiv maxWidth='480px' key={`history-item-responsive-div-${item.uuid}`}>
      <ViewEntry
        key={`history-item-view-entry`}
        editPadding={isEdit ? '30px' : undefined}
        rightWidth={isNew ? undefined : '30px'}
        rightElement={isNew ? undefined : <>
          <div key={`div-open-item-${item.uuid}-${isDisabled}`}>
            <CheckCircleTwoTone
              key={`select-item-${item.uuid}`}
              style={{ fontSize: '22px', marginRight: '5px', marginTop: '5px', opacity: `${isDisabled ? '30%' : undefined}` }}
              onClick={() => isDisabled ? null : onSelect(item)}/>
          </div>
          <div key={`div-menu-item-${item.uuid}-${isDisabled}`} style={{ marginTop: '10px' }}><Dropdown
            key={`menu-item-${item.uuid}`}
            disabled={isDisabled}
            overlay={
              <Menu>
                <Menu.Item key={`edit-${item.uuid}`}>
                  <div onClick={() => {
                    onEdit(item);
                  }}>
                    Edit
                  </div>
                </Menu.Item>
                <Menu.Item key={`delete-${item.uuid}`}>
                  <Popconfirm
                    title="Delete item."
                    onConfirm={() => onDelete(item)}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                  ><div onClick={() => {}}>
                    Delete
                  </div></Popconfirm>
                </Menu.Item>
              </Menu>}>
            <EditTwoTone style={{ fontSize: '22px', opacity: `${isDisabled ? '30%' : undefined}` }} />
          </Dropdown></div>
        </>}
        header={<><Input
          prefix={<span style={{ width: '40px' }}>Name</span>}
          placeholder='Name'
          key={`input-history-name-${item.uuid}`}
          style={{ maxWidth: '100%', marginBottom: '2px' }}
          defaultValue={item.name}
          value={item.name}
          disabled={!isEdit}
          onChange={(e: any) => {
            const newValue = { ...item };
            newValue.name = e.target.value;
            onEdit(newValue);
          }}></Input>
        <Input
          prefix={<span style={{ width: '40px' }}>Url</span>}
          placeholder='Url'
          key={`input-history-value-${item.uuid}`}
          style={{ maxWidth: '100%', marginBottom: '2px' }}
          defaultValue={item.url}
          value={item.url}
          disabled={!isEdit}
          onChange={(e: any) => {
            const newValue = Object.assign({}, item);
            newValue.url = e.target.value;
            onEdit(newValue);
          }}></Input>
          <MultiSelect
            disabled={isDisabled && !isEdit}
            key={`history-item-tags-${item.uuid}`}
            items={{ values: tags ?? [], render: (renderTag: TagItem) => {
              return <Tag
              key={`history-item-tag-${item.uuid}`}
              value={renderTag}
              icon='close'
              onClick={(clickedTag: TagItem) => {
                const updateTags = { ...item };
                updateTags.tagUuids = updateTags.tagUuids.filter((tagUuid: string) => tagUuid !== clickedTag.uuid);
                onUpdateTag(updateTags);
                // ?
                /*
                if (props.newItem && editItem) {
                  const updateEditItem = { ...editItem };
                  updateEditItem.tagUuids = updateEditItem.tagUuids.filter((uuid: string) => uuid !== item.name);
                  infoNotification(`Tag removed: ${item.name}`, 'undo', () => {
                    const updateEditItem = { ...editItem };
                    if (updateEditItem.tagUuids.find((uuid: string) => uuid === item.uuid)) {
                      infoNotification(`This item is already tagged with ${item.name}`);
                      return;
                    }
                    updateEditItem.tagUuids = [...updateEditItem.tagUuids, item.uuid ?? item.name];
                    setEditItem(updateEditItem);
                    setLastUpdate(new Date().toLocaleString());
                    infoNotification(`Undo complete. Tag added: ${item.name}`);
                  });
                  return;
                }
                const updateItems = [...history];
                const updateIndex = updateItems.findIndex((findItem: QRHistoryItem) => findItem.uuid === item.uuid);
                updateItems[updateIndex].tagUuids = updateItems[updateIndex].tagUuids.filter((uuid: string) => uuid !== item.uuid);
                setHistory(updateItems);
                // setHistoryUpdated(true);
                infoNotification(`Tag removed: ${item.name}`, 'undo', () => {
                  if (history[index].tagUuids.find((uuid: string) => uuid === item.uuid)) {
                    infoNotification(`This item is already tagged with ${item.name}`);
                    return;
                  }
                  const updateItems = [...history];
                  const updateIndex = updateItems.findIndex((findItem: QRHistoryItem) => findItem.uuid === item.uuid);
                  updateItems[updateIndex].tagUuids = [...updateItems[updateIndex].tagUuids, item.uuid ?? item.name];
                  setHistory(updateItems);
                  // setHistoryUpdated(true);
                  setLastUpdate(new Date().toLocaleString());
                  infoNotification(`Undo complete. Tag added: ${item.name}`);
                });
                */
              }}
              />;
            }}}
            defaultValue={item.tagUuids ? item.tagUuids : []}
            placeholder='Add or remove tag'
            onChange={(values: string[]) => {
              // infoNotification(`onChange clicked with values ${values}`);
              const updateTags = { ...item };
              updateTags.tagUuids = values;
              onUpdateTag(updateTags);
              // ?
              /*
              if (props.newItem && editItem) {
                const updateEditItem = { ...editItem };
                updateEditItem.tagUuids = values;
                setEditItem(updateEditItem);
                return;
              }
              const updateItems = [...history];
              updateItems[index].tagUuids = values;
              setHistory(updateItems);
              // setHistoryUpdated(true);
              */
            }}
            optionLabelProp='label'
          >
            {tags ? tags.map((tag: TagItem) => <Option key={`history-item-option-${tag.uuid}-${tag.name}`} value={tag.uuid ?? tag.name} label={tag.name}>
              <Tag key={`history-item-option-tag-${tag.uuid}-${tag.name}`} value={tag} />
            </Option>) : null}
          </MultiSelect></>}
        editButtons={isEdit && !isNew ? <>
        <CheckCircleTwoTone style={{ fontSize: '22px', marginRight: '5px' }} twoToneColor='#52c41a' onClick={() => {
          if (!item) {
            errorNotification();
            return;
          }
          if (item.name.length === 0) {
            errorNotification('Please enter a valid name');
            return;
          }
          if (item.url.length === 0) {
            errorNotification('Please enter a valid value');
            return;
          }
          /*
          const duplicateItem = history.find((existingItem: QRHistoryItem) => existingItem.url === editItem.url || existingItem.name === editItem.name);
          if (duplicateItem) {
            if (duplicateItem.url === item.url) {
              errorNotification('Item url must be unique.');
              return;
            }
            if (duplicateItem.name === item.name) {
              errorNotification('Item name must be unique');
              return;
            }
          }*/
          const updateEditItem = { ...item };
          onUpdate(updateEditItem);
          // onEdit(updateEditItem);
          /*
          const duplicateItem = history.find((item: QRHistoryItem) => item.uuid !== editItem.uuid && (item.url === editItem.url || item.name === editItem.name));
          if (duplicateItem) {
            if (duplicateItem.url === editItem.url) {
              errorNotification(<div>Duplicate item already exists with {<span style={{ fontWeight: 'bold' }}>url</span>} value:<pre>{editItem.url}</pre>Item name is <span style={{ fontWeight: 'bold' }}>{editItem.name}</span></div>);
            }
            else if (duplicateItem.name === editItem.name) {
              errorNotification(<div>Duplicate item already exists with <span style={{ fontWeight: 'bold' }}>name</span> value <span style={{ fontWeight: 'bold' }}>{editItem.name}</span></div>);
            }
            return;
          }
          const updateHistory = history;
          const itemIndex = history.findIndex((historyItem: QRHistoryItem) => historyItem.uuid === editItem.uuid);
          if (itemIndex !== -1) {
            updateHistory[itemIndex].name = editItem.name;
            updateHistory[itemIndex].url = editItem.url;
          }
          setHistory(updateHistory);
          setEditItem(null);
          // setHistoryUpdated(true);
          */
        }} />
        <Popconfirm
          title='Discard item changes.'
          onConfirm={() => {
            onEdit(null);
            // setEditItem(null);
          }}
          onCancel={() => { /* Continue editing item */ }}
          okText='Discard'
          cancelText='Cancel'
        >
          <CloseSquareTwoTone style={{ fontSize: '22px' }} twoToneColor='red' />
        </Popconfirm>
      </>
      : undefined}
    />
  </ResponsiveDiv>
  );
};

export default QRHistoryItemViewEntry;
