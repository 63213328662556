import * as React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import RecordModal, { ModalFields, RecordModalAction } from '../../../components/modal/RecordModal';
import { RecordType } from '../../../components/table/DataTypes';
import { DataCollectionInput, useDeleteDataCollectionMutation, useUpdateDataCollectionMutation } from '../../../graphql/generated/graphql';
import { dataClientContext } from '../Api';
import { Dictionary } from 'lodash';

type EditCollectionMenuProps = {
  uuid: string;
  initialData?: Dictionary<string>
};

const EditCollectionMenu: React.FC<EditCollectionMenuProps> = (props: EditCollectionMenuProps) => {
  const { uuid, initialData } = props;
  const [action, setAction] = React.useState<RecordModalAction>(RecordModalAction.None);
  const [updateDataCollectionMutation, { /* data: updatedCollection, */ loading: loadingUpdate, error: errorUpdate }] = useUpdateDataCollectionMutation({
    ...dataClientContext,
  });
  const [deleteDataCollectionMutation, { /* data: deletedCollection, */ loading: loadingDelete, error: errorDelete }] = useDeleteDataCollectionMutation({
    ...dataClientContext,
  });

  const itemEditMenu = () => (
    <Menu>
      {['edit', 'delete'].map((item: string) => {
        return <Menu.Item key={item} onClick={(event:any) => {
          setAction(item === 'edit' ? RecordModalAction.Update : RecordModalAction.Delete);
        }}>{item}</Menu.Item>;
      })}
    </Menu>);

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          Name: { placeholder: 'Enter name', type: RecordType.Input, value: initialData && initialData.Name ? initialData.Name : '' },
          Description: { placeholder: 'Enter description', type: RecordType.Input, value: initialData && initialData.Description ? initialData.Description : '' },
        },
      },
    ],
  };

  const onUpdateCollection = async (record: any) => {
    const dataCollection: DataCollectionInput = { uuid, name: 'new', description: 'collection', ordinal: Number.parseInt(initialData ? initialData.ordinal : '-1') };
    const result = await updateDataCollectionMutation({ variables: { dataCollection } });
    console.log('create new collection');
    console.log(result);
    if (!errorDelete && !errorUpdate) {
      setAction(RecordModalAction.None);
    }
  };

  const onDeleteCollection = async (record: any) => {
    const result = await deleteDataCollectionMutation({ variables: { uuid } });
    console.log('create new collection');
    console.log(result);
    if (!errorDelete && !errorUpdate) {
      setAction(RecordModalAction.None);
    }
  };

  return <>
    {action !== RecordModalAction.None && (
      <RecordModal
        key={'update-collection-modal'}
        data={recordDataValues}
        action={action}
        onCancel={() => {
          setAction(RecordModalAction.None);
        }}
        onSubmit={action === RecordModalAction.Delete ? onDeleteCollection : (action === RecordModalAction.Update ? onUpdateCollection : (data: any) => {}) }
        isLoading={loadingUpdate || loadingDelete}
        error={errorUpdate ? errorUpdate.message : (errorDelete ? errorDelete.message : undefined)}
      />
    )}
    <Dropdown overlay={itemEditMenu()}>
      <MenuOutlined onClick={(event: any) => event.stopPropagation()} />
    </Dropdown>
  </>;
};

export default EditCollectionMenu;
