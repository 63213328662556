import { PlusCircleOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import EnclosedIcon, { IconProps } from './EnclosedIcon';

const AddStyled = styled(PlusCircleOutlined)`
  z-index: 2000;
  font-size: 14px;
  margin-right: 0px;
  ${(props: { color?: string }) => props.color ? `color: ${props.color};` : null}
`;

type AddIconProps = IconProps & {
};

const AddIcon: React.FC<AddIconProps> = (props: AddIconProps) => {
  const { onClick, enclosed, color, disabled, selected } = props;
  const icon = <AddStyled color={color ?? undefined} />;

  return <EnclosedIcon
    color={color}
    icon={icon}
    enclosed={enclosed}
    disabled={disabled}
    selected={selected}
    onClick={onClick} />;
};

export default AddIcon;
