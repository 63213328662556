import React from "react";
import { useWindowSize } from '../layout/Page';
import styled from 'styled-components';
import { TextAlignProperty } from 'csstype';

type EntryProps = {
  loading?: boolean;
  editPadding?: number | string;
  editButtons?: JSX.Element | JSX.Element[];
  leftElement?: JSX.Element;
  leftWidth?: number | string;
  rightElement?: JSX.Element;
  rightWidth?: number | string;
  header: JSX.Element;
  headerAlign?: TextAlignProperty;
  content?: JSX.Element;
  contentAlign?: boolean;
  selected?: boolean;
  hasError?: boolean;
};

type ViewDivProps = {
  maxWidth?: number | string;
  bottomPadding?: number | string;
  selected?: boolean;
  hasError?: boolean;
};

const ViewDiv = styled.div`
  max-width: ${(props: ViewDivProps) => props.maxWidth  ? (isNaN(Number(props.maxWidth)) ? props.maxWidth : `${props.maxWidth}px`) : '800px'};
  position: relative;
  border: ${(props: ViewDivProps) => props.selected ? '1px solid blue' : (props.hasError ? '1px solid red' : '1px solid lightgrey')};
  #background-color: #f0f0f0;
  ${(props: ViewDivProps) => props.selected ? 'background-color: lightyellow;' : (props.hasError ? 'background-color: snow;' : null)}
  padding: 8px;
  border-radius: 5px;
  margin: 4px 0px 4px;
  overflow-wrap: break-word;
  width: 100%;
  ${(props: ViewDivProps) => props.bottomPadding  ? `padding-bottom: ${props.bottomPadding}` : null}
`;

const ViewEntry: React.FC<EntryProps> = (props: EntryProps) => {
  const { editButtons, editPadding, leftElement, leftWidth, rightElement, rightWidth, header, headerAlign, content, contentAlign, selected, hasError } = props;
  const windowSize = useWindowSize();

  return (
    <ViewDiv key='entry-view-div' maxWidth={windowSize.width} bottomPadding={editPadding} selected={selected} hasError={hasError}>
      {leftElement ? <div style={{ position: 'absolute', width: `${leftWidth}` }} >{leftElement}</div> : null}
      <div style={{ fontWeight: 'bold', marginLeft: leftWidth ? leftWidth : undefined, marginRight: rightWidth ? rightWidth : undefined, textAlign: headerAlign ? headerAlign : undefined }}>
        {header}
        {editButtons
          ? <div style={{ float: 'right', marginBottom: '0px', marginTop: '2px' }}>
              {editButtons}
            </div>
          : null}
      </div>
      {rightElement
        ? <div key='entry-view-right-element' style={{ position: 'absolute', float: 'right', top: '5px', right: '0px' }}>
            {rightElement}
          </div>
        : null}
      {contentAlign
        ? <div style={{ marginLeft: leftWidth, marginRight: rightWidth, textAlign: headerAlign ? headerAlign : undefined }}>
            {content}
          </div>
        : content}
    </ViewDiv>);
};

  // const extra = collapseable ? (key: string) => { prevShowContent[key] ?  } : undefined;

export default ViewEntry;
