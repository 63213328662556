import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { default as PageComponent } from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
import ViewCollapsable from '../components/views/Collapsable';
import { GetDataPage } from '../containers/api/DataPage';
import { ResultViewEnum } from '../containers/api/Helpers';
import _ from 'lodash';
import { GetDataCollectionsById } from '../containers/api/Collection/DataCollection';

// import { GetDataObjects } from '../containers/api/DataObject';

type PageProps = RouteComponentProps & {
};

const Page: React.FC<PageProps> = (props: PageProps) => {
  const pageUuid = (props.match.params as any).pageUuid;
  const [pageData, setPageData] = useState<any>(null);
  const [collectionData, setCollectionData] = useState<any>(null);
  const [collectionUuids, setCollectionUuids] = useState<string[]>([]);
  const dataPages = <GetDataPage uuid={pageUuid} view={ResultViewEnum.Json} updateApiResponse={(data: any) => setPageData(data)}/>;
  const dataCollections = <GetDataCollectionsById collectionUuids={collectionUuids} view={ResultViewEnum.Json} updateApiResponse={(data: any) => setCollectionData(data)}/>;

  useEffect(() => {
    if (pageData && pageData.data) {
      const uuids = pageData.data.filter((collection: any) => collection.type === 'collection').map((collection: any) => collection.itemUuid);
      const prevCount = collectionUuids.length;
      const unionUuids = _.union(collectionUuids, uuids);
      // TODO: fix for checking new counts
      if (prevCount !== unionUuids.length) {
        setCollectionUuids(unionUuids);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [pageData]);

  useEffect(() => {
    /*
    if (collectionData) {
      const uuids = collectionData.map((c: any) => {
        return c.filter((collection: any) => collection.type === 'collection').map((collection: any) => collection.itemUuid);
      });
      const prevCount = collectionUuids.length;
      const unionUuids = _.union(collectionUuids, uuids);
      // TODO: fix for checking new counts
      if (prevCount !== unionUuids.length) {
        setCollectionUuids(unionUuids);
      }
    } */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [collectionData]);

  console.log('Pages pagesData');
  console.log(pageData);

  const headerTitle = pageData && pageData.name ? pageData.name : 'Page';
  const data = collectionData ? []
    /* collectionData.map((collection: any, index: number) => {
    return collection.data.map((d: any) => {
      const extra: any[] = [];
      extra.push(<div>owner: {d.ownerUuid}</div>);
      if (d.description) {
        extra.push(<div>description: {d.description}</div>);
      }

      return ({
        header: [<a href={`/pages/${d.uuid}`}>{d.name}</a>],
        extra,
      });
    });
  }) */ : [];

  return (
  <PageComponent headerTitle={headerTitle} content={
      <Content>
        {dataPages}
        {dataCollections}
        {pageData && false ? <ViewCollapsable sourceName='page' style={{ top: 15, maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }} data={data} mapping={null} /> : null}
        {collectionData ? <ViewCollapsable sourceName='collection' style={{ top: 15, maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }} data={data} mapping={null} /> : null}
      </Content>
    }
    />
  );
};

export default Page;
