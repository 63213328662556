import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import isUrl from 'is-url';
import { Guid } from "guid-typescript";
import { default as PageComponent } from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
import QRReader from '../components/qrcode/QRReader';
import { isMobile, isIOS, isSafari } from 'react-device-detect';
import { Dictionary } from 'lodash';
import { Alert, Checkbox, Divider, Radio, Space, Tabs } from 'antd';
import { getWindowDimension } from '../components/styled/ScreenSize';
import { CloseCircleTwoTone, EditTwoTone, FullscreenOutlined, PlusCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import QRSelectUsers, { QRTrackUser } from '../components/qrcode/QRSelectUsers';
import { CenteredDiv, ResponsiveDiv } from '../components/styled/CenteredDiv';
import QRReaderComponent from '../components/qrcode/QRReaderComponent';
import QRScanner from '../components/qrcode/QRScanner';
import WizardTooltip from '../components/wizard/WizardTooltip';
import QRHistory, { QRHistoryItem } from '../components/qrcode/QRHistory';
import Button from '../components/atomic/Button';
import Tag, { TagColor, TagItem } from '../components/atomic/Tag';
import { TutorialType } from '../components/tutorial/Tutorial';

const { TabPane } = Tabs;

type QRTrackerProps = RouteComponentProps & {
};

export enum ScannerType {
  Scanner = 'Scanner',
  ReaderComponent = 'ReaderComponent',
  Reader = 'Reader',
}

export enum TrackerWizard {
  ScanButton = 'ScanButton',
  AddUserButton = 'AddUserButton',
  SelectUsersButton = 'SelectUsersButton',
}

const QRTracker: React.FC<QRTrackerProps> = (props: QRTrackerProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [lastScanned, setLastScanned] = useState<string>(new Date().toLocaleString());
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  // Only saved when history component is closed. Otherwise all state stored in QRHistory component
  const [history, setHistory] = useState<QRHistoryItem[]>([]);
  const [isEnglish, setIsEnglish] = useState<boolean>(true);
  const [consentChecked, setConsentChecked] = useState<boolean>(false);
  const [lowRisk, setLowRisk] = useState<boolean>(true);
  const [submit, setSubmit] = useState<boolean>(false);
  const [isMySejahtera, setIsMySejahtera] = useState<boolean>(false); // TODO FOR DEBUG
  const [users, setUsers] = useState<Dictionary<QRTrackUser> | null>(null);
  const [selectUsers, setSelectUsers] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  const [addHistoryCode, setAddHistoryCode] = useState<JSX.Element | null>(null);
  const [url, setUrl] = useState<string>(''); // TODO FOR DEBUG 'https://mysejahtera.malaysia.gov.my/qrscan'
  const [scan, setScan] = useState<boolean>(false);
  const [loadImage, setLoadImage] = useState<boolean>(false);
  const [embedPage, setEmbedPage] = useState<boolean>(false);
  const [warningRead, setWarningRead] = useState<string | null>('skip warning');
  const [testPixel, setTestPixel] = useState<number>(9);
  const [error, setError] = useState<JSX.Element | null>(null);
  const [wizard, setWizard] = useState<Dictionary<boolean>>(Object.assign({}, ...[TrackerWizard.ScanButton, TrackerWizard.AddUserButton, 'initial'].map((component: string) => ({ [component]: true }))));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isLoaded) {
      return;
    }
    const lastState = localStorage.getItem('s2p-tracker-last-state');
    const lastStateObj = lastState ? JSON.parse(lastState) : null;
    console.log('load last state');
    console.log(lastStateObj);
    if (lastStateObj) {
      if (lastStateObj.lastScanned) {
        setLastScanned(lastStateObj.lastScanned);
      }
      if (lastStateObj.fullScreen) {
        setFullScreen(lastStateObj.fullScreen);
      }
      if (lastStateObj.isEnglish) {
        setIsEnglish(lastStateObj.isEnglish);
      }
      if (lastStateObj.consentChecked) {
        setConsentChecked(lastStateObj.consentChecked);
      }
      if (lastStateObj.lowRisk) {
        setLowRisk(lastStateObj.lowRisk);
      }
      if (lastStateObj.submit) {
        setSubmit(lastStateObj.submit);
      }
      if (lastStateObj.isMySejahtera) {
        setIsMySejahtera(lastStateObj.isMySejahtera);
      }
      if (lastStateObj.url) {
        setUrl(lastStateObj.url);
      }
    }
    setIsLoaded(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    const lastState = ({
      lastScanned,
      fullScreen,
      isEnglish,
      consentChecked,
      lowRisk,
      submit,
      isMySejahtera,
      url,
    });
    console.log('save last state');
    console.log(lastState);
    localStorage.setItem('s2p-tracker-last-state', JSON.stringify(lastState));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [isLoaded, lastScanned, fullScreen, isEnglish, consentChecked, lowRisk, submit, isMySejahtera, url]);

  useEffect(() => {
    return () => {
      if (!fullScreen && url !== '' && isMySejahtera && lowRisk && submit && window.location.href.endsWith('#submit-full-screen')) {
        // setFullScreen(true);
      }
      if ((fullScreen && (url === '' || (url !== '' && !isMySejahtera))) && window.location.href.endsWith('#submit-full-screen')) {
        setFullScreen(false);
        window.history.back();
      }
      if (fullScreen && !window.location.href.endsWith('#submit-full-screen')) {
        setFullScreen(false);
      }
      if ((viewHistory || addHistoryCode) && !window.location.href.endsWith('#history')) {
        setViewHistory(false);
        setAddHistoryCode(null);
      }
      if ((selectUsers || addHistoryCode) && !window.location.href.endsWith('#select-users')) {
        setSelectUsers(false);
      }
      /*
      if (fullScreen && !window.location.href.endsWith('#submit-full-screen')) {
        window.history.forward();
      } */
    };
  });

  useEffect(() => {
    if (users === null) {
      const loadUsers = localStorage.getItem('s2p-MySejahtera');
      const loadSelectedUsers = localStorage.getItem('s2p-MySejahtera-selected-users');
      const loadWarningRead = localStorage.getItem('s2p-MySejahtera-warning');
      const loadSubmit = localStorage.getItem('s2p-MySejahtera-submit');
      const loadHistory = localStorage.getItem('s2p-MySejahtera-history');

      if (loadUsers) {
        const usersObj = JSON.parse(loadUsers);
        setUsers(usersObj);
        if (loadSelectedUsers) {
          setSelectedUsers((loadSelectedUsers as string).split(';').filter((user: string) => usersObj[user]));
        }
        // load if read today, otherwise show warning again
        if (loadWarningRead) {
          setWarningRead(loadWarningRead);
        }
      }
      else {
        setUsers({});
        setSelectedUsers([]);
      }
      const loadWizard = localStorage.getItem('s2p-scan-wizard');
      if (loadWizard) {
        setWizard(JSON.parse(loadWizard));
      }
      else {
        setWizard({ initial: false });
      }
      const submitValue = loadSubmit && loadSubmit !== '' ? true : false;
      setSubmit(submitValue);
      setConsentChecked(submitValue);
      if (loadHistory) {
        const historyItems = JSON.parse(loadHistory);
        for (const historyItem of historyItems) {
          if (!historyItem.uuid) {
            historyItem.uuid = Guid.create().toString();
          }
        }
        setHistory(historyItems);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (users !== null) {
      localStorage.setItem('s2p-MySejahtera-selected-users', selectedUsers.join(';'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [selectedUsers]);

  /*
  useEffect(() => {
    if (history !== null) {
      localStorage.setItem('s2p-MySejahtera-history', JSON.stringify(history));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [history]);
  */

  useEffect(() => {
    if (wizard !== null && !wizard.initial) {
      localStorage.setItem('s2p-scan-wizard', JSON.stringify(wizard));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [wizard]);

  const onUpdateQRCode = (data?: any, err?: any) => {
    console.log(`Scan done with data '${data}' and err '${err}'`);
    setScan(false);
    setLoadImage(false);
    if (err) {
      setError(<><div>`Error found while scanning your QR code. You can try loading an image of a QR code instead.</div><div><span style={{ fontWeight: 'bold' }}>Error message:</span> {err}</div></>);
      // setError(<><div>Error found while {loadImage ? 'processing your QR image.' : `scanning your QR code. You can try loading an image of a QR code instead.`}</div><div><span style={{ fontWeight: 'bold' }}>Error message:</span> {err}`</div></>);
      // setError(<><div>`Error found while scanning your QR code. You can try loading an image of a QR code instead.</div><div><span style={{ fontWeight: 'bold' }}>Error message:</span> {err}</div></>);
    }
    else if (data) {
      // in case url is from mysejahtera and it's missing / between qrscan and url params, insert the slash.
      const newUrl = (data as string).replace('https://mysejahtera.malaysia.gov.my/qrscan?', 'https://mysejahtera.malaysia.gov.my/qrscan/?');
      const newIsMySejahtera = data.startsWith('https://mysejahtera.malaysia.gov.my/qrscan');
      console.log(`QR Code Url: ${newUrl}. QR Code is MySejahtera: ${newIsMySejahtera}`);
      setIsMySejahtera(newIsMySejahtera);
      setUrl(newUrl);
      if (window.location.href.endsWith('qr#scan')) {
        window.history.back();
      }
    }
  };

  const onCancelQRCode = () => {
    console.log('cancel scanning');
    setScan(false);
    setLoadImage(false);
    if (window.location.href.endsWith('qr#scan')) {
      window.history.back();
    }
  };

  const getQRReader = () => {
    if (!scan) {
      return null;
    }
    return (testPixel > 0 && !error)
      ? <QRReader
          key={lastScanned}
          modal={false}
          onCancel={onCancelQRCode}
          onUpdate={onUpdateQRCode}
        />
     : (((testPixel < 0 && testPixel > -5) || error)
        ? <QRReaderComponent
            key={lastScanned}
            modal={false}
            loadImage={loadImage}
            onCancel={onCancelQRCode}
            onUpdate={onUpdateQRCode}
          />
          : <QRScanner
              key={lastScanned}
              modal={false}
              onCancel={onCancelQRCode}
              onUpdate={onUpdateQRCode}
        />);
  };

  const reset = () => {
    setUrl('');
    // setConsentChecked(false);
    // setSubmit(false);
    setScan(false);
    setFullScreen(false);
    setEmbedPage(false);
    setIsMySejahtera(false);
    setLoadImage(false);
  };

  const radioSelect =
    <Radio.Group disabled={submit} onChange={(e: any) => setLowRisk(e.target.value === 1)} value={lowRisk ? 1 : 2} defaultValue={lowRisk ? 1 : 2}>
      <Radio value={1}>{isEnglish ? 'No' : 'Tidak'}</Radio>
      <Radio value={2}>{isEnglish ? 'Yes' : 'Ya'}</Radio>
    </Radio.Group>;

  const consentCheckbox = <>
    <Checkbox id='consentCheckbox' disabled={submit} defaultChecked={submit} onChange={(e: any) => {
      setConsentChecked(e.target.checked);
    }}>{isEnglish ? 'Agree' : 'Setuju'}</Checkbox>
    {consentChecked ? null : <div style={{ color: 'red' }}><WarningTwoTone style={{ marginRight: '4px', position: 'relative' }} type='message' twoToneColor='red'/>{isEnglish ? 'You must click on Agree above.' : 'Anda mesti klik Setuju di atas.'}</div>}
    </>;

  const submitButton = <Link to="qr#submit-full-screen" onClick={(e: any) => {
    setSubmit(true);
    // setFullScreen(lowRisk);
    if (lowRisk) {
      // setEmbedPage(false);
      localStorage.setItem('s2p-MySejahtera-submit',  new Date().toLocaleString());
    }
  }}><Button style={{ marginBottom: '4px' }} disabled={!consentChecked}>{isEnglish ? 'Submit' : 'Hantar'}</Button></Link>;

  const englishConsent =
    <ResponsiveDiv breakWord maxWidth='800px' style={{ border: '1px solid', marginBottom: submit ? '10px' : '50px' }}>
      <div style={{ fontWeight: 'bold', wordWrap: 'break-word', marginLeft: '10px' }}>In the last 14 days, have you:*{submit ? <EditTwoTone style={{ marginLeft: '5px', fontSize: '14px' }} onClick={() => {
        setSubmit(false);
        localStorage.setItem('s2p-MySejahtera-submit', '');
      }} /> : null}</div>
      <div style={{ marginLeft: '10px', marginRight: '10px', textAlign: 'left', wordWrap: 'break-word' }}>Been exhibiting any COVID-19 symptoms (fever, cough, shortness of breath, sore throat)?</div>
      <div style={{ marginLeft: '10px', marginRight: '10px', textAlign: 'left', wordWrap: 'break-word' }}>Had close contact with COVID-19 positive patient?{submit ? <span style={{ marginLeft: '10px' }} >{radioSelect}</span>  : <><br/>{radioSelect}</>}</div>
      <br />
      <div style={{ marginLeft: '10px', marginRight: '10px', textAlign: 'left', wordWrap: 'break-word' }}>I hereby acknowledge that the information given in this form is correct and accurate.{submit ? <span style={{ marginLeft: '10px' }} >{consentCheckbox}</span> : <><br/>{consentCheckbox}</>}</div>
      {submit ? null : <><br/>
      <Space style={{ marginLeft: '10px' }} wrap>{submitButton} OR <Button href={url} target='_blank' style={{ marginBottom: '4px', fontWeight: 'bold', color: 'navy', border: '1px solid', borderColor: 'navy' }}>Open empty form in a new tab</Button></Space>
      <br/></>}
    </ResponsiveDiv>;

  const malayConsent =
    <ResponsiveDiv breakWord maxWidth='800px' style={{ border: '1px solid', marginBottom: submit ? '10px' : '50px' }}>
      <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>Dalam 14 hari yang lalu, adakah anda:*{submit ? <EditTwoTone style={{ marginLeft: '5px', fontSize: '14px' }} onClick={() => {
        setSubmit(false);
        localStorage.setItem('s2p-MySejahtera-submit', '');
      }} /> : null}</div>
      <div style={{ marginLeft: '10px', marginRight: '10px', textAlign: 'left' }}  >Mengalami sebarang simptom COVID-19 (demam, batuk, sesak nafas, sakit tekak)?</div>
      <div style={{ marginLeft: '10px', marginRight: '10px', textAlign: 'left' }}>Berhubung rapat dengan pesakit positif COVID-19?{submit ? <span style={{ marginLeft: '10px' }} >{radioSelect}</span>  : <><br/>{radioSelect}</>}</div>
      <br />
      <div style={{ marginLeft: '10px', marginRight: '10px', textAlign: 'left' }}>Dengan ini, saya mengakui bahawa butiran yang diisi adalah betul dan tepat.*{submit ? <span style={{ marginLeft: '10px' }} >{consentCheckbox}</span> : <><br/>{consentCheckbox}</>}</div>
      {submit ? <br/> : <><br/>
      <Space style={{ marginLeft: '10px' }} wrap>{submitButton} OR <Button href={url} target='_blank' style={{ marginBottom: '4px' }}>Buka borang kosong dalam tab baru</Button></Space>
      <br/></>}
    </ResponsiveDiv>;

  const updateWizard = (component: TrackerWizard, hide: boolean) => {
    if (wizard[component] !== hide) {
      const updateWizardState = Object.assign({}, wizard);
      updateWizardState[TrackerWizard.ScanButton] = true;
      updateWizardState[component] = hide;
      updateWizardState.initial = false;
      setWizard(updateWizardState);
    }
  };

  return (
    <PageComponent headerTitle={'QR Scanner'} content={
      <Content>
        {error ? <Alert
            message={error}
            type="warning"
            closable
            onClose={() => setError(null)}
            /> : null}
        {url === ''
          ? <ResponsiveDiv isCentered maxWidth={'800px'}>
            <br />
            {isMobile && isIOS && !isSafari ? <Alert
              message="If Camera is not working, please try using Safari browser"
              type="warning"
              closable
              onClose={() => {}}
            /> : null}
            <Space wrap style={{ marginBottom: '15px' }}>
            <WizardTooltip currentPage={TutorialType.QRTracker} key='wizard-scan-button' defaultVisible={false} visible={!wizard[TrackerWizard.ScanButton]} onClose={() => updateWizard(TrackerWizard.ScanButton, true)} title='ignore' placement="bottom" message='Use this button to start and stop scanning'>
            <Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '150px' }} onClick={() => {
              const stopScan = scan;
              if (!scan) {
                const updateLastScan = new Date().toLocaleString();
                console.log(`start scanning at ${updateLastScan}`);
                setLastScanned(updateLastScan);
              }
              setScan(!scan);
              setLoadImage(false);
              if (stopScan && window.location.href.endsWith('qr#scan')) {
                window.history.back();
              }
              updateWizard(TrackerWizard.ScanButton, true);
            }}>{scan ? 'STOP SCANNING' : 'SCAN QR CODE'}</Button></WizardTooltip>
            {(history.length > 0) ? <Link to="qr#history" onClick={() => {
              setViewHistory(true);
              if (window.location.href.endsWith('#history')) {
                window.history.back();
              }
            }}><Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '150px' }} >View History</Button></Link> : null}
            {((testPixel < 0 && testPixel > -5) || error) ? <Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '150px' }} onClick={() => {
              setScan(true);
              setLoadImage(true);
            }}>LOAD QR IMAGE</Button> : null}</Space></ResponsiveDiv>
          : <>
              <br />
              <ResponsiveDiv isCentered maxWidth={'800px'}>
                <Space wrap>
                  {false && <Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }} onClick={() => reset()}>Reset</Button>}
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}
                    onClick={() => {
                      reset();
                      setScan(true);
                    }}>Rescan</Button>
                  <Link to="qr#history" onClick={() => {
                    const urlParams = url.split('?');
                    const parseParams = urlParams.length === 2 ? urlParams[1].split('&') : [];
                    const locationParam = parseParams.find((param: string) => param.startsWith('ln='));
                    const encodedLocationParam = parseParams.find((param: string) => param.startsWith('eln='));
                    const location = locationParam ? locationParam.split('=')[1].replaceAll('_', ' ') : (encodedLocationParam ? window.atob(encodedLocationParam.split('=')[1]) : '');
                    setAddHistoryCode(<QRHistory
                      key={`add-history-${location}`}
                      initialHistory={history}
                      newItem={{ date: new Date().toLocaleString(), name: location, url, tagUuids: [], uuid: Guid.create().toString() }}
                      onSelect={() => {}}
                      onUpdate={(updateHistory: QRHistoryItem[]) => {
                        setHistory(updateHistory);
                        setViewHistory(false);
                        setAddHistoryCode(null);
                        if (window.location.href.endsWith('#history')) {
                          window.history.back();
                        }
                      }}
                      onCancel={() => {
                        setViewHistory(false);
                        setAddHistoryCode(null);
                        if (window.location.href.endsWith('#history')) {
                          window.history.back();
                        }
                      }} />);
                  }
                  }><Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>Save</Button></Link>
                  <Link to="qr#history" onClick={() => {
                    setViewHistory(true);
                  }}><Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '80px' }}>History</Button>
                  </Link>
                  {isMySejahtera && submit &&
                    <Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '150px' }} onClick={() => {
                      setSubmit(false);
                      localStorage.setItem('s2p-MySejahtera-submit', '');
                    }}>Update Risk Status</Button>}
                </Space>
              </ResponsiveDiv>
            </>
        }
        {addHistoryCode}
        {getQRReader()}
        {!scan && viewHistory
          ? <QRHistory
              key={`view-history`}
              initialHistory={history}
              onUpdate={(updateHistory: QRHistoryItem[]) => {
                setHistory(updateHistory);
                setViewHistory(false);
                if (window.location.href.endsWith('#history')) {
                  window.history.back();
                }
              }}
              onSelect={(selectItem: QRHistoryItem) => {
                const newIsMySejahtera = selectItem.url.startsWith('https://mysejahtera.malaysia.gov.my/qrscan');
                setUrl(selectItem.url);
                setIsMySejahtera(newIsMySejahtera);
                setViewHistory(false);
                if (window.location.href.endsWith('#history')) {
                  window.history.back();
                }
              }}
              onCancel={(updateHistory?: QRHistoryItem[]) => {
                if (updateHistory) {
                  setHistory(updateHistory);
                }
                setViewHistory(false);
                if (window.location.href.endsWith('#history')) {
                  window.history.back();
                }
              }}
            />
         : null}
        {!scan && isMySejahtera && users !== null && selectUsers ? <QRSelectUsers initialUsers={users} initialSelectedUsers={selectedUsers} onUpdate={(updateUsers?: Dictionary<QRTrackUser>, updateSelectedUsers?: string[]) => {
          setSelectUsers(false);
          if (updateSelectedUsers !== undefined) {
            setSelectedUsers(updateSelectedUsers);
            localStorage.setItem('s2p-MySejahtera-selected-users', selectedUsers.join(';'));
          }
          if (updateUsers !== undefined) {
            setUsers(updateUsers);
          }
          if (window.location.href.endsWith('#select-users')) {
            window.history.back();
          }
        }} onCancel={() => {
          setSelectUsers(false);
          if (window.location.href.endsWith('#select-users')) {
            window.history.back();
          }
        }}/>
         : null}
        {!scan && isMySejahtera
          ? <>
            {warningRead ? null : <Alert
              message="Registration made easy: add user details and register all users with one click. All user data is stored on your phone. You can also open a new tab with My Sejahtera form to fill out."
              type="warning"
              closable
              onClose={() => {
                const updateWanrningRead = new Date().toLocaleString();
                localStorage.setItem('s2p-MySejahtera-warning', updateWanrningRead);
                setWarningRead(updateWanrningRead);
              }}
            />}
            <ResponsiveDiv isCentered maxWidth={'800px'} style={{ fontWeight: 'bold', marginTop: '10px' }}>My Sejahtera link found!</ResponsiveDiv>
            <ResponsiveDiv isCentered maxWidth={'800px'} style={{ marginBottom: '15px' }}>You can open a My Sejahtera form to enter your contact details or register automatically with selected users below.</ResponsiveDiv>
          </>
          : null}
        {!scan && isMySejahtera && users !== null
          ? <ResponsiveDiv maxWidth={'800px'} style={{ border: '1px solid', marginBottom: '10px' }}>
              <CenteredDiv>
                <WizardTooltip currentPage={TutorialType.QRTracker} onClose={() => updateWizard(TrackerWizard.AddUserButton, true)} defaultVisible={wizard[TrackerWizard.AddUserButton]} visible={!wizard[TrackerWizard.AddUserButton]} title='invisible' placement="bottom" message='Click here to Add, Edit, or Remove user details as well as Select users to register with My Sejahtera. All user data is stored locally on your device.' >
                  <Link to="qr#select-users" onClick={() => {
                    setSelectUsers(true);
                    updateWizard(TrackerWizard.AddUserButton, true);
                  }}><Button style={{ marginLeft: getWindowDimension().width < 300 ? undefined : '10px', marginTop: '5px', marginBottom: '5px', width: '200px', color: 'blue' }}>Add or Edit Users</Button></Link>
                </WizardTooltip>
              </CenteredDiv>
                {/*selectedUsers.length === 0
                ? <div style={{ marginLeft: '25px', marginRight: '25px' }} ><span style={{ fontWeight: 'bold' }} >No users selected.</span> You can enter in your details directly into the Mysejahtera Form.</div>
                :*/ <>
                <div style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} >
                  <div style={{ fontWeight: 'bold' }} >Users to register: </div>
                  {selectedUsers.length === 0
                    ? 'None'
                    : selectedUsers.map((selectedUser: string) => (
                      <Tag
                        key={`${selectedUser}`}
                        value={{ uuid: selectedUser, name: selectedUser, color: TagColor.Magenta }}
                        icon={<CloseCircleTwoTone style={{ marginLeft: '8px', marginRight: '0px' }} />}
                        onClick={(item: TagItem) => setSelectedUsers(selectedUsers.filter((user: string) => user !== selectedUser))}
                      />))
                  }
                </div>
                <div style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} >
                  <div style={{ fontWeight: 'bold' }} >Users not to register: </div>
                  {selectedUsers.length === Object.keys(users).length
                    ? 'None'
                    : Object.keys(users).filter((user: string) => !selectedUsers.find((selectedUser: string) => selectedUser === user)).map((unselectedUser: string) => (
                      <Tag
                        key={`${unselectedUser}`}
                        style={{ opacity: '30%' }}
                        value= {{ uuid: unselectedUser, name: unselectedUser, color: TagColor.Magenta }}
                        icon={<PlusCircleTwoTone style={{ marginLeft: '8px', marginRight: '0px' }} />}
                        onClick={(item: TagItem) => {
                          setSelectedUsers([unselectedUser, ...selectedUsers]);
                        }}
                      />))
                  }
                </div>
                </>}
            </ResponsiveDiv>
          : null
        }
        {url && url.startsWith('https://mysejahtera.malaysia.gov.my/qrscan') && !submit && users && Object.keys(users).length > 0
          ? (selectedUsers.length > 0
              ? (<>
                <CenteredDiv maxWidth={'800px'}>
                  {isEnglish
                    ? <span style={{ fontWeight: 'bold' }}>English</span>
                    : <span onClick={() => setIsEnglish(true)}>English</span>} | {!isEnglish
                    ? (<span style={{ fontWeight: 'bold' }}>Bahasa</span>)
                    : <span onClick={() => setIsEnglish(false)}>Bahasa</span>}
                </CenteredDiv>
                {isEnglish
                  ? englishConsent
                  : malayConsent
                }
              </>)
              : <ResponsiveDiv breakWord maxWidth='800px' style={{ border: '1px solid', marginBottom: submit ? '10px' : '50px', color: 'red', textAlign: 'center' }}>At least one user needs to be selected to update users Risk Status</ResponsiveDiv>)
          : null}
        {url !== '' && !isMySejahtera
          ? <div style={{ marginTop: '5px', border: '1px solid', overflowWrap: 'break-word' }}>
            <CenteredDiv>Scanned DATA:</CenteredDiv>
            <Divider style={{ margin: 2 }}/>
            {isUrl(url) ? <a href={url} target='_blank' rel='noopener noreferrer'>{url}</a> : url}</div> : ''}
        {url !== '' && !isMySejahtera
          ? <>
              <Button style={{ marginLeft: 'auto', marginRight: 'auto', width: '300px', marginBottom: '2px' }} href={url} target='_blank'>Open link in new tab</Button>
            </>
          : null}
        {url !== '' && isMySejahtera && (selectedUsers.length === 0 || submit)
          ? <>
              {selectedUsers.length === 0 || !lowRisk
                ? <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto', width: '300px', marginBottom: '50px', marginTop: '2px', fontWeight: 'bold', color: 'navy', border: '1px solid', borderColor: 'navy' }}
                    href={url}
                    target='_blank'>{isEnglish ? 'Open empty form in a new tab' : 'Buka borang kosong dalam tab baru'}</Button>
                : <CenteredDiv style={{ marginBottom: '50px' }}>
                    {false && <Link to="qr#submit-full-screen" style={{ width: '300px', marginTop: '2px' }}>
                      <Button
                        style={{ fontWeight: 'bold', color: 'navy', border: '1px solid', borderColor: 'navy' }}
                        onClick={() => {
                          if (embedPage) {
                            setEmbedPage(false);
                            setFullScreen(false);
                          }
                          else {
                            setFullScreen(true);
                          }
                        }}
                        target='_blank'>{embedPage ? 'Close embedded page' : 'Open link embedded in the page'}
                      </Button>
                    </Link>}
                    {users &&
                      <Space direction='vertical' style={{ marginBottom: '50px' }}>
                        {selectedUsers.map((selectedUser: string) => {
                          return <Button
                            style={{ marginLeft: 'auto', marginRight: 'auto', width: '300px', marginBottom: '2px', marginTop: '2px', fontWeight: 'bold', color: 'navy', border: '1px solid', borderColor: 'navy' }}
                            href={`${url}&data=${window.btoa(JSON.stringify(users[selectedUser]))}`}
                            target='_blank'>{users[selectedUser].name}: {isEnglish ? 'Open form in a new tab' : 'Buka borang dalam tab baru'}</Button>;
                        })}
                    </Space>}
                  </CenteredDiv>}
            </>
          : null}
        {url !== '' && (embedPage || fullScreen)
          ? (selectedUsers.length === 0 || !users
              ? <>
                  {fullScreen
                    ? <>
                        <CloseCircleTwoTone style={{ zIndex: 2000, position: 'fixed', top: 12, right: 20, fontSize: '22px' }} onClick={() => {
                          setFullScreen(false);
                          setEmbedPage(false);
                          if (window.location.href.endsWith('#submit-full-screen')) {
                            window.history.back();
                          }
                        }} twoToneColor='red' />
                        <iframe id={'iframe-no-users'} style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, width:'100%', height:'100%' }} title='MySejahtera' height={400} width={getWindowDimension().width > 800 ? '800px' : `${getWindowDimension().width}px`} src={url}></iframe>
                      </>
                    : <>
                        <CenteredDiv maxWidth='800px' style={{ border: '1px solid', width: `${getWindowDimension().width > 800 ? '800px' : `${getWindowDimension().width}px`}` }}><span><FullscreenOutlined onClick={() => {
                          setFullScreen(true);
                          setEmbedPage(false);
                        }}/></span> URL: <a style={{ width: `${getWindowDimension().width > 800 ? '700px' : `${getWindowDimension().width - 100}px`}` }} href={`${url}`}>{url}</a></CenteredDiv>
                        <CenteredDiv maxWidth='800px' ><iframe title='MySejahtera' height={400} width={getWindowDimension().width > 800 ? '800px' : `${getWindowDimension().width}px`} src={url}></iframe></CenteredDiv>
                      </>
                  }
                </>
              : (fullScreen
                  ? <>
                      <CloseCircleTwoTone style={{ zIndex: 2000, position: 'fixed', top: 12, right: 20, fontSize: '22px' }} onClick={() => {
                        setFullScreen(false);
                        setEmbedPage(false);
                        if (window.location.href.endsWith('#submit-full-screen')) {
                          window.history.back();
                        }
                      }} twoToneColor='red' />
                      <Tabs centered style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, width:'100%', height:'100%', zIndex: 1999, background: 'white' }} key={'tabs'} defaultActiveKey="1" onChange={() => {}}>
                      {submit
                        ? selectedUsers.map((user: string, index: number) => (
                        <TabPane tab={user} key={index}>
                          <div style={{ border: '1px solid' }}><span></span> URL: <a target='_blank' rel='noopener noreferrer' style={{ width: `${getWindowDimension().width - 100}px` }} href={`${url}&data=${window.btoa(JSON.stringify(users[user]))}`}>{url}</a></div>
                          <iframe id={`iframe-user-${index}`} height={`${getWindowDimension().height - 80}px`} width={`${getWindowDimension().width}px`} key={`tab-${user}-${index}`} title='MySejahtera' src={`${url}&data=${window.btoa(JSON.stringify(users[user]))}`}></iframe>
                        </TabPane>))
                        : null
                      }
                      </Tabs>
                    </>
                  : <Tabs key={'tabs'} defaultActiveKey="1" onChange={() => {}}>
                  {submit
                    ? <ResponsiveDiv isCentered style={{ marginBottom: '50px' }} maxWidth='800px'>{selectedUsers.map((user: string, index: number) => (
                    <TabPane tab={user} key={index}>
                      <div style={{ border: '1px solid', width: `${getWindowDimension().width > 800 ? '800px' : `${getWindowDimension().width}px`}` }}><span><FullscreenOutlined onClick={() => {
                        setFullScreen(true);
                        setEmbedPage(false);
                        if (window.location.href.endsWith('#submit-full-screen')) {
                          window.history.back();
                        }
                      }}/></span> URL: <a target='_blank' rel='noopener noreferrer' style={{ width: `${getWindowDimension().width > 800 ? '700px' : `${getWindowDimension().width - 100}px`}` }} href={`${url}&data=${window.btoa(JSON.stringify(users[user]))}`}>{url}</a></div>
                      <iframe key={`tab-${user}-${index}`} title='MySejahtera' height={500} width={getWindowDimension().width > 800 ? '800px' : `${getWindowDimension().width}px`} src={`${url}&data=${window.btoa(JSON.stringify(users[user]))}`}></iframe>
                    </TabPane>))}</ResponsiveDiv>
                    : null
                  }
                  </Tabs>)
              )
          : null}
          {!scan && url === '' ? <Button style={{ position: 'fixed', bottom: '1px', left: '1px', width: '1px', height: '2px', color: 'light-gray', backgroundColor: 'light-gray' }} onClick={() => setTestPixel(testPixel - 1)}>{testPixel}</Button> : null}
          <br />
          <br />
          <br />
      </Content>
    }
    />
  );
};

export default QRTracker;
