import { ImportOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import EnclosedIcon, { IconProps } from './EnclosedIcon';

const ImportStyled = styled(ImportOutlined)`
  z-index: 2000;
  font-size: 14px;
  margin-right: 0px;
  ${(props: { color?: string }) => props.color ? `color: ${props.color};` : null}
`;

type ImportIconProps = IconProps & {
};

const ImportIcon: React.FC<ImportIconProps> = (props: ImportIconProps) => {
  const icon = <ImportStyled color={props.color} />;

  return <EnclosedIcon {...props} icon={icon} />;
};

export default ImportIcon;
