import React, { useEffect, useState }  from 'react';
import { usePostPlaidLinkTokenMutation, PlaidLinkToken } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type GetLinkTokenProps = {
  onUpdate?: (data?: PlaidLinkToken, loading?: boolean, error?: any) => any;
};

const GetLinkToken: React.FC<GetLinkTokenProps> = (props: GetLinkTokenProps) => {
  const { onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);

  const [PostLinkTokenMutation, { data: linkToken, loading, error }] = usePostPlaidLinkTokenMutation({
    ...stockClientContext,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!executed) {
      setExecuted(true);
      PostLinkTokenMutation({ variables: { } })
        .then((result: any) => {
          console.log('done plaid post link token');
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error plaid post link token`);
          console.log(err);
        });
    }
    if (onUpdate) {
      onUpdate(linkToken ? linkToken.postPlaidLinkToken : undefined, loading, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [linkToken, loading, error]);

  return null;
};

export default GetLinkToken;
