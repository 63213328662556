import React, { useEffect, useState }  from 'react';
import { PlaidLinkedInstitution, PlaidLinkedInstitutionInput, useUpdatePlaidLinkedInstitutionMutation } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type UpdateLinkedInstitutionsProps = {
  institution: PlaidLinkedInstitutionInput;
  onUpdateLinkedInstitution: (data?: PlaidLinkedInstitution, loading?: boolean, error?: any) => any;
};

const UpdateLinkedInstitutions: React.FC<UpdateLinkedInstitutionsProps> = (props: UpdateLinkedInstitutionsProps) => {
  const { institution, onUpdateLinkedInstitution } = props;
  const [executed, setExecuted] = useState<boolean>(false);

  const [UpdateLinkedPlaidInstitutionMutation, { data: updatedPlaidLinkedInstitution, loading, error }] = useUpdatePlaidLinkedInstitutionMutation({
    variables: { institution },
    ...stockClientContext,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!executed) {
      console.log(`update linked institution '${institution.institutionId ?? institution.institutionName}'`);
      setExecuted(true);
      UpdateLinkedPlaidInstitutionMutation({ variables: { institution } })
        .then((result: any) => {
          console.log(`done updating institution ${institution.institutionId}`);
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error updating institution ${institution.institutionId}`);
          console.log(err);
        });
    }
    onUpdateLinkedInstitution(updatedPlaidLinkedInstitution ? updatedPlaidLinkedInstitution.updatePlaidLinkedInstitution as PlaidLinkedInstitution : undefined, loading, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [updatedPlaidLinkedInstitution, loading, error]);

  return null;
};

export default UpdateLinkedInstitutions;
