import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// amplify
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import Login from '../../components/login/Login';
import { AuthStatus } from '../../components/login/AuthStatusEnum';
import AuthContext, { AuthProvider } from '../../context/AuthContext';
import { User, UserInput } from '../../graphql/generated/graphql';
import { errorNotification, infoNotification } from '../../components/actions/Notification';
import { RegisterUser } from '../api/User/RegisterUser';
import config from '../../Config';

type LoginContainerProps = RouteComponentProps & {
  emailConfirmed?: string;
};

type LoginContainerState = {
  loginStatus: AuthStatus;
  error: string;
  user: any;
  confirmEmail?: string;
  userInput?: UserInput;
};

/*
function LoginServer(props: any) {
  const authContext = React.useContext(AuthContext);
  const user = props.user;
  const { data, error } = useProvisionQuery({
    variables: { id: '2' },
  });
  let s2pUser: User | null =  null;

  if (data && data.getUser) {
    s2pUser = data?.getUser;
    authContext.login(user.signInUserSession.accessToken.jwtToken, s2pUser, '/');
  }
  else {
    if (error) {
      console.error(`Error authenticating: ${error.message}`);
    }
  }
  return s2pUser;
} */

class LoginContainer extends React.Component<LoginContainerProps, LoginContainerState> {
  static contextType = AuthContext;

  constructor(props: LoginContainerProps) {
    super(props);
    this.state = {
      loginStatus: AuthStatus.None,
      error: '',
      user: {},
      confirmEmail: undefined,
    };
  }

  async componentDidUpdate() {
    try {
      if (this.context.authState === AuthStatus.Authenticated) {
        infoNotification('User is already logged in, redirecting.');
        this.props.history.push('/');
        return;
      }
    }
    catch (e) {
    }
  }

  handleCreateUser = (userInput: UserInput) => {
    this.setState({ userInput });
  }

  handleUserCreated = (user?: User, loading?: boolean, error?: any) => {
    if (user) {
      this.context.register(user);
    }
  }

  handleAuth = (email: string, password: string) => {
    if (this.context.authState === AuthStatus.Authenticated) {
      infoNotification('User is already logged in, redirecting.');
      this.props.history.push('/');
      return;
    }

    this.context.setAuthState(AuthStatus.LoggingIn);
    console.log(config);
    if (config.ENV === 'Local' && config.MOCK_AUTH) {
      this.context.login(JSON.stringify({ uuid: config.MOCK_AUTH, email }), AuthProvider.Sandbox, { emailAddress: email }, '/collections')
      return;
    }
    Auth.signIn(email.toLowerCase(), password)
      .then((user: CognitoUser) => {
        const cognitoUserSession: CognitoUserSession | null = user.getSignInUserSession();
        if (!cognitoUserSession) {
          this.setState({ loginStatus: AuthStatus.Fail, error: 'CongitoUser did not return CognitoUserSession' });
          return;
        }
        const idToken = cognitoUserSession.getIdToken();
        const payload = idToken ? idToken.payload : null;
        const userInput: UserInput | null = payload ? { emailAddress: payload.email, firstName: payload["custom:firstName"], lastName: payload["custom:lastName"] } : null;

        this.context.login(user.getSignInUserSession()!.getIdToken(), AuthProvider.Cognito, userInput, '/collections')
          .then((isRegistered: any) => {
            // const s2pUser = LoginServer(user);
            /*
            if (isRegistered) {
              this.context.setAuthState(AuthStatus.Success);
              this.setState({ user, loginStatus: AuthStatus.Success });
            }
            else {
              this.context.setAuthState(AuthStatus.ConfirmRegister);
              this.setState({ user, loginStatus: AuthStatus.ConfirmRegister });
            }*/
          })
          .catch((err: any) => {
            this.setState({ loginStatus: AuthStatus.Fail, error: err.message, user: null });
            errorNotification(err.message);
            /*
            notification.error({
              message: 'Error',
              description: err.message,
              placement: 'topRight',
            });*/
          });
        /*
        const { data, error } = useProvisionQuery({
          variables: { id: '2' },
        });

        if (data && data.getUser) {
          const s2pUser = data?.getUser;
          this.context.login(user.signInUserSession.accessToken.jwtToken, s2pUser, '/');
          this.setState({ loginStatus: AuthStatus.Success, user }); // token: user.signInUserSession.accessToken.jwtToken });
        }
        else {
          if (error) {
            console.error(`Error authenticating: ${error.message}`);
          }
          this.context.login(user.signInUserSession.accessToken.jwtToken, null, '/');
          // this.setState({  isSemiAuthenticated: true, isAuthenticating: false, isAuthenticated: false });
          this.setState({ loginStatus: AuthStatus.Register, user });
        }
        */
        /*
        notification.success({
          message: 'USER OBJ',
          description: JSON.stringify(user),
          placement: 'topRight',
          duration: 1.5,
        });
        authContext.login(user.signInUserSession.idToken, '/');
        localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);

        notification.success({
          message: 'Successfully logged in!',
          description: 'Logged in successfully, Redirecting you in a few!',
          placement: 'topRight',
          duration: 1.5,
        });*/
      })
      .catch((err: any) => {
        if (err.code === 'UserNotConfirmedException') {
          this.setState({ loginStatus: AuthStatus.ConfirmRegister, confirmEmail: email, error: '', user: null });
          this.context.setAuthState(AuthStatus.ConfirmRegister);
        }
        else {
          this.context.setAuthState(AuthStatus.None);
          this.setState({ loginStatus: AuthStatus.None, user: null });
          errorNotification(err.message);
          console.log(err.message);
        }
        /*
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight',
        });*/
      });
  }

  render() {
    const { loginStatus } = this.state;
    // const token = user ? (user.signInUserSession ? user.signInUserSession.accessToken.jwtToken : '') : '';

    return (
      <>
        {this.context.userInput && this.context.authState === AuthStatus.CreateUser ? <RegisterUser key={`create-user-${this.context.userInput.emailAddress}`} userInput={this.context.userInput} onUpdate={this.handleUserCreated}></RegisterUser> : null}
        <Login handleLogin={this.handleAuth} loginStatus={loginStatus} confirmEmail={this.state.confirmEmail} emailConfirmed={this.props.emailConfirmed} handleCreateUser={this.handleCreateUser} />
      </>
    );
  }
}

export default withRouter(LoginContainer);
