import React from 'react';

type KeepMountedProps = {
  isMounted: boolean;
  render: () => {};
};

type KeepMountedState = {
};

class KeepMounted extends React.Component<KeepMountedProps, KeepMountedState> {
  hasBeenMounted = false;
  constructor(props: KeepMountedProps) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { isMounted, render } = this.props;
    if (!this.hasBeenMounted && !isMounted) {
      return null;
    }
    this.hasBeenMounted = this.hasBeenMounted || isMounted;
    return (
      <div style={{ display: isMounted ? undefined : 'none' }}>
        {this.hasBeenMounted ? render() : null}
      </div>
    );
  }
}

export default KeepMounted;
