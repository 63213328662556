import gql from 'graphql-tag';
import DataEntryData from './DataEntryData';

export default gql`
  fragment DataEntryInstance on DataEntryInstance {
    instanceUuid
    objectUuid
    instanceData
    {
      ...DataEntryData
    }
    dateCreated
    dateDue
    dateCompleted
    active
    dateRemoved
  }
  ${DataEntryData}
`;
