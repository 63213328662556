import { CheckCircleTwoTone, CloseSquareTwoTone, DownOutlined } from '@ant-design/icons';
import { Input, Popconfirm, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { errorNotification } from '../actions/Notification';
import Tag, { TagColor, TagItem } from '../atomic/Tag';
import ViewEntry from '../views/Entry';

type QRHistoryTagEditProps = {
  tag: { isNew: boolean; value: TagItem };
  tags: TagItem[]; // used to ensure duplicates are not created
  onCreate: (tag: TagItem) => void
  onUpdate: (tag: TagItem) => void;
  onCancel: () => void;
};

const QRHistoryTagEdit: React.FC<QRHistoryTagEditProps> = (props: QRHistoryTagEditProps) => {
  const { tag, tags, onCreate, onUpdate, onCancel } = props;
  const [editTag, setEditTag] = useState<{ isNew: boolean; value: TagItem } | null>(tag);

  useEffect(() => {
    if (tag && !editTag) {
      setEditTag(editTag);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const onDone = () => {
    if (!tags) {
      errorNotification('Failed to initialize tags.');
      return;
    }
    if (!editTag) {
      errorNotification('group detail missing.');
      return;
    }
    if (!editTag.value || editTag.value.name.length === 0) {
      errorNotification('Group name is required.');
      return;
    }
    if (tags.find((tag: TagItem) => tag.name === editTag.value.name && tag.uuid !== editTag.value.uuid)) {
      errorNotification('Tag with same name already exists. Tag name must be unique.');
      return;
    }
    if (editTag.isNew) {
      onCreate(editTag.value);
    }
    else {
      onUpdate(editTag.value);
    }
    onUpdate(editTag.value);
    setEditTag(null);
  };

  return (
    editTag
      ? <ViewEntry
          editPadding='30px'
          editButtons={
            <>
              <CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor='#52c41a' onClick={onDone} />
              <Popconfirm
                  title="Discard item changes."
                  onConfirm={() => {
                    setEditTag(null);
                    onCancel();
                  }}
                  onCancel={onCancel}
                  okText="Discard"
                  cancelText="Cancel"
                >
                <CloseSquareTwoTone style={{ marginLeft: '5px', fontSize: '22px' }} twoToneColor='red' />
              </Popconfirm>
            </>
          }
          leftWidth='85px'
          leftElement={
            <>
            <div style={{ fontWeight: 'bold' }} >Color:</div>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>{Object.keys(TagColor).map((c: string, index: number) => {
                  return (
                    <Menu.Item key={`tag-dropdown-color-${c}-${index}`} onClick={(e) => {
                      const updateTag = { ...editTag };
                      updateTag.value.color = TagColor[c];
                      setEditTag(updateTag);
                    }} >
                      <Tag value={{ uuid: c, color: TagColor[c], name: TagColor[c] }} />
                  </Menu.Item>);
                })}
                </Menu>
              }>
                <Tag
                  style={{ width: '80px', textAlign: 'center', padding: '3px 5px' }}
                  value={{ uuid: 'new-tag-color', name: editTag.value.color ?? '', color: editTag.value.color }}
                  icon={<DownOutlined style={{ float: 'right', width: '10px', marginTop: '5px' }}/>}
                />
              </Dropdown>
            </>
          }
          header={
            <>
              <div >Tag name:</div>
              <Input placeholder='Enter Tag name' key={`input-history-tag`} style={{ borderColor: 'blue' }} defaultValue={editTag.value.name} onChange={(e: any) => {
                const updateTag = { ...editTag };
                updateTag.value.name = e.target.value;
                setEditTag(updateTag);
              }}></Input>
            </>
          }
        />
      : null
  );
};

export default QRHistoryTagEdit;
