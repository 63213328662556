import React from 'react';
import { ApolloError } from '@apollo/client';

import './App.css';
import LanguageProvider from './containers/LanguageProvider';
import { translationMessages } from './i18n/i18n';
import { RouteComponentProps } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getClient } from './graphql/Apollo';
import Amplify, { Auth } from 'aws-amplify';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import uuid from 'uuid/v4';

import config from './Config';
// import { ApolloProvider } from 'react-apollo';
import { ApolloProvider } from '@apollo/client';
import { message } from 'antd';
import Settings from './pages/Settings';
import Login from './pages/Login';
import AuthContext, { AuthProvider } from './context/AuthContext';
import ThemeContext, { DisplaySettings, HeaderSettings } from './context/ThemeContext';
import Fuel from './pages/Fuel';
import SignUpContainer from './containers/auth/SignUpContainer';
import { User, GetUserDocument, UserInput, Features } from './graphql/generated/graphql';
import Log from './Log';
import { getDeviceTypeInfo, DeviceTypeInfo } from './components/styled/ScreenSize';
import ManageDocument from './pages/ManageDocument';
import Collection from './pages/Collection';
import ApiBrowser from './pages/ApiBrowser';
import Page from './pages/Page';
import Pages from './pages/Pages';
import Storage from './pages/Storage';
import Account from './pages/Account';
// import Years from './pages/Years';
import OAuth from './pages/OAuth';
import Home from './pages/Home';
import Overview from './pages/Overview';
import Collections from './pages/Collections';
import ManagePage from './pages/ManagePage';
import Entries from './pages/Entries';
import QRTracker from './pages/QRTracker';
import SamplePage from './pages/SamplePage';
import Status from './pages/Status';
import PrettyJson from './pages/PrettyJson';
import * as LocalStorage from './data/LocalStorage';
import { AuthStatus } from './components/login/AuthStatusEnum';
import ResetContainer from './containers/auth/ResetContainer';
import StocksSamplePage from './pages/StocksSamplePage';
import AdHoc from './pages/AdHoc';

message.config({
  top: 11,
  duration: 1,
  maxCount: 2,
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <AuthContext.Consumer>
  {(context: any) =>
    <Route {...rest} render={props => (
      context.authState === AuthStatus.Unknown
      ? <Component {...props} />
      : (context.authState === AuthStatus.Authenticated
        ? (props.location.pathname === '/login'
          ? <Redirect to='/' />
          : <Component {...props} />)
        : (context.authState === AuthStatus.Register
          ? <Redirect to='/register' />
          : (props.location.pathname === '/login' || props.location.pathname === '/auth/google')
            ? <Component {...props} />
            : <Redirect to='/login' />))
    )} />
  }
  </AuthContext.Consumer>
);

const SemiPrivateRoute = ({ component: Component, ...rest }: any) => (
  <AuthContext.Consumer>
  {(context: any) =>
    <Route {...rest} render={props => (
      context.authStatus === AuthStatus.Authenticated && props.location.pathname === '/register'
        ? <Redirect to='/home' />
        : context.isAuthenticated === true || context.isSemiAuthenticated === true || props.location.pathname === '/register'
          ? <Component {...props} />
          : <Redirect to='/login' />
    )} />
  }
  </AuthContext.Consumer>
);

const LoginRoute = ({ component: Component, ...rest }: any) => (
  <AuthContext.Consumer>
  {(context: any) =>
    <Route {...rest} render={props => (
      context.authState === AuthStatus.Authenticated
        ? <Redirect to='/home' />
        : <Component {...props} />
    )} />
  }
  </AuthContext.Consumer>
);

type AppProps = RouteComponentProps & {
  history: any;
};

type AppState = {
  // Auth Context
  isAuthenticating: boolean;
  isSemiAuthenticated: boolean;
  isAuthenticated: boolean;
  provider: AuthProvider;
  authState: AuthStatus;
  userInput: UserInput | undefined;

  // token: any;
  user: User | null;

  getToken: () => Promise<{ token: string; provider: AuthProvider, userInput?: UserInput } | null>;
  isFeatureEnabled: (feature: Features) => boolean,
  setAuthState: (authState: AuthStatus) => {};
  register: (user: any) => {};
  login: (token: CognitoIdToken | string | null, provider: AuthProvider, user: UserInput| null, redirectPath: string | null) => {};
  logout: () => {};

  // Theme Context
  header: HeaderSettings;
  showFooter: boolean;
  showThemeSetting: boolean;
  componentProps: { [name: string] : any};

  deviceTypeInfo: () => DeviceTypeInfo;
  setComponentProps: (props: { [name: string] : any}) => {};
  setDisplaySettings: (settings: DisplaySettings) => {};

  // App State, currently lives in Theme Context
  lastUpdated: string;

  setLastUpdated: (lastUpdated: string) => {};
};

class App extends React.Component<AppProps, AppState> {
  apollo: any;

  constructor(props: AppProps) {
    super(props);

    const authProvider = LocalStorage.getAuthProvider();
    // const lowerGoogle = AuthProvider['google'];
    // const upperGoogle = AuthProvider['Google'];
    this.state = {
      // AuthContext
      isAuthenticating: false,
      isSemiAuthenticated: false,
      // let onMount to determine state of the app
      isAuthenticated: false,
      provider: authProvider,
      authState: AuthStatus.Unknown,
      userInput: undefined as UserInput | undefined,

      // token: null,
      user: null,

      getToken: async (): Promise<{ token: string; provider: AuthProvider, userInput?: UserInput } | null> => {
        // const isSandbox = config.ENV === 'Local' && config.MOCK_AUTH !== undefined;
        let sessionToken: string | null =  LocalStorage.getGoogleAuth();
        const authProvider = LocalStorage.getAuthProvider();
        if (config.ENV === 'Local' && config.MOCK_AUTH) {
          if (authProvider === AuthProvider.Sandbox && sessionToken) {
            return { token: sessionToken, provider: authProvider };
          }
          return null;
        }
        if (sessionToken && authProvider === AuthProvider.Google) {
          return { token: sessionToken, provider: authProvider };
        }
        try {
          const session = await Auth.currentSession();
          sessionToken  = session.getIdToken().getJwtToken();
          const idToken = session.getIdToken();
          const payload = idToken ? idToken.payload : null;
          const userInput: UserInput | undefined = payload ? { emailAddress: payload.email, firstName: payload['custom:firstName'] ?? '', lastName: payload['custom:lastName'] ?? '' } : undefined;
          return { token: sessionToken, provider: AuthProvider.Cognito, userInput };
        }
        catch (e) {
          console.log(e);
        }
        return null;
      },
      setAuthState: (authState: AuthStatus) => {
        this.setState({ authState });
        return authState;
      },
      isFeatureEnabled: (feature: Features): boolean => {
        // while not authenticated, return true;
        const isEnabled: boolean = this.state.user === null ? true : this.state.user.accountDetails !== undefined && this.state.user.accountDetails !== null && this.state.user.accountDetails.features && this.state.user.accountDetails.features.includes(feature);
        return isEnabled;
      },
      register: async (user: User) => {
        this.setState({ user, isSemiAuthenticated: false, isAuthenticated: true, authState: AuthStatus.Authenticated });
        this.props.history.push('/');
      },

      /**
       * If @token and @provider are provided, save new credentials. Otherwise retrieve existent stored credentials.
       * If token is not confirmed, trigger confirmation code flow.
       * If Swing2Places user doesn't exist, trigger createUser flow.
       *
       * @param token token to authenticate the user
       * @param provider AuthProvider used for authentication
       * @param user user logging in
       * @param redirectPath redirect path if login is successful
       */
      login: async (token: CognitoIdToken | string | null, provider: AuthProvider, userInput: UserInput | null, redirectPath: string | null) => {
        let useToken: CognitoIdToken | string | null = null;
        let useProvider = provider;
        let useUserInput = userInput ?? undefined;

        const storedToken = await this.state.getToken();
        if (storedToken) {
          /*
          if (storedToken.provider === AuthProvider.Sandbox) {
            Log.info('login', 'User already logged in to Sandbox, must log out before logging in again.');
            if (redirectPath) {
              this.props.history.push(redirectPath);
            }
            else if (props.location.pathname === '/login') {
              this.props.history.push('/Account');
            }
            return;
          }
          */
          if (token && (token as any).payload && (token as any).payload.email !== storedToken.userInput?.emailAddress) {
            Log.info('login', 'User already logged in, must log out before logging in again.');
            if (redirectPath) {
              this.props.history.push(redirectPath);
            }
            else if (props.location.pathname === '/login') {
              this.props.history.push('/Account');
            }
            return;
          }

          useToken = storedToken.token;
          useProvider = storedToken.provider;
          useUserInput = useUserInput ? useUserInput : storedToken.userInput;
        }
        else {
          useToken = token;
          useProvider = provider;
          if (useProvider === AuthProvider.Google || useProvider === AuthProvider.Sandbox) {
            LocalStorage.setGoogleAuth(token as string);
          }
        }
        /*
        if (!token && true) {
          Log.info('login', 'not authenticated');
          return false;
        } */
        if (!useToken) {
          Log.info('login', 'not authenticated');
          if (this.state.authState !== AuthStatus.None) {
            this.setState({ isAuthenticating: false, isSemiAuthenticated: false, isAuthenticated: false, authState: AuthStatus.None, userInput: undefined, user: null });
          }
          return;
        }

        LocalStorage.setAuthProvider(useProvider);
        Log.info('login', 'authenticated');
        // if (useProvider === AuthProvider.Google) {
        //   LocalStorage.setGoogleAuth(token as string);
          /* this.setState({ user: token, isSemiAuthenticated: false, isAuthenticating: false, isAuthenticated: true, provider });
          if (redirectPath) {
            this.props.history.push(redirectPath);
          }
          */
        //   Log.info('login', 'successfully logged in to s2p-google.');
        // }
        /*
        if (provider) {
          LocalStorage.setAuthProvider(provider);
        }
        */
        // const authorization = await this.state.getToken();
        // const session = provider === AuthProvider.Cognito ? await Auth.currentSession() : token;
        // const authorization = provider === AuthProvider.Cognito ? session.getIdToken().getJwtToken() : `Bearer ${token}`;
        try {
          const s2pUser = await this.apollo.query(
            { query: GetUserDocument,
              headers: { authotization: useToken } });

          if (s2pUser.data && s2pUser.data.getUser) {
            this.setState({ user: s2pUser.data.getUser, isSemiAuthenticated: false, isAuthenticating: false, isAuthenticated: true, provider: useProvider, authState: AuthStatus.Authenticated });
            LocalStorage.setAuthProvider(useProvider);
            if (redirectPath) {
              this.props.history.push(redirectPath);
            }
            Log.info('login', 'successfully logged in to s2p.');
          }
          else {
            this.setState({ isAuthenticating: false, isSemiAuthenticated: true, isAuthenticated: false, provider: AuthProvider.Cognito, authState: AuthStatus.CreateUser, userInput: useUserInput });
            this.props.history.push('/register');
            Log.info('login', 'user not found in s2p, redirecting to finish registeration.');
          }
        }
        catch (e) {
          const err = e as ApolloError;
          if (err && (err.message === 'GraphQL error: User does not exist' || (err.networkError && err.networkError.message === 'User does not exist'))) {
            this.setState({ isAuthenticating: false, isSemiAuthenticated: true, isAuthenticated: false, provider: AuthProvider.None, authState: AuthStatus.CreateUser, userInput: useUserInput });
          }
          else {
            if (useProvider === AuthProvider.Sandbox) {
              this.setState({ isAuthenticating: false, isSemiAuthenticated: false, isAuthenticated: false, provider: AuthProvider.None, authState: AuthStatus.None, userInput: useUserInput });
            }
            console.log(`login error: ${err}`);
          }
        }
      },

      logout: async () => {
        Log.info('Logging out', App.name);
        if (localStorage.getItem('s2p-google')) {
          localStorage.removeItem('s2p-google');
          Log.info('google auth removed', App.name);
        }
        let session;
        try {
          session = await Auth.currentSession();
        }
        catch (e) {}
        if (session) {
          Auth.configure({});
          await Auth.signOut();
          Log.info('auth removed', App.name);
        }
        this.apollo.resetStore();
        LocalStorage.setAuthProvider(undefined);
        Log.info('Logged out', App.name);

        this.setState({  isAuthenticated: false, userInput: undefined });

        if (session && !session.getIdToken().payload.identities) {
          // If this was not a federated identity (OAuth) signin, Auth.signout() won't redirect to the IdP, so we need to reset our own state
          this.setState({ isSemiAuthenticated: false, isAuthenticated: false, /*token: null, */user: null });
        }
        this.props.history.push('/login');
        return false;
      },

      // ThemeContext
      header: { show: true, top: 15, right: 15 } as HeaderSettings,
      showFooter: false,
      showThemeSetting: false,
      deviceTypeInfo: getDeviceTypeInfo,
      componentProps: {},

      setComponentProps: (props: { [name: string] : any}) => {
        this.setState({ componentProps: props });
        return props;
      },

      setDisplaySettings: (settings: DisplaySettings) => {
        const newSetting = {} as any;
        if (settings.header !== undefined) {
          newSetting['header'] = settings.header;
        }
        if (settings.showFooter !== undefined) {
          newSetting['showFooter'] = settings.showFooter;
          // this.setState({ showFooter: settings.showFooter });
        }
        if (settings.showThemeSetting !== undefined) {
          newSetting['showThemeSetting'] = settings.showThemeSetting;
          // this.setState({ showThemeSetting: settings.showThemeSetting });
        }
        if (settings.deviceTypeInfo) {
          newSetting['deviceTypeInfo'] = settings.deviceTypeInfo;
          // this.setState({ deviceTypeInfo: settings.deviceTypeInfo });
        }
        if (Object.keys(newSetting).length > 0) {
          this.setState(newSetting);
        }
        return settings;
      },

      // App State (inside ThemeContext)
      lastUpdated: '',
      setLastUpdated: (lastUpdated: string) => {
        this.setState({ lastUpdated });
        return lastUpdated;
      },
    };

    Log.info('init', 'Initialize apollo client');
    if (!this.apollo) {
      let clientId = LocalStorage.getSessionStorage(LocalStorage.SessionStorageKeys.ClientId);
      if (!clientId) {
        clientId = uuid();
        LocalStorage.setSessionStorage(LocalStorage.SessionStorageKeys.ClientId, clientId!);
      }
      this.apollo = getClient(clientId!, async () => {
        const token = await this.state.getToken();
        // const session = await Auth.currentSession();
        return token ? token.token : ''; // ? session.getIdToken().getJwtToken() : '';
      });
  
    }
  }
  async componentDidMount() {
    try {
      // Automatically refreshes accessToken/idToken if they're expired and we have a valid refreshToken
      /*
      const session = await this.state.getToken(); // await Auth.currentSession();
      const authProvider = AuthProvider[localStorage.getItem('auth-provider') ?? 'None'];
      Log.info('User session found, logged in', 'App');
      Log.info(session, 'App');

      this.state.login(session / * .getIdToken() * /, authProvider, null, null);
      */
      this.state.login(null, AuthProvider.None, null, null);
    }
    catch (e) {
      Log.info('No user session found, user must log in', 'App');
      this.setState({ isAuthenticating: false, authState: AuthStatus.None });
    }
  }

  render() {
    if (this.state.isAuthenticating) {
      Log.info('Rendering null, still determining logged in state', 'App');
      return null;
    }

    Log.info('Rendering App, logged in state determined', 'App');
    const currentUrl = localStorage.getItem('s2p-last-url');

    return (!this.apollo ? <div>Something went wrong... please refresh.</div> : 
    <AuthContext.Provider value={this.state}>
      <ThemeContext.Provider value={this.state}>
          <ApolloProvider client={this.apollo}>
            <LanguageProvider messages={translationMessages}>
              <Switch>
                <PrivateRoute path='/adhoc' component={AdHoc} />
                <PrivateRoute path='/account' component={Account} />
                <PrivateRoute path='/settings' component={Settings} />
                <PrivateRoute path='/overview' component={Overview} />
                <PrivateRoute path='/collection/:collectionUuid' component={Collection} />
                <PrivateRoute path='/pages/:pageUuid' component={Page} />
                <PrivateRoute path='/pages/' component={Pages} />
                <PrivateRoute path='/documents' component={ManageDocument} />
                <PrivateRoute path='/manage' component={ManagePage} />
                <PrivateRoute path='/collections' component={Collections} />
                <PrivateRoute path='/storage' component={Storage} />
                <PrivateRoute path='/entries/:collectionUuid' component={Entries} />
                <PrivateRoute path='/entries' component={Entries} />
                <PrivateRoute path='/api-browser' component={ApiBrowser} />
                <PrivateRoute path='/stocksample' component={StocksSamplePage} />
                <SemiPrivateRoute path='/register' component={SignUpContainer} />
                <LoginRoute path='/login' component={Login} />
                <LoginRoute path='/oauth' component={OAuth} />
                <LoginRoute path='/reset' component={ResetContainer} />
                <Route path='/home' component={Home} />
                <Route path='/qr' component={QRTracker} />
                <Route path='/fuel' component={Fuel} />
                <Route path='/sample' component={SamplePage} />
                <Route path='/status' component={Status} />
                <Route path='/prettyjson' component={PrettyJson} />
                <Redirect to={currentUrl ? currentUrl : (this.state.provider !== AuthProvider.None ? '/home' : '/qr')} />
              </Switch>
            </LanguageProvider>
          </ApolloProvider>
        </ThemeContext.Provider>
      </AuthContext.Provider>
    );
  }
}

export default App;
