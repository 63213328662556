import React, { useContext, useState, useEffect } from 'react';
import { Layout } from 'antd';
import "antd/dist/antd.css";
import Header from '../navigation/Header';
import styled from 'styled-components';
import { getDeviceTypeInfo, DeviceType } from '../styled/ScreenSize';
import ThemeContext from '../../context/ThemeContext';
import ThemeSettings from '../styled/ThemeSettings';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const { Footer } = Layout;

const PageLayout = styled(Layout)`
  #background-color: #eeeeee;
  height: 100%;
  width: 100%;
  position: absolute;
`;

type FooterStyledProps = {
  backgroundColor?: string;
  padding?: number;
  height: number;
};

const FooterStyled = styled(Footer)`
  position: fixed;
  background-color: #00008b;
  padding: ${(props: FooterStyledProps) => props.padding ? props.padding : 15}px;
  height: ${(props: FooterStyledProps) => props.height}px;
`;

const FooterDiv = styled.div`
  text-align: center;
  color: white;
`;

type PageContentDivProps = {
  header: { show: boolean, right: number, top: number };
  footer: { show: boolean, bottom: number };
};

type ContentDivProps = {
  isMobile: boolean;
};

// background-color: grey;
const PageContentDiv = styled.div`
  position: fixed;
  height: calc(100vh - ${(props: PageContentDivProps) => `${(props.header.show ? 60 : 0) + (props.footer.show ? props.footer.bottom : 0)}px`});
  top: ${(props: PageContentDivProps) => props.header.show ? '60px' : '0px'};
  overflow: scroll;
`;

const ContentDiv = styled.div`
  position: relative;
  width: calc(100vw - ${(props: ContentDivProps) => `${(props.isMobile ? 20 : 60)}px`});
  margin-left: ${(props: ContentDivProps) => `${(props.isMobile ? 10 : 30)}px`};
  margin-right: ${(props: ContentDivProps) => `${(props.isMobile ? 10 : 30)}px`};
`;

// Hook
export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

type PageProps = RouteComponentProps & {
  content: any;
  headerTitle?: string;
};

const Page: React.FC<PageProps> = (props: PageProps) => {
  const themeContext = useContext(ThemeContext);
  const deviceTypeInfo = getDeviceTypeInfo();

  useEffect(() => {
    const currentUrl = localStorage.getItem('s2p-last-url');
    if (props.location && currentUrl !== props.location.pathname) {
      localStorage.setItem('s2p-last-url', props.location.pathname);
    }
  },        [props.location]);

  if (themeContext.showFooter !==  (deviceTypeInfo.deviceType !== DeviceType.Mobile)) {
    themeContext.setDisplaySettings({ showFooter: deviceTypeInfo.deviceType !== DeviceType.Mobile });
  }

  return (
    <PageLayout key='page-layout'>
      <Header headerTitle={props.headerTitle} />
      <PageContentDiv id='pageContentDiv' header={themeContext.header} footer={{ show: themeContext.showFooter, bottom: 50 }}>
        <ContentDiv isMobile={deviceTypeInfo.deviceType === DeviceType.Mobile}>
          {props.content}
        </ContentDiv>
      </PageContentDiv>
      {themeContext.showFooter && !themeContext.showThemeSetting
        ? <FooterStyled height={50}><FooterDiv>Swing 2 Places © 2021</FooterDiv></FooterStyled>
        : null
      }
      <ThemeSettings />
    </PageLayout>
  );
};

export default withRouter(Page);
