import React, { useEffect, useState }  from 'react';
import RecordModal, { ModalFields, RecordModalAction } from '../../../components/modal/RecordModal';
import { RecordType } from '../../../components/table/DataTypes';
import DeleteCollection from '../../../containers/api/Collection/DeleteCollection';
import UpdateCollection from '../../../containers/api/Collection/UpdateCollection';
import { DataCollectionInput, DeleteDataCollectionMutation, UpdateDataCollectionMutation } from '../../../graphql/generated/graphql';
import { errorMessage } from '../../actions/ErrorMessage';

type EditCollectionProps = {
  action: RecordModalAction;
  initialData: any;
  mapping: any;
  autoExecute?: boolean;
  onSuccess?: (data?: any, loading?: boolean, error?: string) => any;
  onCancel?: () => any;
  onError?: (error: string) => any;
};

const EditCollection: React.FC<EditCollectionProps> = (props: EditCollectionProps) => {
  const { action, initialData, autoExecute, /* mapping, */ onSuccess, onCancel, onError } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [modalAction, setModalAction] = useState<RecordModalAction>(action);
  const [modalData /*, setModalData */] = useState<any>(initialData);
  const [executeUpdate, setExecuteUpdate] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (action !== modalAction) {
      setModalAction(action);
    }
    if (action === RecordModalAction.Delete && props.autoExecute) {
      onDeleteCollection();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  // TODO: use mapping
  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          Name: { placeholder: 'Enter name', type: RecordType.Input, value: modalData && modalData.name ? modalData.name : '' },
          Description: { placeholder: 'Enter description', type: RecordType.Input, value: modalData && modalData.description ? modalData.description : '' },
        },
      },
    ],
  };

  const onUpdateCollection = async (record: any) => {
    if (!record.pages[0].entries.Name.value) {
      errorMessage('Please enter data entry Name');
      return;
    }
    const dataCollection: DataCollectionInput = { uuid: modalData.uuid , name: record.pages[0].entries.Name.value , description: record.pages[0].entries.Description.value, ordinal: initialData.ordinal };
    const updateQuery = <UpdateCollection collectionInput={dataCollection} onUpdate={(data?: UpdateDataCollectionMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        if (autoExecute && onError) {
          onError(error);
        }
        return;
      }

      if (data) {
        setError(null);
        setIsLoading(false);
        setModalAction(RecordModalAction.None);
        if (onSuccess) {
          onSuccess();
        }
      }
    }} />;
    setExecuteUpdate(updateQuery);
  };

  const onDeleteCollection = async (record?: any) => {
    if (!initialData.uuid) {
      console.error('failed to delete collection. Uuid not provided');
      return;
    }
    const deleteQuery = <DeleteCollection uuid={initialData.uuid} onUpdate={(data?: DeleteDataCollectionMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        if (autoExecute && onError) {
          onError(error);
        }
        return;
      }

      if (data) {
        setError(null);
        setIsLoading(false);
        setModalAction(RecordModalAction.None);
        if (onSuccess) {
          onSuccess();
        }
      }
    }} />;
    setExecuteUpdate(deleteQuery);
  };

  return <>
    {executeUpdate}
    {modalAction !== RecordModalAction.None && !autoExecute
      ? <RecordModal
          key={'update-collection-modal'}
          data={recordDataValues}
          action={action}
          title={'collection'}
          onCancel={() => {
            setError(null);
            setModalAction(RecordModalAction.None);
            if (onCancel) {
              onCancel();
            }
          }}
          onSubmit={action === RecordModalAction.Delete ? onDeleteCollection : (action === RecordModalAction.Update ? onUpdateCollection : (data: any) => {}) }
          isLoading={isLoading}
          error={error ? error.message : undefined}
        />
        : null}
  </>;
};

export default EditCollection;
