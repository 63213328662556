import React, { useContext, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import uuid from 'uuid/v4';
import { Dictionary } from 'lodash';
import { errorNotification, infoNotification } from '../actions/Notification';
import Tag, { TagColor, TagItem } from '../atomic/Tag';
import { CenteredDiv } from '../styled/CenteredDiv';
import ViewDraggable from '../views/ViewDraggable';
import { MoreOutlined } from '@ant-design/icons';
import Popconfirm from '../atomic/Popconfirm';
import { getBrokerStockDataFlattened, LastPrice, Stock, StockHistory, StockHolding, SymbolStockData } from './StockTypes';
import DivAlign, { DivPosition } from '../atomic/DivAlign';
import Button from '../atomic/Button';
import StockListFilter, { StockListSettings, StockListVisible } from './StockListFilter';
import CryptoIcon from '../icons/CryptoIcon';
import ThemeContext from '../../context/ThemeContext';

export enum DefaultStockList {
  'All' = 'All',
  'Starred' = 'Starred',
  'Crypto' = 'Crypto',
  'FixError' = 'Fix Error',
  'Recent' = 'Recent',
  'Track' = 'Track',
  'Orders' = 'Orders',
  'Current' = 'Current',
  'Owned' = 'Owned',
  'LookedUp' = 'Looked Up',
}

type StockListProps = {
  stocks: Dictionary<{ details?: Stock, lastPrice?: LastPrice, lastDay?: StockHistory }>;
  lists: Dictionary<string[]>;
  holdings: SymbolStockData<StockHolding>;
  isLoading: boolean;
  selectedTicker?: string;
  onSelectTicker: (ticker: string) => void;
  onUpdateList: (update: Dictionary<string[]>) => void;
};

const StockList: React.FC<StockListProps> = (props: StockListProps) => {
  const { stocks, lists, selectedTicker, holdings, isLoading, onSelectTicker, onUpdateList } = props;
  const themeContext = useContext(ThemeContext);
  const [addList, setAddList] = useState<string>(uuid());
  const [viewList, setViewList] = useState<string>(DefaultStockList.All);
  const [sort, setSort] = useState<'alphaAsc'|'alphaDesc'|'added'|'changeAsc'|'changeDesc'>('alphaAsc');
  const [stockListSettings, setStockListSettings] = useState<StockListSettings>({ view: [StockListVisible.HoldingUnits] });
  const addListRef = useRef<string>('');
  addListRef.current = addList;

  const allHoldings: Dictionary<StockHolding[]> = Object.assign({}, ...Object.keys(holdings).map((symbol: string) => ({ [symbol]: getBrokerStockDataFlattened(holdings[symbol]) })));

  const stockViewMapping = {
    itemId: (data: any) => {
      return data.title;
    },
    isSelected: (data: any) => {
      return data.data === selectedTicker;
    },
    hasError: (data: any) => {
      return stocks[data.data] && stocks[data.data].details && stocks[data.data].details!.isUnknown;
    },
    header: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        const stock = stocks[data.data];
        const stockPrice = stock ? stock.lastPrice : null;
        const change = stockPrice ? Number.parseFloat(stockPrice.price.last) - stockPrice.price.prevClose : 0;
        const lastPrice = stockPrice ? Number.parseFloat(stockPrice.price.last) : 0;
        let units = 0;
        let costBasis = 0;
        const showHoldingUnits = stockListSettings.view.includes(StockListVisible.HoldingUnits);
        const showHoldingAmount = stockListSettings.view.includes(StockListVisible.HoldingAmount);
        const showHoldingChange = stockListSettings.view.includes(StockListVisible.HoldingChange);
        if (stockListSettings.view.length > 0) {
          if (allHoldings[data.data]) {
            allHoldings[data.data].forEach((holding: StockHolding) => {
              if (holding.quantity) {
                units = units + holding.quantity;
              }
              if (holding.costBasis) {
                costBasis = costBasis + holding.costBasis;
              }
            });
          }
        }
        const holdingChange = (((units * lastPrice) - costBasis) / costBasis * 100).toFixed(2);
        // return Object.keys(mapping).map((m: any) => {
        const isCrypto = stocks[data.data] && stocks[data.data].details && stocks[data.data].details!.isCrypto;
        return !stockPrice
          ? <div>
            <div onClick={() => onSelectTicker(data.data)}>[{isCrypto ? <CryptoIcon style={{ paddingRight: '10px' }} color='blue'/> : ''}{data.data}]</div>
            {units === 0 ? <div style={{ fontSize: '10px' }}>0</div> : <div style={{ fontSize: '10px' }}>{showHoldingUnits ? `${units} ` : ''}{showHoldingAmount ? `[${(units * lastPrice).toFixed(2)}] ` : ''}{showHoldingChange ? <span style={{ color: costBasis > units * lastPrice ? 'red' : 'green' }}>({holdingChange}%)</span> : ''}</div>}
          </div>
          : <div style={{ display: 'flex' }}>
              <div onClick={() => onSelectTicker(data.data)}>
                <div>{isCrypto ? <CryptoIcon style={{ paddingRight: '10px' }} color='blue'/> : ''}{data.data}</div>
                {units === 0 ? <div style={{ fontSize: '10px' }}>0</div> : <div style={{ fontSize: '10px' }}>{showHoldingUnits ? `${units} ` : ''}{showHoldingAmount ? `[${(units * lastPrice).toFixed(2)}] ` : ''}{showHoldingChange ? <span style={{ color: costBasis > units * lastPrice ? 'red' : 'green' }}>({holdingChange}%)</span> : ''}</div>}
              </div>
              <div>
                <div style={{ position: 'absolute', right: '5px', color: change < 0 ? 'red' : 'green' }}>{lastPrice < .01 ? lastPrice.toFixed(4) : lastPrice.toFixed(2)} <MoreOutlined style={{ }} /></div>
                <div style={{ position: 'absolute', right: '5px', top: '28px', fontSize: '10px', color: change < 0 ? 'red' : 'green' }}>({change.toFixed(2)}, {((change / stockPrice.price.prevClose) * 100).toFixed(2)}%)</div>
              </div>
            </div>;
      },
      mapping: {
        stocks: {
        },
      },
    },
    content: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        const stock = stocks[data.data];
        const stockPrice = stock ? stock.lastPrice : null;
        if (!stockPrice) {
          return null;
        }
        // return Object.keys(mapping).map((m: any) => {
        return !stockPrice
          ? null
          : <Row style={{ fontSize: '10px', width: '100%' }}>
              <Col span={7} style={{ borderRadius: '5px', border: '1px solid lightgray', textAlign: 'center', margin: '2px' }}>prev close: {stockPrice.price.prevClose.toFixed(2)}</Col>
              <Col span={5} style={{ borderRadius: '5px', border: '1px solid lightgray', textAlign: 'center', margin: '2px' }}>open: {stockPrice.price.open.toFixed(2)}</Col>
              <Col span={5} style={{ borderRadius: '5px', border: '1px solid lightgray', textAlign: 'center', margin: '2px' }}>high: {stockPrice.price.high.toFixed(2)}</Col>
              <Col span={5} style={{ borderRadius: '5px', border: '1px solid lightgray', textAlign: 'center', margin: '2px' }}>low: {stockPrice.price.low.toFixed(2)}</Col>
            </Row>;
      },
      mapping: {
        stocks: {
        },
      },
    },
  };

  const displayStocks = (
    DefaultStockList[viewList] === DefaultStockList.All ? Object.keys(stocks).map((stock: string) => ({ title: stock, data: stock })) /*? [...Object.keys(stocks), ...(lists[DefaultStockList.FixError] ? lists[DefaultStockList.FixError] : [])].map((stock: string) => ({ title: stock, data: stock })) */
    : (DefaultStockList[viewList] === DefaultStockList.Recent ? Object.keys(stocks).filter((stock: string) => props.lists[DefaultStockList.Recent].find((recent: string) => recent === stock)).map((stock: string) => ({ title: stock, data: stock }))
      : (DefaultStockList[viewList] === DefaultStockList.Crypto ? Object.keys(stocks).filter((stock: string) => stocks[stock].details && stocks[stock].details!.isCrypto).map((stock: string) => ({ title: stock, data: stock }))
        : (DefaultStockList[viewList] === DefaultStockList.FixError ? (lists[DefaultStockList.FixError] ? lists[DefaultStockList.FixError].map((stock: string) => ({ title: stock, data: stock })) : [])
            : (Object.keys(props.lists).find((list: string) => list === viewList) ? Object.keys(stocks).filter((stock: string) => props.lists[viewList].find((listItem: string) => listItem === stock)).map((stock: string) => ({ title: stock, data: stock }))
              : [])))));

  return (
    <div style={{ overflow: 'scroll', paddingBottom: '60px' }}>
      <CenteredDiv style={{ maxWidth: '400px', overflow: 'scroll', whiteSpace: 'nowrap' }}>
        <CenteredDiv bold>Stock Lists:</CenteredDiv>
        {[...Object.keys(DefaultStockList).map((list: string) => (
            <Tag
              key={`tag-${list}`}
              value={{ uuid: list, name: DefaultStockList[list], color: (list === viewList) ? TagColor.Blue : undefined }}
              onClick={() => setViewList(list)}
            />)),
          ...Object.keys(lists).filter((customList: string) => !Object.keys(DefaultStockList).find((defaultList: string) => defaultList === customList)).map((list: string) => (
            <Tag
              key={`tag-${list}`}
              value={{ uuid: list, name: list, color: (list === viewList) ? TagColor.Blue : undefined }}
              onClick={() => setViewList(list)}
              icon={<Popconfirm
                placement={'leftTop'}
                title="Delete list"
                onConfirm={() => {
                  const updateList = { ...lists };
                  delete updateList[list];
                  onUpdateList(updateList);
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              ><MoreOutlined style={{ paddingLeft: '5px' }} />
              </Popconfirm>}
              onClickIcon={() => {}}
            />)),
          <Tag
            key={`add-list-${addListRef.current}`}
            value={{ uuid: `add-list-${addListRef.current}`, name: '' }}
            newTag='Add new list'
            onCreate={(newList: TagItem) => {
              if (newList.name === '') {
                infoNotification('Cancel creating new list.');
                setAddList(uuid());
                return;
              }
              if (Object.keys(lists).find((list: string) => list === newList.name)) {
                errorNotification(`List with name '${newList.name}' already exists.`);
                return;
              }
              const updateLists = { ...lists };
              updateLists[newList.name] = [];
              props.onUpdateList(updateLists);
              setAddList(uuid());
            }} />]
        }
      </CenteredDiv>
      <CenteredDiv style={{ maxWidth: 400, paddingTop: '15px' }}>
        <div style={{ paddingBottom: '5px' }}>
          <div style={{ float: 'left' }}><StockListFilter key='stock-list-filter' settings={stockListSettings} onUpdateFilter={(updateSettings: StockListSettings) => setStockListSettings(updateSettings)} /></div>
          <DivAlign hPosition={DivPosition.Right}>
            <Button size='small' selected={sort === 'alphaAsc'} onClick={() => setSort(sort === 'alphaAsc' ? 'added' : 'alphaAsc')}>A-Z</Button>
            <Button size='small' selected={sort === 'alphaDesc'} onClick={() => setSort(sort === 'alphaDesc' ? 'added' : 'alphaDesc')}>Z-A</Button>
            <Button size='small' selected={sort === 'changeAsc' || sort === 'changeDesc'} onClick={() => setSort(sort === 'changeAsc' ? 'changeDesc' : (sort === 'changeDesc' ? 'added' : 'changeAsc'))}>% Change</Button>
          </DivAlign>
        </div>
        <ViewDraggable
          style={{ height: `calc(100vh ${themeContext.deviceTypeInfo().width < 745 ? (selectedTicker ? '- 220px' : '- 310px') : (selectedTicker ? '- 310px' : '- 300px') } ${themeContext.showFooter ? '- 60px' : ''})` }}
          customKey={`viewcard-stocks`}
          dragDisabled={true}
          data={displayStocks.sort((a, b) => {
            if (sort === 'alphaAsc') {
              return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
            }
            if (sort === 'alphaDesc') {
              return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
            }
            if (sort === 'changeAsc' || sort === 'changeDesc') {
              const stockA = stocks[a.title];
              const stockB = stocks[b.title];
              const stockPriceA = stockA ? stockA.lastPrice : null;
              const stockPriceB = stockB ? stockB.lastPrice : null;
              const changeA = stockPriceA ? Number.parseFloat(stockPriceA.price.last) - stockPriceA.price.prevClose : 0;
              const changeB = stockPriceB ? Number.parseFloat(stockPriceB.price.last) - stockPriceB.price.prevClose : 0;
              const changePercentA = !stockPriceA || changeA === 0 ? 0 : ((changeA / stockPriceA.price.prevClose) * 100);
              const changePercentB = !stockPriceB || changeB === 0 ? 0 : ((changeB / stockPriceB.price.prevClose) * 100);
              const sign = sort === 'changeAsc' ? 1 : -1;
              return changePercentA < changePercentB ? -sign : sign;
            }
            return 0;
          })}
          loading={isLoading}
          noDataMessage={<div>No stocks in the list</div>}
          mapping={stockViewMapping}
          sourceName='stocks'
          extraMenu={undefined} />
      </CenteredDiv>
    </div>);
};

export default StockList;
