// https://codesandbox.io/s/react-loading-overlay-3w274?from-embed=&file=/src/index.js:1251-1270

import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { CSSProperties } from 'styled-components';
import { useWindowSize } from '../layout/Page';

type LoadingOverlayProps = {
  spinner: boolean;
  text: string;
  children: any;
  active?: boolean;
  style?: CSSProperties;
};

const LoadingOverlayStyled: React.FC<LoadingOverlayProps> = (props: LoadingOverlayProps) => {
  const { active, spinner, text, children } = props;

  const windowSize = useWindowSize();

  // message: 4px 0 12px;
  // inner-content: 12px 16px
  return (
    <LoadingOverlay
      style={{ ...props.style, height: windowSize.height, width: windowSize.width, position: 'fixed' }}
      active={active}
      spinner={spinner}
      text={text}
    >
      {children}
    </LoadingOverlay>
  );
};

export default LoadingOverlayStyled;
