import React from 'react';
import { Space, Input,  DatePicker, Select, Table, Button, Form, Checkbox } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import AuthContext from '../../context/AuthContext';
import { upperFirst } from 'lodash';
import { FieldType, InputType, dateFormat } from '../table/DataTypes';
import { FormInstance } from 'antd/lib/form';
import Tree from '../atomic/Tree';
import moment from 'moment';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

export type FormFieldEntries = { [name: string] :
  {
    type: FieldType,
    placeholder?: string,
    inputType?: InputType,
    value?: any,
    values?: string[],
    entries?: FormFieldEntries,
    variable?: boolean, // false by default
    required?: boolean, // true by default
    disabled?: boolean, // enabled by default
    label?: string, // if undefined, use name that is provided. Otherwise overwrite
    actions?: JSX.Element[];
    columns?: ColumnsType<any>,
    columnData?: { [columnKey: string] : FormFieldEntries },
  },
};

export type FormFields = {
  entries: FormFieldEntries;
};

type FormFieldsProps = {
  data: FormFields;
  form?: any;
  disabled? : boolean;
  // formRef: RefObject<FormInstance>;
  // onFinish: (values: Store) => void;
  updateFieldData?: (dataValue: any | undefined) => void;
};

type FormFieldsState = {
  dataValue: any | undefined;
  inputFields: any[],
};

class FormDataOld extends React.Component<FormFieldsProps, FormFieldsState> {
  static contextType = AuthContext;
  formRef = React.createRef<FormInstance>();

  constructor(props: FormFieldsProps) {
    super(props);

    this.state = {
      dataValue: undefined,
      inputFields: [],
    };
  }

  componentDidMount() {
    if (this.props.data.entries) {
      const inputFields = this.getInputFields(this.props.data.entries, undefined, undefined, this.props.disabled);
      this.setState({ inputFields });
    }
  }

  componentDidUpdate() {
    if (this.props.data.entries) {
      const inputFields = this.getInputFields(this.props.data.entries, undefined, undefined, this.props.disabled);
      const update = inputFields.length !== this.state.inputFields.length
      || (inputFields.length > 0 && inputFields.filter((f: any) => this.state.inputFields.filter((s: any) => s.props.name === f.props.name).length === 0).length > 0);
      if (update) {
        this.setState({ inputFields });
      }
    }
  }

  getInputFields = (modalFields: any | undefined, parentKey?: string, includeName?: boolean, disabled = false, actions?: JSX.Element[]) => {
    if (!modalFields) {
      return [];
    }

    const parentTree = parentKey ? parentKey.split('.') : [];
    let modalNode = modalFields;
    parentTree.forEach((nodeKey: string) => modalNode = modalNode[nodeKey]);

    const dataKeys = Object.keys(modalNode);

    const inputFields: any[] = [];
    dataKeys.forEach((key: string) => {
      console.log('dataKeys.forEach data');
      console.log(modalFields);
      if (!modalNode[key].type) {
        inputFields.push(...this.getInputFields(modalFields, parentKey ? `${parentKey}.${key}` : key), disabled);
      }
      else {
        const fieldObject: string | JSX.Element = this.getFieldJsxObject(modalFields, key, modalNode[key], parentKey, includeName, disabled, actions);
        const formKey = parentKey ? `form-${parentKey.replace('.', '-')}-${key}` : `form-${key}`;
        inputFields.push(<Form.Item key={`${formKey}`} name={`${formKey}`} rules={[{ required: modalNode[key].required ?? true, message: `Please input ${key}!` }]}>
        {fieldObject}</Form.Item>);
      }
    });
    console.log(inputFields);
    return inputFields;
  }

  updateLeafValue = (node: any, key: string, path: string[], newValue: any) => {
    const newNode = node;
    if (path.length > 0) {
      newNode[path[0]] = this.updateLeafValue(node[path[0]], key, path.slice(1), newValue);
    }
    else {
      newNode[key].value = newValue;
    }
    return newNode;
  }

  onFieldChange = (newValue: any, key: string, parentKey: string[]) => {
    let prevState = this.state.dataValue ? this.state.dataValue : this.props.data.entries;
    console.log('onFieldChange');
    console.log(prevState);
    console.log(key);
    console.log(newValue);

    prevState = this.updateLeafValue(prevState, key, parentKey, newValue);

    this.setState({ dataValue: prevState });
    if (this.props.updateFieldData) {
      this.props.updateFieldData(prevState);
    }
  }

  render() {
    // const inputFields = this.getInputFields();
    return (
      <React.Fragment>
        <Form style={{ width: '100%' }}{...formItemLayout} layout="horizontal" ref={this.formRef}>
          {this.state.inputFields}
        </Form>
      </React.Fragment>
    );
  }

  private getFieldJsxObject(dataNode: any, key: string, valueNode: any, parentKey?: string, includeName = true, disabled = false, actions?: JSX.Element[]) {
    const parentTree = parentKey ? parentKey.split('.') : [];
    let data = dataNode;
    parentTree.forEach((nodeKey: string) => data = data[nodeKey] ? data[nodeKey] : {});
    let fieldObject: JSX.Element = <div>Unknown type {data[key].type} for {key}</div>;
    console.log('getFieldsJsxObject');
    console.log(data);
    console.log(key);
    // let variableObj: { [name: string]: JSX.Element } = {};
    const dataSourceObj: { [name: string]: JSX.Element }[] = [];
    if (data[key].type === FieldType.Tree) {
      fieldObject = <Tree
        disabled={disabled}
        data={data[key].values}
        showSelectAll={true}
        checkedKeys={data[key].value && data[key].value.checkedKeys ? data[key].value.checkedKeys : undefined}
        updateSelected={(e: any) => this.onFieldChange(e, key, parentTree)}
      />;
    }
    if (data[key].type === FieldType.Checkbox) {
      fieldObject = <Checkbox.Group
        disabled={disabled}
        key={`input-${key}`}
        options={data[key].values}
        defaultValue={data[key].value}
        onChange={(e: any) => {
          this.onFieldChange(e, key, parentTree);
        }}
        />;
    }
    if (data[key].type === FieldType.Text) {
      fieldObject = <Input.TextArea
        disabled={disabled}
        placeholder={data[key].placeholder}
        value={valueNode.value}
        onChange={(e: any) => {
          this.onFieldChange(e.target.value, key, parentTree);
        }
        } />;
    }
    if (data[key].type === FieldType.Input) {
      fieldObject = <Input
        disabled={disabled}
        placeholder={data[key].placeholder}
        value={valueNode.value}
        onChange={(e: any) => this.onFieldChange(e.target.value, key, parentTree)} />;
    }
    if (data[key].type === FieldType.InputArray) {
      fieldObject = <Input
        disabled={disabled}
        placeholder={data[key].placeholder}
        value={valueNode.value}
        onChange={(e: any) => this.onFieldChange(e.target.value, key, parentTree)} /> ;
    }

    if (data[key].type === FieldType.Index) {
      fieldObject = <div>{valueNode}</div>;
    }
    if (data[key].type === FieldType.JsxElement) {
      fieldObject = valueNode.value;
    }
    else if (data[key].type === FieldType.Date) {
      fieldObject = valueNode.value ? <DatePicker
        disabled={disabled}
        defaultValue={moment(valueNode.value)}
        format={dateFormat}
        onChange={(e: any) => this.onFieldChange(e, key, parentTree)} /> : <DatePicker disabled={disabled} />; // <div></div>; */
    }
    else if (data[key].type === FieldType.Dropdown) {
      const dropdownOptions = data[key].values.map((item: any) => (
        <Select.Option
          key={`dropdown-${key}-${item}`}
          value={item}>{item}</Select.Option>));
      fieldObject = <Select
        disabled={disabled}
        placeholder={data[key].placeholder}
        onChange={(e: any) => this.onFieldChange(e, key, parentTree)}
      >{dropdownOptions}</Select>;
    }
    else if (data[key].type === FieldType.Action) {
      fieldObject = data[key].values.map((item: any) => (
        <Button disabled={disabled} value={item}>{item}</Button>));
    }
    else if (data[key].type === FieldType.Table) {
      console.log('Table');
      console.log(data[key].columnData);
      const dataKeys = Object.keys(data[key].columnData);
      console.log(dataKeys);
      const dataSourceElement: { [name: string]: JSX.Element }  = {};
      dataKeys.forEach((columnKey: string) => {
        const columnData = data[key].columnData[columnKey];
        const columnDataKeys = Object.keys(columnData);
        const columnFieldData: JSX.Element[] = [];
        console.log('columnDataKeys');
        console.log(columnDataKeys);
        columnDataKeys.forEach((columnFieldKey: string) => {
          console.log(`columnFieldKey ${columnFieldKey}`);
          console.log(columnData);
          console.log(valueNode);
          const tableField = this.getFieldJsxObject(
            columnData,
            columnFieldKey,
            columnData[columnFieldKey].type === FieldType.Index ? /* index + */ 1 : valueNode.columnData[columnKey][columnFieldKey],
            undefined,
            false,
            disabled,
            actions);
          console.log('tableField');
          console.log(tableField);
          columnFieldData.push(tableField);
        });
        dataSourceElement[columnKey] = <React.Fragment><Space direction='vertical'>{columnFieldData}</Space></React.Fragment>;
        dataSourceObj.push(dataSourceElement);
        // if (data[key].variable) {
        //   variableObj = dataSourceElement;
        // }
        console.log('dataSourceElement');
        console.log(dataSourceElement);
      });
      console.log('columns');
      console.log(data[key].columns);
      fieldObject = <Table
        className='.antd-modal-fields'
        size='small'
        columns={data[key].columns}
        dataSource={dataSourceObj}
        bordered={true}
        pagination={false}
        scroll={{ x: '100', y: '300' }}
      />;
    }
    return <React.Fragment>{data[key].type === FieldType.Table || !includeName || !data[key].label || data[key].label === '' ? '' : <div>{upperFirst(key)}:</div>}{data[key].actions ? <Space direction='horizontal'>{fieldObject}{data[key].actions}</Space> : fieldObject}{data[key].variable && data[key].type === FieldType.Table
      ? <Button disabled={disabled} onClick={() => {
      }}>Add new data field</Button>
      : ''}</React.Fragment>;
  }
}

export default FormDataOld;
