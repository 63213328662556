import React from 'react';
import styled from 'styled-components';
import Popconfirm, { PopconfirmProps } from './Popconfirm';

type DarkBackgroundProps = {
  isVisible: boolean;
};

export const DarkBackground = styled.div`
  display: ${(props: DarkBackgroundProps) => props.isVisible ? 'block' : 'none'}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 4000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

type TutorialTooltipProps = PopconfirmProps & {
};

const TutorialTooltip: React.FC<TutorialTooltipProps> = (props: TutorialTooltipProps) => {
  const { visible, ...popconfirmProps } = props;

  // message: 4px 0 12px;
  // inner-content: 12px 16px
  return (
    <Popconfirm
      visible={visible}
      {...popconfirmProps}
    >
    </Popconfirm>
  );
};

export default TutorialTooltip;
