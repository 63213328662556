import moment from 'moment';
import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import _, { Dictionary } from 'lodash';
import { PlaidAccount, PlaidHoldings, PlaidInvestment, PlaidLinkedInstitution, PlaidTransactions } from '../../graphql/generated/graphql';
import DivAlign, { DivPosition } from '../atomic/DivAlign';
import { CenteredDiv } from '../styled/CenteredDiv';
import { RecordType } from '../table/DataTypes';
import { EditableColumnProps } from '../table/EditableTable';
import Button from '../atomic/Button';
import Plaid from '../../integration/plaid/Plaid';
import Table from '../table/Table';
import GetInvestments from '../../containers/api/Plaid/GetInvestments';
import GetHoldings from '../../containers/api/Plaid/GetHoldings';
import { errorNotification, infoNotification } from '../actions/Notification';
import ImportFidelityOpenOrders from './import/ImportFidelityOpenOrder';
import GetCoinbase from '../../containers/api/Plaid/GetCoinbase';
import { Stock, StockOrder, StockTransaction } from './StockTypes';
import Popconfirm from '../atomic/Popconfirm';
import DatePicker from '../atomic/DatePicker';
import ImportRobinhood from './import/robinhood/ImportRobinhood';

export enum AccountType {
  Credit = 'credit',
  Depository = 'depository',
  Investment = 'investment',
  Other = 'other',
}

type ImportInstance = {
  uuid: string;
  brokerName: string;
  broker: PlaidLinkedInstitution;
  type: 'transactions' | 'investments' | 'holdings' | 'openOrders' | 'robinhood' | 'fidelity';
  startDate?: Date;
  endDate?: Date;
};

/*
export const COINBASE_INSTITUTION: PlaidLinkedInstitution = {
  linkedId: '',
  institutionId: 'coinbasepro',
  institutionName: 'Coinbase Pro',
  accounts: [],
};
*/

export const COINBASE_INSTITUTION: string = 'Coinbase Pro';

type BrokerListProps = {
  accountTypes?: AccountType[];
  linkedInstitutions?: PlaidLinkedInstitution[];
  stockDetails: Dictionary<Stock>;
  onUpdateLinkedInstitutions: (linkedInstitutions: PlaidLinkedInstitution[]) => void;
  onUpdateHoldings: (broker: string, holdings: PlaidHoldings) => void;
  onUpdateInvestmentTransactions: (broker: string, investmentTransactions: PlaidInvestment) => void;
  onUpdateTransactions: (broker: string, transactions: PlaidTransactions) => void;
  onUpdateOrders?: (broker: string, orders: StockOrder[]) => void;
  onUpdateStockDetails: (updateStocks: Stock[]) => void;
  onClose: () => void;
};

const BrokerList: React.FC<BrokerListProps> = (props: BrokerListProps) => {
  const { accountTypes, linkedInstitutions, stockDetails, onUpdateLinkedInstitutions, onUpdateStockDetails } = props;
  const [importFromBroker, setImportFromBroker] = useState<ImportInstance[]>([]);
  const [getOpenOrders, setGetOpenOrders] = useState<string | undefined>(undefined);
  const [initConnectBank, setInitConnectBank] = useState<string>(new Date().toUTCString());
  const [showAccounts, setShowAccounts] = useState<Dictionary<boolean>>({}); // const sandboxKey = s2pConfig.PLAID_ENV === 'sandbox' ? '-sandbox' : '';
  const [startDate, setStartDate] = useState<Date>(moment(new Date()).add(-1, 'year').toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [chooseDates] = useState<boolean>(false);
  const [fetchFromBroker, setFetchFromBroker] = useState<ImportInstance | undefined>(undefined);

  useEffect(() => {
    /*
    if (props.linkedInstitutions) {
      setAccounts((prevAccounts: Dictionary<PlaidAccount[]> | undefined) => {
        let updated = false;
        const updatePrevAccounts = prevAccounts ? { ...prevAccounts } : {};
        props.linkedInstitutions!.forEach((inst: PlaidLinkedInstitution) => {
          if (!updatePrevAccounts[inst.institutionName] || updatePrevAccounts[inst.institutionName].length !== inst.accounts.length) {
            updated = true;
            updatePrevAccounts[inst.institutionName] = inst.accounts;
          }
        });
        return updated ? updatePrevAccounts : prevAccounts;
      });
    }
    */
    /*
    if (accounts === undefined) {
      const savedAccounts = localStorage.getItem(`s2p-bank-accounts${sandboxKey}`) ?? '{}';
      setAccounts(JSON.parse(savedAccounts));
    }
    */
    /*
    if (brokers === undefined) {
      const savedBrokers = localStorage.getItem(`s2p-bank-brokers${sandboxKey}`) ?? '{}';
      setBrokers(JSON.parse(savedBrokers));
    }
    */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  /*
  useEffect(() => {
    if (brokers !== undefined) {
      localStorage.setItem(`s2p-bank-brokers${sandboxKey}`, JSON.stringify(brokers));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [brokers]);

  if (brokers === undefined) {
    return null;
  }
  */

  /*
  const fetchLinkedInstitutions = linkedInstitutions === undefined
    ? <GetLinkedInstitutions
      key='fetch-linked-institutions'
      onUpdateLinkedInstitutions={(data?: PlaidLinkedInstitution[], loading?: boolean, error?: any) => {
        if (data) {
          return onUpdateLinkedInstitutions(data);
        }
      }} />
    : null;
  */

  const fetchInvestments = importFromBroker
    .filter((importInvestments: ImportInstance) => importInvestments.type === 'investments')
    .map((importInvestments: ImportInstance) => {
      if (importInvestments.brokerName === COINBASE_INSTITUTION) {
        return <GetCoinbase key='import-coinbase-transactions' dataType='transactions' onUpdate={(data?: { broker: string, investments?: PlaidInvestment, holdings?: PlaidHoldings, orders?: StockOrder[], accounts?: any }, loading?: boolean, error?: any) => {
          if (data && data.investments) {
            props.onUpdateInvestmentTransactions(data.broker, data.investments);
            setImportFromBroker((prevImport : ImportInstance[]) => {
              const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== importInvestments.uuid);
              return updatePrevImport;
            });
          }
        }} />;
      }
      return <GetInvestments
        key={importInvestments.uuid}
        broker={{ name: importInvestments.brokerName, linkedId: importInvestments.broker.linkedId }}
        startDate={importInvestments.startDate ? moment(importInvestments.startDate).format('YYYY-MM-DD') : '2018-01-01'}
        endDate={importInvestments.endDate ? moment(importInvestments.endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')}
        onUpdate={(data?: { broker: string, investments: PlaidInvestment }, loading?: any, error?: any) => {
          if (data) {
            console.log('investments');
            props.onUpdateInvestmentTransactions(data.broker, data.investments);
            setImportFromBroker((prevImport : ImportInstance[]) => {
              const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== importInvestments.uuid);
              return updatePrevImport;
            });
            /*
            setInvestements((prevInvestments) => {
              const updatePrevInvestments = { ...prevInvestments };
              updatePrevInvestments[data.broker] = data.investments;
              return updatePrevInvestments;
            });
            */
          }
        }} />;
    });

  // Only fetch accounts once loaded from localStorage
  // const fetchAccounts = accounts ? Object.keys(brokers)
  /*
  const fetchAccounts = linkedInstitutions && accounts ? linkedInstitutions
    .filter((broker: PlaidLinkedInstitution) => !accounts[broker.])
    .map((broker: string) => {
      return <GetAccounts
        key={broker}
        broker={{ name: broker, accessToken: brokers[broker].accessToken }}
        onUpdate={(data?: { broker: string, accounts: PlaidAccount[] }, loading?: any, error?: any) => {
          if (data) {
            console.log(`accounts fetched for ${broker}`);
            setAccounts((prevAccounts: Dictionary<PlaidAccount[]> | undefined) => {
              // this will not execute unless accounts is already set.
              const updatePrevAccounts = { ...prevAccounts };
              updatePrevAccounts[data.broker] = data.accounts;
              return updatePrevAccounts;
            });
          }
        }} />;
    }) : null; */

  const fetchHoldings = importFromBroker
    .filter((importInvestments: ImportInstance) => importInvestments.type === 'holdings')
    .map((importInvestments: ImportInstance) => {
      if (importInvestments.brokerName === COINBASE_INSTITUTION) {
        return <GetCoinbase key='import-coinbase-holdings' dataType='holdings' onUpdate={(data?: { broker: string, investments?: PlaidInvestment, holdings?: PlaidHoldings, orders?: StockOrder[], accounts?: any }, loading?: boolean, error?: any) => {
          if (data && data.holdings) {
            props.onUpdateHoldings(data.broker, data.holdings);
            setImportFromBroker((prevImport : ImportInstance[]) => {
              const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== importInvestments.uuid);
              return updatePrevImport;
            });
          }
        }} />;
      }
      return <GetHoldings
        key={importInvestments.uuid}
        broker={{ name: importInvestments.brokerName, linkedId: importInvestments.broker.linkedId }}
        onUpdate={(data?: { broker: string, holdings: PlaidHoldings }, loading?: any, error?: any) => {
          if (data) {
            console.log('holdings');
            props.onUpdateHoldings(data.broker, data.holdings);
            setImportFromBroker((prevImport : ImportInstance[]) => {
              const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== importInvestments.uuid);
              return updatePrevImport;
            });
            /*
            setHoldings((prevHoldings) => {
              const updatePrevHoldings = { ...prevHoldings };
              updatePrevHoldings[data.broker] = data.holdings;
              return updatePrevHoldings;
            });
            */
          }
        }} />;
    });

  const fetchOpenOrders = importFromBroker
    .filter((importInvestments: ImportInstance) => importInvestments.type === 'openOrders')
    .map((importInstance: ImportInstance) => {
      return importInstance.brokerName === 'Robinhood'
        ? <ImportRobinhood
          institution={importInstance.broker}
          stocks={stockDetails}
          existingInvestmentTransactions={{}}
          key={`import-open-orders-robinhood`}
          onUpdateStockDetails={onUpdateStockDetails}
          onUpdateLinkedInstitution={(updateLinkedInstitution: PlaidLinkedInstitution) => {
            const updateInstitutions = linkedInstitutions ? [...linkedInstitutions] : [updateLinkedInstitution];
            if (linkedInstitutions) {
              const updateIndex = updateInstitutions.findIndex((inst: PlaidLinkedInstitution) => inst.institutionId === updateLinkedInstitution.institutionId && inst.linkedId === updateLinkedInstitution.linkedId);
              if (updateIndex < 0) {
                console.log(`No linked institution found for ${updateLinkedInstitution.institutionName}. Nothing updated.`);
                return;
              }
              updateInstitutions[updateIndex] = updateLinkedInstitution;
            }
            onUpdateLinkedInstitutions(updateInstitutions);
          }}
          onClose={() => setImportFromBroker((prevImport : ImportInstance[]) => {
            const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== importInstance.uuid);
            return updatePrevImport;
          })} />
        : (importInstance.brokerName === 'Fidelity'
          ? <ImportFidelityOpenOrders
            key='import-open-orders-fidelity'
            institution={importInstance.broker}
            existingInvestmentTransactions={{}}
            stockDetails={stockDetails}
            onImportInvestmentTransactions={(investments: Dictionary<StockTransaction[]>, updateStockDetails: Stock[], importAccounts: Dictionary<Dictionary<PlaidAccount>>) => {
              if (onUpdateStockDetails && updateStockDetails.length > 0) {
                onUpdateStockDetails(updateStockDetails);
              }
              setImportFromBroker((prevImport : ImportInstance[]) => {
                const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== importInstance.uuid);
                return updatePrevImport;
              });
            }}
            onUpdateStockDetails={onUpdateStockDetails}
            onUpdateLinkedInstitution={(updateInstitution: PlaidLinkedInstitution) => {}}
            onClose={() => setImportFromBroker((prevImport : ImportInstance[]) => {
              const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== importInstance.uuid);
              return updatePrevImport;
            })} />
          : (getOpenOrders === COINBASE_INSTITUTION ? <GetCoinbase key='import-coinbase-orders' dataType='orders' onUpdate={(data?: { broker: string, investments?: PlaidInvestment, holdings?: PlaidHoldings, orders?: StockOrder[], accounts?: any }, loading?: boolean, error?: any) => {
            if (props.onUpdateOrders && data && data.orders) {
              props.onUpdateOrders(COINBASE_INSTITUTION, data.orders);
              setGetOpenOrders(undefined);
            }
          }} />
            : (getOpenOrders ? infoNotification(`${getOpenOrders} not supported`) : null)));
    });

  const brokerColumns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 50,
      render: (text: string, record: any, index: number) => {
        return {
          children: <div>{record.id + 1}</div>,
          props: {
            rowSpan: record.rowSpan ?? 1,
          },
        };
      },
    },
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      key: 'bankName',
      inputType: { recordType: RecordType.Text },
      render: (text: string, record: any, index: number) => {
        return {
          children: text,
          props: {
            rowSpan: record.rowSpan ?? 1,
          },
        };
      },
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      inputType: { recordType: RecordType.Text },
    },
    {
      title: 'Holdings',
      dataIndex: 'holdings',
      key: 'holdings',
      inputType: { recordType: RecordType.Render },
      width: '150px',
      render: (text: string, record: any) => (
        record.rowSpan === 0 ? <CenteredDiv>--</CenteredDiv> :
        <CenteredDiv>
          <Button size='small' onClick={() => {
            setImportFromBroker((prevImport) => {
              const updatePrevImport = [...prevImport];
              const broker = linkedInstitutions ? linkedInstitutions.find((inst: PlaidLinkedInstitution) => inst.institutionName === record.bankName) : undefined;
              if (record.bankName && broker) {
                updatePrevImport.push({
                  uuid: uuid(),
                  brokerName: record.bankName,
                  broker,
                  type: 'holdings',
                });
              }
              else {
                errorNotification('Something went wrong, please try again.');
              }
              return updatePrevImport;
            });
            // setGetHoldings(record.name);
          }}>Import</Button>
        </CenteredDiv>
      ),
    },
    {
      title: 'Transactions',
      dataIndex: 'transactions',
      key: 'transactions',
      inputType: { recordType: RecordType.Render },
      width: '150px',
      render: (text: string, record: any) => (
        record.rowSpan === 0 ? <CenteredDiv>--</CenteredDiv> :
        <CenteredDiv>
          {chooseDates
          ? <Popconfirm
            key={`import-transactins`}
            header={"Import transactions for the following date interval"}
            icon={null}
            title={<>
              <div><span style={{ marginRight: '15px', width: '70px', display: 'inline-block' }}>Start Date:</span>
                <DatePicker
                  key={`data-picker-start}`}
                  allowClear={false}
                  initialValue={moment(startDate)}
                  popupStyle={{ zIndex: 2000 }}
                  onChange={(e: any) => {
                    // TODO
                    if (e > endDate) {
                      infoNotification('Start Date must be before End Date.');
                      return;
                    }
                    setStartDate(e.toDate());
                  }} />
              </div>
              <div><span style={{ marginRight: '15px', width: '70px', display: 'inline-block' }}>End Date:</span>
                <DatePicker
                  key={`data-picker-end}`}
                  allowClear={false}
                  initialValue={moment(endDate)}
                  popupStyle={{ zIndex: 2000 }}
                  onChange={(e: any) => {
                    if (e < startDate) {
                      infoNotification('End Date must be after Start Date.');
                      return;
                    }
                    setEndDate(e.toDate());
                  }} />
              </div>
            </>}
            onConfirm={() => {
              setImportFromBroker((prevImport) => {
                const updatePrevImport = [...prevImport];
                const broker = linkedInstitutions ? linkedInstitutions.find((inst: PlaidLinkedInstitution) => inst.institutionName === record.bankName) : undefined;
                if (record.bankName && broker) {
                  updatePrevImport.push({
                    uuid: uuid(),
                    brokerName: record.bankName,
                    broker,
                    type: 'investments',
                    startDate,
                    endDate,
                  });
                }
                else {
                  errorNotification('Something went wrong, please try again.');
                }
                return updatePrevImport;
              });
            }}
            onCancel={() => {
              setStartDate(new Date());
              setEndDate(moment(new Date()).add(-1, 'year').toDate());
            }}
            okText='Import'
            cancelText='Cancel'
            >
              <Button size='small'>Import</Button>
            </Popconfirm>
            : <Button size='small' onClick={() => setImportFromBroker((prevImport) => {
              const updatePrevImport = [...prevImport];
              const broker = linkedInstitutions ? linkedInstitutions.find((inst: PlaidLinkedInstitution) => inst.institutionName === record.bankName) : undefined;
              if (record.bankName && broker) {
                updatePrevImport.push({
                  uuid: uuid(),
                  brokerName: record.bankName,
                  broker,
                  type: 'investments',
                });
              }
              else {
                errorNotification('Something went wrong, please try again.');
              }
              return updatePrevImport;
            })}>Import</Button>}
        </CenteredDiv>
      ),
    },
    {
      title: 'Open Orders',
      dataIndex: 'openOrders',
      key: 'openOrders',
      inputType: { recordType: RecordType.Render },
      width: '150px',
      render: (text: string, record: any) => (
        record.rowSpan === 0 ? <CenteredDiv>--</CenteredDiv> :
        <CenteredDiv>
          <Button size='small' onClick={() => {
            const broker = linkedInstitutions ? linkedInstitutions.find((inst: PlaidLinkedInstitution) => inst.institutionName === record.bankName) : undefined;
            if (record.bankName === 'Robinhood' && broker) {
              setFetchFromBroker({
                uuid: uuid(),
                brokerName: record.bankName,
                broker,
                type: 'robinhood',
              });
              return;
            }
            setImportFromBroker((prevImport) => {
              const updatePrevImport = [...prevImport];
              if (record.bankName && broker) {
                updatePrevImport.push({
                  uuid: uuid(),
                  brokerName: record.bankName,
                  broker,
                  type: 'openOrders',
                });
              }
              else {
                errorNotification('Something went wrong, please try again.');
              }
              return updatePrevImport;
            });
            setGetOpenOrders(record.bankName);
          }}>Import</Button>
        </CenteredDiv>
      ),
    },
    /*
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      inputType: { recordType: RecordType.Render },
      render: (text: string, record: any) => (
        <>
          <Button size='small' onClick={() => {
            setGetHoldings(record.name);
          }}>Import holdings</Button>
          <Button size='small' onClick={() => {
            setGetInvestments(record.name);
          }}>Import transactions</Button>
          <Button size='small' onClick={() => {
            setGetOpenOrders(record.name);
          }}>Import open orders</Button>
          <Button size='small' disabled onClick={() => {}}>Disconnect</Button>
        </>
      ),
    },
    */
  ];

  const documentData: any[] = [];
  (linkedInstitutions ?? [])
    .sort((one: PlaidLinkedInstitution, two: PlaidLinkedInstitution) => (one.institutionName.toLowerCase() < two.institutionName.toLowerCase() ? -1 : 1))
    .forEach((linkedInstitution: PlaidLinkedInstitution, index: number) => {
      const displayAccounts = accountTypes
          ? linkedInstitution.accounts.filter((account: PlaidAccount) => accountTypes.find((accountType: AccountType) => account.type && accountType === AccountType[_.upperFirst(account.type)]))
          : linkedInstitution.accounts;
      const hideBrokerAccounts = !showAccounts[linkedInstitution.institutionName];
      const addTransaction = {
        key: index,
        id: index,
        bankName: linkedInstitution.institutionName,
        rowSpan: displayAccounts === undefined || displayAccounts.length === 0 || hideBrokerAccounts ? 1 : displayAccounts.length + 1,
        account: displayAccounts === undefined ? 'loading...'
          : (displayAccounts.length > 0
            ? <div>{displayAccounts.length} accounts (<span style={{ color: 'blue' }} onClick={() => setShowAccounts((prevShowAccounts) => {
              const updateShowAccounts = { ...prevShowAccounts } ;
              updateShowAccounts[linkedInstitution.institutionName] = !updateShowAccounts[linkedInstitution.institutionName];
              return updateShowAccounts;
            })}>{hideBrokerAccounts ? 'show' : 'hide'}</span>)</div>
            : 'No accounts'),
      };
      documentData.push(addTransaction);
      if (displayAccounts && !hideBrokerAccounts) {
        displayAccounts.forEach((account: PlaidAccount) => {
          const addAccount = {
            id: index,
            bankName: linkedInstitution.institutionName,
            rowSpan: 0,
            account: `${account.lastFour || account.subtype ? `[${account.lastFour ?? account.lastFour}${account.lastFour && account.subtype ? ' ' : ''}${account.subtype ?? ''}] ` : ''}${account.name}`,
          };
          documentData.push(addAccount);
        });
      }
    });

  return <>
    {fetchInvestments}
    {fetchHoldings}
    {fetchOpenOrders}
    <DivAlign hPosition={DivPosition.Right}>
      {fetchFromBroker ? <Button onClick={() => setFetchFromBroker(undefined)}>Show connected brokers</Button> : null}
      {!fetchFromBroker ? <Plaid
        /* broker={importFromBroker ? brokers[importFromBroker] : undefined} */
        key={initConnectBank}
        linkedInstitutions={linkedInstitutions ?? []}
        onUpdateLinkedInstitution={(broker: PlaidLinkedInstitution) => {
          const updatedLinkedInstitutions = linkedInstitutions ? [...linkedInstitutions] : [];
          updatedLinkedInstitutions.push(broker);
          onUpdateLinkedInstitutions(updatedLinkedInstitutions);
          /*
          setAccounts((prevAccounts: Dictionary<PlaidAccount[]> | undefined) => {
            // this will not execute unless accounts is already set.
            const updatePrevAccounts = { ...prevAccounts };
            updatePrevAccounts[broker.institutionName] = broker.accounts;
            return updatePrevAccounts;
          });
          */
          /*
          setBrokers((prevBrokers) => {
            const updatePrevBrokers = { ...prevBrokers };
            updatePrevBrokers[name] = broker;
            return updatePrevBrokers;
          });
          */
          setInitConnectBank(new Date().toUTCString());
        }}
      /> : null}
      <Button onClick={() => fetchFromBroker ? setFetchFromBroker(undefined) : props.onClose()}>Close</Button>
    </DivAlign>
    {fetchFromBroker
      ? <ImportRobinhood
      institution={fetchFromBroker.broker}
      stocks={stockDetails}
      existingInvestmentTransactions={{}}
      key={`import-broker-init-robinhood`}
      onUpdateStockDetails={onUpdateStockDetails}
      onUpdateLinkedInstitution={(updateLinkedInstitution: PlaidLinkedInstitution) => {
        const updateInstitutions = linkedInstitutions ? [...linkedInstitutions] : [updateLinkedInstitution];
        if (linkedInstitutions) {
          const updateIndex = updateInstitutions.findIndex((inst: PlaidLinkedInstitution) => inst.institutionId === updateLinkedInstitution.institutionId && inst.linkedId === updateLinkedInstitution.linkedId);
          if (updateIndex < 0) {
            console.log(`No linked institution found for ${updateLinkedInstitution.institutionName}. Nothing updated.`);
            return;
          }
          updateInstitutions[updateIndex] = updateLinkedInstitution;
        }
        onUpdateLinkedInstitutions(updateInstitutions);
      }}
      onClose={() => setFetchFromBroker(undefined) /* setImportFromBroker((prevImport : ImportInstance[]) => {
        const updatePrevImport = [...prevImport].filter((instance: ImportInstance) => instance.uuid !== fetchFromBroker.uuid);
        return updatePrevImport;
      }) */ } />
      : <><CenteredDiv bold>Connected Brokers:</CenteredDiv>
      <CenteredDiv>
        <Table
          style={{ overflow: 'auto' }}
          className='.antd-documents-table'
          size='small'
          pagination={false}
          // scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
          columns={brokerColumns}
          dataSource={documentData}
          bordered={true}
          /*
          updateDataSource={(data: any[]) => {
            console.log(data);
          }}
          */
        />
      </CenteredDiv></>}
  </>;
};

export default BrokerList;
