import React, { useEffect } from 'react';
import { FieldType } from '../../components/table/DataTypes';
import { ApiProps } from './Helpers';
import JsxViewResponse from './JsxViewResponse';
import { useGetFilesQuery } from '../../graphql/generated/graphql';
import logger from '../../utils/Logger';
import { getResponseDataObjs } from './Api';

export const storageClientContext = { context: { clientName: 'storage' } };
const logInfo = (message: any) => logger.info({ source: 'containers.api.Storage', message });
const logInfoJson = (message: any) => logger.info({ source: 'containers.api.Storage', message: JSON.stringify(message) });

type StorageApiProps = ApiProps & {
};

export const GetFiles: React.FC<StorageApiProps> = (props: StorageApiProps) => {
  const { ...apiProps } = props;
  const { data: files } = useGetFilesQuery({
    variables: { },
    ...storageClientContext,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    logInfo('GetUser useEffect');
    logInfoJson(files);

    if (!files || !files.getFiles) {
      return;
    }

    if (props.updateApiResponse) {
      logInfo('GetUser useEffect updateApiResponse');
      props.updateApiResponse(files.getFiles);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [files]);

  logInfo('GetFiles data');
  logInfoJson(apiProps);
  logInfoJson(files);

  const result = files ? (<JsxViewResponse data={files?.getFiles} {...apiProps} />) : null;
  logInfo('GetUser result');
  if (!apiProps.returnJsx) {
    return null;
  }
  return files ? result : <div>{'No Data Loaded'}</div>; // apiProps.returnJsx ? result : null;
};

export const fileResponse = (keyPath?: string) => {
  return [...getResponseDataObjs(
    [
      'uuid',
      'url',
      'filename',
      'expriation',
    ],
    keyPath),
  ];
};

export const filesResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [...fileResponse()] },
  },
};

export const storageApiDefinition = {
  storage: {
    getFiles: {
      function: (apiProps: ApiProps) => {
        console.log('storageApiDefinition.getFiles.function');
        console.log(apiProps);
        return <React.Fragment><GetFiles {...apiProps} /></React.Fragment>;
      },
      arguments: {
        entries: {
        },
      },
      response: filesResponse,
    },
  },
};
