import React, { useEffect }  from 'react';
import { StockResult, useGetStockSearchQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type StockSearchProps = {
  query: string;
  onUpdate?: (data?: { query: string; result: StockResult[] }, loading?: boolean, error?: any) => any;
};

const GetStockSearch: React.FC<StockSearchProps> = (props: StockSearchProps) => {
  const { onUpdate, query } = props;
  const { data: stock, loading: stockLoading, error: stockError } = useGetStockSearchQuery({
    variables: { query },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(stock ? { query, result: stock.getStockSearch } : undefined, stockLoading, stockError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stock, stockLoading, stockError]);

  return null;
};

export default GetStockSearch;
