import React, { useState } from 'react';

import { GetDataPages } from '../../containers/api/DataPage';
import { ResultViewEnum } from '../../containers/api/Helpers';
import ViewCollapsable from '../views/Collapsable';
import logger from '../../utils/Logger';
import UserData from './User';

const logInfo = (message: any) => logger.info({ source: 'pages.Pages', message });
const logInfoJson = (message: any) => logger.info({ source: 'pages.Pages', message: JSON.stringify(message) });

type PagesProps = {
};

const PagesData: React.FC<PagesProps> = (props: PagesProps) => {
  // const fields = getResponseKeys(['name', 'description', 'uuid', 'ownerUuid'], dataApiDefinition.getDataPages.response.entries.response.values);
  const [pagesData, setPagesData] = useState<any>(null);
  const dataPages = <GetDataPages view={ResultViewEnum.Json} updateApiResponse={(data: any) => setPagesData(data)}/>;

  console.log('Pages pagesData');
  console.log(pagesData);

  const data = pagesData ? pagesData.map((d: any, index: number) => {
    const extra: any[] = [];
    extra.push(<UserData uuid={d.ownerUuid} />);
    if (d.description) {
      extra.push(<div>description: {d.description}</div>);
    }

    return ({
      header: [<a href={`/pages/${d.uuid}`}>{d.name}</a>],
      extra,
    });
  }) : [];

  console.log(data);

  return (
    <>
      {dataPages}
      {pagesData ? <ViewCollapsable sourceName='pages' style={{ top: 15, maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }} data={data} mapping={null} /> : null}
    </>
  );
};

export default PagesData;
