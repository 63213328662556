import gql from 'graphql-tag';

export default gql`
  fragment StockPrice on StockPrice {
    ticker
    timestamp
    low
    high
    prevClose
    open
    last
  }
  fragment StockHistoryPrice on StockHistoryPrice {
    date
    open
    close
    low
    high
    volume
    adjOpen
    adjClose
    adjLow
    adjHigh
    adjVolume
    divCash
    splitFactor
  }
  fragment StockResult on StockResult {
    name
    ticker
    assetType
    countryCode
    active
  }
  fragment StockDetails on StockDetails {
    name
    ticker
    cusip
    isin
    sedol
    description
    exchangeCode
    startDate
    endDate
    isCrypto
  }
`;
