import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import styled from 'styled-components';

type HandleProps = {
  selected: boolean;
};

const Handle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none;
  margin: -0.5rem 0.5rem -0.5rem -0.5rem;
  padding: 0.5rem;
  line-height: 1.5;
  border-radius: 3px 0 0 3px;
  background: #fff;
  /* border-right: 1px solid #ddd; */
  color: ${(props: HandleProps) => props.selected ? 'blue' : '#000'};
`;

type DragHandleProps = {
  provided: DraggableProvided;
  selected: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
};

const DragHandle: React.FC<DragHandleProps> = (props: DragHandleProps) => {
  const { provided, selected, icon, onClick } = props;

  const defaultIcon =
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
      />
    </svg>;

  return (
    <Handle
      selected={selected}
      {...provided.dragHandleProps}
      onClick={onClick}
    >
      {icon ? icon : defaultIcon}
    </Handle>);
};

export default DragHandle;
