import React, { useEffect }  from 'react';
import { HistoryFrequency, StockHistoryPrice, useGetStockHistoryQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type StockHistoryProps = {
  ticker: string;
  startDate: Date;
  endDate?: Date;
  includeAfterHours?: boolean;
  frequency?: HistoryFrequency;
  onUpdate?: (data?: { ticker: string, result: StockHistoryPrice[] }, loading?: boolean, error?: any) => any;
};

const GetStockHistory: React.FC<StockHistoryProps> = (props: StockHistoryProps) => {
  const { onUpdate, ticker, startDate, endDate, includeAfterHours, frequency } = props;
  const { data: stock, loading: stockLoading, error: stockError } = useGetStockHistoryQuery({
    variables: { ticker, startDate, endDate, includeAfterHours, frequency },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(stock ? { ticker, result: stock.getStockHistory } : undefined, stockLoading, stockError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stock, stockLoading, stockError]);

  return null;
};

export default GetStockHistory;
