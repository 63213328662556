export default {
  apps: 'Apps',
  account: 'Account',
  configureTheme: 'Configure Theme',
  entries: 'Entries',
  events: 'Events',
  fuel: 'Fuel',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  schedule: 'Schedule',
  settings: 'Settings',
  starbucks: 'Starbucks',
  stocks: 'Stocks',
  travel: 'Travel',
  manageDocument: 'Manage Documents',
  pages: 'Pages',
  collections: 'Collections',
  qrScanner: 'QR Scanner',
  manage: 'Manage Pages and Collections',
  'errors.errorSmiley': 'Error :(',
};
