import React, { useContext, useEffect, useRef, useState } from "react";
import { Auth } from 'aws-amplify';
import { Store } from "antd/lib/form/interface";
import SignUp from "../../components/login/SignUp";
import { AuthStatus } from "../../components/login/AuthStatusEnum";
import { errorNotification, infoNotification } from "../../components/actions/Notification";
import ConfirmEmail from "../../components/login/ConfirmEmail";
import {  AlphaUserType, RegisterAlphaUserMutation, UserInput } from "../../graphql/generated/graphql";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import CreateUser from "../../components/login/CreateUser";
import RegisterAlphaUser from "../api/Public/RegisterAlphaUser";

type SignUpContainerProps = {
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

const SignUpContainer: React.FC<SignUpContainerProps> = (props: SignUpContainerProps) => {
  const authContext = useContext(AuthContext);
  const [authState, setAuthState] = useState<AuthStatus>(AuthStatus.None);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<UserInput | null>(null);
  const [confirmEmail, setConfirmEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [addToAlpha, setAddToAlpha] = useState<UserInput | null>(null);
  const location = useLocation();
  const history = useHistory();
  const authStateRef = useRef(AuthStatus.None);
  authStateRef.current = authState;

  useEffect(() => {
    try {
      if (authContext.authState === AuthStatus.Authenticated) {
        infoNotification('User is already logged in, redirecting.');
        history.push('/Account');
        return;
      }
    }
    catch (e) {
    }
  });

  useEffect(() => {
    if (location.search) {
      // get username from url params
      const queryParams = location.search.split('=');
      if (queryParams.length > 1) {
        const email = queryParams[1];
        if (email !== confirmEmail) {
          setConfirmEmail(email);
          setAuthState(AuthStatus.ConfirmRegister);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [location]);

  useEffect(() => {
    if (authState === AuthStatus.EmailConfirmed) {
    }
  },        [authState]);

  useEffect(() => {
    if (error) {
      errorNotification(error);
      setError(null);
    }
  },        [error]);

  const authenticate = (values: Store) => {
    const { fname, lname, password, email } = values;
    if (authStateRef.current === AuthStatus.Register) {
      setLoading(true);
      Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email,
          name: `${fname} ${lname}`,
          'custom:firstName': fname,
          'custom:lastName': lname,
        },
      })
      .then(() => {
        setUser({ emailAddress: email, firstName: fname, lastName: lname });
        setAuthState(AuthStatus.ConfirmRegister);
        setLoading(false);
      })
      .catch((err: any) => {
        setError(err.message);
        setLoading(false);
        // this.setState({ authenticationStatus: AuthStatus.Fail });
      });
    }
    else {
      setAddToAlpha({ firstName: fname, lastName: lname, emailAddress: email });
    }
  };

  const handleConfirmEmail = async (email: string, confirmationCode: string) => {
    setLoading(true);
    await Auth.confirmSignUp(email.toLowerCase(), confirmationCode)
      .then(async (user: any) => {
        setAuthState(AuthStatus.EmailConfirmed);
      })
      .catch((err: any) => {
        if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
          setAuthState(AuthStatus.EmailConfirmed);
        }
        else {
          setError(err.message);
        }
        setLoading(false);
      });
  };

  /* To Remove
  const handleUserCreated = async (user?: User, loading?: boolean, error?: any) => {
    if (error) {
      errorNotification('Email successfully confirmed, but we encountered an error while creating the user. Please try again.');
      setAuthState(AuthStatus.EmailConfirmed);
      setLoading(false);
    }
    if (user) {
      await authContext.register(user);
    }
  };
  */

  const getContainer = () => {
    let container = <></>;
    switch (authState) {
      case AuthStatus.EmailConfirmed:
      case AuthStatus.CreateUser: {
        const email = user ? user.emailAddress : (authContext.userInput ? authContext.userInput.emailAddress : null);
        container = email ? <CreateUser email={email}/> : <Redirect to={{ pathname: '/login' }} />;
        break;
      }
      case AuthStatus.None:
      case AuthStatus.Register:
      case AuthStatus.AlphaProgram: {
        container = <SignUp key='sign-up-create-user' userInput={user ? user : authContext.userInput} authenticate={authenticate} authenticationStatus={authState} loading={loading}/>;
        break;
      }
      case AuthStatus.ConfirmRegister: {
        container = <ConfirmEmail
          key='confirm-email'
          handleConfirmEmail={handleConfirmEmail}
          confirmEmailStatus={authState} error={error ?? ''}
          email={user ? user.emailAddress : (confirmEmail ? confirmEmail : '')}
          loading={loading} />;
        break;
      }
      default: {
        container = <></>;
        break;
      }
    }

    return (
    <>
      {addToAlpha ? <RegisterAlphaUser user={addToAlpha} onUpdate={(data?: RegisterAlphaUserMutation | null, loading?: boolean, error?: any) => {
        if (loading) {

        }
        if (data && data.registerAlphaUser) {
          if (data.registerAlphaUser.status === AlphaUserType.Approved) {
            setAuthState(AuthStatus.Register);
          }
          if (data.registerAlphaUser.status === AlphaUserType.Pending) {
            setAuthState(AuthStatus.AlphaProgram);
          }
          setAddToAlpha(null);
        }
        if (error) {
          setAddToAlpha(null);
        }
      }}/> : null}
      {container}
    </>);
  };

  return getContainer();
};

export default SignUpContainer;
