import * as React from 'react';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { Upload, Button } from 'antd';
import AuthContext from '../../context/AuthContext';

type LoadImageProps = {
  handleCancelPreview: () => void;
  handlePreview: (file: any) => {};
  onUpload?: (file: any) => void;
  url?: string;
  type?: string;
};

type LoadImageState = {
  fileList: UploadFile[];
  imgData: any;
};

class LoadImageContainer extends React.Component<LoadImageProps, LoadImageState> {
  static contextType = AuthContext;

  constructor(props: LoadImageProps) {
    super(props);
    this.state = {
      fileList: [] as UploadFile[],
      imgData: null,
    };
  }

  handleChange = async ({ fileList }: any) => {
    const image = await this.loadImage(fileList[fileList.length - 1]);
    // image.set(
    //   'image',
    //   `test-file`,
    // );
    /*
    await axios({
      method: 'post',
      url: process.env.REACT_APP_UPLOAD_API_URL,
      data: image,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    */

    this.setState({ fileList });
    this.props.handlePreview(image);
  }

  loadImage = async (file: any) => {
    let src = file.name;
    if (src) {
      src = await new Promise((resolve: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
      const image = await new Promise((resolve: any) => {
        const loadImage = new Image();
        loadImage.src = src;
        loadImage.name = file.name;
        loadImage.onload = () => resolve(loadImage);
      });
      file.image = image;
      return file;
    }
    return null;
  }

  getImage = async (dataReader: any) => {
    const loadImage = new Image();
    loadImage.src = dataReader;
    return loadImage;
  }

  render() {
    const { fileList } = this.state;
    const uploadUrl = this.props.url ?? "https://www.mocky.io/v2/5cc8019d300000980a055e76";
    return (<Upload
      key='load-image'
      accept={this.props.type}
      action={uploadUrl}
      fileList={fileList}
      listType="picture-card"
      onPreview={this.props.handlePreview}
      beforeUpload={(file: any) => {
        console.log('upload file beforeUpload');
        console.debug(file);
        // Prevent upload
        // if (this.props.url) {
        return true;
      }}
      customRequest={async (options: any) => {
        // const data = new FormData();
        const selectedFile = options.file;
        try {
          if (selectedFile !== '') {
            // Creating a FormData object
            const fileData = new FormData();
            // Setting the 'image' field and the selected file
            fileData.set(
              'image',
              selectedFile,
              `${selectedFile.lastModified}-${selectedFile.name}`,
            );
            const authToken = await this.context.getToken();
            const imageResult = await axios({
              method: 'post',
              url: this.props.url,
              data: fileData,
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: authToken,
              },
            });
            console.log(imageResult);
            if (this.props.onUpload) {
              this.props.onUpload(imageResult.data);
            }
            // const image = await this.getImage(imageResult.data);
            // this.setState({ imgData: image });
          }
        }
        catch (error) {
          console.log(error);
        }

/*        data.set('file', options.file);
        const config = {
          headers: {
            "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
          },
        };
        axios({
          method: 'post',
          url: this.props.url, // process.env.REACT_APP_UPLOAD_API_URL,
          data,
          headers: { 'Content-Type': 'multipart/form-data' },
        }); */
        /*
        axios.post(options.action, data, config).then((res: any) => {
          options.onSuccess(res.data, options.file);
        }).catch((err: Error) => {
          console.log(err);
        });
        */
      }}
      onChange={this.handleChange}
    >{this.state.imgData ? <img src={this.state.imgData} width='60px' height='40px' key='imgData' alt=''/> : null}<Button><UploadOutlined /> Click to Scan </Button></Upload>);
  }
}

export default LoadImageContainer;
