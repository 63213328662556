import React, { Component } from 'react';
import Page from '../components/layout/Page';
import config from '../Config';

class Status extends Component {
  render() {
    return (
    <Page content={
      <div>{config.ENV}</div>
    }
    />);
  }
}

export default Status;
