export const DeviceWidthObject: { [id: string] : { max: number, min: number }} = {
  MobileSmall: { max: 320, min: 0 },
  MobileMedium: { max: 375, min: 321 },
  MobileLarge: { max: 767, min: 376 },
  Tablet: { max: 991, min: 768 },
  LaptopSmall: { max: 1024, min: 992 },
  LaptopLarge: { max: 1440, min: 1025 },
  LargerThanLaptop: { max: 2560, min: 1441 },
  LargeScreenMax: { max: 999999, min: 2561 },
};

export const IdDeviceBreakpointsByWidth = {
  laptop_max: 1440,
  laptop_min: 992,
  tablet_min: 768,
  tablet_max: 991,
  mobile_max: 767,
  default_min: 768, // Unrecognized device
};
export const IdMobileHeight = {
  mobileLandscape_min: 320,
  mobileLandscape_max: 425,
};

export const getWindowDimension = () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return { width, height };
};

export enum DeviceOrientation {
  Landscape = 1,
  Portrait,
}

export enum DeviceType {
  Unknown = 1,
  LargerThanLaptop,
  Laptop,
  Tablet,
  Mobile,
}

export enum DeviceTypeVariant {
  MobileSmall = 'MobileSmall',
  MobileMedium = 'MobileMedium',
  MobileLarge = 'MobileLarge',
  Tablet = 'Tablet',
  LaptopSmall = 'LaptopSmall',
  LaptopLarge = 'LaptopLarge',
  LargerThanLaptop = 'LargerThanLaptop',
  LargeScreenMax = 'LargeScreenMax',
  Unknown = 'Unknown',
}

export type DeviceTypeInfo = {
  deviceType: DeviceType,
  deviceTypeVariant: DeviceTypeVariant,
  orientation: DeviceOrientation,
  width: number,
  height: number,
};

export const getDeviceTypeInfo = () => {
  const { width, height } = getWindowDimension();
  const buildDeviceDetails: DeviceTypeInfo = {
    deviceType: DeviceType.Unknown,
    deviceTypeVariant: DeviceTypeVariant.Unknown,
    orientation: DeviceOrientation.Portrait,
    width,
    height,
  };
  if (height < width) {
    // Orientation is landscape
    buildDeviceDetails.orientation = DeviceOrientation.Landscape;
    if (height <= IdMobileHeight.mobileLandscape_max) {
      // Mobile (landscape)
      buildDeviceDetails.deviceType = DeviceType.Mobile;

      for (const devc in DeviceWidthObject) {
        if (
          height <= DeviceWidthObject[devc].max &&
          height >= DeviceWidthObject[devc].min
        ) {
          buildDeviceDetails.deviceTypeVariant = devc as DeviceTypeVariant;
          break;
        }
      }
    }
    else if (
      width <= IdDeviceBreakpointsByWidth.tablet_max &&
      width >= IdDeviceBreakpointsByWidth.tablet_min
    ) {
      // Tablet (landscape)
      buildDeviceDetails.deviceType = DeviceType.Tablet;
      buildDeviceDetails.deviceTypeVariant = DeviceTypeVariant.Tablet;
    }
    else if (
      width <= IdDeviceBreakpointsByWidth.laptop_max &&
      width >= IdDeviceBreakpointsByWidth.laptop_min
    ) {
      // Laptop (landscape)
      buildDeviceDetails.deviceType = DeviceType.Laptop;
      for (const devc in DeviceWidthObject) {
        if (
          width <= DeviceWidthObject[devc].max &&
          width >= DeviceWidthObject[devc].min
        ) {
          buildDeviceDetails.deviceTypeVariant = devc as DeviceTypeVariant;
          break;
        }
      }
    }
    else {
      // Larger than Laptop (landscape)
      buildDeviceDetails.deviceType = DeviceType.LargerThanLaptop;
      for (const devc in DeviceWidthObject) {
        if (
          width <= DeviceWidthObject[devc].max &&
          width >= DeviceWidthObject[devc].min
        ) {
          buildDeviceDetails.deviceTypeVariant = devc as DeviceTypeVariant;
          break;
        }
      }
    }
  }
  else {
    // Orientation is portrait
    buildDeviceDetails.orientation = DeviceOrientation.Portrait;
    for (const devc in DeviceWidthObject) {
      if (
        width <= DeviceWidthObject[devc].max &&
        width >= DeviceWidthObject[devc].min
      ) {
        buildDeviceDetails.deviceTypeVariant = devc as DeviceTypeVariant;
        break;
      }
    }
    if (
      width <= IdDeviceBreakpointsByWidth.laptop_max &&
      width >= IdDeviceBreakpointsByWidth.laptop_min
    ) {
      buildDeviceDetails.deviceType = DeviceType.Laptop;
    }
    if (
      width <= IdDeviceBreakpointsByWidth.tablet_max &&
      width >= IdDeviceBreakpointsByWidth.tablet_min
    ) {
      buildDeviceDetails.deviceType = DeviceType.Tablet;
    }

    if (width <= IdDeviceBreakpointsByWidth.mobile_max) {
      buildDeviceDetails.deviceType = DeviceType.Mobile;
    }
    if (width > IdDeviceBreakpointsByWidth.laptop_max) {
      buildDeviceDetails.deviceType = DeviceType.LargerThanLaptop;
    }
  }
  return buildDeviceDetails;
};

/*
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
*/
