import React from 'react';
import Button, { ButtonProps } from '../atomic/Button';

export type IconProps = ButtonProps & {
  onClick?: () => void;
  enclosed?: boolean;
  selected?: boolean;
  disabled?: boolean;
  hide?: boolean;
  color?: string;
};

type EnclosedIconProps = IconProps & {
  icon: JSX.Element;
};

const EnclosedIcon: React.FC<EnclosedIconProps> = (props: EnclosedIconProps) => {
  const { icon, onClick, enclosed, color, selected, disabled, hide } = props;
  const unselectedColor = color ? color : undefined;
  const selectedColor = 'darkblue';
  const iconColor = selected ? selectedColor : unselectedColor;

  return (enclosed
    ? <Button style={{ borderColor: iconColor, opacity: hide ? '0%' : (disabled ? '30%' : undefined) }} onClick={disabled ? undefined : onClick} >{icon}</Button>
    : React.cloneElement(icon, { onClick, style: { borderColor: iconColor, opacity: hide ? '0%' : (disabled ? '30%' : undefined) } })
  );
};

export default EnclosedIcon;
