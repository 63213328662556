import moment from 'moment';
import React, { useState, useContext } from 'react';
import { errorNotification, infoNotification } from '../../components/actions/Notification';
import GetAccessToken from '../../containers/api/Plaid/GetAccessToken';
import GetLinkToken from '../../containers/api/Plaid/GetLinkToken';
import AuthContext from '../../context/AuthContext';
import { PlaidLinkedInstitution, PlaidLinkToken } from '../../graphql/generated/graphql';
import Link, { BrokerMetadata } from './Link';

type LinkedItem = {
  institutionId: string;
  institutionName: string;
  userId: string;
};

/*
const LINKED_ITEMS: LinkedItem[] = s2pConfig.PLAID_ENV === 'sandbox' ? [] : [
  {
    institutionId: 'ins_3',
    institutionName: 'Chase',
    userId: '32ac5b01-7352-4caa-8e38-9407a20110f6',
  },
  {
    institutionId: '',
    institutionName: '',
    userId: '32ac5b01-7352-4caa-8e38-9407a20110f6',
  },
];
*/

type PlaidProps = {
  // broker?: PlaidAccessToken;
  linkedInstitutions: PlaidLinkedInstitution[];
  onUpdateLinkedInstitution: (linkedInstitution: PlaidLinkedInstitution) => void;
};

/**
 * 1. Get link token
 * 2. Trigger Link component to retrieve Public token from Plaid
 * 3. exchange public token for Access token.
 * @param props
 * @returns
 */
const Plaid: React.FC<PlaidProps> = (props: PlaidProps) => {
  const authContext = useContext(AuthContext);
  const [linkToken, setLinkToken] = useState<PlaidLinkToken | undefined>(undefined);
  const [publicToken, setPublicToken] = useState<{ token: string; broker: BrokerMetadata } | undefined>(undefined);
  const [linkedInstitution, setLinkedInstitution] = useState<PlaidLinkedInstitution | undefined>(undefined);
  const [fetchTime, setFetchTime] = useState<Date>(new Date());

  /*
  useEffect(() => {
    if (accessToken !== props.broker) {
      setAccessToken(props.broker);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);
  */

  const validToken = linkToken && moment(linkToken.expiration) > moment(fetchTime);

  if (linkToken && moment(linkToken.expiration) < moment(fetchTime)) {
    setFetchTime(new Date());
  }

  const getLinkToken = linkedInstitution || validToken ? null : <GetLinkToken key={fetchTime.toUTCString()} onUpdate={(data?: PlaidLinkToken, loading?: any, error?: any) => {
    if (data) {
      setLinkToken(data);
    }
  }} />;

  const getAccessToken = !linkedInstitution && publicToken
  ? (!props.linkedInstitutions.find((linked: PlaidLinkedInstitution) => linked.institutionId === publicToken.broker.institution.institution_id && authContext.user)
    ? <GetAccessToken institutionId={publicToken.broker.institution.institution_id} publicToken={publicToken.token} onUpdate={(data?: PlaidLinkedInstitution, loading?: any, error?: any) => {
      if (error) {
        if (error.message === 'GraphQL error: You have already linked an item at this institution.') {
          infoNotification('already linked');
        }
        errorNotification('Something went wrong, please try again.');
      }
      if (data) {
        // setAccessToken(data);
        setLinkedInstitution(data);
        /*
        linkedInstitutions.push({
          userId: authContext.user?.uuid ?? '',
          institutionId: publicToken.broker.institution.institution_id,
          institutionName: publicToken.broker.institution.name });
        */
        props.onUpdateLinkedInstitution(data);
      }
    }} />
    : infoNotification(`You already linked your ${publicToken.broker.institution.name}`))
  : null;

  const linkTokenExpirationDate = linkToken ? moment(new Date(linkToken.expiration)) : null;
  const validLinkToken = linkTokenExpirationDate !== null && linkTokenExpirationDate > moment(new Date());
  return (
    <>
      {getLinkToken}
      {getAccessToken}
      <Link loading={!validLinkToken} token={linkToken ? linkToken.linkToken : undefined} onUpdatePublicToken={(data?: string, broker?: BrokerMetadata) => {
        setPublicToken(data && broker ? { token: data, broker } : undefined);
      }}/>
    </>
  );
};

export default Plaid;
