import React from 'react';
import {  Table as AntTable } from 'antd';
import { TableProps as AntTableProps } from 'antd/lib/table';
import styled from 'styled-components';
import { EditableColumnProps } from './EditableTable';

const TableStyled = styled(AntTable)`
  background: #eeeeee;
`;

export type TableProps<RecordType> = AntTableProps<RecordType> & {
};

type TableState = {
};

class Table extends React.Component<TableProps<any>, TableState> {
  constructor(props: TableProps<any>) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { columns, ...props } = this.props;
    const filteredColumns = columns ? (columns as EditableColumnProps<any>[]).filter((column: any) => column.hidden !== true) : [];
    return (
      <TableStyled className='styled-table' columns={filteredColumns} {...props}/>
    );
  }
}

export default Table;
