import React from 'react';
import { Space } from 'antd';
import FormField, { FormFields } from './FormField';

export type Option = { label: string, value: string };

export type FormDataPages = {
  pages: FormFields[],
};

type FormDataProps = {
  data: FormDataPages;
  pageNumber?: number;
  // trigger?: JSX.Element;
  onUpdate?: (data: FormDataPages) => void;
};

const FormData: React.FC<FormDataProps> = (props: FormDataProps) => {
  const { data, onUpdate } = props;
  const pageNumber = props.pageNumber ? props.pageNumber : 0;

  const onFieldChange = (newValue: any, key: string) => {
    const prevData = { ...data };
    prevData.pages[pageNumber][key].value = newValue;
    if (onUpdate) {
      onUpdate(prevData);
    }
  };

  const loadFields = (loadData: FormDataPages) => {
    const modalFields = loadData;

    const allDataKeys: string[] = [];
    const allInputFields: any[] = [];

    modalFields.pages.forEach((formFields: FormFields, pageIndex: number) => {
      const dataKeys = Object.keys(formFields);
      allDataKeys.push(...dataKeys);

      const inputFields: any[] = [];
      dataKeys.forEach((fieldKey: string) => {
        const fieldObject: JSX.Element =
          <FormField
            fieldData={formFields[fieldKey]}
            fieldKey={fieldKey}
            onFieldChange={onFieldChange} />;
        inputFields.push(<div style={{ width: '100%' }} key={`form-data--${fieldKey}`}>{fieldObject}</div>);
      });
      allInputFields.push(inputFields);
    });
    console.log(allInputFields);
    return allInputFields;
  };

  const inputFields = loadFields(data);

  return (
    <>
      {/* props.trigger */}
      <Space direction="vertical">
        {inputFields[pageNumber]}
      </Space>
    </>
  );
};

export default FormData;
