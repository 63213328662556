import React, { useState } from 'react';
// import styled from 'styled-components';
import {  Button, Layout, message, Space, Avatar, Table } from 'antd';

import { RouteComponentProps } from 'react-router-dom';
import Header from '../components/navigation/Header';
import styled from 'styled-components';
import RecordModal, { RecordModalAction, ModalFields } from '../components/modal/RecordModal';
import moment from 'moment';
import { dateFormat, RecordType } from '../components/table/DataTypes';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

// height: calc(100vh - 64px - 15px); /* 100vh - 62 (header) - 15 (body top margin) */
// background-color: #f7f7f7;

const OptionsDiv = styled.div`
  position: flex;
  float: right;
  display: 'flex';
  justifyContent: 'right';
  top: calc(64px + 15px); /* 62 (header) + 15 (body top margin) */
  padding-right: 50px; /* scrollbar */
  padding-top: calc(64px + 15px); /* scrollbar */
  padding-bottom: 15px;
`;

// height: calc(100vh - 64px - 15px); /* 100vh - 62 (header) - 15 (body top margin) */
// background-color: #f7f7f7;
const ListDiv = styled.div`
  position: flex;
  top: calc(64px + 15px + 40px); /* 62 (header) + 15 (body top margin) */
  left: 50px;
  right: 50px;
  bottom: 0px;
  padding-left: 50px; /* scrollbar */
  padding-right: 50px; /* scrollbar */
  padding-bottom: 15px;
  overflow: auto;
`;

// const nullRecord = { id: undefined, date: undefined, price: undefined, volume: undefined, distance: undefined, who: undefined };

type FeulRecord = {
  key: string;
  id: number | undefined;
  date: Date | undefined;
  price: number | undefined;
  volume: number | undefined;
  distance: number | undefined;
  who: string | undefined;
};

type FuelProps = RouteComponentProps & {
};

const Fuel: React.FC<FuelProps> = (props: FuelProps) => {
  const [action, setAction] = useState(RecordModalAction.None);

  const [data, setData] = useState<FeulRecord[]>([
    { key: 'key-4', id: 4, date: new Date('2018-11-27'), price: 15.00, volume: undefined, distance: undefined, who: 'TJ' },
    { key: 'key-5', id: 5, date: new Date('2018-11-28'), price: 61.65, volume: undefined, distance: undefined, who: 'TJ' },
    { key: 'key-6', id: 6, date: new Date('2018-12-10'), price: 48.49, volume: undefined, distance: undefined, who: 'TJ' },
    { key: 'key-7', id: 7, date: new Date('2018-12-13'), price: 47.40, volume: undefined, distance: 507, who: 'TJ' },
    { key: 'key-8', id: 8, date: new Date('2018-12-17'), price: 56.02, volume: undefined, distance: 789, who: 'TJ' },
    { key: 'key-9', id: 9, date: new Date('2018-12-20'), price: 56.37, volume: 56.52, distance: 1059, who: 'TJ' },
    { key: 'key-10', id: 10, date: new Date('2018-12-24'), price: 15.00, volume: 6.82, distance: 1357, who: 'TJ' },
    { key: 'key-11', id: 11, date: new Date('2018-12-25'), price: 40.00, volume: 18.18, distance: 1383, who: 'TJ' },
    { key: 'key-12', id: 12, date: new Date('2019-01-04'), price: 48.21, volume: 21.91, distance: 1575, who: 'TJ' },
    { key: 'key-13', id: 13, date: new Date('2019-01-08'), price: 46.56, volume: 24.12, distance: 1816, who: 'Alex' },
  ]);
  const [dataNextId, setDataNextId] = useState(14);

  const sCodes = data;

  const onAddRecord = () => {
    setAction(RecordModalAction.Create);
  };

  const onCancelRecord = () => {
    setAction(RecordModalAction.None);
  };

  const onCreate = (record: any) => {
    const newData = data.concat({ key: record.data.value, id: dataNextId, date: record.date.value, price: record.price.value, volume: record.volume.value, distance: record.distance.value, who: record.who.value ?? 'Unassigned' });
    setData(newData);
    setAction(RecordModalAction.None);
    setDataNextId(dataNextId + 1);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 50,
      render: (text: string, record: any) => (
        <Space size="middle">
          {record.who === 'Alex' && (
            <Avatar src="https://www.gravatar.com/avatar/3b08184ec42c778473258a2b4dc3a7b6?s=120" />)
          }
          {record.who === 'TJ' && (
            <Avatar src="https://www.gravatar.com/avatar/baab27349aad89deb55e64b4b8f56d4d?s=120" />)
          }
        </Space>
      ),
    },
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      width: 50,
      render: (text: string, record: any) => (
        <div>{record.id}</div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text: string, record: any) => (
        <div>{moment(record.date).format(dateFormat)}</div>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      key: 'volume',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Efficieny',
      dataIndex: 'efficieny',
      key: 'efficieny',
      render: (text: string, record: any) => (
        <div>TBD</div>
      ),
    },
  ];

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          Date: { placeholder: 'Enter date', type: RecordType.Date, value: moment('2020-06-23', dateFormat) },
          Price: { placeholder: 'Enter price', type: RecordType.Input },
          Volume: { placeholder: 'Enter volume', type: RecordType.Input },
          Distance: { placeholder: 'Enter mileage', type: RecordType.Input },
          Image: { placeholder: 'Load image', type: RecordType.Image },
          Who: { placeholder: 'Select payee', type: RecordType.Dropdown, values: ['TJ', 'Alex'] },
        },
      },
    ],
  };

  return (
    <Layout>
      {action !== RecordModalAction.None && (
        <RecordModal
          data={recordDataValues}
          action={action}
          onCancel={onCancelRecord}
          onSubmit={action === RecordModalAction.Create ? onCreate : (action === RecordModalAction.Update ? onAddRecord : (action === RecordModalAction.Delete ? onAddRecord : (data: any) => {}))}
        />
      )}
      <Header />
      <OptionsDiv>
        <Space direction='horizontal' style={{ float: 'right' }}>
          <Button style={{ float: 'right' }} onClick={() => setAction(RecordModalAction.Create)}>Add new code</Button>
        </Space>
      </OptionsDiv>
      <ListDiv>
        <Table className='.antd-fuel-table' size='small' scroll={{ x: 400 }} columns={columns} dataSource={sCodes} bordered={true} pagination={{ pageSizeOptions: ['5', '8', '20'], showSizeChanger: true, defaultPageSize: 5  }}/>
      </ListDiv>
    </Layout>
  );
};

export default Fuel;
