import * as React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { Upload, Button } from 'antd';
import AuthContext from '../../context/AuthContext';
import { StyledDiv } from '../styled/CenteredDiv';

type LoadFileProps = {
  onLoad: (file: { name: string, data: undefined | string }) => void;
  // url?: string;
  type?: string;
  icon?: JSX.Element;
};

type LoadFileState = {
  fileList: UploadFile[];
  file?: { name: string, data: undefined | string };
};

class LoadFile extends React.Component<LoadFileProps, LoadFileState> {
  static contextType = AuthContext;

  constructor(props: LoadFileProps) {
    super(props);
    this.state = {
      fileList: [] as UploadFile[],
    };
  }

  handleChange = async ({ fileList }: any) => {
    const file = await this.loadData(fileList[fileList.length - 1]);
    // image.set(
    //   'image',
    //   `test-file`,
    // );
    /*
    await axios({
      method: 'post',
      url: process.env.REACT_APP_UPLOAD_API_URL,
      data: image,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    */

    this.setState({ fileList, file });
    this.props.onLoad(file);
  }

  loadData = async (file: any) => {
    const src = { name: file.name, data: undefined };
    if (src.name) {
      src.data = await new Promise((resolve: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    return src;
  }

  render() {
    const { fileList } = this.state;
    const uploadUrl = ''; // this.props.url ?? "https://www.mocky.io/v2/5cc8019d300000980a055e76";
    return (<><Upload
      key='load-image'
      accept={this.props.type}
      action={uploadUrl}
      fileList={fileList}
      // onPreview={this.props.handlePreview}
      beforeUpload={(file: any) => {
        console.log('upload file beforeUpload');
        // Prevent upload
        // if (this.props.url) {
        return true;
      }}
      customRequest={async (options: any) => {
        // const data = new FormData();
        const selectedFile = options.file;
        try {
          if (selectedFile !== '') {
            // Creating a FormData object
          }
        }
        catch (error) {
          console.log(error);
        }
      }}
      onChange={this.handleChange}
    >{this.props.icon ? this.props.icon : <Button><UploadOutlined /> Click to Load file</Button>}</Upload>
    {this.state.file ? <div><StyledDiv bold>Loaded file {this.state.file.name}</StyledDiv><div>{this.state.file.data ? (this.state.file.data.includes('base64,') ? atob(this.state.file.data.split('base64,')[1]) : this.state.file.data) : ''}</div></div> : null}</>);
  }
}

export default LoadFile;
