import React, { useState } from 'react';
import {  message } from 'antd';
import { GetDataCollectionsById } from '../../containers/api/Collection/DataCollection';
import { ResultViewEnum } from '../../containers/api/Helpers';
import ViewCollapsable from '../views/Collapsable';
import { dateFormat } from '../table/DataTypes';
import moment from 'moment';
import { DataEntryType } from '../../graphql/generated/graphql';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

type CollectionProps = {
  uuids: string[],
};

const CollectionData: React.FC<CollectionProps> = (props: CollectionProps) => {
  const [collectionData, setCollectionData] = useState<any>(null);
  const dataCollection = <GetDataCollectionsById collectionUuids={props.uuids} returnJsx={false} view={ResultViewEnum.Card} updateApiResponse={(data: any) => setCollectionData(data)}/>;

  console.log('data collection');
  console.log(collectionData);

  const collections: { [uuid: string] : any } = {};
  const objects: { [uuid: string] : any } = {};
  const entries: any[] = [];
  if (collectionData) {
    collectionData.collections.forEach((collection: any) => {
      collections[collection.uuid] = {
        name: collection.name,
        owner: {
          uuid: collection.uuid,
          firstName: '',
          lastName: '',
        },
        objectUuids: collection.objectUuids,
        description: collection.description,
      };
    });

    collectionData.objects.forEach((obj: any) => {
      const entries: { [uuid: string] : any } = {};
      obj.entries.forEach((e: any) => {
        entries[e.uuid] = e;
      });
      objects[obj.uuid] = {
        name: obj.name,
        owner: {
          uuid: obj.uuid,
          firstName: '',
          lastName: '',
        },
        entries,
        description: obj.description,
      };
    });

    collectionData.entries.forEach((e: any) => {
      const objEntries = objects[e.objectUuid];
      const fields: {[field: string] : { uuid: string, data: string, type: DataEntryType }} = {};
      e.data.forEach((field: any) => {
        fields[objEntries.entries[field.entryUuid].name] = { uuid: field.uuid, data: field.data, type: objEntries.entries[field.entryUuid].type };
      });
      const entry: {
        created: string,
        ownerUuid: string,
        objectUuid: string,
        fields: { [field: string] : { uuid: string, data: string, type: DataEntryType }},
      } = {
        created: e.dateCreated,
        ownerUuid: objEntries.owner.uuid,
        objectUuid: e.objectUuid,
        fields,
      };

      entries.push(entry);
    });
  }

  const data = entries.map((d: any) => {
    const extra: any[] = [];
    // const ownerName = <UserData uuid={d.ownerUuid} />;
    // extra.push(ownerName);
    Object.keys(d.fields).forEach((f: any) => {
      if (f === 'Code') {
        return;
      }
      if (!d.fields[f]) {
        return;
      }
      extra.push(<div key={`data-content-${f}`}><span style={{ fontWeight: 'bold', width: 100, float: 'left' }}>{f}:</span> {d.fields[f].type  === DataEntryType.Date ? (d.fields[f].data ? moment(d.fields[f].data).format(dateFormat) : '') : d.fields[f].data}</div>);
    });

    return ({
      header: [<div>{d.fields['Code'].data}</div>],
      extra,
    });
  });

  return (
    <>
      {dataCollection}
      {collectionData ? <ViewCollapsable style={{ top: 15, maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }} sourceName={'collection'} data={data} mapping={null} /> : null}
    </>
  );
};

export default CollectionData;
