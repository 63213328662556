import React from 'react';
import { ApiProps, ResultViewEnum, filterResult } from './Helpers';
import { useWindowSize } from '../../components/layout/Page';
import { Collapse, Space } from 'antd';
import { DataEntryDataObjType, DataEntryObjType } from './Entry/DataEntry';

export type ApiViewProps = ApiProps & {
  data: any;
};

const JsxViewResponse: React.FC<ApiViewProps> = (props: ApiViewProps) => {
  const filteredData = props.data
    ? (props.fieldFilter ? filterResult(props.data, props.fieldFilter) : props.data)
    : 'No data loaded';
  const windowSize = useWindowSize();
  console.log('JsxViewResponse result');
  console.log(props);
  console.log(props.view);
  console.log(`isEqual? ${props.view === ResultViewEnum.Json}`);
  console.log(props.data);
  console.log(props.fieldFilter);
  console.log(filteredData);
  let response: JSX.Element | null = null;
  if (props.view === ResultViewEnum.Json) {
    response = (
      <React.Fragment>
        <pre style={{ overflow: 'auto', height: 'auto', maxHeight: `${(windowSize.height ?? 550) - 250}px` }} >{JSON.stringify(filteredData, null, 2)}</pre>
      </React.Fragment>
    );
  }
  if (props.view === ResultViewEnum.Card) {
  }
  if (props.view === ResultViewEnum.List) {
    if (!filteredData || !filteredData['0c7e3db7-0b43-4e92-9d66-d2c71015b787']) {
      return <div>No loaded data</div>;
    }
    response = (<>
      <Collapse
            expandIconPosition={'left'}
          >
            {filteredData['0c7e3db7-0b43-4e92-9d66-d2c71015b787'].data.map((entry: DataEntryObjType, index: number) => {
              return (<Collapse.Panel key={`data-${index}`} header={<Space direction='vertical'>{entry.show.map((s: DataEntryDataObjType, divIndex: number) => <div key={`div-${divIndex}`}>{s.fieldName}: {s.data}</div>)}</Space>}>
                {<Space direction='vertical'>{entry.expandable.map((s: DataEntryDataObjType, divIndex: number) => <div key={`div-${divIndex}`}>{s.fieldName}: {s.data}</div>)}</Space>}
            </Collapse.Panel>);
            })}
          </Collapse>
      </>);
  }
  console.log(response);
  return response;
};

export default JsxViewResponse;
