import React, { useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import { StockResult } from '../../graphql/generated/graphql';
import AutoComplete from '../atomic/AutoComplete';
import SearchIcon from '../icons/SearchIcon';
import { CheckCircleOutlined } from '@ant-design/icons';
import GetStockSearch from '../../containers/api/Stock/GetStockSearch';
import { errorNotification } from '../actions/Notification';
import { QRY_ERROR } from './Stocks';
import { StyledSpan } from '../styled/CenteredDiv';
import { Stock } from './StockTypes';

type FindStockProps = {
  disabled?: boolean;
  trackedStocks: Dictionary<Stock>;
  inheritWidth?: boolean;
  resetOnSelect?: boolean;
  initialValue?: string;
  onSelectTicker: (ticker: string) => void;
};
const FindStock: React.FC<FindStockProps> = (props: FindStockProps) => {
  const { disabled, trackedStocks, inheritWidth, resetOnSelect, initialValue, onSelectTicker } = props;
  // const [searchQuery, setSearchQuery] = useState<string>('');
  // const [searchResults, setSearchResults] = useState<>({});
  const [getStockSearch, setGetStockSearch] = useState<{ loading: boolean, query: string, results: Dictionary<StockResult[]>, execute: JSX.Element | undefined }>({ loading: false, query: '', results: {}, execute: undefined });
  const [options, setOptions] = useState<{ value: string, label: JSX.Element  }[]>([]);

  useEffect(() => {
    if (initialValue && initialValue !== getStockSearch.query) {
      setGetStockSearch((prev) => {
        const updatePrev = { ...prev };
        updatePrev.query = initialValue;
        return updatePrev;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [initialValue]);

  useEffect(() => {
    if (getStockSearch.loading && getStockSearch.execute) {
      return;
    }
    if (!disabled && !getStockSearch.execute && getStockSearch.query && getStockSearch.query.length > 0 && !getStockSearch.results[getStockSearch.query]) {
      setGetStockSearch((prev) => {
        const updatePrev = { ...prev };
        updatePrev.loading = true;
        updatePrev.execute = <GetStockSearch key='stock-search' query={updatePrev.query} onUpdate={onUpdateSearch}/>;
        return updatePrev;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [getStockSearch, disabled]);

  useEffect(() => {
    if (getStockSearch.loading) {
      return;
    }
    const query = getStockSearch.query.toLowerCase();
    const initOptions: { value: string, label: JSX.Element  }[] = [...(query !== '' && getStockSearch.results[query]
        ? [
          ...Object.keys(trackedStocks).filter((symbol: string) => {
            const matchStock: Stock = trackedStocks[symbol];
            if (matchStock && symbol.toLowerCase().includes(query)) {
              return true;
            }
            return matchStock && getStockSearch.query && getStockSearch.query.length > 0 && (matchStock.ticker.toLowerCase().includes(query) || matchStock.name.toLowerCase().includes(query));
          })
            .sort((a: string, b: string) => a.toLowerCase() > b.toLowerCase() ? -1 : 1)
            .map((stockToResult: string) => {
              const tickerValue = trackedStocks[stockToResult].ticker && trackedStocks[stockToResult].ticker !== '' ? trackedStocks[stockToResult].ticker : stockToResult;
              const knownResult: StockResult = {
                name: trackedStocks[stockToResult].name,
                ticker: tickerValue.toUpperCase(),
              };
              return knownResult;
            })
            .sort((a, b) =>  a.ticker && (a.ticker!.toLowerCase().startsWith(query) || a.name!.toLowerCase().startsWith(query)) ? -1 : (b.ticker && (b.ticker!.toLowerCase().startsWith(query) || b.name!.toLowerCase().startsWith(query)) ? 1 : 1))
            .slice(0, 6),
          ...getStockSearch.results[query]
            .filter((removeKnown: StockResult) => !Object.keys(trackedStocks).find((knownStock: string) => knownStock.toLowerCase() === removeKnown.ticker?.toLowerCase())),
            // .sort((a: StockResult, b: StockResult) => a.ticker!.toLowerCase() > b.ticker!.toLowerCase() ? 1 : -1),
        ].map((result: StockResult) => {
          const tickerIndex = result.ticker ? result.ticker.toLowerCase().indexOf(query) : -1;
          const nameIndex = result.name ? result.name.toLowerCase().indexOf(query) : -1;
          const tickerValue = result.ticker
            ? (tickerIndex < 0
              ? result.ticker
              : <>{result.ticker.slice(0, tickerIndex)}<StyledSpan bold>{result.ticker.slice(tickerIndex, tickerIndex + query.length)}</StyledSpan>{result.ticker.slice(tickerIndex + query.length)}</>)
            : '';
          const nameValue = result.name
            ? (nameIndex < 0
              ? result.name
              : <>{result.name.slice(0, nameIndex)}<StyledSpan bold>{result.name!.slice(nameIndex, nameIndex + query.length)}</StyledSpan>{result.name!.slice(nameIndex + query.length)}</>)
            : '';
          const isTracked = result.ticker && Object.keys(trackedStocks).find((trackedStock: string) => result.ticker === trackedStock) !== undefined;
          return ({ value: `[${result.ticker}] ${result.name}`, label: <div>{isTracked ? <CheckCircleOutlined /> : null} [{tickerValue}] {nameValue}</div>  });
          // return ({ value: `[${result.ticker}] ${result.name}`, label: <div>{result.ticker && trackedStocks.includes(result.ticker) ? <CheckCircleOutlined /> : null} [{result.ticker}] {result.name}</div>  });
        })
        : [])];
    setOptions(initOptions);
  },        [getStockSearch, trackedStocks]);

  const onUpdateSearch = (stockSearch?: { query: string; result: StockResult[] }, loading?: boolean, error?: any) => {
    if (error) {
      errorNotification(`${QRY_ERROR} Failed to get search result.`);
    }
    setGetStockSearch((prev) => {
      if (prev.loading === loading || !stockSearch) {
        return prev;
      }
      const updatePrev = { ...prev };
      updatePrev.loading = loading === true;
      if (stockSearch) {
        updatePrev.execute = undefined ;
        updatePrev.results[stockSearch.query.toLowerCase()] = stockSearch.result;
      }
      return updatePrev;
    });
  };

  return <>
    {getStockSearch.execute}
    <AutoComplete
      placeholder='Enter symbol'
      disabled={disabled}
      loading={getStockSearch.loading}
      notFoundContent={!getStockSearch.loading && getStockSearch.query !== '' && options.length === 0 ? 'No stocks found' : undefined}
      key={`input-symbol`}
      style={inheritWidth ? { width: '100%' } : { width: '200px', marginBottom: '5px', borderRadius: '5px', paddingRight: '5px' }}
      value={getStockSearch.query.toUpperCase()}
      suffixIcon={<SearchIcon size='small' onClick={() => {}} />}
      options={getStockSearch.query === '' ? [] : options}
      onChange={(value: string) => {
        setGetStockSearch((prev) => {
          const updatePrev = { ...prev };
          const query = value.toLowerCase();
          updatePrev.loading = value === '' ? false : !prev.results[query];
          updatePrev.query = query;
          return updatePrev;
        });
      }}
      onSelect={(value: string) => {
        const parseTicker = value.substring(1, value.indexOf(']'));
        if (resetOnSelect) {
          setGetStockSearch((prev) => {
            const updatePrev = { ...prev };
            updatePrev.loading = false;
            updatePrev.query = '';
            return updatePrev;
          });
        }
        onSelectTicker(parseTicker);
      }}
    />
  </>;
};

export default FindStock;
