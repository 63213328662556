import React, { useEffect }  from 'react';
import { FetchDestination, useGetFetchQuery } from '../../../graphql/generated/graphql';

export const stockClientContext = { context: { clientName: 'stock' } };

type FetchDataProps = {
  command: string;
  token: string;
  destination?: FetchDestination;
  onUpdate?: (data?: string, loading?: boolean, error?: any) => any;
};

const FetchData: React.FC<FetchDataProps> = (props: FetchDataProps) => {
  const { onUpdate, command, token, destination } = props;
  const { data, loading, error } = useGetFetchQuery({
    variables: { command, token, destination },
    ...stockClientContext,
    fetchPolicy: 'cache-and-network',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (onUpdate) {
      onUpdate(data ? data.getFetch : undefined, loading, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [data, loading, error]);

  return null;
};

export default FetchData;
