import React from 'react';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CircleCheckbox from '../atomic/CircleCheckbox';

export const ICONS: { [icon: string] : { name: string, icon: React.FC | React.Component } } = {
  circleCheckbox: { name: 'Circle checkbox', icon: CircleCheckbox },
  plusCircleOutined: { name: 'Add icon', icon: PlusCircleOutlined },
  editOutlined: { name: 'Edit Icon', icon: EditOutlined },
  deleteOutlined: { name: 'Delete icon', icon: DeleteOutlined },
};
