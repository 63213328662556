import { FullscreenOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { CSSProperties } from 'styled-components';
import EntriesView from '../data/EntriesView';
import { TitleDiv } from '../draggable/DraggableContext';
import FormData, { FormDataPages, Option } from '../form/FormData';
import { CenteredDiv } from '../styled/CenteredDiv';
import { RecordType } from '../table/DataTypes';
import FullScreen from '../views/FullScreen';
import { DraggableComponentItem, DraggableComponentList } from './ComponentProperties';

type RenderPageProps = {
  lists: { [uuid: string] : DraggableComponentList };
  component?: DraggableComponentItem;
};

const RenderPage: React.FC<RenderPageProps> = (props: RenderPageProps) => {
  const { lists, component } = props;
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const rootId: string | undefined = Object.keys(lists).find((list: string) => lists[list].isRoot);
  if (!rootId) {
    return null;
  }
  const root: DraggableComponentList = lists[rootId];

  const getElement = (item: DraggableComponentItem) => {
    const value = item && item.value ? item.value : undefined;
    const customProperties = item && item.content && item.content.customProperties ? { ...item.content.customProperties } : {};
    const processUndefined = item.content && item.content.properties ? { ...item.content.properties } : {};
    Object.keys(processUndefined).forEach((property: string) => {
      if (processUndefined[property] === 'undefined') {
        processUndefined[property] = undefined;
      }
    });
    const properties: CSSProperties = { ...processUndefined, border: component && item.id === component.id ? '1px solid red' : undefined };
    if (item.title === 'Header') {
      return <TitleDiv style={{ ...properties }}>{value}</TitleDiv>;
    }
    if (item.title === 'Container') {
      const children = item.childrenListUuid ? lists[item.childrenListUuid].items : null;
      return <div style={{ ...properties }}>{children ? children.map((child: DraggableComponentItem) => getElement(child)) : ''}</div>;
    }
    if (item.title === 'Text') {
      return <div style={{ ...properties }}>{value}</div>;
    }
    if (item.title === 'Collection') {
      return <div style={{ ...properties }}>{customProperties.collection ? <EntriesView collectionUuids={[customProperties.collection]}></EntriesView> : 'missing collection id'}</div>;
    }
    if (item.title === 'Form Field') {
      if (!customProperties.formField) {
        return <div>Error: Form Field missing field type</div>;
      }
      if (!customProperties.fieldName) {
        return <div>Error: Form Field Field Name is missing</div>;
      }
      const formFields: FormDataPages = { pages: [{}] };
      const initialValue = customProperties.initialValue
        ? (RecordType[customProperties.formField] === RecordType.Checkbox ? customProperties.initialValue.split(',')
          : customProperties.initialValue)
        : undefined;

      formFields.pages[0][customProperties.fieldName] = {
        placeholder: customProperties.placeholder ?? '',
        type: RecordType[customProperties.formField],
        value: initialValue,
        values: customProperties.values ? customProperties.values.split(',').map((value: string) => ({ label: value, value })) as Option[] : undefined,
      };
      return (
        <div style={{ ...properties }}>
          <FormData data={formFields} onUpdate={(data: FormDataPages) => {}} />
        </div>);
    }
    return <div style={{ ...properties }} >Unknown component {item.title}</div>;
  };

  const page = <>{root.items.map((item: DraggableComponentItem) => getElement(item))}</>;
  return (!fullScreen
    ? <>
      <CenteredDiv bold>Page Preview: <FullscreenOutlined onClick={() => setFullScreen(true)}/></CenteredDiv>
      {page}
    </>
    : <FullScreen fullScreen content={page} onExit={() => setFullScreen(false)}></FullScreen>);
};

export default RenderPage;
