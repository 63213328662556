import { CheckCircleTwoTone, CloseSquareTwoTone, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Checkbox, Input, List, Popconfirm } from 'antd';
import { Dictionary } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { errorNotification } from '../actions/Notification';
import Button from '../atomic/Button';
import ResponsiveModal from '../modal/ResponsiveModal';
import { ResponsiveDiv } from '../styled/CenteredDiv';
import { TutorialType } from '../tutorial/Tutorial';
import WizardTooltip from '../wizard/WizardTooltip';

export type QRTrackUser = {
  name: string;
  contact: string;
  userStatus: 'LOW' | 'HIGH';
};

export enum TrackUserWizard {
  AddUserButton = 'AddUserButton',
}
type QRSelectUsersProps = {
  initialUsers: Dictionary<QRTrackUser>;
  initialSelectedUsers: string[];
  onUpdate: (users: Dictionary<QRTrackUser>, selectedUsers: string[]) => void;
  onCancel: (newUsers?: Dictionary<QRTrackUser>, deletedUsers?: string[]) => void;
};

const QRSelectUsers: React.FC<QRSelectUsersProps> = (props: QRSelectUsersProps) => {
  // Users
  const [selectedUsers, setSelectedUsers] = useState<string[]>(props.initialSelectedUsers);
  const [users, setUsers] = useState<Dictionary<QRTrackUser>>(Object.assign({}, props.initialUsers));
  const [userListCode, setUserListCode] = useState<JSX.Element | null>(null);
  // Add/Update User
  const [addUser, setAddUser] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<string | any>(null);
  const [updateUserValue, setUpdateUserValue] = useState<QRTrackUser | null>(null);
  const [userDataSource, setUserDataSource] = useState<any[]>([]);
  // const [newUsers, setNewUsers] = useState<Dictionary<QRTrackUser>>({});
  const [deleteUsers, setDeleteUsers] = useState<string[]>([]);
  const [wizard, setWizard] = useState<Dictionary<boolean>>(Object.assign({}, ...[TrackUserWizard.AddUserButton, 'initial'].map((component: string) => ({ [component]: true }))));

  const refNameInput = useRef<Input>(null);

  useEffect(() => {
    if (!addUser && editUser === null && Object.keys(props.initialUsers).length === 0) {
      updateWizard(TrackUserWizard.AddUserButton, false);
      /* setAddUser(true);
      setEditUser('AAANewUser');
      const updateUsers = Object.assign({}, users, { AAANewUser: { name: '', contact: undefined, userStatus: 'LOW' } });
      setUpdateUserValue({ name: '', contact: '', userStatus: 'LOW' });
      setUsers(updateUsers); */
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (refNameInput.current) {
      refNameInput!.current.input.focus();
    }
  },        [refNameInput]);

  useEffect(() => {
    const userList = users && userDataSource && Object.keys(users).length > 0
      ? <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1,
          }}
          dataSource={userDataSource}
          renderItem={item => (
            item
          )} />
      : <div style={{ marginLeft: '25px' }}>No users found.</div>;
    setUserListCode(userList);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [userDataSource]); // userDataSource, updateUserValue, addUser, editUser]);

  useEffect(() => {
    const dataSource = Object.keys(users).sort((one, two) => (one.toLowerCase() < two.toLowerCase() ? -1 : 1)).map((user: string) => {
      return (
        <ResponsiveDiv maxWidth='480px'>
          <List.Item style={{ border: '1px' }} key={`list-item-user-${user}`}>
            <List.Item.Meta title={
            <div style={{ position: 'relative' }}>
              <Checkbox style={{ position: 'absolute', width: '25px' }} disabled={editUser} defaultChecked={addUser || selectedUsers.find((selectedUser: string) => selectedUser === user) !== undefined} onChange={(e: any) => {
                if (editUser) {
                  return;
                }
                const updateSelectedUsers = e.target.checked ? selectedUsers : selectedUsers.filter((u: string) => u !== user) ;
                if (e.target.checked && !updateSelectedUsers.find((selectedUser: string) => selectedUser === user)) {
                  updateSelectedUsers.push(user);
                }
                setSelectedUsers(updateSelectedUsers);
              }}></Checkbox>
              <div style={{  fontWeight: 'bold', marginLeft: '25px', marginRight: '50px' }}>
                {editUser === user
                  ? <Input ref={refNameInput} key={`input-user-${user}-update`} style={{ maxWidth: '100%', borderColor: 'blue' }} placeholder='Enter your name' defaultValue={editUser === user ? (updateUserValue ? updateUserValue.name : '') : users[user].name} onChange={(e: any) => {
                    const newValue = updateUserValue ?? { name: '', contact: '', userStatus: 'LOW' };
                    newValue.name = e.target.value;
                    setUpdateUserValue(newValue);
                  }}></Input>
                : <Input placeholder='Enter your name' key={`input-user-${user}`} style={{ maxWidth: '100%' }} defaultValue={users[user].name} disabled={true}></Input>}
              </div>
              <div style={{ fontWeight: 'bold', marginLeft: '25px', marginRight: '50px' }}>
                {editUser === user
                ? <Input key={`input-user-contact-${user}-update`} placeholder='Enter your phone number' style={{ maxWidth: '100%', borderColor: 'blue' }} disabled={editUser !== user} defaultValue={users[user].contact} value={editUser !== user ? undefined : (updateUserValue ? updateUserValue.contact : '')} onChange={(e: any) => {
                  const newValue: QRTrackUser = updateUserValue ? Object.assign({}, updateUserValue) : { name: '', contact: '', userStatus: 'LOW'  };
                  newValue.contact = !Number.isNaN(Number(e.target.value)) && e.target.value.length < 17 ? e.target.value : (updateUserValue ? updateUserValue.contact : '');
                  setUpdateUserValue(newValue);
                }}></Input>
                : <Input key={`input-user-contact-${user}`} style={{ maxWidth: '100%' }} disabled={true} defaultValue={users[user].contact} ></Input>}
              {editUser && editUser === user
                ? <div style={{ float: 'right', marginBottom: '10px', marginTop: '10px' }}><CheckCircleTwoTone style={{ fontSize: '22px' }} twoToneColor='#52c41a' onClick={() => {
                  if (!updateUserValue) {
                    errorNotification();
                    return;
                  }
                  if (updateUserValue.name.length === 0) {
                    errorNotification('Please enter a valid name');
                    return;
                  }
                  if (!updateUserValue.contact || updateUserValue.contact.length < 9 || updateUserValue.contact.length > 16 || Number.isNaN(Number(updateUserValue.contact))) {
                    errorNotification('Please enter a valid phone number');
                    return;
                  }
                  const updateUsers = users;
                  if (addUser) {
                    updateUsers[updateUserValue.name] = updateUserValue;
                    delete updateUsers.AAANewUser;
                    setAddUser(false);

                    const updateSelectedUsers = selectedUsers;
                    updateSelectedUsers.push(updateUserValue.name);
                    setSelectedUsers(updateSelectedUsers);
                  }
                  else {
                    delete updateUsers[user];
                    updateUsers[updateUserValue.name] = updateUserValue;
                  }
                  setUsers(updateUsers);
                  localStorage.setItem('s2p-MySejahtera', JSON.stringify(updateUsers));
                  setUpdateUserValue(null);
                  setEditUser(null);
                }} />
                <Popconfirm
                    disabled={editUser !== user}
                    title="Discard user changes."
                    onConfirm={() => {
                      setUpdateUserValue(null);
                      setEditUser(null);
                      if (addUser) {
                        setAddUser(false);
                        const updateUsers = users;
                        delete updateUsers.AAANewUser;
                        setUsers(updateUsers);
                      }
                    }}
                    onCancel={() => {}}
                    okText="Discard"
                    cancelText="Cancel"
                  >
                  <CloseSquareTwoTone style={{ marginLeft: '5px', fontSize: '22px' }} twoToneColor='red' /></Popconfirm></div>
                : null}
                </div>
              </div>}
            />
                  <div style={{ position: 'absolute', float: 'right', top: '5px', right: '0px' }}><EditTwoTone style={{ marginLeft: '5px', top: '20px', fontSize: '22px', opacity: `${editUser ? '40%' : undefined}` }} disabled={editUser} onClick={() => {
                    setEditUser(user);
                    setUpdateUserValue(Object.assign({}, users[user]));
                  }} />
                    <Popconfirm
                      disabled={editUser}
                      title="Delete user."
                      onConfirm={() => {
                        const updateSelectedUsers = selectedUsers;
                        const updateUsers = Object.assign({}, users);
                        if (updateSelectedUsers.find((u: string) => u === user)) {
                          setSelectedUsers(updateSelectedUsers.filter((u: string) => u !== user));
                        }
                        delete updateUsers[user];
                        localStorage.setItem('s2p-MySejahtera', JSON.stringify(updateUsers));
                        setUsers(updateUsers);
                        // keep track of deleted users in case use decides to cancel the form
                        setDeleteUsers([user, ...deleteUsers]);
                        /*
                        if (newUsers[user]) {
                          delete newUsers[user];
                        }
                        */
                      }} // setUpdateCollection(<EditCollection key={`update-collection-${initialData.uuid}`} action={RecordModalAction.Delete} initialData={initialData} mapping={collectionDataItemsMap} onSuccess={onSuccess} />)}
                      onCancel={() => {}}
                      okText="Delete"
                      cancelText="Cancel"
                    ><DeleteTwoTone style={{ marginLeft: '5px', top: '20px', fontSize: '22px', opacity: `${editUser ? '40%' : undefined}` }} disabled={editUser} /></Popconfirm>
                  </div>
          </List.Item>
        </ResponsiveDiv>);
    });
    setUserDataSource(dataSource);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [users, addUser, editUser, updateUserValue, selectedUsers]); // userDataSource, updateUserValue, addUser, editUser]);

  const updateWizard = (component: TrackUserWizard, hide: boolean) => {
    if (!wizard[component] || wizard[component] !== hide) {
      const updateWizardVal = Object.assign({}, wizard);
      updateWizardVal[component] = hide;
      setWizard(updateWizardVal);
    }
  };

  return (
    <ResponsiveModal
      visible={true}
      onCancel={() => props.onCancel()} // Do we update new and deleted users when dialog is cancelled?
      onOk={(e: any) => props.onUpdate(users, selectedUsers)}
      okText={'Select Users'}
      okButtonProps={{ disabled: editUser }}
      cancelButtonProps={{ disabled: editUser }}
      modalContent={<>
        <div>
          <span style={{ fontWeight: 'bold' }}>Add, Update, or Select users to register with My Sejahtera:</span>
          <br />
          <WizardTooltip
            currentPage={TutorialType.QRTracker}
            onClose={() => updateWizard(TrackUserWizard.AddUserButton, true)}
            defaultVisible={Object.keys(props.initialUsers).length === 0 && !editUser}
            visible={Object.keys(props.initialUsers).length === 0 &&  !editUser && !wizard[TrackUserWizard.AddUserButton]}
            title='invisible'
            placement="bottom"
            message='Click here to Add new user name and contact number'
          >
            <Button disabled={editUser} onClick={() => {
              setAddUser(true);
              setEditUser('AAANewUser');
              const updateUsers = Object.assign({}, users, { AAANewUser: { name: '', contact: undefined, userStatus: 'LOW' } });
              setUpdateUserValue({ name: '', contact: '', userStatus: 'LOW' });
              setUsers(updateUsers);
            }} style={{ marginLeft: '30px', marginTop: '5px', marginBottom: '8px', width: '125px', color: 'blue', opacity: `${editUser ? '40%' : undefined}` }}>Add new user</Button>
          </WizardTooltip>
          {userListCode}
        </div>
        </>}
    />
  ); // props.initialUsers || Object.keys(props.initialUsers).length === 0
};

export default QRSelectUsers;
