import React from 'react';
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { DraggableItem } from '../draggable/DraggableItemView';

type ItemProps = {
  isDragging?: boolean;
  isSelected?: boolean;
};

const Item = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0  0.5rem 0;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 1px ${(props: ItemProps) => (props.isDragging ? 'dashed #000' : 'solid #ddd')};
  ${(props: ItemProps) => props.isSelected ? 'border-color: blue;' : null};
 `;

const Clone = styled(Item)`
  border-color: red;
  transform: none !important;
`;
/*
const Clone = styled(Item)`
  + div {
    display: none!important;
    border-color: red;
    transform: none !important;
  }
`;
*/

type ListProps = {
  isDraggingOver: boolean;
};

const List = styled.div`
  ${(props: ListProps) => props.isDraggingOver ? 'border: 1px dashed #000;' : null}
  flex: 0 0 150px;
  font-family: sans-serif;
`;

type DraggableListViewProps = {
  id: string;
  list: DraggableItem<any>[];
};

const DraggableListView: React.FC<DraggableListViewProps> = (props: DraggableListViewProps) => {
  const { id, list } = props;

  return (
   <Droppable droppableId={id} isDropDisabled={true}>
      {(provided, snapshot) => (
        <List
          ref={provided.innerRef}
          isDraggingOver={snapshot.isDraggingOver}>
          <div>
          {list.map((item, index) => (
            <Draggable
              key={item.id}
              draggableId={item.id}
              index={index}>
              {(provided, snapshot) => (
                <>
                  <Item
                    key={`component-${item.title}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                    style={
                      provided.draggableProps
                        .style
                    }>
                    {item.title}
                  </Item>
                  {snapshot.isDragging && (
                    <Clone key='item-clone'>{item.title}</Clone>
                  )}
                </>
              )}
            </Draggable>
          ))}
          </div>
        </List>
      )}
    </Droppable>);
};

export default DraggableListView;
