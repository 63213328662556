import { Card } from 'antd';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CollectionsData from '../components/data/Collections';
import PagesData from '../components/data/Pages';

import Page from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
// import logger from '../utils/Logger';

// const logInfo = (message: any) => logger.info({ source: 'pages.Pages', message });
// const logInfoJson = (message: any) => logger.info({ source: 'pages.Pages', message: JSON.stringify(message) });

type ManagePageProps = RouteComponentProps & {
};

const ManagePage: React.FC<ManagePageProps> = (props: ManagePageProps) => {
  const [tabKey, setTabKey] = useState<string>('pages');

  const onTabChange = (key) => {
    setTabKey(key);
  };

  const tabList = [
    {
      key: 'pages',
      tab: 'Pages',
    },
    {
      key: 'collections',
      tab: 'Collections',
    },
  ];

  const contentList = {
    pages: <PagesData />,
    collections: <CollectionsData />, // TODO:
  };

  return (
    <Page headerTitle='Manage collections' content={
      <Content>
        <Card
          style={{ width: '100%' }}
          tabList={tabList}
          activeTabKey={tabKey}
          onTabChange={(key: string) => {
            onTabChange(key);
          }}
        >
          {contentList[tabKey]}
        </Card>
      </Content>
    }
  />);
};

export default ManagePage;
