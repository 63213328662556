import React, { useState } from 'react';
import SubmitFeedback from '../../containers/api/Public/SubmitFeedback';
import { FeedbackInput, FeedbackType, SubmitFeedbackMutation } from '../../graphql/generated/graphql';
import LoadingOverlay from '../atomic/LoadingOverlay';
import RecordModal, { ModalFields, RecordModalAction } from '../modal/RecordModal';
import { RecordType } from '../table/DataTypes';

type FeedbackModalProps = {
  onClose: () => any;
  onError?: (error: string) => any;
};

const FeedbackModal: React.FC<FeedbackModalProps> = (props: FeedbackModalProps) => {
  const { onClose, onError } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [executeUpdate, setExecuteUpdate] = React.useState<JSX.Element | null>(null);

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: {
          feedback: { placeholder: 'Enter your feedback', type: RecordType.Text, required: true },
          type: { placeholder: 'Feedback type', type: RecordType.Dropdown, values: ['Issue', 'Suggestion', 'Feature Request'], required: true },
        },
      },
    ],
  };

  const onSubmit = async (record: any) => {
    if (error) {
      setError(undefined);
    }
    const feedbackType = record.pages[0].entries.type.value === 'Issue'
    ? FeedbackType.Issue
    : record.pages[0].entries.type.value === 'Suggestion'
      ? FeedbackType.Suggestion
      : FeedbackType.FeatureRequest;
    const feedbackInput: FeedbackInput = { message: record.pages[0].entries.feedback.value , type: feedbackType };
    const updateQuery = <SubmitFeedback feedbackInput={feedbackInput} onUpdate={(data?: SubmitFeedbackMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        setExecuteUpdate(null);
        if (onError) {
          onError(error);
        }
        return;
      }

      if (data) {
        setError(undefined);
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      }
    }} />;
    setExecuteUpdate(updateQuery);
  };

  return (
    <>
      {executeUpdate}
      <LoadingOverlay
        active={isLoading}
        spinner
        text={`Thank you. Submitting your feedback...`}
      >
        <RecordModal
          key={'submit-feedback-modal'}
          data={recordDataValues}
          action={RecordModalAction.Custom}
          title={'Submit Feedback'}
          okText={'Submit Feedback'}
          onCancel={onClose}
          onSubmit={onSubmit}
          isLoading={isLoading}
          error={error}
        />
      </LoadingOverlay>
    </>
    /*
    <ResponsiveModal
      visible={true}
      title={'Submit Form'}
      onCancel={onClose}
      onOk={onClose}
      okText={'Submit'}
      modalContent={
        <div></div>
      }
    />
    */
  );
};

export default FeedbackModal;
