import React, { useEffect } from 'react';
import { useGetDataPagesQuery, useGetDataPageQuery } from '../../graphql/generated/graphql';
import { FieldType } from '../../components/table/DataTypes';
import { ApiProps } from './Helpers';
import JsxViewResponse from './JsxViewResponse';
import { dataClientContext } from './Api';

type DataPagesApiProps = ApiProps & {
};

type DataPageApiProps = ApiProps & {
  uuid: string;
};

export const GetDataPages: React.FC<DataPagesApiProps> = (props: DataPagesApiProps) => {
  const { ...apiProps } = props;
  const { data: dataPages } = useGetDataPagesQuery({
    variables: { },
    ...dataClientContext,
  });

  useEffect(() => {
    console.log('GetDataPages useEffect');
    console.log(dataPages);

    if (!dataPages || !dataPages.getDataPages) {
      return;
    }
    if (props.updateApiResponse) {
      console.log('GetDataPages useEffect updateApiResponse');
      props.updateApiResponse(dataPages.getDataPages);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataPages]); // Empty array ensures that effect is only run on mount and unmount

  console.log('GetDataPages data');
  console.log(apiProps);
  console.log(dataPages);

  if (!props.returnJsx) {
    return null;
  }
  const result = (<JsxViewResponse data={dataPages?.getDataPages} {...apiProps} />);
  console.log('getDataPages result');
  console.log(result);

  return result;
};

export const GetDataPage: React.FC<DataPageApiProps> = (props: DataPageApiProps) => {
  const { uuid, ...apiProps } = props;

  const { data: dataPage } = useGetDataPageQuery({
    variables: { uuid: props.uuid },
    ...dataClientContext,
  });

  useEffect(() => {
    console.log('GetDataPage useEffect');
    console.log(dataPage);

    if (!dataPage || !dataPage.getDataPage) {
      return;
    }
    if (props.updateApiResponse) {
      console.log('GetDataPage useEffect updateApiResponse');
      props.updateApiResponse(dataPage.getDataPage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataPage]); // Empty array ensures that effect is only run on mount and unmount

  console.log('GetDataPage data');
  console.log(apiProps);
  console.log(dataPage);

  if (!props.returnJsx) {
    return null;
  }
  const result = (<JsxViewResponse data={dataPage?.getDataPage} {...apiProps} />);
  console.log('getDataPage result');
  console.log(result);

  return result;
};

export const dataPagesResponse = {
  entries: {
    response: { type: FieldType.Tree, values: [...[
      'uuid'].map((o: string) => ({ title: `${o}`, key: `${o}` })),
      {
        title: 'data',
        key: 'data',
        children: [...[
          'uuid',
          'type',
          'itemUuid',
          ].map((o: string) => ({ title: `${o}`, key: `data-${o}` })),
        ],
      },
      ...[
      'ownerUuid',
      'name',
      'description',
      'dateCreated',
      'active',
      'dateRemoved'].map((o: string) => ({ title: `${o}`, key: `${o}` })),
    ]},
  },
};
