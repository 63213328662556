import React, { useEffect, useState }  from 'react';
import { UserInput, useRegisterAlphaUserMutation, RegisterAlphaUserMutation } from '../../../graphql/generated/graphql';
import { publicClientContext } from '../Public';

export type RegisterAlphaUserProps = {
  user: UserInput;
  onUpdate?: (data?: RegisterAlphaUserMutation | null, loading?: boolean, error?: any) => any;
};

const RegisterAlphaUser: React.FC<RegisterAlphaUserProps> = (props: RegisterAlphaUserProps) => {
  const { user, onUpdate } = props;
  const [executed, setExecuted] = useState<boolean>(false);
  const [registerAlphaUser, { data: alphaUserData, loading: alphaUserLoading, error: alphaUserError }] = useRegisterAlphaUserMutation({
    ...publicClientContext,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!executed) {
      console.log(`register for alpha`);
      setExecuted(true);
      registerAlphaUser({ variables: { user } })
        .then((result: any) => {
          console.log(`done registering alpha user ${result}`);
          console.log(result);
        })
        .catch((err: any) => {
          console.log(`error submitting feedback`);
          console.log(err);
        });
    }
    if (onUpdate) {
      onUpdate(alphaUserData, alphaUserLoading, alphaUserError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [alphaUserData, alphaUserLoading, alphaUserError]);

  return <></>;
};

export default RegisterAlphaUser;
