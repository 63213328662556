import { CameraOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import QrReader from 'react-qr-scanner';
import { errorNotification } from '../actions/Notification';
import Button from '../atomic/Button';
import ResponsiveModal from '../modal/ResponsiveModal';

type QRScannerProps = {
  modal?: boolean;
  onUpdate: (data?: any, err?: any) => void;
  onCancel: () => void;
};

const QRScanner: React.FC<QRScannerProps> = (props: QRScannerProps) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [qrCode, setQRCode] = useState<JSX.Element>(<></>);
  const [cameraSide, setCameraSide] = useState<'front' | 'rear'>('rear');

  useEffect(() => {
    const initialCameraSide = cameraSide === 'front' ? 'rear' : 'front';
    console.log(`QR Reader show modal: ${props.modal} with camera side ${initialCameraSide}`);
    console.log(`useEffect data is ${data}`);
    setQRCode(<div>
      {data
        ? <div><div style={{ fontWeight: 'bold' }}>Result:</div><p style={{ wordWrap: 'break-word' }}>{data}</p><Button onClick={() => setData(null)}>Rescan</Button></div>
        : <><QrReader
            delay={500}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%', maxWidth: '600px', marginLeft: '500px', marginRight: '500px' }}
            facingMode={initialCameraSide}
          />
          <CameraOutlined style={{ zIndex: 3000, position: 'fixed', bottom: 20, right: 20, fontSize: '20px' }} onClick={() => {
            console.log(`switch camera from ${cameraSide} to ${cameraSide === 'rear' ? 'front' : 'rear'}`);
            setCameraSide(cameraSide === 'rear' ? 'front' : 'rear');
          }}/></>}
      </div>);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [data, cameraSide]);

  const handleScan = (scannedData: any) => {
    console.log(`handle scan called with ${scannedData}`);
    if (scannedData && scannedData !== data) {
      setData(scannedData);
      setError(null);
      if (!props.modal && props.onUpdate) {
        props.onUpdate(scannedData, null);
      }
    }
  };

  const handleError = (err: any) => {
    console.log(`handle error called with ${err}`);
    console.error(err);
    setError(err);
    if (!props.modal && props.onUpdate) {
      props.onUpdate(null, err);
    }
    else {
      errorNotification();
    }
  };

  return (<>{props.modal
    ? <ResponsiveModal
        visible={true}
        onCancel={props.onCancel}
        onOk={(e: any) => props.onUpdate(data, error)}
        modalContent={qrCode}
      />
    : qrCode}
    <div style={{ zIndex: 3000, position: 'fixed', bottom: 20, marginLeft: 'auto', marginRight: 'auto' }}>scanner</div></>
  );
};

export default QRScanner;
