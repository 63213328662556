import * as winston from 'winston';
import * as stream from 'stream';
import logConfig from './logger.json';

// Ignore log messages if they have { private: true }
const filterSource = winston.format((info, opts) => {
  const { source, ...message } = info;
  if (info.source) {
    const path = info.source.split('.');
    let node = logConfig;
    if (path.length > 0) {
      message.message = `[${path[0]}] ${message.message}`;
    }
    for (const s of path) {
      node = node[s];
      if (node && node['Enabled'] !== undefined && node['Enabled'] === false) {
        console.log('returning false xxxxxxxxx');
        console.log(node);
        return false;
      }
      if (node) {
        continue;
      }
      break;
    }

/*
    for (const key in path) {
      node = node[key];
      if (node && node['Enabled'] === false) {
        return false;
      }
    }

    return false; */
  }
  return message;
});

const logger = winston.createLogger({
  transports: [
    new winston.transports.Console({
      level: 'debug',
      format: winston.format.combine(
        winston.format.colorize(),
        filterSource(),
        winston.format.simple(),
        winston.format.splat(),
      ),
    }),
  ],
  exitOnError: false,
});

logger.stream = (/* options?: any */) => new stream.Duplex({
  write: (message: string) => {
    logger.info(message ? message.trim() : message);
  },
});
// logger.stream = {
//   write: function(message, encoding){
//       logger.info(message);
//   }
// };

export default logger;
