import React from 'react';
import { Input, DatePicker, Select, Button } from 'antd';
// import { ColumnsType } from 'antd/lib/table';
import { upperFirst, Dictionary } from 'lodash';
import { RecordType, dateFormat } from '../table/DataTypes';
import { CheckboxGroupVertical } from '../styled/AntdStyled';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
// import FullWidthSpace from '../styled/FullWidthSpace';
import Tooltip from '../atomic/Tooltip';

export type Option = { label: string, value: string };

export type FormFields = Dictionary<ModalFieldEntry>;

export type ModalFieldEntry = {
  type: RecordType,
  placeholder?: string,
  // inputType?: InputType,
  value?: any,
  values?: string[] | Option[],
  // entries?: FormFields,
  variable?: boolean,
  required?: boolean,
  // columns?: ColumnsType<any>,
  // columnData?: FormFields,
  disabled?: boolean,
  reference?: string,
  updated?: boolean,
  name?: string,
  info?: string,
  autoFocus?: boolean,
  onUpdate?: (record: any) => any,
};

type FormFieldProps = {
  fieldData: ModalFieldEntry;
  fieldKey: string;
  includeName?: boolean;
  onFieldChange: (newValue: any, fieldKey: string) => void;
};

const FormField: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  const { fieldData, fieldKey, onFieldChange } = props;
  const includeName = props.includeName ?? true;

  let fieldObject: JSX.Element = <div>Unknown type {fieldData.type}</div>;
  // const dataSourceObj: { [name: string]: JSX.Element }[] = [];
  if (fieldData.type === RecordType.Input) {
    fieldObject = <Input
      autoFocus={fieldData.autoFocus}
      style={{ width: '100%' }}
      key={`input-${fieldKey}`}
      disabled={fieldData.disabled}
      placeholder={fieldData.placeholder}
      value={fieldData.value}
      onChange={(e: any) => onFieldChange(e.target.value, fieldKey)} />;
  }
  if (fieldData.type === RecordType.Text) {
    fieldObject = <Input.TextArea
      autoFocus={fieldData.autoFocus}
      autoSize
      style={{ width: '100%' }}
      key={`input-${fieldKey}`}
      disabled={fieldData.disabled}
      placeholder={fieldData.placeholder}
      value={fieldData.value}
      onChange={(e: any) => onFieldChange(e.target.value, fieldKey)} />;
  }
  if (fieldData.type === RecordType.TextValue) {
    fieldObject = <div>{fieldData.value}</div>;
  }
  if (fieldData.type === RecordType.Checkbox) {
    fieldObject = <CheckboxGroupVertical
      style={{ width: '100%' }}
      key={`input-${fieldKey}`}
      disabled={fieldData.disabled}
      options={fieldData.values}
      defaultValue={fieldData.value}
      onChange={(e: any) => onFieldChange(e, fieldKey)} />;
  }
  if (fieldData.type === RecordType.Index) {
    fieldObject = <div key={`index-${fieldKey}`}>{fieldData.value}</div>;
  }
  else if (fieldData.type === RecordType.Date) {
    fieldObject = <DatePicker
      key={`date-picker-${fieldKey}`}
      defaultValue={fieldData.value ? moment(fieldData.value) : undefined}
      format={dateFormat}
      disabled={fieldData.disabled}
      onChange={(e: any) => onFieldChange(e, fieldKey)} />;
  }
  else if (fieldData.type === RecordType.Dropdown) {
    const options: Option[] = fieldData.values !== undefined && fieldData.values.length > 0
      ? (((fieldData.values[0] as Option).label || (fieldData.values[0] as Option).value)
          ? (fieldData.values as Option[])
          : (fieldData.values as string[]).map((item: string) => ({ label: item, value: item } as Option)))
      : [];
    const dropdownOptions = options.map((item: Option) => {
      return (
        <Select.Option
          key={`dropdown-${fieldKey}-${item.value}`}
          value={item.value}>
            {item.label}{/*item.info !== null ? <Tooltip placement="topLeft" title={item.info as string}> <QuestionCircleTwoTone /></Tooltip> : null */}
        </Select.Option>);
    });
    // TODO: Add defaultValue
    fieldObject = <Select
      style={{ width: '100%' }}
      key={`dropdown-${fieldKey}`}
      placeholder={fieldData.placeholder}
      value={fieldData.value}
      disabled={fieldData.disabled}
      onChange={(value: any, option: any) => onFieldChange(value, fieldKey)}
      onSelect={(value: any, option: any) => onFieldChange(value, fieldKey)}
    >{dropdownOptions}</Select>;
  }
  /*
  else if (data[fieldKey].type === RecordType.Action) {
    fieldObject = data[fieldKey].values.map((item: any) => (
      <Button disabled={valueNode.disabled} key={`action-${fieldKey}-${item}`} value={item}>{item}</Button>));
  } */
  else if (fieldData.type === RecordType.Render) {
    return fieldData.value;
  }
  /*
  else if (fieldData.type === RecordType.Table) {
    console.log('Table');
    console.log(fieldData.columnData);
    const dataKeys = fieldData.columnData ? Object.keys(fieldData.columnData as FormFields) : [];
    console.log(dataKeys);
    const dataSourceElement: { [name: string]: JSX.Element }  = {};
    dataKeys.forEach((columnKey: string) => {
      const columnData: ModalFieldEntry = (fieldData.columnData as FormFields)[columnKey];
      const columnDataKeys = Object.keys(columnData);
      const columnFieldData: JSX.Element[] = [];
      console.log('columnDataKeys');
      console.log(columnDataKeys);
      columnDataKeys.forEach((columnFieldKey: string) => {
        console.log(`columnFieldKey ${columnFieldKey}`);
        console.log(columnData);
        console.log(fieldData);
        const tableField = <FormField
          fieldData={columnData[columnFieldKey]}
          fieldKey={columnFieldKey}
          includeName={includeName}
          onFieldChange={onFieldChange} />;
        console.log('tableField');
        console.log(tableField);
        columnFieldData.push(tableField);
      });
      dataSourceElement[columnKey] = <>
        <FullWidthSpace
          key={`table-space-${fieldKey}-${columnKey}`}
          direction='vertical'>{columnFieldData}
          </FullWidthSpace>
      </>;
      dataSourceObj.push(dataSourceElement);
      // if (data[key].variable) {
      //   variableObj = dataSourceElement;
      // }
      console.log('dataSourceElement');
      console.log(dataSourceElement);
    });
    console.log('columns');
    console.log(fieldData.columns);
    fieldObject = <Table
      key={`table-${fieldKey}`}
      className='.antd-modal-fields'
      size='small'
      columns={fieldData.columns}
      dataSource={dataSourceObj}
      bordered={true}
      pagination={false}
      scroll={{ x: '100', y: '300' }}
    />;
  }
  */

  return(
  <>
    {fieldData.type === RecordType.Table || !includeName
      ? null
      : (
      <div style={{ fontWeight: 'bold' }}>
        {fieldData.name ? fieldData.name : `${upperFirst(fieldKey)}:`} {fieldData.info
          ? <Tooltip placement="topLeft" title={fieldData.info}><QuestionCircleTwoTone /></Tooltip>
          : null}
      </div>)}
    {fieldObject}{fieldData.variable && fieldData.type === RecordType.Table
      ? (
      <Button onClick={() => {
      }}>Add new data field
      </Button>)
      : null}
  </>);
};

export default FormField;
