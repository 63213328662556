import React from 'react';
import {  message, Space, Divider } from 'antd';
import styled from 'styled-components';
import { useWindowSize } from '../layout/Page';
import { Content } from '../styled/CssContent';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

type CssProps = {
  bigWindow: boolean;
  windowWidth: number;
  isModal?: boolean
};

// background-color: lightGray;
const ListDiv = styled.div`
  #position: flex;
  float: right;
  #display: flex;
  max-width: ${(props: CssProps) => props.isModal ? '-webkit-fill-available' : '800px'};
  ${(props: CssProps) => props.bigWindow && props.isModal ? null :`
  padding-right: 10px; /* scrollbar */
  padding-left: 10px;
  padding-bottom: 15px;`}
  /* overflow: auto; */
  ${(props: CssProps) => props.bigWindow && !props.isModal ? `
  margin-left: auto;
  margin-right: auto;` : ''}
`;
// text-align: center;

type ContentViewProps = {
  actionButtons: JSX.Element[];
  data: JSX.Element;
  hideDivider?: boolean
  isModal?: boolean
  leftActionButtons?: JSX.Element[];
};

const ContentView: React.FC<ContentViewProps> = (props: ContentViewProps) => {
  const windowSize = useWindowSize();

  return (
    // TODO: Why does button onClick not work without a divider?
    // {addCodeError && (message.error(`Failed to add code: ${addCodeError}`))}
    <Content>
      <ListDiv bigWindow={windowSize.width !== undefined && windowSize.width > 800} windowWidth={windowSize.width ? windowSize.width : 800} isModal={props.isModal}>
        {props.actionButtons
          ? <>
              {!props.leftActionButtons ? null : <Space direction='horizontal' style={{ float: 'left', marginTop: 10, marginBottom: 10 }}>
                {props.leftActionButtons}
              </Space>}
              <Space direction='horizontal' style={{ float: 'right', marginTop: 10, marginBottom: 10 }}>
                {props.actionButtons}
              </Space>
              <Divider style={{ opacity: props.hideDivider ? 0 : undefined, margin: 0 }}/>
            </>
          : null
        }
        {props.data}
      </ListDiv>
    </Content>
  );
};

export default ContentView;
