import { Dictionary } from 'lodash';
import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import { PlaidAccount, PlaidLinkedInstitution } from '../../../graphql/generated/graphql';
import { infoNotification } from '../../actions/Notification';
import Button from '../../atomic/Button';
import Dropdown from '../../atomic/Dropdown';
import FormField from '../../form/FormField';
import RecordModal, { ModalFields, RecordModalAction } from '../../modal/RecordModal';
import { StyledDiv, StyledSpan } from '../../styled/CenteredDiv';
import { RecordType } from '../../table/DataTypes';
import { StockDataSourceType, StockTransaction, TransactionAction } from '../StockTypes';
import ImportInvestmentTransactions from './ImportInvestmentTransactions';

type ImportStockCsvProps = {
  linkedInstitutions: PlaidLinkedInstitution[];
  importData: { columns: string[]; rows: string[][] };
  onUpdateImportData: (updateImportData: { columns: string[]; rows: string[][] }) => void;
  onCancel: () => void;
};

const ImportStockCsv: React.FC<ImportStockCsvProps> = (props: ImportStockCsvProps) => {
  const { importData, linkedInstitutions, onUpdateImportData } = props;
  const [lastChanged, setLastChanged] = useState<string | undefined>(undefined);
  const [updatedImportData, setUpdatedImportData] = useState<{ columns: string[]; rows: string[][]} | undefined>(undefined);
  const [editColumn, setEditColumn] = useState<boolean>(false);
  const [mapColumns, setMapColumns] = useState<boolean>(false);
  const [splitColumn, setSplitColumn] = useState<{ columnIndex: number, splitString: string } | undefined>(undefined);
  const [mappedColumns, setMappedColumns] = useState<Dictionary<string>>({});
  const [selectedBrokerAccount, setSelectedBrokerAccount] = useState<{ broker?: PlaidLinkedInstitution, mappedAccounts: Dictionary<string>, accountColumn?: string, accounts: string[] }>({ mappedAccounts: {}, accounts: [] });
  /*
  const columns: Dictionary<string[]> = {
    Symbol: importData.columns,
    Broker: linkedInstitutions.map((inst: PlaidLinkedInstitution) => inst.institutionName),
    Account: importData.columns,
    Date: importData.columns,
    Quantity: importData.columns,
    Price: importData.columns,
    Total: importData.columns,
    Fees: importData.columns,
  };
  */

  useEffect(() => {
    setLastChanged(new Date().toISOString());
  },      [selectedBrokerAccount]);

  useEffect(() => {
    if (splitColumn && splitColumn.splitString !== '') {
      const splitColumns = importData.columns[splitColumn.columnIndex].split(splitColumn.splitString).filter((removeEmpty: string) => removeEmpty !== '');
      if (splitColumns.length > 0) {
        const updateColumns = [...importData.columns.slice(0, splitColumn.columnIndex), ...splitColumns, ...importData.columns.slice(splitColumn.columnIndex + splitColumns.length)];
        const updateRows: string[][] = [...importData.rows].map((rowData: string[]) => {
          const splitData = rowData[splitColumn.columnIndex].split(splitColumn.splitString);
          const updateRow: string[] = [...rowData.slice(0, splitColumn.columnIndex), ...splitColumns.map((dummyColumn: string, index: number) => splitData.length > index ? splitData[index] : ''), ...rowData.slice(splitColumn.columnIndex + splitColumns.length)];
          return updateRow;
        });
        setUpdatedImportData({ columns: updateColumns, rows: updateRows });
      }
      setLastChanged(new Date().toISOString());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [splitColumn]);

  const mapColumnsValues: ModalFields = {
    pages: [
      {
        entries: Object.assign(
          {},
          {
            TablePreviewDescription: { type: RecordType.Render, fieldName: '', renderFn: () => 'Table preview with one row showing' },
            Table: {
              type: RecordType.Table,
              columns: importData.columns.map((column: string, index: number) => ({ title: column, dataIndex: index, key: column, inputType: { recordType: RecordType.TextValue } })),
              columnData: importData.rows.length === 0
                ? []
                : [Object.assign({}, ...importData.rows[0].map((columnData: string, index: number) => ({ [index]: { type: RecordType.TextValue, value: columnData } })))],
            },
            TableResultPreviewDescription: { type: RecordType.Render, fieldName: '', renderFn: () => 'Result table preview with one row showing' },
            TableResult: {
              type: RecordType.Render,
              renderFn: (record: any) => {
                const missingData: JSX.Element[] = [];
                let hasMissingData = false;
                if (!selectedBrokerAccount.broker) {
                  missingData.push(<li>Select broker</li>);
                  hasMissingData = true;
                }
                if (!selectedBrokerAccount.accountColumn) {
                  missingData.push(<li>Select account column</li>);
                  hasMissingData = true;
                }
                if (!selectedBrokerAccount.accounts || selectedBrokerAccount.accounts.length !== Object.keys(selectedBrokerAccount.mappedAccounts).length) {
                  missingData.push(<li>Map accounts</li>);
                  hasMissingData = true;
                }
                if (hasMissingData) {
                  return <>
                    <StyledDiv color='red'>Please complete mapping below<ul>Hmm{missingData}</ul></StyledDiv>
                  </>;
                }
                const brokerName: string = selectedBrokerAccount.broker!.institutionName ?? selectedBrokerAccount.broker!.institutionId;
                const columnIndex: Dictionary<number> = {};
                importData.columns.forEach((column: string, index: number) => columnIndex[column] = index);
                const amount = record.pages[0].entries.Total.value ? Number(importData.rows[0][columnIndex[record.pages[0].entries.Total.value]].replaceAll(',', '').replaceAll('$', '')) : 0;
                const accountId = selectedBrokerAccount.mappedAccounts[importData.rows[0][columnIndex[selectedBrokerAccount.accountColumn!]]];
                const sampleTransaction: StockTransaction = {
                  uuid: uuid(),
                  broker: brokerName,
                  accountId,
                  date: record.pages[0].entries.Date.value ? importData.rows[0][columnIndex[record.pages[0].entries.Date.value]] : '',
                  transactionId: '',
                  transactionIds: [],
                  securityId: '',
                  action: amount < 0 ? TransactionAction.Sold : TransactionAction.Bought,
                  symbol: importData.rows[0][columnIndex[record.pages[0].entries.Symbol.value]],
                  amount,
                  quantity: record.pages[0].entries.Quantity.value ? Number(importData.rows[0][columnIndex[record.pages[0].entries.Quantity.value]].replaceAll(',', '').replaceAll('$', '')) : 0,
                  price: record.pages[0].entries.Price.value ? Number(importData.rows[0][columnIndex[record.pages[0].entries.Price.value]].replaceAll(',', '').replaceAll('$', '')) : undefined,
                  fees: record.pages[0].entries.Fees.value ? Number(importData.rows[0][columnIndex[record.pages[0].entries.Fees.value]].replaceAll(',', '').replaceAll('$', '')) : undefined,
                  name: undefined, // ???
                  source: {
                    sourceType: StockDataSourceType.Csv,
                    addedDate: new Date(),
                  },
                  lots: [],
                };
                const accounts = ({ [brokerName] : Object.assign({}, ...selectedBrokerAccount.broker!.accounts.map((acc: PlaidAccount) => ({ [acc.accountId]: acc} ))) });
                return <ImportInvestmentTransactions
                  broker={brokerName}
                  accounts={accounts}
                  existingTransactions={{}}
                  transactions={({ [brokerName] : [sampleTransaction] })}
                  onImportInvestmentTransactions={() => {}}
                  onClose={() => {}}
                />;
              },
            },
            Symbol: {
              type: RecordType.Dropdown,
              values: importData.columns,
            },
            Broker: {
              type: RecordType.Dropdown,
              value: selectedBrokerAccount.broker ? (selectedBrokerAccount.broker.institutionId && selectedBrokerAccount.broker.institutionId !== '' ? selectedBrokerAccount.broker.institutionId : selectedBrokerAccount.broker.institutionName) : undefined,
              values: linkedInstitutions.map((inst: PlaidLinkedInstitution) => ({ label: inst.institutionName, value: inst.institutionId && inst.institutionId !== '' ? inst.institutionId : inst.institutionName })),
              onUpdate: (value: string) => {
                setSelectedBrokerAccount((prev) => {
                  const updatePrev = { ...prev };
                  if (prev.broker && prev.broker.institutionId === value) {
                    return prev;
                  }

                  updatePrev.broker = linkedInstitutions.find((inst: PlaidLinkedInstitution) => inst.institutionId === value);
                  updatePrev.mappedAccounts =  {};
                  return updatePrev;
                });
              },
            },
            Account: {
              type: RecordType.Dropdown,
              values: importData.columns,
              onUpdate: (value: string) => {
                setSelectedBrokerAccount((prev) => {
                  const updatePrev = { ...prev };
                  if (prev.accountColumn === value) {
                    return prev;
                  }
                  updatePrev.accountColumn = value;
                  const accountColumnIndex = importData.columns.findIndex((col: string) => col === value);
                  if (accountColumnIndex < 0) {
                    return prev;
                  }
                  updatePrev.accounts = [];
                  const dedupAccounts: Dictionary<boolean> = {};
                  importData.rows.forEach((row: string[]) => {
                    if (!row[accountColumnIndex] || row[accountColumnIndex] === '' || dedupAccounts[row[accountColumnIndex]]) {
                      return;
                    }
                    dedupAccounts[row[accountColumnIndex]] = true;
                    updatePrev.accounts.push(row[accountColumnIndex]);
                  });
                  updatePrev.mappedAccounts =  {};
                  return updatePrev;
                });
              },
            },
            AccountMap: {
              type: RecordType.Render,
              renderFn: (record: ModalFields) => {
                /*
                value: selectedBrokerAccount.account,
                values: selectedBrokerAccount.broker ? selectedBrokerAccount.broker.accounts.map((account: PlaidAccount) => ({ label: account.name, value: account.accountId })) : [],
                onUpdate: (value: string) => {
                  setSelectedBrokerAccount((prev) => {
                    const updatePrev = { ...prev };
                    if (updatePrev.account === value) {
                      return prev;
                    }
                    updatePrev.account = value;
                    return updatePrev;
                  });
                },
                */
                const importAccounts: { label: string, value: string }[] = selectedBrokerAccount.broker ? selectedBrokerAccount.broker.accounts.map((account: PlaidAccount) => ({ label: account.name ?? account.accountId, value: account.accountId })) : [];
                return (
                  <>
                    <StyledDiv bold>Map imported account name:</StyledDiv>
                    {selectedBrokerAccount.accountColumn
                      ? (selectedBrokerAccount.broker
                        ? selectedBrokerAccount.accounts.map((account: string) => {
                          return <div><StyledSpan bold>{account}:</StyledSpan> <FormField
                          fieldData={{
                            type: RecordType.Dropdown,
                            placeholder: 'Select account',
                            values: importAccounts,
                          }}
                          fieldKey={`map-account-${account}`}
                          includeName={false}
                          onFieldChange={(newValue: any, fieldKey: string) => setSelectedBrokerAccount((prev) => {
                            const updatePrev = { ...prev };
                            updatePrev.mappedAccounts[account] = newValue;
                            return updatePrev;
                          })}
                        />
                        </div>;
                        })
                        : <div>Please select a broker</div>)
                      : <div>Please select a column to import account</div>}
                  </>);
              },
            },
            ...(Object.assign({}, ...(['Date', 'Quantity', 'Price', 'Total', 'Fees'].map((column: string) => ({ [column]: ({
              type: RecordType.Dropdown,
              values: importData.columns.map((column: string, index: number) => ({ label: column, value: column })),
            })}))))),
          },
        ),
      },
    ],
  };

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: Object.assign(
          {},
          {
            TablePreviewDescription: { type: RecordType.Render, fieldName: '', renderFn: () => 'Table preview with one row showing' },
            Table: {
              type: RecordType.Table,
              columns: importData.columns.map((column: string, index: number) => ({ title: column, dataIndex: index, key: column, inputType: { recordType: RecordType.TextValue } })),
              columnData: importData.rows.length === 0
                ? []
                : [Object.assign({}, ...importData.rows[0].map((columnData: string, index: number) => ({ [index]: { type: RecordType.TextValue, value: columnData } })))],
            },
            TableResultDescription: { type: RecordType.Render, fieldName: '', renderFn: () => 'Table result preview with one row showing' },
            TableResult: {
              type: RecordType.Table,
              columns: updatedImportData ? updatedImportData.columns.map((column: string, index: number) => ({ title: column, dataIndex: index, key: column, inputType: { recordType: RecordType.TextValue } })) : [],
              columnData: updatedImportData && updatedImportData.rows.length === 0
                ? []
                : (updatedImportData ? [Object.assign({}, ...updatedImportData.rows[0].map((columnData: string, index: number) => ({ [index]: { type: RecordType.TextValue, value: columnData } })))] : []),
            },
            EditAction: { placeholder: 'Select edit action', type: RecordType.Dropdown, values: ['Split Column', 'Create New Column', 'Delete Column'] },
            SelectColumn: {
              placeholder: 'Select column to edit',
              type: RecordType.Dropdown,
              values: importData.columns,
              onUpdate: (value: any) => setSplitColumn((prevSplitColumn) => {
                const columnIndex = importData.columns.findIndex((column: string) => column === value);
                if (columnIndex === -1) {
                  return undefined;
                }
                if (!prevSplitColumn) {
                  return { columnIndex, splitString: '' };
                }
                const updateSplitColumn = { ...prevSplitColumn };
                updateSplitColumn.columnIndex = columnIndex;
                return updateSplitColumn;
              }),
            },
            SplitColumnRegex: {
              placeholder: 'Split string',
              type: RecordType.Input,
              value: splitColumn ? splitColumn.splitString : '',
              onUpdate: (value: any) => setSplitColumn((prevSplitColumn) => {
                if (!prevSplitColumn) {
                  infoNotification('Please select column to split');
                  return prevSplitColumn;
                }
                const updateSplitColumn = { ...prevSplitColumn };
                updateSplitColumn.splitString = value;
                return updateSplitColumn;
              }),
            },
          },
        ),
      },
    ],
  };

  const onSubmit = async (record: any) => {
    setEditColumn(false);
    if (updatedImportData) {
      onUpdateImportData(updatedImportData);
    }
  };

  return (
    <>
      <Button onClick={() => setEditColumn(true)}>Edit table columns</Button>
      <Button onClick={() => setMapColumns(true)}>Map columns</Button>
      {editColumn ? <RecordModal
        lastChanged={lastChanged}
        key={'add-entry-modal'}
        data={recordDataValues}
        action={RecordModalAction.Update}
        title={'Columns'}
        onCancel={() => {
          setEditColumn(false);
        }}
        onSubmit={onSubmit}
  //      onEmbedUpdate={onUpdateData}
      /> : null}
      {mapColumns ? <RecordModal
        lastChanged={lastChanged}
        key={'map-columns'}
        data={mapColumnsValues}
        action={RecordModalAction.Update}
        title={'Columns'}
        onCancel={() => {
          setMapColumns(false);
        }}
        onSubmit={onSubmit}
  //      onEmbedUpdate={onUpdateData}
      /> : null}
    </>
  );
};

export default ImportStockCsv;
