import React, { useContext, useState } from 'react';

import { GetDataPages } from '../../containers/api/DataPage';
import { ResultViewEnum } from '../../containers/api/Helpers';
import { GetFriends } from '../../containers/api/User/User';
import AuthContext from '../../context/AuthContext';
import logger from '../../utils/Logger';

const logInfo = (message: any) => logger.info({ source: 'pages.Pages', message });
const logInfoJson = (message: any) => logger.info({ source: 'pages.Pages', message: JSON.stringify(message) });

type UserProps = {
  uuid: string;
};

const UserData: React.FC<UserProps> = (props: UserProps) => {
  const { uuid } = props;
  const authContext = useContext(AuthContext);
  const [friendsData, setFriendsData] = useState<any>(null);
  const friendsApi = <GetFriends returnJsx={false} updateApiResponse={(data: any) => setFriendsData(data)}/>;

  const users: { [uuid: string] : any } = {};
  if (friendsData && friendsData.user) {
    friendsData.forEach((friend: any) => {
      users[friend.uuid] = {
        firstName: friend.firstName,
        lastName: friend.lastName,
        emailAddress: friend.emailAddress,
      };
    });
  }

  const ownerName = authContext.user && authContext.user.uuid === uuid
  ? 'You'
  : (users[uuid]
    ? `${users[uuid].firstName} ${users[uuid].lastName}`
    : uuid
  );

  return <div>owner: {ownerName}</div>;
};

export default UserData;
