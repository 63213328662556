import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { default as PageComponent } from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
import ViewCollapsable from '../components/views/Collapsable';
import { GetDataPage } from '../containers/api/DataPage';
import { ResultViewEnum } from '../containers/api/Helpers';
import _ from 'lodash';
import { GetDataCollectionsById } from '../containers/api/Collection/DataCollection';
import styled from 'styled-components';
import LoadImageContainer from '../containers/uploader/LoadImage';
import ViewCard from '../components/views/Card';

// import { GetDataObjects } from '../containers/api/DataObject';

const ScrollableDiv = styled.div`
  height: 400;
  width: 400;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  background: transparent;
  border-style: solid;
  z-index: 100;
`;

const DisabledIFrame = styled.iframe.attrs(() => ({ target: '_blank' }))`
  width: 400;
  height: 400;
  # disabled: "disabled"
  # pointer-events: none;
  z-index: -2;
`;

type AdHocProps = RouteComponentProps & {
};

const AdHoc: React.FC<AdHocProps> = (props: AdHocProps) => {
  const [url, setUrl] = useState<string>('https://www.google.com');
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const pageUuid = (props.match.params as any).pageUuid;
  const [pageData, setPageData] = useState<any>(null);
  const [ajaxData, setAjaxData] = useState<any>(null);
  // const [ajaxError, setAjaxError] = useState<any>(null);
  // const [dataObjectData, setDataObjectData] = useState<any>(null);
  // const [dataEntriesData, setDataEntriesData] = useState<any>(null);
  const [collectionData, setCollectionData] = useState<any>(null);
  const [collectionUuids, setCollectionUuids] = useState<string[]>([]);
  const dataPages = <GetDataPage uuid={pageUuid} view={ResultViewEnum.Json} updateApiResponse={(data: any) => setPageData(data)}/>;
  const dataCollections = <GetDataCollectionsById collectionUuids={collectionUuids} view={ResultViewEnum.Json} updateApiResponse={(data: any) => setCollectionData(data)}/>;
  // const dataObjects = <GetDataObjects view={ResultViewEnum.Json} updateApiResponse={(data: any) => setCollectionData(data)}/>;
  // const dataEntries = <GetDataObjects view={ResultViewEnum.Json} updateApiResponse={(data: any) => setCollectionData(data)}/>;

  useEffect(() => {
    if (pageData && pageData.data) {
      const uuids = pageData.data.filter((collection: any) => collection.type === 'collection').map((collection: any) => collection.itemUuid);
      const prevCount = collectionUuids.length;
      const unionUuids = _.union(collectionUuids, uuids);
      // TODO: fix for checking new counts
      if (prevCount !== unionUuids.length) {
        setCollectionUuids(unionUuids);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [pageData]);

  useEffect(() => {
    /*
    if (collectionData) {
      const uuids = collectionData.map((c: any) => {
        return c.filter((collection: any) => collection.type === 'collection').map((collection: any) => collection.itemUuid);
      });
      const prevCount = collectionUuids.length;
      const unionUuids = _.union(collectionUuids, uuids);
      // TODO: fix for checking new counts
      if (prevCount !== unionUuids.length) {
        setCollectionUuids(unionUuids);
      }
    } */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [collectionData]);

  useEffect(() => {
    // if (ajaxData === null) {
    //   fetchExternal('https://google.com');
    //  setAjaxData(fetchedData ?? "failed to load");
    // }
  });
    /*
    if (ajaxData === null) {
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
      const targetUrl = 'https://google.com'

      fetch(proxyUrl + targetUrl)
        .then((res) => {
          return res.json();
        })
        .then(
          (result) => {
            console.log('fetch ajax');
            console.log(result);
            if (result.items) {
              setAjaxData(result.items);
            }
            if (!result.items) {
              setAjaxData('failed to load ajax');
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setAjaxError(error);
          },
        );
    }
  });
  */

  console.log('Pages pagesData');
  console.log(pageData);

  const headerTitle = pageData && pageData.name ? pageData.name : 'Page';
  const data = collectionData ? []
    /* collectionData.map((collection: any, index: number) => {
    return collection.data.map((d: any) => {
      const extra: any[] = [];
      extra.push(<div>owner: {d.ownerUuid}</div>);
      if (d.description) {
        extra.push(<div>description: {d.description}</div>);
      }

      return ({
        header: [<a href={`/pages/${d.uuid}`}>{d.name}</a>],
        extra,
      });
    });
  }) */ : [];

  console.log(data);

  const fetchExternal = async (url: string) => {
    const corsApiUrl = 'https://cors-anywhere.herokuapp.com/';
    const options = {
      method: 'GET',
      url,
      data: undefined,
    };
    // function doCORSRequest(options, printResult) {
    const xhr = new XMLHttpRequest();
    const proxyUrl = corsApiUrl + options.url;
    xhr.open(options.method, proxyUrl);
    // xhr.setRequestHeader("Origin", corsApiUrl);
    // xhr.setRequestHeader("Referer", proxyUrl);
    console.log('fetchedExternal open');
    // return result;
    xhr.onload = xhr.onerror = () => {
      console.log('fetchedExternal data');
      console.log(xhr.responseText);
      // const index = xhr.responseText.indexOf('<body');
      let updateData = xhr.responseText ? xhr.responseText.replaceAll('<body', `<script>function clickBody(event, element, x){ console.log(this); event.stopPropagation(); alert('Click is disabled. Clicked on tag ' + element.tagName); }</script><body onclick="clickBody(event, this, 'body')"`) : '';
      updateData = updateData.replaceAll('<div', `<div style="pointer-events:none" onclick="clickBody(event, this, 'div')"`);
      setAjaxData(updateData || '');
    };
    xhr.send(options.data);
    /*
      if (/^POST/i.test(options.method)) {
        x.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      }
      x.send(options.data);
    }
    */
  };

  /*
  // Bind event
  (function() {
    var urlField = document.getElementById('url');
    var dataField = document.getElementById('data');
    var outputField = document.getElementById('output');
    document.getElementById('get').onclick =
    document.getElementById('post').onclick = function(e) {
      e.preventDefault();
      doCORSRequest({
        method: this.id === 'post' ? 'POST' : 'GET',
        url: urlField.value,
        data: dataField.value
      }, function printResult(result) {
        outputField.value = result;
      });
    };
  })();
  if (typeof console === 'object') {
    console.log('// To test a local CORS Anywhere server, set cors_api_url. For example:');
    console.log('cors_api_url = "http://localhost:8080/"');
  }
  */
  if (ajaxData && document.getElementById('iframe')) {
    console.log('insertData');
    console.log(ajaxData);
    console.log(document.getElementById('iframe'));
    // document.getElementById('iframe')?.insertAdjacentHTML('afterbegin', '<div>Hello world</div>');
    console.log(document.getElementById('iframe'));
    // document.getElementById('iframe').set = "<html><body>Hello world</body></html>";
    // .contentWindow.document.write("<html><body>Hello world</body></html> ");
  }

  const handlePreview = async (file: any) => {
    if (!file || !file.name) {
      console.log(`file src is not provided to preview`);
      return;
    }
  };

  const storageItemsMap = {
    title: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        return <div key='user-title'><span style={{ fontWeight: 'bold', width: 100, float: 'left' }}>{mapping.name.label}:</span> {_.get(data, mapping.name.fields.filename)}</div>;
      },
      mapping: {
        storageInfo: { // itemUuid
          name: {
            label: 'File name',
            fields: {
              filename: 'filename',
            },
          },
        },
      },
    },
    content: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        return Object.keys(mapping).map((m: any) => {
          return <div key={`user-content-${m}`}><span style={{ fontWeight: 'bold', width: 100, float: 'left' }}>{mapping[m].label}:</span> {mapping[m].resolve ? mapping[m].resolve(_.get(data, mapping[m].field)) : _.get(data, mapping[m].field)}</div>;
        });
      },
      mapping: {
        storageInfo: {
          key: { label: 'File key', field: 'key' },
          url: { label: 'File url', field: 'url' },
          expirtation: { label: 'Expires on', field: 'expiration' },
          image: { label: 'Image', field: 'url', resolve: (url: any) => <img width={400} height={300} src={url} alt=''/> },
        },
      },
    },
  };

  return (
  <PageComponent headerTitle={headerTitle} content={
      <Content>
        {dataPages}
        {dataCollections}
        <input onChange={e => setUrl(e.target.value)} defaultValue={url}></input>
        <button onClick={() => fetchExternal(url)}>FETCH</button>
        <div>upload image</div>
        <LoadImageContainer
          key='starbucks-modal-load-image'
          handlePreview={handlePreview}
          handleCancelPreview={() => {}}
          onUpload={(file: any) => setUploadedFiles(uploadedFiles.concat(...[file]))}
          url="http://localhost:4000/storage/upload"
          type="image/*" />
        <div>Uploaded Files ({uploadedFiles.length})</div>
        <ViewCard customKey={'viewcard-uploaded-files'} style={{ maxWidth: 400 }} data={uploadedFiles} mapping={storageItemsMap} sourceName='storageInfo' />
        <div>before iframe</div>
        <ScrollableDiv onClick={(e: any) => {
          alert("Im an alert");
          e.preventDefault();
          e.stopPropagation();
        }}>
          <DisabledIFrame target="_blank" title='ajax data' height={400} width={400} srcDoc={ajaxData ? ajaxData : '<html><body>loading...</body></html>'}></DisabledIFrame>
        </ScrollableDiv>
        <div>after iframe</div>
        {pageData && false ? <ViewCollapsable sourceName='page' style={{ top: 15, maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }} data={data} mapping={null} /> : null}
        {collectionData ? <ViewCollapsable sourceName='collection' style={{ top: 15, maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }} data={data} mapping={null} /> : null}
      </Content>
    }
    />
  );
};

export default AdHoc;
