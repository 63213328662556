// Generic react component
// https://dev.to/janjakubnanista/a-peculiar-journey-to-a-generic-react-component-using-typescript-3cm8
import React, { useRef, useState } from 'react';
import uuid from 'uuid/v4';
import styled, { CSSProperties } from 'styled-components';
import { DragDropContext, Droppable, Draggable, DragStart, ResponderProvided, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import { TitleDiv } from '../draggable/DraggableContext';
import { CenteredDiv } from '../styled/CenteredDiv';
import Button from '../atomic/Button';
import { CloseCircleOutlined } from '@ant-design/icons';
import { removeArrayElement } from '../../helper/ArrayHelpers';
import ComponentProperties, { ComponentItem, DraggableComponentItem, DraggableComponentList } from '../pagebuilder/ComponentProperties';
import ComponentList, { ITEMS } from '../pagebuilder/ComponentList';
import DragHandle from '../pagebuilder/DragHandle';
import RenderPage from '../pagebuilder/RenderPage';
import { DraggableItem, DraggableList } from '../draggable/DraggableItemView';

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result: T[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
/**
 * Moves an item from one list to another list.
 */
function copy<T = any>(source: DraggableItem<T>[], destination: DraggableItem<T>[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation, childrenListUuid?: string) {
  const sourceClone: DraggableItem<T>[] = Array.from(source);
  const destClone: DraggableItem<T>[] = Array.from(destination);
  const item: DraggableItem<T> = sourceClone[droppableSource.index];
  item.childrenListUuid = childrenListUuid;
  item.containerUuid = droppableDestination.droppableId;

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  return destClone;
}

function move<T = any>(source: DraggableItem<T>[], destination: DraggableItem<T>[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation, items: { [uuid: string] : DraggableList<T> }): { [uuid: string]: DraggableList<T> } {
  const sourceClone: DraggableItem<T>[] = Array.from(source);
  const destClone: DraggableItem<T>[]  = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  removed.containerUuid = droppableDestination.droppableId;

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = { isRoot: items[droppableSource.droppableId].isRoot, items: sourceClone };
  result[droppableDestination.droppableId] = { isRoot: items[droppableDestination.droppableId].isRoot, items: destClone };

  return result;
}

const Content = styled.div`
  margin-right: 200px;
  width: 100%;
`;

type ItemProps = {
  isDragging?: boolean;
  isSelected?: boolean;
};

const DeleteIcon = styled(CloseCircleOutlined)`
  position: absolute;
  top: 5px;
  right: 10px;
  color: red;
`;

const Item = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0  0.5rem 0;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 1px ${(props: ItemProps) => (props.isDragging ? 'dashed #000' : 'solid #ddd')};
  ${(props: ItemProps) => props.isSelected ? 'border-color: blue;' : null};
 `;

const Clone = styled(Item)`
  border-color: red;
  transform: none !important;
`;
/*
const Clone = styled(Item)`
  + div {
    display: none!important;
    border-color: red;
    transform: none !important;
  }
`;
*/

type ListProps = {
  isDraggingOver: boolean;
  showBorder: boolean;
};

const List = styled.div`
  ${(props: ListProps) => props.showBorder ? `border: 1px ${props.isDraggingOver ? 'dashed #000' : 'solid #ddd'};
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0;` : ''};
  background: #fff;
  flex: 0 0 150px;
  font-family: sans-serif;
`;

const RightPanel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 200px;
`;

const RightPanelContent = styled.div`
  border: 1px solid darkgray;
  border-radius: 5px;
  background: #fff;
  margin-left: 10px;
`;

type ContainerProps = {
  droppable: boolean;
};
const Container = styled(List)`
  margin: 0.5rem 0rem 0rem;
  ${(props: ContainerProps) => props.droppable ? 'border-color: blue;' : ''}
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

/*
type DraggableItem = {
  id: string;
  title: string;
  containerUuid: string;
  enableChildren?: boolean; // only components that can have children components
  content?: string;
  properties?: CSSProperties;
  customProperties?: { [name: string]: string }
  childrenListUuid?: string;
}; */

/*
type DraggableList = {
  items: DraggableItem[],
  dragEnabled: boolean,
  isRoot?: boolean,
}; */

interface DroppableItemViewProps<T> {
  index: number;
  item: DraggableItem<T>;
  lists: { [uuid: string] : DraggableList<T> };
  dropComponentUuid?: string;
  editComponentUuid?: string;
  updateEditComponent: (uuid?: string, item?: DraggableItem<T>) => void;
  onDeleteComponent: (item: DraggableItem<T>) => void;
}

type DroppableItemViewState = {};

// const DroppableItemView: React.FC<DroppableItemViewProps> = (props: DroppableItemViewProps) => {
class DroppableItemView<T> extends React.Component<DroppableItemViewProps<T>, DroppableItemViewState> {

  constructor(props: DroppableItemViewProps<T>) {
    super(props);

    this.state = {};
  }

  render() {
    const { index, item, lists, dropComponentUuid, editComponentUuid, updateEditComponent, onDeleteComponent } = this.props;
    const children: DraggableList<T> | null = item.childrenListUuid ? lists[item.childrenListUuid] : null;

    return (
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}>
        {(provided, snapshot) => {
          // shared itemContent
          const itemContent =
            <div style={{ width: '100%' }}>
              <div style={{ color: item.id === editComponentUuid ? 'blue' : undefined, position: 'relative' }} onClick={() => {
                updateEditComponent(undefined, item);
              }} >{item.title}{item.value ? ` (${item.value})` : ''}{item.id === editComponentUuid ? <DeleteIcon onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                onDeleteComponent(item);
              }} /> : null}</div>
              {children && children.items && item.childrenListUuid ? // This is a container.
              <>
                {item.childrenListUuid === undefined
                ? (children.items.length > 0
                  ? <div style={{ paddingTop: item.childrenListUuid !== dropComponentUuid ? '10px' : undefined }} >{children.items.map(
                      (child, childIndex) => (
                        <DroppableItemView
                          index={childIndex}
                          item={child}
                          lists={lists}
                          updateEditComponent={(uuid?: string, updateItem?: DraggableItem<T>) => updateEditComponent(uuid, updateItem)}
                          dropComponentUuid={dropComponentUuid}
                          editComponentUuid={editComponentUuid}
                          onDeleteComponent={onDeleteComponent} />
                      ),
                    )}</div>
                  : null)
                : (children.items.length === 0 && item.childrenListUuid !== dropComponentUuid
                  ? null
                  : <Droppable key={item.childrenListUuid} droppableId={item.childrenListUuid as string} isDropDisabled={dropComponentUuid !== item.childrenListUuid}>
                    {(provided, snapshot) => (
                      <Container
                        showBorder={item.childrenListUuid === dropComponentUuid || item.id === editComponentUuid}
                        droppable={item.childrenListUuid === dropComponentUuid}
                        ref={provided.innerRef}
                        isDraggingOver={snapshot.isDraggingOver}>
                        {children.items.length > 0 ? children.items.map(
                            (child, childIndex) => (
                              <DroppableItemView
                                index={childIndex}
                                item={child}
                                lists={lists}
                                updateEditComponent={(uuid?: string, updateItem?: DraggableItem) => updateEditComponent(uuid, updateItem)}
                                dropComponentUuid={dropComponentUuid}
                                editComponentUuid={editComponentUuid}
                                onDeleteComponent={onDeleteComponent} />
                            ),
                          )
                          : !provided.placeholder && (
                            <Notice>Drop items here</Notice>
                          )}
                        {item.childrenListUuid === dropComponentUuid ? <Notice>Drop items here</Notice> : null}
                      </Container>
                    )}
                  </Droppable>)}
                </>
              : null}
            </div>;
          return (
            <>
              <Item
                key={`draggable-item-${item.id}`}
                isSelected={item.id === editComponentUuid }
                ref={provided.innerRef}
                {...provided.draggableProps}
                isDragging={
                  snapshot.isDragging
                }
                style={
                  provided
                    .draggableProps
                    .style
                }>
                <DragHandle
                  selected={(item.childrenListUuid !== undefined && dropComponentUuid === item.childrenListUuid)}
                  onClick={() => updateEditComponent(item.childrenListUuid, undefined)}
                  provided={provided} />
                {itemContent}
              </Item>
              {snapshot.isDragging && !snapshot.isDragging && (
                <Clone>{itemContent}</Clone>
              )}
            </>);
        }}
      </Draggable>);
  }
}

type DragCloneExampleProps = {

};

const DragCloneExample: React.FC<DragCloneExampleProps> = (props: DragCloneExampleProps) => {
  const [lists, setLists] = useState<{ [uuid: string] : DraggableComponentList }>({
    [uuid()]: { isRoot: true, dragEnabled: true, items: [] as DraggableComponentItem[] },
  });
  const [dropComponent, setDropComponent] = useState<string | undefined>(undefined);
  const [editComponentProperties, setEditComponentProperties] = useState<DraggableComponentItem | undefined>(undefined);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showPanel, setShowPanel] = useState<boolean>(true);
  const listsRef = useRef({} as { [uuid: string] : DraggableComponentList });
  listsRef.current = lists;

  const onDragStart = (initial: DragStart, provided: ResponderProvided) => {
    const currentList = listsRef.current;
    const sourceId = initial.source.droppableId;
    if (sourceId === 'ITEMS') {
      return;
    }
    const draggableItem = currentList[sourceId].items.find((item: DraggableComponentItem) => item.id === initial.draggableId);
    if (!draggableItem || !draggableItem.childrenListUuid) {
      if (!Object.keys(currentList).find((list: string) => !currentList[list].dragEnabled)) {
        return;
      }
    }
    setLists((prevList) => {
      const updateState = { ...currentList };
      Object.keys(updateState).forEach((list: string) => {
        updateState[list].dragEnabled = true;
      });
      let processLists: string[] = draggableItem && draggableItem.childrenListUuid ? [draggableItem.childrenListUuid] : []; // this.state[draggableItem.childrenListUuid].items.filter((item: DraggableItem) => item.childrenListUuid !== undefined).map((item: DraggableItem) => item.childrenListUuid as string);

      while (processLists.length > 0) {
        const newList: string[] = [];
        processLists.forEach((listId: string) => {
          updateState[listId].dragEnabled = false;
          const newLists: string[] = prevList[listId].items.filter((item: DraggableComponentItem) => item.childrenListUuid).map((item: DraggableComponentItem) => item.childrenListUuid as string);

          // const newLists: string[] = this.state[listId].items.filter((item: DraggableItem) => item.childrenListUuid).map((item: DraggableItem) => item.childrenListUuid as string);
          if (newLists.length > 0) {
            newList.push(...newLists);
          }
        });
        processLists = newList;
      }
      return updateState;
    });
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    setLists((prevList) => {
      const updateState = { ...prevList };
      Object.keys(updateState).forEach((listId: string) => updateState[listId].dragEnabled = true);

      switch (source.droppableId) {
        case destination.droppableId:
          updateState[destination.droppableId].items = reorder(
            prevList[source.droppableId].items,
            source.index,
            destination.index,
          );
          break;
        case 'ITEMS':
          const childrenListUuid = ITEMS[source.index].enableChildren ? uuid() : undefined;
          if (childrenListUuid) {
            updateState[childrenListUuid] = { isRoot: false, dragEnabled: true, items: [] as DraggableComponentItem[] };
          }
          updateState[destination.droppableId].items = copy<ComponentItem>(
            ITEMS,
            prevList[destination.droppableId].items,
            source,
            destination,
            childrenListUuid,
          );
          break;
        default:
          const moveResult = move(
            prevList[source.droppableId].items,
            prevList[destination.droppableId].items,
            source,
            destination,
            prevList,
          );
          updateState[source.droppableId].items = moveResult[source.droppableId].items;
          updateState[destination.droppableId].items = moveResult[destination.droppableId].items;
          break;
      }
      return updateState;
    });
  };

  const onUpdateItem = (value: string, properties: CSSProperties, customProperties: { [name: string] : string }) => {
    if (!editComponentProperties) {
      return;
    }
    setLists((prevList) => {
      const updateList = { ...prevList };
      const containerId = editComponentProperties.containerUuid;
      if (!containerId) {
        return prevList;
      }
      const container = updateList[containerId];
      const componentIndex = container.items.findIndex((component: DraggableComponentItem) => component.id === editComponentProperties.id);
      if (componentIndex < 0) {
        return prevList;
      }
      const updateContent = updateList[containerId].items[componentIndex].content ?? {};
      updateContent.properties = properties;
      updateContent.customProperties = customProperties;

      updateList[containerId].items[componentIndex].content = updateContent;
      updateList[containerId].items[componentIndex].value = value;
      return updateList;
    });
  };

  const deleteChildComponents = (item: DraggableComponentItem, updateList: { [uuid: string] : DraggableComponentList }) => {
    if (item.childrenListUuid) {
      const list = updateList[item.childrenListUuid];
      list.items.forEach((childItem: DraggableComponentItem) => deleteChildComponents(childItem, updateList));
      delete updateList[item.childrenListUuid];
    }
    if (editComponentProperties && editComponentProperties.id ===  item.id) {
      setEditComponentProperties(undefined);
    }
    if (dropComponent === item.id) {
      setDropComponent(undefined);
    }
  };

  const onDeleteComponent = (item: DraggableComponentItem) => {
    setLists((prevList) => {
      const updateList = { ...prevList };
      // Remove children lists and unset dropComponent and EditComponentProperties if a child has been selected
      deleteChildComponents(item, updateList);

      const itemIndex = updateList[item.containerUuid].items.findIndex((removeItem: DraggableComponentItem) => removeItem.id === item.id);
      updateList[item.containerUuid].items = removeArrayElement<DraggableComponentItem>(updateList[item.containerUuid].items, itemIndex);
      return updateList;
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {showPanel
        ? (<RightPanel>
            <RightPanelContent>
              {editComponentProperties
              ? (
                <ComponentProperties
                  key={editComponentProperties ? editComponentProperties.id : 'component-not-selected'}
                  item={editComponentProperties}
                  onUpdate={onUpdateItem}
                  onClose={() => setEditComponentProperties(undefined)}/>)
              : <ComponentList />
              }
            </RightPanelContent>
          </RightPanel>)
        : null}
        <div style={{ paddingRight: showPanel ? '200px' : undefined, width: '100%' }}>
          <Content>
            {Object.keys(lists).map((listId, i) => {
              return (
              !lists[listId].isRoot ? null :
              <>
              <Droppable key={listId} droppableId={listId} isDropDisabled={dropComponent !== undefined}>
                {(provided, snapshot) => (
                  <Container
                    showBorder={true}
                    droppable={dropComponent === undefined && editComponentProperties === undefined}
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}>
                    <TitleDiv style={{ color: dropComponent === undefined ? 'blue' : undefined, margin: '-0.5rem -0.5rem 0', marginBottom: '10px' }} onClick={() => setDropComponent(undefined)}>
                      <CenteredDiv>
                        <Button  style={{ width: '100px', float: 'left', background: showPreview ? 'lightyellow' : undefined  }} size='small' onClick={() => setShowPreview(!showPreview)}>Preview</Button>
                        Edit Layout
                        <Button style={{ width: '100px', float: 'right', marginRight: '10px', background: showPanel ? 'lightyellow' : undefined }} size='small' onClick={() => setShowPanel(!showPanel)}>Show Panel</Button>
                      </CenteredDiv>
                    </TitleDiv>
                    {lists[listId].items.length > 0
                      ? lists[listId].items.map(
                        (item: DraggableComponentItem, index: number) => (
                          <DroppableItemView key={`draggable-item-${index}`} index={index} item={item as DraggableItem} lists={lists as { [uuid: string] : DraggableList }} updateEditComponent={(uuid?: string, item?: DraggableItem) => {
                            setDropComponent(dropComponent === uuid ? undefined : uuid);
                            setEditComponentProperties((editComponentProperties === undefined || (editComponentProperties && item && editComponentProperties.id !== item.id)) ? item as DraggableComponentItem : undefined);
                          }} dropComponentUuid={dropComponent} editComponentUuid={editComponentProperties ? editComponentProperties.id : undefined} onDeleteComponent={onDeleteComponent}/>
                        ),
                      )
                      : null}
                    {dropComponent === undefined && !showPreview ? <Notice>Drop items here</Notice> : null}
                  </Container>
                )}
              </Droppable></>);
            })}
          </Content>
        </div>
      </DragDropContext>
      {showPreview ? <RenderPage lists={lists} component={editComponentProperties}/> : null}
    </div>
  );
};

export default DragCloneExample;

/*
<div style={{ paddingRight: '200px', width: '100%' }}>
          <Button onClick={addList}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
              />
            </svg>
            <ButtonText>Add List</ButtonText>
          </Button>
          <div>selectedItem: {editComponent ? editComponent : 'none'}</div>
*/
