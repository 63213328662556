import React, { useEffect, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import DraggableContext, { DraggableData } from "../draggable/DraggableContext";
import ViewEntry from "../views/Entry";
import { Dictionary } from "lodash";

type HookSampleProps = {
};

const HookSample: React.FC<HookSampleProps> = (props: HookSampleProps) => {
  const [hideContent, setHideContent] = useState<{ [key: string]: boolean }>({});
  const [items, setItems] = useState<DraggableData>({ items: {}, columns: { collection: { id: 'collection', title: 'items', itemIds: [] } }, columnOrder: ['collection'] });

  useEffect(() => {
    updateDataView();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    updateDataView();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [hideContent]);

  const onUpdateOrder = (updateOrder: Dictionary<string[]>) => {
    const updateItems = { ...items };
    Object.keys(updateOrder).forEach((orderedList: string) => {
      if (!updateItems.columns[orderedList]) {
        console.error(`Failed to update reorder, column missing for ${orderedList}`);
        return;
      }
      updateItems.columns[orderedList].itemIds = updateOrder[orderedList];
    });
    setItems(updateItems);
  };

  const updateDataView = () => {
    const updateItems: DraggableData = { ...items };
    ['1', '2', '3'].forEach((item: string) => {
      const cardKey = `toggle-${item}`;
      const collapseMenu = hideContent[cardKey]
        ? <CaretUpOutlined onClick={() => toggleShowContent(hideContent, cardKey)}/>
        : <CaretDownOutlined onClick={() => toggleShowContent(hideContent, cardKey)}/>;

      const header = <div>Header {item}</div>;
      const content = <div>Content {item}</div>;

      const newItem = <ViewEntry
        key={`${cardKey}`}
        header={header}
        leftWidth={'20px'}
        leftElement={collapseMenu}
        content={hideContent[cardKey] ? undefined : content}
        contentAlign
      />;
      updateItems.items[cardKey] = { id: cardKey, content: newItem };
      if (!updateItems.columns.collection.itemIds.find((id: string) => id === cardKey)) {
        updateItems.columns.collection.itemIds.push(cardKey);
      }
    });
    setItems(updateItems);
  };

  const toggleShowContent = (hideContent: any, itemKey: string) => {
    const newHideContent = { ...hideContent };
    newHideContent[itemKey] = (hideContent[itemKey] === undefined) ? true : !hideContent[itemKey];
    setHideContent(newHideContent);
  };

  return <DraggableContext initialData={items} onUpdateOrder={onUpdateOrder} />;
};

export default HookSample;
