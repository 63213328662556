import React, { useEffect, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined, MoreOutlined } from "@ant-design/icons";
import ViewEntry from "./Entry";
import DraggableContext, { DraggableData } from "../draggable/DraggableContext";
import { EmptyMenu, ExtraMenuType, LayoutDirection, ViewProps } from "./Helpers";
import { Divider, Dropdown, Menu, Space } from "antd";
import { Dictionary } from "lodash";
import { HiddenDiv } from "../styled/CssContent";
import WizardTooltip, { WizardTooltipProps } from "../wizard/WizardTooltip";

export enum ViewTutorialType {
  None = 0,
  LeftElement,
  RightElement,
  Header,
  Content,
}

type ViewDraggableProps = ViewProps & {
  style?: React.CSSProperties;
  customKey?: string;
  loading?: boolean;
  dragDisabled?: boolean;
  info?: JSX.Element;
  tutorial?: { type: ViewTutorialType, props: WizardTooltipProps, index?: number };
};

const ViewDraggable: React.FC<ViewDraggableProps> = (props: ViewDraggableProps) => {
  const { noDataMessage, customKey: key, data, mapping, sourceName, collapseable, extraMenu, dragDisabled } = props;
  const [hideContent, setHideContent] = useState<{ [key: string]: boolean }>({});
  const [items, setItems] = useState<DraggableData>({ items: {}, columns: {}, columnOrder: [] });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!data || props.loading) {
      if (!loading) {
        setLoading(true);
      }
      return;
    }
    if (loading) {
      setLoading(false);
    }

    updateDataView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [data, loading]);

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }
    updateDataView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [hideContent, props]);

  const getTutorialWrapper = (type: ViewTutorialType, element: JSX.Element | JSX.Element[], index: number): JSX.Element => {
    if (props.tutorial && props.tutorial.type === type && ((props.tutorial.index === undefined && index === 0) || props.tutorial.index === index)) {
      return <WizardTooltip key={`tutorial-${type}-${index}`} {...props.tutorial.props}>{element}</WizardTooltip>;
    }
    return <>{element}</>;
  };

  const updateDataView = () => {
    const updateItems: DraggableData = { items: {}, columns: { collection: { id: 'collection', title: 'items', itemIds: [] } }, columnOrder: ['collection'] };

    data.forEach((item: any, index: number) => {
      // console.log('ViewDraggable item');
      // console.log(item);

      if (!mapping.itemId) {
        console.error(`ViewDraggable itemId missing in mapping object`);
        return <></>;
      }
      const itemId = mapping.itemId(item);
      const cardKey = `${key}-${itemId}`;
      if (!mapping.header || !mapping.header.mapFn || !mapping.header.mapping || !mapping.header.mapping[sourceName]) {
        console.error(`ViewDraggable header mapping invalid for item: ${mapping.header}`);
        return <></>;
      }
      if (mapping.content && (!mapping.header.mapFn || !mapping.header.mapping || !mapping.header.mapping[sourceName])) {
        console.error(`ViewDraggable content mapping invalid for item ${mapping.content}`);
        return <></>;
      }
      const hide = props.tutorial && props.tutorial.type === ViewTutorialType.Content && ((props.tutorial.index === undefined && index === 0) || props.tutorial.index === index)
        ? false
        : (collapseable && (hideContent[cardKey] || hideContent[cardKey] === undefined));
      const header = mapping.header.mapFn(item, mapping.header.mapping[sourceName], hide);
      const content = mapping.content ? mapping.content.mapFn(item, mapping.content.mapping[sourceName], hide) : null;
      const menu: ExtraMenuType | undefined = extraMenu ? extraMenu(item) : (collapseable && content ? EmptyMenu : undefined);
      if (collapseable && menu) {
        // menu.items.push(content && hide ? <CaretDownOutlined onClick={() => toggleShowContent(cardKey)}/> : <CaretUpOutlined onClick={() => toggleShowContent(cardKey)}/>);
      }
      const isSelected = mapping.isSelected ? mapping.isSelected(item) : false;
      const hasError = mapping.hasError ? mapping.hasError(item) : false;

      const collapseMenu = !content
        ? <CaretUpOutlined style={{ opacity: '30%', fontSize: '16px', paddingTop: '0px' }}/>
        : (hide
          ? <CaretUpOutlined style={{ fontSize: '16px', paddingTop: '0px' }} onClick={() => toggleShowContent(hideContent, cardKey)}/>
          : <CaretDownOutlined style={{ fontSize: '16px', paddingTop: '0px' }} onClick={() => toggleShowContent(hideContent, cardKey)}/>);

      const showMenu = menu && menu.items.length > 0
        ? (menu.items.length > 2
          ? <Dropdown
            overlay={
              <Menu>
                {menu.items.map((item: JSX.Element, index: number) => (
                  <Menu.Item key={`edit-collection-${index}`}>
                    {item}
                  </Menu.Item>
                ))}
              </Menu>}>
              <MoreOutlined style={{ fontSize: '18px', marginTop: '4px' }} />
            </Dropdown>
          : (menu.direction === LayoutDirection.Horizontal
            ? <Space direction='horizontal'>{menu.items.map((item: JSX.Element, index: number) => <div key={`menu-item-${index}`} style={{ marginLeft: '2px' }}>{item}</div>)}</Space>
            : menu.items.map((item: JSX.Element, index: number) => <div key={`menu-item-${index}`} style={{ marginLeft: '2px' }}>{item}</div>)))
        : null;

      const leftElement = mapping.header.leftElement
        ? (collapseable
          ? <>
            <div>{mapping.header.leftElement(item)}</div>
            <div style={{ paddingTop: '2px' }}>{collapseMenu}</div>
          </>
          : mapping.header.leftElement(item))
        : (collapseable ? collapseMenu : undefined);

      const newItem = <ViewEntry
        key={`${cardKey}-${hide}`}
        header={getTutorialWrapper(ViewTutorialType.Header, header, index)}
        headerAlign="left"
        leftWidth={mapping.header.leftWidth ? mapping.header.leftWidth : (mapping.header.leftElement || collapseable ? '20px' : undefined)}
        leftElement={leftElement ? getTutorialWrapper(ViewTutorialType.LeftElement, leftElement, index) : leftElement}
        rightWidth={mapping.header.rightWidth ? mapping.header.rightWidth : ((menu && menu.items.length > 0) ? '40px' : undefined)}
        rightElement={<div style={{ marginRight: '5px' }}>{showMenu ? getTutorialWrapper(ViewTutorialType.RightElement, showMenu, index) : showMenu}</div>}
        content={hide ? <HiddenDiv>{content}</HiddenDiv> : <><Divider style={{ margin: 1 }}/>{content ? getTutorialWrapper(ViewTutorialType.Content, content, index) : content}</>}
        contentAlign
        selected={isSelected}
        hasError={hasError}
      />;
      updateItems.items[itemId] = { id: itemId, content: newItem };
      if (!updateItems.columns.collection.itemIds.find((id: string) => id === itemId)) {
        updateItems.columns.collection.itemIds.push(itemId);
      }
    });
    setItems(updateItems);
  };

  const onUpdateOrder = (updateOrder: Dictionary<string[]>) => {
    const updateItems = { ...items };
    Object.keys(updateOrder).forEach((orderedList: string) => {
      if (!updateItems.columns[orderedList]) {
        console.error(`Failed to update reorder, column missing for ${orderedList}`);
        return;
      }
      updateItems.columns[orderedList].itemIds = updateOrder[orderedList];
    });
    setItems(updateItems);
  };

  const toggleShowContent = (hideContent: any, itemKey: string) => {
    const newHideContent = { ...hideContent };
    newHideContent[itemKey] = (hideContent[itemKey] === undefined) ? false : !hideContent[itemKey];
    setHideContent(newHideContent);
  };

  if (data && data.length !== Object.keys(items.items).length && !loading) {
    console.error(`ViewDraggable: data length (${data.length}) doesn't match items length (${Object.keys(items.items).length})`);
  }

  return !data || data.length === 0
    ? <div>{noDataMessage}</div>
    : (data.length !== Object.keys(items.items).length
      ? null
      : <div style={props.style} key={key}><DraggableContext info={props.info} customKey={key} dragDisabled={dragDisabled} initialData={items} onUpdateOrder={onUpdateOrder}/></div>);
};

export default ViewDraggable;
