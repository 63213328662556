import gql from 'graphql-tag';
import  * as fragments from '../fragments';

export default gql`
  query GetFriends {
    getFriends {
      ...Friend
    }
  }
  ${fragments.Friend}
`;
