import React from 'react';
import AuthContext from '../context/AuthContext';
import config from '../Config';
import LoginContainer from '../containers/auth/LoginContainer';
import Page from '../components/layout/Page';
import { CreateRegisterUserDivWrapper } from '../components/styled/EmailConfirmFormWrapper';

type NormalLoginFormProps = {
};

type NormalLoginFormState = {
  isLoading: boolean;
  errorMessage: string | null;
};

class NormalLoginForm extends React.Component<NormalLoginFormProps, NormalLoginFormState> {
  static contextType = AuthContext;

  constructor(props: NormalLoginFormProps) {
    super(props);

    this.state = {
      isLoading: false,
      errorMessage: null,
    };
  }

  handleGoogle = async (response: any) => {
    /* const { id_token, expires_at } = response.getAuthResponse();
    const profile = response.getBasicProfile();
    let user = {
        email: profile.getEmail(),
        name: profile.getName(),
    };

    const credentials = await Auth.federatedSignIn(
        'google',
        { token: id_token, expires_at },
        user,
    );

    console.log('Logged in with Google!', id_token);
    console.log('Logged in with Google!', credentials); */
    // this.context.login(user.signInUserSession.idToken);
    // this.props.history.push('/');

    const domain = 'swing2places.auth.us-east-1.amazoncognito.com ';
    const redirect = config.OAUTH_REDIRECT_URL;
    const type = 'token';
    const id = '5op7n46cjs90r8o3tf72glj665';

    const url = `https://${domain}/oauth2/authorize?redirect_uri=${redirect}&response_type=${type}&client_id=${id}`; // &identity_provider=Google`;
    window.location.assign(url);
  }

  render() {
    return (
      <Page content={
        <CreateRegisterUserDivWrapper>
            <LoginContainer />
        </CreateRegisterUserDivWrapper>
      }/>
    );
  }
}

// const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);
export default NormalLoginForm;
