import React, { FC, useEffect, useState } from 'react';
import RecordModal, { ModalFieldEntry, ModalFields, RecordModalAction } from '../../modal/RecordModal';
import { getDataEntryTypeToRecordType, RecordType } from '../../table/DataTypes';
import { ActionType, AddDataEntryMutation, CollectionType, DataCollectionData, DataEntry, DataEntryDataInput, DataEntryInput, DataEntryType, DataObject, DataObjectEntry, UpdateItemInput } from '../../../graphql/generated/graphql';
import { errorMessage } from '../../actions/ErrorMessage';
import AddEntry from '../../../containers/api/Entry/AddEntry';
import { PlusCircleTwoTone } from '@ant-design/icons';
import LoadingOverlay from '../../atomic/LoadingOverlay';
import CreateCollection from '../Collection/CreateCollection';
import { getMockedEntryInputToDataEntry } from './EntryFields';
import Button from '../../atomic/Button';
import FormField from '../../form/FormField';
import { Space } from 'antd';
import { errorNotification } from '../../actions/Notification';
import { TagColor, TagItem } from '../../atomic/Tag';
import { Dictionary } from 'lodash';

export enum ObjectType {
  Task = '11111111-1111-1111-1111-111111111111',
  Item = '11111111-1111-1111-1111-111111111112',
  Expense = '11111111-1111-1111-1111-111111111113',
}

type CustomEntryField = { entryUuid: string, name: string, fieldType?: DataEntryType };

export const GlobalObjectFields = '00000000-0000-0000-0000-000000000000';
const GlobalObjectOwner = '00000000-0000-0000-0000-000000000000';

export function getGlobalFields(globalObjectFields: DataObject): { [uuid: string]: DataObjectEntry } {
  if (globalObjectFields.uuid !== GlobalObjectFields) {
    console.error('Invalid global object provided');
  }
  const globalFields: { [uuid: string]: DataObjectEntry } = {};
  if (globalObjectFields.entries) {
    globalObjectFields.entries.forEach((field: DataObjectEntry) => globalFields[field.uuid] = field);
  }
  return globalFields;
}

export const ExludeObjectTypes = [
  GlobalObjectFields,
];

type CreateEntryProps = {
  action: RecordModalAction;
  objects: Dictionary<DataObject>;
  collections: DataCollectionData[];
  initialCollection?: string;
  initialObject?: DataObject;
  initialData?: DataEntry; // optional in case cloning existent entry or using QR Code
  mocked?: boolean;
  onSuccess?: (updatedDataEntry: DataEntry) => any;
  onCancel?: () => any;
  onUpdateCollections?: (updatedCollections: DataCollectionData) => any;
  onError?: (error: string) => any;
};

const CreateEntry: FC<CreateEntryProps> = (props: CreateEntryProps) => {
  const { action, initialCollection, initialObject: initialObjectType, initialData, mocked, onSuccess, onCancel, onError } = props;
  const [objects] = useState<DataObject[]>(Object.keys(props.objects).map((obj: string) => props.objects[obj]).filter((obj: DataObject) => !ExludeObjectTypes.find((exclude: string) => exclude === obj.uuid)));
  const [modalAction, setModalAction] = useState<RecordModalAction>(action);
  const [collections, setCollections] = useState<DataCollectionData[]>(props.collections);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [executeUpdate, setExecuteUpdate] = useState<JSX.Element | null>(null);
  const [selectedObject, setSelectedObject] = useState<DataObject | null>(objects.find((obj: DataObject) => initialObjectType ? ObjectType[obj.uuid] = initialObjectType : obj.uuid === ObjectType.Task) ?? null);
  const [lastChanged, setLastChanged] = useState<string | undefined>(undefined);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
  const [addField, setAddField] = useState<CustomEntryField | undefined>(undefined);
  const [customFields, setCustomFields] = useState<CustomEntryField[]>([]);

  useEffect(() => {
    if (action !== modalAction) {
      setModalAction(action);
    }
    const basicItem = objects ? objects.find((obj: DataObject) => obj.name === 'Skip this for now') : null;
    if (objects && basicItem && (selectedObject === null || selectedObject.uuid !== basicItem.uuid)) {
      setSelectedObject(basicItem);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const GlobalFieldTypes = props.objects[GlobalObjectFields];
  const hideFields = selectedObject && selectedObject.hideFields ? selectedObject.hideFields as string[] : [];
  const hideDateDue = hideFields.find((hide: string) => hide === 'dateDue') !== undefined;
  const hideDateCompleted = hideFields.find((hide: string) => hide === 'dateCompleted') !== undefined;

  const recordDataValues: ModalFields = {
    pages: [ /*
      {
        entries: {
          ObjectType: { type: RecordType.Dropdown, value: selectedObject ? selectedObject.uuid : undefined, values: objects.map((obj: DataObject) => ({ label: obj.name, value: obj.uuid })), onUpdate: (record: any) => {
            setSelectedObject(objects ? (objects.find((obj: DataObject) => obj.uuid === record) ?? null) : null);
          }},
        },
      }, */
      {
        entries: Object.assign(
          {},
          {
            /* Remove selecting Data Object Type. TODO: Fix as part being able to add additional entry fields */
            ObjectType: { fieldName: 'Entry type', required: true, placeholder: 'Select entry type', disabled: false, type: RecordType.Dropdown, value: selectedObject ? selectedObject.name : undefined, values: objects.map((obj: DataObject) => ({ label: obj.name, value: obj.uuid, info: obj.description })), onUpdate: (record: any) => {
              setSelectedObject(objects ? (objects.find((obj: DataObject) => obj.uuid === record) ?? null) : null);
              setLastChanged(new Date().toUTCString());
            }},
            Title: { placeholder: 'Enter entry name', type: RecordType.Text, value: initialData ? initialData.title : undefined, maxLength: 255, autoFocus: true },
            Description: { placeholder: 'Enter entry description', type: RecordType.Text, value: initialData ? initialData.description : undefined },
            Collections: {
              type: collections.length === 0 ? RecordType.TextValue : RecordType.MultiSelect,
              // value: collections.length === 0 ? 'There are no Collections in your account' : (initialCollection ? [initialCollection] : []), values: collections.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)).map((collection: DataCollectionData) => ({ label: collection.name, value: collection.uuid })),
              value: collections.length === 0 ? 'There are no Collections in your account' : (initialCollection ? [initialCollection] : []),
              values: collections.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)).map((collection: DataCollectionData) => ({ uuid: collection.uuid, name: collection.name, color: TagColor.Blue } as TagItem)),
              fieldName: <span>Collections: <CreateCollection
                mocked
                key={lastChanged}
                icon={<PlusCircleTwoTone style={{ marginLeft: '5px' }} />}
                onTrigger={() => {
                  setIsLoading(true);
                  setLoadingMessage('Creating new Collection ...');
                }}
                onSuccess={(data?: DataCollectionData, loading?: boolean, error?: string) => {
                  if (data) {
                    setCollections(prevCollections => [...prevCollections, data]);
                    setLastChanged(new Date().toUTCString());
                    if (props.onUpdateCollections) {
                      props.onUpdateCollections(data);
                    }
                  }
                  setIsLoading(false);
                  setLoadingMessage(undefined);
                }}
                onCancel={() => {
                  setIsLoading(false);
                  setLoadingMessage(undefined);
                }}
                ordinal={collections.length + 1} />
            </span>,
            },
          },
          (hideDateDue ? {} : { DateDue: { placeholder: 'Enter date due', type: RecordType.Date, fieldName: 'Date Due:', value: initialData ? initialData.dateDue : undefined } }),
          (hideDateCompleted ? {} : { DateCompleted: { placeholder: 'Enter date due', type: RecordType.Date, fieldName: 'Date Completed:', value: initialData ? initialData.dateCompleted : undefined } }),
          ...(selectedObject && selectedObject.entries
            ? selectedObject.entries.map((obj: DataObjectEntry) => ({ [obj.name]: { type: getDataEntryTypeToRecordType(obj.type)?.recordType, inputType: getDataEntryTypeToRecordType(obj.type)?.inputType, info: obj.description, reference: obj.uuid } }))
            : []),
          ...(GlobalFieldTypes && GlobalFieldTypes.entries
            ? (
              customFields.map((customField: CustomEntryField) => {
                const objectFields = getGlobalFields(GlobalFieldTypes); // GlobalFieldTypes!.entries!.find((field: DataObjectEntry) => field.uuid === obj.fieldType);
                const fieldEntry = objectFields[customField.entryUuid];
                if (!fieldEntry || !customField.fieldType || !customField.name) {
                  return null;
                }
                const type = getDataEntryTypeToRecordType(customField.fieldType);
                if (!type) {
                  return null;
                }
                return ({ [customField.name]: { type: type.recordType, inputType: type.inputType, info: fieldEntry.description, fieldName: customField.name, reference: fieldEntry.uuid } });
              }))
            : []),
          (!addField
            ? {
              TriggerAddField: { type: RecordType.Render, value: <Space style={{ top: '10px ' }}>
                <Button size='small' style={{ border: addField ? undefined : '1px dashed gray', color: 'gray' }} onClick={() => {
                  setAddField((prevAddField) => {
                    return { entryUuid: '', name: '', fieldType: undefined };
                  });
                  onModalFieldChanged();
                }}>Add Field</Button>
              </Space> },
            }
            : {
              AddField: { type: RecordType.Render, value:
                <Space direction='horizontal' style={{ top: '10px ' }}>
                  <FormField
                    fieldData={{
                      type: RecordType.Dropdown,
                      placeholder: 'Select field type',
                      values: GlobalFieldTypes && GlobalFieldTypes.entries ? GlobalFieldTypes.entries.map((field: DataObjectEntry) => ({ label: field.name, value: field.uuid })) : undefined,
                    }}
                    fieldKey='new-field-type'
                    includeName={false}
                    onFieldChange={(newValue: any, fieldKey: string) => setAddField((prevAddField) => {
                      if (!GlobalFieldTypes) {
                        console.error('GlobalFieldTypes missing');
                        return prevAddField;
                      }
                      const globalFields = getGlobalFields(GlobalFieldTypes);
                      return { entryUuid: newValue, fieldType: globalFields[newValue].type, name: prevAddField === undefined ? '' : prevAddField.name };
                    })}
                  />
                  <FormField
                    fieldData={{
                      type: RecordType.Input,
                      placeholder: 'Enter field name',
                      values: GlobalFieldTypes && GlobalFieldTypes.entries ? GlobalFieldTypes.entries.map((field: DataObjectEntry) => ({ label: field.name, value: field.uuid })) : undefined,
                    }}
                    fieldKey='new-field-name'
                    includeName={false}
                    onFieldChange={(newValue: any, fieldKey: string) => setAddField((prevAddField) => {
                      return { entryUuid: prevAddField === undefined ? '' : prevAddField.entryUuid, fieldType: prevAddField === undefined ? undefined : prevAddField.fieldType, name: newValue };
                    })}                  />
                  <Button key={`add-new-field-${lastChanged}`} size='small' disabled={false} onClick={() => {
                    setAddField((prevAddField) => {
                      if (prevAddField === undefined || prevAddField.fieldType === undefined || !prevAddField.name || prevAddField.name === '') {
                        errorNotification('Please select field type and field name.');
                        return prevAddField;
                      }
                      const updateCustomFields = [...customFields];
                      updateCustomFields.push({ ...prevAddField });
                      setCustomFields(updateCustomFields);
                      return undefined;
                    });
                    onModalFieldChanged();
                  }}>Add Field</Button>
                  <Button key='cancel-new-field' size='small' onClick={() => {
                    setAddField(undefined);
                    onModalFieldChanged();
                  }}>Cancel</Button>
                </Space>},
            }),
        ),
      },
    ],
  };

  const onModalFieldChanged = () => {
    setLastChanged(new Date().toUTCString());
  };

  const onCreateEntry = async (record: any) => {
    if (!selectedObject) {
      errorMessage('Please select data entry Type');
      return;
    }
    if (!record.pages[0].entries.Title.value) {
      errorMessage('Please enter data entry Name');
      return;
    }
    const updateEntryCollectionUuids: string[] = collections.length === 0 ? [] : record.pages[0].entries.Collections.value;

    const updateEntryCollections: UpdateItemInput[] =
      updateEntryCollectionUuids.map((newlyCollectionUuid: string) => {
        const collection = collections.find((collection: DataCollectionData) => collection.uuid === newlyCollectionUuid);
        const itemOrdinal = collection ? collection.entryUuids.length + 1 : -1;
        return {
          itemType: CollectionType.Item,
          action: ActionType.Add,
          id: newlyCollectionUuid,
          ordinal: itemOrdinal, /* TODO fix ordinal */
        };
      });

    const data: DataEntryDataInput[] = [];
    Object.keys(record.pages[0].entries).forEach((fieldKey: string) => {
      const fieldData: ModalFieldEntry = record.pages[0].entries[fieldKey];
      if (fieldData.reference && fieldData.updated) {
        data.push({ entryUuid: fieldData.reference, data: fieldData.value, customName: fieldData.fieldName as string | undefined });
      }
    });

    const dataEntryInput: DataEntryInput = {
      objectUuid: selectedObject.uuid,
      dataParent: updateEntryCollections,
      title: record.pages[0].entries.Title.value,
      data,
      description: record.pages[0].entries.Description.value,
      dateDue: record.pages[0].entries.DateDue && record.pages[0].entries.DateDue.value ? { date: record.pages[0].entries.DateDue.value } : null,
      dateCompleted: record.pages[0].entries.DateCompleted && record.pages[0].entries.DateCompleted.value ? { date: record.pages[0].entries.DateCompleted.value } : null,
    };

    if (mocked) {
      setError(null);
      setIsLoading(false);
      setModalAction(RecordModalAction.None);
      const dataEntryResult: DataEntry = getMockedEntryInputToDataEntry(dataEntryInput);
      dataEntryResult.active = true;
      if (onSuccess) {
        onSuccess(dataEntryResult);
      }
      return;
    }

    // const updateQuery = <EditCollection  collectionInput={collectionInput} onUpdate={(data?: UpdateDataCollectionMutation, loading?: boolean, error?: any) => {
    const updateQuery = <AddEntry entryInput={dataEntryInput} onUpdate={(data?: AddDataEntryMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        if (onError) {
          onError(error);
        }
        return;
      }

      if (data && data.addDataEntry) {
        setError(null);
        setIsLoading(false);
        setModalAction(RecordModalAction.None);
        if (onSuccess) {
          onSuccess(data.addDataEntry as DataEntry);
        }
      }
    }} />;
    setExecuteUpdate(updateQuery);
  };

  return <>
    {executeUpdate}
    {modalAction !== RecordModalAction.None && (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={loadingMessage ? loadingMessage : `Creating new Entry ...`}
        >
        <RecordModal
          loadingMessage={loadingMessage}
          hideButtons={isLoading}
          lastChanged={lastChanged}
          key={'add-entry-modal'}
          data={recordDataValues}
          action={modalAction}
          title={`entry`}
          onCancel={() => {
            setError(null);
            setModalAction(RecordModalAction.None);
            if (onCancel) {
              onCancel();
            }
          }}
          onSubmit={onCreateEntry}
          isLoading={isLoading}
          error={error ? error.message : undefined}
        />
      </LoadingOverlay>
    )}
  </>;
};

export default CreateEntry;
