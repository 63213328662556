import React, { useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import uuid from 'uuid/v4';
import { Stock } from '../../StockTypes';
import { RobinhoodInstrument, RobinhoodQuote, ROBINHOOD_URLS } from './ImportRobinhoodTypes';
import ImportRobinhoodFetch from './ImportRobinhoodFetch';
import { errorNotification, infoNotification } from '../../../actions/Notification';

type FetchByInstId = {
  id: string;
  newIds: string[];
  fetch: JSX.Element;
};

type ImportRobinhoodInstrumentsProps = {
  bearerToken: string;
  stocks: Dictionary<Stock>;
  newInstrumentIds?: string[];
  onUpdateStocks: (updateStocks: Dictionary<Stock>) => void;
  onNotAuthenticated: () => void;
};

const ImportRobinhoodInstruments: React.FC<ImportRobinhoodInstrumentsProps> = (props: ImportRobinhoodInstrumentsProps) => {
  const { bearerToken, stocks, newInstrumentIds, onUpdateStocks, onNotAuthenticated } = props;
  // const [instrumentUrls, setInstrumentUrls] = useState<string[]>([]);
  const [fetchSymbols, setFetchSymbols] = useState<Dictionary<Stock>>(stocks);
  const [updateStock, setUpdateStock] = useState<Dictionary<Stock>>({});
  const [fetchInstruments, setFetchInstruments] = useState<JSX.Element[]>([]);
  const [fetchByInstId, setFetchByInstId] = useState<FetchByInstId[]>([]);

  useEffect(() => {
    if (Object.keys(fetchSymbols).length === 0) {
      console.log(`update stocks ${Object.keys(updateStock).join(', ')}`);
      onUpdateStocks(updateStock);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [fetchSymbols, updateStock]);

  /**
   * Initialize fetch query for new instrument ids
   */
  useEffect(() => {
    if (newInstrumentIds && Object.keys(newInstrumentIds).length > 0) {
      setFetchByInstId((prev) => {
        const newIds = newInstrumentIds.filter((newId: string) => {
          return !prev.find((prevId: FetchByInstId) => prevId.newIds.includes(newId));
        });
        if (newIds.length === 0) {
          return prev;
        }
        const updatePrev = { ...prev };

        const fetchId = uuid();
        const fetch = <ImportRobinhoodFetch
          key={`cryto-instrument-${newIds.join('-')}`}
          customKey={`fetch-instrument-${newIds.join('-')}`}
          url={ROBINHOOD_URLS.INSTRUMENT_BY_IDS(newIds)}
          bearerToken={bearerToken}
          onUpdate={(data?: string, loading?: boolean, error?: any) => onUpdateFetchInstruments(fetchId, newIds, data, loading, error)}
        />;
        updatePrev.push({ id: fetchId, newIds, fetch });
        return updatePrev;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [bearerToken, newInstrumentIds]);

  /**
   * Initialize fetch query for passed in symbols to get instrument ids.
   */
  useEffect(() => {
    const initFetchInstruments: JSX.Element[] = [];
    const cryptoStocks: string[] = [];
    Object.keys(stocks).forEach((symbol: string) => {
      const stock = stocks[symbol];
      if (stock.isCrypto) {
        cryptoStocks.push(symbol);
        return;
      }
      initFetchInstruments.push(<ImportRobinhoodFetch
        key={`stock-instrument-${stock.ticker}`}
        customKey={stock.ticker}
        url={ROBINHOOD_URLS.INSTRUMENT_BY_SYMBOL(stock.ticker)}
        bearerToken={bearerToken}
        onUpdate={(data?: string, loading?: boolean, error?: any) => {
          if (data) {
            if (data === '{"detail":"Incorrect authentication credentials."}') {
              onNotAuthenticated();
            }
            const updateStocks: Dictionary<Stock> = {};
            const obj = JSON.parse(data);
            if (!obj.results || obj.results.length === 0) {
              console.log(`No instrument object returned for symbol ${stock.ticker}`);
              if (stocks[stock.ticker]) {
                const stockObj: Stock = stocks[stock.ticker];
                const now = new Date();
                stockObj.robinhoodId = `DNE-${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
                updateStocks[stockObj.ticker] = stockObj;
              }
            }
            else {
              const instObjs = obj.results as RobinhoodInstrument[];
              instObjs.forEach((inst: RobinhoodInstrument) => {
                const instSymbol = inst.symbol;
                const stockObj: Stock = stocks[stock.ticker] ?? {
                  ticker: instSymbol,
                  name: inst.name,
                  description: '',
                  isCrypto: false,
                  cusip: undefined,
                  isin: undefined,
                  sedol: undefined,
                  inactiveStock: inst.state === 'inactive' ? true : undefined,
                };
                if (stockObj.name.toLowerCase() !== inst.name.toLowerCase() && !stockObj.name.toLowerCase().includes(inst.simple_name.toLowerCase())) {
                  console.log(`Instrument name (${inst.name}) does not match stock name ${stockObj.name} for ${stock.ticker}`);
                  return;
                }
                if (inst.state === 'inactive') {
                  stockObj.ticker = stockObj.ticker === '' ? instSymbol :  stockObj.ticker;
                  stockObj.inactiveStock = true;
                }
                if (stockObj.name === '') {
                  stockObj.name = inst.name;
                }
                stockObj.robinhoodId = inst.id;
                if (updateStocks[stockObj.ticker]) {

                }
                else {
                  updateStocks[stockObj.ticker] = stockObj;
                }
              });
            }
            setUpdateStock((prev: Dictionary<Stock>) => {
              const updateUpdateStock = { ...prev };
              Object.keys(updateStocks).forEach((symbol: string) => {
                updateUpdateStock[symbol] = updateStocks[symbol];
              });
              return updateUpdateStock;
            });
            setFetchSymbols((prev: Dictionary<Stock>) => {
              const updateFetchStocks = { ...prev };
              delete updateFetchStocks[stock.ticker];
              return updateFetchStocks;
            });
          }
        }}
      />);
    });
    if (cryptoStocks.length > 0) {
      initFetchInstruments.push(<ImportRobinhoodFetch
        key={`cryto-instrument-${cryptoStocks.join('-')}`}
        customKey={cryptoStocks.join('-')}
        url={ROBINHOOD_URLS.CRYPTO_QUOTES(cryptoStocks)}
        bearerToken={bearerToken}
        onUpdate={(data?: string, loading?: boolean, error?: any) => {
          if (data || data === '') {
            if (data === '{"detail":"Incorrect authentication credentials."}') {
              onNotAuthenticated();
            }
            const updateStocks: Dictionary<Stock> = {};
            const obj = JSON.parse(data);
            if (!obj || obj.results.length === 0) {
              errorNotification(`No instrument data returned for ${cryptoStocks.join(', ')}`);
              return;
            }
            const quoteResults: RobinhoodQuote[] = obj.results.filter((quote: RobinhoodQuote | null) => quote);
            quoteResults.forEach((crypto: RobinhoodQuote) => {
              const symbol = crypto.symbol.toUpperCase();
              const cryptoObj: Stock = stocks[symbol];
              if (!cryptoObj || cryptoObj.robinhoodId) {
                return;
              }
              cryptoObj.robinhoodId = crypto.id;
              if (updateStocks[symbol]) {

              }
              else {
                updateStocks[symbol] = cryptoObj;
              }
            });
            cryptoStocks.forEach((crypto: string) => {
              if (updateStocks[crypto]) {
                return;
              }
              errorNotification(`No quote data returned for ${crypto}`);
              const stockObj: Stock = stocks[crypto];
              const now = new Date();
              stockObj.robinhoodId = `DNE-${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
              updateStocks[stockObj.ticker] = stockObj;
            });
            setUpdateStock((prev: Dictionary<Stock>) => {
              const updateUpdateStock = { ...prev };
              Object.keys(updateStocks).forEach((symbol: string) => {
                updateUpdateStock[symbol] = updateStocks[symbol];
              });
              return updateUpdateStock;
            });
            setFetchSymbols((prev: Dictionary<Stock>) => {
              const updateFetchStocks = { ...prev };
              cryptoStocks.forEach((crypto: string) => {
                delete updateFetchStocks[crypto];
              });
              return updateFetchStocks;
            });
          }
        }}
      />);
    }
    setFetchInstruments(initFetchInstruments);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [bearerToken, stocks]);

  /**
   *  Parse instrument data returned by Robinhood
   * @param newIds instrument ids that were fetched
   * @param data robinhood response
   * @param loading data being fetched
   * @param error error returned by api
   * @returns
   */
  const onUpdateFetchInstruments = (id: string, newIds: string[], data?: string, loading?: boolean, error?: any) => {
    if (error) {
      errorNotification(<div>`Error occurred fetching ${newIds.join(', ')}. <span onClick={() => errorNotification(JSON.stringify(error))} >See more details.</span></div>);
    }
    if (data || data === '') {
      if (data === '{"detail":"Incorrect authentication credentials."}') {
        onNotAuthenticated();
      }
      const updateStocks: Dictionary<Stock> = {};
      const obj = JSON.parse(data);
      if (!obj || !obj.results || obj.results.length === 0) {
        errorNotification(`No instrument data returned for ${newIds.join(', ')}`);
        return;
      }
      const instObjs = obj.results as RobinhoodInstrument[];
      instObjs.forEach((inst: RobinhoodInstrument) => {
        if (updateStocks[inst.id]) {
          return;
        }
        const instSymbol = inst.symbol;
        if (stocks[inst.symbol]) {
          infoNotification(`Duplicate symbol ${inst.symbol}, already imported: ${stocks[inst.symbol].name}, new: ${inst.simple_name}`);
          return;
        }
        const stockObj: Stock = {
          ticker: instSymbol.toUpperCase(),
          name: inst.simple_name,
          description: '',
          isCrypto: false,
          cusip: undefined,
          isin: undefined,
          sedol: undefined,
          inactiveStock: inst.state === 'inactive' ? true : undefined,
          robinhoodId: inst.id,
        };
        updateStocks[inst.id] = (stockObj);
      });
      if (Object.keys(updateStocks).length > 0) {
        onUpdateStocks(updateStocks);
      }
      setFetchByInstId((prev: FetchByInstId[]) => {
        return prev.filter((fetch: FetchByInstId) => fetch.id !== id);
      });
    }
  };

  return (
    <>
      {fetchInstruments}
      {fetchByInstId}
    </>);
};

export default ImportRobinhoodInstruments;
