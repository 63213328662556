import { ApolloClient, HttpLink, ApolloLink, Observable } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
// import { ApolloLink, Observable } from 'apollo-link';
// apollo-cache-inmemory apollo-link-error
// import { toIdValue } from 'apollo-utilities';
import {  } from 'apollo-link-context';
import { Auth } from 'aws-amplify';
import config from '../Config';
import Log from '../Log';
import { AuthProvider } from '../context/AuthContext';
import * as LocalStorage from '../data/LocalStorage';


export const getToken = async () => {
  let sessionToken = LocalStorage.getGoogleAuth();
  const authProvider = LocalStorage.getAuthProvider();

  if (sessionToken) {
    return sessionToken;
  }
  if (authProvider === AuthProvider.Cognito) {
    const session = await Auth.currentSession();
    sessionToken = session.getIdToken().getJwtToken();
    return sessionToken;
  }
  if (authProvider === AuthProvider.Google) {

  }
  return sessionToken;
};

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const session = await getToken(); // Auth.currentSession();
  // return the headers to the context so httpLink can read them
  return session ? {
    headers: {
      ...headers,
      authorization: session, // .getIdToken().getJwtToken(),
    },
  } : headers;
});

let clientInitialized = false;
export function getClient(clientId: string, getLatestToken: () => Promise<string>) {
  if (clientInitialized) {
    throw new Error('Apollo client was already retrieved.');
  }
  clientInitialized = true;

  Log.error(`Public client  URL: ${config.API_URL}-${config.ENDPOINT_PUBLIC}`, 'apollo');

  const request = async (operation: any) => {
    const latestToken = await getLatestToken();
    console.log('graphql', operation, latestToken.substr(0, 10));
    if (latestToken !== '') {
      operation.setContext({
        headers: {
          authorization: latestToken,
        },
      });
    }
  };

  const requestLink = new ApolloLink((operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward!(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
  );

  const cache: any = new InMemoryCache({
    /*
    cacheRedirects: {
      Query: {
        getStarbucksCode: (_, { id }) => toIdValue(cache.config.dataIdFromObject({ id, __typename: 'getStarbucksCode' })),
        getDataObjects: (_, { id }) => toIdValue(cache.config.dataIdFromObject({ id, __typename: 'getDataObjects' })),
      },
    },
    */
  });

  try {
    const wsLink = new WebSocketLink({
      uri: `${config.API_URL.replace('https://', 'wss://').replace('http://', 'ws://')}-${config.ENDPOINT_DATA_WS}`,
      options: {
        reconnect: true,
        connectionParams: async () => {
          
          const latestToken = await getLatestToken();
          // JSON.stringify({ uuid: config.MOCK_AUTH, email: 'kronrod@gmail.com' }) }
          return { authorization: latestToken, clientId };
        },
        connectionCallback: (error: Error[], result?: any) => {
          console.log(error);
          console.log(result);
        }
      },
    })

    return new ApolloClient({
      cache,
      link: authLink.concat(ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              ),
            );
          }

          if (networkError) {
            console.log(`[Network error]: ${networkError}`);
          }
        }),
        requestLink,
        /*
        new HttpLink({
          uri: `${config.API_URL}-${config.ENDPOINT_PROVISION}`,
          credentials: 'include', // 'same-origin',
        }),*/
      ])).split(operation => operation.getContext().clientName === "public",
                new HttpLink({
                  uri: `${config.API_URL}-${config.ENDPOINT_PUBLIC}`,
                  credentials: 'same-origin', // include
                }),
                ApolloLink.split(operation => operation.getContext().clientName === "data-ws",
                                wsLink
                                ,
                                ApolloLink.split(operation => operation.getContext().clientName === "storage",
                                                new HttpLink({
                                                  uri: `${config.API_URL}-${config.ENDPOINT_STORAGE}`,
                                                  credentials: 'same-origin',
                                                }),
                                                ApolloLink.split(operation => operation.getContext().clientName === "stock",
                                                                  new HttpLink({
                                                                    uri: `${config.API_URL}-${config.ENDPOINT_STOCK}`,
                                                                    credentials: 'same-origin',
                                                                  }),
                                                                  ApolloLink.split(operation => operation.getContext().clientName === "data",
                                                                                  new HttpLink({
                                                                                    uri: `${config.API_URL}-${config.ENDPOINT_DATA}`,
                                                                                    credentials: 'same-origin',
                                                                                  }),
                                                                                  ApolloLink.split(operation => operation.getContext().clientName === "user",
                                                                                                    new HttpLink({
                                                                                                      uri: `${config.API_URL}-${config.ENDPOINT_USER}`,
                                                                                                      credentials: 'same-origin',
                                                                                                    }),
                                                                                                    new HttpLink({
                                                                                                      uri: `${config.API_URL}-${config.ENDPOINT_PROVISION}`,
                                                                                                      credentials: 'same-origin',
                                                                                                    }),
                                                                                  ),
                                                                    ),
                                                ),
                                  ),
                  ),
      ),
    });
  }
  catch (e) {
    clientInitialized = false;
    console.error('init', `failed to initialize ApolloClient. Error: ${(e as any)?.message}`);
  }
}

/*
let publicClient : any;
export function getPublicClient() {
  if (publicClient) {
    return publicClient;
  }

  Log.error(`Public client  URL: ${config.API_URL}-${config.ENDPOINT_PUBLIC}`, 'apollo');

  const cache: any = new InMemoryCache({
    cacheRedirects: {
      Query: {
        getStarbucksCode: (_, { id }) => toIdValue(cache.config.dataIdFromObject({ id, __typename: 'getStarbucksCode' })),
      },
    },
  });

  publicClient = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }

        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      new HttpLink({
        uri: `${config.API_URL}-${config.ENDPOINT_PUBLIC}`,
        credentials: 'same-origin',
      }),
    ]),
    cache,
  });

  return publicClient;
}
*/
/*
let provisionClient : any;
export function getProvisionClient() {
  Log.error('login', 'foo');
  if (provisionClient) {
    return provisionClient;
  }

  const cache: any = new InMemoryCache({
    cacheRedirects: {
      Query: {
        getUser: (_, { id }) => toIdValue(cache.config.dataIdFromObject({ id, __typename: 'getUser' })),
      },
    },
  });

  provisionClient = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        }

        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      new HttpLink({
        uri: `${config.API_URL}-${config.ENDPOINT_PROVISION}`,
        credentials: 'same-origin',
      }),
    ]),
    cache,
  });

  return provisionClient;
}
*/
