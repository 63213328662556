import React, { useEffect, useState }  from 'react';
import RecordModal, { ModalFieldEntry, ModalFields, RecordModalAction } from '../../modal/RecordModal';
import { getDataEntryTypeToRecordType, RecordType } from '../../table/DataTypes';
import { ActionType, CollectionType, DataCollectionData, DataEntry, DataEntryData, DataEntryDataInput, DataEntryInput, DataObject, DataObjectEntry, DataParent, DeleteDataEntryMutation, UpdateDataEntryMutation, UpdateItemInput } from '../../../graphql/generated/graphql';
import UpdateEntry from '../../../containers/api/Entry/UpdateEntry';
import { errorMessage } from '../../actions/ErrorMessage';
import { dataObjectResponseFn } from '../../../containers/api/DataObject';
import DeleteEntry from '../../../containers/api/Entry/DeleteEntry';
import { PlusCircleTwoTone } from '@ant-design/icons';
import CreateCollection from '../Collection/CreateCollection';
import LoadingOverlay from '../../atomic/LoadingOverlay';
import { getMockedEntryInputToDataEntry } from './EntryFields';
import { TagColor, TagItem } from '../../atomic/Tag';

type EditEntryProps = {
  action: RecordModalAction;
  initialDataEntry: DataEntry;
  dataObject: DataObject;
  collections: DataCollectionData[];
  autoExecute?: boolean; // used when updating data to set data as done or undone.
  mocked?: boolean;
  onSuccess?: (dataEntry: DataEntry) => any;
  onCancel?: () => any;
  onError?: (error: string) => any;
  onUpdateCollections?: (dataCollection: DataCollectionData) => any;
};

const EditEntry: React.FC<EditEntryProps> = (props: EditEntryProps) => {
  const { action, initialDataEntry, dataObject, autoExecute, mocked, onSuccess, onCancel, onError } = props;
  const [collections, setCollections] = useState<DataCollectionData[]>(props.collections);
  const [modalAction, setModalAction] = useState<RecordModalAction>(action);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [executeUpdate, setExecuteUpdate] = useState<JSX.Element | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
  const [lastChanged, setLastChanged] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (action !== modalAction) {
      setModalAction(action);
    }
    if (action === RecordModalAction.Update && props.autoExecute) {
      const dataEntryInput: DataEntryInput = {
        objectUuid: dataObject.uuid,
        dataUuid: initialDataEntry.dataUuid,
        dateDue: { date: initialDataEntry.dateDue } ,
        dateCompleted: { date: initialDataEntry.dateCompleted },
      };

      // const updateQuery = <EditCollection  collectionInput={collectionInput} onUpdate={(data?: UpdateDataCollectionMutation, loading?: boolean, error?: any) => {
      const updateQuery = <UpdateEntry entryInput={dataEntryInput} onUpdate={(data?: UpdateDataEntryMutation | null, loading?: boolean, error?: any) => {
        if (loading !== undefined) {
          setIsLoading(loading);
        }
        if (error) {
          setError(error);
          if (autoExecute && onError) {
            onError(error);
          }
          return;
        }

        if (data) {
          setError(null);
          setIsLoading(false);
          setModalAction(RecordModalAction.None);
          setExecuteUpdate(null);
          if (onSuccess) {
            onSuccess(data.updateDataEntry as DataEntry);
          }
        }
      }} />;
      setExecuteUpdate(updateQuery);
    }
    if (action === RecordModalAction.Delete && props.autoExecute) {
      onDeleteEntry();
    }
    if (action === RecordModalAction.Restore && props.autoExecute) {
      onRestoreEntry();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  const hideFields = dataObject.hideFields ? dataObject.hideFields as string[] : [];
  const hideDateDue = hideFields.find((hide: string) => hide === 'dateDue') !== undefined;
  const hideDateCompleted = hideFields.find((hide: string) => hide === 'dateCompleted') !== undefined;

  const recordDataValues: ModalFields = {
    pages: [
      {
        entries: Object.assign(
          {},
          {
            Title: { placeholder: 'Enter name', type: RecordType.Text, value: initialDataEntry.title, maxLength: 255, disabled: action === RecordModalAction.Delete || action === RecordModalAction.Restore },
            Description: { placeholder: 'Enter description', type: RecordType.Text, value: initialDataEntry.description, disabled: action === RecordModalAction.Delete || action === RecordModalAction.Restore },
            Collections: {
              type: collections.length === 0 ? RecordType.TextValue : RecordType.MultiSelect,
              // value: collections.length === 0 ? 'There are no Collections in your account' : (initialCollection ? [initialCollection] : []), values: collections.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)).map((collection: DataCollectionData) => ({ label: collection.name, value: collection.uuid })),
              value: collections.length === 0
              ? 'There are no Collections in your account'
              : (initialDataEntry.parent ? (initialDataEntry.parent as DataParent[]).map((p: DataParent) => p.uuid) : []),
              // values: collections.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)).map((collection: DataCollectionData) => ({ label: collection.name, value: collection.uuid })),
              values: collections.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)).map((collection: DataCollectionData) => ({ uuid: collection.uuid, name: collection.name, color: TagColor.Blue } as TagItem)),
              disabled: action === RecordModalAction.Delete || action === RecordModalAction.Restore,
              fieldName: <span>Collections: <CreateCollection
                mocked
                icon={<PlusCircleTwoTone style={{ marginLeft: '5px' }} />}
                onTrigger={() => {
                  setIsLoading(true);
                  setLoadingMessage('Creating new Collection ...');
                }}
                onSuccess={(data?: DataCollectionData, loading?: boolean, error?: string) => {
                  if (data) {
                    setCollections(prevCollections => [...prevCollections, data]);
                    setLastChanged(new Date().toUTCString());
                    if (props.onUpdateCollections) {
                      props.onUpdateCollections(data);
                    }
                  }
                  setIsLoading(false);
                  setLoadingMessage(undefined);
                }}
                onCancel={() => {
                  setIsLoading(false);
                  setLoadingMessage(undefined);
                }}
                ordinal={collections.length + 1} />
            </span>,
            },
            // Collections: { type: collections.length === 0 ? RecordType.TextValue : RecordType.Checkbox, value: collections.length === 0 ? 'There are no Collections in your account' : (initialDataEntry.parent ? (initialDataEntry.parent as DataParent[]).map((p: DataParent) => p.uuid) : []), values: collections.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)).map((collection: DataCollectionData) => ({ label: collection.name, value: collection.uuid })), disabled: action === RecordModalAction.Delete || action === RecordModalAction.Restore },
            DateCreated: { placeholder: 'Enter date created', type: RecordType.Date, fieldName: 'Date Created', value: initialDataEntry.dateCreated, disabled: true },
          },
          (hideDateDue ? {} : { DateDue: { placeholder: 'Enter date due', type: RecordType.Date, value: initialDataEntry.dateDue, fieldName: 'Date Due' } }),
          (hideDateCompleted ? {} : { DateCompleted: { placeholder: 'Enter date completed', type: RecordType.Date, value: initialDataEntry.dateCompleted, fieldName: 'Date Completed' } }),
          ...(dataObject && dataObject.entries
            ? dataObject.entries.map((obj: DataObjectEntry) => {
              return ({ [obj.name]: { type: getDataEntryTypeToRecordType(obj.type)?.recordType, inputType: getDataEntryTypeToRecordType(obj.type)?.inputType, value: initialDataEntry.data.find((ded: DataEntryData) => ded.entryUuid === obj.uuid)?.data, reference: obj.uuid, disabled: action === RecordModalAction.Delete || action === RecordModalAction.Restore } });
            })
            : []),
        ),
      },
    ],
  };

  const onUpdateEntry = async (record: any) => {
    if (!dataObjectResponseFn) {
      errorMessage('Please select data entry Type');
      return;
    }
    if (!record.pages[0].entries.Title.value) {
      errorMessage('Please enter data entry Name');
      return;
    }

    const updateEntryCollectionUuids: string[] = record.pages[0].entries.Collections.value;
    const originalCollections = initialDataEntry.parent ? initialDataEntry.parent.map((collection: DataParent) => collection.uuid) : [];
    const addCollections = collections.length === 0 ? [] : updateEntryCollectionUuids.filter((update: string) => !originalCollections.find((original: string) => update === original));
    const removeCollections = originalCollections.filter((original: string) => !updateEntryCollectionUuids.find((update: string) => update === original));
    const updateEntryCollections: UpdateItemInput[] =
      [
        ...removeCollections.map((newlyCollectionUuid: string) => {
          return {
            itemType: CollectionType.Item,
            action: ActionType.Remove,
            id: newlyCollectionUuid,
            ordinal: -1,
          };
        }),
        ...addCollections.map((newlyCollectionUuid: string) => {
          const collection = collections.find((collection: DataCollectionData) => collection.uuid === newlyCollectionUuid);
          const itemOrdinal = collection ? collection.entryUuids.length + 1 : -1; // TODO Fix Ordinal
          return {
            itemType: CollectionType.Item,
            action: ActionType.Add,
            id: newlyCollectionUuid,
            ordinal: itemOrdinal,
          };
        }),
      ];

    const data: DataEntryDataInput[] = [];
    Object.keys(record.pages[0].entries).forEach((fieldKey: string) => {
      const fieldData: ModalFieldEntry = record.pages[0].entries[fieldKey];
      if (fieldData.reference && fieldData.updated) {
        data.push({ entryUuid: fieldData.reference, data: fieldData.value });
      }
    });

    const dataEntryInput: DataEntryInput = {
      objectUuid: dataObject.uuid,
      dataUuid: initialDataEntry.dataUuid,
      dataParent: updateEntryCollections,
      title: record.pages[0].entries.Title.value,
      description: record.pages[0].entries.Description.value,
      data,
      dateDue: { date: record.pages[0].entries.DateDue.value },
      dateCompleted: { date: record.pages[0].entries.DateCompleted.value },
    };

    if (mocked) {
      setError(null);
      setIsLoading(false);
      setModalAction(RecordModalAction.None);
      const dataEntry: DataEntry = getMockedEntryInputToDataEntry(dataEntryInput);
      if (dataEntry.active === undefined) {
        dataEntry.active = true;
      }
      if (onSuccess) {
        onSuccess(dataEntry);
      }
      return;
    }

    // const updateQuery = <EditCollection  collectionInput={collectionInput} onUpdate={(data?: UpdateDataCollectionMutation, loading?: boolean, error?: any) => {
    const updateQuery = <UpdateEntry entryInput={dataEntryInput} onUpdate={(data?: UpdateDataEntryMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        if (autoExecute && onError) {
          onError(error);
        }
        return;
      }

      if (data && data.updateDataEntry) {
        setError(null);
        setIsLoading(false);
        setModalAction(RecordModalAction.None);
        if (onSuccess) {
          onSuccess(data.updateDataEntry as DataEntry);
        }
      }
    }} />;
    setExecuteUpdate(updateQuery);
  };

  const onDeleteEntry = async (record?: any) => {
    if (mocked) {
      setError(null);
      setIsLoading(false);
      setModalAction(RecordModalAction.None);
      const dataEntry: DataEntry = props.initialDataEntry;
      dataEntry.active = false;
      if (onSuccess) {
        onSuccess(dataEntry);
      }
      return;
    }
    const deleteQuery = <DeleteEntry dataUuid={initialDataEntry.dataUuid} objectUuid={initialDataEntry.objectUuid} onUpdate={(data?: DeleteDataEntryMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        if (autoExecute && onError) {
          onError(error);
        }
        return;
      }

      if (data) {
        setError(null);
        setIsLoading(false);
        setModalAction(RecordModalAction.None);
        if (onSuccess) {
          onSuccess(data.deleteDataEntry as DataEntry);
        }
      }
    }} />;
    setExecuteUpdate(deleteQuery);
  };

  const onRestoreEntry = async (record?: any) => {
    if (mocked) {
      setError(null);
      setIsLoading(false);
      setModalAction(RecordModalAction.None);
      const dataEntry: DataEntry = props.initialDataEntry;
      dataEntry.active = true;
      if (onSuccess) {
        onSuccess(dataEntry);
      }
      return;
    }

    const dataEntryInput: DataEntryInput = {
      objectUuid: dataObject.uuid,
      dataUuid: initialDataEntry.dataUuid,
      active: true,
    };

    const restoreQuery = <UpdateEntry entryInput={dataEntryInput} onUpdate={(data?: UpdateDataEntryMutation | null, loading?: boolean, error?: any) => {
      if (loading !== undefined) {
        setIsLoading(loading);
      }
      if (error) {
        setError(error);
        if (autoExecute && onError) {
          onError(error);
        }
        return;
      }

      if (data) {
        setError(null);
        setIsLoading(false);
        setModalAction(RecordModalAction.None);
        if (onSuccess) {
          onSuccess(data.updateDataEntry as DataEntry);
        }
      }
    }} />;
    setExecuteUpdate(restoreQuery);
  };

  return <>
    {executeUpdate}
    {modalAction !== RecordModalAction.None && !props.autoExecute && (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={`Updating Entry...`}
      >
        <RecordModal
          loadingMessage={loadingMessage}
          hideButtons={isLoading}
          lastChanged={lastChanged}
          key={'update-entry-modal'}
          data={recordDataValues}
          action={action}
          title={'entry'}
          onCancel={() => {
            setError(null);
            setModalAction(RecordModalAction.None);
            if (onCancel) {
              onCancel();
            }
          }}
          onSubmit={action === RecordModalAction.Restore ? onRestoreEntry : (action === RecordModalAction.Update ? onUpdateEntry : (action === RecordModalAction.Delete ? onDeleteEntry : () => {}))}
          isLoading={isLoading}
          error={error ? error.message : undefined}
        />
      </LoadingOverlay>
    )}
  </>;
};

export default EditEntry;
