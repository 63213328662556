import React, { useState } from 'react';
import { CSSProperties } from 'styled-components';
import { CssDisplay, CssPosition, CssTextAlign } from '../styled/CssConstants';
import { RecordType } from '../table/DataTypes';
import { DataCollectionData } from '../../graphql/generated/graphql';
import { DraggableItem, DraggableList } from '../draggable/DraggableItemView';
import { GetDataCollections } from '../../containers/api/Collection/DataCollection';
import { ICONS } from './IconList';
import FormData, { FormDataPages } from '../form/FormData';
import Button from '../atomic/Button';
import { CenteredDiv } from '../styled/CenteredDiv';
import { TitleDiv } from '../draggable/DraggableContext';
import { ComponentItems } from './ComponentList';

export type ComponentItem = {
  properties?: CSSProperties;
  customProperties?: { [name: string] : any};
};

export type DraggableComponentItem = DraggableItem<ComponentItem>;
export type DraggableComponentList = DraggableList<ComponentItem>;

type ComponentPropertiesProps = {
  item?: DraggableComponentItem;
  onUpdate: (value: string, properties: CSSProperties, customProperties: { [name: string] : string }) => void;
  onClose: () => void;
};

const ComponentProperties: React.FC<ComponentPropertiesProps> = (props: ComponentPropertiesProps) => {
  const { item, onClose, onUpdate } = props;
  const [collectionsData, setCollectionsData] = useState<DataCollectionData[]>([]);
  const dataCollections = <GetDataCollections updateApiResponse={(data?: any, loading?: any, error?: any) => {
    if (data) {
      setCollectionsData(data.sort((one, two) => (one.name.toLowerCase() < two.name.toLowerCase() ? -1 : 1)));
    }
  }}/>;

  const onUpdateData = (data: any) => {
    const fields = data.pages[0];
    const value = fields.Value ? (fields.Value.value ? fields.Value.value : '') : '';
    const properties: CSSProperties = {
      display: fields.Display.value && fields.Display.value !== 'undefined' ? fields.Display.value : undefined,
      position: fields.Position.value && fields.Position.value !== 'undefined' ? fields.Position.value : undefined,
      textAlign: fields.TextAlign.value && fields.TextAlign.value !== 'undefined' ? fields.TextAlign.value : undefined,
      width: fields.Width.value ?? undefined,
      padding: fields.Padding.value ?? undefined,
      margin: fields.Margin.value ?? undefined,
    };
    const customProperties: { [name: string] : any } = {};
    if (item && item.title) {
      if (ComponentItems[item.title] === ComponentItems.Collection) {
        customProperties.collection = fields.Collection.value ?? undefined;
      }
      if (ComponentItems[item.title] === ComponentItems['Form Field']) {
        customProperties.formField = fields.FormField.value ?? undefined;
        customProperties.fieldName = fields.FieldName.value ?? undefined;
        customProperties.initialValue = fields.InitialValue.value ?? undefined;
        customProperties.values = fields.Values.value ?? undefined;
      }
    }
    onUpdate(value, properties, customProperties);
  };

  const getPropertyFields = () => {
    const itemProperties = item && item.content && item.content.properties ? item.content.properties : undefined;
    const itemCustomProperties = item && item.content && item.content.customProperties ? item.content.customProperties : undefined;
    const propertyFields: FormDataPages = {
      pages: [
        {
          TextAlign: {
            placeholder: 'Select Text Align',
            type: RecordType.Dropdown,
            value: itemProperties ? itemProperties.textAlign : undefined,
            values: Object.keys(CssTextAlign).map((field: string) => ({ label: CssTextAlign[field], value: field })) },
          Display: {
            placeholder: 'Select Display',
            type: RecordType.Dropdown,
            value: itemProperties ? itemProperties.display : undefined,
            values: Object.keys(CssDisplay).map((field: string) => ({ label: CssDisplay[field], value: field })) },
          Position: {
            placeholder: 'Select Position',
            type: RecordType.Dropdown,
            value: itemProperties ? itemProperties.position : undefined,
            values: Object.keys(CssPosition).map((field: string) => ({ label: CssPosition[field], value: field })) },
          Padding: {
            placeholder: 'Select Padding',
            type: RecordType.Input,
            value: itemProperties ? itemProperties.padding : undefined,
          },
          Margin: {
            placeholder: 'Select Margin',
            type: RecordType.Input,
            value: itemProperties ? itemProperties.margin : undefined,
          },
          Width: {
            placeholder: 'Select Width',
            type: RecordType.Input,
            value: item && itemProperties ? itemProperties.width : undefined,
          },
          /*
          Object.assign(
            {},
            ...Object.keys(ConfigureableProperties).map((property: string) => ({ [property]: (!obj.entries
                ? {}
                : Object.assign(
                  {},
                  ...obj.entries.map((entry: DataObjectEntry) => ({ [entry.uuid]: ({ label: entry.name, field: entry.name, fieldType: entry.type }) })))
              )})),
          ...Object.keys(ConfigureableProperties).map()
          Date: { placeholder: 'Enter date', type: RecordType.Date, value: moment('2020-06-23', dateFormat) },
          Price: { placeholder: 'Enter price', type: RecordType.Input },
          Volume: { placeholder: 'Enter volume', type: RecordType.Input },
          Distance: { placeholder: 'Enter mileage', type: RecordType.Input },
          Image: { placeholder: 'Load image', type: RecordType.Image },
          Who: { placeholder: 'Select payee', type: RecordType.Dropdown, values: ['TJ', 'Alex'] },
          */
        },
      ],
    };
    if ([ComponentItems.Button, ComponentItems.Text, ComponentItems.Header].find((component: ComponentItems) => item && item.title && component === ComponentItems[item.title])) {
      propertyFields.pages[0].Value = {
        placeholder: 'Enter Value',
        type: RecordType.Input,
        value: item ? item.value : undefined,
      };
    }
    if (item?.title === 'Collection') {
      propertyFields.pages[0]['Collection'] = {
        placeholder: 'Select Collection',
        type: RecordType.Dropdown,
        value: itemCustomProperties ? itemCustomProperties.collection : undefined,
        values: collectionsData.map((collection: DataCollectionData) => ({ label: collection.name, value: collection.uuid })),
      };
    }
    if (item?.title === 'Button') {
      propertyFields.pages[0]['Icon'] = {
        placeholder: 'Select Icon',
        type: RecordType.Dropdown,
        value: item ? item.value : undefined,
        values: Object.keys(ICONS).map((icon: string) => ({ label: icon, value: ICONS[icon].name })),
      };
      propertyFields.pages[0]['Trigger'] = {
        placeholder: 'Select Trigger',
        type: RecordType.Dropdown,
        value: item ? item.content : undefined,
        values: collectionsData.map((collection: DataCollectionData) => ({ label: collection.name, value: collection.uuid })),
      };
    }
    if (item?.title === 'Form Field') {
      propertyFields.pages[0]['FormField'] = {
        placeholder: 'Select field type',
        type: RecordType.Dropdown,
        value: itemCustomProperties ? itemCustomProperties.formField : undefined,
        values: Object.keys(RecordType).map((recordType: string) => ({ label: recordType, value: RecordType[recordType] })),
      };
      propertyFields.pages[0]['FieldName'] = {
        placeholder: 'Enter field name',
        type: RecordType.Input,
        value: itemCustomProperties ? itemCustomProperties.fieldName : undefined,
        values: Object.keys(RecordType).map((recordType: string) => ({ label: recordType, value: RecordType[recordType] })),
      };
      propertyFields.pages[0]['InitialValue'] = {
        placeholder: 'Enter initial value',
        type: RecordType.Input,
        value: itemCustomProperties ? itemCustomProperties.initialValue : undefined,
      };
      propertyFields.pages[0]['Values'] = {
        placeholder: `Enter option values comma separated (e.g. 'Option 1, Option 2, Option 3')`,
        type: RecordType.Input,
        value: itemCustomProperties ? itemCustomProperties.values : undefined,
      };
    }

    return propertyFields;
  };

  return (item
    ? <>
      {dataCollections}
      <TitleDiv>Properties</TitleDiv>
      <div style={{ padding: '10px' }}>
        <FormData key={`loaded-collections-${collectionsData.length}`} data={getPropertyFields()} onUpdate={onUpdateData}></FormData>
        <CenteredDiv style={{ padding: '10px' }}><Button size='small' onClick={onClose}>Close</Button></CenteredDiv>
      </div>
    </>
    : null);
};

export default ComponentProperties;
