import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PagesData from '../components/data/Pages';

import Page from '../components/layout/Page';
import { Content } from '../components/styled/CssContent';
// import logger from '../utils/Logger';

// const logInfo = (message: any) => logger.info({ source: 'pages.Pages', message });
// const logInfoJson = (message: any) => logger.info({ source: 'pages.Pages', message: JSON.stringify(message) });

type PagesProps = RouteComponentProps & {
};

const Pages: React.FC<PagesProps> = (props: PagesProps) => {
  return (
  <Page headerTitle='Pages' content={
      <Content>
        <div></div>
        <PagesData />
      </Content>
    }
    />
  );
};

export default Pages;
