import { Stock } from './StockTypes';

export enum StockReferenceUrlType {
  openLot = 0,
  closedLot,
  accountPortolio,
  openOrders,
  summary,
}

export const getUrl = (broker: string, account: string, stock: Stock, urlType: StockReferenceUrlType): string | undefined => {
  let url: string | undefined;
  if (broker === 'Fidelity') {
    if (account === '') {
      return url;
    }
    if (urlType === StockReferenceUrlType.accountPortolio) {
      url = `https://oltx.fidelity.com/ftgw/fbc/oftop/portfolio#summary/${account ?? ''}`;
    }
    else if (urlType === StockReferenceUrlType.openLot) {
      const assetId = stock.fidelityAssetId;
      url = `https://oltx.fidelity.com/ftgw/fbc/ofpositions/openLots?${account ? `ACCOUNT=${account}&` : ''}QUOTE_REQUEST_ID=E${stock.cusip ? `&CUSIP=${stock.cusip}` : ''}${assetId ? `&ASSET_ID=${assetId}` : ''}&ACCT_TYPE_CODE=1&POSITION_TYPE_CODE=L${stock.ticker ? `&SYMBOL=${stock.ticker}` : ''}${stock.ticker ? `&FBSI_SYMBOL_ID=${stock.ticker}` : ''}&BROKERAGE_ACCOUNT_TYPE=1${stock.ticker ? `&QUERY_FBSI_SYMBOL_ID=${stock.ticker}` : ''}`;
    }
    else if (urlType === StockReferenceUrlType.closedLot) {
      const assetId = stock.fidelityAssetId;
      url = `https://oltx.fidelity.com/ftgw/fbc/ofpositions/closedLots?${account ? `ACCOUNT=${account}&` : ''}QUOTE_REQUEST_ID=E${stock.cusip ? `&CUSIP=${stock.cusip}` : ''}${assetId ? `&ASSET_ID=${assetId}` : ''}&ACCT_TYPE_CODE=1&POSITION_TYPE_CODE=L${stock.ticker ? `&SYMBOL=${stock.ticker}` : ''}${stock.ticker ? `&FBSI_SYMBOL_ID=${stock.ticker}` : ''}&BROKERAGE_ACCOUNT_TYPE=1${stock.ticker ? `&QUERY_FBSI_SYMBOL_ID=${stock.ticker}` : ''}`;
    }
    else if (urlType === StockReferenceUrlType.summary) {
      url = 'https://oltx.fidelity.com/ftgw/fbc/oftop/portfolio#summary';
    }
    else if (urlType === StockReferenceUrlType.openOrders) {
      url = `https://oltx.fidelity.com/ftgw/fbc/oftop/portfolio#orders/${account}`;
    }
  }
  if (broker === 'Robinhood') {
    url = `https://robinhood.com/${stock.isCrypto ? 'crypto' : 'stocks'}/${stock.ticker}`;
  }
  if (broker === 'Coinbase') {
    url = `https://pro.coinbase.com/portfolios/${account}`;
  }
  return url;
};
