import { FilterOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useState } from 'react';
import Button from '../atomic/Button';
import Popconfirm from '../atomic/Popconfirm';

export enum HoldingColumns {
  'Row' = 'Row',
  'Broker' = 'Broker',
  'Account' = 'Account',
  'Symbol' = 'Symbol',
}
export type HoldingSettings = {
  view: HoldingColumns[];
};

type HoldingFilterProps = {
  settings: HoldingSettings;
  onUpdateFilter: (updateSetting: HoldingSettings) => void;
};

const HoldingFilter: React.FC<HoldingFilterProps> = (props: HoldingFilterProps) => {
  const { settings, onUpdateFilter } = props;
  const [setting, setSetting] = useState<any>(settings);
  const [updated, setUpdated] = useState<boolean>(false);

  return (
    <Popconfirm
      key={`entry-filter`}
      header={"Configure Filter"}
      icon={null}
      title={<>
        <div style={{ fontWeight: 'bold' }}>Select table columns to display:</div>
        <div>
          {Object.keys(HoldingColumns).map((option: string) => <Checkbox
            key={`stock-filter-${option}`}
            checked={setting.view.find((selectedOption: HoldingColumns) => {
              return selectedOption === HoldingColumns[option];
            }) !== undefined}
            onChange={(value: any) => {
              setSetting((prevUpdateSettings) => {
                const updateSettings = { ...prevUpdateSettings };
                updateSettings.view = value.target.checked ? [...updateSettings.view, HoldingColumns[option]] : updateSettings.view.filter((selectedOption: HoldingColumns) => selectedOption !== HoldingColumns[option]);
                return updateSettings;
              });
              setUpdated(true);
            }}>{HoldingColumns[option]}</Checkbox>)}
        </div>
      </>}
      onConfirm={() => {
        if (updated) {
          onUpdateFilter(setting);
        }
      }}
      onCancel={() => {
        setSetting(settings);
        setUpdated(false);
      }}
      okText='Done'
      cancelText='Cancel'
    >
      <Button size='small' key='stock-list-filter'><FilterOutlined /></Button>
    </Popconfirm>
  );
};

export default HoldingFilter;
