import React, { useState } from 'react';
import { Typography } from 'antd';
import Page from '../components/layout/Page';
import { GetUser } from '../containers/api/Provision';
import { ResultViewEnum } from '../containers/api/Helpers';
import { GetFriends } from '../containers/api/User/User';
import logger from '../utils/Logger';
import _ from 'lodash';
import { dateFormat } from '../components/table/DataTypes';
import moment from 'moment';
import { DataItem } from '../components/views/Helpers';
import ViewDraggable from '../components/views/ViewDraggable';
import { CenteredDiv } from '../components/styled/CenteredDiv';

const { Title } = Typography;
const logInfo = (message: any) => logger.info({ source: 'pages.Account', message });
const logInfoJson = (message: any) => logger.info({ source: 'pages.Account', message: JSON.stringify(message) });

type AccountProps = {
};

const Account: React.FC<AccountProps> = (props: AccountProps) => {
  const [userData, setUserData] = useState<any>(null);
  const [friendData, setFriendData] = useState<any>(null);

  const dataUser = <GetUser view={ResultViewEnum.Json} updateApiResponse={(data: any) => setUserData(data)}/>;
  const dataFriend = <GetFriends view={ResultViewEnum.Json} updateApiResponse={(data: any) => setFriendData(data)}/>;

  logInfo('Account userData');
  logInfoJson(userData);

  /* TODO: Delete
  const generateUserCardData = (data: any, mapping: any) => {
    const userCard: {
      title: string | JSX.Element
      content: string | JSX.Element } = { title: '', content: '' };
    userCard.title = `Name: ${data.firstName} ${data.lastName}`;
    userCard.content = mapping.content.map((m: any) => {
      return <div>{m.label}: {data[m.field]}</div>;
    });
    return userCard;
  }; */

  const userDataItems: DataItem[] = userData
  ? [
    { title: 'First Name', data: userData.firstName },
    { title: 'Last Name', data: userData.lastName },
    { title: 'Email Address', data: userData.emailAddress },
    { title: 'Created On', data: userData.dateCreated },
  ]
  : [];

  const userDataItemsMap = {
    itemId: (data: any) => {
      return data.user ? data.user.uuid : data.uuid;
    },
    header: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        return <div key='user-title'><span style={{ fontWeight: 'bold', width: 100, float: 'left' }}>{mapping.name.label}:</span> {_.get(data, mapping.name.fields.firstName)} {_.get(data, mapping.name.fields.lastName)}</div>;
      },
      mapping: {
        userInfo: { // itemUuid
          name: {
            label: 'Name',
            fields: {
              firstName: 'firstName',
              lastName: 'lastName',
            },
          },
        },
        friendInfo: { // itemUuid
          name: {
            label: 'Name',
            fields: {
              firstName: 'user.firstName',
              lastName: 'user.lastName',
            },
          },
        },
      },
    },
    content: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        return Object.keys(mapping).map((m: any) => {
          return <div key={`user-content-${m}`}><span style={{ fontWeight: 'bold', width: 100, float: 'left' }}>{mapping[m].label}:</span> {mapping[m].resolve ? mapping[m].resolve(_.get(data, mapping[m].field)) : _.get(data, mapping[m].field)}</div>;
        });
      },
      mapping: {
        userInfo: {
          email: { label: 'Email', field: 'emailAddress' },
          createdOn: { label: 'Created On', field: 'dateCreated', resolve: (date: any) => moment(date).format(dateFormat) },
        },
        friendInfo: {
          email: { label: 'Email', field: 'user.emailAddress', resolve: (email: any) => email ? email : <span style={{ color: 'red' }}>N/A</span> },
          createdOn: { label: 'Created On', field: 'dateCreated', resolve: (date: any) => moment(date).format(dateFormat) },
          status: { label: 'Status', field: 'status' },
        },
      },
    },
  };
  /*
  ? [
    { title: 'First Name', data: userData.firstName },
    { title: 'Last Name', data: userData.lastName },
    { title: 'Email Address', data: userData.emailAddress },
    { title: 'Created On', data: userData.dateCreated },
  ]
  : []; */

  const friendsDataItems: { title: string, friend: DataItem[]}[] = friendData
  ? friendData.map((f: any) => {
    const friend = [
      { title: 'First Name', data: f.user.firstName },
      { title: 'Last Name', data: f.user.lastName },
      { title: 'Email Address', data: f.user.emailAddress },
      { title: 'Created On', data: f.dateCreated },
    ];
    const title = `${f.user.firstName} ${f.user.lastName}`;
    return { title, friend };
  })
  : [];

  logInfoJson(userDataItems);
  logInfoJson(friendsDataItems);

  return (
    <Page headerTitle='Account' content={
      <CenteredDiv>
        {dataUser}
        {dataFriend}
        <Title level={3} style={{ marginLeft: 'auto', marginRight: 'auto' }}>User Info</Title>
        <ViewDraggable dragDisabled customKey={'viewcard-user'} loading={userData === null} data={userData ? [userData] : []} mapping={userDataItemsMap} sourceName='userInfo'></ViewDraggable>
        <br />
        <Title level={3} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Contact Info</Title>
        <ViewDraggable dragDisabled customKey={'viewcard-friends'} loading={friendData === null} data={friendData} mapping={userDataItemsMap} sourceName='friendInfo' collapseable={true}></ViewDraggable>
      </CenteredDiv>
    }
    />);
};

//        {friendData ? friendData.map((f: any) => <><ViewCard data={f} mapping={userDataItemsMap}></ViewCard><br /></>) : null}

export default Account;
