import React, { useEffect, useRef, useState } from 'react';
import _, { Dictionary } from 'lodash';
import { errorNotification, infoNotification } from '../actions/Notification';
import { TagItem } from '../atomic/Tag';
import ResponsiveModal from '../modal/ResponsiveModal';
import QRHistoryItemView from './QRHistoryItemView';
import QRHistoryTags, { KeyValuePair } from './QRHistoryTags';

export type QRHistoryItem = {
  uuid: string;
  date: string;
  name: string;
  url: string;
  qrCode?: any;
  tagUuids: string[];
};

type QRHistoryProps = {
  initialHistory: QRHistoryItem[];
  newItem?: QRHistoryItem;
  onUpdate: (history: QRHistoryItem[]) => void;
  onSelect: (item: QRHistoryItem) => void;
  onCancel: (history?: QRHistoryItem[]) => void;
};

export const defaultTags: Dictionary<KeyValuePair> = {
  allItems: { key: 'all-items', value: 'All Items' },
  itemsWithoutTags: { key: 'items-without-items', value: 'Items with no tags' },
};

const QRHistory: React.FC<QRHistoryProps> = (props: QRHistoryProps) => {
  // Users
  const [tags, setTags] = useState<TagItem[] | null>(null);
  const [history, setHistory] = useState<QRHistoryItem[]>(props.initialHistory);
  // const [isEditingTag, setIsEditingTag] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [editItem, setEditItem] = useState<QRHistoryItem | null>(props.newItem ? props.newItem : null);
  const [closeHistory, setCloseHistory] = useState<boolean>(false);
  const [historyUpdated, setHistoryUpdated] = useState<boolean>(false);

  const historyRef = useRef(props.initialHistory);
  historyRef.current = history;
  const editItemRef = useRef(props.newItem ? props.newItem : null);
  editItemRef.current = editItem;

  useEffect(() => {
    localStorage.setItem('s2p-MySejahtera-history', JSON.stringify(history));
  },        [history]);

  useEffect(() => {
    if (closeHistory) {
      if (historyUpdated) {
        props.onUpdate(history);
        setHistoryUpdated(false);
      }
      else {
        props.onCancel();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [history, historyUpdated, closeHistory]);

  useEffect(() => {
    if (!tags) {
      const loadTags = localStorage.getItem('s2p-scan-history-tags');
      const parseTags = loadTags ? JSON.parse(loadTags) : null;
      if (parseTags === null) {
        setTags([]);
        return;
      }
      if (!parseTags.version) {
        const updateTags = parseTags ? parseTags : [];
        const selectedTags = parseTags ? parseTags.filter((tag: any) => tag.selected).map((selected: any) => selected.uuid) : [];
        const updateStoredTags = {
          version: '1.0',
          tags: updateTags,
          selected: selectedTags,
        };
        setTags(parseTags ? parseTags : []);
        setSelectedTag(selectedTags.length !== 1 ? defaultTags.allItems.key : selectedTags[0]);
        localStorage.setItem('s2p-scan-history-tags', JSON.stringify(updateStoredTags));
      }
      else if (parseTags.version === '1.0') {
        setTags(parseTags.tags);
        const selTag = parseTags.selected.length === 0 ? defaultTags.allItems.key : parseTags.selected[0];
        setSelectedTag(selTag);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (!tags) {
      return;
    }
    const updateStoredTags = {
      version: '1.0',
      tags,
      selected: selectedTag ? [selectedTag] : [],
    };
    localStorage.setItem('s2p-scan-history-tags', JSON.stringify(updateStoredTags));
  },        [selectedTag, tags]);

  const onUpdateTags = (updateTags: TagItem[]) => {
    setTags(updateTags);
  };

  const onUndoUpdate = (restoreItem: QRHistoryItem) => {
    const updateHistory = [...historyRef.current];
    const prevItemIndex = updateHistory.findIndex((existingItem: QRHistoryItem) => existingItem.uuid === restoreItem.uuid);
    if (prevItemIndex === -1) {
      errorNotification('Failed to update item. Please try again.');
      return;
    }
    updateHistory[prevItemIndex] = { ...restoreItem };
    infoNotification('Item restored.');
    setHistory(updateHistory);
  };

  const onUndoTagUpdate = (restoreItem: QRHistoryItem) => {
    const currentEditItem = editItemRef.current;
    if (props.newItem) {
      if (currentEditItem === null) {
        errorNotification('Failed to update item. Please try again.');
        return;
      }
      const tagUuidsAdded = _.difference(restoreItem.tagUuids, currentEditItem.tagUuids);
      const tagUuidsRemoved = _.difference(currentEditItem.tagUuids, restoreItem.tagUuids);
      const tagsAdded = tags ? tags.filter((tag: TagItem) => tagUuidsAdded.find((addedTag: string) => addedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;
      const tagsRemoved = tags ? tags.filter((tag: TagItem) => tagUuidsRemoved.find((removedTag: string) => removedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;
      const updateEditItem = { ...currentEditItem };
      updateEditItem.tagUuids = restoreItem.tagUuids;
      setEditItem(updateEditItem);
      infoNotification(`Item tags restored: ${tagsAdded ? `Tags added: ${tagsAdded}` : ''} ${tagsRemoved ? `Tags removed:  ${tagsRemoved}` : ''}`);
      return;
    }
    const updateHistory = [...historyRef.current];
    const prevItemIndex = updateHistory.findIndex((existingItem: QRHistoryItem) => existingItem.uuid === restoreItem.uuid);
    if (prevItemIndex === -1) {
      errorNotification('Failed to update item. Please try again.');
      return;
    }
    const prevItem = updateHistory[prevItemIndex];
    const tagUuidsAdded = _.difference(restoreItem.tagUuids, prevItem.tagUuids);
    const tagUuidsRemoved = _.difference(prevItem.tagUuids, restoreItem.tagUuids);
    if (tagUuidsAdded.length === 0 && tagUuidsRemoved.length === 0) {
      infoNotification('Action already executed.');
      return;
    }
    const tagsAdded = tags ? tags.filter((tag: TagItem) => tagUuidsAdded.find((addedTag: string) => addedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;
    const tagsRemoved = tags ? tags.filter((tag: TagItem) => tagUuidsRemoved.find((removedTag: string) => removedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;

    updateHistory[prevItemIndex] = { ...restoreItem };
    infoNotification(`Item tags restored: ${tagsAdded ? `Tags added: ${tagsAdded}` : ''} ${tagsRemoved ? `Tags removed:  ${tagsRemoved}` : ''}`);
    if (currentEditItem) {
      const updateEditItem = { ...currentEditItem };
      updateEditItem.tagUuids = restoreItem.tagUuids;
      setEditItem(updateEditItem);
    }
    setHistory(updateHistory);
  };

  const onUpdateTag = (updateItem: QRHistoryItem) => {
    const currentEditItem = editItemRef.current;
    if (props.newItem) {
      if (currentEditItem === null) {
        errorNotification('Failed to update item. Please try again.');
        return;
      }
      const restoreItemTags = { ...currentEditItem };
      const tagUuidsAdded = _.difference(updateItem.tagUuids, currentEditItem.tagUuids);
      const tagUuidsRemoved = _.difference(currentEditItem.tagUuids, updateItem.tagUuids);
      const tagsAdded = tags ? tags.filter((tag: TagItem) => tagUuidsAdded.find((addedTag: string) => addedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;
      const tagsRemoved = tags ? tags.filter((tag: TagItem) => tagUuidsRemoved.find((removedTag: string) => removedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;
      const updateEditItem = { ...currentEditItem };
      updateEditItem.tagUuids = updateItem.tagUuids;
      setEditItem(updateEditItem);
      infoNotification(`${tagsAdded ? `Tags added: ${tagsAdded}` : ''} ${tagsRemoved ? `Tags removed:  ${tagsRemoved}` : ''}`, 'Undo', () => onUndoTagUpdate(restoreItemTags));
      return;
    }
    const updateHistory = [...historyRef.current];
    const prevItemIndex = updateHistory.findIndex((existingItem: QRHistoryItem) => existingItem.uuid === updateItem.uuid);
    if (prevItemIndex === -1) {
      errorNotification('Failed to update item. Please try again.');
      return;
    }
    const restoreItem: QRHistoryItem = { ...updateHistory[prevItemIndex] };
    const tagUuidsAdded = _.difference(updateItem.tagUuids, restoreItem.tagUuids);
    const tagUuidsRemoved = _.difference(restoreItem.tagUuids, updateItem.tagUuids);
    const tagsAdded = tags ? tags.filter((tag: TagItem) => tagUuidsAdded.find((addedTag: string) => addedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;
    const tagsRemoved = tags ? tags.filter((tag: TagItem) => tagUuidsRemoved.find((removedTag: string) => removedTag === tag.uuid)).map((tag: TagItem) => tag.name).join(', ') : null;
    if (currentEditItem) {
      const updateEditItem = { ...currentEditItem };
      updateEditItem.tagUuids = updateItem.tagUuids;
      setEditItem(updateEditItem);
    }
    updateHistory[prevItemIndex].tagUuids = updateItem.tagUuids;
    setHistory(updateHistory);
    infoNotification(`${tagsAdded ? `Tags added: ${tagsAdded}` : ''} ${tagsRemoved ? `Tags removed:  ${tagsRemoved}` : ''}`, 'Undo', () => onUndoTagUpdate(restoreItem));
  };

  const onUpdate = (updateItem: QRHistoryItem) => {
    const currentHistory = [...historyRef.current];
    const duplicateItem = currentHistory.find((existingItem: QRHistoryItem) => existingItem.uuid !== updateItem.uuid && (existingItem.url === updateItem.url || existingItem.name === updateItem.name));
    if (duplicateItem) {
      if (duplicateItem.url === updateItem.url) {
        errorNotification('Item url must be unique.');
        return;
      }
      if (duplicateItem.name === updateItem.name) {
        errorNotification('Item name must be unique');
        return;
      }
    }
    if (props.newItem) {
      infoNotification(`Item added: ${updateItem.name}`, 'Delete', () => onDelete(updateItem));
      currentHistory.push(updateItem);
    }
    else {
      const prevItemIndex = currentHistory.findIndex((existingItem: QRHistoryItem) => existingItem.uuid === updateItem.uuid);
      if (prevItemIndex === -1) {
        errorNotification('Failed to update item. Please try again.');
        return;
      }
      const restoreItem = { ...currentHistory[prevItemIndex] };
      if (restoreItem.url === updateItem.url && restoreItem.name === updateItem.name) {
        infoNotification('No changes to name and url made.');
        setEditItem(null);
        return;
      }
      infoNotification('Item updated.', 'Undo', () => onUndoUpdate(restoreItem));
      currentHistory[prevItemIndex] = updateItem;
    }
    setHistory(currentHistory);
    setHistoryUpdated(true);
    setEditItem(null);
    if (props.newItem) {
      setCloseHistory(true);
    }
  };

  const onDelete = (deleteItem: QRHistoryItem) => {
    const currentHistory = [...historyRef.current];
    const updateHistory = currentHistory.filter((item: QRHistoryItem) => deleteItem.uuid !== item.uuid);
    if (updateHistory.length === currentHistory.length) {
      infoNotification(`Item not found: ${deleteItem.name}`);
      return;
    }
    setHistory(updateHistory);
    infoNotification(`Item deleted: ${deleteItem.name}`);
  };

  return (
    <ResponsiveModal
      visible={true}
      onCancel={() => {
        props.onCancel(); // historyUpdated ? history : undefined);
      }}
      onOk={(e: any) => {
        const currentEditItem = editItemRef.current ? { ...editItemRef.current } : null;
        if (props.newItem) {
          if (!currentEditItem) {
            errorNotification('Failed to create new item.');
            return;
          }
          onUpdate(currentEditItem);
        }
        // If not new item, then just close the screen.
        else {
          setCloseHistory(true);
        }
        /*
        const saveItem = editItem ? editItem : props.newItem;
        if (props.newItem && saveItem) {
          const duplicateItem = history.find((item: QRHistoryItem) => item.url === saveItem!.url || item.name === saveItem!.name);
          if (duplicateItem) {
            if (duplicateItem.url === saveItem.url) {
              errorNotification(<div>Duplicate item already exists with {<span style={{ fontWeight: 'bold' }}>url</span>} value:<pre>{saveItem.url}</pre>Item name is <span style={{ fontWeight: 'bold' }}>{saveItem.name}</span></div>);
            }
            else if (duplicateItem.name === saveItem.name) {
              errorNotification(<div>Duplicate item already exists with <span style={{ fontWeight: 'bold' }}>name</span> value <span style={{ fontWeight: 'bold' }}>{saveItem.name}</span></div>);
            }
            return;
          }
        }
        const updateHistory = props.newItem && saveItem ? [saveItem, ...history] : history;

        props.onUpdate(updateHistory);
        */
      }}
      okText={props.newItem ? 'Save' : 'Close'}
      modalContent={
        <div>
          {props.newItem ? null : <QRHistoryTags
            key='history-tags'
            tags={tags ?? []}
            defaultTags={defaultTags}
            disabled={editItem !== null}
            selectedTags={selectedTag ? [selectedTag] : []}
            onCreate={(createTag: TagItem) => {
              const updateTags = tags ? [...tags] : [];
              updateTags.push(createTag);
              onUpdateTags(updateTags);
            }}
            onUpdate={(updateTag: TagItem) => {
              const updateTags = tags ? [...tags] : []; // .sort((one, two) => (one.name < two.name ? -1 : 1));
              const tagIndex = updateTags.findIndex((tag: TagItem) => tag.uuid === updateTag.uuid);
              if (tagIndex < 0) {
                return;
              }
              updateTags[tagIndex] = updateTag;
              onUpdateTags(updateTags);
            }}
            onDelete={(deleteTag: TagItem) => {
              const copyTags = tags ? [...tags] : []; // .sort((one, two) => (one.name < two.name ? -1 : 1));
              const updateTags = copyTags.filter((tag: TagItem) => tag.uuid !== deleteTag.uuid);
              onUpdateTags(updateTags);
            }}
            onEdit={(editing: boolean) => {} /* setIsEditingTag(editing) */}
            onSelect={(selectedItem: TagItem) => {
              if (selectedTag === null) {
                setSelectedTag(selectedItem.uuid);
              }
              else {
                if (selectedTag === selectedItem.uuid) {
                  setSelectedTag(null);
                }
                else {
                  setSelectedTag(selectedItem.uuid);
                }
              }
            }} />}
          <QRHistoryItemView
            key={'history-item-view'}
            history={history}
            selectedTag={selectedTag}
            tags={tags ?? []}
            isNew={props.newItem !== undefined}
            editItem={editItem}
            onEdit={(updateEditItem: QRHistoryItem | null) => {
              if (updateEditItem === null) {
                // cancel editing
                infoNotification(`Edit item cancelled`);
                setEditItem(null);
                return;
              }
              if (editItem && updateEditItem.uuid !== editItem.uuid) {
                errorNotification(`You cannot edit item '${updateEditItem.name}' because you are already editing item '${editItem.name}'`);
                return;
              }
              setEditItem(updateEditItem);
            }}
            onUpdate={onUpdate}
            onUpdateTag={onUpdateTag}
            onSelect={props.onSelect}
            onDelete={onDelete} />
        </div>}
    />
  );
};

export default QRHistory;
