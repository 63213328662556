import React, { useEffect, useLayoutEffect, useRef, useState, useContext } from 'react';
import { Dictionary } from 'lodash';
import moment from 'moment';
import { Divider } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import uuid from 'uuid/v4';
import AuthContext from '../../context/AuthContext';
import GetStockHistory from '../../containers/api/Stock/GetStockHistory';
import GetStocks from '../../containers/api/Stock/GetStocks';
import { ActionType, AddDataCollectionMutation, AddDataEntryMutation, CollectionType, DataCollectionData, DataEntry, DataEntryData, DataEntryInput, DataObject, DataObjectEntry, Features, HistoryFrequency, PlaidAccount, PlaidLinkedInstitution, PlaidSecurity, StockDetails, StockHistoryPrice, StockPrice, UpdateDataEntryMutation } from '../../graphql/generated/graphql';
import { errorNotification, infoNotification } from '../actions/Notification';
import { CenteredDiv, StyledDiv } from '../styled/CenteredDiv';
import StockList, { DefaultStockList } from './StockList';
import ResponsiveContentSidebar from '../views/ResponsiveContentSidebar';
import DivAlign, { DivPosition } from '../atomic/DivAlign';
import GetStockDetails from '../../containers/api/Stock/GetStockDetails';
import StockDetail from './StockDetail';
import ImportIcon from '../icons/ImportIcon';
import AddIcon from '../icons/AddIcon';
import Dropdown, { DropdownType } from '../atomic/Dropdown';
import ImportBroker from './import/ImportBroker';
import { BrokerStockData, getBrokerStockData, getStockFromStockDetails, LastPrice, Stock, StockHistory, StockHolding, StockMergedTransaction, StockOrder, StockTransaction, SymbolStockData, UpdateStockEntryData } from './StockTypes';
import Button from '../atomic/Button';
import ImportCsv from '../import/ImportFile';
import s2pConfig from '../../Config';
import { GetDataCollections } from '../../containers/api/Collection/DataCollection';
import AddCollection from '../../containers/api/Collection/AddCollection';
import { GetDataEntries } from '../../containers/api/Entry/DataEntry';
import { GetDataObjects } from '../../containers/api/DataObject';
import AddEntry from '../../containers/api/Entry/AddEntry';
import UpdateEntry from '../../containers/api/Entry/UpdateEntry';
import LoadingOverlay from '../atomic/LoadingOverlay';
import FindStock from './FindStock';
import AddStockData, { StockDataType } from './AddStockData';
import MenuIcon from '../icons/MenuIcon';
import ImportPdf from '../import/ImportPdfFile';
import ViewTransactionHistory from './ViewTransactionHistory';
import ViewHoldings from './ViewHoldings';
import ViewOrders from './ViewOrders';
import GetLinkedInstitutions from '../../containers/api/Plaid/GetLinkedInstitutions';

/*
export type StockOrder = {
  buyOrSell: 'Buy' | 'Sell';
  type: 'Limit' | 'Market';
  triggerPrice: number;
};
*/

export type StockBuy = {
  uuid: string;
  date: Date;
  price: number;
  units: number;
  expirationDate?: Date;
  fee?: number;
};

export type StockSell = {
  uuid: string;
  date: Date;
  price: number;
  units: number;
  fee: number;
  matchBuy: { uuid: string; units: number };
  expirationDate?: Date;
};

type UpdateStockEntries = {
  uuid: string;
  symbols: string[];
  jsx: JSX.Element;
};

export const QRY_ERROR = 'Something went wrong, please try again.';

const STOCK_OBJ_UUID = '11111111-1111-1111-1111-111111111115';
type StockObjEntries = {
  holdingsUuid: string;
  ordersUuid: string;
  listsUuid: string;
  transactionsUuid: string;
  detailsUuid: string;
  lastViewedUuid: string;
};

enum DataState {
  uninitialized = 0,
  cached,
  fetch,
  loaded,
}
type StockDataState = {
  stockObjs: DataState;
  dataEntries: DataState;
};

type EntryLastUpdated = { details?: Date, transactions?: Date, holdings?: Date, orders?: Date, lastViewed?: Date, lists?: Date };

enum ViewListType {
  None = 'None',
  Stocks = 'Stocks',
  Orders = 'Orders',
  Holdings = 'Holdings',
}

type StocksSampleProps = {
};
const Stocks: React.FC<StocksSampleProps> = (props: StocksSampleProps) => {
  const authContext = useContext(AuthContext);
  const [dataState, setDataState] = useState<StockDataState>({ stockObjs: DataState.uninitialized, dataEntries: DataState.uninitialized });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // unused
  // const [stocks, setStocks] = useState<string[]>([]);
  // const [recentStocks, setRecentStocks] = useState<string[]>([]);

  // Stock Detail Panel
  const [ticker, setTicker] = useState<string | undefined>(undefined);
  // Stock Lists Panel
  const [stockDetails, setStockDetails] = useState<Dictionary<Stock>>({});
  const [stockDetailsNotFound, setStockDetailsNotFound] = useState<Dictionary<string>>({});
  const [lists, setLists] = useState<Dictionary<string[]>>({}); // [list]: symbols[]
  const [lastPrice, setLastPrice]  = useState<Dictionary<LastPrice>>({});
  // Stock prices fetched
  const [stockHistoryWeek, setStockHistoryWeek]  = useState<Dictionary<StockHistory>>({});
  const [stockHistoryYear, setStockHistoryYear]  = useState<Dictionary<StockHistory>>({});
  // Stock search
  const [searchResult, setSearchResult] = useState<string | undefined>(undefined);
  // import
  const [importFromBank, setImportFromBank] = useState<boolean>(false);
  const [refreshStocks, setRefreshStocks] = useState<{ weekHistory: string[]; yearHistory: string[]; lastPrice: string[] }>({ weekHistory: [], yearHistory: [], lastPrice: [] });
  const [transactions, setTransactions] = useState<SymbolStockData<StockTransaction>>({});
  const [holdings, setHoldings] = useState<SymbolStockData<StockHolding>>({});
  const [orders, setOrders] = useState<SymbolStockData<StockOrder>>({});
  const [brokerAccounts, setBrokerAccounts] = useState<Dictionary<Dictionary<PlaidAccount>>>({});
  // const [securities, setSecurities] = useState<Dictionary<Dictionary<PlaidSecurity>>>({});
  const [importFromCsv, setImportFromCsv] = useState<{ file: string, columns: string[], rows: string[][] } | undefined>(undefined);
  // Data from the server
  const [linkedInstitutions, setLinkedInstitutions] = useState<PlaidLinkedInstitution[] | undefined>(undefined);
  const [stockCollectionData, setStockCollectionData] = useState<{ createNew: boolean, ordinal?: number, collection?: DataCollectionData }>({ createNew: false });
  const [stockObj, setStockObj] = useState<StockObjEntries | undefined>(undefined);
  const [stockEntries, setStockEntries] = useState<Dictionary<{ entry: DataEntry, lastUpdated: EntryLastUpdated}> | undefined>(undefined);
  // Save to server
  const [updateStockEntries, setUpdateStockEntries] = useState<UpdateStockEntries[]>([]);
  const [addStockData, setAddStockData] = useState<StockDataType | undefined>(undefined);
  const [detailType, setDetailType] = useState<ViewListType>(ViewListType.None);

  const stockDetailsRef = useRef<Dictionary<Stock>>({});
  stockDetailsRef.current = stockDetails;
  const lastPriceRef = useRef<Dictionary<LastPrice>>({});
  lastPriceRef.current = lastPrice;
  const stockEntriesRef = useRef<Dictionary<{ entry: DataEntry, lastUpdated: EntryLastUpdated }> | undefined>({});
  stockEntriesRef.current = stockEntries;
  const holdingsRef = useRef<SymbolStockData<StockHolding>>({});
  holdingsRef.current = holdings;
  const transactionsRef = useRef<SymbolStockData<StockTransaction>>({});
  transactionsRef.current = transactions;
  const ordersRef = useRef<SymbolStockData<StockOrder>>({});
  ordersRef.current = orders;
  const stockImportEnabled = authContext.isFeatureEnabled(Features.StockImport);

  const sandboxKey = s2pConfig.PLAID_ENV === 'sandbox' ? '-sandbox' : '';

  const dataCollections = <GetDataCollections key={'initial-fetch'} updateApiResponse={(data?: any, loading?: any, error?: any) => {
    if (error) {
      errorNotification(`${QRY_ERROR} Failed to get collection data.`);
    }
    if (data) {
      const collection = data.find((collection: DataCollectionData) => collection.name === 'Finance Investment Portfolio');
      if (!collection) {
        setStockCollectionData({ createNew: true, ordinal: data.length });
      }
      else {
        setStockCollectionData({ createNew: false, ordinal: undefined, collection });
      }
    }
  }}/>;

  const newCollection = stockCollectionData.createNew && stockCollectionData.ordinal !== undefined ? <AddCollection key={new Date().toUTCString()} collectionInput={{ name: 'Finance Investment Portfolio', ordinal: stockCollectionData.ordinal }} onUpdate={(data?: AddDataCollectionMutation | null, loading?: boolean, error?: any) => {
    if (error) {
      errorNotification(`${QRY_ERROR} Failed to create collection data.`);
      return;
    }

    if (data && data.addDataCollection) {
      setStockCollectionData({ createNew: false, ordinal: undefined, collection: data.addDataCollection });
    }
  }} /> : null;

  const getStockEntries = stockCollectionData.collection && stockEntries === undefined ? <GetDataEntries
    collectionUuids={[stockCollectionData.collection.uuid]}
    key={`get-stock-entries`}
    updateApiResponse={(data?: DataEntry[], loading?: boolean, error?: any) => {
      if (data) {
        const initTransactions: SymbolStockData<StockTransaction> = {};
        const initHoldings: SymbolStockData<StockHolding> = {};
        const initOrders: SymbolStockData<StockOrder> = {};
        const initStockDetails: Dictionary<Stock> = {};
        const initLists: Dictionary<string[]> = {};
        const refetchStockDetails: Dictionary<string> = {};
        const fixTransactions: SymbolStockData<StockTransaction> = {};
        let missingTitle = 0;

        const activeData = data.filter((removeInactive: DataEntry) => removeInactive.active);

        activeData.forEach((entryData: DataEntry) => {
          if (!entryData.title) {
            missingTitle += 1;
          }
          const initSymbol = entryData.title ? /* (entryData.title.startsWith('CUR:') ? entryData.title.split(':')[1] : entryData.title) */ entryData.title : `Missing Title ${missingTitle}`;
          if (entryData.data) {
            entryData.data.forEach((stockData: DataEntryData) => {
              if (!stockData.data || stockData.data === '') {
                return;
              }
              const initData = JSON.parse(stockData.data);
              if (stockData.entryUuid === stockObj?.detailsUuid) {
                // old version had stockDetails, new version has { details: StockDetails, mappedSymbols: string

                initStockDetails[initSymbol] = initData.details ?? {
                  ticker: initData.ticker ?? '',
                  name: initData.name ?? '',
                  description: initData.description ?? '',
                  isCrypto: initData.isCrypto === true,
                  cusip: initData.cusip,
                  isin: initData.isin,
                  sedol: initData.sedol,
                  securityId: initData.securityId,
                };
                if (initData.mappedSymbols) {
                  initStockDetails[initSymbol].mappedSymbols = initData.mappedSymbols;
                }
                if (initData.isMapped) {
                  initStockDetails[initSymbol].isMapped = initData.isMapped;
                }
                if (initData.isUnknown) {
                  initStockDetails[initSymbol].isUnknown = initData.isUnknown;
                }
              }
              else if (stockData.entryUuid === stockObj?.holdingsUuid) {
                initHoldings[initSymbol] = initData;
              }
              else if (stockData.entryUuid === stockObj?.transactionsUuid) {
                initTransactions[initSymbol] = initData;
                Object.keys(initTransactions[initSymbol]).forEach((broker: string) => {
                  Object.keys(initTransactions[initSymbol][broker]).forEach((account: string) => {
                    initTransactions[initSymbol][broker][account] = initTransactions[initSymbol][broker][account].map((fix: StockTransaction) => {
                      if (initStockDetails[initSymbol] && !initStockDetails[initSymbol].securityId) {
                        initStockDetails[initSymbol].securityId = fix.securityId;
                      }
                      if (fix.type === 'buy' && fix.quantity < 0) {
                        fix.quantity = -fix.quantity;
                      }
                      if (fix.type === 'buy' && fix.amount < 0) {
                        fix.amount = -fix.amount;
                      }
                      if (fix.uuid === '') {
                        fix.uuid = uuid();
                      }
                      return fix;
                    });
                  });
                });
              }
              else if (stockData.entryUuid === stockObj?.ordersUuid) {
                initOrders[initSymbol] = initData;
              }
              else if (stockData.entryUuid === stockObj?.listsUuid) {
                initData.forEach((initList: string) => {
                  if (!initLists[initList]) {
                    initLists[initList] = [initSymbol];
                  }
                  else if (!initLists[initList].includes(initList)) {
                    initLists[initList].push(initSymbol);
                  }
                });
              }
            });
          }
          if (!initStockDetails[initSymbol]) {
            refetchStockDetails[initSymbol] = initSymbol;
            if (!initLists[DefaultStockList.FixError] || !initLists[DefaultStockList.FixError].includes(initSymbol)) {
              if (initLists[DefaultStockList.FixError]) {
                initLists[DefaultStockList.FixError].push(initSymbol);
              }
              else {
                initLists[DefaultStockList.FixError] = [initSymbol];
              }
            }
          }
        });

        setTransactions(initTransactions);
        setHoldings(initHoldings);
        setOrders(initOrders);
        setLists(initLists);
        setStockDetails(initStockDetails);
        missingTitle = 0;
        setStockEntries(Object.assign({}, ...activeData.map((stockData: DataEntry) => {
          if (!stockData.title) {
            missingTitle += 1;
          }
          const initSymbol = stockData.title ? /* (stockData.title.startsWith('CUR:') ? stockData.title.split(':')[1] : stockData.title) */ stockData.title : `Missing Title ${missingTitle}`;
          const initLastUpdated = stockData.data.find((updatedEntry: DataEntryData) => updatedEntry.entryUuid === stockObj?.lastViewedUuid);

          return { [initSymbol]: { entry: stockData, lastUpdated: initLastUpdated && initLastUpdated.data && initLastUpdated.data !== '' ? JSON.parse(initLastUpdated.data) : {} } };
        })));
        if (Object.keys(refetchStockDetails).length > 0) {
          setStockDetailsNotFound((prevStockDetailsNotFound) => {
            const updatePrevStockDetailsNotFound = Object.assign({}, prevStockDetailsNotFound, refetchStockDetails);
            return updatePrevStockDetailsNotFound;
          });
        }
      }
      if (error) {
        errorNotification(`${QRY_ERROR} Failed to get data entries.`);
      }
    }}/> : null;

  const getStockObject = stockCollectionData.collection && stockObj === undefined ? <GetDataObjects
    key={`get-stock-object`}
    updateApiResponse={(data?: DataObject[], loading?: boolean, error?: any) => {
      if (data) {
        const stockDataObject = data.find((dataObj: DataObject) => dataObj.uuid === STOCK_OBJ_UUID);
        if (stockObj === undefined && stockDataObject && stockDataObject.entries) {
          const objUuids: StockObjEntries = {
            listsUuid: stockDataObject.entries.find((objEntry: DataObjectEntry) => objEntry.name === 'Lists')!.uuid,
            transactionsUuid: stockDataObject.entries.find((objEntry: DataObjectEntry) => objEntry.name === 'Transactions')!.uuid,
            holdingsUuid: stockDataObject.entries.find((objEntry: DataObjectEntry) => objEntry.name === 'Holdings')!.uuid,
            ordersUuid: stockDataObject.entries.find((objEntry: DataObjectEntry) => objEntry.name === 'Orders')!.uuid,
            detailsUuid: stockDataObject.entries.find((objEntry: DataObjectEntry) => objEntry.name === 'StockDetails')!.uuid,
            lastViewedUuid: stockDataObject.entries.find((objEntry: DataObjectEntry) => objEntry.name === 'LastViewed')!.uuid,
          };
          setStockObj(objUuids);
        }
      }
      if (error) {
        errorNotification(`${QRY_ERROR} Failed to get data object.`);
      }
    }}/> : null;

  useLayoutEffect(() => {
    if (ticker && ticker !== '') {
      const pageContentComponent = document.getElementById('pageContentDiv');
      if (pageContentComponent) {
        pageContentComponent.scrollTo(0, 0);
      }
    }
  },              [ticker]);

  useEffect(() => {
    if (stockObj === undefined || stockCollectionData.collection === undefined) {
      if (!isLoading) {
        setIsLoading(true);
      }
    }
    else if (isLoading) {
      setIsLoading(false);
    }
  },        [stockObj, stockCollectionData, isLoading]);

  useEffect(() => {
    if (!stockDetails) {
      return;
    }
    const checkIsMapped: Dictionary<boolean> = {};
    const updateDataMapped: string[] = [];
    Object.keys(stockDetails).forEach((checkSymbol) => {
      if (stockDetails[checkSymbol].isUnknown) {
        checkIsMapped[checkSymbol] = stockDetails[checkSymbol].isMapped === true;
      }
    });
    Object.keys(stockDetails).forEach((checkSymbol: string) => {
      if (stockDetails[checkSymbol].mappedSymbols !== undefined) {
        stockDetails![checkSymbol]!.mappedSymbols!.forEach((mapped: string) => {
          if (!checkIsMapped[mapped]) {
            updateDataMapped.push(mapped);
          }
          delete checkIsMapped[mapped];
        });
      }
    });
    updateDataMapped.forEach((updateMappedSymbol: string) => updateStockEntryData({ [updateMappedSymbol]: { updateIsMapped: true } }));
    Object.keys(checkIsMapped).forEach((updateNotMappedSymbol: string) => {
      if (checkIsMapped[updateNotMappedSymbol]) {
        updateStockEntryData({ [updateNotMappedSymbol]: { updateIsMapped: false } });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stockDetails]);

  useEffect(() => {
    if (!stockEntries) {
      return;
    }
    // If title is not upper case, update the stock entry;
    Object.keys(stockEntries).forEach((symbolToUpper: string) => {
      if (stockEntries[symbolToUpper].entry.title !== symbolToUpper.toUpperCase()) {
        updateStockEntryData({ [symbolToUpper.toUpperCase()]: { } });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stockEntries]);

  useEffect(() => {
    if (stockObj !== undefined && stockCollectionData !== undefined) {
      if (dataState.stockObjs === DataState.fetch) {
        const updateDataState = { ...dataState };
        updateDataState.stockObjs = DataState.loaded;
        setDataState(updateDataState);
      }
      localStorage.setItem(`s2p-stocks-entry-objs${sandboxKey}`, JSON.stringify({ collectionData: stockCollectionData, dataObject: stockObj }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stockObj, stockCollectionData]);

  useEffect(() => {
    if (dataState.stockObjs === DataState.uninitialized) {
      const cachedObjsString = localStorage.getItem(`s2p-stocks-entry-objs${sandboxKey}`);
      if (cachedObjsString) {
        const cachedObjs = JSON.parse(cachedObjsString);
        setStockCollectionData(cachedObjs.collectionData);
        setStockObj(cachedObjs.dataObject);
      }
      const updateDataState = { ...dataState };
      updateDataState.stockObjs = cachedObjsString ? DataState.cached : DataState.fetch;
      setDataState(updateDataState);
    }
    /*
    if (stocks.length === 0) {
      const savedStocks = (localStorage.getItem(`s2p-stocks${sandboxKey}`)?.split(',') ?? []).filter((stock: string) => stock !== '');
      setStocks(savedStocks);
    }
    if (stocks.length === 0) {
      const savedInvestments = localStorage.getItem(`s2p-stock-investments${sandboxKey}`) ?? '{}';
      const parsedInvestments = JSON.parse(savedInvestments);
      setHoldings(parsedInvestments.holdings ?? {});
      setSecurities(parsedInvestments.securities ?? {});
      setBrokerAccounts(parsedInvestments.accounts ?? {});
      setTransactions(parsedInvestments.transactions ?? {});
    }
    if (Object.keys(stockDetails).length === 0) {
      const savedStockDetails = (localStorage.getItem(`s2p-stock-details${sandboxKey}`) ?? '{}');
      setStockDetails(JSON.parse(savedStockDetails));
    }
    */
    /*
    if (recentStocks.length === 0) {
      const getRecentStocks = localStorage.getItem(`s2p-stocks-recent${sandboxKey}`);
      const getRecentStocksValue = getRecentStocks ? JSON.parse(getRecentStocks) : [];
      setRecentStocks(getRecentStocksValue);
    }
    */
    /*
    if (Object.keys(lastPrice).length === 0) {
      const savedLastPrice = localStorage.getItem(`s2p-stocks-last-price${sandboxKey}`) ?? '{}';
      setLastPrice(JSON.parse(savedLastPrice));
    }
    */
    if (Object.keys(stockHistoryWeek).length === 0) {
      const savedHistoryWeek = localStorage.getItem(`s2p-stocks-history-week${sandboxKey}`) ?? '{}';
      setStockHistoryWeek(JSON.parse(savedHistoryWeek));
    }
    if (Object.keys(stockHistoryYear).length === 0) {
      const savedHistoryYear = localStorage.getItem(`s2p-stocks-history-year${sandboxKey}`) ?? '{}';
      setStockHistoryYear(JSON.parse(savedHistoryYear));
    }
    if (Object.keys(stockHistoryYear).length === 0) {
      const savedHistoryYear = localStorage.getItem(`s2p-stocks-history-year${sandboxKey}`) ?? '{}';
      setStockHistoryYear(JSON.parse(savedHistoryYear));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  /*
  useEffect(() => {
    try {
      localStorage.setItem(`s2p-stocks${sandboxKey}`, stocks.join(','));
    }
    catch (e) {
      console.log(`Failed to cached s2p-stocks`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stocks]);
  */

  /*
  useEffect(() => {
    const saveAccounts = Object.keys(brokerAccounts)
      .map((broker: string) => ({ [broker]: Object.keys(brokerAccounts[broker])
        .map((account: string) => ({
          accountId: brokerAccounts[broker][account].accountId,
          lastFour: brokerAccounts[broker][account].lastFour,
          name: brokerAccounts[broker][account].name,
        })),
      }));
    const saveSecurities = Object.keys(securities)
      .map((securityId: string) => ({ [securityId]: Object.keys(securities[securityId])
        .map((broker: string) => ({
          cusip: securities[securityId][broker].cusip,
          name: securities[securityId][broker].name,
          securityId: securities[securityId][broker].securityId,
          symbol: securities[securityId][broker].symbol,
        })),
      }));
    try {
      localStorage.setItem(`s2p-stock-investments${sandboxKey}`, JSON.stringify({ holdings, transactions, securities: saveSecurities, accounts: saveAccounts }));
    }
    catch (e)  {
      console.log(`Failed to cached s2p-stock-investments`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [holdings, transactions, securities, brokerAccounts]);
  */

  /*
  useEffect(() => {
    try {
      localStorage.setItem(`s2p-stocks-recent${sandboxKey}`, JSON.stringify(recentStocks));
    }
    catch (e)  {
      console.log(`Failed to cached s2p-stocks-recent`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [recentStocks]);
  */

  useEffect(() => {
    try {
      const saveWeekHistory = Object.keys(stockHistoryWeek).filter((symbol: string) => moment(new Date()).diff(moment(stockHistoryWeek[symbol].lastFetched), 'hour') < 4).map((saveSymbol: string) => ({ [saveSymbol]: stockHistoryWeek[saveSymbol] }));
      localStorage.setItem(`s2p-stocks-history-week${sandboxKey}`, JSON.stringify(saveWeekHistory));
    }
    catch (e)  {
      console.error('failed to cache week history');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stockHistoryWeek]);

  useEffect(() => {
    try {
      const saveYearHistory = Object.keys(stockHistoryYear).filter((symbol: string) => moment(new Date()).diff(moment(stockHistoryYear[symbol].lastFetched), 'hour') < 12).map((saveSymbol: string) => ({ [saveSymbol]: stockHistoryWeek[saveSymbol] }));
      localStorage.setItem(`s2p-stocks-history-year${sandboxKey}`, JSON.stringify(saveYearHistory));
    }
    catch (e)  {
      console.error('failed to cache year history');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stockHistoryYear]);

  /*
  useEffect(() => {
    try {
      localStorage.setItem(`s2p-stocks-last-price${sandboxKey}`, JSON.stringify(lastPrice));
    }
    catch (e)  {
      console.log(`Failed to cached s2p-stocks-last-price`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [lastPrice]);
  */

  useEffect(() => {
    if (Object.keys(lists).length === 0) {
      const savedStockLists = localStorage.getItem(`s2p-stock-lists${sandboxKey}`) ?? '[]';
      const savedStockListsValue = JSON.parse(savedStockLists);
      setLists(savedStockListsValue);
    }
    if (Object.keys(brokerAccounts).length === 0) {
      const savedAccounts = localStorage.getItem(`s2p-stock-accounts${sandboxKey}`) ?? '{}';
      const savedAccountsValue = JSON.parse(savedAccounts);
      setBrokerAccounts(savedAccountsValue);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    try {
      localStorage.setItem(`s2p-stock-lists${sandboxKey}`, JSON.stringify(lists));
    }
    catch (e)  {
      console.log(`Failed to cached s2p-stocks-lists`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [lists]);

  /*
  useEffect(() => {
    try {
      localStorage.setItem(`s2p-stock-details${sandboxKey}`, JSON.stringify(stockDetails));
    }
    catch (e) {
      console.log(`Failed to cached s2p-stocks-details`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [stockDetails]);
  */

  useEffect(() => {
    if (Object.keys(brokerAccounts).length > 0) {
      try {
        localStorage.setItem(`s2p-stock-accounts${sandboxKey}`, JSON.stringify(brokerAccounts));
      }
      catch (e) {
        console.log(`Failed to cached s2p-stock-accounts`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [brokerAccounts]);

  const onUpdatedStockEntry = (dataEntry: DataEntry) => {
    const oldDataEntryLastUpdated: EntryLastUpdated = stockEntriesRef.current && stockEntriesRef.current[dataEntry.title] ? { ...stockEntriesRef.current[dataEntry.title].lastUpdated } : {};
    const updateSymbol = dataEntry.title;
    const updatedDetails = dataEntry.data.find((data: DataEntryData) => data.entryUuid === stockObj!.detailsUuid);
    const updatedTransactions = dataEntry.data.find((data: DataEntryData) => data.entryUuid === stockObj!.transactionsUuid);
    const updatedHoldings = dataEntry.data.find((data: DataEntryData) => data.entryUuid === stockObj!.holdingsUuid);
    const updatedOrders = dataEntry.data.find((data: DataEntryData) => data.entryUuid === stockObj!.ordersUuid);
    const updatedLists = dataEntry.data.find((data: DataEntryData) => data.entryUuid === stockObj!.listsUuid);
    const updatedLastUpdatedObj = dataEntry.data.find((data: DataEntryData) => data.entryUuid === stockObj!.lastViewedUuid);
    const updatedLastUpdated: EntryLastUpdated = updatedLastUpdatedObj && updatedLastUpdatedObj.data && updatedLastUpdatedObj.data !== '' ? JSON.parse(updatedLastUpdatedObj.data) : {};
    setStockEntries((prevStockEntries) => {
      const updateStockEntries = { ...prevStockEntries };
      if (!updateStockEntries[updateSymbol]) {
        updateStockEntries[updateSymbol] = { entry: dataEntry, lastUpdated: {} };
      }
      else {
        updateStockEntries[updateSymbol].entry = dataEntry;
      }
      updateStockEntries[updateSymbol].lastUpdated = updatedLastUpdated;
      return updateStockEntries;
    });
    // TODO Add support for orders
    // const updatedOrders = dataEntry.data.find((data: DataEntryData) => data.entryUuid === stockObj!.ordersUuid);
    if (updatedDetails && updatedDetails.data && updatedDetails.data !== '' && oldDataEntryLastUpdated.details !== updatedLastUpdated.details) {
      const dataObj = JSON.parse(updatedDetails.data);
      const details: StockDetails = dataObj.details ?? dataObj;
      const currentStockDetails = stockDetailsRef.current;
      if (!currentStockDetails[details.ticker]
        || currentStockDetails[details.ticker].isMapped !== dataObj.isMapped
        || currentStockDetails[details.ticker].isUnknown !== dataObj.isUnknown
        || currentStockDetails[details.ticker].mappedSymbols !== dataObj.mappedSymbols
        || currentStockDetails[details.ticker].ticker !== details.ticker
        || currentStockDetails[details.ticker].name !== details.name
        || currentStockDetails[details.ticker].isCrypto !== details.isCrypto
        || currentStockDetails[details.ticker].description !== details.description
        || currentStockDetails[details.ticker].cusip !== details.cusip
        || currentStockDetails[details.ticker].isin !== details.isin
        || currentStockDetails[details.ticker].sedol !== details.sedol
        ) {
        setStockDetails((prevStockDetails: Dictionary<Stock>) => {
          if (prevStockDetails[updateSymbol] && prevStockDetails[updateSymbol].mappedSymbols === dataObj.mappedSymbols) {
            return prevStockDetails;
          }
          const updateStockDetails = { ...prevStockDetails };

          updateStockDetails[updateSymbol] = {
            ticker: details.ticker,
            name: details.name,
            description: details.description,
            isCrypto: details.isCrypto === true,
            mappedSymbols: dataObj.mappedSymbols,
            isMapped: dataObj.isMapped,
            isUnknown: dataObj.isUnknown,
            cusip: dataObj.cusip,
            isin: dataObj.isin,
            sedol: dataObj.sedol,
          };
          return updateStockDetails;
        });
      }
      if (lists[DefaultStockList.FixError] && lists[DefaultStockList.FixError].includes(details.ticker)) {
        setLists((prevLists) => {
          const updatePrevLists = { ...prevLists };
          updatePrevLists[DefaultStockList.FixError] = updatePrevLists[DefaultStockList.FixError].filter((removeSymbol: string) => removeSymbol !== details.ticker);
          if (updatePrevLists[DefaultStockList.FixError].length === 0) {
            delete updatePrevLists[DefaultStockList.FixError];
          }
          return prevLists;
        });
      }
    }
    if (updatedTransactions && updatedTransactions.data && updatedTransactions.data !== ''  && oldDataEntryLastUpdated.transactions !== updatedLastUpdated.transactions) {
      const transEntryObj = JSON.parse(updatedTransactions.data);
      const trans: BrokerStockData<StockTransaction> = transEntryObj.lastUpdated ? transEntryObj.transactions : transEntryObj;
      setTransactions((prevTransactions: SymbolStockData<StockTransaction>) => {
        const updatePrevTransactions = { ...prevTransactions };
        // TODO Check if transactions changed
        updatePrevTransactions[updateSymbol] = trans;
        return updatePrevTransactions;
      });
      infoNotification(`successfully imported investment transactions in broker bank ${Object.keys(trans).join(', ')} for stocks ${updateSymbol}`);
    }
    if (updatedHoldings && updatedHoldings.data && updatedHoldings.data !== ''  && oldDataEntryLastUpdated.holdings !== updatedLastUpdated.holdings) {
      const holdEntryObj = JSON.parse(updatedHoldings.data);
      const hold: BrokerStockData<StockHolding> = holdEntryObj.lastUpdated ? holdEntryObj.holdings : holdEntryObj;
      setHoldings((prevHoldings: SymbolStockData<StockHolding>) => {
        const updatePrevHoldings = { ...prevHoldings };
        updatePrevHoldings[updateSymbol] = hold;
        /*
        Object.keys(hold).forEach((symbol: string) => {
          // TODO check if anything changed
          updatePrevHoldings[symbol] = hold;
        });
        */
        return updatePrevHoldings;
      });
      infoNotification(`successfully imported investment holdings for ${updateSymbol}`);
    }
    if (updatedOrders && updatedOrders.data && updatedOrders.data !== '' && oldDataEntryLastUpdated.orders !== updatedLastUpdated.orders) {
      const updateOrders: BrokerStockData<StockOrder> = JSON.parse(updatedOrders.data);
      setOrders((prevOrders: SymbolStockData<StockOrder>) => {
        const updatePrevOrders = { ...prevOrders };
        // TODO Check if orders changed
        updatePrevOrders[updateSymbol] = updateOrders;
        return updatePrevOrders;
      });
      infoNotification(`successfully added new orders in broker bank ${Object.keys(updateOrders).join(', ')} for stocks ${updateSymbol}`);
    }
    if (updatedLists && updatedLists.data && updatedLists.data !== '') {
      const newLists: string[] = JSON.parse(updatedLists.data);
      setLists((prevLists: Dictionary<string[]>) => {
        let isUpdated = false;
        const updatePrevLists = { ...prevLists };
        // TODO check if anything changed
        Object.keys(prevLists).forEach((list: string) => {
          const partOfList = newLists.find((symbolList: string) => symbolList === list);
          const partOfPrevList = prevLists[list].find((symbol: string) => symbol === updateSymbol);
          if ((partOfList && partOfPrevList) || (!partOfList && !partOfPrevList)) {
            return;
          }
          isUpdated = true;
          if (partOfList) {
            updatePrevLists[list].push(updateSymbol);
          }
          else {
            updatePrevLists[list] = updatePrevLists[list].filter((removeSymbol: string) => removeSymbol !== updateSymbol);
          }
        });
        return isUpdated ? updatePrevLists : prevLists;
      });
    }
  };

  const updateStockEntryData = (
    updateStockData:Dictionary<UpdateStockEntryData>) => {
    const currentTransactions = { ...transactionsRef.current };
    const updateDate = new Date();
    const updateLastUpdated: Dictionary<EntryLastUpdated> = {};
    const changedLastUpdated: Dictionary<boolean> = {};
    // remove merged transactions and updated transactions where broker or account is changed. :
    Object.keys(updateStockData).forEach((stockValue: string) => {
      const stock = stockValue.toUpperCase(); // ensure stock is always all upper case
      updateLastUpdated[stock] = stockEntriesRef.current && stockEntriesRef.current[stock] ? { ...stockEntriesRef.current[stock].lastUpdated } : {};
      if (updateStockData[stock].transactions && updateStockData[stock]!.transactions!.length > 0) {
        updateLastUpdated[stock].transactions = updateDate;
        changedLastUpdated[stock] = true;
        updateStockData[stock].transactions!.forEach((removeMergedOrUpdated: StockTransaction) => {
          // remove merged
          if (removeMergedOrUpdated.transactionIds && removeMergedOrUpdated.transactionIds.length > 0 && currentTransactions[stock] && currentTransactions[stock][removeMergedOrUpdated.broker] && currentTransactions[stock][removeMergedOrUpdated.broker][removeMergedOrUpdated.accountId]) {
            // remove all including old transaction that got updated with merged data, it will be added below.
            currentTransactions[stock][removeMergedOrUpdated.broker][removeMergedOrUpdated.accountId] = currentTransactions[stock][removeMergedOrUpdated.broker][removeMergedOrUpdated.accountId].filter((remove: StockTransaction) => !removeMergedOrUpdated.transactionIds!.find((merged: StockMergedTransaction) => merged.transactionId === remove.transactionId));
          }
          // remove updated
          if (currentTransactions[stock]) {
            Object.keys(currentTransactions[stock]).forEach((broker: string) => {
              if (removeMergedOrUpdated.broker !== broker) {
                Object.keys(currentTransactions[stock][broker]).forEach((account: string) => {
                  if (removeMergedOrUpdated.accountId !== account) {
                    const updatedWithRemovedTransaction = currentTransactions[stock][broker][account].filter((toRemove: StockTransaction) => toRemove.uuid !== removeMergedOrUpdated.uuid);
                    if (updatedWithRemovedTransaction.length === 0) {
                      delete currentTransactions[stock][broker][account];
                      if (Object.keys(currentTransactions[stock][broker]).length === 0) {
                        delete currentTransactions[stock][broker];
                        if (Object.keys(currentTransactions[stock]).length === 0) {
                          delete currentTransactions[stock];
                        }
                      }
                    }
                    else if (updatedWithRemovedTransaction.length !== currentTransactions[stock][broker][account].length) {
                      currentTransactions[stock][broker][account] = updatedWithRemovedTransaction;
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
    const currentHoldings = { ...holdingsRef.current };
    const currentOrders = { ...ordersRef.current };
    if (stockObj === undefined || stockCollectionData.collection === undefined) {
      console.log('UpdateStockEntryDate got triggered when stockObj and stockCollectionData is not initialized yet.');
      return;
    }
    const addEntries: DataEntryInput[] = [];
    const updateEntries: DataEntryInput[] = [];

    Object.keys(updateStockData).forEach((symbol: string) => {
      const dataExists: boolean = stockEntriesRef.current !== undefined && stockEntriesRef.current[symbol] !== undefined;
      const updateStockEntriesData: DataEntryData[] = [];
      // Transactions
      if ((updateStockData[symbol].transactions && updateStockData[symbol].transactions!.length > 0)
        || (updateStockData[symbol].removeTransactions && updateStockData[symbol].removeTransactions!.length > 0)) {
        const updatedSymbolTransactions = currentTransactions[symbol] ? { ...currentTransactions[symbol] } : {};
        let transactionsUpdated: boolean = false;
        if (updateStockData[symbol].removeTransactions) {
          updateStockData[symbol].removeTransactions!.forEach((removeTransaction: StockTransaction) => {
            if (!updatedSymbolTransactions[removeTransaction.broker]) {
              return;
            }
            if (!updatedSymbolTransactions[removeTransaction.broker][removeTransaction.accountId]) {
              return;
            }
            const currentLength = updatedSymbolTransactions[removeTransaction.broker][removeTransaction.accountId].length;
            updatedSymbolTransactions[removeTransaction.broker][removeTransaction.accountId] = updatedSymbolTransactions[removeTransaction.broker][removeTransaction.accountId].filter((existingTransaction: StockTransaction) => existingTransaction.uuid !== removeTransaction.uuid);
            const newLength = updatedSymbolTransactions[removeTransaction.broker][removeTransaction.accountId].length;
            if (newLength === 0) {
              delete updatedSymbolTransactions[removeTransaction.broker][removeTransaction.accountId];
              if (Object.keys(updatedSymbolTransactions[removeTransaction.broker]).length === 0) {
                delete updatedSymbolTransactions[removeTransaction.broker];
              }
              transactionsUpdated = true;
            }
            else if (currentLength !== newLength) {
              transactionsUpdated = true;
            }
          });
        }
        if (updateStockData[symbol].transactions) {
          updateStockData[symbol].transactions!.forEach((importTransaction: StockTransaction) => {
            if (!updatedSymbolTransactions[importTransaction.broker]) {
              updatedSymbolTransactions[importTransaction.broker] = {};
            }
            if (!updatedSymbolTransactions[importTransaction.broker][importTransaction.accountId]) {
              updatedSymbolTransactions[importTransaction.broker][importTransaction.accountId] = [];
            }
            if (!updatedSymbolTransactions[importTransaction.broker][importTransaction.accountId].find((existingTransaction: StockTransaction) => existingTransaction.transactionId === importTransaction.transactionId || existingTransaction.uuid === importTransaction.uuid)) {
              transactionsUpdated = true;
              updatedSymbolTransactions[importTransaction.broker][importTransaction.accountId].push(importTransaction);
            }
            const updateTransactionIndex = updatedSymbolTransactions[importTransaction.broker][importTransaction.accountId].findIndex((existingTransaction: StockTransaction) => existingTransaction.uuid === importTransaction.uuid);
            if (updateTransactionIndex >= 0) {
              transactionsUpdated = true;
              updatedSymbolTransactions[importTransaction.broker][importTransaction.accountId][updateTransactionIndex] = importTransaction;
            }
          });
        }
        if (transactionsUpdated) {
          updateLastUpdated[symbol].transactions = updateDate;
          changedLastUpdated[symbol] = true;
          updateStockEntriesData.push({
            entryUuid: stockObj.transactionsUuid,
            data: JSON.stringify(updatedSymbolTransactions),
            /* updateStockData[symbol].transactions
              ? JSON.stringify(updateStockData[symbol].transactions)
              : (dataExists ? (stockEntries![symbol].data.find((transData: DataEntryData) => transData.entryUuid === stockObj.transactionsUuid)?.data ?? emptyData) : emptyData),
            */
          });
        }
      }
      // Holdings
      if (updateStockData[symbol].holdings || updateStockData[symbol].removeHoldings) {
        const updatedSymbolHoldings = currentHoldings[symbol] ? { ...currentHoldings[symbol] } : {};
        let holdingsUpdated: boolean = false;
        if (updateStockData[symbol].removeHoldings) {
          updateStockData[symbol].removeHoldings!.forEach((importHolding: StockHolding) => {
            if (!updatedSymbolHoldings[importHolding.broker]) {
              return;
            }
            if (!updatedSymbolHoldings[importHolding.broker][importHolding.accountId]) {
              return;
            }
            // TODO: if no change, dont update.
            // if (!updatedSymbolHoldings[importHolding.broker][importHolding.accountId].find((existingHolding: StockHolding) => existingHolding.priceDate === importHolding.priceDate && existingHolding.quantity === importHolding.quantity)) {
            if (updatedSymbolHoldings[importHolding.broker][importHolding.accountId].length > 0) {
              holdingsUpdated = true;
              updatedSymbolHoldings[importHolding.broker][importHolding.accountId] = [];
            }
          });
        }
        if (updateStockData[symbol].holdings) {
          updateStockData[symbol].holdings!.forEach((importHolding: StockHolding) => {
            if (!updatedSymbolHoldings[importHolding.broker]) {
              updatedSymbolHoldings[importHolding.broker] = {};
            }
            if (!updatedSymbolHoldings[importHolding.broker][importHolding.accountId]) {
              updatedSymbolHoldings[importHolding.broker][importHolding.accountId] = [];
            }
            // TODO: if no change, dont update.
            // if (!updatedSymbolHoldings[importHolding.broker][importHolding.accountId].find((existingHolding: StockHolding) => existingHolding.priceDate === importHolding.priceDate && existingHolding.quantity === importHolding.quantity)) {
            holdingsUpdated = true;
            updatedSymbolHoldings[importHolding.broker][importHolding.accountId] = [importHolding];
          });
        }
        if (holdingsUpdated) {
          updateLastUpdated[symbol].holdings = updateDate;
          changedLastUpdated[symbol] = true;
          updateStockEntriesData.push({
            entryUuid: stockObj.holdingsUuid,
            data: JSON.stringify(updatedSymbolHoldings),
            /*
            updateStockData[symbol].holdings
              ? JSON.stringify(updateStockData[symbol].holdings)
              : (dataExists ? (stockEntries![symbol].data.find((holdingsData: DataEntryData) => holdingsData.entryUuid === stockObj.holdingsUuid)?.data ?? emptyData) : emptyData),
            */
          });
        }
      }
      // Orders
      if (updateStockData[symbol].orders || updateStockData[symbol].removeOrders) {
        const updatedSymbolOrders = currentOrders[symbol] ? { ...currentOrders[symbol] } : {};
        let ordersUpdated: boolean = false;
        if (updateStockData[symbol].orders) {
          updateStockData[symbol].orders!.forEach((addOrder: StockOrder) => {
            if (!updatedSymbolOrders[addOrder.broker]) {
              updatedSymbolOrders[addOrder.broker] = {};
            }
            if (!updatedSymbolOrders[addOrder.broker][addOrder.accountId]) {
              updatedSymbolOrders[addOrder.broker][addOrder.accountId] = [];
            }
            if (!updatedSymbolOrders[addOrder.broker][addOrder.accountId].find((existingOrder: StockOrder) => existingOrder.uuid === addOrder.uuid)) {
              ordersUpdated = true;
              updatedSymbolOrders[addOrder.broker][addOrder.accountId].push(addOrder);
            }
          });
        }
        if (updateStockData[symbol].removeOrders) {
          updateStockData[symbol].removeOrders!.forEach((removeOrder: StockOrder) => {
            if (!updatedSymbolOrders[removeOrder.broker]) {
              return;
            }
            if (!updatedSymbolOrders[removeOrder.broker][removeOrder.accountId]) {
              return;
            }
            if (updatedSymbolOrders[removeOrder.broker][removeOrder.accountId].find((existingOrder: StockOrder) => existingOrder.uuid === removeOrder.uuid)) {
              updatedSymbolOrders[removeOrder.broker][removeOrder.accountId] = currentOrders[removeOrder.symbol][removeOrder.broker][removeOrder.accountId].filter((existingOrder: StockOrder) => existingOrder.uuid !== removeOrder.uuid);
              ordersUpdated = true;
            }
          });
        }
        if (ordersUpdated) {
          updateLastUpdated[symbol].orders = updateDate;
          changedLastUpdated[symbol] = true;
          updateStockEntriesData.push({
            entryUuid: stockObj.ordersUuid,
            data: JSON.stringify(updatedSymbolOrders),
          });
        }
      }
      // Lists
      if (updateStockData[symbol].lists) {
        updateLastUpdated[symbol].lists = updateDate;
        changedLastUpdated[symbol] = true;
        updateStockEntriesData.push({
          entryUuid: stockObj.listsUuid,
          data: JSON.stringify(updateStockData[symbol].lists),
        });
      }
      // Stock Details
      if (updateStockData[symbol].details || updateStockData[symbol].updateMappedSymbols || updateStockData[symbol].isUnknown || updateStockData[symbol].updateIsMapped !== undefined) {
        const detailsObj: { details?: Stock, isMapped?: boolean, isUnknown?: boolean, mappedSymbols?: string[] } = {};
        let updateDetails = updateStockData[symbol].details;
        let mappedSymbols: string[] | undefined = updateStockData[symbol].updateMappedSymbols;
        let isMapped: boolean | undefined = updateStockData[symbol].updateIsMapped;
        let isUnknown: boolean | undefined = updateStockData[symbol].isUnknown;
        if (stockEntries && stockEntries[symbol] && stockEntries[symbol].entry.data) {
          const stockDetailsDataObj = stockEntries[symbol].entry.data.find((findDetails: DataEntryData) => findDetails.entryUuid === stockObj.detailsUuid);
          const updateDetailsObj = stockDetailsDataObj && stockDetailsDataObj.data ? JSON.parse(stockDetailsDataObj.data) : undefined;
          if (!updateDetails) {
            updateDetails = updateDetailsObj ? updateDetailsObj.details : undefined;
          }
          if (isMapped === undefined && updateDetailsObj && updateDetailsObj.isMapped !== undefined) {
            isMapped = updateDetailsObj.isMapped;
          }
          if (isUnknown === undefined && updateDetailsObj && updateDetailsObj.isUnknown !== undefined) {
            isUnknown = updateDetailsObj.isUnknown;
          }
          if (mappedSymbols === undefined && updateDetailsObj && updateDetailsObj.mappedSymbols !== undefined) {
            mappedSymbols = updateDetailsObj.mappedSymbols;
          }
        }
        // update details
        if (updateDetails) {
          detailsObj.details = updateDetails;
        }
        if (isUnknown) {
          detailsObj.isUnknown = isUnknown;
        }
        if (isMapped) {
          detailsObj.isMapped = isMapped;
        }
        if (mappedSymbols && mappedSymbols.length > 0) {
          detailsObj.mappedSymbols = mappedSymbols;
        }
        updateLastUpdated[symbol].details = updateDate;
        changedLastUpdated[symbol] = true;
        updateStockEntriesData.push({
          entryUuid: stockObj.detailsUuid,
          data: JSON.stringify(detailsObj),
          /*
          updateStockData[symbol].details
            ? JSON.stringify(updateStockData[symbol].details)
            : (dataExists ? (stockEntries![symbol].data.find((detailsData: DataEntryData) => detailsData.entryUuid === stockObj.detailsUuid)?.data ?? emptyData) : emptyData),
          */
        });
      }
      // last Viewed
      if (updateStockData[symbol].updateLastViewed) {
        updateLastUpdated[symbol].lastViewed = updateDate;
        changedLastUpdated[symbol] = true;
      }
      if (changedLastUpdated[symbol]) {
        updateStockEntriesData.push({
          entryUuid: stockObj.lastViewedUuid,
          data: JSON.stringify(updateLastUpdated[symbol]),
        });
      }
      if (updateStockEntriesData.length === 0 && stockEntriesRef.current![symbol].entry.title === symbol.toUpperCase()) {
        // no changes made
        return;
      }
      const stockData: DataEntryInput = {
        dataUuid: dataExists ? stockEntriesRef.current![symbol].entry.dataUuid : undefined,
        objectUuid: STOCK_OBJ_UUID,
        title: symbol.toUpperCase(), // All tickers should be uppercase;
        dataParent:[{
          itemType: CollectionType.Item,
          action: ActionType.Add,
          id: stockCollectionData.collection!.uuid,
          ordinal: -1,
        }],
        data: updateStockEntriesData,
      };
      if (dataExists) {
        updateEntries.push(stockData);
      }
      else {
        addEntries.push(stockData);
      }
    });
    setUpdateStockEntries((prevUpdateStockEntries: UpdateStockEntries[]) => {
      const updateQueries = [...prevUpdateStockEntries];
      addEntries.forEach((addEntry: DataEntryInput) => {
        const updateUuid = uuid();
        updateQueries.push({
          uuid: updateUuid,
          symbols: [],
          jsx: <AddEntry
            key={`add-stock-entry-${updateUuid}`}
            entryInput={addEntry}
            onUpdate={(data?: AddDataEntryMutation | null, error?: boolean) => {
              if (data) {
                onUpdatedStockEntry(data.addDataEntry as DataEntry);
                setUpdateStockEntries((prevUpdateStockEntries: UpdateStockEntries[]) => {
                  const removeQuery = [...prevUpdateStockEntries].filter((query: UpdateStockEntries) => query.uuid !== updateUuid);
                  return removeQuery;
                });
              }
            }} />,
        });
      });
      updateEntries.forEach((updateEntry: DataEntryInput) => {
        const updateUuid = uuid();
        updateQueries.push({
          uuid: updateUuid,
          symbols: [],
          jsx: <UpdateEntry
            key={`update-stock-entry-${updateUuid}`}
            entryInput={updateEntry}
            onUpdate={(data?: UpdateDataEntryMutation | null, error?: boolean) => {
              if (data) {
                onUpdatedStockEntry(data.updateDataEntry as DataEntry);
                setUpdateStockEntries((prevUpdateStockEntries: UpdateStockEntries[]) => {
                  const removeQuery = [...prevUpdateStockEntries].filter((query: UpdateStockEntries) => query.uuid !== updateUuid);
                  return removeQuery;
                });
              }
            }} />,
        });
      });
      return updateQueries;
    });
  };

  const onUpdateLinkedInstitutions = (updatedLinkedInstitutions: PlaidLinkedInstitution[]) => {
    /*
    if (!updatedLinkedInstitutions.find((hasCoinbase: PlaidLinkedInstitution) => hasCoinbase.institutionName === COINBASE_INSTITUTION)) {
      updatedLinkedInstitutions.push(COINBASE_INSTITUTION);
    }
    */
    setLinkedInstitutions(updatedLinkedInstitutions);
    const updateBrokerAccounts: Dictionary<Dictionary<PlaidAccount>> = Object.assign({}, ...updatedLinkedInstitutions.map((institution: PlaidLinkedInstitution) => {
      const updateAccounts: Dictionary<PlaidAccount> = Object.assign({}, ...institution.accounts.map((account: PlaidAccount) => {
        return ({ [account.accountId] : account });
      }));
      return ({ [institution.institutionName]: updateAccounts });
    }));
    onUpdateBrokerAccounts(updateBrokerAccounts);
  };

  const onUpdateStockPrice = (symbols: string[], stocks?: StockPrice[], loading?: boolean, error?: any) => {
    if (error) {
      errorNotification(`${QRY_ERROR} Failed to get stock prices for ${symbols.join(', ')}.`);
    }
    if (stocks) {
      setRefreshStocks((prevRefreshStocks) => {
        const updateRefreshStocks = { ...prevRefreshStocks };
        updateRefreshStocks.lastPrice = updateRefreshStocks.lastPrice.filter((stock: string) => !stocks.find((stockPrice: StockPrice) => stock.toLowerCase() === stockPrice.ticker.toLowerCase()));
        return updateRefreshStocks.lastPrice.length === prevRefreshStocks.lastPrice.length ? prevRefreshStocks : updateRefreshStocks;
      });
      setLastPrice((prevLastPrice) => {
        const updateLastPrice = { ...prevLastPrice };
        stocks.forEach((stock: StockPrice) => {
          updateLastPrice[stock.ticker.toUpperCase()] = { lastFetched: new Date(), price: stock };
        });
        return updateLastPrice;
      });
    }
  };

  const onUpdateStockDetails = (symbol: string, updateStockDetails?: StockDetails, loading?: boolean, error?: any, isRecent?: boolean) => {
    if (error) {
      errorNotification(`${QRY_ERROR} Failed to get stock details for ${symbol}`);
      if (error.message === 'GraphQL error: Failed to get stock details.') {
        updateStockEntryData({ [symbol]: { isUnknown: true } });
      }
      else {
        setStockDetailsNotFound((prevStockDetailsNotFound) => {
          const updateStockDetailsNotFound = { ...prevStockDetailsNotFound };
          updateStockDetailsNotFound[symbol] = symbol;
          return updateStockDetailsNotFound;
        });
      }
    }
    if (updateStockDetails) {
      updateStockEntryData({ [updateStockDetails.ticker]: { details: getStockFromStockDetails(updateStockDetails), lists: isRecent ? ['Recent'] : undefined } });
      /*
      setStockDetails((prevStockDetails) => {
        const updateStockDetails = { ...prevStockDetails };
        updateStockDetails[stockDetails.ticker] = {
          ticker: stockDetails.ticker,
          name: stockDetails.name,
          description: stockDetails.description,
          isCrypto: stockDetails.isCrypto === true,
        };
        return updateStockDetails;
      });
      */
    }
  };

  const onUpdateHistoryWeek = (symbol: string, stockHistory?: { ticker: string, result: StockHistoryPrice[] }, loading?: boolean, error?: any) => {
    if (error) {
      errorNotification(`${QRY_ERROR} Failed to get weekly historic data for ${symbol}`);
    }
    if (stockHistory) {
      setRefreshStocks((prevRefreshStocks) => {
        const updateRefreshStocks = { ...prevRefreshStocks };
        updateRefreshStocks.weekHistory = updateRefreshStocks.weekHistory.filter((stock: string) => stock !== stockHistory.ticker);
        return updateRefreshStocks.weekHistory.length === prevRefreshStocks.weekHistory.length ? prevRefreshStocks : updateRefreshStocks;
      });
      setStockHistoryWeek((prevStockHistory) => {
        const updateStockHistory = { ...prevStockHistory };
        updateStockHistory[stockHistory.ticker.toUpperCase()] = { lastFetched: new Date(), history: stockHistory.result };
        return updateStockHistory;
      });
    }
  };

  const onUpdateHistoryYear = (symbol: string, stockHistory?: { ticker: string, result: StockHistoryPrice[] }, loading?: boolean, error?: any) => {
    if (error) {
      errorNotification(`${QRY_ERROR} Failed to get yearly historic data for ${symbol}`);
    }
    if (stockHistory) {
      setRefreshStocks((prevRefreshStocks) => {
        const updateRefreshStocks = { ...prevRefreshStocks };
        updateRefreshStocks.yearHistory = updateRefreshStocks.yearHistory.filter((stock: string) => stock !== stockHistory.ticker);
        return updateRefreshStocks.yearHistory.length === prevRefreshStocks.yearHistory.length ? prevRefreshStocks : updateRefreshStocks;
      });
      setStockHistoryYear((prevStockHistory) => {
        const updateStockHistory = { ...prevStockHistory };
        updateStockHistory[stockHistory.ticker.toUpperCase()] = { lastFetched: new Date(), history: stockHistory.result };
        return updateStockHistory;
      });
    }
  };

  /**
   * Add @existingSymbol (unknown) to be associated with another @mapSymbol that is known.
   * @param existingSymbol Unknown symbol
   * @param mapSymbol Known symbol
   */
  const onMapUnknownSymbol = (existingSymbol: string, mapSymbol: string) => {
    const mapped = stockDetails[mapSymbol] && stockDetails[mapSymbol].mappedSymbols ? stockDetails[mapSymbol].mappedSymbols : [];
    if (!mapped!.includes(existingSymbol)) {
      mapped!.push(existingSymbol);
      updateStockEntryData({ [mapSymbol] : { updateMappedSymbols: mapped } });
    }
    let updateIsMapped = mapSymbol !== '' ? true : false;
    if (!updateIsMapped) {
      Object.keys(stockDetails).forEach((checkSymbol: string) => {
        if (stockDetails[checkSymbol].mappedSymbols !== undefined) {
          stockDetails![checkSymbol]!.mappedSymbols!.forEach((mapped: string) => {
            if (mapped === mapSymbol) {
              updateIsMapped = true;
            }
          });
        }
      });
    }
    if (stockDetails[existingSymbol].isMapped !== updateIsMapped) {
      updateStockEntryData({ [existingSymbol]: { updateIsMapped } });
    }
    /*
    setStockDetails((prevStockDetails) => {
      const updateStockDetails = { ...prevStockDetails };
      updateStockDetails[existingSymbol].alternativeTicker = mapSymbol;
      return updateStockDetails;
    });
    */
  };

  const allStocks = stockDetails ? Object.keys(stockDetails).filter((filterUnknown: string) => !stockDetails[filterUnknown].isUnknown && !stockDetails[filterUnknown].inactiveStock) : []; // [...Object.keys(lastPrice)];
  const refetchStockDetails: string[] = [];
  // allStocks.push(...recentStocks.filter((recent: string) => !allStocks.find((stock: string) => stock === recent)));
  // allStocks.push(...Object.keys(holdings).filter((holding: string) => !allStocks.find((stock: string) => stock === holding)));
  // allStocks.push(...Object.keys(transactions).filter((transaction: string) => !allStocks.find((stock: string) => stock === transaction)));
  if (searchResult && !allStocks.find((entry: string) => entry === searchResult)) {
    allStocks.push(searchResult);
  }
  if (holdings) {
    Object.keys(holdings).forEach((holdingSymbol: string) => {
      if (!allStocks.includes(holdingSymbol) && (stockDetails[holdingSymbol] && !stockDetails[holdingSymbol].isUnknown)) {
        allStocks.push(holdingSymbol);
      }
      else if (!stockDetails[holdingSymbol] && !refetchStockDetails.includes(holdingSymbol)) {
        refetchStockDetails.push(holdingSymbol);
      }
    });
  }
  if (transactions) {
    Object.keys(transactions).forEach((transactionSymbol: string) => {
      if (!allStocks.includes(transactionSymbol) && (stockDetails[transactionSymbol] && !stockDetails[transactionSymbol].isUnknown)) {
        allStocks.push(transactionSymbol);
      }
      else if (!stockDetails[transactionSymbol] && !refetchStockDetails.includes(transactionSymbol)) {
        refetchStockDetails.push(transactionSymbol);
      }
    });
  }
  if (orders) {
    Object.keys(orders).forEach((orderSymbol: string) => {
      if (!allStocks.includes(orderSymbol) && (stockDetails[orderSymbol] && !stockDetails[orderSymbol].isUnknown)) {
        allStocks.push(orderSymbol);
      }
      else if (!stockDetails[orderSymbol] && !refetchStockDetails.includes(orderSymbol)) {
        refetchStockDetails.push(orderSymbol);
      }
    });
  }
  const fetchStocks = allStocks.filter((stock: string) => {
    if (refreshStocks.lastPrice.find((refresh: string) => refresh === stock)) {
      return true;
    }
    const diff = lastPrice[stock] ? moment(new Date()).diff(moment(lastPrice[stock].lastFetched), 'hour') : null;
    return diff === null || diff > 1;
  });
  const fetchStockHistoryWeek = allStocks.filter((stock: string) => {
    if (refreshStocks.weekHistory.find((refresh: string) => refresh === stock)) {
      return true;
    }
    // will load when viewing stock details. No need to pre-fetch all stocks.
    return false;
    // const diff = stockHistoryWeek[stock] ? moment(new Date()).diff(moment(stockHistoryWeek[stock].lastFetched), 'hour') : null;
    // return diff === null || diff > 1;
  });
  const fetchStockHistoryYear = allStocks.filter((stock: string) => {
    if (refreshStocks.yearHistory.find((refresh: string) => refresh === stock)) {
      return true;
    }
    // will load when viewing stock details. No need to pre-fetch all stocks.
    return false;
    // const diff = stockHistoryYear[stock] ? moment(new Date()).diff(moment(stockHistoryYear[stock].lastFetched), 'hour') : null;
    // return diff === null || diff > 1;
  });
  const fetchStockDetails = [...refetchStockDetails, ...allStocks].filter((stock: string) => !stockDetails[stock] || (!stockDetails[stock].isUnknown && !stockDetails[stock].ticker));
  const getStocks = fetchStocks.length > 0 ? <GetStocks tickers={fetchStocks} onUpdate={(stocks?: StockPrice[], loading?: boolean, error?: any) => onUpdateStockPrice(fetchStocks, stocks, loading, error)}/> : null;
  const getStockDetails = fetchStockDetails.map((stock: string) => <GetStockDetails ticker={stock} onUpdate={(stockDetails?: StockDetails, loading?: boolean, error?: any) => onUpdateStockDetails(stock, stockDetails, loading, error, stock === searchResult)} />);
  const getStockHistoryWeek = fetchStockHistoryWeek.map((stock: string) => <GetStockHistory
    key={`get-history-week-${stock}`}
    ticker={stock}
    onUpdate={(stockHistory?: { ticker: string, result: StockHistoryPrice[] }, loading?: boolean, error?: any) => onUpdateHistoryWeek(stock, stockHistory, loading, error)}
    startDate={new Date(new Date().setDate(new Date().getDate() - 14))} // fetch 2 weeks of data
  />);
  const getStockHistoryYear = fetchStockHistoryYear.map((stock: string) => <GetStockHistory
  key={`get-history-year-${stock}`}
    ticker={stock}
    onUpdate={(stockHistory?: { ticker: string, result: StockHistoryPrice[] }, loading?: boolean, error?: any) => onUpdateHistoryYear(stock, stockHistory, loading, error)}
    startDate={new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 2)).setDate(new Date().getDate() - 1))}
    frequency={HistoryFrequency.Daily}
   />);

  const getStockData = (symbol: string, holdings: SymbolStockData<StockHolding>) => {
    let mappedStock: string | undefined = undefined;
    if (stockDetails[symbol] && stockDetails[symbol].isMapped) {
      Object.keys(stockDetails).forEach((s: string) => {
        if (stockDetails[s].mappedSymbols && stockDetails[s].mappedSymbols?.includes(symbol)) {
          mappedStock = s;
        }
      });
    }
    const mappedInvestmentStock = stockDetails[symbol] && stockDetails[symbol].mappedSymbols && stockDetails[symbol].mappedSymbols!.length > 0 ? stockDetails[symbol].mappedSymbols![0] : undefined;
    return (
      <div style={{ paddingBottom: '60px', paddingLeft: '5px', paddingRight: '5px' }} >
        <StockDetail
          ticker={symbol}
          stock={{
            details: stockDetails[symbol],
            lastPrice: lastPrice[symbol],
            weekHistory: stockHistoryWeek[symbol],
            yearHistory: stockHistoryYear[symbol],
            lists,
            mappedStock,
          }}
          investments={{
            holdings: holdings[symbol] ?? {},
            transactions: transactions[symbol] ?? {},
            accounts: brokerAccounts ?? [],
            orders: orders[symbol] ?? {},
            // securities,
          }}
          trackedStocks={stockDetails}
          mappedInvestments={mappedInvestmentStock ? {
            holdings: holdings[mappedInvestmentStock] ?? {},
            transactions: transactions[mappedInvestmentStock] ?? {},
            orders: orders[mappedInvestmentStock] ?? {},
          } : undefined}
          onRefreshHistory={onRefreshHistory}
          onClose={() => {
            setSearchResult(undefined);
            setTicker(undefined);
          }}
          onMapUnknownSymbol={onMapUnknownSymbol}
          onGoToMappedStock={(goToStock: string) => setTicker(goToStock)}
          onUpdateTransactions={onUpdate}
          onUpdateLists={onUpdateLists}
        />
      </div>);
  };

  const getStockView = (viewType: ViewListType) => {
    return (
      <div style={{ paddingBottom: '60px', paddingLeft: '5px', paddingRight: '5px' }} >
        {viewType === ViewListType.Stocks
          ? <ViewTransactionHistory
            key={`view-type-${viewType}`}
            transactionHistory={getBrokerStockData<StockTransaction>(transactions)}
            brokerAccounts={brokerAccounts}
            stockDetails={stockDetails}
            onUpdateTransactions={onAddStockData}
          />
          : (viewType === ViewListType.Holdings
            ? <ViewHoldings
              key={`view-type-${viewType}`}
              existingHoldings={getBrokerStockData(holdings)}
              existingTransactions={getBrokerStockData(transactions)}
              existingOrders={getBrokerStockData(orders)}
              brokerAccounts={brokerAccounts}
              stockDetails={stockDetails}
              lastPrices={lastPrice}
              readOnly
            />
            : <ViewOrders
              key={`view-type-${viewType}`}
              existingTransactions={getBrokerStockData(transactions)}
              existingOrders={getBrokerStockData(orders)}
              brokerAccounts={brokerAccounts}
              stockDetails={Object.assign({}, ...Object.keys(stockDetails).map((symbol: string) => ({ [symbol]: { details: stockDetails[symbol], lastPrice: lastPrice[symbol] } })))}
              onUpdateOrders={onAddStockData}
            />)}
      </div>);
  };

  const displayStocks = Object.assign(
    {},
    ...Object.keys(stockDetails)
      .map((stock: string) => ({
        [stock]: ({
          details: stockDetails[stock],
          lastPrice: lastPrice[stock],
          lastDay: stockHistoryWeek[stock],
        }) })));

  const onSelectTicker = (select: string) => {
    setTicker(select);
        // const diff = stockHistoryYear[stock] ? moment(new Date()).diff(moment(stockHistoryYear[stock].lastFetched), 'hour') : null;
    // return diff === null || diff > 1;
    setRefreshStocks((prevRefreshStocks) => {
      const updateRefreshStocks = { ...prevRefreshStocks };
      let update = false;
      const diffYear = stockHistoryYear[select] ? moment(new Date()).diff(moment(stockHistoryYear[select].lastFetched), 'hour') : null;
      const diffWeek = stockHistoryWeek[select] ? moment(new Date()).diff(moment(stockHistoryWeek[select].lastFetched), 'minute') : null;
      if (!updateRefreshStocks.weekHistory.find((stock: string) => stock === select) && (diffWeek === null || diffWeek > 15)) {
        update = true;
        updateRefreshStocks.weekHistory.push(select);
      }
      if (!updateRefreshStocks.yearHistory.find((stock: string) => stock === select) && (diffYear === null || diffYear > 1)) {
        update = true;
        updateRefreshStocks.yearHistory.push(select);
      }
      return update ? updateRefreshStocks : prevRefreshStocks;
    });
    updateStockEntryData({ [select] : { updateLastViewed: true } });
  };

  const hasUnknownStocks = Object.keys(stockDetails).filter((unknownStock: string) => stockDetails[unknownStock].isUnknown && !stockDetails[unknownStock].isMapped);
  const stockList = <StockList
    key='stock-list'
    stocks={displayStocks}
    lists={Object.assign(
      {},
      lists,
      ...[(hasUnknownStocks.length > 0 ? { [DefaultStockList.FixError] : hasUnknownStocks  } : {})],
      ...Object.keys(DefaultStockList).map((defaultList: string) => {
        const defaultListStocks: string[] = (
          /* defaultList === DefaultStockList.Recent ? recentStocks
          : */
          defaultList === DefaultStockList.LookedUp ? Object.keys(stockDetails)
            : (defaultList === DefaultStockList.Owned ? Object.keys(transactions)
              : (defaultList === DefaultStockList.Current ? Object.keys(holdings)
                : (defaultList === DefaultStockList.Recent ? Object.keys(stockDetails).sort((a: string, b: string) => {
                  if (stockDetails[a].lastViewed) {
                    if (!stockDetails[b].lastViewed) {
                      return 1;
                    }
                    return (stockDetails[a].lastViewed as Date) < (stockDetails[b].lastViewed as Date) ? 1 : -1;
                  }
                  return stockDetails[b].lastViewed ? -1 : 1;
                }).slice(0, 10)
                  : (lists[defaultList] ? lists[defaultList]
                    : [])))));
        return { [defaultList]: defaultListStocks };
      }),
    )}
    holdings={holdings}
    isLoading={isLoading}
    selectedTicker={ticker ?? searchResult}
    onSelectTicker={onSelectTicker}
    onUpdateList={(updateList: Dictionary<string[]>) => {
      setLists(updateList);
    }}/>;

  const onRefreshHistory = (symbol: string) => {
    setRefreshStocks((prevRefreshStocks) => {
      const updateRefreshStocks = { ...prevRefreshStocks };
      if (!updateRefreshStocks.weekHistory.find((stock: string) => stock === symbol)) {
        updateRefreshStocks.weekHistory.push(symbol);
      }
      if (!updateRefreshStocks.yearHistory.find((stock: string) => stock === symbol)) {
        updateRefreshStocks.yearHistory.push(symbol);
      }
      return updateRefreshStocks;
    });
  };

  const onUpdateLists = (symbol: string, lists: string[]) => {
    updateStockEntryData({ [symbol] : { lists } });
  };

  const onUpdateBrokerAccounts = (updatedAccounts: Dictionary<Dictionary<PlaidAccount>>) => {
    setBrokerAccounts((prevAccounts: Dictionary<Dictionary<PlaidAccount>>) => {
      let isUpdated = false;
      const updateAccounts = { ...prevAccounts };
      Object.keys(updatedAccounts).forEach((importBroker: string) => {
        if (!updateAccounts[importBroker]) {
          updateAccounts[importBroker] = {};
        }
        Object.keys(updatedAccounts[importBroker]).forEach((accountId: string) => {
          if (!updateAccounts[importBroker][accountId] || updateAccounts[importBroker][accountId].brokerAccountId !== updatedAccounts[importBroker][accountId].brokerAccountId) {
            isUpdated = true;
            updateAccounts[importBroker][accountId] = updatedAccounts[importBroker][accountId];
          }
        });
      });
      return isUpdated ? updateAccounts : prevAccounts;
    });
  };

  const onUpdate = (update: Dictionary<UpdateStockEntryData>, importAccounts?: Dictionary<Dictionary<PlaidAccount>>) => {
    updateStockEntryData(update);
    if (importAccounts) {
      onUpdateBrokerAccounts(importAccounts);
    }
  };

  const onUpdateTransactions = (importTransactions: Dictionary<StockTransaction[]>, removeTransactions: Dictionary<StockTransaction[]>, importSecurities?: Dictionary<Dictionary<PlaidSecurity>>, importAccounts?: Dictionary<Dictionary<PlaidAccount>>) => {
    const importData: Dictionary<UpdateStockEntryData> = Object.assign({}, ...Object.keys(importTransactions).map((updateSymbol: string) => ({ [updateSymbol] : { transactions: importTransactions[updateSymbol] } })));
    Object.keys(removeTransactions).forEach((symbol: string) => {
      if (!importData[symbol]) {
        importData[symbol] = {};
      }
      importData[symbol].removeTransactions = removeTransactions[symbol];
    });
    if (importSecurities) {
      Object.keys(importSecurities).forEach((broker: string) => {
        Object.keys(importSecurities[broker]).forEach((account: string) => {
          const symbol: string = importSecurities[broker][account].symbol ?? '';
          if (symbol === '') {
            return;
          }
          const stock: Stock | undefined = stockDetailsRef.current[symbol];
          // only update stocks that have been previously imported or added to a stock list
          if (!stock) {
            return;
          }
          let updated = false;
          if (stock.cusip === undefined && importSecurities[broker][account].cusip) {
            updated = true;
            stock.cusip = importSecurities[broker][account].cusip as string;
          }
          if (stock.isin === undefined && importSecurities[broker][account].cusip) {
            updated = true;
            stock.isin = importSecurities[broker][account].isin as string;
          }
          if (stock.sedol === undefined && importSecurities[broker][account].sedol) {
            updated = true;
            stock.sedol = importSecurities[broker][account].sedol as string;
          }
          if (updated) {
            if (!importData[symbol]) {
              importData[symbol] = {};
            }
            importData[symbol].details = stock;
          }

        });
      });
    }
    onUpdate(importData, importAccounts);
    // const currentTransactions = { ...transactionsRef.current };
    // (updateStockData: Dictionary<{ holdings?: StockHolding[], transactions?: StockTransaction[], orders?: any[], lists?: string[], details?: StockDetails, updateLastViewed?: boolean }>) => {
    // updateStockEntryData(Object.assign({}, ...Object.keys(importTransactions).map((updateSymbol: string) => ({ [updateSymbol] : { transactions: importTransactions[updateSymbol] } }))));
    /*
    Object.keys(importTransactions).forEach((importSymbol: string) => {
      importTransactions[importSymbol].forEach((importTransaction: StockTransaction) => {
        if (!currentTransactions[importSymbol]) {
          currentTransactions[importSymbol] = {};
        }
        if (!currentTransactions[importSymbol][importTransaction.broker]) {
          currentTransactions[importSymbol][importTransaction.broker] = {};
        }
        if (!currentTransactions[importSymbol][importTransaction.broker][importTransaction.accountId]) {
          currentTransactions[importSymbol][importTransaction.broker][importTransaction.accountId] = [];
        }
        if (!currentTransactions[importSymbol][importTransaction.broker][importTransaction.accountId].find((existingTransaction: StockTransaction) => existingTransaction.transactionId === importTransaction.transactionId)) {
          currentTransactions[importSymbol][importTransaction.broker][importTransaction.accountId].push(importTransaction);
        }
      });
    });
    */

    // updateStockEntryData(Object.keys(importTransactions).map((symbol: string) => ({ [symbol]: { transactions: importTransactions[symbol] } })));
    /*
    setTransactions((prevTransactions: Dictionary<StockTransaction[]>) => {
      const updateTransactions = { ...prevTransactions };
      Object.keys(importTransactions).forEach((importSymbol: string) => {
        importTransactions[importSymbol].forEach((importTransaction: StockTransaction) => {
          if (!updateTransactions[importSymbol]) {
            updateTransactions[importSymbol] = [];
          }
          if (!updateTransactions[importSymbol].find((existingTransaction: StockTransaction) => existingTransaction.transactionId === importTransaction.transactionId)) {
            updateTransactions[importSymbol].push(importTransaction);
          }
        });
      });
      return updateTransactions;
    });
    */
    /*
    setSecurities((prevSecurities: Dictionary<Dictionary<PlaidSecurity>>) => {
      const updateSecurities = { ...prevSecurities };
      Object.keys(importSecurities).forEach((importBroker: string) => {
        if (!updateSecurities[importBroker]) {
          updateSecurities[importBroker] = {};
        }
        Object.keys(importSecurities[importBroker]).forEach((importSecurityId: string) => {
          if (!updateSecurities[importBroker][importSecurityId]) {
            updateSecurities[importBroker][importSecurityId] = importSecurities[importBroker][importSecurityId];
          }
        });
      });
      return updateSecurities;
    });
    */
    /*
    if (importAccounts) {
      setBrokerAccounts((prevAccounts: Dictionary<Dictionary<PlaidAccount>>) => {
        const updateAccounts = { ...prevAccounts };
        Object.keys(importAccounts).forEach((importBroker: string) => {
          if (!updateAccounts[importBroker]) {
            updateAccounts[importBroker] = {};
          }
          Object.keys(importAccounts[importBroker]).forEach((accountId: string) => {
            if (!updateAccounts[importBroker][accountId]) {
              updateAccounts[importBroker][accountId] = importAccounts[importBroker][accountId];
            }
          });
        });
        return updateAccounts;
      });
    }
    */
    // infoNotification('successfully imported investment transactions');
  };

  const onUpdateHoldings = (importHoldings: Dictionary<StockHolding[]>, removedHoldings: Dictionary<StockHolding[]>, importSecurities: Dictionary<Dictionary<PlaidSecurity>>, importAccounts: Dictionary<Dictionary<PlaidAccount>>) => {
    const allsymbols: string[] = Object.keys(importHoldings);
    Object.keys(removedHoldings).forEach((removeSymbol: string) => {
      if (!allsymbols.includes(removeSymbol)) {
        allsymbols.push(removeSymbol);
      }
    });

    const importData: Dictionary<UpdateStockEntryData> = Object.assign({}, ...allsymbols.map((updateSymbol: string) => ({ [updateSymbol] : { holdings: importHoldings[updateSymbol], removeHoldings: removedHoldings[updateSymbol] } })));
    if (importSecurities) {
      Object.keys(importSecurities).forEach((broker: string) => {
        Object.keys(importSecurities[broker]).forEach((account: string) => {
          const symbol: string = importSecurities[broker][account].symbol ?? '';
          const stock: Stock | undefined = stockDetailsRef.current[symbol];
          let updated = false;
          if (stock.cusip === undefined && importSecurities[broker][account].cusip) {
            updated = true;
            stock.cusip = importSecurities[broker][account].cusip as string;
          }
          if (stock.isin === undefined && importSecurities[broker][account].cusip) {
            updated = true;
            stock.isin = importSecurities[broker][account].isin as string;
          }
          if (stock.sedol === undefined && importSecurities[broker][account].sedol) {
            updated = true;
            stock.sedol = importSecurities[broker][account].sedol as string;
          }
          if (updated) {
            if (!importData[symbol]) {
              importData[symbol] = {};
            }
            importData[symbol].details = stock;
          }
        });
      });
    }
    updateStockEntryData(importData);
    onUpdateBrokerAccounts(importAccounts);
  };

  const onUpdateStocks = (updateStocks: Stock[]) => {
    if (updateStocks.length === 0) {
      return;
    }
    const importData: Dictionary<UpdateStockEntryData> = {};
    updateStocks.forEach((stock: Stock) => {
      importData[stock.ticker.toUpperCase()] = { details: stock };
    });
    updateStockEntryData(importData);
  };

  const onUpdateOrders = (importOrders: Dictionary<StockOrder[]>, removedOrders: Dictionary<StockOrder[]>) => {
    updateStockEntryData(Object.assign({}, ...Object.keys(importOrders).map((updateSymbol: string) => ({ [updateSymbol] : { addOrders: importOrders[updateSymbol], removeOrders:removedOrders[updateSymbol] } }))));
  };

  const onAddStockData = (updateStock?: { transactions: StockTransaction[], orders: StockOrder[], details?: StockDetails }, deleteStock?: { transactions: StockTransaction[], orders: StockOrder[] }) => {
    const addNewStocks: Dictionary<{ transactions?: StockTransaction[], addOrders?: StockOrder[], details?: Stock }> = {};
    if (updateStock) {
      updateStock.transactions.forEach((newStock: StockTransaction) => {
        if (!addNewStocks[newStock.symbol]) {
          addNewStocks[newStock.symbol] = { transactions: [] };
        }
        addNewStocks[newStock.symbol].transactions!.push(newStock);
      });
      updateStock.orders.forEach((newOrder: StockOrder) => {
        if (!addNewStocks[newOrder.symbol]) {
          addNewStocks[newOrder.symbol] = { addOrders: [] };
        }
        else if (!addNewStocks[newOrder.symbol].addOrders) {
          addNewStocks[newOrder.symbol].addOrders = [];
        }
        if (transactions[newOrder.symbol] && transactions[newOrder.symbol][newOrder.broker] && transactions[newOrder.symbol][newOrder.broker][newOrder.accountId] && transactions[newOrder.symbol][newOrder.broker][newOrder.accountId].length > 0) {
          newOrder.securityId = transactions[newOrder.symbol][newOrder.broker][newOrder.accountId][0].securityId;
        }
        else if (holdings[newOrder.symbol] && holdings[newOrder.symbol][newOrder.broker] && holdings[newOrder.symbol][newOrder.broker][newOrder.accountId] && holdings[newOrder.symbol][newOrder.broker][newOrder.accountId].length > 0) {
          newOrder.securityId = holdings[newOrder.symbol][newOrder.broker][newOrder.accountId][0].securityId;
        }
        addNewStocks[newOrder.symbol].addOrders!.push(newOrder);
        if (updateStock.details && updateStock.details.ticker === newOrder.symbol) {
          addNewStocks[newOrder.symbol].details = getStockFromStockDetails(updateStock.details);
        }
      });
    }
    updateStockEntryData(addNewStocks);
    setAddStockData(undefined);
  };

  return (
    <>
      {/* initialize DataCollection DataObject and DataEntries */}
      {dataState.stockObjs === DataState.fetch ? dataCollections : null}
      {newCollection}
      {dataState.stockObjs === DataState.fetch ? getStockObject : null}
      {getStockEntries}
      {updateStockEntries.map((update: UpdateStockEntries) => update.jsx)}
      {getStocks}
      {getStockDetails}
      {getStockHistoryWeek}
      {getStockHistoryYear}
      {linkedInstitutions === undefined && (addStockData || importFromBank || importFromCsv)
        ? <GetLinkedInstitutions
          key='fetch-linked-institutions'
          onUpdateLinkedInstitutions={(data?: PlaidLinkedInstitution[], loading?: boolean, error?: any) => {
            if (data) {
              return onUpdateLinkedInstitutions(data);
            }
          }} />
        : null}
      {addStockData
        ? <AddStockData
        dataType={addStockData}
        accounts={brokerAccounts}
        transactions={Object.assign({}, ...Object.keys(stockDetails).map((starredStock: string) => ({ [starredStock] : ({} as any) })), transactions)}
        stockDetails={Object.assign({}, ...Object.keys(stockDetails).map((stockSymbol: string) => ({ [stockSymbol]: { price: lastPrice[stockSymbol] ? lastPrice[stockSymbol].price : undefined, details: stockDetails[stockSymbol] } })))}
        onCancel={() => setAddStockData(undefined)}
        onSuccess={onAddStockData} />
        : null}
      <br />
      <LoadingOverlay spinner text='Loading ...' active={stockObj === undefined || stockCollectionData.collection === undefined}>
        <DivAlign hPosition={DivPosition.Right}>
          <FindStock
            key='find-stock'
            disabled={importFromBank || importFromCsv !== undefined}
            trackedStocks={stockDetails ?? {}}
            resetOnSelect
            onSelectTicker={(updateSelectedTicker: string) => {
              setSearchResult(updateSelectedTicker);
              onSelectTicker(updateSelectedTicker);
            }}
          />
          <Button key='stocks-refresh' onClick={() => {
            setRefreshStocks((prevRefreshStocks) => {
              const updateRefreshStocks = { ...prevRefreshStocks };
              updateRefreshStocks['lastPrice'] = [...Object.keys(lastPrice)];
              return updateRefreshStocks;
            });
          }}><ReloadOutlined /></Button>
          {!stockImportEnabled ? null : <Dropdown
            disabled={importFromBank || importFromCsv !== undefined}
            menuItems={[
              { name: 'Import csv', component: <ImportCsv trigger={'Import CSV file'} onImportData={(file: string, columns: string[], rows: string[][]) => {
                setImportFromCsv({ file, columns, rows });
                infoNotification(`Data loaded from file ${file}`);
              }} /> },
              { name: 'Import pdf', component: <ImportPdf trigger={'Import PDF file'} onImportData={(file: string, columns: string[], rows: string[][]) => {
                setImportFromCsv({ file, columns, rows });
                infoNotification(`Data loaded from file ${file}`);
              }} /> },
              { name: 'Divider', component: <Divider style={{ margin: 0 }}/> },
              { name: 'Import from bank' },
            ]}
            dropdownType={[DropdownType.Click]}
            onClick={(item: any) => {
              if (item === 'Import yahoo CSV') {
                infoNotification('Add position');
              }
              else if (item === 'Import from bank') {
                setImportFromBank(true);
              }
            }}
          >
            <ImportIcon size='small' disabled={importFromBank || importFromCsv !== undefined} selected={importFromBank} enclosed onClick={() => {}} />
          </Dropdown>}
          <Dropdown
            disabled={importFromBank || importFromCsv !== undefined}
            menuItems={Object.keys(StockDataType).map((dataType: string) => ({ name: dataType, component: `Add ${StockDataType[dataType]}` }))}
            dropdownType={[DropdownType.Click]}
            onClick={(dataType: string) => {
              setAddStockData(StockDataType[dataType]);
            }}
          >
            <AddIcon size='small' disabled={importFromBank || importFromCsv !== undefined} enclosed onClick={() => {}} />
          </Dropdown>
          <Dropdown
            disabled={importFromBank || importFromCsv !== undefined}
            menuItems={['Stocks', 'Orders', 'Holdings'].map((dataType: string) => ({ name: dataType, component: ViewListType[dataType] === detailType ? <StyledDiv bold>View {dataType}</StyledDiv> : `View ${dataType}` }))}
            dropdownType={[DropdownType.Click]}
            onClick={(listType: string) => {
              if (Object.keys(ViewListType).includes(listType)) {
                setDetailType(detailType === ViewListType[listType] ? ViewListType.None : ViewListType[listType]);
                if (listType !== ViewListType.None) {
                  setTicker(undefined);
                }
              }
            }}

          >
            <MenuIcon size='small' disabled={importFromBank || importFromCsv !== undefined} enclosed onClick={() => {}} />
          </Dropdown>
        </DivAlign>
        <br />
        {importFromBank || importFromCsv
          ? <ImportBroker
            key='import-broker'
            existingInvestmentTransactions={transactions}
            existingHoldings={holdings}
            existingOrders={orders}
            importData={importFromCsv}
            linkedInstitutions={linkedInstitutions}
            stockDetails={stockDetails}
            onUpdateLinkedInstitutions={onUpdateLinkedInstitutions}
            onImportInvestmentTransactions={onUpdateTransactions}
            onUpdateHoldings={onUpdateHoldings}
            onUpdateOrders={onUpdateOrders}
            onUpdateStocks={onUpdateStocks}
            onClose={() => {
              if (importFromBank) {
                setImportFromBank(false);
              }
              if (importFromCsv !== undefined) {
                setImportFromCsv(undefined);
              }
            }} />
          : (ticker || searchResult
            ? <ResponsiveContentSidebar
                customKey='stocks-layout'
                left={getStockData((ticker ?? searchResult) as string, holdings)}
                right={stockList}
                closeable={['left']}
                widthRight={'300px'}
                style={{ height: 'calc(100vh - 110px - 60px - 30px)' }}
                leftName='Stock detail'
                rightName='Stocks'
                separatedScroll={true}
                onCloseTab={(side: 'left' | 'right') => {
                  setSearchResult(undefined);
                  setTicker(undefined);
                }}
              />
          : (detailType !== ViewListType.None
             ? <ResponsiveContentSidebar
              customKey='stocks-layout'
              left={getStockView(detailType)}
              right={stockList}
              closeable={['left']}
              widthRight={'300px'}
              style={{ height: 'calc(100vh - 110px - 60px - 30px)' }}
              leftName='Stock detail'
              rightName='Stocks'
              separatedScroll={true}
              onCloseTab={(side: 'left' | 'right') => {
                setDetailType(ViewListType.None);
              }}
            />
              :<CenteredDiv>
                {stockList}
              </CenteredDiv>))
        }
      </LoadingOverlay>
    </>
  );
};

export default Stocks;
