/* Steps to retrieve and process collection page data
 *
 * <ComponentA></ComponentA>
 *
 * 1. Get Collection UUID from URL
 * 2. Get Collection Data from GraphQL
 *    getPageData(collectionUuid) {
 *      uuid
 *      layout [
 *        {
 *          component
 *          content {
 *            collection [ // collection of collections, DOs, and items
 *              {
 *                type
 *                uuid
 *                fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *                  visible [
 *                    {
 *                      entryUuid
 *                      viewMap
 *                    }
 *                  ]
 *                  expandable [...]
 *                  hidden [...]
 *                }
 *                data { // entry data to display on the page.
 *                  entries [
 *                    {
 *                      uuid
 *                      name
 *                      data
 *                    }
 *                  ]
 *                }
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 *
 *    getPageData(collectionUuid) {
 *      uuid
 *      layout [
 *        {
 *          component
 *          collectionUuid
 *        }
 *      ]
 *      collection [ // collection of collections, DOs, and items
 *        {
 *          type
 *          uuid
 *          fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *            visible [
 *              {
 *                 entryUuid
 *                 viewMap
 *              }
 *            ]
 *            expandable [...]
 *            hidden [...]
 *          }
 *          data { // entry data to display on the page.
 *            entries [
 *              {
 *                uuid
 *                name
 *                data
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 *
 *    getPageData('starbucks_page_uuid') {
 *      uuid: 'starbucks_page_uuid'
 *      ownerUuid: 'owner_uuid'
 *      layout [
 *        {
 *          component
 *          collectionUuid
 *        }
 *      ]
 *      collection [ // collection of collections, DOs, and items
 *        {
 *          type
 *          uuid
 *          fields { // define which fields are visible, expandable, and hidden. Define where these fields are displayed.
 *            visible [
 *              {
 *                 entryUuid
 *                 viewMap
 *              }
 *            ]
 *            expandable [...]
 *            hidden [...]
 *          }
 *          data { // entry data to display on the page.
 *            entries [
 *              {
 *                uuid
 *                name
 *                data
 *              }
 *            ]
 *          }
 *        }
 *      ]
 *    }
 */

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../components/layout/Page';
import CollectionsData from '../components/data/Collections';
import { CenteredDiv } from '../components/styled/CenteredDiv';

type CssProps = {
  bigWindow: boolean;
  windowWidth: number;
};

/*
// background-color: lightGray;
const ListDiv = styled.div`
  position: flex;
  float: right;
  display: 'flex';
  max-width: 800px;
  padding-right: 10px; /* scrollbar * /
  padding-left: 10px;
  padding-bottom: 15px;
  overflow: auto;
  ${(props: CssProps) => props.bigWindow ? `
  margin-left: auto;
  margin-right: auto;` : ''}
  text-align: center;
`;
*/

type CollectionsProps = RouteComponentProps & {
};

const Collections: React.FC<CollectionsProps> = (props: CollectionsProps) => {
  return (
    // TODO: Why does button onClick not work without a divider?
    // {addCodeError && (message.error(`Failed to add code: ${addCodeError}`))}
    <Page
      headerTitle='Collections'
      content={<CenteredDiv><CollectionsData key='collections-data' /></CenteredDiv>}
    />
  );
};

/*
        <ListDiv bigWindow={windowSize.width !== undefined && windowSize.width > 800} windowWidth={windowSize.width ? windowSize.width : 800}>
          <CollectionsData key='collections-data' />
        </ListDiv>
*/

export default Collections;
