import React, { useEffect, useRef, useState } from 'react';
import { message, Tag, Popover, Select, Space } from 'antd';
import { CalendarOutlined, CheckCircleTwoTone, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileAddOutlined, FilterOutlined, InfoCircleTwoTone, PlusCircleOutlined, QuestionCircleTwoTone, ReloadOutlined, UndoOutlined } from '@ant-design/icons';
import ContentView from './ContentView';
import ViewCard from '../views/Card';
import { DataCollectionData, DataEntry, DataEntryData, DataObject, DataObjectEntry, DataParent } from '../../graphql/generated/graphql';
import { RecordModalAction } from '../modal/RecordModal';
import { GetDataEntries } from '../../containers/api/Entry/DataEntry';
import { GetDataObjects } from '../../containers/api/DataObject';
import { useWindowSize } from '../layout/Page';
import { GetDataCollections } from '../../containers/api/Collection/DataCollection';
import { CenteredDiv } from '../styled/CenteredDiv';
import { errorNotification } from '../actions/Notification';
import CreateEntry, { GlobalObjectFields } from './Entry/CreateEntry';
import EditEntry from './Entry/EditEntry';
import { dateFormat, FieldType } from '../table/DataTypes';
import moment from 'moment';
import CircleCheckbox from '../atomic/CircleCheckbox';
import ViewDraggable, { ViewTutorialType } from '../views/ViewDraggable';
import { fireConfetti } from '../misc/Confetti';
import DatePicker from '../atomic/DatePicker';
import Button from '../atomic/Button';
import Popconfirm from '../atomic/Popconfirm';
import EntryFilter, { EntrySettings, EntrySort, ViewFilter } from '../filter/EntryFilter';
import { filterData } from './EntriesViewHelper';
import BottomPageOrContent from '../atomic/BottomPageOrContent';
import UpdateCollectionEntries from './UpdateCollectionEntries';
import LoadingOverlay from '../atomic/LoadingOverlay';
import _, { Dictionary } from 'lodash';
import { getTutorialProps, TutorialNoneProps, TutorialType } from '../tutorial/Tutorial';
import TutorialCollection from '../tutorial/TutorialCollection';
import { sampleEntries } from '../tutorial/TutorialEntry';
import WizardTooltip, { ALLOWED_KEYS, WizardTooltipProps } from '../wizard/WizardTooltip';
import Calendar from '../calendar/Calendar';
import { entryToEvents } from '../calendar/EntryToEvents';
// import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { LayoutDirection } from '../views/Helpers';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

export const hideField = (data: DataEntry, dataObjs: Dictionary<DataObject>, field: 'dateDue' | 'dateCompleted'): boolean => {
  const dataObj = dataObjs[data.objectUuid];
  return (dataObj && dataObj.hideFields && dataObj.hideFields.find((hideField: string) => field === hideField) !== undefined) as boolean;
};

type EntriesViewProps = {
  collectionUuids?: string[];
  entriesUuids?: string[]; // filter to show only these entries
  addToCollection?: boolean;
  viewAsModal?: boolean;
  // objectUuids?: string[];
  // entryUuids?: string[];
  onUpdate?: (collection: DataCollectionData[], objects: Dictionary<DataObject>, entries: DataEntry[]) => void;
};

type IsLoading = { collections: boolean; dataObjects: boolean; entries: boolean };
type LastUpdated = { collections: string; dataObjects: string; entries: string, fetchedEntries: string };

const EntriesView: React.FC<EntriesViewProps> = (props: EntriesViewProps) => {
  const [lastUpdated, setLastUpdated] = useState<LastUpdated>({ collections: new Date().toISOString(), dataObjects: new Date().toISOString(), entries: new Date().toISOString(), fetchedEntries: new Date().toISOString() });
  const [updateEntry, setUpdateEntry] = useState<JSX.Element | null>(null);
  const [isLoading, setIsLoading] = useState<IsLoading>({ collections: false, dataObjects: false, entries: false });
  const [dataEntries, setDataEntries] = useState<DataEntry[] | null>(null);
  const [dataObjects, setDataObjects] = useState<Dictionary<DataObject>>({});
  const [dataCollections, setDataCollections] = useState<DataCollectionData[]>([]);
  const [selectedView, setSelectedView] = useState<any>('Sortable');
  const [markDueDate, setMarkDueDate] = useState<Date | null>(null);
  const [updateDueDate, setUpdateDueDate] = useState<DataEntry | null>(null);
  const [updateDoneDueDate, setUpdateDoneDueDate] = useState<DataEntry | null>(null);
  const [markDoneDate, setMarkDoneDate] = useState<Date | null>(null);
  const [updateDoneDate, setUpdateDoneDate] = useState<DataEntry | null>(null);
  const [debug, setDebug] = useState<number>(0);
  const [entriesSettings, setEntriesSettings] = useState<EntrySettings>({ sortBy: EntrySort.DueDate, filter: ViewFilter.All, includeCompleted: true, showFireworks: false });
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [addEntriesToCollection, setAddEntriesToCollection] = useState<string[] | null>(null);
  const [showTutorial, setShowTutorial] = useState<TutorialType>(TutorialType.None);
  const [showCalendar, setShowCalendar] = useState<{ startDate: Date, endDate: Date } | undefined>(undefined);
  const [calendarSelectedDates, setCalendarSelectedDates] = useState<{start: Date, end?: Date}[]>([]);

  const showTutorialRef = useRef(TutorialType.None);
  const dataEntriesRef = useRef([] as DataEntry[] | null);
  const dataObjectsRef = useRef({} as Dictionary<DataObject>);
  const dataCollectionsRef = useRef([] as DataCollectionData[]);
  showTutorialRef.current = showTutorial;
  dataEntriesRef.current = dataEntries;
  dataObjectsRef.current = dataObjects;
  dataCollectionsRef.current = dataCollections;

  const getDataEntries = <GetDataEntries
    collectionUuids={/* if addToCollection, show all entries */ props.addToCollection ? undefined : props.collectionUuids}
    key={`get-entries-query-${lastUpdated.entries}`}
    updateApiResponse={(data?: any, loading?: boolean, error?: any) => {
      if (data) {
        setDataEntries(data);
      }
      if (loading !== undefined && loading !== isLoading.entries) {
        setIsLoading((prevIsLoading) => {
          const currentLoading = { ...prevIsLoading };
          currentLoading.entries = loading;
          return currentLoading;
        });
      }
      if (error) {
        errorNotification();
      }
    }}/>;

  const getDataObjects = <GetDataObjects
    key={`get-objects-query-${lastUpdated.dataObjects}`}
    updateApiResponse={(data?: DataObject[], loading?: boolean, error?: string) => {
      if (data) {
        const dataObjs = Object.assign({}, ...data.map((dataObj: DataObject) => ({ [dataObj.uuid]: dataObj })));
        setDataObjects(dataObjs);
      }
      if (loading !== undefined && loading !== isLoading.dataObjects) {
        setIsLoading((prevIsLoading) => {
          const currentLoading = { ...prevIsLoading };
          currentLoading.dataObjects = loading;
          return currentLoading;
        });
      }
      if (error) {
        errorNotification();
      }
    }}/>;

  const getDataCollections = <GetDataCollections
    key={`get-collections-query-${lastUpdated.collections}`}
    updateApiResponse={(data?: DataCollectionData[], loading?: boolean, error?: string) => {
      if (data) {
        setDataCollections(data);
      }
      if (loading !== undefined && loading !== isLoading.collections) {
        setIsLoading((prevIsLoading) => {
          const currentLoading = { ...prevIsLoading };
          currentLoading.collections = loading;
          return currentLoading;
        });
      }
      if (error) {
        errorNotification();
      }
    }}/>;

  useEffect(() => {
    if (props.addToCollection && props.onUpdate && dataEntries) {
      props.onUpdate([], {} as Dictionary<DataObject>, dataEntries.filter((entry: DataEntry) => addEntriesToCollection?.find((entryUuid: string) => entry.dataUuid === entryUuid)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [addEntriesToCollection]);

  useEffect(() => {
    if (props.onUpdate && dataCollections && dataObjects && dataEntries) {
      props.onUpdate(dataCollections, dataObjects, dataEntries);
    }
    const collection = props.collectionUuids && props.collectionUuids.length === 1 && dataCollections ? dataCollections.find((c: DataCollectionData) => props.collectionUuids && c.uuid === props.collectionUuids[0]) : null;
    if (props.addToCollection && collection && dataEntries) {
      setAddEntriesToCollection(dataEntries
        .filter((entry: DataEntry) => entry.parent
          ? entry.parent.find((parent: DataParent) => parent.uuid === collection.uuid)
          : false)
        .map((entry: DataEntry) => entry.dataUuid));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [dataObjects, dataCollections, dataEntries]);

  const collection = props.collectionUuids && props.collectionUuids.length === 1 && dataCollections ? dataCollections.find((c: DataCollectionData) => props.collectionUuids && c.uuid === props.collectionUuids[0]) : null;

  // Done circle
  const getLeftElement = (data: DataEntry) => {
    // Show checkboxes if adding entries to a collection
    if (props.addToCollection) {
      if (addEntriesToCollection) {
        return addEntriesToCollection.find((entryUuid: string) => entryUuid === data.dataUuid)
          ? <CircleCheckbox isChecked onClick={() => setAddEntriesToCollection(prevEntries => prevEntries ? [...prevEntries.filter((entryUuid: string) => entryUuid !== data.dataUuid)] : prevEntries)} />
          : <CircleCheckbox onClick={() => setAddEntriesToCollection(prevEntries => prevEntries ? (prevEntries.find((entryUuid: string) => entryUuid === data.dataUuid) ? prevEntries : [...prevEntries, data.dataUuid]) : prevEntries)}/>;
      }
      return null;
    }

    if (hideField(data, dataObjects, 'dateDue')) {
      return null;
    }

    // TODO: need to do diff based on local date
    const now = moment(moment(new Date()).format(dateFormat));
    const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
    const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;

    return data.dateCompleted
      ? <span style={{ marginRight: '5px' }}><CheckCircleTwoTone style={{ paddingRight: '1px' }} twoToneColor="#52c41a" /></span>
      : (diffDays > 0
          ? <span key={`mark-done-icon`} style={{ marginRight: '5px' }}><Popover key='popover-missing-fields' content={<div>Please update the due date. If item is complete, please mark it as complete</div>} title="Item over due">
              <Popconfirm
                placement={'rightTop'}
                key={`mark-due-done-${data.dataUuid}`}
                header={'Item expired'}
                title={<>
                  <div><span style={{ marginRight: '15px' }}>Update due date</span>
                    <DatePicker
                      key={`data-picker-due}`}
                      allowClear={true}
                      initialValue={moment(data.dateDue)}
                      popupStyle={{ zIndex: 2000 }}
                      onChange={(e: any) => {
                        // TODO
                        setMarkDueDate(e ? e.toDate() : null);
                      }} />
                  </div>
                  <div style={{ marginTop: '5px' }}><span style={{ marginRight: '4px' }}>Mark item as done</span>
                    <DatePicker
                      key={`data-picker-done}`}
                      allowClear={true}
                      initialValue={undefined}
                      popupStyle={{ zIndex: 2000 }}
                      onChange={(e: any) => {
                        // TODO
                        setMarkDoneDate(e ? e.toDate() : null);
                      }} />
                  </div>
                </>}
                onConfirm={() => {
                  // TODO
                  setUpdateDoneDueDate(data);
                }}
                onCancel={() => {
                  // TODO
                  setMarkDueDate(null);
                  setMarkDoneDate(null);
                }}
                okText='Update dates'
                cancelText='Cancel'
              >
                <ExclamationCircleOutlined style={{ color: 'red' }} onClick={() => {
                  setMarkDueDate(data.dateDue ? moment(data.dateDue).toDate() : null);
                }} />
              </Popconfirm>
            </Popover></span>
          : <span style={{ marginRight: '30px' }}>
              <Popconfirm
                  placement={'rightTop'}
                  title={<>
                    <div>Mark item as complete:
                      <DatePicker
                        allowClear={false}
                        initialValue={moment(new Date())}
                        popupStyle={{ zIndex: 2000 }}
                        onChange={(e: any) => {
                          setMarkDoneDate(e ? e.toDate() : null);
                        }} />
                    </div>
                  </>}
                  onConfirm={() => {
                    if (!markDoneDate) {
                      setMarkDoneDate(new Date());
                    }
                    setUpdateDoneDate(data);
                  }}
                  onCancel={() => setMarkDoneDate(null)}
                  okText="Mark Complete"
                  cancelText="Cancel"
                ><CircleCheckbox /></Popconfirm>
            </span>);
  };

  const getEntryDates = (data: DataEntry, mapping: any, show: boolean, content: boolean) => {
    return !show
      ? null
      : <Space size={10} direction='horizontal' style={{ marginBottom: '5px' }}>
      {content
        ? <>
          <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px', fontWeight: 'bold' }}>{mapping.dateCreated.label}: {mapping.dateCreated.resolve(data)}</div>
          {hideField(data, dataObjects, 'dateCompleted') ? null :
            <>
              <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px', fontWeight: 'bold' }}>{mapping.dateDue.label}: {mapping.dateDue.resolve(data)}</div>
              <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px', fontWeight: 'bold' }}>{mapping.dateCompleted.label}: {mapping.dateCompleted.resolve(data)}</div>
            </>
          }
        </>
        : hideField(data, dataObjects, 'dateCompleted') ? null : (!data.dateCompleted
          ? <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px' }}>{mapping.dateDue.label}: {mapping.dateDue.resolve(data)}</div>
          : <div style={{ background: 'inherit', borderRadius: '8px', fontSize: '10px' }}>{mapping.dateCompleted.label}: {mapping.dateCompleted.resolve(data)}</div>)}
    </Space>;
  };

  const commonHeaderContentFields = {
    collections: { label: 'Collections', field: '', fieldType: FieldType.JsxElement, resolve: (dataEntry: DataEntry) => {
      const currentDataCollections = dataCollectionsRef.current;
      const dataParents: DataParent[] = (dataEntry.parent ?? []) as DataParent[];
      let collectionArray = [<span key={`${dataEntry.dataUuid}-no-collections`}>None </span>];
      const editCollections = <EditOutlined key={`${dataEntry.dataUuid}-edit-outlined`} onClick={() => {
        const currentDataObjects = dataObjectsRef.current;
        const dataObject = currentDataObjects ? currentDataObjects[dataEntry.objectUuid] : null;
        if (dataObject) {
          onEditEntry(dataObject, dataEntry);
        }
        /*
        const updateTime = new Date().toLocaleString();
        setUpdateEntry(<UpdateEntryCollection
          key={`update-entry-collections-${dataEntry.dataUuid}-${updateTime}`}
          dataEntry={dataEntry}
          action={RecordModalAction.Update}
          collections={currentDataCollections}
          selectedCollections={dataParents.map((parent: DataParent) => parent.uuid)}
          onSuccess={() => {
            setLastUpdated(new Date().toLocaleString());
            setUpdateEntry(null);
          }}
        />);
        */
      }
      } />;
      if (!dataParents) {
        return editCollections;
      }
      const collectionObjs = currentDataCollections.filter((collection: DataCollectionData) => dataParents.find((parent: DataParent) => parent.uuid === collection.uuid));
      collectionArray = collectionObjs && collectionObjs.length > 0 ? collectionObjs.map((collectionObj: any) => <Tag key={`${dataEntry.dataUuid}-collections-${collectionObj.name}`} style={{ right: '0px', borderRadius: '8px', fontWeight: 'bold', fontSize: '10px' }} color="blue">{collectionObj.name}</Tag>) : collectionArray;
      collectionArray.push(editCollections);
      return collectionArray;
    }},
    dateDue: { label: 'Due', field: '', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
      if (hideField(data, dataObjects, 'dateCompleted')) {
        return;
      }
      const now = moment(moment().format(dateFormat));
      const dueDate = data.dateDue ? moment(moment(data.dateDue).format(dateFormat)) : undefined;
      const diffDays = data.dateDue ? now.diff(dueDate, 'days') : 0;

      return <DatePicker
        disabled={props.addToCollection}
        key={`data-picker-due-${data.dateDue}`}
        color={data.dateDue && !data.dateCompleted && diffDays > 0 ? 'red' : undefined}
        style={{ background: 'inherit', color: 'red', height: 'auto', width: 'auto' }}
        confirmReset={{ title: 'Remove due date' }}
        size='small'
        initialValue={data.dateDue ? moment(data.dateDue) : undefined}
        onChange={(e: any) => {
          setMarkDueDate(e);
          setUpdateDueDate(data);
        }}
      />;
    }},
    dateCompleted: { label: 'Completed', field: 'dateCompleted', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
      if (hideField(data, dataObjects, 'dateCompleted')) {
        return;
      }
      return <DatePicker
        key={`data-picker-completed-${data.dateCompleted}`}
        disabled={props.addToCollection}
        style={{ background: 'inherit', color: 'red', height: 'auto', width: 'auto' }}
        size='small'
        initialValue={data.dateCompleted ? moment(data.dateCompleted) : undefined}
        onChange={(e: any) => {
          setMarkDoneDate(e);
          setUpdateDoneDate(data);
        }}
      />;
    }}};

  const entryDataViewMapping = {
    lastUpdated: '',
    lastCollectionsUpdated: '',
    itemId: (data: any) => {
      return data.dataUuid;
    },
    header: {
      leftWidth: '30px',
      leftElement: getLeftElement,
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        // return Object.keys(mapping).map((m: any) => {
        return (
          <div key={`data-entries-header-${lastUpdated.entries}`}>
            {data.title}{mapping.dataObject && false
                          ? <span style={{ fontWeight: 'bold', maxWidth: 125, float: 'right', wordWrap: 'break-word', overflowWrap: 'break-word', right: '0px' }}>
                              {mapping.dataObject.resolve(data[mapping.dataObject.field])}
                            </span>
                          : null}
            <div>
              {getEntryDates(data, mapping, collapsed === undefined ? true : collapsed, false)}
            </div>
          </div>);
        // });
      },
      mapping: {
        dataEntry: {
          title: { label: '', field: 'title' },
          dataObject: { label: '', field: 'objectUuid', resolve: (objectUuid: string) => {
            const latestDataObjects = dataObjectsRef.current;
            const dataObj = latestDataObjects ? latestDataObjects[objectUuid] : null;
            return dataObj ? <Tag style={{ right: '0px' }} color="magenta">{dataObj.name}</Tag> : null;
          }},
          ...commonHeaderContentFields,
        },
      },
    },
    content: {
      mapFn: (data: any, mapping: any, collapsed?: boolean) => {
        const description = _.get(data, mapping.description.fields.description);
        return <>
          {description ? <div key={`entry-content-description`}><span style={{ fontWeight: 'bold' }}>Description: </span>{description}</div> : null}
          {mapping.collections && mapping.collections.resolve ? <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Collections: {mapping.collections.resolve(data)}</div> : null}
          <div>{getEntryDates(data, mapping, collapsed === undefined ? false : !collapsed, true)}</div>
        </>;
      },
      mapping: {
        dataEntry: {
          description: {
            label: 'Description',
            fields: {
              description: 'description',
            },
          },
          dateCreated: { label: 'Created', field: 'dateCreated', fieldType: FieldType.Date, resolve: (data: DataEntry) => {
            return <DatePicker
              disabled={true}
              allowClear={false}
              key={`data-picker-created-${data.dateCreated}`}
              style={{ background: 'inherit', height: 'auto', width: 'auto' }}
              size='small'
              initialValue={data.dateCreated ? moment(data.dateCreated) : undefined}
            />;
          }},
          ...commonHeaderContentFields,
        },
      },
    },
  };

  useEffect(() => {
    if (updateDoneDate) {
      setLoadingMessage('Update Entry done date ...');
      onMarkDone(updateDoneDate);
      setUpdateDoneDate(null);
    }
    if (updateDueDate) {
      setLoadingMessage('Update Entry due date ...');
      onMarkDue(updateDueDate);
      setUpdateDueDate(null);
    }
    if (updateDoneDueDate) {
      setLoadingMessage('Update Entry done and due date ...');
      onMarkDoneDue(updateDoneDueDate);
      setUpdateDoneDueDate(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [updateDueDate, updateDoneDate, updateDoneDueDate]);

  const windowSize = useWindowSize();

  const extraMenu = (initialData: any) => {
    const currentDataObjects = dataObjectsRef.current;
    const dataObject = currentDataObjects ? currentDataObjects[initialData.objectUuid] : null;
    return  {
      direction: hideField(initialData, currentDataObjects, 'dateCompleted') ? LayoutDirection.Horizontal : LayoutDirection.Vertical,
      items: [
        <EditOutlined onClick={() => dataObject ? onEditEntry(dataObject, initialData) : errorNotification()} />,
        initialData.active
          ? <Popconfirm
              placement={'leftTop'}
              title={<><div>Deleted Entries cannot be restored.</div><div>Please confirm you that want to delete the Entry.</div></>}
              onConfirm={() => dataObject ? onDeleteEntry(dataObject, initialData) : errorNotification()}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            ><DeleteOutlined />
            </Popconfirm>
          : <Popconfirm
              placement={'leftTop'}
              title="Restore entry."
              onConfirm={() => dataObject ? onRestoreEntry(dataObject, initialData) : errorNotification()}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            ><UndoOutlined />
            </Popconfirm>,
      ],
    };
  };

  const updateCreateEntry = () => {
    setLastUpdated((prevLastUpdated) => {
      const currentLastUpdated = { ...prevLastUpdated };
      currentLastUpdated.entries = new Date().toLocaleString();
      return currentLastUpdated;
    });
    if (markDoneDate) {
      fireConfetti();
    }
    setMarkDueDate(null);
    setMarkDoneDate(null);
    setUpdateEntry(null);
  };

  const onUpdateCreateError = (error: string) => {
    errorNotification(`Error found while updating item, please try again. Error: ${error}`);
    // setUpdateEntry(null);
  };

  const onUpdateCreateCancel = () => {
    setUpdateEntry(null);
  };

  const onCreateEntry = () => onCreateEntryWithArgs();

  const onCreateEntryWithArgs = (dateDue?: Date, dateCompleted?: Date, dataObject?: DataObject) => {
    const initialDataEntry: DataEntry | undefined = dateDue || dateCompleted ? { title: '', description: '', dataUuid: '', objectUuid: '', dateCreated: '', instances: [], ownerUuid: '', data: [], dateDue, dateCompleted } : undefined;
    setLoadingMessage('Creating Entry ...');
    setUpdateEntry(<CreateEntry
      initialData={initialDataEntry}
      action={RecordModalAction.Create}
      objects={dataObjects} collections={dataCollections}
      initialCollection={props.collectionUuids && props.collectionUuids.length > 0 ? props.collectionUuids[0] : undefined}
      initialObject={dataObject}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onUpdateCollections={onUpdateCollection}
      onError={onUpdateCreateError} />);
  };

  const onEditEntry = (dataObject: DataObject, initialEntry: DataEntry) => {
    onEditEntryWithArgs(dataObject, initialEntry);
  };

  const onEditEntryWithArgs = (dataObject: DataObject, initialEntry: DataEntry, dateDue?: Date, dateDone?: Date) => {
    setLoadingMessage('Updating Entry ...');
    const globalFields = dataObjects[GlobalObjectFields];
    initialEntry.data.forEach((field: DataEntryData) => {
      if (!dataObject.entries) {
        dataObject.entries = [];
      }
      if (!dataObject.entries.find((objField: DataObjectEntry) => objField.uuid === field.entryUuid && (field.customName === undefined || field.customName === objField.name))) {
        const globalFieldIndex = globalFields.entries!.findIndex((globalField: DataObjectEntry) => globalField.uuid === field.entryUuid);
        if (globalFieldIndex >= 0) {
          const globalField = { ...globalFields.entries![globalFieldIndex] };
          if (field.customName) {
            globalField.name = field.customName;
          }
          dataObject.entries.push(globalField as DataObjectEntry);
        }
      }
    });
    if (dateDue) {
      initialEntry.dateDue = dateDue;
    }
    if (dateDone) {
      initialEntry.dateCompleted = dateDone;
    }
    setUpdateEntry(<EditEntry
      autoExecute={dateDue ? true : undefined}
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={initialEntry}
      collections={dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onUpdateCollections={onUpdateCollection}
      onError={onUpdateCreateError} />);
  };

  const onDeleteEntry = (dataObject: DataObject, initialEntry: DataEntry) => {
    setLoadingMessage('Deleting Entry ...');
    setUpdateEntry(<EditEntry
      autoExecute
      action={RecordModalAction.Delete}
      dataObject={dataObject}
      initialDataEntry={initialEntry}
      collections={dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onMarkDue = (initialEntry: DataEntry, date?: Date) => {
    const dataObject = dataObjects ? dataObjects[initialEntry.objectUuid] : null;
    if (!dataObject) {
      errorNotification();
      return;
    }
    const markDue = { ...initialEntry };
    markDue.dateDue = date ? moment(date) : (markDueDate ? moment(markDueDate) : null);
    setUpdateEntry(<EditEntry
      autoExecute
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={markDue}
      collections={dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onMarkDone = (initialEntry: DataEntry, date?: Date) => {
    const currentDataObjects = dataObjectsRef.current;
    const dataObject = currentDataObjects ? currentDataObjects[initialEntry.objectUuid] : null;
    if (!dataObject) {
      errorNotification();
      return;
    }
    const markDone = { ...initialEntry };
    markDone.dateCompleted = date ? moment(date) : (markDoneDate ? moment(markDoneDate) : null);
    // setMarkDoneDate(null);
    setUpdateEntry(<EditEntry
      autoExecute
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={markDone}
      collections={dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onMarkDoneDue = (initialEntry: DataEntry, date?: Date) => {
    const dataObject = dataObjects ? dataObjects[initialEntry.objectUuid] : null;
    if (!dataObject) {
      errorNotification();
      return;
    }
    const markDue = { ...initialEntry };
    markDue.dateDue = date ? moment(date) : (markDueDate ? moment(markDueDate) : null);
    markDue.dateCompleted = date ? moment(date) : (markDoneDate ? moment(markDoneDate) : null);
    // setMarkDueDate(null);
    // setMarkDoneDate(null);
    setUpdateEntry(<EditEntry
      autoExecute
      action={RecordModalAction.Update}
      dataObject={dataObject}
      initialDataEntry={markDue}
      collections={dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onRestoreEntry = (dataObject: DataObject, initialEntry: DataEntry) => {
    setLoadingMessage('Restoring Entry ...');
    setUpdateEntry(<EditEntry
      autoExecute
      action={RecordModalAction.Restore}
      dataObject={dataObject}
      initialDataEntry={initialEntry}
      collections={dataCollections}
      onSuccess={updateCreateEntry}
      onCancel={onUpdateCreateCancel}
      onError={onUpdateCreateError} />);
  };

  const onUpdateCollection = () => {
    setLastUpdated((prevLastUpdated) => {
      const currentLastUpdated = { ...prevLastUpdated };
      currentLastUpdated.collections = new Date().toLocaleString();
      return currentLastUpdated;
    });
  };

  /*
  const onSetCalender = (dates: { startDate: Date; endDate: Date } | undefined) => {
    setShowCalendar(dates);
  };
  */

  const viewOptions = ['Card', 'Entry', 'Sortable'].map((e: any) => {
    return <Select.Option key={`option-view-${e}`} value={e}>{e}</Select.Option>;
  });

  const viewItems =
    <Select style={{ top: '30px', left: 'auto', right: 'auto' }} key='view-select' placeholder="Select View" value={selectedView} onSelect={(value: any) => {
      if (selectedView !== value) {
        setSelectedView(value);
      }
    }}>
      {viewOptions}
    </Select>;

  const noDataMessage = dataEntries
    ? (props.collectionUuids && props.collectionUuids.length === 1
      ? (props.addToCollection
        ? <CenteredDiv style={{ fontWeight: 'bold' }}> There are no Entries in your account.</CenteredDiv>
        : (collection
              ? <>
                <CenteredDiv style={{ fontWeight: 'bold' }}>There are no Entries in <span style={{ fontWeight: 'bold' }}>{collection.name}</span></CenteredDiv>
                <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> Create a new Entry or add an existing entry to this Collection.</div>
                <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> Click on <QuestionCircleTwoTone onClick={() => setShowTutorial(TutorialType.EntryCreateNew)}/> for more details.</div>
                <CenteredDiv><span style={{ color: 'blue' }} onClick={onCreateEntry}>Create a new Entry</span> or <span style={{ color: 'blue' }} onClick={() => setAddEntriesToCollection([])}>add an existing Entry</span></CenteredDiv>
              </>
              : <>
                <CenteredDiv style={{ fontWeight: 'bold' }}><InfoCircleTwoTone /> There are no Entries in this collection.</CenteredDiv>
                <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> Create a new Entry or add an existing entry to this Collection.</div>
                <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> Click on <QuestionCircleTwoTone onClick={() => setShowTutorial(TutorialType.EntryCreateNew)}/> for more details.</div>
                <CenteredDiv><span style={{ color: 'blue' }} onClick={onCreateEntry}>Create a new Entry</span> or <span style={{ color: 'blue' }} onClick={() => setAddEntriesToCollection([])}>add an existing Entry</span></CenteredDiv>
              </>))
      : <>
          <CenteredDiv style={{ fontWeight: 'bold' }}>There are no Entries in your account.</CenteredDiv>
          <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> You can create and edit an Entry to keep track of your data and group multiple Entries using Collections.</div>
          <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> You can assign an Entry a date when the item is Due or when it was completed so you can stay on top of your tasks.</div>
          <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> You can filter and sort your entries to make it easier for you to manage.</div>
          <div style={{ textAlign: 'left' }}><InfoCircleTwoTone /> Click on <QuestionCircleTwoTone onClick={() => setShowTutorial(TutorialType.EntryCreateNew)}/> for more details.</div>
          {!props.addToCollection ? <CenteredDiv style={{ color: 'blue', cursor: 'pointer' }} onClick={onCreateEntry}>Create a new Entry.</CenteredDiv> : null}
        </>)
    : <CenteredDiv>Loading entries ...</CenteredDiv>;

  const onTutorialClose = () => setShowTutorial(TutorialType.None);

  const getTutorialNextPrev = (type: TutorialType): { nextPage: TutorialType | null, prevPage: TutorialType | null } => {
    const order = [
      TutorialType.EntryCreateNew,
      TutorialType.EntryFilter,
      TutorialType.EntryCollections,
      TutorialType.EntryItem,
      TutorialType.EntryEdit,
      TutorialType.EntryItemToggle,
      TutorialType.EntryContent,
      TutorialType.EntryOverdue,
      TutorialType.EntryCompleted,
    ];
    const index = order.findIndex((item: TutorialType) => item === type);
    const nextPage = index + 1 === order.length || index === undefined ? null : order[index + 1];
    const prevPage = index === 0 || index === undefined ? null : order[index - 1];
    return { nextPage, prevPage };
  };

  const onTutorialKeyPress = (currentPage: TutorialType, key: string) => {
    const currentShowTutorial = showTutorialRef.current;
    if (currentPage !== currentShowTutorial) {
      return;
    }
    const { prevPage, nextPage } = getTutorialNextPrev(currentShowTutorial);
    if (key === ALLOWED_KEYS.ArrowLeft && prevPage) {
      setShowTutorial(prevPage);
    }
    if (key === ALLOWED_KEYS.ArrowRight && nextPage) {
      setShowTutorial(nextPage);
    }
  };

  const getTutorialButtons = (type: TutorialType): JSX.Element[] => {
    const { prevPage, nextPage } = getTutorialNextPrev(type);
    return [
      <Button key='tutorial-close-button' style={{ background: 'darkgray' }} onClick={() => setShowTutorial(TutorialType.None)}>Close</Button>,
      ...(prevPage === null) ? [] : [<Button key='tutorial-prev-button' style={{ background: 'darkgray' }}  onClick={() => setShowTutorial(prevPage)}>Previous</Button>],
      ...(nextPage === null) ? [] : [<Button key='tutorial-next-button' style={{ background: 'darkgray' }}  onClick={() => setShowTutorial(nextPage)}>Next</Button>],
    ];
  };

  const sampleEntriesType = [TutorialType.EntryItem, TutorialType.EntryItemToggle, TutorialType.EntryOverdue, TutorialType.EntryCompleted, TutorialType.EntryEdit, TutorialType.EntryContent];

  const getTutorial = (): { type: ViewTutorialType, props: WizardTooltipProps, index?: number }  => {
    const header = [TutorialType.EntryItem];
    const leftElement = [TutorialType.EntryItemToggle, TutorialType.EntryItemToggle, TutorialType.EntryOverdue, TutorialType.EntryCompleted];
    const rightElement = [TutorialType.EntryEdit];
    const content = [TutorialType.EntryContent];
    if (header.find((item: TutorialType) =>  item === showTutorial)) {
      return { type: ViewTutorialType.Header, props: getTutorialProps(showTutorial, onTutorialClose, getTutorialButtons(showTutorial), onTutorialKeyPress) };
    }
    if (content.find((item: TutorialType) =>  item === showTutorial)) {
      return { type: ViewTutorialType.Content, props: getTutorialProps(showTutorial, onTutorialClose, getTutorialButtons(showTutorial), onTutorialKeyPress), index: 1 };
    }
    if (rightElement.find((item: TutorialType) =>  item === showTutorial)) {
      return { type: ViewTutorialType.RightElement, props: getTutorialProps(showTutorial, onTutorialClose, getTutorialButtons(showTutorial), onTutorialKeyPress) };
    }
    const leftIndex = leftElement.findIndex((item: TutorialType) =>  item === showTutorial);
    if (leftIndex >= 0) {
      return { type: ViewTutorialType.LeftElement, props: getTutorialProps(showTutorial, onTutorialClose, getTutorialButtons(showTutorial), onTutorialKeyPress), index: leftIndex };
    }

    return { type: ViewTutorialType.None, props: TutorialNoneProps };
  };

  // if showCalendar is false, do not filter based on calendarSelectedDates
  const entriesToDisplay = !sampleEntriesType.find((item: TutorialType) => item === showTutorial) ? (dataEntries ? filterData(entriesSettings, dataEntries, props.entriesUuids, showCalendar ? calendarSelectedDates : []) : []) : sampleEntries;
  // const calendarEntriesToDisplay = !sampleEntriesType.find((item: TutorialType) => item === showTutorial) ? (dataEntries ? filterData(entriesSettings, dataEntries, props.entriesUuids) : []) : sampleEntries;

  const onCalendarCreateEvent = (dateDue?: Date, dateCompleted?: Date) => {
    onCreateEntryWithArgs(dateDue, dateCompleted);
  };

  const onCalendarDeleteEvent = (dataUuid: string) => {
    const currentDataEntries = dataEntriesRef.current;
    const dataEntry = currentDataEntries ? currentDataEntries.find((entry: DataEntry) => entry.dataUuid === dataUuid) : null;
    if (!dataEntry) {
      console.error(`Error onCalendarUpdateEvent entry not found: ${dataUuid}`);
      errorNotification('Something went wrong, please try again.');
      return;
    }
    const currentDataObjects = dataObjectsRef.current;
    const dataObject = currentDataObjects ? currentDataObjects[dataEntry.objectUuid] : null;
    if (!dataObject) {
      console.error(`Error onCalendarUpdateEvent data object not found: ${dataEntry.objectUuid}`);
      errorNotification('Something went wrong, please try again.');
      return;
    }
    onDeleteEntry(dataObject, dataEntry);
  };

  const onCalendarUpdateEvent = (dataUuid: string, dateDue?: Date, dateCompleted?: Date) => {
    const currentDataEntries = dataEntriesRef.current;
    const dataEntry = currentDataEntries ? currentDataEntries.find((entry: DataEntry) => entry.dataUuid === dataUuid) : null;
    if (!dataEntry) {
      console.error(`Error onCalendarUpdateEvent entry not found: ${dataUuid}`);
      errorNotification('Something went wrong, please try again.');
      return;
    }
    const currentDataObjects = dataObjectsRef.current;
    const dataObject = currentDataObjects ? currentDataObjects[dataEntry.objectUuid] : null;
    if (!dataObject) {
      console.error(`Error onCalendarUpdateEvent data object not found: ${dataEntry.objectUuid}`);
      errorNotification('Something went wrong, please try again.');
      return;
    }
    onEditEntryWithArgs(dataObject, dataEntry, dateDue, dateCompleted);
  };

  const onCalendarUpdateSelected = (dates: { start: Date, end?: Date }[]) => {
    if (dates.length !== calendarSelectedDates.length || calendarSelectedDates.length !== 0) {
      const newDates = dates.find((date: any) => date.end !== undefined);
      const oldDates = calendarSelectedDates.find((date: any) => date.end !== undefined);
      if (!oldDates || !newDates || oldDates.start.toISOString() !== newDates.start.toISOString() || oldDates.end!.toISOString() !== newDates.end!.toISOString()) {
        setCalendarSelectedDates(dates);
      }
    }
  };

  return (
    <>
      <TutorialCollection tutorial={showTutorial} />
      {getDataObjects}
      {getDataEntries}
      {getDataCollections}
      {updateEntry}
      {collection && dataEntries && addEntriesToCollection && !props.addToCollection ? <UpdateCollectionEntries entries={dataEntries} collection={collection} onSuccess={() => {
        setAddEntriesToCollection(null);
        setLastUpdated((prevLastUpdated) => {
          const currentLastUpdated = { ...prevLastUpdated };
          currentLastUpdated.collections = new Date().toLocaleString();
          return currentLastUpdated;
        });
      }} /> : null}
      <LoadingOverlay
        active={(isLoading.collections || isLoading.dataObjects || isLoading.entries) && updateEntry === null}
        spinner
        text={`${loadingMessage}`}
        >
        <ContentView
          isModal={props.addToCollection || props.viewAsModal}
          hideDivider={(entriesSettings.filter === ViewFilter.All && !(dataEntries && dataEntries.length === 0)) || props.addToCollection}
          actionButtons={props.addToCollection ? [] : [
            <Button key='entry-refresh' style={{ float: 'right' }} onClick={() => {
              setLastUpdated((prevLastUpdated) => {
                const currentLastUpdated = { ...prevLastUpdated };
                const newLastUpdated = new Date().toLocaleString();
                currentLastUpdated.entries = newLastUpdated;
                currentLastUpdated.collections = newLastUpdated;
                currentLastUpdated.dataObjects = newLastUpdated;
                return currentLastUpdated;
              });
            }}><ReloadOutlined /></Button>,
            <Button
              key='entry-calendar'
              style={{ float: 'right', display: debug >= 0 ? undefined : 'none' }}
              onClick={() => {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth();
                setShowCalendar(showCalendar !== undefined ? undefined : { startDate: new Date(year, month, 1), endDate: new Date(year, month + 1, 0) });
              }}>
              <CalendarOutlined />
            </Button>,
            <WizardTooltip
              key={`tutorial-entry-filter`}
              {...getTutorialProps(
                showTutorial === TutorialType.EntryFilter ? showTutorial : TutorialType.None,
                () => setShowTutorial(TutorialType.None),
                getTutorialButtons(TutorialType.EntryFilter),
                onTutorialKeyPress)}
            >
              <EntryFilter
                isTutorial={showTutorial === TutorialType.EntryFilter ? true : undefined}
                settings={entriesSettings}
                onUpdateSetting={(updateSettings: EntrySettings) => setEntriesSettings(updateSettings)}
                icon={<Button key='entry-filter' style={{ float: 'right' }}><FilterOutlined /></Button>}
              />
            </WizardTooltip>,
            ...(((props.collectionUuids && props.collectionUuids.length > 0)) || showTutorial !== TutorialType.None
              ? [<WizardTooltip
                  key={`tutorial-entry-collections`}
                  {...getTutorialProps(
                    showTutorial === TutorialType.EntryCollections ? showTutorial : TutorialType.None,
                    () => setShowTutorial(TutorialType.None),
                    getTutorialButtons(TutorialType.EntryCollections),
                    onTutorialKeyPress)}
                >
                  <Button key='entry-existing' style={{ float: 'right' }} onClick={() => setAddEntriesToCollection([])}>
                    <FileAddOutlined />
                  </Button>
                </WizardTooltip>]
              : []),
            <WizardTooltip
              key={`tutorial-entry-create-new`}
              {...getTutorialProps(
                showTutorial === TutorialType.EntryCreateNew ? showTutorial : TutorialType.None,
                () => setShowTutorial(TutorialType.None),
                getTutorialButtons(TutorialType.EntryCreateNew),
                onTutorialKeyPress)}
            >
              <Button key='entry-add' style={{ float: 'right' }} onClick={onCreateEntry}><PlusCircleOutlined /></Button>
            </WizardTooltip>]}
          data={
            <>
              {entriesSettings.filter !== ViewFilter.All ? <CenteredDiv style={{ color: 'blue' }}><span style={{ fontWeight: 'bold' }}>Filter: </span>{entriesSettings.filter}</CenteredDiv> : null}
              {showCalendar && !props.addToCollection && !props.viewAsModal ? <Calendar key={`entries-calendar-${lastUpdated.entries}`} onCreateEvent={onCalendarCreateEvent} onUpdateEvent={onCalendarUpdateEvent} onDeleteEvent={onCalendarDeleteEvent} onUpdateSelected={onCalendarUpdateSelected} weekendsVisible={true} entryEvents={entryToEvents(entriesToDisplay, dataCollections)} /> : null}
              {selectedView === 'Card'
                ? <ViewCard
                    customKey={`viewcard-entries`}
                    style={{ maxWidth: windowSize.width ? windowSize.width : 800 }}
                    data={dataEntries ? filterData(entriesSettings, dataEntries) : []}
                    loading={(isLoading.collections || isLoading.dataObjects || isLoading.entries) || dataEntries === null}
                    noDataMessage={noDataMessage}
                    mapping={entryDataViewMapping}
                    sourceName='dataEntry'
                    extraMenu={props.addToCollection ? undefined : extraMenu} />
                : (selectedView === 'Sortable'
                  ? <ViewDraggable
                      info={props.addToCollection || props.viewAsModal ? undefined : <Button type="text" key='collection-info' style={{ float: 'left' }}><QuestionCircleTwoTone style={{ float: 'left' }} onClick={() => setShowTutorial(TutorialType.EntryCreateNew)}/></Button>}
                      tutorial={getTutorial()}
                      customKey={`viewcard-entries-${props.collectionUuids ? props.collectionUuids.join('-') : 'all'}`}
                      dragDisabled={entriesSettings.sortBy !== EntrySort.Custom || (props.addToCollection || props.viewAsModal || !props.collectionUuids || props.collectionUuids.length === 0)}
                      style={{ maxWidth: windowSize.width ? windowSize.width : 800 }}
                      data={entriesToDisplay}
                      loading={(isLoading.collections || isLoading.dataObjects || isLoading.entries) || dataEntries === null}
                      noDataMessage={noDataMessage}
                      mapping={entryDataViewMapping}
                      sourceName='dataEntry'
                      collapseable
                      extraMenu={props.addToCollection ? undefined : extraMenu} />
                  : null)}
            </>}
        />
      </LoadingOverlay>
      {props.addToCollection
        ? null
        :<CenteredDiv>
        {debug > 10 ? <div style={{ padding: '10px' }}>{viewItems}</div> : null}
        <br />
        <BottomPageOrContent pinBottom={!dataEntries || dataEntries.length === 0}>
          <div onClick={() => setDebug(debug + 1)}>last updated: {moment(lastUpdated.entries) < moment(lastUpdated.collections) ? lastUpdated.collections : lastUpdated.entries}</div>
        </BottomPageOrContent>
      </CenteredDiv>}
    </>
  );
};

export default EntriesView;
