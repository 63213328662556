import React from 'react';
import { getDeviceTypeInfo, DeviceTypeInfo } from '../components/styled/ScreenSize';

export type HeaderSettings = { show: boolean, top: number, right: number };

export type DisplaySettings = { header?: HeaderSettings, showFooter?: boolean, showThemeSetting?: boolean, deviceTypeInfo?: DeviceTypeInfo };

export default React.createContext({
  header: { show: true, top: 15, right: 15 } as HeaderSettings,
  showFooter: true,
  showThemeSetting: false,
  componentProps: {} as { [name: string] : any },
  lastUpdated: '',

  deviceTypeInfo: getDeviceTypeInfo,
  setDisplaySettings: (settings: DisplaySettings) => {},
  setComponentProps: (prop: { [name: string] : any }) => {},
  setLastUpdated: (lastUpdated: string) => {},
});
