import gql from 'graphql-tag';
import DataObjectEntry from './DataObjectEntry';

export default gql`
  fragment DataObject on DataObject {
    uuid
    ownerUuid
    name
    description
    entries
      {
        ...DataObjectEntry
      }
    dateCreated
    active
    hideFields
  }
  ${DataObjectEntry}
`;
