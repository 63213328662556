import React, { useState, useContext, useEffect } from 'react';
// import styled from 'styled-components';
import {  Button, message, Space } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { RecordModalAction } from '../components/modal/RecordModal';
import Page from '../components/layout/Page';
import { ResponsiveContentDiv } from '../components/styled/ResonsiveStyles';
import { DeviceType } from '../components/styled/ScreenSize';
import ThemeContext from '../context/ThemeContext';
import { Content } from '../components/styled/CssContent';
import EditableTable, { EditableColumnProps } from '../components/table/EditableTable';
import { RecordType, InputType, getEnumValues, HorizontalList, HorizontalListItem, DocumentDataSchema, DocumentEntrySchema, getDataEntryTypeToRecordType } from '../components/table/DataTypes';
import { useGetDataObjectsQuery, DataObject, DataObjectEntry } from '../graphql/generated/graphql';
import logger from '../utils/Logger';

message.config({
  top: 11,
  duration: 5,
  maxCount: 2,
});

const FieldsDiv = styled.div`
  margin: 10px;
  width: 100vh;
  display: block
`;

type ManageDocumentProps = RouteComponentProps & {
};

type ManageDocumentState = {
  action: RecordModalAction;
  editRow: number | undefined;
  dataSource: any[];
  isEditing: boolean;
  showDataSource: boolean;
};

const ManageDocument: React.FC<ManageDocumentProps> = (props: ManageDocumentProps) => {
  const themeContext = useContext(ThemeContext);

  // const [action, setAction] = useState<RecordModalAction>(RecordModalAction.None);
  // const [editRow, setEditRow] = useState(undefined);
  const [dataSource, setDataSource] = useState<DocumentDataSchema[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showDataSource, setShowDataSource] = useState<boolean>(false);

  const authedDataClientContext = { context: { clientName: 'data' } };

  // const [addDocument, { error: addDocumentError }] = useAddAuthedDataMutation(authedDataClientContext);
  // const [updateDocument] = useUpdateAuthedDataMutation(authedDataClientContext);
  // const [deleteDocument] = useDeleteAuthedDataMutation(authedDataClientContext);

  const { data } = useGetDataObjectsQuery({
    variables: { includeInactive: false },
    ...authedDataClientContext,
  });

  useEffect(() => {
    if (!data || !data.getDataObjects) {
      return;
    }
    logger.info({ source: 'pages.ManageDocument', message: 'manageDocument useEffect' });
    logger.info({ source: 'pages.ManageDocument', message: JSON.stringify(data.getDataObjects, null, 2) });

    const newData: DocumentDataSchema[] = data!.getDataObjects.map((obj: DataObject) => {
      const entries: DocumentEntrySchema[] = (obj && obj.entries)
        ? obj.entries.map((entry: DataObjectEntry) => {
          const recordType = getDataEntryTypeToRecordType(entry.type);
          return ({
            name: entry.name,
            type: recordType ? recordType.recordType : '',
            values: entry.schema && entry.schema.values ? entry.schema.values : [],
          });
        })
        : [];
      return ({ name: obj.name, description: obj.description, entries });
    });
    setDataSource(newData);
  },        [data]);

  const showData = dataSource.length > 0 ? dataSource : data?.getDataObjects;

  logger.info({ source: 'pages.ManageDocument', message: 'showData' });
  logger.info({ source: 'pages.ManageDocument', message: JSON.stringify(showData, null, 2) });

  const columns: EditableColumnProps<any>[] = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      inputType: { recordType: RecordType.Index },
      width: 50,
      render: (text: string, record: any) => (
        <div>{record.id}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      inputType: { recordType: RecordType.Input, inputType: InputType.String },
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      inputType: { recordType: RecordType.Input, inputType: InputType.String },
      editable: true,
      required: false,
    },
  ];

  const expandableColumns = [
    {
      title: 'Field Name',
      dataIndex: 'name',
      key: 'name',
      inputType: { recordType: RecordType.Input, inputType: InputType.String },
      width: 150,
      editable: true,
    },
    {
      title: 'Field Type',
      dataIndex: 'type',
      key: 'type',
      inputType: { recordType: RecordType.Dropdown, inputType: InputType.Dropdown, values: getEnumValues(RecordType) },
      width: 150,
      editable: true,
    },
    {
      title: 'Possible Values',
      dataIndex: 'values',
      key: 'values',
      inputType: { recordType: RecordType.Dropdown, inputType: InputType.String },
      width: 200,
      editable: true,
      required: false,
      render: (text: string, record: any) => {
        logger.info({ source: 'pages.ManageDocument', message: JSON.stringify(record, null, 2) });
        if (!record.values) {
          return '--';
        }
        if (typeof record.values === 'string') {
          return [record.values];
        }
        const listOptions = record.values.map((item: any) => (
          <HorizontalListItem key={`field-options-${item}`}>{item}</HorizontalListItem>));
        return (<HorizontalList>{listOptions}</HorizontalList>);
      },
    },
  ];

  const handleShowDataSource = () => {
    setShowDataSource(!showDataSource);
  };

  const handleAddDocument = () => {
    const newData = {
      name: '',
      description: '',
      entries: [],
      newRow: true,
    };
    setDataSource([...dataSource, newData]);
  };

  const handleAddDocumentField = (documentName: string) => {
    const newDataField: DocumentEntrySchema = {
      name: '',
      type: '',
      values: [],
      newRow: true,
    };
    const updateDataSource = dataSource;
    const documentIndex = updateDataSource.findIndex((doc: any) => doc.name === documentName);
    if (documentIndex === -1) {
      logger.error({ source: 'pages.ManageDocument', message: `document not found with name ${documentName}` });
      return;
    }
    updateDataSource[documentIndex].entries = [...updateDataSource[documentIndex].entries as DocumentEntrySchema[], newDataField];
    setDataSource([...updateDataSource]);
    setIsEditing(true);
  };

  const updateDocumentField = (documentName: string, newData: any[]) => {
    const updateDataSource = dataSource;
    const documentIndex = updateDataSource.findIndex((doc: any) => doc.name === documentName);
    if (documentIndex === -1) {
      logger.error({ source: 'pages.ManageDocument', message: `document not found with name ${documentName}` });
      return;
    }
    updateDataSource[documentIndex].entries = [...newData];
    setDataSource([...updateDataSource]);
  };

  const updateDataSource = (newData: any[]) => {
    setDataSource([...newData]);
  };

  const updateEditing = (editing: boolean) => {
    setIsEditing(editing);
  };

    // const { action } = this.state;
  const documentData: any[] = [];
  dataSource.forEach((d: any, index: number) => {
    const newDocumentData = d.newRow
    ? { key: `document-${index + 1}`, id: index + 1, name: d.name, description: d.description, entries: d.entries, newRow: true }
    : { key: `document-${index + 1}`, id: index + 1, name: d.name, description: d.description, entries: d.entries };
    documentData.push(newDocumentData);
  });

  logger.info({ source: 'pages.ManageDocument', message: 'data source' });
  logger.info({ source: 'pages.ManageDocument', message: JSON.stringify(documentData, null, 2) });

  return (
    <Page content={
      <Content>
        <ResponsiveContentDiv isMobile={themeContext.deviceTypeInfo().deviceType === DeviceType.Mobile}>
          <Space direction='horizontal' style={{ float: 'right', paddingTop: '15px', paddingBottom: '0px' }}>
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                logger.info({ source: 'pages.ManageDocument', message: 'clicked' });
                handleShowDataSource();
                // this.setState({ action: RecordModalAction.Create });
              }}>Show pretty Object</Button>
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                logger.info({ source: 'pages.ManageDocument', message: 'clicked' });
                handleAddDocument();
                // this.setState({ action: RecordModalAction.Create });
              }}>Add new document</Button>
          </Space>
        </ResponsiveContentDiv>
        <ResponsiveContentDiv isMobile={themeContext.deviceTypeInfo().deviceType === DeviceType.Mobile}>
          <EditableTable
            updateDataSource={(newData: any[]) => updateDataSource(newData)}
            updateNewField={handleAddDocumentField}
            updateEditing={updateEditing}
            editable={!isEditing}
            className='.antd-documents-table'
            size='small'
            scroll={{ x: 400, y: themeContext.deviceTypeInfo().height - 250 }}
            columns={columns}
            dataSource={documentData}
            pagination={false}
            expandable={{
              expandedRowRender: (record: any) => {
                logger.info({ source: 'pages.ManageDocument', message: 'record' });
                logger.info({ source: 'pages.ManageDocument', message: JSON.stringify(record, null, 2) });
                const fieldData: any[] = [];
                record.entries.forEach((field: any) => {
                  const addFieldData = field.newRow
                  ? {
                    key: `document-${record.key} document-fields-${field.name}`,
                    name: field.name,
                    type: `${RecordType[field.type as keyof typeof RecordType]}${field.inputType ? ` ${InputType[field.inputType as keyof typeof InputType]}` : ''}`,
                    values: field.values, // ? `[ ${field.values.join(', ')} ]` : '--',
                    newRow: true,
                  }
                  : {
                    key: `document-${record.key} document-fields-${field.name}`,
                    name: field.name,
                    type: `${RecordType[field.type as keyof typeof RecordType]}${field.inputType ? ` ${InputType[field.inputType as keyof typeof InputType]}` : ''}`,
                    values: field.values, // ? `[ ${field.values.join(', ')} ]` : '--',
                  };
                  fieldData.push(addFieldData);
                });
                logger.info({ source: 'pages.ManageDocument', message: 'expand table' });
                logger.info({ source: 'pages.ManageDocument', message: JSON.stringify(fieldData, null, 2) });
                logger.info({ source: 'pages.ManageDocument', message: JSON.stringify(expandableColumns, null, 2) });
                return (<FieldsDiv><EditableTable
                  updateDataSource={(newData: any[]) => updateDocumentField(record.name, newData)}
                  updateEditing={updateEditing}
                  editable={!isEditing}
                  className='.antd-document-fields'
                  size='small'
                  columns={expandableColumns}
                  dataSource={fieldData}
                  bordered={true}
                  pagination={false}
                /></FieldsDiv>);
              },
              rowExpandable: record => record.name !== 'Not Expandable',
            }}
            bordered={true}/>
        </ResponsiveContentDiv>
        {showDataSource ? (<ResponsiveContentDiv isMobile={themeContext.deviceTypeInfo().deviceType === DeviceType.Mobile}>
          <pre style={{ overflow: 'auto', height: 'auto', maxHeight: '400px' }}>{JSON.stringify(showData, null, 2)}</pre>
        </ResponsiveContentDiv>) : null}
      </Content>
    } />
  );
};

export default ManageDocument;
