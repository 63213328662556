// https://jsfiddle.net/0aogqbyp/
// https://stackoverflow.com/questions/59546928/keydown-up-events-with-react-hooks-not-working-properly
import React, { useEffect, useRef, useState } from "react";
import { Alert, Space } from "antd";
import "antd/dist/antd.css";
import styled from "styled-components";
import Button from "../atomic/Button";
import { TutorialType } from "../tutorial/Tutorial";
import Tooltip, { IconType, TooltipProps } from "../atomic/Tooltip";

export type DivWithButtonProps = {
  topLeft?: JSX.Element;
  topRight?: JSX.Element;
};

const Styled = styled.div`
  position: relative;
  .ant-tooltip-inner {
    color: yellow;
    background-color: green;
    width: 200px;
  }
`;

const TooltipStyled = styled(Tooltip)`
  .ant-tooltip {
    max-width: 350px
  }
`;

export const DivWithButton = styled.div`
`;

export enum ALLOWED_KEYS {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Escape = 'Escape',
}

export type WizardTooltipProps = TooltipProps & {
  buttonText?: string;
  type?: 'info';
  message: string | JSX.Element;
  children?: React.ReactNode;
  icon?: JSX.Element;
  buttons?: JSX.Element[];
  noButtons?: boolean;
  hideCloseIcon?: boolean;
  onClose: () => void;
  currentPage: TutorialType;
  onKeyPress?: (currentPage: TutorialType, key: string) => void;
};

const WizardTooltip: React.FC<WizardTooltipProps> = (props: WizardTooltipProps) => {
  const { buttons, hideCloseIcon, noButtons, buttonText, type, iconType, message, children, title, onClose, currentPage, onKeyPress, visible, defaultVisible, ...tooltipProps } = props;
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const propsRef = useRef(props);
  propsRef.current = props;

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(props.visible ?? true);
  // const [scrollY, setScrollY] = useState(0);
  // const [scrollInit, setScrollInit] = useState(false);
  const [tooltipDefaultVisible, setTooltipDefaultVisible] = useState<boolean>(props.defaultVisible ?? false);

  /*
  useEffect(() => {
    if (!scrollInit) {
      document.addEventListener('scroll', updateScroll);
      setScrollInit(true);

    }
    return () => {
      if (scrollInit) {
        document.removeEventListener('scroll', updateScroll);
        setScrollInit(false);
      }
    };
  }); */

  useEffect(() => {
    if (props.visible !== undefined && tooltipVisible !== props.visible) {
      setTooltipVisible(props.visible);
    }
    if (props.defaultVisible  !== undefined && tooltipDefaultVisible !== props.defaultVisible) {
      setTooltipDefaultVisible(props.defaultVisible);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [props]);

  useEffect(() => {
    const onKeyDown = ({ key }) => {
      const currentProps = propsRef.current;
      if (currentProps.visible && Object.keys(ALLOWED_KEYS).includes(key) && !pressedKeys.includes(key)) {
        setPressedKeys(previousPressedKeys => [...previousPressedKeys, key]);
      }
    };
    /*
    const onKeyUp = ({ key }) => {
      if (Object.keys(ALLOWED_KEYS).includes(key)) {
        setPressedKeys(previousPressedKeys => previousPressedKeys.filter(k => k !== key));
      }
    };*/

    document.addEventListener('keydown', onKeyDown);
    // document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      // document.removeEventListener('keyup', onKeyUp);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        []);

  useEffect(() => {
    if (pressedKeys.length > 0) {
      const processKey = pressedKeys[0];
      setPressedKeys(prevPressedKeys => prevPressedKeys.splice(1, 1));
      if (processKey === ALLOWED_KEYS.Escape) {
        onClose();
      }
      if (onKeyPress) {
        onKeyPress(currentPage, processKey);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },        [pressedKeys]);

  /*
  const updateScroll = (event: any) => {
    const pageContentDiv = document.getElementById('pageContentDiv');
    if (pageContentDiv && pageContentDiv.scrollHeight !== scrollY) {
      setScrollY(pageContentDiv.scrollHeight);
    }
  }; */

/*
  const message = type === 'info'
    ? <div style={{ marginBottom: '20px' }}>
      <WarningTwoTone style={{ marginRight: '4px' }} type='message' twoToneColor='red'/>
      {message}
    </div>
    : <div>{message}</div>;*/

  return <Styled>
    <TooltipStyled
      color='#ccd0d9'
      style={{ maxWidth: '350px' }}
      overlayStyle={{ maxWidth: '350px' }}
      {...tooltipProps}
      visible={props.visible}
      defaultVisible={tooltipDefaultVisible}
      title={
        <Alert
          key={new Date().toUTCString()}
          style={{ border: undefined }}
          message={<div style={{ fontWeight: 'bold', fontSize: '14px' }}>{title}</div>}
          description={<><div style={{ paddingTop: '4px', fontSize: '14px' }}>{message}</div><Space style={{ paddingTop: '8px', float: 'right' }} direction='horizontal'>{buttons ? buttons : (noButtons ? null : <Button key='tutorial-close-button' size='small' onClick={onClose}>Close</Button>)}</Space></>}
          type={iconType === IconType.None ? undefined : 'info'}
          showIcon={iconType === IconType.None ? undefined : true}
          closable={!hideCloseIcon}
          onClose={() => {
            onClose();
          }}
        />}
    >
      {children}
    </TooltipStyled>
  </Styled>;
};

export default WizardTooltip;
